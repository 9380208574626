import React, { memo, useContext } from 'react'
import Draggable from 'react-draggable';

import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { TheiaContext } from '../../../../Theia';

const SatelliteImageDialog = ({
  dragSatelliteImageEventHandler, satelliteImageDialogPosition, isImgDialogOpen, setIsImgDialogOpen,
  marrineTrafficImgUrl, imgUrl
}) => {
  const { zIndex, setZindex } = useContext(TheiaContext)
  return (
    <Draggable
      axis="both"
      handle=".satellite-image-dialog"
      defaultPosition={{ x: 0, y: 0 }}
      onDrag={dragSatelliteImageEventHandler}
      position={{ x: satelliteImageDialogPosition.clientX, y: satelliteImageDialogPosition.clientY }}
      bounds="body"
      onStart={() => setZindex({
        dropPin: false,
        pathSlider: false,
        attribution: false,
        satelliteImage: true,
        extendedPath: false,
        polygonSlider: false,
        polygonDataTable: false,
        futurePathModal: false,
        futurePathSlider: false,
        spoofingTimelineSlider: false,
        portsOfCallSlider: false,
      })}
    >
      <Dialog className={`${zIndex.satelliteImage ? 'zindex-true' : 'zindex-false'}`} open={isImgDialogOpen} onClose={() => setIsImgDialogOpen(false)} classes={{ root: `MuiDialog-root satellite-image-dialog viewSatelliteImage__dialog + ${(!marrineTrafficImgUrl ? 'viewSatelliteImage' : 'viewMarineTrafficImage')}` }}>
        <IconButton onClick={() => setIsImgDialogOpen(false)} style={{ position: 'absolute', right: 8, top: 8, backgroundColor: 'gray', color: 'white' }} >
          <CloseIcon />
        </IconButton>
        {imgUrl && !marrineTrafficImgUrl && <img src={imgUrl} alt="" />}
        {marrineTrafficImgUrl && <img src={marrineTrafficImgUrl} alt="" />}
      </Dialog>
    </Draggable>
  )
}

export default memo(SatelliteImageDialog);
