import React from 'react'
import { CircleMarker, Popup } from 'react-leaflet';

const UploadedCSVs = ({ uploadedCSVs }) => {
    console.log(uploadedCSVs, ':uploadedCSVs')
    return (
        uploadedCSVs.map(({ allPoints, isVisible, name, uId }, i) => {
            if(isVisible) {
                return allPoints.map((point, j) => {
                    if(point.length === 3) {
                        return <CircleMarker
                        key={i + j + uId}
                        center={{ lat: point[1], lng: point[2] }}
                        radius={5}
                    >
                        <Popup>
                            <div>Filename: {name}</div>
                            <div>Pointname: {point[0]}</div>
                            <div>Lat: {point[1]}</div>
                            <div>Lon: {point[2]}</div>
                        </Popup>
                    </CircleMarker>
                    } else {
                        return;
                    }
                    
                })
            } else {
                return;
            }
        })
    )
}

export default UploadedCSVs