import React, { memo, useCallback, useContext, useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import XLSX from 'xlsx';
import L from 'leaflet';
import { ReactSVG } from 'react-svg';
import { frontendAPIURL, getObservedShipsRequest, getSatelliteImageRequest } from '../../../../utils';

import { Button, Grid } from '@mui/material';
import SatelliteIcon from '@mui/icons-material/Satellite';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UpdateIcon from '@mui/icons-material/Update';
import DownloadIcon from '@mui/icons-material/Download';
import LayersIcon from '@mui/icons-material/Layers';
import TimelineIcon from '@mui/icons-material/Timeline';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';

import CargoIcon from '../../../../../../Icons/CargoIcon';
import TankerIcon from '../../../../../../Icons/TankerIcon';
import PassengerIcon from '../../../../../../Icons/PassengersIcon';
import FishingIcon from '../../../../../../Icons/FisihingIcon';
import OtherShips from '../../../../../../Icons/OtherShipIcon';

import { SatelliteData } from '../../../SatelliteData';
import { TheiaContext } from '../../../../Theia';

import styles from '../../../DrawerData.module.css';

const SelectedShipsTabTwo = ({
  imgUrl, setIsImgDialogOpen, setMarineTrafficImgUrl, setViewSpoofingTimeline, openExtendedPathModal,
  selectedShipsData, selectedShips, setSatelliteImageTimeline, setOpenSatelliteImagesTimelineDialog, setOpenDrawerData,
  setIsSpoofingSnackBarOpen, setShowExtendedPathDialog, setCurrentLocationExtendedPathDialog, extendedStartDate, extendedEndDate
}) => {
  const {
    setViewAnalyzePath, viewAnalyzePath, setSpoofingHeatmapVisible, setViewPathSlider, filters, setFilters, viewPathSlider,
    setShowSpinner, setViewSatelliteImage, setTileBlueValue, setTileGreenValue, setTileRedValue, setTileLayerUrl, setRedBands,
    setGreenBand, setBlueBand, viewSatelliteImage, setSelectedShips, setHaveShipInfo, setSatelliteData, mapRef, date,
    setCurrentLocationData, setSpoofingTimelineShips, setShowPathPredictionDialog, spoofingHeatmapVisible, setCurrentLocation,
    progressLoading, currentLocation, setSpoofingHeatmapTimelineVisible, spoofingHeatmapTimelineVisible, setSatelliteTimelineData, userData
  } = useContext(TheiaContext)
  const { object_id, initialData, length, width, shipCategory, status, type } = selectedShipsData.static
  const { draught } = selectedShipsData.dynamic;
  const [ShipColor, setShipColor] = useState("")
  const [dynamicData, setDynamicData] = useState([]);
  const [staticData, setStaticData] = useState([]);

  let shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.synmax_ship_id
  if (!shipId) {
    shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.attribution
  }

  const startDate = moment.utc(`${moment(extendedStartDate).format('YYYY-MM-DD')} 00:00:00`).format('YYYY-MM-DD HH:mm:ss')
  const endDate = moment.utc(`${moment(extendedEndDate).format('YYYY-MM-DD')} 23:59:59`).format('YYYY-MM-DD HH:mm:ss')


  const getIcon = useCallback(
    (color) => {
      if (type === "Tanker") {
        return <TankerIcon fill={color} />
      } else if (type === "Cargo") {
        return <CargoIcon fill={color} />
      } else if (type === "passengers") {
        return <PassengerIcon fill={color} />
      } else if (type === "Fishing") {
        return <FishingIcon fill={color} />
      } else {
        return <OtherShips fill={color} />
      }
    },
    [type],
  )

  useEffect(() => {
    let color;
    switch (shipCategory) {
      case "blue":
        setShipColor("#25a0ca");
        break;
      case "red":
        setShipColor("#f85348");
        break;
      case "orange":
        setShipColor("#fba501");
        break;
      case "ais":
        setShipColor("#148000");
        break;
      case "ais-spoofing":
        setShipColor("#f552d4");
        break;
      case "footprint":
        setShipColor("#f552d4");
        break;
      case "pink":
        setShipColor("#f552d4");
        break;
      default:
        setShipColor("#fff");
    }
    getIcon(color)
  }, [shipCategory, getIcon])

  const showSpoofingTimeline = async () => {
    if (spoofingHeatmapTimelineVisible) {
      setSpoofingHeatmapTimelineVisible(false)
    } else {
      setSpoofingHeatmapTimelineVisible(true)
      setViewSpoofingTimeline(true)
      openExtendedPathModal()
    }
  }

  const getDynamicAndStaticData = async () => {
    const token = localStorage.getItem('token')

    const shipInfo = await axios.post(`${frontendAPIURL}/ship_info`,
      { "id": "frontend", "start": startDate, "end": endDate, "shipids": [shipId] }
      , {
        headers: {
          Authorization: 'Bearer ' + token,
        },
    })
    setStaticData(shipInfo?.data?.static);
    setDynamicData(shipInfo?.data?.dynamic);
  }

  useEffect(() => {
    getDynamicAndStaticData();
  }, [shipId, startDate, endDate])


  const DownloadPathXLS = () => {
    const shipPath = selectedShips?.[selectedShips?.length - 1]?.path;
    const updatedshipPath = shipPath.map((f) => {
      return { ...f, timestamp: `${moment.utc(new Date(f.timestamp * 1000)).format('YYYY-MM-DD HH:mm:ss')}` }
    });
    const shipSheet = XLSX.utils.json_to_sheet(updatedshipPath);

    const updatedDynamicShipData = dynamicData?.map((f) => {
      return { ...f, timestamp: `${moment.utc(new Date(f.timestamp * 1000)).format('YYYY-MM-DD HH:mm:ss')}` }
    });

    const dynamicShipDataSheet = XLSX.utils.json_to_sheet(updatedDynamicShipData);

    const updatedStaticShipData = staticData?.map((f) => {
      return {
        ...f,
        updated_at: `${moment.utc(new Date(f.updated_at)).format('YYYY-MM-DD HH:mm:ss')}`,
        last_query: `${moment.utc(new Date(f.last_query)).format('YYYY-MM-DD HH:mm:ss')}`
      };
    });

    const staticShipDataSheet = XLSX.utils.json_to_sheet(updatedStaticShipData);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, shipSheet, 'Path Data');
    XLSX.utils.book_append_sheet(workBook, dynamicShipDataSheet, 'Dynamic Data');
    XLSX.utils.book_append_sheet(workBook, staticShipDataSheet, 'Static Data');

    XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    XLSX.writeFile(workBook, `${selectedShips[selectedShips?.length - 1]?.initialData?.properties?.synmax_ship_id || selectedShips[selectedShips?.length - 1]?.initialData?.properties?.attribution}-pathdata.xlsx`);
  }

  const viewPathAnimationSlider = () => {
    setViewPathSlider(!viewPathSlider)
    const { ais, light, dark } = filters;
    setFilters({
      ...filters,
      ais: !ais,
      light: !light,
      dark: !dark
    })
  };

  const getColorBands = async (url) => {
    const imageInfoForMetaData = url.split('/rgb/')[1].split('/{z}')[0]

    //red
    try {
      setShowSpinner(true)
      let redRes;
      const token = localStorage.getItem('token')

      if (process.env.REACT_APP_NGA_ENV === true || process.env.REACT_APP_NGA_ENV === "true") {
        redRes = await axios.get(`https://frontend-image-server-v2-abekcpaqxa-uk.a.run.app/metadata/${imageInfoForMetaData}/red?auth=${token}`);
      } else {
        redRes = await axios.get(`https://terracotta-server-dev-xzzxclvs3q-uc.a.run.app/metadata/${imageInfoForMetaData}/red`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
      }
      setShowSpinner(false)
      let arrPercentiles = redRes.data.percentiles;
      let arrRange = redRes.data.range;
      setRedBands([arrRange[0], ...arrPercentiles, arrRange[1]])
    } catch (err) {
      setShowSpinner(false)
      console.log(err)
    }
    //green
    try {
      setShowSpinner(true)
      let greenRes;
      const token = localStorage.getItem('token')

      if (process.env.REACT_APP_NGA_ENV === true || process.env.REACT_APP_NGA_ENV === "true") {
        greenRes = await axios.get(`https://frontend-image-server-v2-abekcpaqxa-uk.a.run.app/metadata/${imageInfoForMetaData}/green?auth=${token}`);
      } else {
        greenRes = await axios.get(`https://terracotta-server-dev-xzzxclvs3q-uc.a.run.app/metadata/${imageInfoForMetaData}/green`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
      }

      setShowSpinner(false)
      let arrPercentiles = greenRes.data.percentiles;
      let arrRange = greenRes.data.range;
      setGreenBand([arrRange[0], ...arrPercentiles, arrRange[1]])
    } catch (err) {
      setShowSpinner(false)
      console.log(err)
    }
    //blue
    try {
      setShowSpinner(true)
      let blueRes;
      const token = localStorage.getItem('token')

      if (process.env.REACT_APP_NGA_ENV === true || process.env.REACT_APP_NGA_ENV === "true") {
        blueRes = await axios.get(`https://frontend-image-server-v2-abekcpaqxa-uk.a.run.app/metadata/${imageInfoForMetaData}/blue?auth=${token}`);
      } else {
        blueRes = await axios.get(`https://terracotta-server-dev-xzzxclvs3q-uc.a.run.app/metadata/${imageInfoForMetaData}/blue`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
      }

      setShowSpinner(false)
      let arrPercentiles = blueRes.data.percentiles;
      let arrRange = blueRes.data.range;
      setBlueBand([arrRange[0], ...arrPercentiles, arrRange[1]])
    } catch (err) {
      setShowSpinner(false)
    }
  }

  const viewSatelliteImageOnTheMap = async () => {
    const token = localStorage.getItem('token')

    if (!viewSatelliteImage) {
      const { source_id } = selectedShips[selectedShips.length - 1].initialData.properties;
      setShowSpinner(true);

      try {
        const res = await axios.post(`${frontendAPIURL}/image_url`, { source_id }, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setViewSatelliteImage(true);
        const split1 = res.data.url.split(('&b_range='));
        const blueRange = JSON.parse(split1[1]);
        const split2 = split1[0].split('&g_range=');
        const greenRange = JSON.parse(split2[1]);
        const split3 = split2[0].split('&r_range=');
        const redRange = JSON.parse(split3[1]);

        setTileBlueValue(blueRange);
        setTileGreenValue(greenRange);
        setTileRedValue(redRange);
        setTileLayerUrl(res.data.url.substr(1));
        getColorBands(res.data.url);

        // Set the satelliteImageLoaded state to true only when the API call succeeds.
        // setSatelliteImageLoaded(true);
      } catch (error) {
        // Handle the error here, you can display a message to the user or log the error.
        setViewSatelliteImage(false);
      } finally {
        setShowSpinner(false);
      }
    } else {
      // If viewSatelliteImage is already true, simply set it to false to hide the image.
      setViewSatelliteImage(false);

      // Clear the image-related state variables when hiding the image.
      setTileBlueValue(null);
      setTileGreenValue(null);
      setTileRedValue(null);
      setTileLayerUrl(null);
    }
  };

  const getSatelliteImageTimeline = async () => {
    setShowSpinner(true)
    const firstShipSelected = selectedShips[selectedShips.length - 1];

    const propertiesShip = firstShipSelected
      && firstShipSelected.initialData
      && firstShipSelected.initialData.properties
      ? firstShipSelected.initialData.properties
      : null;

    if (firstShipSelected
      && firstShipSelected.shipCategory !== "red"
      && selectedShips.length
      && propertiesShip
      && ((propertiesShip?.attribution && propertiesShip?.attribution !== 'None')
        || (propertiesShip?.synmax_ship_id && propertiesShip?.synmax_ship_id !== 'None'))
    ) {

      const idShip = propertiesShip?.attribution && propertiesShip?.attribution !== 'None'
        ? propertiesShip?.attribution
        : propertiesShip?.synmax_ship_id &&
          propertiesShip?.synmax_ship_id !== 'None'
          ? propertiesShip?.synmax_ship_id
          : ''

      const shipsImages = await getObservedShipsRequest(
        '2021-01-01 00:00:00',
        moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        [idShip]
      )
      const allImgShips = await Promise.all(shipsImages.map(item => {
        return getSatelliteImageRequest(item.object_id)
      }));

      const filterShipImages = allImgShips.reduce((accumulator, currentValue, currentIndex) => {
        if (!currentValue) return accumulator;

        return [
          ...accumulator,
          {
            ...shipsImages[currentIndex],
            signedUrl: currentValue
          }
        ]
      }, [])

      setSatelliteImageTimeline(filterShipImages);
      setShowSpinner(false)
      setOpenSatelliteImagesTimelineDialog(true)
    } else {
      setShowSpinner(false)
      setOpenSatelliteImagesTimelineDialog(false)
    }
  }

  const onClearSelections = () => {
    setSelectedShips([]);
    setViewPathSlider(false);
    setViewSatelliteImage(false);
    setTileLayerUrl(null);
    setViewAnalyzePath(false);
    setHaveShipInfo(false)
    setSatelliteData({ geojson: null, key: parseInt(Math.random() * 10000) })
    setSatelliteTimelineData({ geojson: null, key: parseInt(Math.random() * 10000) })
    setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
    setSpoofingTimelineShips([])
    setOpenDrawerData(true);

    setFilters({
      ...filters,
      ais: true,
      light: true,
      dark: true,
      unknown: true,
      spoofing: true,
      footprintSpoofing: true
    })

    if (mapRef.current) {
      const { leafletElement } = mapRef.current;
      const circleMarkers = leafletElement._layers;

      Object.values(circleMarkers).forEach((circleMarker) => {
        const shipIndex = circleMarker.options.shipIndex;
        if (shipIndex !== undefined) {
          leafletElement.removeLayer(circleMarker)
        }
      });
    }

    if (mapRef.current) {
      const { leafletElement } = mapRef.current;

      leafletElement.eachLayer((layer) => {
        if (layer instanceof L.Polyline) {
          leafletElement.removeLayer(layer)
        }
      });
    }

  }

  const showPathPredictionPopUp = () => setShowPathPredictionDialog(true);

  const showFootPrintHeatmap = () => {
    if (spoofingHeatmapVisible) {
      setSpoofingHeatmapVisible(false)
    } else {
      setSpoofingHeatmapVisible(true)
      setSatelliteData({ geojson: selectedShips[selectedShips?.length - 1].initialData.properties.geojson, key: parseInt(Math.random() * 10000) })
    }
  }

  const showFootPrintSpoofingHeatmap = async () => {
    setShowSpinner(true)
    const token = localStorage.getItem('token')

    const dateString = moment(date.$d).format('YYYY-MM-DD')
    const res = await axios.post(`${frontendAPIURL}/get_events`, {
      types: ['footprint-spoofing'], "start": `${dateString} 00:00:00`, "end": `${dateString} 23:59:59`, 'shipids': [selectedShips[selectedShips?.length - 1]?.initialData?.properties?.synmax_ship_id]
    }
      , {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
    if (res.data['footprint-spoofing']) {
      const mainDataArr = res.data['footprint-spoofing']

      if (mainDataArr.length > 0) {
        const mainData = mainDataArr[0]
        setSatelliteData({ geojson: mainData.geojson, key: parseInt(Math.random() * 10000), center: [mainData.latitude, mainData.longitude] })
      } else {
        setIsSpoofingSnackBarOpen(true)
      }
      setShowSpinner(false)
    }
  }

  const currentLocationPathModal = () => {
    setShowExtendedPathDialog(false)
    setCurrentLocationExtendedPathDialog(true)
  }

  const showCurrentLocation = async () => {
    if (currentLocation) {
      setCurrentLocation(false)
    } else {
      setCurrentLocation(true)
      currentLocationPathModal()
    }
  }

  return (
    <>
      {/* 2 */}
      <Grid
        item
        xs={12}
        sm={2}
        md={3}
        lg={2}
        xl={2}
        className="drawerData__svgGrid"
      >
        <div className="drawerData__svgBox">
          {getIcon(ShipColor)}
          <div className="drawerData__svg-title">
            {draught ? draught + "m draft" : ""}{" "}
          </div>
          <div className="drawerData__svg-title">
            {length ? length + "m x " : ""}
            {width ? width + "m" : ""}
          </div>
          {shipCategory !== "ais" && (
            <div style={{ marginTop: 10 }} className="drawerData__svg-title">
              Status: {status ? status : "No Info"}
            </div>
          )}
        </div>
      </Grid>

      {/* 3 */}
      <Grid item xs={12} sm={12} md={11} lg={5} xl={5}>
        <div className="container-img-action">
          {Object.keys(initialData).length > 0 && object_id !== "No Info" && (
            <div className="drawerData__satelliteData">
              <SatelliteData
                imgUrl={imgUrl}
                setIsImgDialogOpen={setIsImgDialogOpen}
                initialData={initialData}
                setMarineTrafficImgUrl={setMarineTrafficImgUrl}
              />
            </div>
          )}

          <div className={styles.drawerDataActions}>
            {shipCategory !== "red" && (
              <>
                <Button
                  className={styles.drawerDataBtn}
                  onClick={() =>
                    setViewAnalyzePath((viewAnalyzePath) => !viewAnalyzePath)
                  }
                >
                  <ReactSVG
                    className={styles.drawerDataBtnIcon}
                    src="./shipActions/analyze-path.svg"
                  />
                  <span className={styles.titleSpan}>
                    {viewAnalyzePath ? "View Path" : "Analyze Path"}
                  </span>
                </Button>
                <Button
                  className={styles.drawerDataBtn}
                  onClick={() => {
                    setViewSpoofingTimeline(false);
                    openExtendedPathModal();
                  }}
                >
                  <ReactSVG
                    className={styles.drawerDataBtnIcon}
                    src="./shipActions/extended-path.svg"
                  />
                  <span className={styles.titleSpan}>View Extended Path</span>
                </Button>
                {userData.role !== "theia_customer" && (
                  <Button
                    className={styles.drawerDataBtn}
                    onClick={DownloadPathXLS}
                  >
                    <DownloadIcon className={styles.drawerDataBtnIcon} />
                    <span className={styles.titleSpan}>Download Path XLS</span>
                  </Button>
                )}
                <Button
                  className={styles.drawerDataBtn}
                  onClick={viewPathAnimationSlider}
                >
                  <ReactSVG
                    className={styles.drawerDataBtnIcon}
                    src="./shipActions/path-playback.svg"
                  />
                  <span className={styles.titleSpan}>
                    {viewPathSlider
                      ? "Hide  Path Playback"
                      : "Show Path Playback"}
                  </span>
                </Button>
              </>
            )}

            {/* {
              (object_id !== 'No Info' || shipCategory === 'pink' || shipCategory === "ais-spoofing")
              && (
                <Button
                  className={styles.drawerDataBtn}
                  onClick={() => viewSatelliteImageOnTheMap()}
                >
                  <ReactSVG className={styles.drawerDataBtnIcon} src='./shipActions/setellite-image.svg' />
                  <span className={styles.titleSpan}>
                    {viewSatelliteImage ? 'Hide Satellite Image' : 'View Satellite Image'}
                  </span>
                </Button>
              )
            } */}
            {shipCategory !== "red" && (
              <Button
                className={styles.drawerDataBtn}
                onClick={getSatelliteImageTimeline}
              >
                <div>
                  <SatelliteIcon className={styles.drawerDataBtnMuiIcon} />
                </div>
                <span className={styles.titleSpan}>
                  Satellite Images Timeline
                </span>
              </Button>
            )}
            <Button
              className={styles.drawerDataBtn}
              onClick={onClearSelections}
            >
              <div>
                <HighlightOffIcon className={styles.drawerDataBtnMuiIcon} />
              </div>
              <span className={styles.titleSpan}>Clear Selections</span>
            </Button>
            <Button
              className={styles.drawerDataBtn}
              onClick={showPathPredictionPopUp}
            >
              <div>
                <UpdateIcon className={styles.drawerDataBtnMuiIcon} />
              </div>
              <span className={styles.titleSpan}>
                Show Future Path Prediction
              </span>
            </Button>

            {shipCategory === "footprint" && (
              <Button
                className={styles.drawerDataBtn}
                onClick={showFootPrintHeatmap}
              >
                <div>
                  <LayersIcon className={styles.drawerDataBtnMuiIcon} />
                </div>
                <span className={styles.titleSpan}>
                  {spoofingHeatmapVisible ? "Hide" : "Show"} Spoofing Heatmap
                </span>
              </Button>
            )}

            {shipCategory === "pink" && (
              <Button
                className={styles.drawerDataBtn}
                onClick={showFootPrintSpoofingHeatmap}
              >
                <div>
                  <LayersIcon className={styles.drawerDataBtnMuiIcon} />
                </div>
                <span className={styles.titleSpan}>Show Optical Spoofing</span>
              </Button>
            )}

            {shipCategory === "ais-spoofing" && (
              <Button
                className={styles.drawerDataBtn}
                onClick={showFootPrintSpoofingHeatmap}
              >
                <div>
                  <LayersIcon className={styles.drawerDataBtnMuiIcon} />
                </div>
                <span className={styles.titleSpan}>Show AIS Spoofing</span>
              </Button>
            )}

            {shipCategory !== "red" && (
              <>
                {shipCategory !== "ais-spoofing" && (
                  <Button
                    className={styles.drawerDataBtn}
                    onClick={showSpoofingTimeline}
                  >
                    <div>
                      <TimelineIcon className={styles.drawerDataBtnMuiIcon} />
                    </div>
                    <span className={styles.titleSpan}>
                      {spoofingHeatmapTimelineVisible ? "Hide" : "View"}{" "}
                      Spoofing TimeLine
                    </span>
                  </Button>
                )}

                <Button
                  className={styles.drawerDataBtn}
                  onClick={showCurrentLocation}
                  disabled={progressLoading}
                >
                  <div>
                    <ShareLocationIcon
                      className={styles.drawerDataBtnMuiIcon}
                    />
                  </div>
                  <span className={styles.titleSpan}>
                    {progressLoading
                      ? "Loading..."
                      : (currentLocation ? "Hide" : "View") +
                        " Current Location"}
                  </span>
                </Button>
              </>
            )}
          </div>
        </div>
      </Grid>
    </>
  );
}

export default memo(SelectedShipsTabTwo);
