import React, { useEffect, useState } from "react";
import axios from "axios";

import "./style.scss";
import { DataGrid } from "@mui/x-data-grid";
import { frontendAPIURL } from "../../../../utils";

const SanctionDetails = ({ selectedShips }) => {
  const [details, setDetails] = useState(null)
  useEffect(() => {
    getSanctionDetails();
  }, [selectedShips[selectedShips.length - 1].shipIndex]);

  let shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.synmax_ship_id
  if (!shipId) {
    shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.attribution
  }

  const getSanctionDetails = async () => {
    const token = localStorage.getItem("token");

    let synmaxShipId = ''

    if (selectedShips[selectedShips?.length - 1]?.initialData?.properties
      ?.synmax_ship_id) {
        synmaxShipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties
        ?.synmax_ship_id
      } else {
        synmaxShipId= selectedShips[selectedShips?.length - 1]?.initialData?.properties
        ?.attribution
      }
    const sanctionDetails = await axios.get(
      `${frontendAPIURL}/sanctions?synmax_ship_id=${synmaxShipId}`
    ,{
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    setDetails(sanctionDetails.data.sanctions)
  };
  return (
    <div className="dataBox" id="sanctionDetails">
      {details && (
        <>
          <div className="firstLine">
            <div className="textBlock ">
              <div className="textBlock__title">Program(s):</div>
              <div className="textBlock__value">{details['Program(s)'] ? details['Program(s)'] : 'No Info'}</div>
            </div>
            <div className="textBlock">
              <div className="textBlock__title">Remarks:</div>
              <div className="textBlock__value">{details.Remarks ? details.Remarks : 'No Info'}</div>
            </div>
            <div className="textBlock">
              <div className="textBlock__title">Sanctions List:</div>
              <div className="textBlock__value">{details['Sanctions List'] ? details['Sanctions List'] : 'No Info'}</div>
            </div>
            <div className="textBlock">
              <div className="textBlock__title">Vessel Owner:</div>
              <div className="textBlock__value">{details['Vessel Owner'] ? details['Vessel Owner'] : 'No Info'}</div>
            </div>
          </div>
          <DataGrid rows={details.Identifications.map((shipdata, i) => ({ ...shipdata, id: i }))}
            columns={columns}
            density="compact"
            headerHeight={40}
            hideFooter={true}
            rowHeight={30}
            className="sanctionTable"
            style={{ color: '#fff', marginTop: 10, height: '100%' }}
          />
        </>
      )}
    </div>

  );
};
const columns = [
  { field: 'id', hide: true },
  { field: 'Type', headerName: 'Type', flex: 1 },
  { field: 'ID#', headerName: 'ID#', flex: 1 },
  { field: 'Country', headerName: 'Country' },
  { field: 'Issue Date', headerName: 'Issue Date' },
  { field: 'Expire Date', headerName: 'Expire Date', flex: 1 }
]
export default SanctionDetails;
