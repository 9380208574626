import React, { useContext, memo } from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { GeoJSON } from 'react-leaflet';
import L from 'leaflet';

import { convertArrToGeojson, pointToLayer, getShipDetails } from '../../utils';
import { TheiaContext } from '../../Theia';

const AisSpoofing = () => {
  const { selectedShips, setSelectedShips,setSatelliteData,setShowPathPredictionDialog,setSpoofingTimelineShips, setCurrentLocationData, setTileLayerUrl, AisSpoofingShips, setPathInterpolationData, setViewPathInterpolation,setViewSatelliteImage,setShowSpinner, setIsSnackbarOpen, colorArr, setColorArr, setHaveShipInfo, setSelectShipWaiting, setSatelliteTimelineData } = useContext(TheiaContext);

    const onEachFeaturePoint = (feature, layer) => {
    layer.on({
      click: async function (e) {
        console.log(feature, "<===feature");
        setSelectShipWaiting(true) //for disable pointer when ship data 
        setTileLayerUrl(null)
        setPathInterpolationData(null)
        setViewSatelliteImage(false)
        setViewPathInterpolation(false)
        setShowPathPredictionDialog(false)
        setSpoofingTimelineShips([])
        getShipDetails(feature, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, 'ais-spoofing')
        setSatelliteData({geojson:null, key: parseInt(Math.random() * 10000)})
        setSatelliteTimelineData({ geojson: null, key: parseInt(Math.random() * 10000) })
        setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
      },
      mouseover: () => {
        const {destination, name} = feature.properties;
        layer.bindPopup(
          `<div>
              <div>Destination: ${destination}</div>
              <div>Name: ${name}</div>
          </div>`);
        layer.openPopup()
      },
      mouseout: () => {
        layer.closePopup()
      }
    });
  }


  let shipIndex = 0

  if(selectedShips.length > 0) {
    shipIndex = selectedShips[selectedShips.length - 1].shipIndex
  }

  if(selectedShips.length > 0 && selectedShips[selectedShips.length - 1].path) {
    shipIndex = shipIndex + selectedShips[selectedShips.length - 1].path.length
  }
  
  return (
    <>
        {AisSpoofingShips.ships.length > 0 && <MarkerClusterGroup
          disableClusteringAtZoom={7}
          maxClusterRadius={40}
          iconCreateFunction={function (cluster) {
            return L.divIcon({
              html: cluster.getChildCount(),
              className: 'pinkCluster',
              // iconSize: L.point(40, 40),
            });
          }}
        >
           <GeoJSON
            key={'aisSpoofing' + AisSpoofingShips.key + selectedShips.length+ shipIndex}
            data={convertArrToGeojson(AisSpoofingShips.ships)}
          pointToLayer={(feature, latlng) => pointToLayer(feature, latlng, 'ais-spoofing')}
            onEachFeature={onEachFeaturePoint}
          />
        </MarkerClusterGroup>}
    </>
  )
}

export default memo(AisSpoofing)
