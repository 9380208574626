import React, { memo, useContext, useState } from 'react'
import axios from 'axios';
import Draggable from 'react-draggable';

import { Dialog, Select, MenuItem, IconButton, Snackbar, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from "@mui/lab/LoadingButton";

import { TheiaContext } from '../../../../Theia';
import { clientCordinatesObj } from '../../../Utils/Common';
import { frontendAPIURL } from '../../../../utils';

const FuturePathPredictionDialog = ({
  futurePathPredictionValues, setFuturePathPredictionValues
}) => {
  const {
    setZindex, zIndex, showPathPredictionDialog, setShowPathPredictionDialog, setProgressStatus, setProgressValue, setResultUrl,
    setPathPredictionStatusUrl, setIntervalId
  } = useContext(TheiaContext)
  const [futurePathDialog, setFuturePathDialog] = useState(clientCordinatesObj)
  const [predictionStarting, setPredictionStarting] = useState(false)
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
  const [isValuesMissing, setIsValuesMissing] = useState(false)

  const dropFuturePathHandler = (e, data) => {
    setFuturePathDialog({
      clientX: data.x,
      clientY: data.y
    })
  }

  const updateFuturePathPredictionValuesObj = (key, value) => {
    setFuturePathPredictionValues({ ...futurePathPredictionValues, [key]: value })
  }

  const checkStatus = async (task_id) => {
    const token = localStorage.getItem('token')
    try {
      let res;
        res = await axios.post(`${frontendAPIURL}/future_location` ,{action: "progress", task_id},{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
      setProgressStatus(res.data.message)
      const progress = parseInt(res?.data?.progress)
      setProgressValue(progress)
      if (progress === 100) {
        if(res.data.status === 'Failed') {
          setResultUrl('Failed')
        } else {
          setResultUrl(task_id)
        }
      }
    } catch (err) {
      console.log(err, ': err')
    }
  }

  const loadFuturePathPrediction = async () => {
    const token = localStorage.getItem('token')
    const { lat, lon, length, heading, ship_type, speed } = futurePathPredictionValues;

    if(lat&& lon && length && heading && ship_type && speed) {
      const newPathPredictionObject = {
        action: "start",
        lat: parseFloat(lat),
        lon: parseFloat(lon),
        length: parseFloat(length),
        heading: parseFloat(heading),
        speed: parseFloat(speed),
        ship_type: ship_type.toLowerCase()
      }
  
      try {
        setPredictionStarting(true)
        let res;
        
        res = await axios.post(`${frontendAPIURL}/future_location`, newPathPredictionObject, {
          headers: {
            Authorization: 'Bearer ' + token,
          }
        })
        setPredictionStarting(false)
        setIsSnackBarOpen(true)
        setShowPathPredictionDialog(false)
        if (res?.data?.task_id) {
          setPathPredictionStatusUrl(res?.data?.task_id)
          // setInterval(checkStatus, 10000)
          const intervalInstance = setInterval(function () { checkStatus(res?.data?.task_id); }, 10000);
          setIntervalId(intervalInstance)
        }
      } catch (err) {
        setPredictionStarting(false)
      }
    } else {
      setIsValuesMissing(true)
    }

   
  }

  return (
    <Box>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackBarOpen(false)}
        message={"Path Prediction Process Started"}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        className="greenSnack"
      />
      <Snackbar
        open={isValuesMissing}
        autoHideDuration={6000}
        onClose={() => setIsValuesMissing(false)}
        message={"Please Add All The Values"}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        className="redSnack"
      />
      <Draggable
        axis="both"
        handle=".futurePath-Dialog"
        defaultPosition={{ x: 0, y: 0 }}
        onDrag={dropFuturePathHandler}
        position={{ x: futurePathDialog.clientX, y: futurePathDialog.clientY }}
        bounds="body"
        onStart={() =>
          setZindex({
            dropPin: false,
            pathSlider: false,
            attribution: false,
            satelliteImage: false,
            extendedPath: false,
            polygonSlider: false,
            polygonDataTable: false,
            futurePathModal: true,
            futurePathSlider: false,
            spoofingTimelineSlider: false,
            portsOfCallSlider: false,
          })
        }
      >
        <Dialog
          className={`${
            zIndex.futurePathModal ? "zindex-true" : "zindex-false"
          }`}
          open={showPathPredictionDialog}
          onClose={() => setShowPathPredictionDialog(false)}
          classes={{
            root: "MuiDialog-root pathPredictionDialog futurePath-Dialog",
          }}
        >
          <div className="pathPredictionDialog__contentBox">
            <IconButton
              onClick={() => setShowPathPredictionDialog(false)}
              style={{ position: "absolute", right: 0, top: 15 }}
            >
              <CloseIcon />
            </IconButton>
            <div className="pathPredictionDialog__heading">
              Edit Future Path Prediction Parameters
            </div>

            <div className="pathPredictionDialog__parameters">
              <div className="pathPredictionDialog__input">
                <div className="pathPredictionDialog__inputHeading">
                  Latitude
                </div>
                <input
                  type="text"
                  placeholder="Latitude"
                  value={futurePathPredictionValues.lat}
                  onChange={(e) =>
                    updateFuturePathPredictionValuesObj("lat", e.target.value)
                  }
                />
              </div>
              <div className="pathPredictionDialog__input">
                <div className="pathPredictionDialog__inputHeading">
                  Longitude
                </div>
                <input
                  type="text"
                  placeholder="Longitude"
                  value={futurePathPredictionValues.lon}
                  onChange={(e) =>
                    updateFuturePathPredictionValuesObj("lon", e.target.value)
                  }
                />
              </div>
              <div className="pathPredictionDialog__input">
                <div className="pathPredictionDialog__inputHeading">Speed</div>
                <input
                  type="text"
                  placeholder="Speed"
                  value={futurePathPredictionValues.speed}
                  onChange={(e) =>
                    updateFuturePathPredictionValuesObj("speed", e.target.value)
                  }
                />
              </div>
              <div className="pathPredictionDialog__input">
                <div className="pathPredictionDialog__inputHeading">Length</div>
                <input
                  type="text"
                  placeholder="Length"
                  value={futurePathPredictionValues.length}
                  onChange={(e) =>
                    updateFuturePathPredictionValuesObj(
                      "length",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="pathPredictionDialog__input">
                <div className="pathPredictionDialog__inputHeading">
                  Heading
                </div>
                <input
                  type="text"
                  placeholder="Heading"
                  value={futurePathPredictionValues.heading}
                  onChange={(e) =>
                    updateFuturePathPredictionValuesObj(
                      "heading",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="pathPredictionDialog__input">
                <div className="pathPredictionDialog__inputHeading">
                  Ship Type
                </div>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={futurePathPredictionValues.ship_type}
                  onChange={(e) =>
                    updateFuturePathPredictionValuesObj(
                      "ship_type",
                      e.target.value
                    )
                  }
                  style={{
                    fontSize: "1.5rem",
                  }}
                >
                  <MenuItem value={"none"}>None</MenuItem>
                  <MenuItem value={"Cargo"}>Cargo</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                  <MenuItem value={"Tanker"}>Tanker</MenuItem>
                </Select>
              </div>
            </div>
            <LoadingButton
              loading={predictionStarting}
              variant="contained"
              onClick={loadFuturePathPrediction}
            >
              View Path Prediction
            </LoadingButton>
          </div>
        </Dialog>
      </Draggable>
    </Box>
  );
}

export default memo(FuturePathPredictionDialog);
