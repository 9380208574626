import React, { memo, useContext, useEffect, useState } from 'react'
import axios from 'axios';
import Draggable from 'react-draggable';
import DatePicker from "react-datepicker";
import moment from 'moment';

import { Box, Dialog, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from "@mui/lab/LoadingButton";

import { TheiaContext } from '../../../../Theia';

const REACT_APP_ENV = process.env.REACT_APP_ENV;

const CurrentLocation = ({
  extendedPathDialogPosition, dragExtendedPathEventHandler, currentLocationPathDialog, setCurrentLocationExtendedPathDialog,
  isPathLoading, setIsPathLoading, shipId, selectedShipsData
}) => {
  const { zIndex, setZindex, setProgressLoading, setCurrentLocation, setCurrentLocationData, setSpoofingHeatmapVisible, isAbortController, setSpoofingHeatmapTimelineVisible } = useContext(TheiaContext)
  const [isCurrentLocationSnackBarOpen, setIsCurrentLocationSnackBarOpen] = useState(false)
  const [currentLocationStartDate, setCurrentLocationStartDate] = useState(new Date(moment(new Date()).subtract(1, 'days')));
  const { mmsi } = selectedShipsData.static

  useEffect(() => {
    setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) });
    setSpoofingHeatmapVisible(false)
    setCurrentLocation(false)
    setSpoofingHeatmapTimelineVisible(false)
  }, [currentLocationStartDate, shipId, setCurrentLocationData, setSpoofingHeatmapVisible, setCurrentLocation, setSpoofingHeatmapTimelineVisible])

  const viewCurrentLocation = async () => {
    const startDate = moment.utc(`${moment(currentLocationStartDate).format('YYYY-MM-DD')} 00:00:00`).format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment.utc(`${moment(currentLocationStartDate).format('YYYY-MM-DD')} 23:59:59`).format('YYYY-MM-DD HH:mm:ss');

    try {
      setIsPathLoading(true);
      setProgressLoading(true)
      setIsCurrentLocationSnackBarOpen(true)

      setTimeout(() => {
        setIsPathLoading(false);
        setCurrentLocationExtendedPathDialog(false)
      }, 2000)
      const token = localStorage.getItem('token')

      const computeUrl = REACT_APP_ENV === 'dev' ? 'https://frontend-api-j75nm34yfa-uc.a.run.app/ship_location' : 'https://frontend-api-y5rlib2gfa-uc.a.run.app/ship_location';

      await axios.post(`${computeUrl}`, {
        "mmsi": mmsi.toString(),
        "start": startDate,
        "end": endDate
      }, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        signal: isAbortController.signal
      }).then((res) => {
        setIsPathLoading(false);
        setCurrentLocation(true);
        setCurrentLocationData({ geojson: { ...res?.data?.response.geojson, date: currentLocationStartDate, shipId }, key: parseInt(Math.random() * 10000) });
        setCurrentLocationExtendedPathDialog(false);
        setIsCurrentLocationSnackBarOpen(false)
        setProgressLoading(false)
      });
    } catch (error) {
      setIsPathLoading(false);
      setCurrentLocationExtendedPathDialog(false);
    }
  };


  return (
    <Box>
      <Snackbar
        open={isCurrentLocationSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setIsCurrentLocationSnackBarOpen(false)}
        message={"Current Location Process Started"}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        className="greenSnack"
      />
      <Draggable
        axis="both"
        handle=".extendedPathDraggable"
        defaultPosition={{ x: 0, y: 0 }}
        position={{ x: extendedPathDialogPosition.clientX, y: extendedPathDialogPosition.clientY }}
        bounds="body"
        onDrag={dragExtendedPathEventHandler}
        onStart={() => setZindex({
          dropPin: false,
          pathSlider: false,
          attribution: false,
          satelliteImage: false,
          extendedPath: true,
          polygonSlider: false,
          polygonDataTable: false,
          futurePathModal: false,
          futurePathSlider: false,
          spoofingTimelineSlider: false,
          portsOfCallSlider: false,
        })}
      >
        <Dialog
          open={currentLocationPathDialog}
          onClose={() => setCurrentLocationExtendedPathDialog(false)}
          style={{ right: 'auto' }}
          className={`${zIndex.extendedPath ? 'zindex-true' : 'zindex-false'} extendedPathDraggable`}

        >

          <div className="extendedPath">
            <div className="extendedPath__heading">
              Pick a date for which to estimate the vessel's location:
            </div>
            <IconButton onClick={() => setCurrentLocationExtendedPathDialog(false)} style={{ position: 'absolute', right: 2, top: 8 }} >
              <CloseIcon />
            </IconButton>
            <div className="extendedPath__content">
              <div className="extendedPath__dates">
                <div className="extendedPath__date extendedPath__date--1">
                  <div className="extendedPath__date--text">Select Date</div>
                  <DatePicker
                    selected={currentLocationStartDate}
                    onChange={(date) => {
                      setCurrentLocationStartDate(date);
                    }}
                  />
                </div>
              </div>

              <LoadingButton
                size="small"
                color='success'
                variant="contained"
                className="button"
                onClick={viewCurrentLocation}
                loading={isPathLoading}
                style={{ marginTop: 15 }}
              >
                View Current Location
              </LoadingButton>
            </div>
          </div>
        </Dialog>
      </Draggable>
    </Box>
  )
}

export default memo(CurrentLocation);
