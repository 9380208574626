import React, { useContext, useEffect, useRef } from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { GeoJSON } from 'react-leaflet';
import L from 'leaflet';

import { TheiaContext } from '../../Theia';

import { convertArrToGeojson, pointToLayer, getShipDetails } from '../../utils';

const OrangeDetections = ({ orangeShipDetections }) => {
  const { setHaveShipInfo, selectedShips, setSelectedShips, setShowPathPredictionDialog,setSatelliteData,setCurrentLocationData, setSpoofingTimelineShips, setTileLayerUrl, selectedPolygons, setPathInterpolationData, setViewPathInterpolation, setViewSatelliteImage, setShowSpinner, setIsSnackbarOpen, colorArr, setColorArr, setSelectShipWaiting, setSatelliteTimelineData } = useContext(TheiaContext);
 
  const onEachFeaturePoint = (feature, layer) => {
    layer.on({
      click: async function (e) {
        setSelectShipWaiting(true)
        setTileLayerUrl(null)
        setPathInterpolationData(null)
        setViewSatelliteImage(false)
        setViewPathInterpolation(false)
        setSpoofingTimelineShips([])
        setShowPathPredictionDialog(false)
        setSatelliteData({geojson:null, key: parseInt(Math.random() * 10000)})
        setSatelliteTimelineData({ geojson: null, key: parseInt(Math.random() * 10000) })
        setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
        getShipDetails(feature, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, 'orange')
      },
      mouseover: () => {
        console.log(feature, 'feature')
        const { destination, name } = feature.properties;
        layer.bindPopup(
          `<div>
              <div>Destination: ${destination ? destination : "-"}</div>
              <div>Name: ${name}</div>
          </div>`);
        layer.openPopup()
      },
      mouseout: () => {
        layer.closePopup()
      }
    });
  }

  
  let shipIndex = 0

  if(selectedShips.length > 0) {
    shipIndex = selectedShips[selectedShips.length - 1].shipIndex
  }

  if(selectedShips.length > 0 && selectedShips[selectedShips.length - 1].path) {
    shipIndex = shipIndex + selectedShips[selectedShips.length - 1].path.length
  }
  
  return (
    <>
      {orangeShipDetections.ships.length > 0 && (
        <MarkerClusterGroup
        disableClusteringAtZoom={9}
          maxClusterRadius={40}
          iconCreateFunction={function (cluster) {
            return L.divIcon({
              html: cluster.getChildCount(),
              className: 'orangecluster',
              // iconSize: L.point(40, 40),
            });
          }}
        >
          <GeoJSON
            key={'orangeShips' + orangeShipDetections.key + selectedShips.length + selectedPolygons.length+ shipIndex}
            data={convertArrToGeojson(orangeShipDetections.ships)}
            pointToLayer={(feature, latlng) => pointToLayer(feature, latlng, 'orange')}
            onEachFeature={onEachFeaturePoint}
          />
        </MarkerClusterGroup>
      )}
    </>
  )
}

export default OrangeDetections
