import React, { useContext, useEffect, useRef } from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { GeoJSON } from 'react-leaflet';
import L from 'leaflet';

import { convertArrToGeojson, pointToLayer, getShipDetails } from '../../utils';
import { TheiaContext } from '../../Theia';
import { ShipRenderContext } from './AllRenderedShips'

const AisShips = ({aisShips}) => {
  const { selectedShips, setSelectedShips, setShowPathPredictionDialog,setTileLayerUrl,setSatelliteData, setCurrentLocationData, setSpoofingTimelineShips, setPathInterpolationData, setViewPathInterpolation,setViewSatelliteImage,setShowSpinner, setIsSnackbarOpen, colorArr, setColorArr, setHaveShipInfo, setSelectShipWaiting, setSatelliteTimelineData } = useContext(TheiaContext);

  const onEachFeaturePoint = (feature, layer) => {
    layer.on({
      click: async function (e) {
        setSelectShipWaiting(true) //for disable pointer when ship data 
        setTileLayerUrl(null)
        setPathInterpolationData(null)
        setViewSatelliteImage(false)
        setViewPathInterpolation(false)
        setSpoofingTimelineShips([])
        setShowPathPredictionDialog(false)
        setSatelliteData({geojson:null, key: parseInt(Math.random() * 10000)})
        setSatelliteTimelineData({ geojson: null, key: parseInt(Math.random() * 10000) })
        setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
        getShipDetails(feature,setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, 'ais')
      },
      mouseover: () => {
        const {destination, name} = feature.properties;
        layer.bindPopup(
          `<div>
              <div>Destination: ${destination}</div>
              <div>Name: ${name}</div>
          </div>`);
        layer.openPopup()
      },
      mouseout: () => {
        layer.closePopup()
      }
    });
  }

  let shipIndex = 0

  if(selectedShips.length > 0) {
    shipIndex = selectedShips[selectedShips.length - 1].shipIndex
  }

  if(selectedShips.length > 0 && selectedShips[selectedShips.length - 1].path) {
    shipIndex = shipIndex + selectedShips[selectedShips.length - 1].path.length
  }

  return (
    <>
        {aisShips.ships.length > 0 && <MarkerClusterGroup
          disableClusteringAtZoom={7}
          maxClusterRadius={40}
          iconCreateFunction={function (cluster) {
            return L.divIcon({
              html: cluster.getChildCount(),
              className: 'greencluster',
              iconSize: L.point(40, 40),
            });
          }}
        >
           <GeoJSON
            key={'aisships' + aisShips.key + selectedShips.length + shipIndex}
            data={convertArrToGeojson(aisShips.ships)}
            pointToLayer={(feature, latlng) => pointToLayer(feature, latlng, 'ais')}
            onEachFeature={onEachFeaturePoint}
          />
        </MarkerClusterGroup>}
    </>
  )
}

export default AisShips
