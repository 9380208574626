import React from "react";
import L from "leaflet";
import { GeoJSON } from "react-leaflet";
import { allPorts } from "../../../geojsons/portData";
import { aisStations } from "../../../geojsons/aisStationData";
import { globalEEZ } from "../../../geojsons/eez";
import { submarineCables } from "../../../geojsons/submarine_cables";
import { elecLink } from '../../../geojsons/elecLink';
import { nemoLink } from '../../../geojsons/nemoLink';
import { NSL } from '../../../geojsons/NSL';
import { IFA2 } from '../../../geojsons/IFA2';
import { IFA } from '../../../geojsons/IFA';
import { britNed } from '../../../geojsons/britNed';

var portIcon = L.icon({
  iconUrl: "./port.png",
  iconSize: [35, 35],
});
var aisStationIcon = L.icon({
  iconUrl: "./aisStation.png",
  iconSize: [35, 35],
});

const onEachPort = (feature, layer) => {
  layer.bindPopup(`${feature.properties.portname} port`);
};

const portsPointToLayer = (feature, latlng) => {
  return L.marker(latlng, {
    icon: portIcon,
  });
};

const aisStationPointToLayer = (feature, latlng) => {
  return L.marker(latlng, {
    icon: aisStationIcon,
  });
};

const onEachAisStation = (feature, layer) => {
  layer.bindPopup(`${feature.properties.location} (AIS Station)`);
};

const onEachEEZ = (feature, layer) => {
  const { Country } = feature.properties;
  layer.bindPopup(`<div>${Country}</div>`);
};

const onEachSubCable = (feature, layer) => {
  const { name, length, owners } = feature.properties;
  layer.bindPopup(`<div>
   <div style="font-size: 16px;font-weight: bold;">Submarine Cable</div>
   <div>Name: ${name}</div>
   <div>Length: ${length}</div>
   <div>Owners: ${owners}</div>
  </div>`);
};

const onEachCableLayer = (feature, layer, name) => {
  layer.bindPopup(`<div>
   <div style="font-size: 16px;font-weight: bold;">${name}</div>
  </div>`);
};

const MinorFeatures = ({ switches: { ports, infrastructure, territories, submarineCablesVisible } }) => {
  return (
    <>
      {ports && <GeoJSON data={allPorts} pointToLayer={portsPointToLayer} onEachFeature={onEachPort} />}
      {infrastructure && (
        <GeoJSON data={aisStations} pointToLayer={aisStationPointToLayer} onEachFeature={onEachAisStation} />
      )}
      {territories && <GeoJSON data={globalEEZ} onEachFeature={onEachEEZ} />}
      {submarineCablesVisible && (
        <>
        <GeoJSON data={submarineCables} onEachFeature={onEachSubCable} style={{ color: '#a8a8a8' ,weight: 5}}/>
        <GeoJSON data={elecLink} onEachFeature={(a,b) => onEachCableLayer(a,b, 'ElecLink Cable')} style={{ color: '#168e9e', weight: 5}}/>
        <GeoJSON data={nemoLink} onEachFeature={(a,b) => onEachCableLayer(a,b, 'Nemo Link Cable V2')} style={{ color: '#38e4ae',weight: 5}}/>
        <GeoJSON data={NSL} onEachFeature={(a,b) => onEachCableLayer(a,b, 'NSL Cable')} style={{ color: '#F85348',weight: 5}}/>
        <GeoJSON data={IFA} onEachFeature={(a,b) => onEachCableLayer(a,b, 'IFA Cable')} style={{ color: 'pink',weight: 5}}/>
        <GeoJSON data={IFA2} onEachFeature={(a,b) => onEachCableLayer(a,b, 'IFA2 Cable')} style={{ color: 'purple',weight: 5}}/>
        <GeoJSON data={britNed} onEachFeature={(a,b) => onEachCableLayer(a,b, 'BritNed Cable V2')} style={{ color: '#fa9f42',weight: 5}}/>
        </>
      ) }
      
    </>
  );
};

export default MinorFeatures;
