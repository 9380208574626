import { MapControl, withLeaflet } from "react-leaflet";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.polylinemeasure/Leaflet.PolylineMeasure.css";
import "leaflet.polylinemeasure/Leaflet.PolylineMeasure";

class PolylineMeasure extends MapControl {
  createLeafletElement() {
    return L.control.polylineMeasure({
      position: "topright",
      unit: "metres",
      showBearings: true,
      clearMeasurementsOnStop: false,
      showClearControl: true,
      showUnitControl: true,
      arrow: {                        // Styling of the midway arrow 
        color: '#000',              // Color of the arrow
    },
    });
  }

  componentDidMount() {
    const { map } = this.props.leaflet;
    const polylineMeasure = this.leafletElement;
    polylineMeasure.addTo(map);
  }
}

export default withLeaflet(PolylineMeasure);
