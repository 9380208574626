import React, { useEffect, useContext, useState } from 'react'
import { FeatureGroup, Marker, Tooltip } from 'react-leaflet';
import Drawtool from '../Drawtool';
import * as turf from '@turf/turf';
import CloseIcon from '@mui/icons-material/Close';

import { TheiaContext } from '../../Theia';
import moment from 'moment';

const DrawnPolygonShips = ({ setAisShips }) => {

  const { filters, setFilters, selectedPolygons, setSelectedPolygons, selectedPolylines, setSelectedPolylines, setCreatedCirclesObjs, createdCirclesObjs, setDropdownPolygonId, deletePolygonIds, setDeletePolygonIds, setPurpleShips, loadShipsForPolygon, getDetectedData, date, getAisInPolygonArea, mapRef } = useContext(TheiaContext);
  const [mapLayer, setMapLayer] = useState([])
  const [layerName, setLayerName] = useState("")



  useEffect(() => {
    if (selectedPolygons.length > 0 || createdCirclesObjs.length > 0 || selectedPolylines.length > 0) {
      let allAisShipsToLoad = []
      selectedPolygons.forEach(polygon => {
        if (polygon.ships) {
          allAisShipsToLoad = allAisShipsToLoad.concat(polygon.ships)
        }
      })
      console.log(allAisShipsToLoad, 'allAisShipsToLoad')
      setAisShips({ ships: allAisShipsToLoad, initialShips: allAisShipsToLoad, key: parseInt(Math.random() * 10000) })
      setPurpleShips({ ships: [], initialShips: [], key: parseInt(Math.random() * 10000) })
      setSelectedPolylines(selectedPolylines.filter(polyline => !deletePolygonIds.includes(`${polyline.id}`)))
      setCreatedCirclesObjs(createdCirclesObjs.filter(circle => !deletePolygonIds.includes(`${circle.id}`)))
      const filteredData = selectedPolygons.filter(polygon => !deletePolygonIds.includes(`${polygon.id}`))
      const updatedData = filteredData.map((f, index) => {
        const name = f.layerType === 'rectangle' ? 'Area' : 'Polygon';
        return { ...f, name: `${name } ${index + 1}` }
      });
      setSelectedPolygons(updatedData);
    }
  }, [deletePolygonIds])

  // useEffect(() => {
  //   if(selectedPolygons.length === 0) {
  //    if(deletePolygonIds.length > 0) {
  //     // We don't want to do this on the first render where selectedPolygons' length is actually 0
  //     // We only do it when selectedPolygons' becomes 0 after we delete them from the map
  //      setAisShips({ ships: [], initialShips: [], key: parseInt(Math.random() * 10000)})
  //    }
  //   } else {
  //     let allAisShipsToLoad = []
  //     selectedPolygons.forEach(polygon => {
  //       if(polygon.ships) {
  //         allAisShipsToLoad = allAisShipsToLoad.concat(polygon.ships)
  //       }
  //     })
  //     setAisShips({ ships: allAisShipsToLoad, initialShips: allAisShipsToLoad, key: parseInt(Math.random() * 10000)})
  //   }

  // },[selectedPolygons])

  const drawToolOnCreate = (e) => {
    const { layerType } = e
setFilters({...filters, ais:true})
    setMapLayer([
      ...mapLayer,
      e.layer
    ])
    if (layerType === 'polygon' || layerType === 'rectangle') {
      const points = e.layer._latlngs[0].map(item => [item.lng, item.lat])

      let polygon = turf.polygon([[...points, [points[0][0], points[0][1]]]]);
      let area = turf.area(polygon);
      const name = layerType === 'rectangle' ? 'Area' : 'Polygon';

      if (selectedPolygons.length === 0) {
        setSelectedPolygons([{ id: e.layer._leaflet_id, name: `${name} 1`, points, area, layerType }])
        setDropdownPolygonId(e.layer._leaflet_id)
      } else {
        setSelectedPolygons([...selectedPolygons, { id: e.layer._leaflet_id, name: `${name} ${selectedPolygons.length + 1}`, points, area, layerType }])
        setDropdownPolygonId(e.layer._leaflet_id)
      }
      if (moment(date.$d).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        console.log("todayyyyy")
        getAisInPolygonArea(true, points)
        getDetectedData(points)
      } else {
        console.log("not todayyyyy");
        getDetectedData(points)
      }
    }

    if (layerType === 'polyline') {
      const latlngPoints = e.layer._latlngs
      console.log(e, 'e')
      const points = latlngPoints.map(item => [item.lng, item.lat])

      let polylineLength = 0
      for (let i = 0; i < points.length - 1; i++) {
        var line = turf.lineString([points[i], points[i + 1]]);
        var length = turf.length(line);
        polylineLength += length
      }

      if (points.length > 2) {
        let polygon = turf.polygon([[...points, [points[0][0], points[0][1]]]]);
        let area = turf.area(polygon);

        if (selectedPolylines.length === 0) {
          setSelectedPolylines([{ id: e.layer._leaflet_id, type: 'polyline', name: 'polyline 1', points, area, polylineLength }])
        } else {
          setSelectedPolylines([...selectedPolylines, { id: e.layer._leaflet_id, type: 'polyline', name: `polyline ${selectedPolygons.length + 1}`, points, area, polylineLength }])
        }
      } else {
        // Just Two Points
        if (selectedPolylines.length === 0) {
          setSelectedPolylines([{ id: e.layer._leaflet_id, type: 'polyline', name: 'polyline 1', points, polylineLength }])
        } else {
          setSelectedPolylines([...selectedPolylines, { id: e.layer._leaflet_id, type: 'polyline', name: `polyline ${selectedPolygons.length + 1}`, points, polylineLength }])
        }
      }

    }

    if (layerType === 'circle') {
      if (createdCirclesObjs.length === 0) {
        setCreatedCirclesObjs([{ id: e.layer._leaflet_id, type: 'circle', name: 'radius 1', radius: e.layer._mRadius, center: e.layer._latlng }])
      } else {
        setCreatedCirclesObjs([...createdCirclesObjs, { id: e.layer._leaflet_id, type: 'circle', name: `radius ${createdCirclesObjs.length + 1}`, radius: e.layer._mRadius, center: e.layer._latlng }])
      }
    }


  }



  const polylineCreated = async (e) => {
    console.log(e, 'e')
    const latlngPoints = e.layer._latlngs

    if (Array.isArray(latlngPoints)) {
      // polygon
      const points = latlngPoints.map(item => [item.lng, item.lat])
      var polygon = turf.polygon([[...points, [points[0][0], points[0][1]]]]);
      var area = turf.area(polygon);


      if (selectedPolygons.length === 0) {
        setSelectedPolygons([{ id: e.layer._leaflet_id, type: 'polygon', name: 'polygon 1', points, area }])
        setDropdownPolygonId(e.layer._leaflet_id)
      } else {
        setSelectedPolygons([...selectedPolygons, { id: e.layer._leaflet_id, type: 'polygon', name: `polygon ${selectedPolygons.length + 1}`, points, area }])
      }
    } else {
      //circle  
      if (createdCirclesObjs.length === 0) {
        setCreatedCirclesObjs([{ id: e.layer._leaflet_id, type: 'circle', name: 'circle 1', radius: e.layer._mRadius, center: e.layer._latlng }])
      } else {
        setCreatedCirclesObjs([...createdCirclesObjs, { id: e.layer._leaflet_id, type: 'circle', name: `circle ${createdCirclesObjs.length + 1}`, radius: e.layer._mRadius, center: e.layer._latlng }])
      }
    }

  }

  const polygonDeleted = (e) => {
    const map = mapRef.current.leafletElement;

    if(e?.layers){
      setDeletePolygonIds(Object.keys(e.layers._layers))
    } else {
      setDeletePolygonIds([e.id.toString()])
      mapLayer.map((ele)=>{
        if(ele._leaflet_id === e.id){
          map.removeLayer(ele);
        }
      })
    }
  }




  useEffect(() => {
    console.log(createdCirclesObjs, 'createdCirclesObjs')
  }, [createdCirclesObjs])
  return (
    <>
      {selectedPolygons.length > 0 && selectedPolygons.map((polygon, i) => 
      (
        <Marker key={i} position={reversePoints(polygon.points[1])}>
          <Tooltip interactive={true} direction="right" offset={[0, 0]} opacity={1} permanent>
              <CloseIcon
                style={{ cursor: 'cursor', fontSize:"20px", float:"right" }}
                onClick={() => polygonDeleted(polygon)}
              />
              <span style={{ fontSize: 15 }}>
                {polygon.name}
              </span> 
              <br /> 
              {numberWithCommas(polygon.area / 1000000).split('.')[0]} km &#178;
          </Tooltip>
        </Marker>
      ))}

      {selectedPolylines.length > 0 && selectedPolylines.map((polyline, i) => 
      (<Marker key={i} position={reversePoints(polyline.points[1])}>
        <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent> 
        <span style={{ fontSize: 15 }}>{polyline.name}</span> 
        <br /> Length: {return3DecimalPoints(polyline.polylineLength)} km
        {}
        <br /> {polyline.area && (<span>Area: {return3DecimalPoints(polyline.area / 1000000)} km &#178;</span>)} 
        </Tooltip>
      </Marker>))}

      {createdCirclesObjs.length > 0 && createdCirclesObjs.map((circle, i) => (<Marker key={i} position={[circle.center.lat, circle.center.lng]}>
        <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent> <span style={{ fontSize: 15 }}>{circle.name}</span> <br /> {numberWithCommas(circle.radius / 1000).split('.')[0] + '.' + (circle.radius / 1000).toString().split('.')[1].substr(0, 2)} km</Tooltip>
      </Marker>))}
      <FeatureGroup>
        <Drawtool
          position="topright"
          onEdited={(e) => console.log(e)}
          onCreated={drawToolOnCreate}
          onDeleted={polygonDeleted}
          draw={{
            polyline: true,
            marker: false,
            circle: true,
            polygon: true,
            rectangle: true,
            circlemarker: false,
          }}
          edit={{ edit: false }}
        />
      </FeatureGroup>
    </>
  )
}

const  numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const return3DecimalPoints = (numberString) => {
  const arrSplit = numberString.toString().split('.')
  return numberWithCommas(arrSplit[0]) + '.' +  arrSplit[1].substr(0,2) 
}


const reversePoints = (arr) => {
  return [arr[1], arr[0]]
}

export default DrawnPolygonShips