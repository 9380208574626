import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import Draggable from "react-draggable";
import { Slider, Select, MenuItem, TextField, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { TheiaContext } from "../Theia";
import { filterDuplicateShips } from "./AllRenderedShips/utils";
import { frontendAPIURL, getObservedShipsRequest, getSatelliteImageRequest } from "../utils";
import { SatelliteImagesTimelineDialog } from "../DrawerData/SatelliteImagesTimelineDialog";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const PolygonSlider = () => {
  const { selectedPolygons, selectedShips, setShowSpinner, similarDetections, footprintShips, spoofingDetections,
    setSimilarDetections, dropdownPolygonId, polygonSliderValue, setIsFiltersChanged, setPolygonAisSliderShipsKey, setPolygonSliderValue, startEpochDate, setStartEpochDate, isOpenDrawerData, filterAisData, setFilterAisData, haveShipsSelected, setDropdownPolygonId, startDate, setStartDate, endDate, setEndDate, setAisShips, setPurpleShips, date, setShipDataDialog, getDetectedData, getAisInPolygonArea, setZindex, zIndex, sanctionedShipsPositionData } = useContext(TheiaContext);

  const [sliderMaxValue, setSliderMaxValue] = useState(0);
  const [isToday, setIsToday] = useState(false)
  const [satelliteImageTimeline, setSatelliteImageTimeline] = useState([])
  const [openSatelliteImagesTimelineDialog, setOpenSatelliteImagesTimelineDialog] = useState(false)
  const [windowWidth, windowHeight] = useWindowSize();
  const [polygonSliderDialogPosition, setPolygonSliderDialogPosition] = useState({
    clientX: null,
    clientY: null
  })

  useEffect(() => {
    setPolygonSliderDialogPosition({
      clientX: -5,
      clientY: -20
    });
  }, [windowWidth, windowHeight])

  const controller = new AbortController();

  const [isAisController, setIsAisController] = useState(new AbortController());

  useEffect(() => {
    const startEpoch = moment
      .utc(
        `${moment(startDate).format("YYYY-MM-DD")} 00:00:00`,
        "YYYY-MM-DD HH:mm:ss"
      )
      .unix();
    const sliderMax =
      (moment
        .utc(
          `${moment(endDate).format("YYYY-MM-DD")} 23:59:59`,
          "YYYY-MM-DD HH:mm:ss"
        )
        .unix() -
        startEpoch) /
      60 /
      15;

    setStartEpochDate(startEpoch);
    setSliderMaxValue(sliderMax);
  }, [startDate, endDate]);

  useEffect(() => {
    if (moment(date.$d).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      console.log("Today")
      setIsToday(true)
    } else {
      console.log("not today");
      setIsToday(false)
    }
  }, [date])

  const findSimilarShips = async () => {
    setShowSpinner(true)
    const token = localStorage.getItem('token')
    const selectedPolygon = selectedPolygons.filter(polygon => polygon.id === dropdownPolygonId)[0]
    let shipId = selectedShips[selectedShips.length - 1].initialData.properties.attribution
    if (selectedShips[selectedShips.length - 1].shipCategory === 'sanction' || selectedShips[selectedShips.length - 1].shipCategory === 'ais' || selectedShips[selectedShips.length - 1].shipCategory === 'ais-spoofing' || selectedShips[selectedShips.length - 1].shipCategory === 'pink' || selectedShips[selectedShips.length - 1].shipCategory === 'footprint') {
      shipId = selectedShips[selectedShips.length - 1].initialData.properties.synmax_ship_id
    }
    const res = await axios.post(`${frontendAPIURL}/ship_search`, {
      "synmax_ship_id": shipId, "id": "frontend", "start": `${moment(startDate).format("YYYY-MM-DD")} 00:00:00`, "end": `${moment(endDate).format("YYYY-MM-DD")} 23:59:59`, "area": { "type": "Polygon", coordinates: [selectedPolygon.points] }
    }
      , {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }).catch(() => {
        setShowSpinner(false)
      })
    await setSimilarDetections(res?.data)

    setShowSpinner(true)

    if (res?.data?.length > 0) {

      const allImgShips = await Promise.all(res?.data?.map(item => {
        return getSatelliteImageRequest(item.object_id)
      }));

      const filterShipImages = allImgShips?.reduce((accumulator, currentValue, currentIndex) => {
        if (!currentValue) return accumulator;

        return [
          ...accumulator,
          {
            ...res?.data[currentIndex],
            signedUrl: currentValue
          }
        ]
      }, [])

      setSatelliteImageTimeline(filterShipImages);
      setShowSpinner(false)
      setOpenSatelliteImagesTimelineDialog(allImgShips?.length > 0 ? true : false)
    } else {
      setShowSpinner(false)
    }
  }

  const findSimilarShipsTimeline = async () => {
    setShowSpinner(true)

    const allImgShips = await Promise.all(similarDetections?.map(item => {
      return getSatelliteImageRequest(item.object_id)
    }));

    const filterShipImages = allImgShips?.reduce((accumulator, currentValue, currentIndex) => {
      if (!currentValue) return accumulator;

      return [
        ...accumulator,
        {
          ...similarDetections[currentIndex],
          signedUrl: currentValue
        }
      ]
    }, [])

    setSatelliteImageTimeline(filterShipImages);
    setShowSpinner(false)
    setOpenSatelliteImagesTimelineDialog(true)
  }

  const selectedPolygon = selectedPolygons.filter(polygon => polygon.id === dropdownPolygonId)[0]

  const loadShipsForPolygon = async () => {
    if (filterAisData) {
      setFilterAisData(false)
    }
    const token = localStorage.getItem('token')
    setShowSpinner(true)

    const res = await axios.post(`${frontendAPIURL}/ais_in_area`,
      {
        id: "frontend",
        start: `${moment(startDate).format("YYYY-MM-DD")} 00:00:00`,
        end: `${moment(endDate).format("YYYY-MM-DD")} 23:59:59`,
        area: { type: "Polygon", coordinates: [selectedPolygon.points] },
      },
      {
        signal: isAisController.signal,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }).catch(() => {
        setShowSpinner(false)
      })
    console.log(res.data, 'res.data')

    const allInitiallyFetchedShips = res.data
    let uniqueShipIds = []
    let purpleShipDetails = []
    allInitiallyFetchedShips.forEach(ship => {
      if (uniqueShipIds.includes(ship.synmax_ship_id)) {
        return;
      } else {
        uniqueShipIds.push(ship.synmax_ship_id)
        purpleShipDetails.push(ship)
      }
    })

    const shipInfoRes = await axios.post(`${frontendAPIURL}/ship_info`,
      { "id": "frontend", "shipids": uniqueShipIds }
      , {
        headers: {
          Authorization: "Bearer " + token,
        },
      }).catch(() => {
        setShowSpinner(false)
      })
    setShowSpinner(false)
    let uniqueShipMmsis = []
    let allUniqueShips = {}
    let polygonPurpleShips = []
    let initialPurpleShips = []
    shipInfoRes.data.static.forEach(ship => {
      if (uniqueShipMmsis.includes(ship.mmsi)) {
        return;
      } else {
        uniqueShipMmsis.push(ship.mmsi)
        let spoofingShips = []
        footprintShips.ships.forEach(spoofingShipData => {
          if (spoofingShipData.synmax_ship_id === ship.synmax_ship_id) {
            spoofingShips = [...spoofingShips, spoofingShipData]
          }
        })
        spoofingDetections.ships.forEach(spoofingShipData => {
          if (spoofingShipData.synmax_ship_id === ship.synmax_ship_id) {
            spoofingShips = [...spoofingShips, spoofingShipData]
          }
        })
        if (spoofingShips.length > 0) {
          console.log(spoofingShips, 'match found')

        }
        allUniqueShips[ship.synmax_ship_id] = { ...ship, spoofingShips }
      }
    })
    uniqueShipIds.forEach((e) => {
      if (allUniqueShips[e]) {
        return;
      } else {
        purpleShipDetails.map((id) => {
          if (id.synmax_ship_id === e) {
            polygonPurpleShips.push(id);
          }
        })

      }
    })

    allInitiallyFetchedShips.forEach((ship) => {
      polygonPurpleShips.map((purple) => {
        if (ship.synmax_ship_id === purple.synmax_ship_id) {
          initialPurpleShips.push(ship)
        }
      })
    })

    let allShipsForPolygon = []
    let allPurpleShips = []

    console.log(allUniqueShips, ':allUniqueShips')
    allInitiallyFetchedShips.forEach(ship => {
      if (allUniqueShips[ship.synmax_ship_id]) {
        const { length, width, ship_type, flag, name, destination, imo } =
          allUniqueShips[ship.synmax_ship_id];

        const isSanctioned = sanctionedShipsPositionData?.some(
          sanctionedShip => sanctionedShip.synmax_ship_id === ship.synmax_ship_id
        );

        console.log(isSanctioned, "isSanctioned")

        allShipsForPolygon.push({
          ...ship,
          staticLength: length,
          staticWidth: width,
          staticType: ship_type,
          staticFlagCode: flag,
          name: name,
          destination: destination ? destination : "-",
          imo,
          sanctionedShip: isSanctioned
        });
      }
    })

    const timestampToFilterStart = startEpochDate
    const timestampToFilterEnd = timestampToFilterStart + (15 * 60)
    const filteredShips = allShipsForPolygon.filter(ship => ((ship.timestamp <= timestampToFilterEnd) && (ship.timestamp > timestampToFilterStart)))
    const purpleShipFilters = initialPurpleShips.filter(ship => ((ship.timestamp <= timestampToFilterEnd) && (ship.timestamp > timestampToFilterStart)))

    const polygonShipsKey = parseInt(Math.random() * 10000)
    setAisShips(aisShips => ({
      ...aisShips,
      ships: filterDuplicateShips(filteredShips),
      initialShips: allShipsForPolygon,
      key: polygonShipsKey
    }))

    setPurpleShips(purpleships => ({
      ...purpleships,
      ships: filterDuplicateShips(purpleShipFilters),
      initialShips: initialPurpleShips,
      key: parseInt(Math.random() * 10000)
    }))

    setPolygonAisSliderShipsKey(polygonShipsKey)
    setIsFiltersChanged(true)
  };

  const handleChange = async (newValue) => {
    setPolygonSliderValue(newValue);
  };

  const getLabel = (value) => {
    return moment
      .utc((startEpochDate + value * 15 * 60) * 1000)
      .format("YYYY-MM-DD HH:mm:ss");
  };

  const cancelRequest = () => {
    controller.abort();
  };

  useEffect(() => {
    if (!selectedPolygon) {
      isAisController.abort();
    }
    const newAbortController = new AbortController();
    setIsAisController(newAbortController)
  }, [selectedPolygon])

  const sliderStepBack = () => {
    if (polygonSliderValue > 0) {
      handleChange(polygonSliderValue - 1);
    }
  };
  const sliderStepForward = () => {
    if (polygonSliderValue < sliderMaxValue) {
      handleChange(polygonSliderValue + 1);
    }
  };

  useEffect(() => {
    if (selectedPolygons.length > 0) {
      let x = selectedPolygons?.filter((e) => e.id === dropdownPolygonId);
      let { points } = x[0];
      if (moment(date.$d).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        getAisInPolygonArea(true, points)
        getDetectedData(points)
      } else {
        getDetectedData(points)
      }
    }
  }, [dropdownPolygonId])

  const polygonSliderEventHandler = (_, data) => {
    setPolygonSliderDialogPosition({
      clientX: data.x,
      clientY: data.y
    })
  }

  return (
    <>

      <SatelliteImagesTimelineDialog
        openSatelliteImagesTimelineDialog={openSatelliteImagesTimelineDialog}
        satelliteImagesTimeline={satelliteImageTimeline}
        setOpenSatelliteImagesTimelineDialog={setOpenSatelliteImagesTimelineDialog}
      />

      <Draggable
        axis="both"
        handle=".handle"
        defaultPosition={{ x: -200, y: -10 }}
        cancel="div.polygonsDataArea__slider"
        position={{ x: polygonSliderDialogPosition.clientX, y: polygonSliderDialogPosition.clientY }}
        onDrag={polygonSliderEventHandler}
        bounds={{ right: 0, bottom: 0 }}
        onStart={() => setZindex({
          dropPin: false,
          pathSlider: false,
          attribution: false,
          satelliteImage: false,
          extendedPath: false,
          polygonSlider: true,
          polygonDataTable: false,
          futurePathModal: false,
          futurePathSlider: false,
          spoofingTimelineSlider: false,
          portsOfCallSlider: false,
        })}
      >
        <div className={`${zIndex.polygonSlider ? 'zindex-true' : 'zindex-false'} handle`} >
          {selectedPolygons.length > 0 && (
            <div
              className={
                isOpenDrawerData && haveShipsSelected
                  ? "polygonsDataArea polygonsDataArea__selectedShip"
                  : "polygonsDataArea"
              }
            >

              <div className="polygonsDataArea__right">
                <div className="polygonsDataArea__dateSelection">
                  <div className="polygonsDataArea__left">
                    <div className="polygonsDataArea__polygonPicker">
                      <Select
                        labelId="demo-simple-select-label"
                        id="select-speed-dropdown"
                        value={dropdownPolygonId}
                        label="Speed"
                        onChange={(e) => setDropdownPolygonId(e.target.value)}
                      >
                        {selectedPolygons.map(({ id, name }, i) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      <br />
                    </div>
                    <div className="polygonsDataArea__datePicker">
                      <div className="polygonsDataArea__datePickerHeading">
                        Start Date:
                      </div>
                      <DatePicker
                        dateFormat={"yyyy/MM/dd"}
                        selected={startDate}
                        disabled={false}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>


                    <div className="polygonsDataArea__datePicker">
                      <div className="polygonsDataArea__datePickerHeading">
                        End Date:
                      </div>
                      <DatePicker
                        dateFormat={"yyyy/MM/dd"}
                        selected={endDate}
                        disabled={false}
                        onChange={(date) => setEndDate(date)}
                      />
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                      style={{ marginTop: 5, marginRight: 5, fontSize: 10, height: 30 }}
                      variant="contained"
                      onClick={loadShipsForPolygon}
                    >
                      Load AIS
                    </Button>
                    <Button
                      style={{ marginTop: 5, fontSize: 10, marginRight: 5, height: 30 }}
                      variant="contained"

                      onClick={() => {
                        setShipDataDialog(true)
                      }}
                    >
                      Load Data
                    </Button>
                    {selectedShips.length > 0 && selectedShips[selectedShips.length - 1].shipCategory !== 'red' && (
                      <Button
                        style={{ marginTop: 5, marginRight: 5, fontSize: 10, height: 30 }}
                        variant="contained"
                        onClick={findSimilarShips}
                      >
                        Find Similar Ships
                      </Button>
                    )}
                    {/* {selectedShips.length > 0 && selectedShips[selectedShips.length -1].shipCategory !== 'red' && (
                      <Button
                        style={{ marginTop: 5, fontSize: 10, height: 30 }}
                        variant="contained"
                        onClick={findSimilarShipsTimeline}
                      >
                       Similar Ship Timeline
                      </Button>
                    )}    */}
                  </div>
                </div>
                <div className="polygonsDataArea__slider">
                  <div
                    className="polygonsDataArea__moveButton"
                    onClick={sliderStepBack}
                  >
                    <RemoveCircleIcon
                      style={{
                        color: polygonSliderValue === 0 ? "#ccc" : "#23263c",
                        fontSize: "22px",
                        marginBottom: 2,
                        cursor: polygonSliderValue === 0 ? "unset" : "pointer",
                      }}
                    />
                  </div>
                  <Slider
                    size="medium"
                    max={sliderMaxValue}
                    value={polygonSliderValue}
                    disabled={false}
                    // defaultValue={10}
                    valueLabelFormat={getLabel}
                    aria-label="Small"
                    valueLabelDisplay="on"
                    onChange={(e, a) => handleChange(a)}
                    style={{
                      color: "#24A0CA",
                      margin: "0 1rem",
                      marginBottom: "0.5rem",
                    }}
                    min={0}
                  />
                  <div
                    className="polygonsDataArea__moveButton"
                    onClick={sliderStepForward}
                  >
                    <AddCircleIcon
                      style={{
                        color:
                          polygonSliderValue === sliderMaxValue
                            ? "#ccc"
                            : "#23263c",
                        fontSize: "22px",
                        marginBottom: 2,
                        cursor:
                          polygonSliderValue === sliderMaxValue
                            ? "unset"
                            : "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Draggable>
    </>
  );
};

export default PolygonSlider;
