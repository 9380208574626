import React, { memo } from 'react'

const TabData = ({ title, value, lon }) => {
  return (
    <div className="textBlock">
      <div className="textBlock__title">{title}</div>
      <div className="textBlock__value">{value}
        {lon !== "undefined" ? <span className="lon">{lon}</span> : ""}
      </div>
    </div>
  )
}

export default memo(TabData)
