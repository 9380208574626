const getAisStationData = (frackingData) => {
    const features = []
    // Download CSV Data from:
    // https://www.aishub.net/stations
    // Parse it to json, and use this function to convert it to geojson
    // Paste the geojson to "aisStations" below
    frackingData.data.data.forEach(item => {
      const {Longitude, Latitude, Location,ID,Country } = item;
      const lastSignal = item['Last signal']
      features.push({
        type: 'Feature',
        id: ID,
        geometry: { type: 'Point', coordinates: [Longitude, Latitude] },
        properties: {
          location: Location,
          country: Country,
          lastSignal
        }
      });
    })

    const stationGeoJson =  {
      type: 'FeatureCollection',
      features
    }
    console.log(stationGeoJson, 'stationGeoJson')
}

export const aisStations = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "id": "2995",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "147.31",
                    "-42.97"
                ]
            },
            "properties": {
                "location": "Kingston",
                "country": "au",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2936",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "147.249",
                    "-43.1261"
                ]
            },
            "properties": {
                "location": "Kettering",
                "country": "au",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2675",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "151.293",
                    "-33.7726"
                ]
            },
            "properties": {
                "location": "Sydney",
                "country": "au",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3427",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "152.03",
                    "-32.7258"
                ]
            },
            "properties": {
                "location": "Lemon Tree Passage",
                "country": "au",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2724",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "151.122",
                    "-33.7933"
                ]
            },
            "properties": {
                "location": "North Ryde, Sydney",
                "country": "au",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2502",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "153.105",
                    "-27.1942"
                ]
            },
            "properties": {
                "location": "Scarborough",
                "country": "au",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3292",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "145.092",
                    "-37.9074"
                ]
            },
            "properties": {
                "location": "Oakleigh, Victoria",
                "country": "au",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3488",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "151.021",
                    "-33.8254"
                ]
            },
            "properties": {
                "location": "Parramatta",
                "country": "au",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2070",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "152.161",
                    "-32.7105"
                ]
            },
            "properties": {
                "location": "Nelson Bay, NSW",
                "country": "au",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2617",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "145.233",
                    "-37.8991"
                ]
            },
            "properties": {
                "location": "Knoxfield",
                "country": "au",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3354",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "118.609",
                    "-20.311"
                ]
            },
            "properties": {
                "location": "Port Hedland",
                "country": "au",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3020",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "147.782",
                    "-42.9445"
                ]
            },
            "properties": {
                "location": "Smooth Island, Tasmania",
                "country": "au",
                "lastSignal": "1648191902"
            }
        },
        {
            "type": "Feature",
            "id": "2701",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "115.792",
                    "-32.0561"
                ]
            },
            "properties": {
                "location": "O'Connor",
                "country": "au",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2838",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "114.604",
                    "-28.7763"
                ]
            },
            "properties": {
                "location": "Geraldton",
                "country": "au",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2834",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "115.642",
                    "-33.307"
                ]
            },
            "properties": {
                "location": "Bunbury",
                "country": "au",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2556",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "146.996",
                    "-43.0782"
                ]
            },
            "properties": {
                "location": "Franklin",
                "country": "au",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2739",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "146.71",
                    "-19.26"
                ]
            },
            "properties": {
                "location": "Townsville",
                "country": "au",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2288",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "143.39",
                    "-38.75"
                ]
            },
            "properties": {
                "location": "Johanna",
                "country": "au",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2637",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "146.328",
                    "-41.1087"
                ]
            },
            "properties": {
                "location": "Devonport",
                "country": "au",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2999",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "145.742",
                    "-41.0373"
                ]
            },
            "properties": {
                "location": "Mount Hicks",
                "country": "au",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2798",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.3208",
                    "48.3155"
                ]
            },
            "properties": {
                "location": "Linz",
                "country": "at",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2520",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "16.3337",
                    "48.1638"
                ]
            },
            "properties": {
                "location": "Vienna",
                "country": "at",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3175",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "16.4351",
                    "48.2379"
                ]
            },
            "properties": {
                "location": "Vienna",
                "country": "at",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2984",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "50.6029",
                    "40.3158"
                ]
            },
            "properties": {
                "location": "Baku",
                "country": "az",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3075",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.02998",
                    "50.9562"
                ]
            },
            "properties": {
                "location": "Aalst",
                "country": "be",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2824",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.404",
                    "51.232"
                ]
            },
            "properties": {
                "location": "Antwerpen",
                "country": "be",
                "lastSignal": "1647812102"
            }
        },
        {
            "type": "Feature",
            "id": "3152",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.18621",
                    "51.3623"
                ]
            },
            "properties": {
                "location": "Zeebrugge",
                "country": "be",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3215",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.41947",
                    "51.2379"
                ]
            },
            "properties": {
                "location": "Antwerp",
                "country": "be",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2300",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.5667",
                    "51.2333"
                ]
            },
            "properties": {
                "location": "Antwerp Harbour 1",
                "country": "be",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2981",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "2.91928",
                    "51.2464"
                ]
            },
            "properties": {
                "location": "Oostende",
                "country": "be",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3359",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.52747",
                    "51.2768"
                ]
            },
            "properties": {
                "location": "Schoten",
                "country": "be",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2276",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.58835",
                    "51.249"
                ]
            },
            "properties": {
                "location": "Schilde",
                "country": "be",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3249",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.38616",
                    "51.2085"
                ]
            },
            "properties": {
                "location": "Antwerp",
                "country": "be",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2061",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "2.927",
                    "51.2155"
                ]
            },
            "properties": {
                "location": "Ostend",
                "country": "be",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2145",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.4156",
                    "51.2551"
                ]
            },
            "properties": {
                "location": "Antwerp2",
                "country": "be",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2292",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.6309",
                    "51.0352"
                ]
            },
            "properties": {
                "location": "Gent",
                "country": "be",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3459",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "2.92852",
                    "51.236"
                ]
            },
            "properties": {
                "location": "Oostende",
                "country": "be",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2404",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.19562",
                    "51.2019"
                ]
            },
            "properties": {
                "location": "Bruges",
                "country": "be",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2546",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "2.77",
                    "51.15"
                ]
            },
            "properties": {
                "location": "Merchtem",
                "country": "be",
                "lastSignal": "1647865501"
            }
        },
        {
            "type": "Feature",
            "id": "2127",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.21189",
                    "50.9213"
                ]
            },
            "properties": {
                "location": "Izegem",
                "country": "be",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3489",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-35.8175",
                    "-9.71963"
                ]
            },
            "properties": {
                "location": "Maceio",
                "country": "br",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2850",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-38.4746",
                    "-3.71445"
                ]
            },
            "properties": {
                "location": "Fortaleza",
                "country": "br",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2517",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-48.5039",
                    "-1.45583"
                ]
            },
            "properties": {
                "location": "Belem",
                "country": "br",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2512",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-125.225",
                    "50.117"
                ]
            },
            "properties": {
                "location": "Quadra Island",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2821",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.989",
                    "49.26"
                ]
            },
            "properties": {
                "location": "Burnaby",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2676",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-79.3832",
                    "43.6532"
                ]
            },
            "properties": {
                "location": "Toronto",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3145",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.092",
                    "49.314"
                ]
            },
            "properties": {
                "location": "Vancouver",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2593",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-130.32",
                    "54.32"
                ]
            },
            "properties": {
                "location": "Prince Rupert",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2818",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-73.5973",
                    "45.5041"
                ]
            },
            "properties": {
                "location": "Montreal",
                "country": "ca",
                "lastSignal": "1645473302"
            }
        },
        {
            "type": "Feature",
            "id": "2920",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.952",
                    "49.288"
                ]
            },
            "properties": {
                "location": "Burnaby",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2857",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.054",
                    "49.2905"
                ]
            },
            "properties": {
                "location": "Vancouver",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2215",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.021",
                    "49.282"
                ]
            },
            "properties": {
                "location": "Burnaby",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3201",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-73.088",
                    "45.5997"
                ]
            },
            "properties": {
                "location": "Sorel-Tracy",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2042",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.116",
                    "49.2463"
                ]
            },
            "properties": {
                "location": "Vancouver",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2202",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-125.551",
                    "48.9417"
                ]
            },
            "properties": {
                "location": "Ucluelet",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2853",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.045",
                    "48.7845"
                ]
            },
            "properties": {
                "location": "Vancouver",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2218",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-52.7126",
                    "47.5615"
                ]
            },
            "properties": {
                "location": "Newfoundland, St. Johns",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2253",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-73.867",
                    "45.456"
                ]
            },
            "properties": {
                "location": "Montreal",
                "country": "ca",
                "lastSignal": "1646242801"
            }
        },
        {
            "type": "Feature",
            "id": "2660",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-73.25",
                    "45.85"
                ]
            },
            "properties": {
                "location": "Sorel-Tracy",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2463",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-130.315",
                    "54.284"
                ]
            },
            "properties": {
                "location": "Prince Rupert",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3185",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-63.569",
                    "44.6373"
                ]
            },
            "properties": {
                "location": "Halifax",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2848",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.314",
                    "48.4653"
                ]
            },
            "properties": {
                "location": "Victoria",
                "country": "ca",
                "lastSignal": "1646070601"
            }
        },
        {
            "type": "Feature",
            "id": "2267",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-63.5565",
                    "44.6619"
                ]
            },
            "properties": {
                "location": "Dartmouth",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3401",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-73.5107",
                    "45.5369"
                ]
            },
            "properties": {
                "location": "Longueuil",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3103",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123",
                    "49"
                ]
            },
            "properties": {
                "location": "Multiple Locations",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2796",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.33",
                    "48.4073"
                ]
            },
            "properties": {
                "location": "Victoria",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2890",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-73.6197",
                    "45.5365"
                ]
            },
            "properties": {
                "location": "Montreal",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3238",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.052",
                    "48.781"
                ]
            },
            "properties": {
                "location": "East Point Saturna Island",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2700",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-73.1833",
                    "45.9833"
                ]
            },
            "properties": {
                "location": "Sorel-Tracy",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2372",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.132",
                    "49.2858"
                ]
            },
            "properties": {
                "location": "Vancouver",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2955",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.331",
                    "49.3806"
                ]
            },
            "properties": {
                "location": "Bowen Island",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2025",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-83.0531",
                    "42.3167"
                ]
            },
            "properties": {
                "location": "Windsor, Ontario",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3083",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.915",
                    "49.2006"
                ]
            },
            "properties": {
                "location": "New Westminster",
                "country": "ca",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2017",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-57.987",
                    "48.9334"
                ]
            },
            "properties": {
                "location": "Corner Brook",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2019",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.306",
                    "48.4235"
                ]
            },
            "properties": {
                "location": "Victoria, BC",
                "country": "ca",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2348",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-71.5518",
                    "-33.0246"
                ]
            },
            "properties": {
                "location": "Viña del Mar",
                "country": "cl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2632",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "114.258",
                    "22.7345"
                ]
            },
            "properties": {
                "location": "Shenzhen",
                "country": "cn",
                "lastSignal": "1648136402"
            }
        },
        {
            "type": "Feature",
            "id": "2930",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "106.552",
                    "29.563"
                ]
            },
            "properties": {
                "location": "Chongqing",
                "country": "cn",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2356",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "120.419",
                    "31.968"
                ]
            },
            "properties": {
                "location": "Zhangjiagang",
                "country": "cn",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3048",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "113.948",
                    "22.2914"
                ]
            },
            "properties": {
                "location": "",
                "country": "cn",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2083",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "121.522",
                    "31.2674"
                ]
            },
            "properties": {
                "location": "Yangpu",
                "country": "cn",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2193",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "118.082",
                    "24.4798"
                ]
            },
            "properties": {
                "location": "Xia Men",
                "country": "cn",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2969",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "119.25",
                    "25.4"
                ]
            },
            "properties": {
                "location": "Meizhou Bay",
                "country": "cn",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2044",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "118.426",
                    "24.628"
                ]
            },
            "properties": {
                "location": "Fujian",
                "country": "cn",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2598",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "116.383",
                    "39.9167"
                ]
            },
            "properties": {
                "location": "Beijing",
                "country": "cn",
                "lastSignal": "1648160102"
            }
        },
        {
            "type": "Feature",
            "id": "3394",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "107.07",
                    "29.82"
                ]
            },
            "properties": {
                "location": "Chongqing",
                "country": "cn",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3031",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "120.282",
                    "31.922"
                ]
            },
            "properties": {
                "location": "Jiangyin & Maanshan",
                "country": "cn",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3458",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "118.078",
                    "38.2468"
                ]
            },
            "properties": {
                "location": "Tianjin",
                "country": "cn",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2866",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-74.828",
                    "11.0163"
                ]
            },
            "properties": {
                "location": "Puerto Colombia",
                "country": "co",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2443",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-74.8095",
                    "11.0232"
                ]
            },
            "properties": {
                "location": "Barranquilla",
                "country": "co",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2362",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-77.0805",
                    "3.89071"
                ]
            },
            "properties": {
                "location": "Buenaventura",
                "country": "co",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3439",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.5817",
                    "45.2668"
                ]
            },
            "properties": {
                "location": "Kraljevica",
                "country": "hr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2690",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.82",
                    "44.8999"
                ]
            },
            "properties": {
                "location": "Štinjan, Pula",
                "country": "hr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2669",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "32.9",
                    "34.67"
                ]
            },
            "properties": {
                "location": "Limaasol",
                "country": "cy",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3093",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "33.0226",
                    "34.7071"
                ]
            },
            "properties": {
                "location": "Limassol",
                "country": "cy",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2255",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "33.0151",
                    "34.666"
                ]
            },
            "properties": {
                "location": "Limassol",
                "country": "cy",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2524",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "32.99",
                    "34.69"
                ]
            },
            "properties": {
                "location": "Limassol",
                "country": "cy",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2139",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.1436",
                    "54.8106"
                ]
            },
            "properties": {
                "location": "Islands Brygge",
                "country": "dk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3497",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.5578",
                    "54.5717"
                ]
            },
            "properties": {
                "location": "Rødby",
                "country": "dk",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2545",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.5312",
                    "55.9557"
                ]
            },
            "properties": {
                "location": "Sletten",
                "country": "dk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2725",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.2098",
                    "56.1433"
                ]
            },
            "properties": {
                "location": "Aarhus",
                "country": "dk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3361",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.14",
                    "55.34"
                ]
            },
            "properties": {
                "location": "Korsør",
                "country": "dk",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2621",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.5935",
                    "57.7216"
                ]
            },
            "properties": {
                "location": "Aalborg",
                "country": "dk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2125",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.2878",
                    "56.21"
                ]
            },
            "properties": {
                "location": "Egå",
                "country": "dk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3424",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.42",
                    "55.43"
                ]
            },
            "properties": {
                "location": "Holbæk",
                "country": "dk",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3421",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.5179",
                    "54.8585"
                ]
            },
            "properties": {
                "location": "Marstal",
                "country": "dk",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3130",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.34747",
                    "55.4612"
                ]
            },
            "properties": {
                "location": "Lunderskov",
                "country": "dk",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2899",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.946",
                    "55.4033"
                ]
            },
            "properties": {
                "location": "Ringsted",
                "country": "dk",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3179",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.1533",
                    "56.1721"
                ]
            },
            "properties": {
                "location": "Aarhus",
                "country": "dk",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2400",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.9577",
                    "57.0862"
                ]
            },
            "properties": {
                "location": "Norresundby",
                "country": "dk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2104",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.9714",
                    "55.7115"
                ]
            },
            "properties": {
                "location": "Gershøj",
                "country": "dk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2327",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.71",
                    "55.102"
                ]
            },
            "properties": {
                "location": "Roenne, Bornholm",
                "country": "dk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2580",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.0808",
                    "55.8223"
                ]
            },
            "properties": {
                "location": "Frederikssund",
                "country": "dk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2058",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.53",
                    "55.96"
                ]
            },
            "properties": {
                "location": "Humlebaek",
                "country": "dk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2872",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.64",
                    "55.6"
                ]
            },
            "properties": {
                "location": "Dragør",
                "country": "dk",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2479",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "24.788",
                    "59.422"
                ]
            },
            "properties": {
                "location": "Tallinn",
                "country": "ee",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2566",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "24.6385",
                    "59.4343"
                ]
            },
            "properties": {
                "location": "Tallinn",
                "country": "ee",
                "lastSignal": "1648193402"
            }
        },
        {
            "type": "Feature",
            "id": "2811",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "24.7385",
                    "59.4521"
                ]
            },
            "properties": {
                "location": "Tallinn",
                "country": "ee",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2527",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "24.44",
                    "59.4"
                ]
            },
            "properties": {
                "location": "Vaana-Joesuu",
                "country": "ee",
                "lastSignal": "1648185002"
            }
        },
        {
            "type": "Feature",
            "id": "3340",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "24.62",
                    "59.43"
                ]
            },
            "properties": {
                "location": "Tallinn",
                "country": "ee",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3452",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "24.7641",
                    "59.437"
                ]
            },
            "properties": {
                "location": "Tallinn",
                "country": "ee",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2643",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-6.77661",
                    "62.0123"
                ]
            },
            "properties": {
                "location": "Tórshavn",
                "country": "fo",
                "lastSignal": "1646935202"
            }
        },
        {
            "type": "Feature",
            "id": "2867",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "25.0593",
                    "60.3315"
                ]
            },
            "properties": {
                "location": "Vantaa",
                "country": "fi",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2481",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "21.1434",
                    "63.2118"
                ]
            },
            "properties": {
                "location": "Vaasa",
                "country": "fi",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3054",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "22.2277",
                    "60.4476"
                ]
            },
            "properties": {
                "location": "Turku",
                "country": "fi",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2190",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "23.4166",
                    "60"
                ]
            },
            "properties": {
                "location": "Ekenas",
                "country": "fi",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2388",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "21.5",
                    "61.1"
                ]
            },
            "properties": {
                "location": "Finland",
                "country": "fi",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2695",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "22.2955",
                    "60.4486"
                ]
            },
            "properties": {
                "location": "Turku",
                "country": "fi",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2264",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "21.369",
                    "59.7809"
                ]
            },
            "properties": {
                "location": "Uto",
                "country": "fi",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3385",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "22.3273",
                    "60.2996"
                ]
            },
            "properties": {
                "location": "Parainen",
                "country": "fi",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2380",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "24.961",
                    "60.2037"
                ]
            },
            "properties": {
                "location": "Helsinki",
                "country": "fi",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3155",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "23.0869",
                    "63.8277"
                ]
            },
            "properties": {
                "location": "Kokkola",
                "country": "fi",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2557",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.91646",
                    "43.6503"
                ]
            },
            "properties": {
                "location": "Grasse",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2289",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.84552",
                    "43.1177"
                ]
            },
            "properties": {
                "location": "Six Fours Les Plages",
                "country": "fr",
                "lastSignal": "1647371402"
            }
        },
        {
            "type": "Feature",
            "id": "2180",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0.46",
                    "49.48"
                ]
            },
            "properties": {
                "location": "Rouen",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2345",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.444",
                    "43.6045"
                ]
            },
            "properties": {
                "location": "Toulouse",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2774",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.55419",
                    "43.4474"
                ]
            },
            "properties": {
                "location": "Bidart",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2730",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.70041",
                    "43.3988"
                ]
            },
            "properties": {
                "location": "Sète",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2093",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0.07",
                    "49.51"
                ]
            },
            "properties": {
                "location": "Rouen",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2335",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0.115559",
                    "49.4832"
                ]
            },
            "properties": {
                "location": "Le Havre",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2487",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0.37925",
                    "49.7626"
                ]
            },
            "properties": {
                "location": "Fecamp",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2431",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.25403",
                    "44.6448"
                ]
            },
            "properties": {
                "location": "Cap Ferret",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2156",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "2.39122",
                    "48.9087"
                ]
            },
            "properties": {
                "location": "Aubervilliers",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2405",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.67232",
                    "43.2003"
                ]
            },
            "properties": {
                "location": "Cap Camarat",
                "country": "fr",
                "lastSignal": "1648150501"
            }
        },
        {
            "type": "Feature",
            "id": "2486",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.09915",
                    "46.2035"
                ]
            },
            "properties": {
                "location": "Saint Xandre",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2102",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.101",
                    "43.601"
                ]
            },
            "properties": {
                "location": "Antibes",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3030",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0.147599",
                    "49.5158"
                ]
            },
            "properties": {
                "location": "Le Havre",
                "country": "fr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2564",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.17039",
                    "46.1401"
                ]
            },
            "properties": {
                "location": "La Rochelle",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2662",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0.06927",
                    "49.5124"
                ]
            },
            "properties": {
                "location": "Le Havre",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2615",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.7125",
                    "47.5101"
                ]
            },
            "properties": {
                "location": "Sarzeau",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2299",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.77787",
                    "47.6244"
                ]
            },
            "properties": {
                "location": "Sene-Vannes",
                "country": "fr",
                "lastSignal": "1645351801"
            }
        },
        {
            "type": "Feature",
            "id": "3353",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.58467",
                    "48.3729"
                ]
            },
            "properties": {
                "location": "Plouzane",
                "country": "fr",
                "lastSignal": "1648192502"
            }
        },
        {
            "type": "Feature",
            "id": "2972",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.48556",
                    "48.3908"
                ]
            },
            "properties": {
                "location": "Brest",
                "country": "fr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2572",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.935",
                    "43.562"
                ]
            },
            "properties": {
                "location": "Mandelieu La Napoule",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2471",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.74731",
                    "47.6446"
                ]
            },
            "properties": {
                "location": "UBS-Vannes",
                "country": "fr",
                "lastSignal": "1648138502"
            }
        },
        {
            "type": "Feature",
            "id": "2801",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.5829",
                    "50.7262"
                ]
            },
            "properties": {
                "location": "Boulogne-sur-Mer",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2653",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.15144",
                    "46.1417"
                ]
            },
            "properties": {
                "location": "La Rochelle",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2178",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.03",
                    "49.44"
                ]
            },
            "properties": {
                "location": "Rouen",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3467",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "2.17508",
                    "48.9215"
                ]
            },
            "properties": {
                "location": "Houilles",
                "country": "fr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2960",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "2.6368",
                    "48.5463"
                ]
            },
            "properties": {
                "location": "Melun",
                "country": "fr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2749",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.97503",
                    "43.5433"
                ]
            },
            "properties": {
                "location": "Carnon",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2038",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.98496",
                    "43.4041"
                ]
            },
            "properties": {
                "location": "Port de Bouc",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2836",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.48",
                    "48.3918"
                ]
            },
            "properties": {
                "location": "Brest",
                "country": "fr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2421",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.43809",
                    "48.3329"
                ]
            },
            "properties": {
                "location": "Plougastel",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3234",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.26972",
                    "46.9744"
                ]
            },
            "properties": {
                "location": "L'Epine",
                "country": "fr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2166",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.99684",
                    "45.6073"
                ]
            },
            "properties": {
                "location": "Saint Georges de Didonnes",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2954",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.05276",
                    "43.6154"
                ]
            },
            "properties": {
                "location": "Sophia Antipolis, MINES Paristech",
                "country": "fr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2693",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "2.309",
                    "48.859"
                ]
            },
            "properties": {
                "location": "Paris",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2275",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.3791",
                    "47.7297"
                ]
            },
            "properties": {
                "location": "Lorient",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3162",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.68981",
                    "48.0582"
                ]
            },
            "properties": {
                "location": "Cléden-Cap-Sizun",
                "country": "fr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3228",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.9192",
                    "48.6691"
                ]
            },
            "properties": {
                "location": "Carantec",
                "country": "fr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2037",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.19169",
                    "47.6576"
                ]
            },
            "properties": {
                "location": "France",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2320",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.49136",
                    "48.4066"
                ]
            },
            "properties": {
                "location": "Brest",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2777",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.77",
                    "47.53"
                ]
            },
            "properties": {
                "location": "Sarzon",
                "country": "fr",
                "lastSignal": "1648040401"
            }
        },
        {
            "type": "Feature",
            "id": "2415",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.56365",
                    "48.3584"
                ]
            },
            "properties": {
                "location": "Brest",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2428",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0.162778",
                    "49.5161"
                ]
            },
            "properties": {
                "location": "Le Havre",
                "country": "fr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2513",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.03236",
                    "50.8632"
                ]
            },
            "properties": {
                "location": "Cologne",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2865",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.7226",
                    "50.0987"
                ]
            },
            "properties": {
                "location": "Frankfurt",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2582",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.2234",
                    "50.8027"
                ]
            },
            "properties": {
                "location": "Siegburg",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2165",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.76747",
                    "53.5831"
                ]
            },
            "properties": {
                "location": "Hamburg",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2989",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.49409",
                    "49.7569"
                ]
            },
            "properties": {
                "location": "Gernsheim",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2728",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.1823",
                    "53.3679"
                ]
            },
            "properties": {
                "location": "Emden",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2175",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.4905",
                    "52.4918"
                ]
            },
            "properties": {
                "location": "Berlin Rummelsburg",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2577",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.94333",
                    "53.5484"
                ]
            },
            "properties": {
                "location": "Hamburg",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2423",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.0325",
                    "54.1674"
                ]
            },
            "properties": {
                "location": "Rostock",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3433",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2295",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.917",
                    "50.905"
                ]
            },
            "properties": {
                "location": "Cologne",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2861",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.42545",
                    "49.0155"
                ]
            },
            "properties": {
                "location": "Karlsruhe",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2291",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.5369",
                    "53.5947"
                ]
            },
            "properties": {
                "location": "Bremerhaven",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2464",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.98746",
                    "53.3063"
                ]
            },
            "properties": {
                "location": "Asendorf",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2860",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.405",
                    "52.52"
                ]
            },
            "properties": {
                "location": "Berlin",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3366",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.4454",
                    "53.2641"
                ]
            },
            "properties": {
                "location": "Lüneburg",
                "country": "de",
                "lastSignal": "1648191002"
            }
        },
        {
            "type": "Feature",
            "id": "2223",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.76124",
                    "53.0989"
                ]
            },
            "properties": {
                "location": "Bremen Winterhafen",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2110",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.80642",
                    "53.0736"
                ]
            },
            "properties": {
                "location": "Bremen",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3496",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.7599",
                    "54.1503"
                ]
            },
            "properties": {
                "location": "Ostseebad Kühlungsborn",
                "country": "de",
                "lastSignal": "1648193402"
            }
        },
        {
            "type": "Feature",
            "id": "2858",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.5085",
                    "52.4276"
                ]
            },
            "properties": {
                "location": "Brandenburg",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3364",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.2229",
                    "54.1487"
                ]
            },
            "properties": {
                "location": "Mönchhagen",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2030",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.1088",
                    "54.0833"
                ]
            },
            "properties": {
                "location": "Rostock",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3425",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.0078",
                    "53.5546"
                ]
            },
            "properties": {
                "location": "Hamburg",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2856",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.7582",
                    "50.1146"
                ]
            },
            "properties": {
                "location": "Offenbach",
                "country": "de",
                "lastSignal": "1648148102"
            }
        },
        {
            "type": "Feature",
            "id": "2663",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.30938",
                    "52.5325"
                ]
            },
            "properties": {
                "location": "Lingen",
                "country": "de",
                "lastSignal": "1648190402"
            }
        },
        {
            "type": "Feature",
            "id": "2413",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.0172",
                    "53.5648"
                ]
            },
            "properties": {
                "location": "Hamburg Alster",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2907",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.8421",
                    "53.6281"
                ]
            },
            "properties": {
                "location": "Halstenbek",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2352",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.6742",
                    "51.0628"
                ]
            },
            "properties": {
                "location": "Dresden",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2130",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.47639",
                    "52.3546"
                ]
            },
            "properties": {
                "location": "Lower Saxony (Mittellandkanal)",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2717",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.18538",
                    "49.1335"
                ]
            },
            "properties": {
                "location": "Heilbronn",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2805",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.164",
                    "53.7"
                ]
            },
            "properties": {
                "location": "Norderney",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2759",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.53067",
                    "49.405"
                ]
            },
            "properties": {
                "location": "Bruehl-Baden",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2216",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.41544",
                    "49.9902"
                ]
            },
            "properties": {
                "location": "Ruesselsheim am Main",
                "country": "de",
                "lastSignal": "1648191302"
            }
        },
        {
            "type": "Feature",
            "id": "2207",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.396",
                    "53.91"
                ]
            },
            "properties": {
                "location": "Wismar",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3022",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2167",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.85633",
                    "53.1109"
                ]
            },
            "properties": {
                "location": "BIBA",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3409",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.96842",
                    "53.463"
                ]
            },
            "properties": {
                "location": "Hamburg",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3017",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.75717",
                    "51.3637"
                ]
            },
            "properties": {
                "location": "Duisburg",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2849",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.2034",
                    "52.5243"
                ]
            },
            "properties": {
                "location": "Berlin Spandau",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2754",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.84727",
                    "52.9534"
                ]
            },
            "properties": {
                "location": "Barrien",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2584",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.62644",
                    "54.2567"
                ]
            },
            "properties": {
                "location": "Rendsburg",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2403",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.51667",
                    "53.6333"
                ]
            },
            "properties": {
                "location": "Stadersand",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2176",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.42181",
                    "53.7956"
                ]
            },
            "properties": {
                "location": "Glueckstadt",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2543",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.96863",
                    "53.4603"
                ]
            },
            "properties": {
                "location": "Hamburg",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3266",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.54763",
                    "49.5009"
                ]
            },
            "properties": {
                "location": "Mannheim",
                "country": "de",
                "lastSignal": "1646639701"
            }
        },
        {
            "type": "Feature",
            "id": "2603",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.4216",
                    "53.4081"
                ]
            },
            "properties": {
                "location": "Geesthacht",
                "country": "de",
                "lastSignal": "1646634902"
            }
        },
        {
            "type": "Feature",
            "id": "2126",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.99368",
                    "53.5511"
                ]
            },
            "properties": {
                "location": "Cologne",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2840",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.7523",
                    "51.4068"
                ]
            },
            "properties": {
                "location": "Duisburg",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3009",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.8989",
                    "53.0582"
                ]
            },
            "properties": {
                "location": "Bremen",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3097",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.08942",
                    "53.5481"
                ]
            },
            "properties": {
                "location": "Wilhelmshaven",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2600",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.7866",
                    "51.6693"
                ]
            },
            "properties": {
                "location": "Hamm",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2568",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.53972",
                    "51.6614"
                ]
            },
            "properties": {
                "location": "Wesel",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2747",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.147",
                    "53.515"
                ]
            },
            "properties": {
                "location": "Wilhelmshaven",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2186",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.7042",
                    "52.8869"
                ]
            },
            "properties": {
                "location": "Könau",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2889",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.1743",
                    "54.4222"
                ]
            },
            "properties": {
                "location": "Kiel",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3392",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.45647",
                    "53.2371"
                ]
            },
            "properties": {
                "location": "Lienen",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2359",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.7991",
                    "52.9155"
                ]
            },
            "properties": {
                "location": "Neuruppin",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3236",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.41519",
                    "50.0126"
                ]
            },
            "properties": {
                "location": "Flörsheim",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2958",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.1657",
                    "49.8038"
                ]
            },
            "properties": {
                "location": "Dettelbach",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2793",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.418",
                    "53.9049"
                ]
            },
            "properties": {
                "location": "Wismar",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3170",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.11",
                    "50.65"
                ]
            },
            "properties": {
                "location": "Bonn",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2461",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.7995",
                    "51.0464"
                ]
            },
            "properties": {
                "location": "Dresden",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3232",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.9603",
                    "50.9375"
                ]
            },
            "properties": {
                "location": "Cologne",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2072",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.76",
                    "51.57"
                ]
            },
            "properties": {
                "location": "Walsum",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2784",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.19935",
                    "53.3602"
                ]
            },
            "properties": {
                "location": "Emden",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2382",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.73908",
                    "50.1118"
                ]
            },
            "properties": {
                "location": "Offenbach",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2563",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.9937",
                    "53.5511"
                ]
            },
            "properties": {
                "location": "Hamburg",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2228",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.1798",
                    "54.263"
                ]
            },
            "properties": {
                "location": "Kiel",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2877",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.8766",
                    "51.0992"
                ]
            },
            "properties": {
                "location": "Dormagen",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2783",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.4236",
                    "52.426"
                ]
            },
            "properties": {
                "location": "Wunstorf",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2738",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.91481",
                    "53.5926"
                ]
            },
            "properties": {
                "location": "Hamburg",
                "country": "de",
                "lastSignal": "1645536902"
            }
        },
        {
            "type": "Feature",
            "id": "3384",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.83727",
                    "53.0338"
                ]
            },
            "properties": {
                "location": "Bremen, Arsten",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2737",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.0504",
                    "53.5203"
                ]
            },
            "properties": {
                "location": "Hamburg",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2561",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.5724",
                    "53.547"
                ]
            },
            "properties": {
                "location": "Bremerhaven",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2551",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.38479",
                    "53.7098"
                ]
            },
            "properties": {
                "location": "Elbe River, Drochtersen",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3160",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.62",
                    "52.32"
                ]
            },
            "properties": {
                "location": "Wildau",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3453",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.62",
                    "52.42"
                ]
            },
            "properties": {
                "location": "Garbsen",
                "country": "de",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2531",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.69981",
                    "50.1019"
                ]
            },
            "properties": {
                "location": "Frankfurt",
                "country": "de",
                "lastSignal": "1648193101"
            }
        },
        {
            "type": "Feature",
            "id": "2508",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.202",
                    "49.041"
                ]
            },
            "properties": {
                "location": "Neckarwestheim",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2137",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.755",
                    "53.112"
                ]
            },
            "properties": {
                "location": "Bremen",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2633",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.8017",
                    "53.0793"
                ]
            },
            "properties": {
                "location": "Bremen",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2688",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.7884",
                    "53.9071"
                ]
            },
            "properties": {
                "location": "Lübeck",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2733",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.4897",
                    "52.4906"
                ]
            },
            "properties": {
                "location": "Berlin",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2398",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.48",
                    "52.48"
                ]
            },
            "properties": {
                "location": "Berlin",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2570",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.0725",
                    "54.3587"
                ]
            },
            "properties": {
                "location": "Kiel-Suchsdorf",
                "country": "de",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2939",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "24.0131",
                    "37.8855"
                ]
            },
            "properties": {
                "location": "Athens",
                "country": "gr",
                "lastSignal": "0"
            }
        },
        {
            "type": "Feature",
            "id": "3485",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "21.8092",
                    "38.2977"
                ]
            },
            "properties": {
                "location": "Patra",
                "country": "gr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3197",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "22.922",
                    "40.6366"
                ]
            },
            "properties": {
                "location": "Thessaloniki",
                "country": "gr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2290",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "23.8487",
                    "38.0015"
                ]
            },
            "properties": {
                "location": "Athens",
                "country": "gr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3344",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "23.7046",
                    "37.9375"
                ]
            },
            "properties": {
                "location": "Athens",
                "country": "gr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2084",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "25.3333",
                    "36.7167"
                ]
            },
            "properties": {
                "location": "Ios",
                "country": "gr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2237",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "23.755",
                    "37.865"
                ]
            },
            "properties": {
                "location": "Athens, Glyfada",
                "country": "gr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2339",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "24.4813",
                    "40.9542"
                ]
            },
            "properties": {
                "location": "North Aegean",
                "country": "gr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2226",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "23.7275",
                    "37.9838"
                ]
            },
            "properties": {
                "location": "Athens",
                "country": "gr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2746",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "23.908",
                    "38.0217"
                ]
            },
            "properties": {
                "location": "Rafina",
                "country": "gr",
                "lastSignal": "1647794102"
            }
        },
        {
            "type": "Feature",
            "id": "2118",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "23.7204",
                    "37.9114"
                ]
            },
            "properties": {
                "location": "Alimos",
                "country": "gr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3036",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "23.6444",
                    "37.9469"
                ]
            },
            "properties": {
                "location": "Piraeus",
                "country": "gr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2654",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.55245",
                    "49.4353"
                ]
            },
            "properties": {
                "location": "St Martin",
                "country": "gg",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2316",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.51",
                    "49.49"
                ]
            },
            "properties": {
                "location": "Guernsey",
                "country": "gg",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3202",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "114.108",
                    "22.3548"
                ]
            },
            "properties": {
                "location": "Tsuen Wan",
                "country": "hk",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2177",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "114.158",
                    "22.3229"
                ]
            },
            "properties": {
                "location": "Kowloon",
                "country": "hk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3272",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "114.257",
                    "22.3119"
                ]
            },
            "properties": {
                "location": "Tseung Kwan O",
                "country": "hk",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2785",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "114.11",
                    "22.23"
                ]
            },
            "properties": {
                "location": "Aberdeen",
                "country": "hk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3302",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-21.6869",
                    "64.1429"
                ]
            },
            "properties": {
                "location": "Reykjavik",
                "country": "is",
                "lastSignal": "1645458301"
            }
        },
        {
            "type": "Feature",
            "id": "2670",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "in",
                "lastSignal": "1648193402"
            }
        },
        {
            "type": "Feature",
            "id": "2644",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "83.2573",
                    "17.7465"
                ]
            },
            "properties": {
                "location": "Visakhapatnam",
                "country": "in",
                "lastSignal": "1647617701"
            }
        },
        {
            "type": "Feature",
            "id": "3016",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "106.841",
                    "-6.17329"
                ]
            },
            "properties": {
                "location": "Jakarta",
                "country": "id",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2897",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "110.433",
                    "-5.87985"
                ]
            },
            "properties": {
                "location": "Karimun Jawa",
                "country": "id",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3015",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "116.856",
                    "1.26238"
                ]
            },
            "properties": {
                "location": "Balikpapan, East Kalimantan",
                "country": "id",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3304",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-6.06153",
                    "53.0698"
                ]
            },
            "properties": {
                "location": "Newcastle",
                "country": "ie",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2354",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-9.6292",
                    "51.5555"
                ]
            },
            "properties": {
                "location": "Mizen Head",
                "country": "ie",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2150",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.13333",
                    "54.5667"
                ]
            },
            "properties": {
                "location": "Lackenbehy",
                "country": "ie",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2378",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-6.11395",
                    "53.0853"
                ]
            },
            "properties": {
                "location": "Newtownmountkennedy",
                "country": "ie",
                "lastSignal": "1648173602"
            }
        },
        {
            "type": "Feature",
            "id": "2569",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-9.0761",
                    "53.1629"
                ]
            },
            "properties": {
                "location": "Galway",
                "country": "ie",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3357",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-10.1855",
                    "53.6199"
                ]
            },
            "properties": {
                "location": "Inishbofin",
                "country": "ie",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2085",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-8.5261",
                    "51.7045"
                ]
            },
            "properties": {
                "location": "Kinsale",
                "country": "ie",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3104",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-5.62833",
                    "54.6583"
                ]
            },
            "properties": {
                "location": "Bangor",
                "country": "ie",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2846",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-9.52622",
                    "53.805"
                ]
            },
            "properties": {
                "location": "Westport",
                "country": "ie",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2321",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-8.302",
                    "51.849"
                ]
            },
            "properties": {
                "location": "Cobh",
                "country": "ie",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2441",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-7.615",
                    "52.05"
                ]
            },
            "properties": {
                "location": "Ring",
                "country": "ie",
                "lastSignal": "1648184101"
            }
        },
        {
            "type": "Feature",
            "id": "3053",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-9.97437",
                    "53.5961"
                ]
            },
            "properties": {
                "location": "Renvyle",
                "country": "ie",
                "lastSignal": "1648188002"
            }
        },
        {
            "type": "Feature",
            "id": "2360",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-7.1101",
                    "52.2593"
                ]
            },
            "properties": {
                "location": "Waterford",
                "country": "ie",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2371",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "35",
                    "32.8"
                ]
            },
            "properties": {
                "location": "Haifa",
                "country": "il",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2283",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "34.89",
                    "32.47"
                ]
            },
            "properties": {
                "location": "Haifa",
                "country": "il",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2610",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "34.9613",
                    "32.8241"
                ]
            },
            "properties": {
                "location": "Haifa",
                "country": "il",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2751",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "34.7872",
                    "32.1143"
                ]
            },
            "properties": {
                "location": "\tTel Aviv",
                "country": "il",
                "lastSignal": "1648106702"
            }
        },
        {
            "type": "Feature",
            "id": "2893",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "35.0187",
                    "32.7628"
                ]
            },
            "properties": {
                "location": "Haifa",
                "country": "il",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2217",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "17.1973",
                    "40.4769"
                ]
            },
            "properties": {
                "location": "Taranto",
                "country": "it",
                "lastSignal": "1648047001"
            }
        },
        {
            "type": "Feature",
            "id": "3373",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "15.217",
                    "37.223"
                ]
            },
            "properties": {
                "location": "Augusta",
                "country": "it",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2189",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "15.245",
                    "38.2308"
                ]
            },
            "properties": {
                "location": "Milazzo",
                "country": "it",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2394",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.193",
                    "43.3154"
                ]
            },
            "properties": {
                "location": "Livorno",
                "country": "it",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2914",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.8665",
                    "40.7349"
                ]
            },
            "properties": {
                "location": "Ischia",
                "country": "it",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2169",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.82408",
                    "44.1025"
                ]
            },
            "properties": {
                "location": "La Spezia",
                "country": "it",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2573",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.3155",
                    "45.55"
                ]
            },
            "properties": {
                "location": "Venice",
                "country": "it",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2525",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.415",
                    "40.6433"
                ]
            },
            "properties": {
                "location": "Meta (Naples)",
                "country": "it",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3116",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.4244",
                    "40.6356"
                ]
            },
            "properties": {
                "location": "Sorrento",
                "country": "it",
                "lastSignal": "1645543802"
            }
        },
        {
            "type": "Feature",
            "id": "2656",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "9.13968",
                    "39.2185"
                ]
            },
            "properties": {
                "location": "Cagliari",
                "country": "it",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2521",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.2373",
                    "37.0686"
                ]
            },
            "properties": {
                "location": "Gela",
                "country": "it",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2098",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.1843",
                    "38.1233"
                ]
            },
            "properties": {
                "location": "Palermo",
                "country": "it",
                "lastSignal": "1648078802"
            }
        },
        {
            "type": "Feature",
            "id": "2489",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.878",
                    "44.41"
                ]
            },
            "properties": {
                "location": "Messina",
                "country": "it",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2553",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.6333",
                    "41.45"
                ]
            },
            "properties": {
                "location": "Aprilia",
                "country": "it",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2873",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.9383",
                    "37.0977"
                ]
            },
            "properties": {
                "location": "Licata",
                "country": "it",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3224",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.8999",
                    "42.9214"
                ]
            },
            "properties": {
                "location": "San Benedetto del Tronto",
                "country": "it",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2171",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "140.098",
                    "35.6004"
                ]
            },
            "properties": {
                "location": "Chiba",
                "country": "jp",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2119",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "139.638",
                    "35.4437"
                ]
            },
            "properties": {
                "location": "Yokohama",
                "country": "jp",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2121",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "135.196",
                    "34.69"
                ]
            },
            "properties": {
                "location": "Kobe",
                "country": "jp",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3024",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "140.321",
                    "35.1523"
                ]
            },
            "properties": {
                "location": "Katsuura, Chiba",
                "country": "jp",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2326",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "134.881",
                    "34.3433"
                ]
            },
            "properties": {
                "location": "Sumoto",
                "country": "jp",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3410",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "141",
                    "40.9024"
                ]
            },
            "properties": {
                "location": "Aomori",
                "country": "jp",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3163",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "135.291",
                    "34.7193"
                ]
            },
            "properties": {
                "location": "Kobe",
                "country": "jp",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2062",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "139.684",
                    "35.2625"
                ]
            },
            "properties": {
                "location": "Yokosuka",
                "country": "jp",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2947",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "139.544",
                    "35.473"
                ]
            },
            "properties": {
                "location": "Yokohama (JP YOK)",
                "country": "jp",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2417",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "135.178",
                    "34.2591"
                ]
            },
            "properties": {
                "location": "Wakayama Port",
                "country": "jp",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2651",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-61.2038",
                    "13.1297"
                ]
            },
            "properties": {
                "location": "S/Y Aweh",
                "country": "je",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2991",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "126.51",
                    "33.4716"
                ]
            },
            "properties": {
                "location": "Jejusi",
                "country": "kr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2391",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "126.48",
                    "36.59"
                ]
            },
            "properties": {
                "location": "Pyuntaek",
                "country": "kr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3402",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "126.56",
                    "37.4879"
                ]
            },
            "properties": {
                "location": "Incheon",
                "country": "kr",
                "lastSignal": "1647940802"
            }
        },
        {
            "type": "Feature",
            "id": "3322",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "129.121",
                    "35.1783"
                ]
            },
            "properties": {
                "location": "Busan",
                "country": "kr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3188",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "48.15",
                    "29.56"
                ]
            },
            "properties": {
                "location": "Kuwait",
                "country": "kw",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2719",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "22.1348",
                    "55.6866"
                ]
            },
            "properties": {
                "location": "Klaipeda",
                "country": "lt",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2504",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "116.079",
                    "5.92633"
                ]
            },
            "properties": {
                "location": "Kota Kinabalu",
                "country": "my",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2507",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-105.296",
                    "20.6938"
                ]
            },
            "properties": {
                "location": "Nuevo Vallarta, Nayarit",
                "country": "mx",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2138",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-96.13",
                    "19.2"
                ]
            },
            "properties": {
                "location": "Veracruz",
                "country": "mx",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2427",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-86.957",
                    "20.5"
                ]
            },
            "properties": {
                "location": "Cozumel",
                "country": "mx",
                "lastSignal": "1648176002"
            }
        },
        {
            "type": "Feature",
            "id": "2822",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.43083",
                    "43.7485"
                ]
            },
            "properties": {
                "location": "Monaco",
                "country": "mc",
                "lastSignal": "1648159502"
            }
        },
        {
            "type": "Feature",
            "id": "3043",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.41621",
                    "43.7338"
                ]
            },
            "properties": {
                "location": "Monaco",
                "country": "mc",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3241",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-7.5898",
                    "33.5731"
                ]
            },
            "properties": {
                "location": "Casablanca",
                "country": "ma",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2136",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.96",
                    "52.563"
                ]
            },
            "properties": {
                "location": "NW_Overijssel",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2775",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.52395",
                    "53.1796"
                ]
            },
            "properties": {
                "location": "Franeker",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2631",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.03885",
                    "51.1238"
                ]
            },
            "properties": {
                "location": "Roermond",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2679",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.59895",
                    "52.6631"
                ]
            },
            "properties": {
                "location": "Urk",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2330",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.75764",
                    "50.9754"
                ]
            },
            "properties": {
                "location": "Stein",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2250",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.4962",
                    "52.1137"
                ]
            },
            "properties": {
                "location": "Zoeterwoude",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2571",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.37098",
                    "53.3666"
                ]
            },
            "properties": {
                "location": "Leens",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2112",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.42881",
                    "53.1801"
                ]
            },
            "properties": {
                "location": "Harlingen",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3436",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.43155",
                    "53.0058"
                ]
            },
            "properties": {
                "location": "Ferwoude",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2678",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.64597",
                    "52.4941"
                ]
            },
            "properties": {
                "location": "Beverwijk",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2241",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.70532",
                    "52.0177"
                ]
            },
            "properties": {
                "location": "Gouda",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2236",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.585",
                    "52.463"
                ]
            },
            "properties": {
                "location": "Ijmuiden",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2105",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.32",
                    "51.85"
                ]
            },
            "properties": {
                "location": "Spijkenisse",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2548",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.39404",
                    "51.8548"
                ]
            },
            "properties": {
                "location": "Poortugaal",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3372",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.725",
                    "51.8092"
                ]
            },
            "properties": {
                "location": "Wijchen",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2771",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.22194",
                    "53.3658"
                ]
            },
            "properties": {
                "location": "West-Terschelling",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2549",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.3217",
                    "52.0302"
                ]
            },
            "properties": {
                "location": "Rijswijk",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2239",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.58416",
                    "52.2265"
                ]
            },
            "properties": {
                "location": "Goor",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2625",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.62599",
                    "53.2705"
                ]
            },
            "properties": {
                "location": "Sint Anne",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2435",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.4113",
                    "52.2079"
                ]
            },
            "properties": {
                "location": "Katwijk",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3212",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.01892",
                    "52.1291"
                ]
            },
            "properties": {
                "location": "Maarssenbroek",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3429",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.8324",
                    "51.3323"
                ]
            },
            "properties": {
                "location": "Terneuzen",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2674",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.84056",
                    "50.868"
                ]
            },
            "properties": {
                "location": "Maastricht",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2987",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.95924",
                    "51.3534"
                ]
            },
            "properties": {
                "location": "Vogelwaarde, Zeeland",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2815",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.35922",
                    "52.0096"
                ]
            },
            "properties": {
                "location": "Delft",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2097",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.1529",
                    "51.8476"
                ]
            },
            "properties": {
                "location": "Hellevoetsluis",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2374",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.68802",
                    "52.8507"
                ]
            },
            "properties": {
                "location": "Lemmer",
                "country": "nl",
                "lastSignal": "1645627501"
            }
        },
        {
            "type": "Feature",
            "id": "2623",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.5839",
                    "51.8312"
                ]
            },
            "properties": {
                "location": "Appeltern",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2921",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.23325",
                    "52.0906"
                ]
            },
            "properties": {
                "location": "Zeist",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2671",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.95",
                    "52.9013"
                ]
            },
            "properties": {
                "location": "Hippolytushoef",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2622",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.33528",
                    "51.9056"
                ]
            },
            "properties": {
                "location": "Vlaardingen",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2052",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.37013",
                    "52.1642"
                ]
            },
            "properties": {
                "location": "Amersfoort",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3040",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.166",
                    "52.029"
                ]
            },
            "properties": {
                "location": "Ter Heijde",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3056",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.49544",
                    "52.1659"
                ]
            },
            "properties": {
                "location": "Leiden",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3295",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.70603",
                    "50.7776"
                ]
            },
            "properties": {
                "location": "Eijsden",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3136",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.10361",
                    "52.5322"
                ]
            },
            "properties": {
                "location": "Zwolle",
                "country": "nl",
                "lastSignal": "1645567801"
            }
        },
        {
            "type": "Feature",
            "id": "2916",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.4751",
                    "51.9242"
                ]
            },
            "properties": {
                "location": "Rotterdam",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3135",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.80926",
                    "52.4573"
                ]
            },
            "properties": {
                "location": "Koog aan de Zaan",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3360",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.16763",
                    "51.7136"
                ]
            },
            "properties": {
                "location": "Nieuwe Tonge",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2497",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.5",
                    "52.194"
                ]
            },
            "properties": {
                "location": "Warmond",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2347",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.32646",
                    "52.1105"
                ]
            },
            "properties": {
                "location": "The Hague",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3029",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.05021",
                    "51.8719"
                ]
            },
            "properties": {
                "location": "Rockanje",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3290",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.44479",
                    "53.1656"
                ]
            },
            "properties": {
                "location": "Harlingen",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2554",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.93829",
                    "51.8327"
                ]
            },
            "properties": {
                "location": "Gorinchem",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2231",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.4",
                    "51.86"
                ]
            },
            "properties": {
                "location": "Poortugaal",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2483",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.76294",
                    "51.2824"
                ]
            },
            "properties": {
                "location": "Nederweert",
                "country": "nl",
                "lastSignal": "1647717602"
            }
        },
        {
            "type": "Feature",
            "id": "3027",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.86083",
                    "52.2811"
                ]
            },
            "properties": {
                "location": "Amstelveen",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3287",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.75391",
                    "51.7291"
                ]
            },
            "properties": {
                "location": "Renesse",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2346",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.00265",
                    "52.3562"
                ]
            },
            "properties": {
                "location": "Amsterdam",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2475",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.39404",
                    "51.8548"
                ]
            },
            "properties": {
                "location": "Poortugaal",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3199",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.48141",
                    "51.912"
                ]
            },
            "properties": {
                "location": "Rotterdam",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2196",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.9462",
                    "51.7315"
                ]
            },
            "properties": {
                "location": "Zonnemaire",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2379",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.2597",
                    "52.104"
                ]
            },
            "properties": {
                "location": "Zeist",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2287",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.37535",
                    "51.7153"
                ]
            },
            "properties": {
                "location": "Rosmalen",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3123",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.9159",
                    "51.6466"
                ]
            },
            "properties": {
                "location": "Zierikzee",
                "country": "nl",
                "lastSignal": "1648097401"
            }
        },
        {
            "type": "Feature",
            "id": "2852",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.80691",
                    "51.036"
                ]
            },
            "properties": {
                "location": "Born",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2904",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.00273",
                    "52.4671"
                ]
            },
            "properties": {
                "location": "Wezep",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3412",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.594",
                    "51.9843"
                ]
            },
            "properties": {
                "location": "Rotterdam",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2661",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.93076",
                    "53.3306"
                ]
            },
            "properties": {
                "location": "Amersfoort",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3254",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.74879",
                    "52.7055"
                ]
            },
            "properties": {
                "location": "Emmeloord",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2224",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.42638",
                    "52.1954"
                ]
            },
            "properties": {
                "location": "Katwijk",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2425",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.11989",
                    "52.9226"
                ]
            },
            "properties": {
                "location": "Ter Walslage",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2803",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.65225",
                    "51.8641"
                ]
            },
            "properties": {
                "location": "Bergharen",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2303",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.0334",
                    "51.1227"
                ]
            },
            "properties": {
                "location": "Posterholt",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2429",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.47773",
                    "51.9244"
                ]
            },
            "properties": {
                "location": "Rotterdam",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2350",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.9043",
                    "53.3285"
                ]
            },
            "properties": {
                "location": "Delfzijl",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2712",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.48568",
                    "51.3278"
                ]
            },
            "properties": {
                "location": "Oostburg",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2655",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.73462",
                    "51.8243"
                ]
            },
            "properties": {
                "location": "Sliedrecht",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2968",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.11808",
                    "53.1082"
                ]
            },
            "properties": {
                "location": "Drachten",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3473",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.5559",
                    "51.3945"
                ]
            },
            "properties": {
                "location": "Breskens",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2711",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.79346",
                    "52.2627"
                ]
            },
            "properties": {
                "location": "Hengelo",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2273",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.49751",
                    "51.9239"
                ]
            },
            "properties": {
                "location": "Rotterdam",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3109",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.1",
                    "52.001"
                ]
            },
            "properties": {
                "location": "Nieuwegein",
                "country": "nl",
                "lastSignal": "1646849402"
            }
        },
        {
            "type": "Feature",
            "id": "2607",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.858",
                    "52.4241"
                ]
            },
            "properties": {
                "location": "Amsterdam",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3181",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.9131",
                    "51.3125"
                ]
            },
            "properties": {
                "location": "Zaamslag",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2155",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.47142",
                    "52.5185"
                ]
            },
            "properties": {
                "location": "Lelystad",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2402",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.34207",
                    "51.8528"
                ]
            },
            "properties": {
                "location": "Spijkenisse",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2022",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.02",
                    "51.31"
                ]
            },
            "properties": {
                "location": "Hulst",
                "country": "nl",
                "lastSignal": "1648143002"
            }
        },
        {
            "type": "Feature",
            "id": "2214",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.47541",
                    "51.9148"
                ]
            },
            "properties": {
                "location": "Rotterdam",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2652",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.92",
                    "52.676"
                ]
            },
            "properties": {
                "location": "Marknesse",
                "country": "nl",
                "lastSignal": "1645700401"
            }
        },
        {
            "type": "Feature",
            "id": "2468",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.1359",
                    "51.9953"
                ]
            },
            "properties": {
                "location": "Rotterdam",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2892",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.0263",
                    "51.9526"
                ]
            },
            "properties": {
                "location": "Duiven",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3396",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.12395",
                    "51.9778"
                ]
            },
            "properties": {
                "location": "Rotterdam",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2559",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.8388",
                    "51.8332"
                ]
            },
            "properties": {
                "location": "Nijmegen",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3264",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.14339",
                    "52.0903"
                ]
            },
            "properties": {
                "location": "Utrecht",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2510",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.14085",
                    "51.834"
                ]
            },
            "properties": {
                "location": "Hellevoetsluis",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2599",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.52922",
                    "51.897"
                ]
            },
            "properties": {
                "location": "Zwijndrecht",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3174",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.16945",
                    "51.4329"
                ]
            },
            "properties": {
                "location": "‘S-Hertogenbosch",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2595",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.1214",
                    "52.0907"
                ]
            },
            "properties": {
                "location": "Utrecht",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2314",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.08",
                    "52.11"
                ]
            },
            "properties": {
                "location": "Utrecht",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3007",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.0189",
                    "52.129"
                ]
            },
            "properties": {
                "location": "Maarssen",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3334",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.91277",
                    "52.3959"
                ]
            },
            "properties": {
                "location": "Amsterdam",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3172",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.4",
                    "52.7"
                ]
            },
            "properties": {
                "location": "IJsselmeer",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2244",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.6625",
                    "52.3567"
                ]
            },
            "properties": {
                "location": "Almelo",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3006",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.918",
                    "52.964"
                ]
            },
            "properties": {
                "location": "Heerenveen",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3260",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.45958",
                    "52.5513"
                ]
            },
            "properties": {
                "location": "Lelystad",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2470",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.05564",
                    "52.5334"
                ]
            },
            "properties": {
                "location": "Zwolle",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2697",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.21386",
                    "53.357"
                ]
            },
            "properties": {
                "location": "Wadscan AIS",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2590",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.73655",
                    "51.5983"
                ]
            },
            "properties": {
                "location": "Breda",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2588",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.28475",
                    "52.0806"
                ]
            },
            "properties": {
                "location": "The Hague",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3390",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.7709",
                    "51.7413"
                ]
            },
            "properties": {
                "location": "Alphen aan den Rijn",
                "country": "nl",
                "lastSignal": "1647592202"
            }
        },
        {
            "type": "Feature",
            "id": "2835",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.72357",
                    "51.8272"
                ]
            },
            "properties": {
                "location": "Papendrecht",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2077",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.4399",
                    "51.9192"
                ]
            },
            "properties": {
                "location": "Rotterdam",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3086",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.13217",
                    "52.4495"
                ]
            },
            "properties": {
                "location": "Andijk",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2574",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.04665",
                    "52.5325"
                ]
            },
            "properties": {
                "location": "Zwolle",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3166",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.83511",
                    "52.4418"
                ]
            },
            "properties": {
                "location": "Zaandam",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2878",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.16227",
                    "51.9066"
                ]
            },
            "properties": {
                "location": "Brielle",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2642",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.57932",
                    "51.8079"
                ]
            },
            "properties": {
                "location": "Dordrecht",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2694",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.92653",
                    "53.1789"
                ]
            },
            "properties": {
                "location": "Suwald",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2082",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.84367",
                    "52.3445"
                ]
            },
            "properties": {
                "location": "Amsterdam",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2409",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.84093",
                    "51.6929"
                ]
            },
            "properties": {
                "location": "Geertruidenberg",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2298",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.47986",
                    "51.9083"
                ]
            },
            "properties": {
                "location": "Rotterdam",
                "country": "nl",
                "lastSignal": "1648193101"
            }
        },
        {
            "type": "Feature",
            "id": "2086",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.41141",
                    "52.1993"
                ]
            },
            "properties": {
                "location": "Katwijk",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3082",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.66902",
                    "52.1549"
                ]
            },
            "properties": {
                "location": "Alphen Aan Den Rijn",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2831",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.33561",
                    "51.9848"
                ]
            },
            "properties": {
                "location": "Delft",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2691",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.0574",
                    "52.0954"
                ]
            },
            "properties": {
                "location": "Utrecht",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2782",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.6796",
                    "53.0306"
                ]
            },
            "properties": {
                "location": "Sneek",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2065",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.17",
                    "52.42"
                ]
            },
            "properties": {
                "location": "Ijsselmeer",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3381",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.3572",
                    "51.8557"
                ]
            },
            "properties": {
                "location": "Hoogvliet",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2945",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.24441",
                    "51.9044"
                ]
            },
            "properties": {
                "location": "Rotterdam",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2140",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.4798",
                    "51.9084"
                ]
            },
            "properties": {
                "location": "Rotterdam",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3317",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3.66957",
                    "51.5466"
                ]
            },
            "properties": {
                "location": "Veere",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3159",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.98999",
                    "51.8783"
                ]
            },
            "properties": {
                "location": "Gendt",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2636",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.63408",
                    "52.4733"
                ]
            },
            "properties": {
                "location": "IJmuiden",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2734",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.26577",
                    "52.0575"
                ]
            },
            "properties": {
                "location": "Driebergen",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2596",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.11283",
                    "52.1208"
                ]
            },
            "properties": {
                "location": "Utrecht",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2459",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.05",
                    "52.5"
                ]
            },
            "properties": {
                "location": "Edam",
                "country": "nl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3448",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.67412",
                    "51.8405"
                ]
            },
            "properties": {
                "location": "Alblasserdam",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3078",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "4.93231",
                    "52.3699"
                ]
            },
            "properties": {
                "location": "Amsterdam",
                "country": "nl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2544",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "176.178",
                    "-37.6315"
                ]
            },
            "properties": {
                "location": "Tauranga",
                "country": "nz",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2926",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "173.894",
                    "-41.1698"
                ]
            },
            "properties": {
                "location": "Nikau Bay",
                "country": "nz",
                "lastSignal": "1646267702"
            }
        },
        {
            "type": "Feature",
            "id": "2770",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "173.975",
                    "-41.2968"
                ]
            },
            "properties": {
                "location": "Marlborough Sounds",
                "country": "nz",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2761",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "174.774",
                    "-41.2831"
                ]
            },
            "properties": {
                "location": "Wellington",
                "country": "nz",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2611",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "176.167",
                    "-37.6854"
                ]
            },
            "properties": {
                "location": "Tauranga",
                "country": "nz",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2114",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "174.748",
                    "-36.7264"
                ]
            },
            "properties": {
                "location": "Auckland",
                "country": "nz",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2188",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "176.167",
                    "-37.6833"
                ]
            },
            "properties": {
                "location": "Tauranga",
                "country": "nz",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2518",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "175.023",
                    "-37.6191"
                ]
            },
            "properties": {
                "location": "Waikato",
                "country": "nz",
                "lastSignal": "1648037402"
            }
        },
        {
            "type": "Feature",
            "id": "2830",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "174.907",
                    "-41.22"
                ]
            },
            "properties": {
                "location": "Wellington",
                "country": "nz",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2780",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "172.637",
                    "-43.5742"
                ]
            },
            "properties": {
                "location": "Christchurch",
                "country": "nz",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2943",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "174.213",
                    "-35.2706"
                ]
            },
            "properties": {
                "location": "Auckland",
                "country": "nz",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3151",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.4031",
                    "63.4416"
                ]
            },
            "properties": {
                "location": "Trondheim",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2928",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "23.4157",
                    "70.4017"
                ]
            },
            "properties": {
                "location": "Hammerfest",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2453",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.94669",
                    "58.1359"
                ]
            },
            "properties": {
                "location": "Kristiansand",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3065",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.268",
                    "59.4136"
                ]
            },
            "properties": {
                "location": "Haugesund",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3370",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.3747",
                    "67.2872"
                ]
            },
            "properties": {
                "location": "Bodo",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2122",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.31",
                    "58.16"
                ]
            },
            "properties": {
                "location": "Homborsund",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2506",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.60105",
                    "58.0906"
                ]
            },
            "properties": {
                "location": "Lista",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2430",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.14565",
                    "62.4696"
                ]
            },
            "properties": {
                "location": "Ålesund",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3296",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.7184",
                    "58.949"
                ]
            },
            "properties": {
                "location": "Stavanger",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2341",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.8431",
                    "59.1088"
                ]
            },
            "properties": {
                "location": "Papper",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2982",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.9339",
                    "69.6783"
                ]
            },
            "properties": {
                "location": "Tromso",
                "country": "no",
                "lastSignal": "1648191902"
            }
        },
        {
            "type": "Feature",
            "id": "2366",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.4272",
                    "63.4394"
                ]
            },
            "properties": {
                "location": "Trondheim",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2257",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.3085",
                    "63.6685"
                ]
            },
            "properties": {
                "location": "Titran",
                "country": "no",
                "lastSignal": "1648130702"
            }
        },
        {
            "type": "Feature",
            "id": "3134",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.5002",
                    "59.7561"
                ]
            },
            "properties": {
                "location": "Nærsnes",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2562",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "17.6178",
                    "69.0534"
                ]
            },
            "properties": {
                "location": "Brostadbotn",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2764",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.27093",
                    "59.1453"
                ]
            },
            "properties": {
                "location": "Skudeneshavn",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2979",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.7409",
                    "59.9017"
                ]
            },
            "properties": {
                "location": "Oslo",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2393",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "15.4566",
                    "67.1487"
                ]
            },
            "properties": {
                "location": "Rognan",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2804",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.3923",
                    "59.5691"
                ]
            },
            "properties": {
                "location": "Oslo",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2586",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.6152",
                    "59.4378"
                ]
            },
            "properties": {
                "location": "Jeloy, Moss",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3196",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.457",
                    "68.3911"
                ]
            },
            "properties": {
                "location": "Straumnes",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3349",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.303",
                    "60.5059"
                ]
            },
            "properties": {
                "location": "Hordvik",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2221",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.703",
                    "58.935"
                ]
            },
            "properties": {
                "location": "Stavanger",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3242",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.26366",
                    "59.4747"
                ]
            },
            "properties": {
                "location": "Haugesund",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2705",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.2215",
                    "59.1789"
                ]
            },
            "properties": {
                "location": "Sandefjord",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2141",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.73521",
                    "58.8524"
                ]
            },
            "properties": {
                "location": "Sandnes",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2581",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.00218",
                    "58.0225"
                ]
            },
            "properties": {
                "location": "Korshamn",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2589",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.4867",
                    "59.878"
                ]
            },
            "properties": {
                "location": "Asker",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2051",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.3592",
                    "66.3698"
                ]
            },
            "properties": {
                "location": "Lovund",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3171",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.29",
                    "58.34"
                ]
            },
            "properties": {
                "location": "Sokndal",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3052",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.5212",
                    "59.8853"
                ]
            },
            "properties": {
                "location": "Sandvika",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3331",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "6.55773",
                    "58.4536"
                ]
            },
            "properties": {
                "location": "Moi",
                "country": "no",
                "lastSignal": "1648187102"
            }
        },
        {
            "type": "Feature",
            "id": "2198",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.73631",
                    "63.1078"
                ]
            },
            "properties": {
                "location": "Kristiansund",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2384",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.77096",
                    "63.1085"
                ]
            },
            "properties": {
                "location": "Kristiansund",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2063",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.012",
                    "66.5"
                ]
            },
            "properties": {
                "location": "Traena",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2174",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "8.8377",
                    "63.657"
                ]
            },
            "properties": {
                "location": "Knutshaugen",
                "country": "no",
                "lastSignal": "1648104601"
            }
        },
        {
            "type": "Feature",
            "id": "2462",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.7263",
                    "59.9088"
                ]
            },
            "properties": {
                "location": "Oslo",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2829",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.1842",
                    "63.8705"
                ]
            },
            "properties": {
                "location": "Inderoy",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2689",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "5.27013",
                    "59.43"
                ]
            },
            "properties": {
                "location": "Haugesund",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2778",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "19.0177",
                    "69.6661"
                ]
            },
            "properties": {
                "location": "Tromsø",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2942",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.5461",
                    "68.076"
                ]
            },
            "properties": {
                "location": "Ballstad",
                "country": "no",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2211",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "10.3839",
                    "59.1759"
                ]
            },
            "properties": {
                "location": "Kjopmannskjaer",
                "country": "no",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2542",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-79.5159",
                    "9.02"
                ]
            },
            "properties": {
                "location": "Panama City",
                "country": "pa",
                "lastSignal": "1644677101"
            }
        },
        {
            "type": "Feature",
            "id": "2810",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "120.971",
                    "14.3866"
                ]
            },
            "properties": {
                "location": "Imus",
                "country": "ph",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3400",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "121.001",
                    "14.4873"
                ]
            },
            "properties": {
                "location": "Paranaque",
                "country": "ph",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2043",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.5842",
                    "53.4766"
                ]
            },
            "properties": {
                "location": "Szczecin-Golecin",
                "country": "pl",
                "lastSignal": "1647817801"
            }
        },
        {
            "type": "Feature",
            "id": "3220",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.6807",
                    "53.3992"
                ]
            },
            "properties": {
                "location": "Szczecin, Dąbie",
                "country": "pl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3013",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.5171",
                    "53.4046"
                ]
            },
            "properties": {
                "location": "Szczecin",
                "country": "pl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3095",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.5453",
                    "53.4518"
                ]
            },
            "properties": {
                "location": "Szczecin",
                "country": "pl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3168",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.5943",
                    "54.4177"
                ]
            },
            "properties": {
                "location": "Gdańsk",
                "country": "pl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3226",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "14.6501",
                    "53.4551"
                ]
            },
            "properties": {
                "location": "Szczecin",
                "country": "pl",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2031",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.4345",
                    "54.4967"
                ]
            },
            "properties": {
                "location": "Gdynia",
                "country": "pl",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2509",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-8.67982",
                    "41.1725"
                ]
            },
            "properties": {
                "location": "Matosinhos",
                "country": "pt",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2242",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-25.6667",
                    "37.7333"
                ]
            },
            "properties": {
                "location": "Ponta Delgada",
                "country": "pt",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3299",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-9.01",
                    "38.53"
                ]
            },
            "properties": {
                "location": "Azeitão",
                "country": "pt",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3205",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-9.41222",
                    "38.9494"
                ]
            },
            "properties": {
                "location": "Carvoeira",
                "country": "pt",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2385",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-8.70697",
                    "41.1872"
                ]
            },
            "properties": {
                "location": "Porto",
                "country": "pt",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2281",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-8.879",
                    "37.954"
                ]
            },
            "properties": {
                "location": "Sines",
                "country": "pt",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2183",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-9.13567",
                    "38.7332"
                ]
            },
            "properties": {
                "location": "Lisbon",
                "country": "pt",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2059",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-25.5061",
                    "37.7946"
                ]
            },
            "properties": {
                "location": "Azores",
                "country": "pt",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2252",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-9.376",
                    "38.969"
                ]
            },
            "properties": {
                "location": "Mafra",
                "country": "pt",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2498",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-9.01",
                    "38.53"
                ]
            },
            "properties": {
                "location": "Azeitão",
                "country": "pt",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2205",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-8.44",
                    "37.18"
                ]
            },
            "properties": {
                "location": "Silves",
                "country": "pt",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2419",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-9.30453",
                    "38.7375"
                ]
            },
            "properties": {
                "location": "Porto Salvo",
                "country": "pt",
                "lastSignal": "1648192801"
            }
        },
        {
            "type": "Feature",
            "id": "3386",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-9.1393",
                    "38.7223"
                ]
            },
            "properties": {
                "location": "Lisbon",
                "country": "pt",
                "lastSignal": "1647891602"
            }
        },
        {
            "type": "Feature",
            "id": "3280",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "55.3314",
                    "-20.9535"
                ]
            },
            "properties": {
                "location": "La Possession",
                "country": "re",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2696",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "28.6187",
                    "44.1487"
                ]
            },
            "properties": {
                "location": "Constanta Gara",
                "country": "ro",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2732",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "33.0861",
                    "68.9962"
                ]
            },
            "properties": {
                "location": "Murmansk",
                "country": "ru",
                "lastSignal": "1646552401"
            }
        },
        {
            "type": "Feature",
            "id": "2210",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "43.9702",
                    "56.3359"
                ]
            },
            "properties": {
                "location": "Nizhny Novogorod",
                "country": "ru",
                "lastSignal": "1648104601"
            }
        },
        {
            "type": "Feature",
            "id": "2334",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "28.4683",
                    "59.7377"
                ]
            },
            "properties": {
                "location": "Ust-Luga",
                "country": "ru",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3057",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "104.385",
                    "52.228"
                ]
            },
            "properties": {
                "location": "Irkutsk",
                "country": "ru",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2034",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "29.7061",
                    "59.9223"
                ]
            },
            "properties": {
                "location": "Lomonosov",
                "country": "ru",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3332",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "131.914",
                    "43.1181"
                ]
            },
            "properties": {
                "location": "Vladivostok",
                "country": "ru",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2424",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "37.7924",
                    "44.6838"
                ]
            },
            "properties": {
                "location": "Novorossiysk",
                "country": "ru",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2219",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "20.5396",
                    "54.7281"
                ]
            },
            "properties": {
                "location": "Kaliningrad",
                "country": "ru",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2516",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "33.0925",
                    "68.9792"
                ]
            },
            "properties": {
                "location": "Murmansk",
                "country": "ru",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2270",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-63.0915",
                    "18.0283"
                ]
            },
            "properties": {
                "location": "Simpson Bay",
                "country": "mf",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2106",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-17.042",
                    "14.4502"
                ]
            },
            "properties": {
                "location": "Ngaparou",
                "country": "sn",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2088",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "103.85",
                    "1.26"
                ]
            },
            "properties": {
                "location": "Singapore",
                "country": "sg",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2855",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "103.936",
                    "1.31069"
                ]
            },
            "properties": {
                "location": "Singapore",
                "country": "sg",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2851",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "103.906",
                    "1.38778"
                ]
            },
            "properties": {
                "location": "Singapore",
                "country": "sg",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2902",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "103.841",
                    "1.31379"
                ]
            },
            "properties": {
                "location": "Singapore",
                "country": "sg",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2886",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "103.812",
                    "1.36388"
                ]
            },
            "properties": {
                "location": "Singapore",
                "country": "sg",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3080",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "103.9",
                    "1.3"
                ]
            },
            "properties": {
                "location": "Singapore",
                "country": "sg",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2997",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "103.811",
                    "1.26625"
                ]
            },
            "properties": {
                "location": "Keppel Bay",
                "country": "sg",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2485",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "17.317",
                    "48.0926"
                ]
            },
            "properties": {
                "location": "Miloslavov",
                "country": "sk",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2344",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.9758",
                    "45.5338"
                ]
            },
            "properties": {
                "location": "Koper",
                "country": "si",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2511",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.4844",
                    "-33.7959"
                ]
            },
            "properties": {
                "location": "Cape Town",
                "country": "za",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2132",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "31.0292",
                    "-29.8579"
                ]
            },
            "properties": {
                "location": "Durban",
                "country": "za",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3475",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.0161",
                    "-33.1209"
                ]
            },
            "properties": {
                "location": "Langebaan",
                "country": "za",
                "lastSignal": "1645075802"
            }
        },
        {
            "type": "Feature",
            "id": "2790",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "31.0819",
                    "-29.7178"
                ]
            },
            "properties": {
                "location": "Umhlanga",
                "country": "za",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2683",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-16.4524",
                    "28.4579"
                ]
            },
            "properties": {
                "location": "La Matanza de Acentejo",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3375",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.79062",
                    "43.4618"
                ]
            },
            "properties": {
                "location": "Santander",
                "country": "es",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3437",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.3424",
                    "39.4794"
                ]
            },
            "properties": {
                "location": "Valencia",
                "country": "es",
                "lastSignal": "1644837602"
            }
        },
        {
            "type": "Feature",
            "id": "2773",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.069",
                    "39.85"
                ]
            },
            "properties": {
                "location": "Burriana",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2729",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.75602",
                    "37.8218"
                ]
            },
            "properties": {
                "location": "Murcia",
                "country": "es",
                "lastSignal": "1647527401"
            }
        },
        {
            "type": "Feature",
            "id": "3303",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-6.62167",
                    "45.2583"
                ]
            },
            "properties": {
                "location": "Lugo",
                "country": "es",
                "lastSignal": "1648174202"
            }
        },
        {
            "type": "Feature",
            "id": "2624",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-15.4524",
                    "28.0805"
                ]
            },
            "properties": {
                "location": "Las Palmas de Gran Canaria",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2922",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-5.31904",
                    "36.2413"
                ]
            },
            "properties": {
                "location": "Alcaidesa",
                "country": "es",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2240",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.7523",
                    "41.1819"
                ]
            },
            "properties": {
                "location": "Vilanova i la Geltru",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3298",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-8.84622",
                    "42.1192"
                ]
            },
            "properties": {
                "location": "Baiona",
                "country": "es",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3139",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-16.7709",
                    "28.1218"
                ]
            },
            "properties": {
                "location": "Canary Islands, Tenerife, Playa Paraiso",
                "country": "es",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3428",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.3327",
                    "39.4609"
                ]
            },
            "properties": {
                "location": "Valencia",
                "country": "es",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3209",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.42127",
                    "36.7213"
                ]
            },
            "properties": {
                "location": "Malaga",
                "country": "es",
                "lastSignal": "1646717402"
            }
        },
        {
            "type": "Feature",
            "id": "2469",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.7628",
                    "43.3698"
                ]
            },
            "properties": {
                "location": "Sollube - Bizkaia",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2720",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-9.00958",
                    "42.2409"
                ]
            },
            "properties": {
                "location": "Vigo Bay",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3291",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-15.4238",
                    "28.1408"
                ]
            },
            "properties": {
                "location": "Las Palmas de Gran Canaria",
                "country": "es",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3480",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.4217",
                    "36.7212"
                ]
            },
            "properties": {
                "location": "Malaga",
                "country": "es",
                "lastSignal": "1648143003"
            }
        },
        {
            "type": "Feature",
            "id": "3273",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-16.2714",
                    "28.4672"
                ]
            },
            "properties": {
                "location": "Santa Cruz De Tenerife",
                "country": "es",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3245",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.42843",
                    "36.7258"
                ]
            },
            "properties": {
                "location": "Málaga",
                "country": "es",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2302",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-5.26917",
                    "43.515"
                ]
            },
            "properties": {
                "location": "Llastres",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2962",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "2.14113",
                    "41.4104"
                ]
            },
            "properties": {
                "location": "Barcelona",
                "country": "es",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2602",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.9659",
                    "41.2752"
                ]
            },
            "properties": {
                "location": "Castelldefels",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3239",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-8.42576",
                    "43.3647"
                ]
            },
            "properties": {
                "location": "A Coruña",
                "country": "es",
                "lastSignal": "1648193402"
            }
        },
        {
            "type": "Feature",
            "id": "2146",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "2.625",
                    "39.5625"
                ]
            },
            "properties": {
                "location": "Palma de Mallorca",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2959",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.3425",
                    "39.4805"
                ]
            },
            "properties": {
                "location": "Valencia",
                "country": "es",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3465",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.62473",
                    "36.54"
                ]
            },
            "properties": {
                "location": "Fuengirola",
                "country": "es",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2147",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-16.2467",
                    "28.4702"
                ]
            },
            "properties": {
                "location": "S/C de Tenerife",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2325",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.43789",
                    "36.8196"
                ]
            },
            "properties": {
                "location": "Almeria",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2076",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.802267",
                    "37.7997"
                ]
            },
            "properties": {
                "location": "Murcia",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3318",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "3",
                    "43.2"
                ]
            },
            "properties": {
                "location": "Getxo",
                "country": "es",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2526",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-5.84476",
                    "43.3603"
                ]
            },
            "properties": {
                "location": "Oviedo",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2367",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0.603318",
                    "40.6203"
                ]
            },
            "properties": {
                "location": "Sant Carles de la Rapita",
                "country": "es",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2476",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "20.149",
                    "63.6639"
                ]
            },
            "properties": {
                "location": "Norrmjöle",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2304",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.4886",
                    "59.2791"
                ]
            },
            "properties": {
                "location": "Ingarostrand",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2628",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "16.1874",
                    "58.6961"
                ]
            },
            "properties": {
                "location": "Nyköping",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3068",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.0085",
                    "59.3237"
                ]
            },
            "properties": {
                "location": "Stockholm",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2505",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.976",
                    "57.6892"
                ]
            },
            "properties": {
                "location": "Göteborg",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2988",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.2782",
                    "58.2722"
                ]
            },
            "properties": {
                "location": "Trollhättan",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2480",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "17.747",
                    "59.484"
                ]
            },
            "properties": {
                "location": "Kungsangen",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2266",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.9104",
                    "59.7518"
                ]
            },
            "properties": {
                "location": "Sodersvik",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2767",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.9841",
                    "57.6844"
                ]
            },
            "properties": {
                "location": "Göteborg",
                "country": "se",
                "lastSignal": "1648193102"
            }
        },
        {
            "type": "Feature",
            "id": "2203",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.3234",
                    "58.3807"
                ]
            },
            "properties": {
                "location": "Vanersborg",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2319",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.1637",
                    "59.3159"
                ]
            },
            "properties": {
                "location": "Stockholm",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3200",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.7168",
                    "59.2628"
                ]
            },
            "properties": {
                "location": "Täby",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2032",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.06",
                    "59.332"
                ]
            },
            "properties": {
                "location": "Stockholm",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2978",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "19.44",
                    "63.5412"
                ]
            },
            "properties": {
                "location": "Rundvik",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3285",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.19",
                    "55.703"
                ]
            },
            "properties": {
                "location": "Lund",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2977",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.0009",
                    "57.6768"
                ]
            },
            "properties": {
                "location": "Krokslätt",
                "country": "se",
                "lastSignal": "1648186802"
            }
        },
        {
            "type": "Feature",
            "id": "2451",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "17.9338",
                    "59.0228"
                ]
            },
            "properties": {
                "location": "Segersang",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2494",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.344",
                    "59.281"
                ]
            },
            "properties": {
                "location": "Ingarö",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3406",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.8635",
                    "57.6668"
                ]
            },
            "properties": {
                "location": "Gothenburg",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2355",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.7708",
                    "57.7022"
                ]
            },
            "properties": {
                "location": "Torslanda, Gothenburg",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2164",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.6453",
                    "57.7086"
                ]
            },
            "properties": {
                "location": "Goteborg, West Coast",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3471",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "17.9836",
                    "58.9606"
                ]
            },
            "properties": {
                "location": "Wik-Inn Marina",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3270",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.9601",
                    "57.5154"
                ]
            },
            "properties": {
                "location": "Gothenburg - SW, Särö",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2752",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.8169",
                    "60.0983"
                ]
            },
            "properties": {
                "location": "Grisslehamn",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3345",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.6507",
                    "59.2906"
                ]
            },
            "properties": {
                "location": "Stockholm",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2604",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "17.9604",
                    "59.351"
                ]
            },
            "properties": {
                "location": "Sundbyberg",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2011",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.9746",
                    "57.7089"
                ]
            },
            "properties": {
                "location": "Gothenburg",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3176",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "18.44",
                    "59.29"
                ]
            },
            "properties": {
                "location": "Näsudden Ingarö",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3265",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "17.95",
                    "62.6304"
                ]
            },
            "properties": {
                "location": "Härnösand",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2192",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "16.1588",
                    "58.5952"
                ]
            },
            "properties": {
                "location": "Norrköping",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3466",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "17.922",
                    "59.4566"
                ]
            },
            "properties": {
                "location": "Sollentuna",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2262",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "15.6355",
                    "56.1689"
                ]
            },
            "properties": {
                "location": "Karlskrona",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2109",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "16.6734",
                    "57.4135"
                ]
            },
            "properties": {
                "location": "Oskarshamn",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2450",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "12.38",
                    "58.28"
                ]
            },
            "properties": {
                "location": "Trollhattan",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3464",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "11.919",
                    "57.704"
                ]
            },
            "properties": {
                "location": "Gothenburg",
                "country": "se",
                "lastSignal": "1646597402"
            }
        },
        {
            "type": "Feature",
            "id": "2956",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "16.7259",
                    "57.893"
                ]
            },
            "properties": {
                "location": "Loftahammar",
                "country": "se",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2342",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "16.55",
                    "58.583"
                ]
            },
            "properties": {
                "location": "Ostra Husby",
                "country": "se",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3144",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "13.5934",
                    "45.1736"
                ]
            },
            "properties": {
                "location": "Bern",
                "country": "ch",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2434",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "7.69733",
                    "47.5271"
                ]
            },
            "properties": {
                "location": "Pratteln",
                "country": "ch",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3154",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "120.301",
                    "22.5911"
                ]
            },
            "properties": {
                "location": "Kaohsiung",
                "country": "tw",
                "lastSignal": "1646270702"
            }
        },
        {
            "type": "Feature",
            "id": "2913",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "121.513",
                    "25.0467"
                ]
            },
            "properties": {
                "location": "Taipei",
                "country": "tw",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2592",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "121.78",
                    "25.1509"
                ]
            },
            "properties": {
                "location": "Keelung, National Taiwan Ocean University",
                "country": "tw",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2353",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "120.303",
                    "22.6144"
                ]
            },
            "properties": {
                "location": "Kaohsiung",
                "country": "tw",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3046",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "121.778",
                    "25.1493"
                ]
            },
            "properties": {
                "location": "Keelung, National Taiwan Ocean University",
                "country": "tw",
                "lastSignal": "1647573002"
            }
        },
        {
            "type": "Feature",
            "id": "2750",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "121.483",
                    "25.263"
                ]
            },
            "properties": {
                "location": "New Taipei",
                "country": "tw",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2338",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "120.301",
                    "22.6273"
                ]
            },
            "properties": {
                "location": "Kaohsiung",
                "country": "tw",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2794",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "121.739",
                    "25.1276"
                ]
            },
            "properties": {
                "location": "Keelung",
                "country": "tw",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3308",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "121.044",
                    "24.7767"
                ]
            },
            "properties": {
                "location": "Chutung",
                "country": "tw",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2983",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "100.562",
                    "13.7464"
                ]
            },
            "properties": {
                "location": "Bangkok",
                "country": "th",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3492",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-61.5236",
                    "10.6644"
                ]
            },
            "properties": {
                "location": "Port of Spain",
                "country": "tt",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2370",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-61.632",
                    "10.678"
                ]
            },
            "properties": {
                "location": "Chaguaramas",
                "country": "tt",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3434",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "28.8796",
                    "40.9913"
                ]
            },
            "properties": {
                "location": "Istanbul",
                "country": "tr",
                "lastSignal": "1647399002"
            }
        },
        {
            "type": "Feature",
            "id": "3012",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "28.9784",
                    "41.0082"
                ]
            },
            "properties": {
                "location": "Istanbul",
                "country": "tr",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2111",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "28.0518",
                    "38.9232"
                ]
            },
            "properties": {
                "location": "Göcek",
                "country": "tr",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2310",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "32.01",
                    "46.96"
                ]
            },
            "properties": {
                "location": "Nikolaev",
                "country": "ua",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2401",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "30.7592",
                    "46.4767"
                ]
            },
            "properties": {
                "location": "Odessa",
                "country": "ua",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3391",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "31.9617",
                    "46.8311"
                ]
            },
            "properties": {
                "location": "Mykolaiv",
                "country": "ua",
                "lastSignal": "1646132402"
            }
        },
        {
            "type": "Feature",
            "id": "2703",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "55.2584",
                    "25.1881"
                ]
            },
            "properties": {
                "location": "Dubai",
                "country": "ae",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2591",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "54.67",
                    "24.73"
                ]
            },
            "properties": {
                "location": "Abu Dhabi",
                "country": "ae",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2206",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.0342",
                    "53.3382"
                ]
            },
            "properties": {
                "location": "Cleethorpes",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2142",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-6.2936",
                    "58.4688"
                ]
            },
            "properties": {
                "location": "Isle of Lewis, Scotland",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2297",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.733",
                    "52.617"
                ]
            },
            "properties": {
                "location": "Great Yarmouth",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2260",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-5.408",
                    "56.5"
                ]
            },
            "properties": {
                "location": "Oban",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2438",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-5.8787",
                    "57.2381"
                ]
            },
            "properties": {
                "location": "Scotland, Broadford Bay, Isle of Skye",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2312",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.68306",
                    "51.4906"
                ]
            },
            "properties": {
                "location": "Bristol",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2862",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-5.639",
                    "54.646"
                ]
            },
            "properties": {
                "location": "Bangor, County Down",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2073",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.34994",
                    "51.9612"
                ]
            },
            "properties": {
                "location": "Felixstowe",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3063",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.90194",
                    "50.7507"
                ]
            },
            "properties": {
                "location": "Bournemouth",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2066",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3",
                    "59"
                ]
            },
            "properties": {
                "location": "Orkney",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2474",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.14213",
                    "50.7931"
                ]
            },
            "properties": {
                "location": "Gosport",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2054",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.2135",
                    "50.8463"
                ]
            },
            "properties": {
                "location": "Solent",
                "country": "gb",
                "lastSignal": "1648167001"
            }
        },
        {
            "type": "Feature",
            "id": "2191",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.11839",
                    "57.1582"
                ]
            },
            "properties": {
                "location": "Aberdeen",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3137",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.1372",
                    "50.8225"
                ]
            },
            "properties": {
                "location": "Brighton",
                "country": "gb",
                "lastSignal": "1647774302"
            }
        },
        {
            "type": "Feature",
            "id": "2021",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.76112",
                    "52.479"
                ]
            },
            "properties": {
                "location": "Suffolk",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2620",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.24993",
                    "50.8878"
                ]
            },
            "properties": {
                "location": "Fareham",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2522",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.51434",
                    "50.4638"
                ]
            },
            "properties": {
                "location": "Torquay",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2377",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.988",
                    "50.715"
                ]
            },
            "properties": {
                "location": "Poole",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2980",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.79833",
                    "50.2243"
                ]
            },
            "properties": {
                "location": "Bolt Head Airfield",
                "country": "gb",
                "lastSignal": "1645109702"
            }
        },
        {
            "type": "Feature",
            "id": "2808",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.16604",
                    "50.8485"
                ]
            },
            "properties": {
                "location": "Fareham",
                "country": "gb",
                "lastSignal": "1645438201"
            }
        },
        {
            "type": "Feature",
            "id": "2120",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.2842",
                    "56.8287"
                ]
            },
            "properties": {
                "location": "Gourdon",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2854",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.6212",
                    "54.4828"
                ]
            },
            "properties": {
                "location": "Whitby",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2116",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.62979",
                    "55.3927"
                ]
            },
            "properties": {
                "location": "Alnmouth",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3416",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.02083",
                    "51.507"
                ]
            },
            "properties": {
                "location": "London",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3259",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.05175",
                    "50.8416"
                ]
            },
            "properties": {
                "location": "Portsmouth",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2160",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.77493",
                    "57.5092"
                ]
            },
            "properties": {
                "location": "Scotland, Peterhead",
                "country": "gb",
                "lastSignal": "1647253202"
            }
        },
        {
            "type": "Feature",
            "id": "3356",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.02911",
                    "55.942"
                ]
            },
            "properties": {
                "location": "Musselburgh",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3257",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.40546",
                    "50.864"
                ]
            },
            "properties": {
                "location": "Hythe, Hampshire",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3481",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.41555",
                    "52.8853"
                ]
            },
            "properties": {
                "location": "Mundesley",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2410",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.87645",
                    "55.943"
                ]
            },
            "properties": {
                "location": "Gourock",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2716",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.1308",
                    "50.8107"
                ]
            },
            "properties": {
                "location": "Gosport",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2313",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.44438",
                    "51.3624"
                ]
            },
            "properties": {
                "location": "Broadstairs, Kent",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2758",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.729",
                    "56.006"
                ]
            },
            "properties": {
                "location": "Helensburgh",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2439",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.535",
                    "54.4339"
                ]
            },
            "properties": {
                "location": "Robin Hoods Bay",
                "country": "gb",
                "lastSignal": "1648160401"
            }
        },
        {
            "type": "Feature",
            "id": "2713",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.772",
                    "55.9565"
                ]
            },
            "properties": {
                "location": "Scotland, Greenock",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3351",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.260833",
                    "50.8442"
                ]
            },
            "properties": {
                "location": "Shoreham",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2901",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.42357",
                    "50.6178"
                ]
            },
            "properties": {
                "location": "Exmouth",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2055",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.07625",
                    "51.5135"
                ]
            },
            "properties": {
                "location": "London & Poole",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2558",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-5.0656",
                    "51.7082"
                ]
            },
            "properties": {
                "location": "Gelliswick",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3244",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.3202",
                    "51.3486"
                ]
            },
            "properties": {
                "location": "Manston",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2540",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.50333",
                    "55.1094"
                ]
            },
            "properties": {
                "location": "Blyth",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3243",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-5.66509",
                    "54.4111"
                ]
            },
            "properties": {
                "location": "Killyleagh",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2967",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.023",
                    "53.39"
                ]
            },
            "properties": {
                "location": "Birkenhead",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2708",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.15",
                    "50.85"
                ]
            },
            "properties": {
                "location": "Fareham",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2896",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.74",
                    "50.82"
                ]
            },
            "properties": {
                "location": "Bude",
                "country": "gb",
                "lastSignal": "1644585002"
            }
        },
        {
            "type": "Feature",
            "id": "2964",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.09043",
                    "57.1449"
                ]
            },
            "properties": {
                "location": "Aberdeen",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2045",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.6354",
                    "50.8325"
                ]
            },
            "properties": {
                "location": "Portsmouth",
                "country": "gb",
                "lastSignal": "1647006001"
            }
        },
        {
            "type": "Feature",
            "id": "2843",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0.370759",
                    "51.4419"
                ]
            },
            "properties": {
                "location": "Gravesend",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2286",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-6.4564",
                    "55.6937"
                ]
            },
            "properties": {
                "location": "Isle of Islay Scotland",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2333",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "TEST-EP",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2839",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.09154",
                    "50.799"
                ]
            },
            "properties": {
                "location": "Portsmouth",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2133",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.5",
                    "58.4167"
                ]
            },
            "properties": {
                "location": "Caithness (Scotland)",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3096",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.0342",
                    "53.4281"
                ]
            },
            "properties": {
                "location": "Wirral",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2647",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.195",
                    "55.966"
                ]
            },
            "properties": {
                "location": "Edinburgh",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2837",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.518",
                    "51.7563"
                ]
            },
            "properties": {
                "location": "Oldcroft",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2060",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.992471",
                    "50.8439"
                ]
            },
            "properties": {
                "location": "Solent",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2885",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.1909",
                    "54.6949"
                ]
            },
            "properties": {
                "location": "Hartlepool",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2583",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-5.91058",
                    "57.2403"
                ]
            },
            "properties": {
                "location": "Broadford",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2309",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.44588",
                    "51.6045"
                ]
            },
            "properties": {
                "location": "ABXAIS",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2787",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.20146",
                    "54.7018"
                ]
            },
            "properties": {
                "location": "Redcar",
                "country": "gb",
                "lastSignal": "1644495301"
            }
        },
        {
            "type": "Feature",
            "id": "2020",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3",
                    "53.4"
                ]
            },
            "properties": {
                "location": "Liverpool",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2575",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.38877",
                    "56.0201"
                ]
            },
            "properties": {
                "location": "Inverkeithing",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2103",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.184878",
                    "51.4112"
                ]
            },
            "properties": {
                "location": "London",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2883",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.3905",
                    "50.9305"
                ]
            },
            "properties": {
                "location": "Southampton",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3387",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.02358",
                    "53.3801"
                ]
            },
            "properties": {
                "location": "Birkenhead",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3329",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.22",
                    "50.32"
                ]
            },
            "properties": {
                "location": "Plymouth",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2411",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-5.89",
                    "54.405"
                ]
            },
            "properties": {
                "location": "Ballynahinch, N.Ireland",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3324",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.006158",
                    "51.4939"
                ]
            },
            "properties": {
                "location": "London",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2159",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.945",
                    "50.607"
                ]
            },
            "properties": {
                "location": "Swanage",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2447",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-77.6088",
                    "43.1566"
                ]
            },
            "properties": {
                "location": "Rochester",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3033",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.76108",
                    "52.4792"
                ]
            },
            "properties": {
                "location": "Norwich",
                "country": "gb",
                "lastSignal": "1647855002"
            }
        },
        {
            "type": "Feature",
            "id": "2876",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-3.05376",
                    "55.9414"
                ]
            },
            "properties": {
                "location": "Musselburgh",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2233",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0.8798",
                    "51.8762"
                ]
            },
            "properties": {
                "location": "Colchester",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3051",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.43647",
                    "53.7244"
                ]
            },
            "properties": {
                "location": "Hessle",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2567",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-4.12592",
                    "50.3906"
                ]
            },
            "properties": {
                "location": "Plymouth",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2131",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-5.05025",
                    "50.4238"
                ]
            },
            "properties": {
                "location": "Newquay-Cornwall",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2735",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "1.73109",
                    "52.4897"
                ]
            },
            "properties": {
                "location": "Lowestoft",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2477",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.338",
                    "53.7385"
                ]
            },
            "properties": {
                "location": "Humber Dock",
                "country": "gb",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2478",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-2.01113",
                    "55.7633"
                ]
            },
            "properties": {
                "location": "Tweedmouth",
                "country": "gb",
                "lastSignal": "1645768202"
            }
        },
        {
            "type": "Feature",
            "id": "3377",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-1.14975",
                    "50.7786"
                ]
            },
            "properties": {
                "location": "Gosport",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2825",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-0.981075",
                    "51.1451"
                ]
            },
            "properties": {
                "location": "Alton",
                "country": "gb",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3376",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.398",
                    "37.8003"
                ]
            },
            "properties": {
                "location": "San Francisco",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3221",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.518",
                    "37.8884"
                ]
            },
            "properties": {
                "location": "Mill Valley, CA",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3307",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-119.842",
                    "34.413"
                ]
            },
            "properties": {
                "location": "Santa Barbara",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2149",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-86.4203",
                    "30.4619"
                ]
            },
            "properties": {
                "location": "Niceville, FL",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2308",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-80.0835",
                    "26.9476"
                ]
            },
            "properties": {
                "location": "Jupiter, FL",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3069",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.925",
                    "47.7027"
                ]
            },
            "properties": {
                "location": "Dabob Bay",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3219",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-70.662",
                    "42.6159"
                ]
            },
            "properties": {
                "location": "Gloucester, Massachusetts",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2990",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-80.1373",
                    "26.1224"
                ]
            },
            "properties": {
                "location": "Fort Lauderdale",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2627",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-72.0461",
                    "41.339"
                ]
            },
            "properties": {
                "location": "Groton CT",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3213",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-80.709",
                    "28.417"
                ]
            },
            "properties": {
                "location": "Merritt Island, FL",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2727",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.708",
                    "45.8175"
                ]
            },
            "properties": {
                "location": "Washingotn",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3369",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-88.2308",
                    "35.055"
                ]
            },
            "properties": {
                "location": "Pickwick Dam, Tennessee",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2161",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.432",
                    "37.8065"
                ]
            },
            "properties": {
                "location": "San Francisco",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2277",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-80.129",
                    "26.057"
                ]
            },
            "properties": {
                "location": "Dania Beach - Florida",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2769",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-65.3009",
                    "18.3028"
                ]
            },
            "properties": {
                "location": "Culebra",
                "country": "us",
                "lastSignal": "1644962402"
            }
        },
        {
            "type": "Feature",
            "id": "2726",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.335",
                    "47.608"
                ]
            },
            "properties": {
                "location": "Seattle",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3210",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-81.9594",
                    "26.5481"
                ]
            },
            "properties": {
                "location": "Florida",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2014",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-73.25",
                    "41.19"
                ]
            },
            "properties": {
                "location": "Fairfield",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2814",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-82.4225",
                    "42.9824"
                ]
            },
            "properties": {
                "location": "Port Huron",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2148",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-90.8913",
                    "32.3637"
                ]
            },
            "properties": {
                "location": "Vicksburg",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2812",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.386",
                    "37.6665"
                ]
            },
            "properties": {
                "location": "San Francisco",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2282",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.338",
                    "47.4327"
                ]
            },
            "properties": {
                "location": "Seattle",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2597",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-118.292",
                    "33.7361"
                ]
            },
            "properties": {
                "location": "San Pedro",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2722",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.141",
                    "48.3295"
                ]
            },
            "properties": {
                "location": "Salish Sea",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2092",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-90.8495",
                    "46.7896"
                ]
            },
            "properties": {
                "location": "Bayfield",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2057",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.383",
                    "47.6667"
                ]
            },
            "properties": {
                "location": "Seattle",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2666",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-76.6094",
                    "39.2992"
                ]
            },
            "properties": {
                "location": "Baltimore",
                "country": "us",
                "lastSignal": "1648193402"
            }
        },
        {
            "type": "Feature",
            "id": "2765",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-82.4526",
                    "27.9642"
                ]
            },
            "properties": {
                "location": "Tampa",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2035",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.335",
                    "47.608"
                ]
            },
            "properties": {
                "location": "Seattle",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2619",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-93.92",
                    "29.9"
                ]
            },
            "properties": {
                "location": "Groves",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3487",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.08",
                    "48.49"
                ]
            },
            "properties": {
                "location": "Friday Harbor",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3133",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-73.9732",
                    "40.8286"
                ]
            },
            "properties": {
                "location": "Edgewater",
                "country": "us",
                "lastSignal": "1648184102"
            }
        },
        {
            "type": "Feature",
            "id": "2806",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.483",
                    "37.85"
                ]
            },
            "properties": {
                "location": "San Francisco",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2033",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122",
                    "38.39"
                ]
            },
            "properties": {
                "location": "Bodega Bay",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2906",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-86.4874",
                    "30.4951"
                ]
            },
            "properties": {
                "location": "Valparaiso",
                "country": "us",
                "lastSignal": "1648131602"
            }
        },
        {
            "type": "Feature",
            "id": "3413",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-80.2429",
                    "42.0483"
                ]
            },
            "properties": {
                "location": "Fairview",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2369",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.335",
                    "47.608"
                ]
            },
            "properties": {
                "location": "Seattle",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2329",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-75.0114",
                    "40.0094"
                ]
            },
            "properties": {
                "location": "Riverton",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2249",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-117.2",
                    "33.0797"
                ]
            },
            "properties": {
                "location": "San Diego",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2614",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-94.922",
                    "29.487"
                ]
            },
            "properties": {
                "location": "Kingwood",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3479",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-117.24",
                    "32.88"
                ]
            },
            "properties": {
                "location": "San Diego",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2484",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-80.1451",
                    "25.7667"
                ]
            },
            "properties": {
                "location": "Biscayne Bay PS - Miami FL",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2449",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-76.8975",
                    "39.1642"
                ]
            },
            "properties": {
                "location": "Johns Hopkins University",
                "country": "us",
                "lastSignal": "1648186801"
            }
        },
        {
            "type": "Feature",
            "id": "2757",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-89.99",
                    "29.9"
                ]
            },
            "properties": {
                "location": "New Orleans",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2408",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-74.039",
                    "40.7137"
                ]
            },
            "properties": {
                "location": "Jersey City, NJ",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2579",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-80.1869",
                    "25.7939"
                ]
            },
            "properties": {
                "location": "Miami",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2200",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-77.2653",
                    "38.9012"
                ]
            },
            "properties": {
                "location": "Vienna, VW",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2802",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-91.1686",
                    "29.7736"
                ]
            },
            "properties": {
                "location": "Morgan City, Louisiana",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2756",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-76.5732",
                    "39.3495"
                ]
            },
            "properties": {
                "location": "Baltimore",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3110",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-90.8779",
                    "32.3526"
                ]
            },
            "properties": {
                "location": "Vicksburg",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2390",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123",
                    "47.2093"
                ]
            },
            "properties": {
                "location": "Shelton Washington",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3271",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-71.513",
                    "41.6343"
                ]
            },
            "properties": {
                "location": "East Greenwich, Rhode Island",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2305",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-80.1332",
                    "26.222"
                ]
            },
            "properties": {
                "location": "Florida, Fort Lauderdale",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2048",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-176.655",
                    "51.8539"
                ]
            },
            "properties": {
                "location": "Adak, Alaska",
                "country": "us",
                "lastSignal": "1648119001"
            }
        },
        {
            "type": "Feature",
            "id": "2800",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.497",
                    "37.76"
                ]
            },
            "properties": {
                "location": "San Francisco",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2056",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-90.0715",
                    "29.9511"
                ]
            },
            "properties": {
                "location": "New Orleans",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3469",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-166.326",
                    "53.5337"
                ]
            },
            "properties": {
                "location": "Dutch Harbor",
                "country": "us",
                "lastSignal": "1646274602"
            }
        },
        {
            "type": "Feature",
            "id": "2707",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-76.3722",
                    "37.0064"
                ]
            },
            "properties": {
                "location": "Hampton, VA",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2466",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-76.5455",
                    "37.9987"
                ]
            },
            "properties": {
                "location": "Callao, VA",
                "country": "us",
                "lastSignal": "1648191602"
            }
        },
        {
            "type": "Feature",
            "id": "2187",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-121.898",
                    "36.604"
                ]
            },
            "properties": {
                "location": "Canada, Monterey",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2023",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-76.5786",
                    "39.2774"
                ]
            },
            "properties": {
                "location": "Baltimore, Maryland",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2028",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.5",
                    "37.85"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2797",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-118.16",
                    "33.8"
                ]
            },
            "properties": {
                "location": "Signal Hill",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2157",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-83.1613",
                    "42.6136"
                ]
            },
            "properties": {
                "location": "Michigan",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3098",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-90.0486",
                    "35.209"
                ]
            },
            "properties": {
                "location": "Orleans",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3342",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.433",
                    "37.8"
                ]
            },
            "properties": {
                "location": "San Francisco",
                "country": "us",
                "lastSignal": "1648155602"
            }
        },
        {
            "type": "Feature",
            "id": "2650",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-93.85",
                    "29.71"
                ]
            },
            "properties": {
                "location": "Sabine Pass, TX",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3261",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-73.7349",
                    "40.9482"
                ]
            },
            "properties": {
                "location": "Mamaroneck",
                "country": "us",
                "lastSignal": "1648191002"
            }
        },
        {
            "type": "Feature",
            "id": "2646",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-79.237",
                    "42.4146"
                ]
            },
            "properties": {
                "location": "Arkwright, NY",
                "country": "us",
                "lastSignal": "1648150201"
            }
        },
        {
            "type": "Feature",
            "id": "3235",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-82.5606",
                    "27.5096"
                ]
            },
            "properties": {
                "location": "Palmetto",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2957",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-80.634",
                    "28.0659"
                ]
            },
            "properties": {
                "location": "Melbourne",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3462",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-87.0478",
                    "30.7017"
                ]
            },
            "properties": {
                "location": "Milton",
                "country": "us",
                "lastSignal": "1648059302"
            }
        },
        {
            "type": "Feature",
            "id": "2247",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-76.7",
                    "39.2"
                ]
            },
            "properties": {
                "location": "Hanover, Maryland",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2745",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.33",
                    "47.6501"
                ]
            },
            "properties": {
                "location": "Seattle",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2235",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-74.1339",
                    "39.7102"
                ]
            },
            "properties": {
                "location": "Harvey Cedars NJ",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3460",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.26",
                    "47.9427"
                ]
            },
            "properties": {
                "location": "Everett, Washington",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2495",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-92.1021",
                    "46.7834"
                ]
            },
            "properties": {
                "location": "Duluth, MN",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2744",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.393",
                    "47.6673"
                ]
            },
            "properties": {
                "location": "Seattle",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2229",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-73.9207",
                    "42.0737"
                ]
            },
            "properties": {
                "location": "New York",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3092",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.522",
                    "47.6204"
                ]
            },
            "properties": {
                "location": "Bainbridge Island",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2172",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-86.819",
                    "30.413"
                ]
            },
            "properties": {
                "location": "Navarre FL",
                "country": "us",
                "lastSignal": "1648167901"
            }
        },
        {
            "type": "Feature",
            "id": "2529",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-76.4812",
                    "39.0665"
                ]
            },
            "properties": {
                "location": "Arnold, MD",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2742",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.479",
                    "37.7914"
                ]
            },
            "properties": {
                "location": "San Francisco",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2100",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-75.5668",
                    "39.662"
                ]
            },
            "properties": {
                "location": "New Castle",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2016",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-77.912",
                    "34.0669"
                ]
            },
            "properties": {
                "location": "Carolina Beach",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3231",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-91.1607",
                    "30.4"
                ]
            },
            "properties": {
                "location": "Baton Rouge, Louisiana",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2523",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.23",
                    "38.0701"
                ]
            },
            "properties": {
                "location": "California Maritime Academy",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2833",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-119.418",
                    "36.7783"
                ]
            },
            "properties": {
                "location": "California",
                "country": "us",
                "lastSignal": "1648185301"
            }
        },
        {
            "type": "Feature",
            "id": "2640",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-74.209",
                    "40.8259"
                ]
            },
            "properties": {
                "location": "New Jersey, Montclair",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3230",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.692",
                    "47.9075"
                ]
            },
            "properties": {
                "location": "Port Ludlow, WA",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2950",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.641",
                    "48.5087"
                ]
            },
            "properties": {
                "location": "Anacortes, WA",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3455",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-118.468",
                    "34.0507"
                ]
            },
            "properties": {
                "location": "Los Angeles",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2538",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-77.8269",
                    "34.2241"
                ]
            },
            "properties": {
                "location": "Wilmington",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3045",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-73.9847",
                    "40.741"
                ]
            },
            "properties": {
                "location": "New York",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2383",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-83.8107",
                    "45.4175"
                ]
            },
            "properties": {
                "location": "Rogers City, Michigan",
                "country": "us",
                "lastSignal": "1648173901"
            }
        },
        {
            "type": "Feature",
            "id": "2639",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-117.221",
                    "32.7231"
                ]
            },
            "properties": {
                "location": "San Diego",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3454",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-88",
                    "36.0336"
                ]
            },
            "properties": {
                "location": "Tennessee, Eva",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3081",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-118.142",
                    "33.8317"
                ]
            },
            "properties": {
                "location": "California, Long Beach",
                "country": "us",
                "lastSignal": "1647605402"
            }
        },
        {
            "type": "Feature",
            "id": "2426",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.46",
                    "37.867"
                ]
            },
            "properties": {
                "location": "San Francisco Bay",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2875",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-121.567",
                    "38.103"
                ]
            },
            "properties": {
                "location": "Isleton",
                "country": "us",
                "lastSignal": "1646268602"
            }
        },
        {
            "type": "Feature",
            "id": "2550",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-81.2996",
                    "29.8007"
                ]
            },
            "properties": {
                "location": "St. Augustine",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3380",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-74.0592",
                    "40.1177"
                ]
            },
            "properties": {
                "location": "Brielle",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2343",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-123.022",
                    "48.5"
                ]
            },
            "properties": {
                "location": "Friday Harbor",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2197",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-71.4469",
                    "41.7109"
                ]
            },
            "properties": {
                "location": "Warwick, Rhode Island",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3079",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-85.029",
                    "45.501"
                ]
            },
            "properties": {
                "location": "Petoskey",
                "country": "us",
                "lastSignal": "1646523002"
            }
        },
        {
            "type": "Feature",
            "id": "2827",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-80.284",
                    "25.2952"
                ]
            },
            "properties": {
                "location": "Key Largo",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "3309",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-81.1667",
                    "28.9074"
                ]
            },
            "properties": {
                "location": "Florida",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2537",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-81.0653",
                    "32.0824"
                ]
            },
            "properties": {
                "location": "Savannah, Georgia",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2436",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-64.8702",
                    "18.337"
                ]
            },
            "properties": {
                "location": "Treehouse, Virgin Islands",
                "country": "us",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3223",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-122.525",
                    "47.1387"
                ]
            },
            "properties": {
                "location": "Lakewood",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2530",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-80.4138",
                    "25.1143"
                ]
            },
            "properties": {
                "location": "Key Largo, FL",
                "country": "us",
                "lastSignal": "1648162802"
            }
        },
        {
            "type": "Feature",
            "id": "3011",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-81.5658",
                    "39.2834"
                ]
            },
            "properties": {
                "location": "Belpre, Ohio",
                "country": "us",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2819",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "zz",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2501",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "zz",
                "lastSignal": "1648188302"
            }
        },
        {
            "type": "Feature",
            "id": "3059",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "zz",
                "lastSignal": "1648193702"
            }
        },
        {
            "type": "Feature",
            "id": "2357",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "zz",
                "lastSignal": "1648189801"
            }
        },
        {
            "type": "Feature",
            "id": "2718",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "zz",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3120",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "zz",
                "lastSignal": "1648151702"
            }
        },
        {
            "type": "Feature",
            "id": "2376",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "zz",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2258",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "zz",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "3330",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "0",
                    "0"
                ]
            },
            "properties": {
                "location": "At Sea",
                "country": "zz",
                "lastSignal": "1647897002"
            }
        },
        {
            "type": "Feature",
            "id": "2389",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-56.1913",
                    "-34.8792"
                ]
            },
            "properties": {
                "location": "Montevideo",
                "country": "uy",
                "lastSignal": "1648193701"
            }
        },
        {
            "type": "Feature",
            "id": "2618",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "-62.72",
                    "8.28"
                ]
            },
            "properties": {
                "location": "Puerto Ordaz",
                "country": "ve",
                "lastSignal": "1648150501"
            }
        },
        {
            "type": "Feature",
            "id": "2040",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    "106.724",
                    "10.7423"
                ]
            },
            "properties": {
                "location": "HCMC",
                "country": "vn",
                "lastSignal": "1648193701"
            }
        }
    ]
}