import React, { useContext, useEffect, useRef } from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { GeoJSON } from 'react-leaflet';
import L from 'leaflet';

import { TheiaContext } from '../../Theia';
import { convertArrToGeojson, pointToLayer, getShipDetails } from '../../utils';

const BlueDetections = ({ blueShipDetections }) => {
  const { setHaveShipInfo, selectedShips, setSelectedShips, setShowPathPredictionDialog,setSpoofingTimelineShips,setSatelliteData, setCurrentLocationData, setTileLayerUrl, selectedPolygons, setPathInterpolationData, setViewPathInterpolation, setViewSatelliteImage, setShowSpinner, setIsSnackbarOpen, colorArr, setColorArr, setSelectShipWaiting, setSatelliteTimelineData } = useContext(TheiaContext);
  // const inputRef = useRef(selectedShips);
  const onEachFeaturePoint = (feature, layer) => {
    layer.on({
      click: async function (e) {
        setSelectShipWaiting(true)
        setTileLayerUrl(null)
        setViewSatelliteImage(false)
        setPathInterpolationData(null)
        setViewPathInterpolation(false)
        setSpoofingTimelineShips([])
        setShowPathPredictionDialog(false)
        setSatelliteData({geojson:null, key: parseInt(Math.random() * 10000)})
        setSatelliteTimelineData({ geojson: null, key: parseInt(Math.random() * 10000) })
        setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
        getShipDetails(feature, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, 'blue')
      },
      mouseover: () => {
        const { destination, name } = feature?.properties;
        layer.bindPopup(
          `<div>
              <div>Destination: ${destination ? destination : "-"}</div>
              <div>Name: ${name}</div>
          </div>`);
        layer.openPopup()
      },
      mouseout: () => {
        layer.closePopup()
      }
    });
  }
  let shipIndex = 0

  if(selectedShips.length > 0) {
    shipIndex = selectedShips[selectedShips.length - 1].shipIndex
  }

  if(selectedShips.length > 0 && selectedShips[selectedShips.length - 1].path) {
    shipIndex = shipIndex + selectedShips[selectedShips.length - 1].path.length
  }
  
  return (
    <>
      {blueShipDetections.ships.length > 0 && <MarkerClusterGroup
           disableClusteringAtZoom={9}
        maxClusterRadius={40}
        iconCreateFunction={function (cluster) {
          return L.divIcon({
            html: cluster.getChildCount(),
            className: 'bluecluster',
            // iconSize: L.point(40, 40),
          });
        }}
      >
        <GeoJSON
          key={'blueships' + blueShipDetections.key + selectedShips.length + selectedPolygons.length+ shipIndex}
          data={convertArrToGeojson(blueShipDetections.ships)}
          pointToLayer={(feature, latlng) => pointToLayer(feature, latlng, 'blueDetections')}
          onEachFeature={onEachFeaturePoint}
        />
      </MarkerClusterGroup>}
    </>
  )
}

export default BlueDetections
