import React, { useEffect } from 'react'
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import { Dialog, Tabs } from "@material-ui/core";
import { TheiaContext } from "../../Theia";
import moment from 'moment';
import { Accordion, AccordionDetails, AccordionSummary, Tab } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import { getSatelliteImageRequest, getObservedShipsRequest, frontendAPIURL } from "../../utils";
import DownloadXLS from './DownloadXLS';
import { useState } from 'react';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';

function Row(props) {
  const { row, shipType, id, setAllShipData, allShipData } = props;
  const [open, setOpen] = React.useState(false);
  const [shipInfo, setShipInfo] = React.useState(null);
  const [shipDetails, setShipDetails] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [shipImage, setShipImage] = React.useState(null);
  const [shipImageTimeLine, setShipImageTimeLine] = React.useState([]);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [darkShipDetails, setDarkShipDetails] = React.useState([]);
  const [darkShipVisibleColumns, setDarkShipVisibleColumns] = React.useState(null);
  const [DataTableDialogOpen, setDataTableDialogOpen] = useState(false)

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setDarkShipVisibleColumns([
      { title: 'Acquired', value: 'acquired' },
      { title: 'Attribution', value: 'attribution' },
      { title: 'Dark', value: 'dark' },
      { title: 'Dark time', value: 'dark_time' },
      { title: 'Heading', value: 'heading' },
      { title: 'Latitude', value: 'latitude' },
      { title: 'Length', value: 'length' },
      { title: 'longitude', value: 'longitude' },
      { title: 'Moving', value: 'moving' },
      { title: 'Object id', value: 'object_id' },
      { title: 'Ship type', value: 'ship_type' },
    ])
    if (shipType === 'Unattributed Ships') {
      setDarkShipDetails({
        ...darkShipDetails,
        acquired: moment.utc(row?.acquired).format('YYYY-MM-DD HH:mm:ss') + ' UTC',
      })
    }
  }, [])

  const openBox = () => {
    setOpen(!open);
    if (!open) {
      if (shipType === "Unattributed Ships") {
        getAttributionData(id);
        getAllData(id)
      } else {
        getShipInfo(id)
      }
    }
  }

  const getAllData = async (id) => {
    const shipTypeIndex = allShipData.findIndex(data => data.type === shipType)
    let shipAlreadyLoaded = true;
    if (shipTypeIndex !== -1) {
      let index = allShipData[shipTypeIndex].data.findIndex((ship) => ship.id === id);
      if (index !== -1) {
        if(!allShipData[shipTypeIndex].data[index].info.shipData){
          const data = await getSatelliteImageRequest(id)
          setShipImage(data)
          const shipsDetails = allShipData;
          shipsDetails[shipTypeIndex].data[index].info = {
            ...shipsDetails[shipTypeIndex].data[index].info,
            shipImage: data
          }
          setAllShipData(shipsDetails);
        } else{
          setShipImage(allShipData[shipTypeIndex].data[index].info.shipImage);
        }
      }else {
        shipAlreadyLoaded = false;
      }
    } else {
      shipAlreadyLoaded = false;
    }
  }

  const getAttributionData = async (id) => {
    const token = localStorage.getItem('token')
    const shipTypeIndex = allShipData.findIndex(data => data.type === shipType)
    let shipAlreadyLoaded = true;
    if (shipTypeIndex !== -1) {
      let index = allShipData[shipTypeIndex].data.findIndex((ship) => ship.id === id);
      if (index !== -1) {
        if(!allShipData[shipTypeIndex].data[index].info.shipData){
          try {
            const res = await axios.post(`${frontendAPIURL}/get_attributes`,
              { "object_id": id }
              , {
                headers: {
                  Authorization: 'Bearer ' + token,
                },
              })
            setDarkShipDetails(res?.data.auto);
            const shipsDetails = allShipData;
            shipsDetails[shipTypeIndex].data[index].info = {
              ...shipsDetails[shipTypeIndex].data[index].info,
              shipData: {
                ...res.data.auto
              }
            }
            setAllShipData(shipsDetails);
          } catch (err) {
            console.log(err, 'err')
            setShowSpinner(false)
          }
        } else {
          setDarkShipDetails(allShipData[shipTypeIndex].data[index].info.shipData);
        }
      } else {
        shipAlreadyLoaded = false;
      }
    } else {
      shipAlreadyLoaded = false;
    }

    if (!shipAlreadyLoaded) {
      const res = await axios.post(`${frontendAPIURL}/get_attributes`,
        { "object_id": id }
        , {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }).catch(() => {
          setShowSpinner(false)
        })
      setShipDetails(res?.data);
    }
  }

  const getShipInfo = async (id) => {
    const token = localStorage.getItem('token')
    const shipTypeIndex = allShipData.findIndex(data => data.type === shipType)
    let shipAlreadyLoaded = true;
    if (shipTypeIndex !== -1) {
      let index = allShipData[shipTypeIndex].data.findIndex((ship) => ship.id === id);
      if (index !== -1) {
        if (!allShipData[shipTypeIndex].data[index].info.shipData) {
          const shipInfoRes = await axios.post(`${frontendAPIURL}/ship_info`,
            { "id": "frontend", "shipids": [id] }
            , {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            }).catch(() => {
              setShowSpinner(false)
            })
          setShipInfo({
            ...shipInfoRes.data.static[0],
            long: row?.longitude,
            lat: row?.latitude,
            heading: row?.heading,
            destination: row?.destination,
          })
          allShipData[shipTypeIndex].data[index].info = {
            ...allShipData[shipTypeIndex].data[index].info,
            shipData: {
              ...shipInfoRes.data.static[0],
              long: row?.longitude,
              lat: row?.latitude,
              heading: row?.heading,
              destination: row?.destination,
            }
          }
        } else {
          setShipInfo({
            ...allShipData[shipTypeIndex].data[index].info.shipData
          })
        }
      } else {
        shipAlreadyLoaded = false;
      }
    } else {
      shipAlreadyLoaded = false;
    }
    if (!shipAlreadyLoaded) {
      const shipInfoRes = await axios.post(`${frontendAPIURL}/ship_info`,
        { "id": "frontend", "shipids": [id] }
        , {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }).catch(() => {
          setShowSpinner(false)
        })
      setShipInfo({
        ...shipInfoRes.data.static[0],
        long: row?.longitude,
        lat: row?.latitude,
        heading: row?.heading,
        destination: row?.destination,
      })
    }
    setAllShipData(allShipData);
    setShipDetails([
      { title: 'Ship Type', value: 'ship_type' },
      { title: 'Flag', value: 'flag' },
      { title: 'Class', value: 'class' },
      { title: 'IMO', value: 'imo' },
      { title: 'MMSI', value: 'mmsi' },
      { title: 'Call Sign', value: 'call_sign' },
      { title: 'Longitude', value: 'long' },
      { title: 'Latitude', value: 'lat' },
      { title: 'Speed', value: 'ship_type' },
      { title: 'Heading', value: 'heading' },
      { title: 'Destination', value: 'destination' },
      { title: 'Drought', value: 'draught' },
    ])
  }

  const getShipImage = async () => {
    const token = localStorage.getItem('token')
    const shipTypeIndex = allShipData.findIndex(data => data.type === shipType)
    let shipAlreadyLoaded = true;
    if (shipTypeIndex !== -1) {
      let index = allShipData[shipTypeIndex].data.findIndex((ship) => ship.id === id);
      if (index !== -1) {
        if(!allShipData[shipTypeIndex].data[index].info.shipImage){
          setShowSpinner(true)
          try {
            const imageRes = await axios.get(`${frontendAPIURL}/ship_pic?shipid=${id}`, {
              headers: {
                Authorization: 'Bearer ' + token,
              },
              responseType: 'blob'
            })
            let shipImageUrl = URL.createObjectURL(imageRes.data);
            setShipImage(shipImageUrl)
            const shipsDetails = allShipData;
            shipsDetails[shipTypeIndex].data[index].info = {
              ...shipsDetails[shipTypeIndex].data[index].info,
              shipImage: {
                shipImageUrl
              }
            }
            setAllShipData(shipsDetails);
          } catch (err) {
            console.log(err, ':err')
            setShowSpinner(false)
          }
          setShowSpinner(false)
        } else {
          setShipImage(allShipData[shipTypeIndex].data[index].info.shipImage.shipImageUrl);
        }
      }else {
        shipAlreadyLoaded = false;
      }
    } else {
      shipAlreadyLoaded = false;
    }

    if (!shipAlreadyLoaded) {
      const imageRes = await axios.get(`${frontendAPIURL}/ship_pic?shipid=${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        responseType: 'blob'
      }).catch(() => {
        setShowSpinner(false)
      })
      let shipImageUrl = URL.createObjectURL(imageRes.data);
      setShipImage(shipImageUrl)
    }
  }

  //  for SatelliteImageTimeline
  const getSatelliteImageTimeline = async (id) => {
    const shipTypeIndex = allShipData.findIndex(data => data.type === shipType)
    let shipAlreadyLoaded = true;
    if (shipTypeIndex !== -1) {
      let index = allShipData[shipTypeIndex].data.findIndex((ship) => ship.id === id);
      if (index !== -1) {
        if(!allShipData[shipTypeIndex].data[index].info.shipTimeline){
          setShowSpinner(true)
          const shipsImages = await getObservedShipsRequest(
            moment.utc(new Date(moment(new Date()).subtract('6', 'months'))).format('YYYY-MM-DD HH:mm:ss'),
            moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            [id]
          )
  
          const allImageWithTimeLine = []
  
          await Promise.all(shipsImages.map(async (item) => {
            const data = await getSatelliteImageRequest(item.object_id)
            allImageWithTimeLine.push({
              object_id: item?.object_id,
              longitude: item?.longitude,
              latitude: item?.latitude,
              acquired: item?.acquired,
              img: data,
            })
          }))
          setShowSpinner(false)
          setShipImageTimeLine(allImageWithTimeLine)
          const shipsDetails = allShipData;
          shipsDetails[shipTypeIndex].data[index].info = {
            ...shipsDetails[shipTypeIndex].data[index].info,
            shipTimeline: allImageWithTimeLine
          }
  
          setAllShipData(shipsDetails);

        } else {
          setShipImageTimeLine(allShipData[shipTypeIndex].data[index].info.shipTimeline)
        }
      } else {
        shipAlreadyLoaded = false;
      }
    } else {
      shipAlreadyLoaded = false;
    }

    if (!shipAlreadyLoaded) {
      const shipsImages = await getObservedShipsRequest(
        moment.utc(new Date(moment(new Date()).subtract('6', 'months'))).format('YYYY-MM-DD HH:mm:ss'),
        moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        [id]
      )

      const allImageWithTimeLine = []

      await Promise.all(shipsImages.map(async (item) => {
        const data = await getSatelliteImageRequest(item.object_id)
        allImageWithTimeLine.push({
          object_id: item?.object_id,
          longitude: item?.longitude,
          latitude: item?.latitude,
          acquired: item?.acquired,
          img: data,
        })
      }))
      setShowSpinner(false)
      setShipImageTimeLine(allImageWithTimeLine)
    }
  }

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            value={row?.source_id}
            aria-label="expand row"
            size="small"
            onClick={() => openBox()}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.name || '--'}
        </TableCell>
        <TableCell align="right">{row?.object_id || '--'}</TableCell>
        <TableCell align="right">{row?.ship_type || '--'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className='padding-y-0' colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={`margin-8 ` + open ? `padding-y-8` : `padding-y-0 `}  >
              {/* ship Data */}
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography className='ship-table-accordion' >Ship Data</Typography>
                </AccordionSummary>
                <AccordionDetails className='ship-data-table-accordionDetails'>
                  {shipType !== "Unattributed Ships" ?
                    (
                      shipDetails?.map((detail) => {
                        return (
                          <Typography className='width-50'>
                            <Typography component='strong' className='font-weight-bold'>{detail?.title}</Typography> : {shipInfo && shipInfo[detail?.value] || "-"}
                          </Typography>
                        )
                      })
                    ) : (
                      darkShipVisibleColumns?.map((detail) => {
                        return (
                          <Typography className='width-50'>
                            <Typography component='strong' className='font-weight-bold'>{detail?.title}</Typography> : {darkShipDetails[detail?.value] || "-"}
                          </Typography>
                        )
                      })
                    )
                  }
                </AccordionDetails>
              </Accordion>
              {/* ship Image */}
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                  onClick={() => {
                    if (shipType === "Unattributed Ships") {
                      return;
                    } else {
                      getShipImage();
                    }
                  }}
                >
                  <Typography className='ship-table-accordion' >Ship Image</Typography>
                </AccordionSummary>
                <AccordionDetails className='ship-table-accordionDetails'>
                  {showSpinner ?
                    (
                      <Box className='display-flex' id={row?.name} >
                        <CircularProgress />
                      </Box>
                    ) :
                    <img src={shipImage} alt="Image" className='width-100' />
                  }
                </AccordionDetails>
              </Accordion>
              {/* ship Image Timeline */}
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} disabled={shipType === 'Unattributed Ships' ? true : false} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                  onClick={() => {
                    if (shipType === "Unattributed Ships") {
                      return;
                    } else {
                      getSatelliteImageTimeline(row?.attribution ? row?.attribution : row.synmax_ship_id)
                    }
                  }}
                >
                  <Typography className='ship-table-accordion' >Ship Image Timeline</Typography>
                </AccordionSummary>
                <AccordionDetails className='ship-table-accordionDetails'>
                  {showSpinner ?
                    (
                      <Box className='display-flex' id={row?.name}>
                        <CircularProgress />
                      </Box>
                    ) :
                    (
                      <ImageList className='satellite-timeline-image-list' cols={3} >
                        {
                          shipImageTimeLine.map((element) => (
                            <div className='ship-timeline-img-list'>
                              <ImageListItem key={element?.img}   >
                                <img
                                  src={element?.img}
                                  srcSet={element?.img}
                                  alt="Satellite Image Timeline"
                                  loading="lazy"
                                />
                              </ImageListItem>
                              <div className='padding-left-5' >
                                <Typography>
                                  <Typography component='strong' className='font-weight-bold'>Acquired: </Typography>{new Date(element?.acquired).toISOString() || "-"}
                                </Typography>
                                <Typography>
                                  <Typography component='strong' className='font-weight-bold'>Long: </Typography>{element?.longitude || "-"}
                                </Typography>
                                <Typography>
                                  <Typography component='strong' className='font-weight-bold'>Lat: </Typography>{element?.latitude || "-"}
                                </Typography>
                                <Typography className='padding-bottom-8'>
                                  <Typography component='strong' className='font-weight-bold'>OID: </Typography>{element?.object_id || "-"}
                                </Typography>
                              </div>
                            </div>
                          ))
                        }
                      </ImageList>
                    )
                  }
                </AccordionDetails>
              </Accordion>
              {/* </Table> */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CollapsibleTable({ setShipDataDialog, shipDataDialog }) {
  const { orangeShipDetectionsInPolygon, blueShipDetectionsInPolygon, darkShipDetectionsInPolygon, aisShips, selectedPolygons, zIndex, setZindex } = React.useContext(TheiaContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [value, setValue] = React.useState(0);
  const [dataCount, setDataCount] = React.useState(blueShipDetectionsInPolygon ? blueShipDetectionsInPolygon?.ships?.length : 0);
  const [showShipsData, setShowShipsData] = React.useState(true);
  const labelList = ['Light Ships', 'Dark Ships', 'Unattributed Ships', 'AIS Ships']
  const [allShipData, setAllShipData] = useState([
    {
      type: 'Light Ships',
      data: []
    },
    {
      type: 'Dark Ships',
      data: []
    },
    {
      type: 'Unattributed Ships',
      data: []
    },
    {
      type: 'Ais Ships',
      data: []
    },
  ])

  const [showPolygonDataTable, setShowPolygonDataTable] = useState ({
    clientX: null,
    clientY: null
  })


  useEffect(() => {
    if (value === 0) {
      setDataCount(blueShipDetectionsInPolygon ? blueShipDetectionsInPolygon?.ships?.length : 0)
      setShowShipsData(blueShipDetectionsInPolygon?.ships?.length <= 0 ? false : true)
    } else if (value === 1) {
      setDataCount(orangeShipDetectionsInPolygon ? orangeShipDetectionsInPolygon?.ships?.length : 0)
      setShowShipsData(orangeShipDetectionsInPolygon?.ships?.length <= 0 ? false : true)
    } else if (value === 2) {
      setDataCount(darkShipDetectionsInPolygon ? darkShipDetectionsInPolygon?.ships?.length : 0)
      setShowShipsData(darkShipDetectionsInPolygon?.ships?.length <= 0 ? false : true)
    } else if (value === 3) {
      setDataCount(aisShips ? aisShips?.ships?.length : 0)
      setShowShipsData(aisShips?.ships?.length <= 0 ? false : true)
    }
    setPage(0)
  }, [value, blueShipDetectionsInPolygon, darkShipDetectionsInPolygon, aisShips?.ships])

  useEffect(() => {
    const shipData1 = [
      {
        type: 'Light Ships',
        data: []
      },
      {
        type: 'Dark Ships',
        data: []
      },
      {
        type: 'Unattributed Ships',
        data: []
      },
      {
        type: 'Ais Ships',
        data: []
      },
    ]
    if (selectedPolygons.length > 0) {
      blueShipDetectionsInPolygon.ships.map((ship) => {
        shipData1.map((e) => {
          if (e.type === "Light Ships") {
            const shipParam = {
              name: ship.name,
              id: ship.attribution,
              info:
              {
                shipData: null,
                shipImage: null,
                shipTimeline: null
              },
            }
            const isShipExist = e.data.find((blue) => blue.id === ship.attribution);
            if (!isShipExist) {
              e.data.push(shipParam)
            }
          }
        })
      })

      orangeShipDetectionsInPolygon.ships.map((ship) => {
        const shipParam = {
          name: ship.name,
          id: ship.attribution,
          info:
          {
            shipData: null,
            shipImage: null,
            shipTimeline: null
          },
        }
        shipData1.map((e) => {
          if (e.type === "Dark Ships") {
            const isShipExist = e.data.find((blue) => blue.id === ship.attribution);
            if (!isShipExist) {
              e.data.push(shipParam)
            }
          }
        })
      })

      darkShipDetectionsInPolygon.ships.map((ship) => {
        const shipParam = {
          name: ship.name,
          id: ship.object_id,
          info:
          {
            shipData: null,
            shipImage: null,
            shipTimeline: null
          },
        }
        shipData1.map((e) => {
          if (e.type === "Unattributed Ships") {
            const isShipExist = e.data.find((blue) => blue.id === ship.object_id);
            if (!isShipExist) {
              e.data.push(shipParam)
            }
          }
        })
      })

      aisShips.ships.map((ship) => {
        const shipParam = {
          name: ship.name,
          id: ship.synmax_ship_id,
          info:
          {
            shipData: null,
            shipImage: null,
            shipTimeline: null
          },
        }
        shipData1.map((e) => {
          if (e.type === "Ais Ships") {
            const isShipExist = e.data.find((blue) => blue.id === ship.synmax_ship_id);
            if (!isShipExist) {
              e.data.push(shipParam)
            }
          }
        })
      })

      setAllShipData(shipData1)
    }
  }, [blueShipDetectionsInPolygon, darkShipDetectionsInPolygon, aisShips])

  const blueShips = blueShipDetectionsInPolygon.ships.filter((r, ind) => {
    return ind >= rowsPerPage * page && ind < rowsPerPage * (page + 1);
  });
  const orangeShip = orangeShipDetectionsInPolygon.ships.filter((r, ind) => {
    return ind >= rowsPerPage * page && ind < rowsPerPage * (page + 1);
  });
  const darkShips = darkShipDetectionsInPolygon?.ships?.filter((r, ind) => {
    return ind >= rowsPerPage * page && ind < rowsPerPage * (page + 1);
  });

  let greenShips = aisShips.ships.filter((r, ind) => {
    return ind >= rowsPerPage * page && ind < rowsPerPage * (page + 1);
  });

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value,'event.target.value')
    // setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleChange(_, newValue) {
    setValue(newValue);
  }

  const polygonDataTableHandler = (_ , data) => {
    setShowPolygonDataTable({
      clientX: data.x,
      clientY: data.y
    })
  }

  return (
    <Draggable
    axis="both"
    handle=".dropPinDialog"
    defaultPosition={{x: 0, y:0}}
    position={{x: showPolygonDataTable.clientX, y: showPolygonDataTable.clientY }}
    onDrag={polygonDataTableHandler}
    bounds={{ right: 0, bottom: 0 }}
    onStart={()=> setZindex({
      dropPin: false,
      pathSlider: false,
      attribution: false,
      satelliteImage: false,
      extendedPath: false,
      polygonSlider: false,
      polygonDataTable:true,
      futurePathModal: false,
      futurePathSlider: false,
      spoofingTimelineSlider : false,
      portsOfCallSlider: false,
    })}
    >
      <Dialog className={`${zIndex.polygonDataTable ? 'zindex-true' : 'zindex-false'} handle`} open={shipDataDialog} onClose={() => setShipDataDialog(false)} classes={{ root: 'MuiDialog-root attributionDraggable polygonData-table-modal-position' }}>
        <div className="dropPinDialog" >
        <IconButton onClick={() => setShipDataDialog(false)} style={{position:'relative',left:"95%", padding:"0" ,top:0 }} >
            <CloseIcon />
          </IconButton>
          <div className='table-tabs'>
            <Tabs value={value} onChange={handleChange}>
              {labelList.map((label) => {
                return <Tab className='collapsible-table-tab' label={label} />
              })}
            </Tabs>
            <div className='table-download-btn'>
              <DownloadXLS blueShips={blueShipDetectionsInPolygon} orangeShip={orangeShipDetectionsInPolygon} darkShips={darkShipDetectionsInPolygon} greenShips={aisShips} />
            </div>
          </div>
          <br />
          {showShipsData ?
          
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Ship Id</TableCell>
                    <TableCell align="right">Ship Type</TableCell>
                  </TableRow>
                </TableHead>
                {value === 0 &&
                  <TableBody>
                    {blueShips.map((row) => (
                      <Row key={row.attribution} row={row} id={row?.attribution} shipType="Light Ships" setAllShipData={setAllShipData} allShipData={allShipData} />
                    ))}
                  </TableBody>
                }
                {value === 1 && orangeShipDetectionsInPolygon.ships.length > 0 &&
                  <TableBody>
                    {orangeShip.map((row) => (
                      <Row key={row.name} row={row} id={row?.attribution} shipType="Dark Ships" setAllShipData={setAllShipData} allShipData={allShipData} />
                    ))}
                  </TableBody>
                }
                {value === 2 &&
                  <TableBody>
                    {darkShips.map((row) => (
                      <Row key={row.object_id} row={row} id={row?.object_id} shipType="Unattributed Ships" setAllShipData={setAllShipData} allShipData={allShipData} />
                    ))}
                  </TableBody>
                }
                {value === 3 &&
                  <TableBody>
                    {greenShips.map((row, i) => (
                      <Row key={i} row={row} id={row?.synmax_ship_id} shipType="Ais Ships" setAllShipData={setAllShipData} allShipData={allShipData} />
                    ))}
                  </TableBody>
                }
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer> :
            <div className='no-ship-found'> No Ship found </div>
          }
        </div>
      </Dialog>
    </Draggable>
  );
}
