import React, { useState, useCallback, useEffect, useLayoutEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, TextField, Select, MenuItem, IconButton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import debounce from "lodash.debounce";
import axios from 'axios'

import './drawerData.scss'
import { TheiaContext } from '../Theia';
import { useContext } from 'react';
import { frontendAPIURL } from '../utils';

const columns = [
    { field: 'id', hide: true },
    { field: 'attributes', headerName: 'Attributes' },
    { field: 'auto', headerName: 'Automatic', flex: 1 }
]

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

export const Attribution = ({ attributionData, setAttribution, removeAttribution, imgUrl, setIsImgDialogOpen }) => {

    const {setZindex, zIndex } =  useContext(TheiaContext);

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [selectedSearchedShip, setSelectedSearchedShip] = useState({ label: '', id: '' })
    const [newLength, setNewLength] = useState('')
    const [newHeading, setNewHeading] = useState('')
    const [newShiptype, setNewShiptype] = useState('none')
    const [searchOptions, setSearchOptions] = useState([])
    const [isShipMoving, setIsShipMoving] = useState('none');
    const [newReattribute, setNewReattribute] = useState(false)

    const [isDark, setIsDark] = useState(false)

    const [windowWidth, windowHeight] = useWindowSize();
    const [attributionDialogPosition, setAttributionDialogPosition] = useState ({
        clientX: null,
        clientY: null
    })

    useEffect(() => {
        setAttributionDialogPosition({
          clientX: 0,
          clientY: 0
        });
      }, [windowWidth, windowHeight])

      const attributionDraggableEventHandler = (e, data) => {
        setAttributionDialogPosition({
          clientX: data.x,
          clientY: data.y
        })
      }

    const getOptionsDelayed = useCallback(
        debounce((query) => {
            // set new arr here
            getNewOptions(query)
        }, 200),
        []
    );

    useEffect(() => {
        // resetting the values when dialog closes
        setNewReattribute(false)
        setIsDark(false)
    }, [isDialogOpen])

    const getNewOptions = async (searchValue) => {
        const token = localStorage.getItem('token')
        try {
            const res = await axios.post(`${frontendAPIURL}/search`, { "id": "test_ships", "query": searchValue }
                , {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                });

            if (res.data) {
                console.log('setting search results')
                const searchOptions = res.data
                    .filter(ship => (ship.ship_name && (ship.ship_name !== "nan")))
                    .map(({ ship_name, synmax_ship_id, type, value }, i) => ({ key: i, label: ship_name + (type === 'mmsi' ? ` (${type} :${value})` : '') + (type === 'imo' ? ` (${type} :${value})` : ''), id: synmax_ship_id, type, value }))
                console.log(searchOptions, 'searchOptions')
                setSearchOptions(searchOptions)
            }
        } catch (err) {
            console.log(err, 'err')
        }
    }

    const performAttribution = () => {
        const newAttributionObject = {}

        if (selectedSearchedShip.id) {
            newAttributionObject.attribution = selectedSearchedShip.id
        }

        if (newLength !== '') {
            newAttributionObject.length = parseInt(newLength)
        }

        if (newHeading !== '') {
            newAttributionObject.heading = parseInt(newHeading)
        }

        if (newShiptype !== 'none') {
            newAttributionObject.ship_type = newShiptype
        }

        if (isShipMoving !== 'none') {
            newAttributionObject.moving = isShipMoving === 'true'
        }

        newAttributionObject.redo_attribution = newReattribute
        newAttributionObject.dark = isDark;
        setAttribution(newAttributionObject)
        setIsDialogOpen(false)
    }

    return (
        <div className='attributionTab'>
            <Draggable
                axis="both"
                handle=".attributionDraggable"
                defaultPosition={{ x: 0 , y: 0 }}
                position={{x: attributionDialogPosition.clientX, y: attributionDialogPosition.clientY }}
                onDrag={attributionDraggableEventHandler}
                bounds="body"
                onStart={()=> setZindex({
                    dropPin: false,
                    pathSlider: false,
                    attribution: true,
                    satelliteImage: false,
                    extendedPath: false,
                    polygonSlider: false,
                    polygonDataTable:false,
                    futurePathModal: false,
                    futurePathSlider: false,
                    spoofingTimelineSlider : false,
                    portsOfCallSlider: false,
                  })}
            >
                <Dialog className={`${zIndex.attribution ? 'zindex-true' : 'zindex-false'}`} open={isDialogOpen} onClose={() => setIsDialogOpen(false)} classes={{ root: 'MuiDialog-root changeAttributionDialog attributionDraggable muiModalClass' }}>

                <div className="changeAttributionDialog__content">
                <IconButton onClick={() => setIsDialogOpen(false)} style={{position:'absolute',  right:8, top:8 }} >
                    <CloseIcon />
                </IconButton>
                    <div className="changeAttributionDialog__mainHeading">Manually Attribute this Ship</div>
                    <div className="changeAttributionDialog__subHeading">Find Ship:</div>
                    <Autocomplete
                        style={{marginTop:'8px'}}
                        disablePortal
                        id="combo-box-demo"
                        options={searchOptions}
                        onInputChange={(event, newInputValue) => {
                            if (newInputValue.length > 2) {
                                getOptionsDelayed(newInputValue);
                            } else {
                                setSearchOptions([]);
                            }
                        }}
                        onChange={(event, newValue) => {
                            console.log(newValue, 'check synmax_ship_id')
                            setSelectedSearchedShip(newValue);
                        }}
                        value={selectedSearchedShip}
                        renderOption={(props, { id, label, type, value, key }) => {
                            return (
                                <li {...props} key={key}>
                                    <span style={{ fontSize: 16 }}>{label}</span>
                                </li>
                            );
                        }}
                        renderInput={(params) => {
                            return (
                                <div
                                    ref={params.InputProps.ref}
                                    style={{ position: "relative" }}
                                >
                                    <div className="svgBox">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 15.663 18.629"
                                        >
                                            <path
                                                id="Path_37"
                                                data-name="Path 37"
                                                d="M6.316,0a5.9,5.9,0,0,1,4.466,2.145,7.928,7.928,0,0,1,1.85,5.18,7.964,7.964,0,0,1-1.715,5.018.528.528,0,0,1,.056.055l4.547,5.266a.63.63,0,0,1,0,.8.441.441,0,0,1-.688,0L10.286,13.2a.568.568,0,0,1-.074-.108,5.766,5.766,0,0,1-3.9,1.56A5.9,5.9,0,0,1,1.85,12.505,7.929,7.929,0,0,1,0,7.325a7.928,7.928,0,0,1,1.85-5.18A5.9,5.9,0,0,1,6.316,0Zm3.76,2.965a4.963,4.963,0,0,0-3.76-1.806,4.964,4.964,0,0,0-3.76,1.806A6.675,6.675,0,0,0,1,7.325a6.675,6.675,0,0,0,1.557,4.361,4.963,4.963,0,0,0,3.76,1.806,4.963,4.963,0,0,0,3.76-1.806,6.674,6.674,0,0,0,1.557-4.361,6.675,6.675,0,0,0-1.557-4.361Z"
                                                transform="translate(0)"
                                                fill="#23283d"
                                            />
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        {...params.inputProps}
                                        id="searchInput__attribution"
                                        placeholder="Search by ship name, IMO, or MMSI"
                                    />
                                    {selectedSearchedShip?.label !== "" && (
                                        <div
                                            className="closeIcon"
                                            onClick={() =>
                                                setSelectedSearchedShip({ label: "", id: "" })
                                            }
                                        >
                                            <CloseIcon sx={{ color: "#333", fontSize: 20 }} />
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                    />
                    <div className="changeAttributionDialog__inputArea">
                        <TextField
                            id="outlined-basic"
                            label="Length"
                            variant="standard"
                            type="number"
                            name="length"
                            value={newLength}
                            onChange={(e) => setNewLength(e.target.value)}
                        />
                    </div>

                    <div className="changeAttributionDialog__inputArea">
                        <TextField
                            id="outlined-basic"
                            label="Heading"
                            variant="standard"
                            type="number"
                            name="heading"
                            value={newHeading}
                            onChange={(e) => setNewHeading(e.target.value)}
                        />
                    </div>

                    {/* <div className="changeAttributionDialog__inputArea">
                        <TextField
                            id="outlined-basic"
                            label="Ship Type"
                            variant="standard"
                            type="string"
                            name="newShiptype"
                            value={newShiptype}
                            onChange={(e) => setNewShiptype(e.target.value)}
                        />
                    </div> */}

                    <div className="changeAttributionDialog__inputArea">
                        <div className="changeAttributionDialog__subHeading changeAttributionDialog__subHeading--withMT">Ship Type:</div>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size='small'
                            value={newShiptype}
                            onChange={(e) => setNewShiptype(e.target.value)}
                            style={{
                                fontSize: '1.5rem',
                                marginTop:'8px'
                            }}
                        >
                            <MenuItem value={'none'}>None</MenuItem>
                            <MenuItem value={'Cargo'}>Cargo</MenuItem>
                            <MenuItem value={'Tug'}>Tug</MenuItem>
                            <MenuItem value={'Other'}>Other</MenuItem>
                            <MenuItem value={'Passenger'}>Passenger</MenuItem>
                            <MenuItem value={'Sailing'}>Sailing</MenuItem>
                            <MenuItem value={'Tanker'}>Tanker</MenuItem>
                            <MenuItem value={'Fishing'}>Fishing</MenuItem>
                            <MenuItem value={'Pleasure Craft'}>Pleasure Craft</MenuItem>
                        </Select>
                    </div>

                    <div className="changeAttributionDialog__inputArea">
                        <div className="changeAttributionDialog__subHeading changeAttributionDialog__subHeading--withMT">Is Moving:</div>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size='small'
                            value={isShipMoving}
                            onChange={(e) => setIsShipMoving(e.target.value)}
                            style={{
                                fontSize: '1.5rem',
                                marginTop:'8px'
                            }}
                        >
                            <MenuItem value={'none'}>None</MenuItem>
                            <MenuItem value={'true'}>True</MenuItem>
                            <MenuItem value={'false'}>False</MenuItem>
                        </Select>
                    </div>

                    <div className="changeAttributionDialog__inputArea changeAttributionDialog__inputArea--reattribute">
                        <div className="changeAttributionDialog__subHeading">Is Dark:</div>
                        <div>
                            <input type="checkbox" id="isDark" name="isDark" onChange={() => setIsDark(prevValue => !prevValue)} />
                        </div>
                    </div>

                    <div className="changeAttributionDialog__inputArea changeAttributionDialog__inputArea--reattribute">
                        <div className="changeAttributionDialog__subHeading">Redo Automated Attribution:</div>
                        <div>
                            <input type="checkbox" id="reattribute" name="reattribute" onChange={() => setNewReattribute(prevValue => !prevValue)} />
                        </div>
                    </div>


                    <Button onClick={performAttribution} variant="contained" style={{ marginTop: '2rem', color: '#fff', borderColor: '#24A0CA', marginBottom: '1rem'}}>Complete Attribution</Button>
                </div>

                </Dialog>
            </Draggable>
                            
            {attributionData && (
                <div className="attributionTab__contentBox">
                    <div className="attributionTab__image" onClick={() => setIsImgDialogOpen(true)}>
                        <img src={imgUrl} alt="" />
                    </div>

                    <div className="attributionTab__tableBox">
                        <DataGrid
                            rows={attributionData}
                            columns={columns}
                            pageSize={5}
                            density="compact"
                            headerHeight={30}
                            hideFooter={true}
                            rowHeight={30}
                            className="attributionTab__new"
                        />
                        <div className="attributionTab__buttons">

                            <Button onClick={removeAttribution} variant='outlined' className='attributionTab__btn' 
                            style={{backgroundColor: 'red', color:'white' }}
                            >Remove Attribution</Button>

                            <Button onClick={() => setIsDialogOpen(true)} variant='outlined' className='attributionTab__btn'
                             style={{ backgroundColor: 'lightgray', color:'black' }}
                            >Re-Attribute</Button>

                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
