import React, { useState, useContext, useEffect, useLayoutEffect } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Dialog, Button, IconButton, Snackbar } from '@mui/material';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import './dropPin.scss'
import { TheiaContext } from '../Theia'


function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export const DropPin = ({ setDroppedPins }) => {
  const { setMapCenter, setZindex, zIndex } = useContext(TheiaContext);
  const [dropPinDialogOpen, setDropPinDialogOpen] = useState(false)
  const [name, setName] = useState('')
  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')
  const [windowWidth, windowHeight] = useWindowSize();
  const [dropPinDialogPosition, setDropPinDialogPosition] = useState({
    clientX: null,
    clientY: null
  })
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    setDropPinDialogPosition({
      clientX: 0,
      clientY: 0
    });
  }, [windowWidth, windowHeight])


  const addPin = () => {
    if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
      setSnackbarMessage('Invalid latitude or longitude values. Latitude must be between -90 and 90, and longitude must be between -180 and 180.');
      setSnackbarOpen(true);
      return;
    }
    
    setDropPinDialogOpen(false)
    setMapCenter({ lat, lng })
    setDroppedPins(droppedPins => [...droppedPins, {
      name: name,
      point: [ lat === '' ? 0 : lat, lng === '' ? 0 : lng]
    }
    ])
    setName('');
    setLat('');
    setLng('');
  }

  const dropPinEventHandler = (e, data) => {
    setDropPinDialogPosition({
      clientX: data.x,
      clientY: data.y
    })
  }

  return (
    <>
      <Draggable
        axis="both"
        handle=".dropPinDraggable"
        defaultPosition={{ x: 0, y: 0 }}
        position={{ x: dropPinDialogPosition.clientX, y: dropPinDialogPosition.clientY }}
        onDrag={dropPinEventHandler}
        bounds="body"
        onStart={() => setZindex({
          dropPin: true,
          pathSlider: false,
          attribution: false,
          satelliteImage: false,
          extendedPath: false,
          polygonSlider: false,
          polygonDataTable: false,
          futurePathModal: false,
          futurePathSlider: false,
          spoofingTimelineSlider: false,
          portsOfCallSlider: false,
        })}
      >

        <Dialog className={`${zIndex.dropPin ? 'zindex-true' : 'zindex-false'}`} open={dropPinDialogOpen} onClose={() => setDropPinDialogOpen(false)} classes={{ root: 'MuiDialog-root dropPinDraggable dropPin-modal-position' }}>
          <div className="dropPinDialog">
            <div className="dropPinDialog__heading"> Drop Pin At:</div>
            <IconButton onClick={() => setDropPinDialogOpen(false)} style={{ position: 'absolute', right: 0, top: 8 }} >
              <CloseIcon />
            </IconButton>

            <div className="dropPinDialog__input">
              <div className="dropPinDialog__input--heading">Name</div>
              <input type="text" placeholder='Enter name' value={name} onChange={e => setName(e.target.value)} />
              <div className="dropPinDialog__input--heading">Latitude</div>
              <input
                type="number"
                placeholder='0'
                value={lat}
                onChange={e => {
                  const currentVal = e.target.value;
                  setLat(currentVal);
                }}
              />
            </div>
            <div className="dropPinDialog__input">
              <div className="dropPinDialog__input--heading">Longitude</div>
              <input
                type="number"
                placeholder='0'
                value={lng}
                onChange={e => {
                  const currentVal = e.target.value;
                  setLng(currentVal);
                }}
              />
            </div>
            <Button variant='contained' onClick={addPin}>Drop Pin</Button>
          </div>
        </Dialog>
      </Draggable>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <div className='dropPin' onClick={() => setDropPinDialogOpen(true)}>
        <LocationOnIcon
          style={{
            color: '#333',
            fontSize: '22px',
            marginBottom: 2,
          }}
        />
      </div>
    </>

  )
}
