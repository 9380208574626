import { useState, useLayoutEffect } from 'react'

const token = localStorage.getItem('token')

export const selectedShipObj = {
  static: {
    name: 'Seri Alarm',
    imo: 9265999,
    mmsi: 533925000,
    year: 1990,
    synmax_ship_id: 'asdasdasdasdasdasdasdasdasdasasdada',
    acquired: '01 Dec 2022 17:48:00 UTC',
    type: 'tanker',
    object_id: 0,
    flag: 'lr',
    shipCategory: 'red',
    initialData: {}
  },
  dynamic: {
    speed: {
      value: 0,
      time: 'No info'
    },
    destination: 'japan',
    eta: 562566,
    draught: 20,
    heading: 277,
    length: 0,
    width: 0,
    location: {
      lat: 25.3256,
      lon: 130.5509
    },
  }
}

export const clientCordinatesObj = {
  clientX: null,
  clientY: null
}

export const futurePathPredictionValuesObj = {
  lat: '',
  lon: '',
  speed: 0,
  length: 0,
  heading: 0,
  ship_type: ''
}

export const adjustForNullValues = (value) => {
  if (value) {
    return value
  } else {
    return 'No Info'
  }
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export const compare = (a, b) => {
  if (a.timestamp < b.timestamp) {
    return -1;
  }
  if (a.timestamp > b.timestamp) {
    return 1;
  }
  return 0;
}

export const getSelectedShipData = ({ selectedShip, length }) => {
  const {
    shipData: {
      dynamic,
      static: staticData
    },
    initialData,
    path,
    shipCategory
  } = selectedShip;

  const {
    acquired,
    latitude: lat,
    longitude: lon,
    speed_min,
    speed_max,
    object_id,
    heading,
    attribution,
    moving, status
  } = initialData.properties;

  // Update State when we load new data
  let shipLength;
  if (length !== undefined) {
    shipLength = length;
  } else if (length === undefined) {
    shipLength = initialData.properties.length;
  } else {
    shipLength = length
  }

  const newSelectedShipObject = {
    static: {
      name: 'No Info',
      imo: 'No Info',
      mmsi: 'No Info',
      year: 'No Info',
      synmax_ship_id: 'No Info',
      acquired: adjustForNullValues(acquired),
      type: 'No Info',
      object_id: adjustForNullValues(object_id),
      flag: null,
      avgSpeed: 'No Info',
      minSpeed: speed_min,
      maxSpeed: speed_max,
      shipCategory,
      shipImageUrl: null,
      initialData,
      moving,
      status
    },
    dynamic: {
      speed: {
        value: 'No Info',
        time: 'No Info'
      },
      destination: 'No Info',
      eta: null,
      draught: null,
      heading: adjustForNullValues(heading),
      length: length,
      width: null,
      location: {
        lat,
        lon
      },
    }
  }

  return {
    shipData: {
      dynamic,
      static: staticData
    },
    initialData,
    path,
    latitude: lat,
    longitude: lon,
    object_id,
    heading,
    attribution,
    shipLength,
    newSelectedShipObject
  }
}

export const getSelectedShipObject = ({ selectedShip, dynamic, acquired }) => {
  const {
    shipImageUrl,
    shipData: { static: staticData },
    initialData,
    shipCategory
  } = selectedShip;

  const { name, imo, mmsi, built_year: year, synmax_ship_id, flag, ship_type, length, width, speeds } = staticData[0];

  const {
    latitude: lat,
    longitude: lon,
    object_id,
    heading,
    status,
    timestamp,
    day
  } = initialData.properties;

  let earlierDynamicObjects = dynamic.filter(item => (item.timestamp * 1000) < acquired).sort(compare)
  if (!acquired) {
    // for AIS ships, acquired shows up as timestamp
    acquired = timestamp * 1000
    earlierDynamicObjects = dynamic.filter(item => (item.timestamp) <= timestamp).sort(compare)

    if (!timestamp) {
      acquired = day
    }
  }

  let destination = null, eta = null, draught = null
  if (earlierDynamicObjects.length > 0) {
    const closestDynamicObject = earlierDynamicObjects[earlierDynamicObjects.length - 1]
    destination = closestDynamicObject.destination
    eta = closestDynamicObject.eta
    draught = closestDynamicObject.draught
  }

  const newSelectedShipObject = {
    static: {
      name: adjustForNullValues(name),
      imo: adjustForNullValues(imo),
      mmsi: adjustForNullValues(mmsi),
      year: adjustForNullValues(year),
      synmax_ship_id: adjustForNullValues(synmax_ship_id),
      acquired: adjustForNullValues(acquired),
      type: adjustForNullValues(ship_type),
      object_id: adjustForNullValues(object_id),
      flag,
      avgSpeed: 'No Info',
      maxSpeed: 'No Info',
      shipCategory,
      shipImageUrl,
      initialData,
      length, width, status
    },
    dynamic: {
      speed: {
        value: 'No Info',
        time: 'No Info'
      },
      destination: adjustForNullValues(destination),
      eta: eta,
      draught: draught,
      heading: adjustForNullValues(heading),
      location: {
        lat,
        lon
      },
    }
  }

  return { newSelectedShipObject, speeds };
}

export const getShipProperties = (initial, shipId) => {
  return {
    acquired: initial?.timestamp * 1000,
    synmax_ship_id: initial?.synmax_ship_id_1,
    latitude: initial?.latitude,
    longitude: initial?.longitude,
    [`ship${shipId}Status`]: "ais",
    status: initial?.status,
    bunkering: "ais_bunkering",
    fromDecoupleModel: true
  }
}

export const getShipOptProperties = (initial, shipNo) => {
  const shipStatus = initial?.[`ship${shipNo}_dark`]
    ? "orange"
    : initial?.[`ship${shipNo}_dark`] === false
      ? "blue"
      : "red";

  const shipOptProperties = {
    acquired: initial?.acquired,
    attribution: initial?.[`ship${shipNo}_attribution`],
    heading: initial?.[`ship${shipNo}_heading`],
    latitude: initial?.[`ship${shipNo}_lat`],
    longitude: initial?.[`ship${shipNo}_lon`],
    length: initial?.[`ship${shipNo}_length`],
    moving: initial?.[`ship${shipNo}_moving`],
    object_id: initial?.[`ship${shipNo}_object_id`],
    ship_type: initial?.[`ship${shipNo}_ship_type`],
    source_id: initial?.source_id,
    status: initial?.[`ship${shipNo}_status`],
    bunkering: initial?.[`ship${shipNo}_bunkering`],
    dark: initial?.[`ship${shipNo}_dark`],
    dark_time: initial?.[`ship${shipNo}_dark_time`],
    shipStatus,
    fromDecoupleModel: true,
    xPixel: initial?.[`ship${shipNo}_clip_pxl_x`],
    yPixel: initial?.[`ship${shipNo}_clip_pxl_y`]
  }

  return shipOptProperties
}
