import axios from "axios";
import { frontendAPIURL } from "../../utils";

export const ShipInfoResponse = async (allShipIds, setShowSpinner, {shipIds, shipData}) => {
  const token = localStorage.getItem("token");

  let infoResData;
  if(allShipIds.length > 0) {
    const shipInfoRes = await axios
    .post(
      `${frontendAPIURL}/ship_info`,
      { id: "ifrontend", shipids: allShipIds },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .catch(() => {
      setShowSpinner(false);
    });
    infoResData = shipInfoRes.data.static
  } else {
    infoResData = []
  }


  setShowSpinner(false);
  const uniqueShipMmsis = [];
  const allUniqueShips = [];
  const uniqueShipIds = [];

  infoResData.forEach((ship) => {
    if (!uniqueShipMmsis.includes(ship.mmsi)) {
      uniqueShipMmsis.push(ship.mmsi);
      allUniqueShips.push(ship);
    }

    if (!uniqueShipIds.includes(ship.synmax_ship_id)) {
      uniqueShipIds.push(ship.synmax_ship_id);
    }
  });

  return { allUniqueShips: [...shipData, ...allUniqueShips], uniqueShipIds: [...shipIds, ...uniqueShipIds] };
};
