import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const CustomModal = ({ open, onClose, title, message }) => {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // maxWidth: '50%',
    // width: 240,
    borderRadius: "5px",
    bgcolor: 'white',
    p: 4,
    outline: "none"
  };

  const buttonStyle = {
    marginTop: '16px',
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography variant="h5" component="div" align="center">
          {title}
        </Typography>
        <Typography sx={{ mt: 2 }} align="center">
          {message}
        </Typography>
        <Button variant="contained" color="primary" fullWidth sx={buttonStyle} onClick={onClose}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default CustomModal;

