import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { withLeaflet, Polyline } from "react-leaflet";
import L from "leaflet";

export const PolylineDecorator = withLeaflet((props) => {
  const polyRef = useRef();
  useEffect(() => {
    let map;
    let test;
    if (polyRef.current) {
      const polyline = polyRef.current.leafletElement; //get native Leaflet polyline
      const { map: lMap } = polyRef.current.props.leaflet;
      map = lMap; //get native Leaflet map
      setTimeout(() => {
        test = L.polylineDecorator(polyline, {
          leaflet_id: 9999,
          patterns: [
            {
              offset: 0,
              repeat: 50,
              symbol: L.Symbol.arrowHead({
                pixelSize: 8,
                polygon: false,
                pathOptions: {
                  stroke: true,
                  color: "white",
                  opacity: 1,
                  interactive: false,
                  smoothFactor: 1,
                },
              }),
            },
          ],
        });

        test.addTo(map);
      });
    }

    return () => {
      test.removeFrom(map);
    };
  }, []);

  return <Polyline ref={polyRef} {...props} />;
});

export const formatPathData = (pathData) => {
  const updatedPathData = [];
  pathData?.forEach(({ latitude: lat, longitude: lon, timestamp: t }) =>
    updatedPathData.push({ lat, lon, t })
  );
  return updatedPathData;
};
