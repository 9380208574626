import React, { memo, useContext } from 'react'

import { Button, Tab, Tabs } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TheiaContext } from '../../../../Theia';
import { getShipDetails, getUniqueColor } from '../../../../utils';

const SelectedShips = ({
  tabValue, handleChange, selectedShips, setOpenDrawerData, selectedShipsData
}) => {
  const {
    viewSatelliteImage, setCurrentLocationData, setSelectedShips, setHaveShipInfo, colorArr, setColorArr, setIsSnackbarOpen,
    setSelectShipWaiting, setShowSpinner, userData
  } = useContext(TheiaContext)

  const { object_id, shipCategory } = selectedShipsData.static

  const showShipOne = () => {
    const ship1Data = selectedShips[selectedShips.length - 1].initialData.ship1State
    setSelectedShips([...selectedShips.slice(0, -1), { ...ship1Data, ship2State: selectedShips[selectedShips.length - 1] }])
  }

  const showShipTwo = () => {
    if (selectedShips[selectedShips.length - 1].ship2State) {
      const ship2Data = selectedShips[selectedShips.length - 1].ship2State
      setSelectedShips([...selectedShips.slice(0, -1), { ...ship2Data }])
    } else {
      const { ship2Properties, ship1Properties, geometry } = selectedShips[selectedShips.length - 1].initialData
      const { ship2Status } = ship2Properties

      const shipFeature = {
        geometry: {
          type: 'Point',
          coordinates: geometry.coordinates
        },
        properties: ship2Properties,
        type: "Feature",
        id: parseInt(Math.random() * 10000),
        ship2Properties,
        ship1Properties,
        ship1State: selectedShips[selectedShips.length - 1],
        oldIndexValue: selectedShips[selectedShips.length - 1].shipIndex,
        isShipOne: false
      }

      if (ship2Status === 'blue' || ship2Status === 'orange' || ship2Status === 'ais') {
        setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
        getShipDetails(shipFeature, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, ship2Status)
      } else {
        setSelectedShips([...selectedShips.slice(0, -1), { shipCategory: 'red', initialData: { ...shipFeature, isClicked: true }, shipIndex: selectedShips[selectedShips.length - 1].shipIndex, visible: true, pathVisible: true, shipData: { ...shipFeature.properties, name: 'Dark Ship' }, showCircle: true, path: [], color: getUniqueColor(colorArr, setColorArr) }])
        setSelectShipWaiting(false)
      }
    }
  }

  const onCloseDrawer = () => setOpenDrawerData(false);

  console.log(selectedShips, "selectedShipsselectedShips")

  return (
    <div className="drawerData__tabs" >
      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example"
        className="navTab"
      >
        <Tab label="Ship Info" id="ship" value={0} />
        <Tab label="Voyage Info" id="voyage" value={1} />
        {object_id !== 'No Info' && userData.role !== 'theia_customer' && (<Tab label="Attribution" id="attribution" value={2} />)}
        {viewSatelliteImage && <Tab label="Enhance Image" id="enhance" value={3} />}
        {shipCategory !== 'red' && <Tab label="Event" id='event' value={5} />}
        {(shipCategory !== 'red' && selectedShips[selectedShips.length - 1].shipData.static && selectedShips[selectedShips.length - 1].shipData.static[0].sanctions_list) && <Tab label="Sanction Details" id='sanction' value={6} />}
     { selectedShips[selectedShips.length - 1].shipCategory !== 'red' && selectedShips[selectedShips.length - 1].shipData.static[0].commercialowner &&  <Tab label="Ownership" id='ownership' value={7} />}
      </Tabs>

      {selectedShips[selectedShips.length - 1].initialData.properties && ("bunkeringStatus" in selectedShips[selectedShips.length - 1].initialData?.properties || selectedShips[selectedShips.length - 1].initialData.properties.fromDecoupleModel) && <div>
        <Button onClick={showShipOne} disabled={selectedShips[selectedShips.length - 1].initialData.isShipOne} style={{
          color: selectedShips[selectedShips.length - 1].initialData.isShipOne ? '#333' : '#fff',
          cursor: selectedShips[selectedShips.length - 1].initialData.isShipOne ? 'none' : 'pointer'
        }}>Ship One</Button>
        <Button onClick={showShipTwo} disabled={!selectedShips[selectedShips.length - 1].initialData.isShipOne} style={{
          color: !selectedShips[selectedShips.length - 1].initialData.isShipOne ? '#333' : '#fff',
          cursor: !selectedShips[selectedShips.length - 1].initialData.isShipOne ? 'none' : 'pointer'
        }}>Ship Two</Button>
      </div>}

      <CloseIcon className="drawerData__drawer-close-btn" onClick={onCloseDrawer} />
    </div>
  )
}

export default memo(SelectedShips);
