import React, { memo, useContext, useEffect, useState } from 'react'
import axios from 'axios';
import Draggable from 'react-draggable';
import DatePicker from "react-datepicker";
import moment from 'moment';

import { frontendAPIURL, getAllShipEventsRequest, getObservedShipsRequest, getShipsPathRequest } from '../../../../utils';

import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from "@mui/lab/LoadingButton";

import { TheiaContext } from '../../../../Theia';
import { compare } from '../../../Utils/Common';

const ExtendedPath = ({
  extendedPathDialogPosition, dragExtendedPathEventHandler, showExtendedPathDialog, setShowExtendedPathDialog, viewSpoofingTimeline,
  isPathLoading, setIsPathLoading, selectedShips, setIsSpoofingSnackBarOpen, selectedShipsData, extendedStartDate, setExtendedStartDate, extendedEndDate, setExtendedEndDate
}) => {
  const {
    zIndex, setZindex, setSpoofingTimelineShips, setFilters, setBunkeringData, setAisBunkeringData, setPathInterpolationData,
    setViewPathInterpolation, setSatelliteData, setSelectedShips, setSpoofingHeatmapTimelineVisible, setSatelliteTimelineData
  } = useContext(TheiaContext)

  let shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.synmax_ship_id
  if (!shipId) {
    shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.attribution
  }

  useEffect(() => {
    setSpoofingTimelineShips([])
    setSpoofingHeatmapTimelineVisible(false)
    setSatelliteTimelineData({ geojson: null, key: parseInt(Math.random() * 10000) })
  }, [shipId, setSpoofingHeatmapTimelineVisible])

  const viewSpoofingTimlineHeatMap = async () => {
    const token = localStorage.getItem('token')

    try {
      setIsPathLoading(true);

      const startDate = moment.utc(`${moment(extendedStartDate).format('YYYY-MM-DD')} 00:00:00`).format('YYYY-MM-DD HH:mm:ss');
      const endDate = moment.utc(`${moment(extendedEndDate).format('YYYY-MM-DD')} 23:59:59`).format('YYYY-MM-DD HH:mm:ss');

      const res = await axios.post(
        `${frontendAPIURL}/get_events`,
        {
          types: ['footprint-spoofing'],
          start: startDate,
          end: endDate,
          shipids: [shipId],
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      setIsPathLoading(false);
      setShowExtendedPathDialog(false);

      if (res.data['footprint-spoofing'].length > 0) {
        setSpoofingTimelineShips(res.data['footprint-spoofing']);
      } else {
        setIsSpoofingSnackBarOpen(true);
      }
    } catch (error) {
      console.error('error', error);
      setIsPathLoading(false);
    }
  };

  const viewExtendedPath = async () => {
    const selectedShipStatus = selectedShips[selectedShips.length - 1]?.initialData?.properties?.bunkering;
    setIsPathLoading(true);
    setFilters(prev => ({
      ...prev,
      ais: selectedShipStatus === "ais_bunkering" ? true : false,
      unknown: false,
      light: false,
      dark: false,
      spoofing: false,
      decoupleBunkering: false
    })
    )
    const { synmax_ship_id } = selectedShipsData.static

    const synmaxShipId = [synmax_ship_id]

    const shipEventData = await getAllShipEventsRequest(
      moment.utc(`${moment(extendedStartDate).format('YYYY-MM-DD')} 00:00:00`).format('YYYY-MM-DD HH:mm:ss'),
      moment.utc(`${moment(extendedEndDate).format('YYYY-MM-DD')} 23:59:59`).format('YYYY-MM-DD HH:mm:ss'),
      synmaxShipId,
      setBunkeringData,
      setSatelliteData,
      selectedShips,
      setSelectedShips,
      setAisBunkeringData
    )

    const shipsData = await getShipsPathRequest(
      moment.utc(`${moment(extendedStartDate).format('YYYY-MM-DD')} 00:00:00`).format('YYYY-MM-DD HH:mm:ss'),
      moment.utc(`${moment(extendedEndDate).format('YYYY-MM-DD')} 23:59:59`).format('YYYY-MM-DD HH:mm:ss'),
      synmaxShipId
    )

    const pathData = shipsData.sort(compare) || [];

    const resPathPositions = await getObservedShipsRequest(
      moment.utc(`${moment(extendedStartDate).format('YYYY-MM-DD')} 00:00:00`).format('YYYY-MM-DD HH:mm:ss'),
      moment.utc(`${moment(extendedEndDate).format('YYYY-MM-DD')} 23:59:59`).format('YYYY-MM-DD HH:mm:ss'),
      synmaxShipId
    )

    const updatedOpticalSts = Array.isArray(shipEventData?.optical_sts?.noShipInfo) ? shipEventData?.optical_sts?.noShipInfo?.map((item) => ({
      ...item,
      latitude: item.ship1_lat || item.ship2_lat,
      longitude: item.ship1_lon || item.ship2_lon,
      bunkeringStatus: "Optical_STS"
    })) : []

    const updatedAisSts = Array.isArray(shipEventData?.ais_sts?.noShipInfo) ? shipEventData?.ais_sts?.noShipInfo?.map((item) => ({
      ...item,
      bunkeringStatus: "Ais_STS",
      timestamp: item.timestamp_t0
    })) : []

    const updatedEventPathPositions = [...shipEventData?.spoofing?.initialShips, ...updatedOpticalSts, ...updatedAisSts];

    if (pathData.length) {
      setPathInterpolationData(null)
      setViewPathInterpolation(false)
      const extendedPathData = synmaxShipId.reduce((acum, current) => {
        return {
          ...acum,
          [current]: {
            path: pathData.filter((item) => item.attribution === current || item.synmax_ship_id === current),
            key: parseInt(Math.random() * 10000),
            observations: resPathPositions?.filter((item) => item.attribution === current || item.synmax_ship_id === current).concat(updatedEventPathPositions)
          },
        };
      }, {});
      setSelectedShips(selectedShips => selectedShips.map((ship, i) => {
        const shipId = ship.initialData?.properties?.attribution
          ? ship.initialData?.properties?.attribution
          : ship.initialData?.properties?.synmax_ship_id
            ? ship.initialData?.properties?.synmax_ship_id
            : '';

        if (extendedPathData[shipId]) {
          return {
            ...ship,
            ...extendedPathData[shipId]
          }
        } else {
          return ship
        }
      }))

      setIsPathLoading(false);
      setShowExtendedPathDialog(false);
    } else {
      const extendedPathData = synmaxShipId.reduce((acum, current) => {
        return {
          ...acum,
          [current]: {
            path: [],
            key: parseInt(Math.random() * 10000),
          },
        };
      }, {});

      setSelectedShips(selectedShips => selectedShips.map((ship, i) => {
        const shipId = ship.initialData?.properties?.attribution
          ? ship.initialData?.properties?.attribution
          : ship.initialData?.properties?.synmax_ship_id
            ? ship.initialData?.properties?.synmax_ship_id
            : '';

        if (extendedPathData[shipId]) {
          return {
            ...ship,
            ...extendedPathData[shipId]
          }

        } else {
          return ship
        }
      }))
      setIsPathLoading(false);
      setShowExtendedPathDialog(false);
    }
  };

  return (
    <Draggable
      axis="both"
      handle=".extendedPathDraggable"
      defaultPosition={{ x: 0, y: 0 }}
      position={{ x: extendedPathDialogPosition.clientX, y: extendedPathDialogPosition.clientY }}
      bounds="body"
      onDrag={dragExtendedPathEventHandler}
      onStart={() => setZindex({
        dropPin: false,
        pathSlider: false,
        attribution: false,
        satelliteImage: false,
        extendedPath: true,
        polygonSlider: false,
        polygonDataTable: false,
        futurePathModal: false,
        futurePathSlider: false,
        spoofingTimelineSlider: false,
        portsOfCallSlider: false,
      })}
    >
      <Dialog
        open={showExtendedPathDialog}
        onClose={() => setShowExtendedPathDialog(false)}
        style={{ right: 'auto' }}
        className={`${zIndex.extendedPath ? 'zindex-true' : 'zindex-false'} extendedPathDraggable`}
      >

        <div className="extendedPath">
          <div className="extendedPath__heading">
            {viewSpoofingTimeline ? 'Pick a range for spoofing timeline:' : 'Pick a range for extended path:'}
          </div>
          <IconButton onClick={() => setShowExtendedPathDialog(false)} style={{ position: 'absolute', right: 2, top: 8 }} >
            <CloseIcon />
          </IconButton>
          <div className="extendedPath__content">
            <div className="extendedPath__dates">
              <div className="extendedPath__date extendedPath__date--1">
                <div className="extendedPath__date--text">Start Date</div>
                <DatePicker
                  selected={extendedStartDate}
                  onChange={(date) => {
                    setExtendedStartDate(date);
                  }}
                />
              </div>
              <div className="extendedPath__date">
                <div className="extendedPath__date--text">End Date</div>
                <DatePicker
                  selected={extendedEndDate}
                  onChange={(date) => {
                    setExtendedEndDate(date);
                  }}
                />
              </div>
            </div>

            <LoadingButton
              size="small"
              color='success'
              variant="contained"
              className="button"
              onClick={viewSpoofingTimeline ? viewSpoofingTimlineHeatMap : viewExtendedPath}
              loading={isPathLoading}
              style={{ marginTop: 15 }}
            >
              {viewSpoofingTimeline ? 'View Spoofing Timeline' : 'View Extended Path'}
            </LoadingButton>
          </div>
        </div>
      </Dialog>
    </Draggable>
  )
}

export default memo(ExtendedPath);
