import React, { useContext, useState, useEffect, useCallback } from 'react'
import moment from 'moment';
import { getSatelliteImageRequest } from "../utils";

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { TheiaContext } from '../Theia';
import ShipDrawerData from './ShipDrawerData';

import {
  clientCordinatesObj, futurePathPredictionValuesObj, selectedShipObj, useWindowSize, getSelectedShipData,
  getSelectedShipObject, getShipOptProperties, getShipProperties
} from './Utils/Common';

import styles from './DrawerData.module.css';
import './drawerData.scss'

const DrawerData = ({ isOpenDrawerData, setOpenDrawerData }) => {
  const {
    selectedShips, showPathPredictionDialog, setSelectedShips, setShowSpinner
  } = useContext(TheiaContext);
  const [windowWidth, windowHeight] = useWindowSize();
  const [selectedShipsData, setSelectedShipsData] = useState(selectedShipObj)

  const [imgUrl, setImgUrl] = useState(null)
  const [satelliteImageDialogPosition, setSatelliteImageDialogPosition] = useState(clientCordinatesObj)
  const [extendedPathDialogPosition, setExtendedPathDialogPosition] = useState(clientCordinatesObj)
  const [shipInfo, setShipInfo] = useState({
    shipSpeed: null,
    shipLength: null,
    shipType: null
  })
  const [futurePathPredictionValues, setFuturePathPredictionValues] = useState(futurePathPredictionValuesObj)

  const a = "bunkeringStatus" in selectedShips[selectedShips.length - 1]?.initialData?.properties && ("ship2Properties" in selectedShips[selectedShips.length - 1]?.initialData || "ship1Properties" in selectedShips[selectedShips.length - 1]?.initialData);

  const currentlySelectedShip = selectedShips[selectedShips.length - 1]
  let shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.synmax_ship_id
  if (!shipId) {
    shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.attribution
  }
  let objectId =
    selectedShips[selectedShips?.length - 1]?.initialData?.properties?.object_id;
      console.log(objectId, "objectId");

  const { type, length } = selectedShipsData.static
  const { speed } = selectedShipsData.dynamic;

  const getSatteliteImage = useCallback(
    (objectId) => {
      setShowSpinner(true)
      getSatelliteImageRequest(objectId).then((data) => {
        setImgUrl(data)
        setShowSpinner(false)
      }).catch(() => {
        setShowSpinner(false)
      })
    },
    [setShowSpinner],
  )

  const setInfoForSelectedShip = useCallback(
    (selectedShips) => {
      const selectedShip = selectedShips[selectedShips.length - 1]
      if (selectedShip) {
        const {
          shipData: { dynamic, static: staticData }, initialData, path, latitude: lat, longitude: lon, object_id, heading,
          attribution, shipLength, newSelectedShipObject
        } = getSelectedShipData({ selectedShip, length })

        console.log(shipLength, shipInfo.shipSpeed, "shipLength");

        let { acquired } = initialData.properties

        setFuturePathPredictionValues({
          speed: shipInfo.shipSpeed === 'No Info' ? 0 : shipInfo.shipSpeed,
          length: shipLength,
          heading,
          lat,
          lon,
          ship_type: staticData ? staticData[0].ship_type : initialData.properties.ship_type
        })

        if (object_id) {
          getSatteliteImage(object_id)
        }

        if (attribution === "None") {
          // Unknown Ship
          setSelectedShipsData(newSelectedShipObject)
        } else {
          const { newSelectedShipObject, speeds } = getSelectedShipObject({ selectedShip, dynamic, acquired })

          if (path?.length > 0) {
            newSelectedShipObject.dynamic.speed = {
              value: path[path.length - 1].speed,
              time: moment.utc(path[path.length - 1].timestamp * 1000).format('YYYY-MM-DD HH:mm:ss') + ' UTC'
            }
          }

          if (speeds && speeds.length > 0) {
            newSelectedShipObject.static.avgSpeed = speeds[5]
            newSelectedShipObject.static.maxSpeed = speeds[9]
          }
          setSelectedShipsData(newSelectedShipObject)
        }
      }
    },
    [getSatteliteImage, length, shipInfo.shipSpeed],
  )

  useEffect(() => {
    setInfoForSelectedShip(selectedShips)
  }, [showPathPredictionDialog, shipId, objectId, a, setInfoForSelectedShip]);

  useEffect(() => {
    setExtendedPathDialogPosition({
      clientX: 0,
      clientY: 0
    });
    setSatelliteImageDialogPosition({
      clientX: 0,
      clientY: 0
    });
  }, [windowWidth, windowHeight])

  if ("bunkeringStatus" in selectedShips[selectedShips.length - 1]?.initialData?.properties) {
    if (!("ship1Properties" in selectedShips[selectedShips.length - 1]?.initialData)) {
      const updatedSelectedShips = selectedShips?.map((ship) => {
        const initial = ship.initialData.properties;

        const ship1Properties = getShipProperties(initial, 1)
        const ship2Properties = getShipProperties(initial, 2)

        const ship1OptProperties = getShipOptProperties(initial, 1)
        const shipOpt2Properties = getShipOptProperties(initial, 2)

        return {
          ...ship,
          initialData: {
            ...ship.initialData,
            isShipOne: true,
            id: parseInt(Math.random() * 10000),
            properties: {
              ...ship.initialData.properties
            },
            ship1Properties: ship.initialData.properties?.bunkeringStatus === "Ais_STS" ? ship1Properties : ship1OptProperties,
            ship2Properties: ship.initialData.properties?.bunkeringStatus === "Ais_STS" ? ship2Properties : shipOpt2Properties
          }
        };
      });

      setSelectedShips(updatedSelectedShips)
    }
  }

  useEffect(() => {
    if (typeof speed.value === "number" && speed.value > 0) {
      setShipInfo((prev) => ({
        ...prev,
        shipSpeed: speed.value
      }));
    }

    setShipInfo((prev) => ({
      ...prev,
      shipLength: length !== undefined ? length : selectedShipsData?.static?.initialData?.properties?.length
    }));

    setShipInfo((prev) => ({
      ...prev,
      shipType: type === "No Info" ? selectedShipsData?.static?.initialData?.properties?.ship_type : type
    }));
  },
    [
      speed.value, selectedShipsData?.static?.initialData?.properties?.length,
      selectedShipsData?.static?.initialData?.properties?.ship_type,
      length, type
    ])

  useEffect(() => {
    setFuturePathPredictionValues((prev) => ({
      ...prev,
      length: shipInfo.shipLength,
      speed: shipInfo.shipSpeed,
      ship_type: shipInfo.shipType
    }))
  }, [shipInfo])

  const dragSatelliteImageEventHandler = (e, data) => {
    setSatelliteImageDialogPosition({
      clientX: data.x,
      clientY: data.y
    })
  }

  const dragExtendedPathEventHandler = (e, data) => {
    setExtendedPathDialogPosition({
      clientX: data.x,
      clientY: data.y
    })
  }

  return (
    <div className='drawerData'>
      {
        isOpenDrawerData ? (
          <ShipDrawerData
            futurePathPredictionValues={futurePathPredictionValues}
            setFuturePathPredictionValues={setFuturePathPredictionValues}
            dragSatelliteImageEventHandler={dragSatelliteImageEventHandler}
            satelliteImageDialogPosition={satelliteImageDialogPosition}
            imgUrl={imgUrl}
            extendedPathDialogPosition={extendedPathDialogPosition}
            dragExtendedPathEventHandler={dragExtendedPathEventHandler}
            selectedShips={selectedShips}
            setOpenDrawerData={setOpenDrawerData}
            selectedShipsData={selectedShipsData}
            shipId={shipId}
          />
        ) : (
          <div className={styles.buttonDrawerOpen} onClick={() => setOpenDrawerData(true)}>
            <div className={styles.buttonDrawerOpenText}>View Ship Details</div>
            <ArrowUpwardIcon className={styles.buttonDrawerOpenIcon} />
          </div>
        )
      }
    </div>
  )
}

export default DrawerData;
