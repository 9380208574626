import React, { useState, useContext } from 'react'
import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { TheiaContext } from '../Theia';
import './drawerData.scss'

const FuturePathPrediction = () => {
  const { setPathPredictionData } = useContext(TheiaContext)
  const [callingStartApi, setCallingStartApi] = useState(false)
  const [statusUrl, setStatusUrl] = useState(null)
  const [checkingStatus, setCheckingStatus] = useState(false)
  const [progressValue, setProgressValue] = useState(null)
  const [resultUrl, setResultUrl] = useState(null)

  const fetchPathPredictionData = async () => {
    try {
      setCallingStartApi(true)
      const res = await axios.post('http://35.224.239.165:8080/ship-future/start',
      { "lat": 10.581182, "lon": 108.25798, "speed": 20, "length": 150, "heading": 45, "ship_type": "cargo" })
      if(res?.data?.status_url) {
        console.log(res?.data?.status_url, 'res.status_url')
        setStatusUrl(res?.data?.status_url)
        // axios.get(res.data.status_url)
      }
    } catch (err) {
      console.log(err, ':err')
    }
  }

  const checkStatus = async () => {
    try {
      setCheckingStatus(true)
      const res = await  axios.get(statusUrl)
      setCheckingStatus(false)  
      const progress = parseInt(res?.data?.progress)
      setProgressValue(progress)
      if(progress === 100) {
        setCallingStartApi(false)
        setResultUrl(res?.data?.result_url)
      } else {
        console.log(progress, 'progress')
      }
    } catch(err) {
      console.log(err, ': err')
    }
  }

  const loadDataOnMap = async () => {
    try {
      const token = localStorage.getItem('token')
      let res;
      if (process.env.REACT_APP_NGA_ENV === true || process.env.REACT_APP_NGA_ENV === "true") {
        res = await axios.get(resultUrl, {
          headers: {
              Authorization: 'Bearer ' + token,
          },
      })
      } else {
        res = await axios.get(resultUrl)
      }
     
    // example result url: http://35.224.239.165:8080/ship-future/result/bf810691bb4043a4bb366ff365cfff80
    setPathPredictionData(res.data.futures)
    } catch (err) {
      console.log(err, ': err')
    }
  }
  return (
    <div className='futurePathPredictionTab'>
      <Button variant="contained" style={{ marginTop: '2rem', color: '#000', background: '#fff', borderColor: '#24A0CA' }} onClick={fetchPathPredictionData}>

        {callingStartApi ? (<CircularProgress
          sx={{
            color: '#000'
          }}
          size={20}
        />) : 'Fetch Path Prediction Data'}

      </Button>

      <div>
        {statusUrl && (
          <Button variant="contained" style={{ marginTop: '2rem', color: '#000', background: '#fff', borderColor: '#24A0CA' }} onClick={checkStatus}>

            {checkingStatus ? (<CircularProgress
              sx={{
                color: '#000'
              }}
              size={20}
            />) : 'Get Status Update'}

          </Button>
        )}
      </div>

      <div style={{ color: '#fff', fontSize: 16 }}>
        {progressValue && (progressValue === 100 ? 
       
        <Button variant="contained" style={{ marginTop: '2rem', color: '#000', background: '#fff', borderColor: '#24A0CA' }} onClick={loadDataOnMap}>
          {checkingStatus ? (<CircularProgress
            sx={{
              color: '#000'
            }}
            size={20}
          />) : 'Load Data'}
        </Button> : `${progressValue}% done`)}

      
      </div>
    </div>
  )
}

export default FuturePathPrediction