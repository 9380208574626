import React, { memo } from "react";
import {
  CircleMarker,
  Popup,
} from "react-leaflet";
import moment from "moment";
import SuperCluster from "./utils/SuperCluster";
import { PolylineDecorator, formatPathData } from "./utils/PolylineDecorator";

export const PathRender = memo(
  ({
    pathData,
    index,
    color,
    refreshKey,
    viewAnalyzePath,
    extendedPathSpoofing,
  }) => {
    const RenderMultiplePaths = (pathData, extendedPathSpoofing) => {
      const allSpoofingPoints = extendedPathSpoofing.map((spoofingPoint) => {
        const spoofData = pathData.filter(
          (point) =>
            point.timestamp >= spoofingPoint.timestamp &&
            point.timestamp <= spoofingPoint.timestamp + 86400
        );
        return spoofData;
      });

      const allSpoofPointsFlat = allSpoofingPoints.flat();

      const allPathPoints = pathData.filter(
        (item) => !allSpoofPointsFlat.includes(item)
      );

      return (
        <SuperCluster
          allSpoofPointsFlat={allSpoofPointsFlat}
          allSpoofingPoints={allSpoofingPoints}
          index={index}
          refreshKey={refreshKey}
          pathData={pathData}
          viewAnalyzePath={viewAnalyzePath}
          allPathPoints={allPathPoints}
          color={color}
        />
      );
    };

    return (
      <div>
        {extendedPathSpoofing ? (
          RenderMultiplePaths(pathData, extendedPathSpoofing)
        ) : viewAnalyzePath ? (
          pathData.map(({ latitude: lat, longitude: lon, timestamp: t }, i) => (
            <CircleMarker
              key={i}
              center={{ lat, lng: lon }}
              radius={5}
              color={color}
            >
              <Popup>
                <div>
                  Time:{" "}
                  {moment.utc(t * 1000).format("YYYY-MM-DD HH:mm:ss") + " UTC"}
                </div>
                <div>Lat: {lat}</div>
                <div>Lon: {lon}</div>
              </Popup>
            </CircleMarker>
          ))
        ) : (
          <PolylineDecorator
            preferCanvas={true}
            key={"path" + index + refreshKey}
            positions={formatPathData(pathData)}
            color={color}
            opacity={1}
          />
        )}
      </div>
    );
  }
);


