import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom';
import { initializeApp,  } from 'firebase/app';
import { getFirestore, doc, getDoc, setDoc, collection, addDoc, getDocs } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions } from 'firebase/functions'
import { CircularProgress, Snackbar, SnackbarContent } from '@mui/material';

import Auth from './pages/Auth/Auth';
import './App.scss';
import Admin from './pages/admin/Admin';
// import Theia from './pages/Theia/Theia'; 
import Theia from './pages/Theia-Phase3/Theia'

const REACT_APP_ENV = process.env.REACT_APP_ENV;

export const firebaseConfig = REACT_APP_ENV === 'dev' ? {
  apiKey: "AIzaSyA895Qfqtls2Ckww7JE35QtQG5bcj9O5S8",
  authDomain: "theia-dev-376415.firebaseapp.com",
  projectId: "theia-dev-376415",
  storageBucket: "theia-dev-376415.appspot.com",
  messagingSenderId: "861000466587",
  appId: "1:861000466587:web:c20ea509df245c3caa063e",
  measurementId: "G-20E2YQG6N5"
} : {
  apiKey: "AIzaSyBQ04Z_dL7AvznjFInivS5WEVbYOD00lPk",
  authDomain: "theia-prod-401716.firebaseapp.com",
  projectId: "theia-prod-401716",
  storageBucket: "theia-prod-401716.appspot.com",
  messagingSenderId: "842455938625",
  appId: "1:842455938625:web:119144c578d563fae338c0",
  measurementId: "G-VY946CN9DB"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const FirebaseFunctions = getFunctions(app);

export default function App() {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isTokenLoading, setIsTokenLoading] = useState(true);
  const [isNewUser, setIsNewUser] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  // userData ~ user profile (has email and role)
  const [userData, setUserData] = useState({});
  const [snackBarMsg, setSnackBarMsg] = useState('');

  let auth = getAuth();
  let isloggedIn = auth.currentUser;
 
  useEffect(() => {
    //extra code to check build
    // This function from  firebase is called when our authentication state changes(eg: logged in/ logged out)
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const { uid, email } = user;
        const docRef = doc(db, 'users', uid);
        const docSnap = await getDoc(docRef);
        const userData = docSnap.data();
        setUserData({ ...userData, uid });
        const token = await user.getIdToken();
        console.log(userData, )
        if (uid && !userData) {
          // It means this is a new user, so it doesn't have any profile data yet
          // So we create a profile for this user on firebase
          let firstName = '';
          let lastName = '';

          if(!localStorage.getItem("version")){
            localStorage.setItem("version", "1.0.0")
          }

          if (localStorage.getItem('firstName')) {
            firstName = localStorage.getItem('firstName');
            localStorage.removeItem('firstName');
          }

          if (localStorage.getItem('lastName')) {
            lastName = localStorage.getItem('lastName');
            localStorage.removeItem('lastName');
          }
          await setDoc(doc(db, 'users', uid), {
            email,
            role: 'new_user',
            firstName,
            lastName,
            isNewUser: true,
          });
        }

        // We are checking the user's role
        // As you need to be a customer, co-worker, admin, or super-user to view the app
        const role = userData ? userData.role : '';
        if (
          role === 'super_user' ||
          role === 'admin' ||
          role === 'co-worker' ||
          role === 'theia_customer' ||
          (role === 'temporary_theia_user' &&
            userData.expiry_date.seconds > Date.now() / 1000)
        ) {
          localStorage.setItem('token', token)
          setIsTokenLoading(false);
        } else {
          if (token) {
            signOut(auth).then(() => {
              setSnackBarMsg('Email info@synmax.com For Access');
              setIsSnackBarOpen(true);
            });
          } else {
            setSnackBarMsg('Email info@synmax.com For Access');
            setIsSnackBarOpen(true);
          }
        }
        setIsTokenLoading(false);
      } else {
        setIsTokenLoading(false);
      }
    });
  }, []);

  useEffect(()=>{
    // addUpdatesCollection();
    if(!localStorage.getItem("version")){
      localStorage.setItem("version", "1.0.0")
    }
  },[])


  const addUpdatesCollection = async () => {

    let allData;
    const querySnapshot = await getDocs(collection(db, 'updates'));

    if(querySnapshot){
        querySnapshot.forEach((doc) => {
          allData = doc.data();
        })
    }

    if(!allData){
      await setDoc(doc(db, "updates", "new_feature"), {
            version : "1.0.0",
            newUpdate : {
              message: "Important Security Update",
              content: "We prioritize your online security and want to inform you about an important update regarding our system's security measures. As part of our ongoing commitment to safeguard your data and protect your privacy, we have implemented enhanced security protocols to ensure the highest level of protection for your account."
          }
        }).then(()=>{
        console.log("success done");
        }).catch((err)=>{
        console.log(err,'something went wrong');
      });
    }

  }

  useEffect(() => {
    setInterval(() => {
      if (isloggedIn) {
        refreshToken();
      }
    }, 5000);

    const refreshToken = async () => {
      const token = await isloggedIn.getIdToken();
      localStorage.setItem('token', token)
    };
  }, [isloggedIn]);

  const logout = () => {
    signOut(auth)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error, 'err');
      });
  };

  const PrivateOutlet = () => {
    return isloggedIn ? <Outlet /> : <Navigate to="/login" />;
  };

  const PublicOutlet = () => {
    const token = localStorage.getItem('token')
    return isloggedIn && token !== '' ? <Navigate to="/" /> : <Outlet />;
  };

  const AdminRoute = () => {
    // We have already prevented 'new_user' from accessing any page in the "onAuthStateChanged" function above
    // This route makes sure that only people in our company has access to certain pages
    const { role } = userData;
    console.log(role);
    if (isloggedIn) {
      if (role === 'super_user' || role === 'admin' || role === 'co-worker') {
        return <Outlet />;
      } else {
        setSnackBarMsg("You don't have access to that page");
        setIsSnackBarOpen(true);
        return <Navigate to="/" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  };

  return isTokenLoading ? (
    <div className="loadingPage">
      <CircularProgress />
    </div>
  ) : (
    <>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackBarOpen(false)}
        message={snackBarMsg}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        className="askKevin"
      >
        <SnackbarContent
          message={<span id="client-snackbar">{snackBarMsg}</span>}
        />
      </Snackbar>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PrivateOutlet />}>
            <Route
              path=""
              element={
                <Theia logout={logout} userData={userData} />
              }
            />
          </Route>
          <Route path="/admin" element={<AdminRoute />}>
              <Route path="" element={<Admin userData={userData} />} />
            </Route>

          <Route path="/login" element={<PublicOutlet />}>
            <Route
              path=""
              element={
                <Auth
                  isNewUser={isNewUser}
                  setIsNewUser={setIsNewUser}
                  forgotPassword={forgotPassword}
                  setForgotPassword={setForgotPassword}
                />
              }
            />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
