import React, { useState, useContext, useEffect } from 'react'
import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { Button, CircularProgress } from '@mui/material';

import { TheiaContext } from '../Theia'
import { frontendAPIURL } from '../utils';

const PathPredictionLoaderUI = () => {
  const { setPathPredictionStatusUrl, setPathPredictionData, progressStatus, setProgressStatus, pathPredictionData, setFuturePathObj, progressValue, setProgressValue, resultUrl, setResultUrl, intervalId, setIntervalId } = useContext(TheiaContext);
  const [isIconClicked, setIsIconClicked] = useState(false)
  // const [percentDone, setPercentDone] = useState(0)
  const [pathPredictionDataLoading, setPathPredictionDataLoading] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  // useEffect(() => {
  //    const predictionInterval = setInterval(checkStatus, 10000)
  //    setIntervalId(predictionInterval)
  // },[])


  const loadDataOnMap = async () => {
    try {
      setPathPredictionDataLoading(true)
      const token = localStorage.getItem('token')
      let res;
      if (!isCanceled) {
       
          // res = await axios.get(resultUrl, {
          //   headers: {
          //     Authorization: 'Bearer ' + token,
          //   },
          // });

          res = await axios.post(`${frontendAPIURL}/future_location` ,{action: "result", task_id: resultUrl},{
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          })

  
        setPathPredictionData(res.data.futures);
        setPathPredictionDataLoading(false);
        console.log(intervalId, ':intervalId');
        clearInterval(intervalId);
        setIntervalId(null);
      }
    } catch (err) {
      console.log(err, ': err')
    }
  }

  const exitSlider = () => {
    clearInterval(intervalId)
    setPathPredictionStatusUrl(null)
    setPathPredictionData(null)
    setFuturePathObj(null)
    setProgressValue(0)
    setResultUrl(null)
    setProgressStatus('')
  }

  const cancelProcess = () => {
    setIsCanceled(true); 
    clearInterval(intervalId)
    setPathPredictionStatusUrl(null)
    setPathPredictionData(null)
    setFuturePathObj(null)
    setProgressValue(0)
    setResultUrl(null)
    setProgressStatus('')
  }


  return (
    <div className='pathPredictionLoaderUI'>
      <div className="">
        {isIconClicked ?
          (<div className="pathPredictionLoaderUI__percent">
            <div className='pathPredictionLoaderUI__strong'>{progressValue}</div>% Done
            <div className="pathPredictionLoaderUI__close">
              <CloseIcon
                style={{ cursor: 'pointer' }}
                onClick={() => setIsIconClicked(false)}
              />
            </div>
            <div className="" style={{ marginTop: 10 }}>
              {resultUrl && resultUrl !== 'Failed' && !pathPredictionData && <Button variant="contained" onClick={loadDataOnMap}>
                {pathPredictionDataLoading ? (<CircularProgress
                  sx={{
                    color: '#fff'
                  }}
                  size={20}
                />) : 'Load Data'}
              </Button>}
              <div>
                {progressStatus}
              </div>

             {!resultUrl && <Button variant='contained' onClick={cancelProcess}>Cancel the process</Button>} 
             {resultUrl === 'Failed' && 'Process Failed'}
              {(pathPredictionData || resultUrl === 'Failed') && <Button variant='contained' onClick={exitSlider}>Exit Process</Button>}
            </div>
          </div>) :
          (<UpdateIcon style={{ width: '4rem', height: '4rem' }} fontSize="large" color='primary' onClick={() => setIsIconClicked(true)} />)}
      </div>

    </div>
  )
}

export default PathPredictionLoaderUI;