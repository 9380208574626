import React from 'react'
import { GeoJSON } from 'react-leaflet';

const UploadGeoJson = ({uploadedGeojsons}) => {
  return uploadedGeojsons.map(({data, key, isVisible}) => {
    console.log(isVisible, ':isvisisb')
    if(isVisible) {
       return <GeoJSON data={data} key={key}/>
    } else {
        return;
    }
  }
  )
}

export default UploadGeoJson