import React, { useContext, useState } from 'react';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { TheiaContext } from './Theia'
import './theiaWorkspace.scss';

const getImgUrl = (type) => {
  let imgUrl = ''
  if (type === 'orange') {
    imgUrl = './orangeEyeSquare.svg'
  }
  if (type === 'blue') {
    imgUrl = './blueEyeSquare.svg'
  }
  if (type === 'ais') {
    imgUrl = './aisSquare.svg'
  }
  if (type === 'red') {
    imgUrl = './redSquare.svg'
  }

  if (type === 'footprint') {
    imgUrl = './pinkGhost.svg'
  }
  if (type === 'pink') {
    imgUrl = './seethroughPinky.svg'
  }
  return imgUrl
}

export const TheiaWorkspace = () => {
  const [color, setColor] = useState("#ccc");
  const { selectedShips, setSelectedShips, setShipViewToggle, shipViewToggle, setPathInterpolationData, setViewPathInterpolation, setTileLayerUrl, setViewSatelliteImage } = useContext(TheiaContext);

  const changeVisibility = (shipIndex, type) => {
    setShipViewToggle(!shipViewToggle)
    setSelectedShips(selectedShips => selectedShips.map((ship, i) => {
      if (ship.shipIndex === shipIndex) {
        if (type === 'path') {
          return {
            ...ship,
            pathVisible: !ship.pathVisible
          }
        } else {
          return {
            ...ship,
            visible: !ship.visible
          }
        }

      } else {
        return ship
      }
    }))
  }

  const deleteShip = (shipIndex) => {
    setSelectedShips(selectedShips => selectedShips.filter((ship, i) => ship.shipIndex !== shipIndex))
  }

  const handleColorChange = (shipIndex, e) => {

    const selectedShipIndices = selectedShips.map(ship => ship.shipIndex);

    if (selectedShipIndices.includes(shipIndex)) {
      const updatedSelectedShips = selectedShips.map(ship => {
        if (ship.shipIndex === shipIndex) {
          return {
            ...ship,
            color: e.target.value,
          };
        }
        return ship;
      });

      setSelectedShips(updatedSelectedShips);
    }

    setColor(e.target.value)
  }

  const makeShipSelected = (shipIndex) => {
    setTileLayerUrl(null)
    setViewSatelliteImage(false)
    setPathInterpolationData(null)
    setViewPathInterpolation(false)
    setSelectedShips(selectedShips => ([...selectedShips.filter((ship, i) => ship.shipIndex !== shipIndex), selectedShips.filter(ship => ship.shipIndex === shipIndex)[0]]))
  }

  return (
    <div className='theiaWorkspace'>
      {[...selectedShips].sort(compare).map(({ color, shipCategory, shipData, shipIndex, visible, pathVisible, initialData }, i) => (
        <div className="theiaWorkspace__item" key={i} >
          <div className="theiaWorkspace__details" onClick={() => makeShipSelected(shipIndex)}>
            <div className="theiaWorkspace__colorBox" style={{ border: `${shipIndex === (selectedShips[selectedShips.length - 1]?.shipIndex) ? 4 : 2}px solid ${color}` }}>
              <img src={initialData.properties.fromDecoupleModel ? './bunkeringIcon.svg' : `${getImgUrl(shipCategory)}`} alt="" />
            </div>
            <div className="theiaWorkspace__name">{shipData.static && shipData.static.length > 0 && shipData.static[0].name}</div>
          </div>


          <div className="theiaWorkspace__actions">
            <div className="theiaWorkspace__pathView" onClick={() => changeVisibility(shipIndex, 'path')}>
              <RouteOutlinedIcon
                style={{
                  color: '#ccc',
                  fontSize: '1.8rem',
                  cursor: 'pointer',
                  opacity: pathVisible ? 1 : 0.3,
                }}
              />
            </div>
            <div className="theiaWorkspace__view" onClick={() => changeVisibility(shipIndex, 'ship')}>
              <RemoveRedEyeOutlinedIcon
                style={{
                  color: '#ccc',
                  fontSize: '1.8rem',
                  cursor: 'pointer',
                  opacity: visible ? 1 : 0.3,
                }}
              />
            </div>
            <div className="theiaWorkspace__delete" onClick={() => deleteShip(shipIndex)}>
              <DeleteOutlinedIcon
                style={{
                  color: '#ccc',
                  fontSize: '1.8rem',
                  cursor: 'pointer',
                }}
              />
            </div>

            <div className="creatNewTimeseries__color">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="color"
                  name="color"
                  id="colorSelector"
                  value={color}
                  style={{ width: "25px", height: "25px", marginLeft: 10}}
                  onChange={(e) => handleColorChange(shipIndex, e)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}


function compare(a, b) {
  if (a.shipIndex < b.shipIndex) {
    return -1;
  }
  if (a.shipIndex > b.shipIndex) {
    return 1;
  }
  return 0;
}