import React from 'react'

export const SatelliteData = ({imgUrl, setIsImgDialogOpen, initialData, setMarineTrafficImgUrl}) => {
  const { length, ship_type, speed_max, speed_min,xPixel,yPixel, width } = initialData.properties;

  const showSatelliteImage = () => {
    setMarineTrafficImgUrl(null)
    setIsImgDialogOpen(true)
  }
  return (
    <div className='satelliteData'>
        <div className="satelliteData__image" onClick={showSatelliteImage}>
        {(xPixel && yPixel && <div className="pointer" style={{top: `${yPixel/192 * 100}%`, left: `${xPixel/192 * 100}%`}}></div>)}  
        <img src={imgUrl} alt="" />
        </div>
        <div className="satelliteData__info">
          <div className="satelliteData__dataPoint">
            <span className="setelliteData__title">Length: </span>
            <span className="setelliteData__value">{length}</span>
          </div>
          <div className="satelliteData__dataPoint">
            <span className="setelliteData__title">Ship Type: </span>
            <span className="setelliteData__value">{ship_type}</span>
          </div>
          <div className="satelliteData__dataPoint">
            <span className="setelliteData__title">Width </span>
            <span className="setelliteData__value">{width}</span>
          </div>
        </div>
    </div>
  )
}