import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import Draggable from 'react-draggable';
import { Slider, Select, MenuItem } from '@mui/material';
import moment from 'moment';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ReplayIcon from '@mui/icons-material/Replay';

import './pathSlider.scss';
import { TheiaContext } from '../../Theia';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';


function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const PathSlider = ({ selectedShips, pathSliderRef, viewPathSlider }) => {
  const {  setZindex, zIndex, filters, setFilters, setViewPathSlider } =  useContext(TheiaContext);

  const [value, setValue] = useState(0);
  const [path, setPath] = useState([]);
  const [showPlayBtn, setShowPlayBtn] = useState(true);
  const [intervalReference, setIntervalReference] = useState(null);
  const [dropdownSpeed, setDropdownSpeed] = useState(600);
  const [valueChangedBydragging, setValueChangedByDragging] = useState(false)
  const [showReplayBtn, setShowReplayBtn] = useState(false)

  const [windowWidth, windowHeight] = useWindowSize();
  const [pathSliderDialogPosition, setPathSliderDialogPosition] = useState ({
    clientX: null,
    clientY: null
  })

  useEffect(() => {
    setPathSliderDialogPosition({
      clientX: -500,
      clientY: -250
    });
  }, [windowWidth, windowHeight])

  useEffect(() => {
    console.log('calling useeffect')
    console.log(selectedShips, 'selectedShips')
    console.log(pathSliderRef, 'pathSliderRef')
    if (selectedShips.length > 0 && pathSliderRef.current) {
      console.log('we in')
      const shipPath = selectedShips[selectedShips.length - 1].path
      setPath(shipPath);
      pathSliderRef.current(shipPath[0], 0);
    }
  }, [selectedShips, pathSliderRef, viewPathSlider]);

  useEffect(() => {
    if (value === path.length - 1 || value === path.length) {
      clearInterval(intervalReference);
      setIntervalReference(null);
      if (value !== 0) {
        console.log('now showing replay button')
        setShowReplayBtn(true)
        setShowPlayBtn(true)
      }
    } else {
      if (showReplayBtn) {
        setShowReplayBtn(false)
      }
      if (!showPlayBtn && valueChangedBydragging) {
        clearInterval(intervalReference);
        setIntervalReference(null);
        playSlider();
      } else {
        if (pathSliderRef.current) {
          console.log(path[value], 'path[value]')
          console.log(value, 'value')
          pathSliderRef.current(path[value], value);
        }
      }
    }
    setValueChangedByDragging(false);
  }, [value]);

  useEffect(() => {
    if (intervalReference && !showPlayBtn) {
      clearInterval(intervalReference);
      setIntervalReference(null)
      playSlider();
    }
  }, [dropdownSpeed]);

  const handleChange = (event, newValue) => {
    setValueChangedByDragging(true)
    setValue(newValue);
  };

  const getLabel = (value) => {
    console.log(path, ':path, value:',value)
    if (path?.length > 0) {
      return moment.utc(path[value].timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return 'No Data';
    }
  };
  function valuetext(value) {
    return `${value}°C`;
  }

  const playSlider = () => {
    setShowPlayBtn(false);
    let newValue = value;
    const intervalref = setInterval(() => {
      setValue(newValue + 1);
      newValue++;
    }, dropdownSpeed);
    setIntervalReference(intervalref);
  };

  const pauseSlider = () => {
    setShowPlayBtn(true);
    // for (var i=0; i<timeoutArr.length; i++) {
    //   clearTimeout(timeoutArr[i]);
    // }
    clearInterval(intervalReference);
  };

  const rePlaySlider = () => {
    setValue(0)
  }

  const draggableEventHandler = (e, data) => {
    setPathSliderDialogPosition({
      clientX: data.x,
      clientY: data.y
    })
  }

  const viewPathAnimationSlider = () => {
    setViewPathSlider(!viewPathSlider)
    const { ais, light, dark } = filters;
    setFilters({
      ...filters,
      ais: !ais,
      light: !light,
      dark: !dark
    })
  };

  return (
    <Draggable
      axis="both"
      handle=".pathSlider"
      cancel="div.pathSlider__sliderBox"
      position={{x: pathSliderDialogPosition.clientX, y: pathSliderDialogPosition.clientY }}
      onDrag={draggableEventHandler}
      bounds={{right: 0, bottom: 0}}
      onStart={() => setZindex({
        dropPin: false,
        pathSlider: true,
        attribution: false,
        satelliteImage: false,
        extendedPath: false,
        polygonSlider: false,
        polygonDataTable:false,
        futurePathModal: false,
        futurePathSlider: false,
        spoofingTimelineSlider : false,
        portsOfCallSlider: false,
      })}
    >
      <div className={`${zIndex.pathSlider ? 'zindex-true' : 'zindex-false'} pathSlider`}>
        <div className="pathSlider__sliderBox">
        <CloseIcon onClick={viewPathAnimationSlider} style={{ cursor: 'pointer', color:"#414141", position:'absolute', right:"10px", top:"5px" }} />
          {showReplayBtn ? (
            <div className="pathSlider__playIcon" onClick={rePlaySlider}>
              <ReplayIcon sx={{ color: '#24A0CA', fontSize: 30 }} />
            </div>
          ) : showPlayBtn ? (
            <div className="pathSlider__playIcon" onClick={playSlider}>
              <PlayArrowIcon sx={{ color: '#24A0CA', fontSize: 30 }} />
            </div>
          ) : (
            <div className="pathSlider__playIcon" onClick={pauseSlider}>
              <PauseIcon sx={{ color: '#24A0CA', fontSize: 30 }} />
            </div>
          )}

          <Slider
            size="medium"
            max={path.length - 1}
            value={value}
            valueLabelFormat={getLabel}
            aria-label="Small"
            valueLabelDisplay="on"
            onChange={handleChange}
            style={{
              color: '#24A0CA',
            }}
          />
        </div>

        <Select
          labelId="demo-simple-select-label"
          id="select-speed-dropdown"
          value={dropdownSpeed}
          label="Speed"
          onChange={(e) => setDropdownSpeed(e.target.value)}
        >
          <MenuItem value={600}>1x</MenuItem>
          <MenuItem value={200}>2x</MenuItem>
          <MenuItem value={0}>3x</MenuItem>
        </Select>
      </div>
    </Draggable>
  );
};

export default PathSlider;
