import React, { useContext, useEffect } from 'react';
import { Polyline, Rectangle, GeoJSON, Tooltip } from 'react-leaflet';
import { scaleThreshold } from 'd3-scale';
import { TheiaContext } from '../../Theia';
import * as turf from '@turf/turf';
import moment from 'moment';

const AllShipsHeatmap = () => {
    const { currentLocationData, currentLocation, selectedShips } = useContext(TheiaContext);

    const newData = selectedShips?.filter((item) => {
        return (item?.initialData?.properties?.synmax_ship_id || item?.initialData?.properties?.attribution ) === currentLocationData?.geojson?.shipId;
    });

    const polygons = currentLocationData.geojson?.features || [];

    const date = currentLocationData.geojson?.date;
    const updatedDate = moment.utc(`${moment(date).format('YYYY-MM-DD')} 00:00:00`).format('YYYY-MM-DD') 

    const occurrenceArray = polygons.map((polygon) => polygon.properties.confidence);

    const maxConfidenceIndex = occurrenceArray.indexOf(Math.max(...occurrenceArray));

    const center = polygons?.length > 0 &&  turf.center(polygons[maxConfidenceIndex])

    // Define color scale using d3-scale
    const colorScale = scaleThreshold()
        .domain([80, 85, 90, 95, 100]) 
        .range(['#481567FF', '#39568CFF', '#1F968BFF', '#55C667FF', '#FDE725FF']);
        
    const limeOptions = { color: 'lime' }

    return (
        <>
            {
                currentLocation && polygons.length > 0 && selectedShips.length > 0 && (
                    <>
                        {polygons?.map((polygon, index) => {
                            
                            let geojsonData;
                            if (polygon.geometry.type === "Polygon") {
                                geojsonData = {
                                    type: "Feature",
                                    geometry: polygon.geometry,
                                    properties: {}
                                };
                            } else if (polygon.geometry.type === "MultiPolygon") {
                                geojsonData = {
                                    type: "Feature",
                                    geometry: {
                                        type: "MultiPolygon",
                                        coordinates: polygon.geometry.coordinates
                                    },
                                    properties: {}
                                };
                            }
                            return (
                                <>
                                    <GeoJSON
                                        key={index}
                                        data={geojsonData}
                                        style={() => ({
                                            color: colorScale(occurrenceArray?.[index]),
                                            fillOpacity: 0.2,
                                            weight: 2
                                        })}
                                    />

                                    <Polyline
                                        pathOptions={limeOptions}
                                        positions={[[newData?.[0]?.initialData.geometry.coordinates[1], newData?.[0]?.initialData.geometry.coordinates[0]], [center?.geometry?.coordinates[1], center?.geometry?.coordinates[0]]]}
                                        color='#FDE725FF' dashArray={'20, 20'} dashOffset='20' weight={1}>
                                        <Tooltip permanent={true}>Distance:
                                            {getDistance([newData?.[0]?.initialData?.geometry?.coordinates[1],
                                            newData?.[0]?.initialData?.geometry?.coordinates[0]],
                                                [center?.geometry?.coordinates[1], center?.geometry?.coordinates[0]])}
                                            miles
                                            <br />
                                            Date: {updatedDate}
                                        </Tooltip>
                                    </Polyline>
                                </>
                            )
                        })}
                    </>
                )
            }
        </>
    )
}

const getDistance = (pointA, pointB) => {
    var from = turf?.point(pointB);
    var to = turf?.point(pointA);
    var options = { units: 'miles' };

    return Math.round(turf.distance(from, to, options));
}

export default AllShipsHeatmap;
