import React, { useEffect, useContext, useState } from 'react'
import axios from 'axios';
import moment from 'moment';

import AisShips from './AisShips';
import DarkDetections from './DarkDetections';
import BlueDetections from './BlueDetections';
import DrawnPolygonShips from './DrawnPolygonShips';
import OrangeDetections from './OrangeDetections';
import { TheiaContext } from '../../Theia';
import { filterThroughDataManager, filterPinkOrangeAndBlueShips, filterAisShipsWhenUsingPolygon, filterRedShips, filterPurpleShipsWhenUsingPolygon } from './utils';
import PurpleDetections from './PurpleDetections';
import { frontendAPIURL } from '../../utils';
import SpoofingDetections from './SpoofingDetections';
import SimilarDetections from './SimilarDetections';
import FootPrintSpoofing from './FootPrintSpoofing';
import Bunkering from './Bunkering';
import AisSpoofing from './AisSpoofingDetection';
import { ShipInfoResponse } from '../utils/ShipInfoResponse';
import SanctionedShips from './SanctionedShips';

export const ShipRenderContext = React.createContext();

const AllRenderedShips = ({ mapRef, setShowSpinner, date }) => {
    const { setAisShips, aisShips, isFiltersChanged, setInitialDate, zoomLevel, filters, deselectedShipsObjectIds, filterAisData, polygonSliderValue, polygonSliderAisShipsKey,
        setFilterAisData, footprintShips, setFootprintShips,AisSpoofingShips, setAisSpoofingShips, selectedShips, setSelectedShips, spoofingDetections, setSpoofingDetections, filtersDetectionsKey, isAttributionDataUpdated, similarDetections, refreshViewClicked, dragValueForRerender, blueShipDetections, startEpochDate, orangeShipDetections, setOrangeShipDetections, setBlueShipDetections, darkShipDetections, setDarkShipDetections, selectedPolygons, setPolygonShip, setPurpleShips, purpleShips, purpleShipVisible, dragEndValueForRerender, setDragEndValueForRerender, sanctionedShipsPositionData } = useContext(TheiaContext);

    const { sentinel2Unknown, planetScopeUnknown, sentinel1Unknown, sentinel2Dark, planetScopeDark, sentinel1Dark, sentinel2Light, planetScopeLight, sentinel1Light, ais, lengthAis, widthAis, typeAis, countryAis, lengthDark, widthDark, typeDark, countryDark, lengthLight, widthLight, typeLight, countryLight, lengthUnknown, headingUnknown, typeUnknown, movingUnknown, light, dark, unknown, spoofing, noImoAis, noImoLight, noImoDark, bunkeringLight, bunkeringAis, bunkeringOrange, bunkeringUnknown, lengthSpoofing, widthSpoofing, typeSpoofing, countrySpoofing, noImoSpoofing, sanctionedLight, sanctionedDark, sanctionedAis } = filters;
    const [shipIds, setShipIds] = useState([])
    const [shipData, setShipData] = useState([])

    const getLiveAisData = async (onlyLargeShips) => {

        const token = localStorage.getItem('token')
        setShowSpinner(true)
        const { _northEast, _southWest } = mapRef.current.leafletElement.getBounds()
        const { lat: lat1, lng: lng1 } = _northEast;
        const { lat: lat2, lng: lng2 } = _southWest;
        // const fullMap = [[-180,180],[-90,90]]
        const res = await axios.post(`${frontendAPIURL}/live_area`, {
            "id": "frontend", "area": { "type": "Polygon", "coordinates": [[[lng2, lat1], [lng1, lat1], [lng1, lat2], [lng2, lat2], [lng2, lat1]]] }
        }
            , {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            }).catch(() => {
                setShowSpinner(false)
            })

        let allInitialShips = res?.data

        allInitialShips = allInitialShips?.map(ship => ({
            ...ship,
            sanctionedShip: sanctionedShipsPositionData?.some(
                sanctionedShip => sanctionedShip?.synmax_ship_id === ship?.synmax_ship_id
            ),
        }));

        if (onlyLargeShips) {
            const updatedAisShips = { ships: allInitialShips?.filter(ship => ship.length > 200), initialShips: allInitialShips.filter(ship => ship.length > 200), key: parseInt(Math.random() * 10000) }
            filterThroughDataManager(lengthAis, widthAis, typeAis, countryAis, updatedAisShips, setAisShips, noImoAis, sanctionedAis)
        } else {
            const updatedAisShips = { ships: allInitialShips, initialShips: allInitialShips, key: parseInt(Math.random() * 10000) }
            filterThroughDataManager(lengthAis, widthAis, typeAis, countryAis, updatedAisShips, setAisShips, noImoAis, sanctionedAis)
        }
        setShowSpinner(false)
        setInitialDate(moment.utc(Date.now()).format('YYYY-MM-DD HH:mm:ss'))
    }

    const getDetectedData = async () => {
        const token = localStorage.getItem('token')
        const { _northEast, _southWest } = mapRef.current.leafletElement.getBounds()
        const { lat: lat1, lng: lng1 } = _northEast;
        const { lat: lat2, lng: lng2 } = _southWest;
        const dateString = moment(date.$d).format('YYYY-MM-DD')
        setShowSpinner(true)
        const res = await axios.post(`${frontendAPIURL}/objects_in_area`, {
            "id": "frontend", "start": `${dateString} 00:00:00`, "end": `${dateString} 23:59:59`, "area": { "type": "Polygon", "coordinates": [[[lng2, lat1], [lng1, lat1], [lng1, lat2], [lng2, lat2], [lng2, lat1]]] }
        }
            , {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            }).catch(() => {
                setShowSpinner(false)
            })

        let allInitialShips = res?.data

        allInitialShips = allInitialShips?.map(ship => ({
            ...ship,
            sanctionedShip: sanctionedShipsPositionData?.some(
                sanctionedShip => sanctionedShip?.synmax_ship_id === ship?.attribution
            ),
        }));

        const allShipIds = allInitialShips.map(ship => ship.attribution).filter(attribution => attribution !== 'None')
        console.log(shipIds, ':shipIds')
        const filterShipIds = shipIds.length === 0 ? allShipIds : allShipIds.filter(id => !shipIds.includes(id))
        const { allUniqueShips, uniqueShipIds } = await ShipInfoResponse(filterShipIds, setShowSpinner, { shipIds: shipIds, shipData })
        setShipIds(uniqueShipIds)
        setShipData(allUniqueShips)
        const detectionsWithInfo = []
        const detectionsWithoutInfo = []
        allInitialShips.forEach(ship => {
            if (uniqueShipIds.includes(ship.attribution)) {
                // has ship info
                const shipInfo = allUniqueShips.filter(uniqueShip => uniqueShip.synmax_ship_id === ship.attribution)[0]
                detectionsWithInfo.push({ ...ship, staticLength: shipInfo.length, staticWidth: shipInfo.width, staticType: shipInfo.ship_type, staticFlagCode: shipInfo.flag, name: shipInfo?.name, destination: shipInfo?.destination, imo: shipInfo.imo })
            } else {
                // doesn't have ship_info
                detectionsWithoutInfo.push(ship)
            }
        })
        const updatedOrangeShips = { initialShips: detectionsWithInfo.filter(detection => (detection.dark && detection.attribution !== 'None')), noShipInfo: detectionsWithoutInfo.filter(detection => (detection.dark && detection.attribution !== 'None')) }

        filterPinkOrangeAndBlueShips(lengthDark, widthDark, typeDark, countryDark, updatedOrangeShips, setOrangeShipDetections, deselectedShipsObjectIds, noImoDark, bunkeringOrange, sanctionedDark, sentinel1Dark,sentinel2Dark,planetScopeDark)

        const updatedBlueShips = { initialShips: detectionsWithInfo.filter(detection => (!detection.dark && detection.attribution !== 'None')), noShipInfo: detectionsWithoutInfo.filter(detection => (!detection.dark && detection.attribution !== 'None')) }

        filterPinkOrangeAndBlueShips(lengthLight, widthLight, typeLight, countryLight, updatedBlueShips, setBlueShipDetections, deselectedShipsObjectIds, noImoLight, bunkeringLight, sanctionedLight, sentinel1Light, sentinel2Light, planetScopeLight)

        const updatedRedShips = { initialShips: allInitialShips.filter(detection => (!detection.dark && detection.attribution === 'None')), ships: res.data.filter(detection => (!detection.dark && detection.attribution === 'None')), key: parseInt(Math.random() * 10000) }
        filterRedShips(lengthUnknown, headingUnknown, typeUnknown, movingUnknown, updatedRedShips, setDarkShipDetections, deselectedShipsObjectIds, bunkeringUnknown, sentinel1Unknown, sentinel2Unknown, planetScopeUnknown)
        setShowSpinner(false)
    }

    useEffect(() => {
        if (moment(date.$d).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {

            if (zoomLevel >= 8 && selectedPolygons.length <= 0 && ais) {
                if (zoomLevel >= 8 && zoomLevel < 10) {
                    // only Large Ships
                    getLiveAisData(true)
                }

                if (zoomLevel >= 10 && selectedPolygons.length <= 0) {
                    // All Ships
                    getLiveAisData(false)
                }
            }
            setFilterAisData(true)

            if (moment(date.$d).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') || selectedPolygons.length > 0) {
                console.log(selectedPolygons, ':selectedPolygons')
                setFilterAisData(false)
            }

            if (zoomLevel < 8) {
                if (light || unknown || dark || spoofing) {
                    getDetectedData()
                }
            }
        } else {
            if (light || unknown || dark || spoofing) {
                getDetectedData()
            }
        }
    }, [zoomLevel, dragEndValueForRerender, refreshViewClicked])

    useEffect(() => {
        if (moment(date.$d).format('YYYY-MM-DD') !== moment(new Date()).format('YYYY-MM-DD')) {
            setAisShips({
                ships: [],
                initialShips: [],
                key: parseInt(Math.random() * 10000)
            })
        }
        getDetectedData()
        // getSpoofingData()
    }, [date])

    useEffect(() => {
        getDetectedData()
    }, [isAttributionDataUpdated])

    useEffect(() => {
        if (isFiltersChanged || deselectedShipsObjectIds.length > 0) {
            filterRedShips(lengthUnknown, headingUnknown, typeUnknown, movingUnknown, darkShipDetections, setDarkShipDetections, deselectedShipsObjectIds, bunkeringUnknown,sentinel1Unknown, sentinel2Unknown, planetScopeUnknown)
        }
    }, [lengthUnknown, headingUnknown, typeUnknown, movingUnknown, filtersDetectionsKey, deselectedShipsObjectIds, bunkeringUnknown,sentinel1Unknown, sentinel2Unknown, planetScopeUnknown])

    useEffect(() => {
        if (isFiltersChanged) {
            console.log(filterAisData, ':filterAisData')
            if (filterAisData) {
                // Filters the live_ais data
                console.log('calling ais filtyert live')
                filterThroughDataManager(lengthAis, widthAis, typeAis, countryAis, aisShips, setAisShips, noImoAis, sanctionedAis)
            } else {
                // Filters data inside the polygon
                filterAisShipsWhenUsingPolygon(lengthAis, widthAis, typeAis, countryAis, aisShips, setAisShips, startEpochDate, polygonSliderValue, selectedShips, setSelectedShips, setPolygonShip, noImoAis, sanctionedAis)
                filterPurpleShipsWhenUsingPolygon(typeAis, countryAis, purpleShips, setPurpleShips, startEpochDate, polygonSliderValue, selectedShips)
            }
        }
    }, [lengthAis, widthAis, typeAis, countryAis, polygonSliderValue, polygonSliderAisShipsKey, noImoAis, sanctionedAis])

    useEffect(() => {
        if (isFiltersChanged || deselectedShipsObjectIds.length > 0) {
            filterPinkOrangeAndBlueShips(lengthDark, widthDark, typeDark, countryDark, orangeShipDetections, setOrangeShipDetections, deselectedShipsObjectIds, noImoDark, bunkeringOrange, sanctionedDark,sentinel1Dark,sentinel2Dark,planetScopeDark)
        }
    }, [lengthDark, widthDark, typeDark, countryDark, deselectedShipsObjectIds, noImoDark, bunkeringOrange, sanctionedDark,sentinel1Dark,sentinel2Dark,planetScopeDark])

    useEffect(() => {
        if (isFiltersChanged || deselectedShipsObjectIds.length > 0) {
            filterPinkOrangeAndBlueShips(lengthLight, widthLight, typeLight, countryLight, blueShipDetections, setBlueShipDetections, deselectedShipsObjectIds, noImoLight, bunkeringLight, sanctionedLight,  sentinel1Light, sentinel2Light, planetScopeLight)
        }
    }, [lengthLight, widthLight, typeLight, countryLight, deselectedShipsObjectIds, noImoLight, bunkeringLight, sanctionedLight, sentinel1Light,sentinel2Light, planetScopeLight])

    useEffect(() => {
        console.log(lengthSpoofing, ':length changed')
        if (isFiltersChanged || deselectedShipsObjectIds.length > 0) {
            filterPinkOrangeAndBlueShips(lengthSpoofing, widthSpoofing, typeSpoofing, countrySpoofing, spoofingDetections, setSpoofingDetections, deselectedShipsObjectIds, noImoSpoofing, false)
            filterPinkOrangeAndBlueShips(lengthSpoofing, widthSpoofing, typeSpoofing, countrySpoofing, footprintShips, setFootprintShips, deselectedShipsObjectIds, noImoSpoofing, false)
            filterPinkOrangeAndBlueShips(lengthSpoofing, widthSpoofing, typeSpoofing, countrySpoofing, AisSpoofingShips, setAisSpoofingShips, deselectedShipsObjectIds, noImoSpoofing, false)
        
        }
    }, [lengthSpoofing, widthSpoofing, typeSpoofing, countrySpoofing, deselectedShipsObjectIds, noImoSpoofing])


    // useEffect(() => {
    //     console.log(lengthSpoofing, ':length changed')
    //     if (isFiltersChanged || deselectedShipsObjectIds.length > 0) {
    //         filterPinkOrangeAndBlueShips(lengthSpoofing, widthSpoofing, typeSpoofing, countrySpoofing, footprintShips, setFootprintShips, deselectedShipsObjectIds, noImoSpoofing, false)
    //     }
    // }, [lengthSpoofing, widthSpoofing, typeSpoofing, countrySpoofing, deselectedShipsObjectIds, noImoSpoofing])

    return (
        <>
            {filters.ais && <AisShips aisShips={aisShips} />}
            {filters.unknown && <DarkDetections darkShipDetections={darkShipDetections} />}
            {filters.light && <BlueDetections blueShipDetections={blueShipDetections} />}
            {filters.dark && <OrangeDetections orangeShipDetections={orangeShipDetections} />}
            {filters.spoofing && filters.opticalSpoofing && <SpoofingDetections spoofingDetections={spoofingDetections} />}
            {purpleShipVisible && <PurpleDetections purpleShips={purpleShips} />}
            {filters.similarShips && similarDetections?.length > 0 && <SimilarDetections similarDetections={similarDetections} />}
            {filters.spoofing && filters.footprintSpoofing && <FootPrintSpoofing />}
            {filters.spoofing && filters.aisSpoofing && <AisSpoofing />}
            <DrawnPolygonShips date={date} setAisShips={setAisShips} aisShips={aisShips} setShowSpinner={setShowSpinner} />
            <Bunkering />
            <SanctionedShips />
        </>
    )
}

export default AllRenderedShips