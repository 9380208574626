import React, { useEffect, useContext, useState } from 'react'
import { Polygon, CircleMarker } from 'react-leaflet';
import Wkt from 'wicket';

import { TheiaContext } from '../../Theia'


const FuturePathPrediction = () => {
  const { futurePathObj } = useContext(TheiaContext);
  const [pathCluster, setPathCluster] = useState(null)
  const purpleOptions = { color: 'purple' }

  useEffect(() => {
    if (futurePathObj.clusters.length > 0) {
      setPathCluster(futurePathObj.clusters)
      console.log(futurePathObj.clusters, 'futurePathObj.clusters')
    }
  }, [futurePathObj])

  return (
    <>

    {pathCluster && pathCluster.map((cluster,i) => {
      const { lat, lon, probability, wkt } = cluster
      var wktReader = new Wkt.Wkt();
      wktReader.read(wkt)
      const wktData = wktReader.components;

      let polyonData = []
      console.log(wktData, 'wktData')
      if(Array.isArray(wktData[0][0])) {
        // Multi polygon
        wktData.forEach(item => {
          const allValues = item[0]
          const newValues = []
          allValues.forEach(val => newValues.push([val.y,val.x]))
          polyonData.push(newValues)
        })

      } else {
        // Single Polygon
        polyonData = [wktData[0].map(val => [val.y,val.x])]

      }
      // This separates out the polygons from the wkt string
     // const allPolygonsInWicket = wktString.substring(2, wktString.length - 2).split('), (')
     
      return (
        <>
        {/* {allPolygonsInWicket.length > 0 && allPolygonsInWicket.map((polygonWkt, i) => {
          const pointArr = polygonWkt.split(',')        
          const pointGeometry = []
            pointArr.forEach(point => {
                const trimmedPoint = point.trim()
                const points = trimmedPoint.split(" ")
                pointGeometry.push([parseFloat(points[1]), parseFloat(points[0])])
              })
            
              return <Polygon pathOptions={purpleOptions} positions={pointGeometry}  fillOpacity={parseFloat(probability)} key={i}/>

        })} */}

        <Polygon pathOptions={purpleOptions} positions={polyonData} fillOpacity={parseFloat(probability)} key={i}/>
          
            <CircleMarker
              center={{ lat, lng: lon }}
              radius={10}
              color={'red'}
              opacity={1}
            >
          </CircleMarker>
        </>
      )
    })}     
    </>
  )
}




export default FuturePathPrediction