import React, { useEffect, useState } from "react";
import { Slider } from "@mui/material";
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import "../theia-p3.scss";
import { useContext } from "react";
import { TheiaContext } from "../Theia";
import Draggable from "react-draggable";
const SatelliteCoverageSlider = ({ spoofingTimelineShips, setSatelliteTimelineData, setSpoofingTimelineShips }) => {
    const { setZindex, zIndex, satelliteTimelineData, spoofingHeatmapTimelineVisible, selectedShips, setSpoofingHeatmapTimelineVisible } = useContext(TheiaContext);
    const [
        satelliteSpoofingGeojsonPosition,
        setSatelliteSpoofingGeojsonPosition,
    ] = useState(0);

    const [spoofingModelTimeline, setSpoofingTimelineModel] = useState({
        clientX: null,
        clientY: null
      })

    const getLabel = (value) => {
        return moment.utc(spoofingTimelineShips[satelliteSpoofingGeojsonPosition]?.day).format('DD MMM YYYY HH:mm:ss') + ' UTC'
    };

    const { startDate, endDate } = spoofingTimelineShips;

    let shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.synmax_ship_id
    if (!shipId) {
      shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.attribution
    }
    
    useEffect(() => {
        setSatelliteTimelineData({geojson: spoofingTimelineShips[satelliteSpoofingGeojsonPosition].geojson, key: parseInt(Math.random() * 10000)})
    },[satelliteSpoofingGeojsonPosition, spoofingTimelineShips[0].event_id, startDate, endDate])

    const spoofingPathSlider = (_, data) => {
        setSpoofingTimelineModel({
          clientX: data.x,
          clientY: data.y
        })
    }
    
    return (
        spoofingHeatmapTimelineVisible && <Draggable
            axis="both"
            handle=".satelliteCoverageSlider"
            defaultPosition={{ x: 0, y: 0 }}
            cancel=".cancelDrag"
            position={{ x: spoofingModelTimeline.clientX, y: spoofingModelTimeline.clientY }}
            onDrag={spoofingPathSlider}
            bounds="body"
            onStart={() => setZindex({
            dropPin: false,
            pathSlider: false,
            attribution: false,
            satelliteImage: false,
            extendedPath: false,
            polygonSlider: false,
            polygonDataTable:false,
            futurePathModal: false,
            futurePathSlider: false,
            spoofingTimelineSlider : true,
            portsOfCallSlider: false,
            })}
        >
            <div className={`${zIndex.spoofingTimelineSlider ? 'zindex-true' : 'zindex-false'} satelliteCoverageSlider`}>
                    <Slider
                        size="medium"
                        max={spoofingTimelineShips.length - 1}
                        value={satelliteSpoofingGeojsonPosition}
                        valueLabelFormat={getLabel}
                        min={0}
                        aria-label="Blue"
                        valueLabelDisplay="on"
                        onChange={(e, val) => setSatelliteSpoofingGeojsonPosition(val)}
                        style={{
                            color: "#24A0CA",
                        }}
                        className="cancelDrag"
                    />
                    <div className="closeIcon">
                        <CloseIcon className="drawerData__drawer-close-btn" onClick={() =>  {
                            setSpoofingTimelineShips([])
                            setSatelliteTimelineData({geojson:null, key: parseInt(Math.random() * 10000)})
                            setSpoofingHeatmapTimelineVisible(false)
                        }} />
                </div>
                

            </div>
        </Draggable>
    );
};

export default SatelliteCoverageSlider;
