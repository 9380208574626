import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { GeoJSON, TileLayer } from 'react-leaflet';
import L, { tileLayer } from 'leaflet';
import * as turf from '@turf/turf';

import { TheiaContext } from '../../Theia';
import { frontendAPIURL, getShipDetails, getUniqueColor } from '../../utils';
import MarkerClusterGroup from 'react-leaflet-markercluster';
// import { bunkeringData } from './BunkeringDummy';

const Bunkering = () => {
    const { setShowSpinner, filters, date, setShowPathPredictionDialog, bunkeringData, aisBunkeringData, setAisBunkeringData, setBunkeringData, setHaveShipInfo, selectedShips, deselectedShipsObjectIds, setSelectedShips, setSpoofingTimelineShips, setSatelliteData, setCurrentLocationData, setTileLayerUrl, selectedPolygons, setPathInterpolationData, setViewPathInterpolation, setViewSatelliteImage, setIsSnackbarOpen, colorArr, setColorArr, setSelectShipWaiting, setSatelliteTimelineData } = useContext(TheiaContext);
    const [terracottaUrl, setTerracottaUrl] = useState(null)

    useEffect(() => {
        getBunkeringData()
    }, [date])

    const getBunkeringData = async () => {
        // setShowSpinner(true)
        const token = localStorage.getItem('token')
       
        const dateString = moment(date.$d).format('YYYY-MM-DD')
        const res = await axios.post(`${frontendAPIURL}/get_events`, {
            "types": ["optical_sts", "ais_sts"], "start": `${dateString} 00:00:00`, "end": `${dateString} 23:59:59`
        }
            , {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            }).catch(() => {
                setShowSpinner(false)
              })
              console.log(res, ";bunkering res")
            //   setShowSpinner(false)
              setBunkeringData({events: res.data.optical_sts, key: parseInt(Math.random() * 10000)})
              setAisBunkeringData({ events: res.data.ais_sts, key: parseInt(Math.random() * 10000)})   
    }

    const pointToLayer = (feature, latlng) => {

        const { ship1_attribution, ship1_dark, ship2_attribution, ship2_dark, ship1_lat, ship1_lon, ship2_lat, ship2_lon, ship1Status, ship2Status, frontend_rotation } = feature.properties;

        // lon first
        // var point1 = turf.point([ship1_lon, ship1_lat]);
        // var point2 = turf.point([ship2_lon, ship2_lat]);

        // var bearing = Math.abs(turf.bearing(point1,point2));
        // console.log(bearing, ':bearing')
        
        switch (ship1Status + ship2Status) {
            case 'redblue':
                return L.canvasMarker(latlng, {
                    radius: 20,
                    img: {
                        url: './redBlueBunkering.svg',
                        size: [50, 30],
                        opacity: 0.5,
                        rotate: frontend_rotation
                    }
                })

            case 'bluered':
                return L.canvasMarker(latlng, {
                    radius: 20,
                    img: {
                        url: './blueRedBunkering.svg',
                        size: [50, 30],
                        opacity: 0.5,
                        rotate: frontend_rotation
                    }
                })
            case 'redred':
                return L.canvasMarker(latlng, {
                    radius: 20,
                    img: {
                        url: './redRedBunkering.svg',
                        size: [50, 30],
                        opacity: 0.5,
                        rotate: frontend_rotation
                    }
                })
            case 'orangered':
                return L.canvasMarker(latlng, {
                    radius: 20,
                    img: {
                        url: './orangeRedBunkering.svg',
                        size: [50, 30],
                        opacity: 0.5,
                        rotate: frontend_rotation
                    }
                })
            case 'redorange':
                return L.canvasMarker(latlng, {
                    radius: 20,
                    img: {
                        url: './redOrangeBunkering.svg',
                        size: [50, 30],
                        opacity: 0.5,
                        rotate: frontend_rotation
                    }
                })
            case 'orangeblue':
                return L.canvasMarker(latlng, {
                    radius: 20,
                    img: {
                        url: './orangeBlueBunkering.svg',
                        size: [50, 30],
                        opacity: 0.5,
                        rotate: frontend_rotation
                    }
                })
            case 'blueorange':
                return L.canvasMarker(latlng, {
                    radius: 20,
                    img: {
                        url: './blueOrangeBunkering.svg',
                        size: [50, 30],
                        opacity: 0.5,
                        rotate: frontend_rotation
                    }
                })
            case 'orangeorange':
                return L.canvasMarker(latlng, {
                    radius: 20,
                    img: {
                        url: './orangeOrangeBunkering.svg',
                        size: [50, 30],
                        opacity: 0.5,
                        rotate: frontend_rotation
                    }
                })
            case 'blueblue':
                return L.canvasMarker(latlng, {
                    radius: 20,
                    img: {
                        url: './blueBlueBunkering.svg',
                        size: [50, 30],
                        opacity: 0.5,
                        rotate: frontend_rotation
                    }
                })

            default:
                return L.marker(latlng)

        }
    }

    const pointAisToLayer = (feature, latlng) => {
        const { frontend_rotation } = feature.properties;

        return L.canvasMarker(latlng, {
            radius: 20,
            img: {
                url: './aisBunkering.svg',
                size: [50, 30],
                opacity: 0.5,
                rotate: frontend_rotation
            }
        })
    }

    const onEachFeaturePoint = (feature, layer, setTerracottaUrlFromSourceID) => {
        layer.on({
            click: async function (e) {
                //         setTerracottaUrlFromSourceID(feature.properties.source_id)

                //         const {ship1_attribution,ship1_dark,ship2_attribution,ship2_dark,ship1_lat,ship1_lon,ship2_lat,ship2_lon } = feature.properties;

                //         var point1 = turf.point([ship1_lon,ship1_lat]);
                // var point2 = turf.point([ship2_lon, ship2_lat]);

                // var bearing = turf.bearing(point1, point2);
                // console.log(bearing, ':bearing')
                setShowPathPredictionDialog(false)
                setSelectShipWaiting(true)
                setTileLayerUrl(null)
                setViewSatelliteImage(false)
                setPathInterpolationData(null)
                setViewPathInterpolation(false)
                setSpoofingTimelineShips([])
                setSatelliteData({ geojson: null, key: parseInt(Math.random() * 10000) })
                setSatelliteTimelineData({ geojson: null, key: parseInt(Math.random() * 10000) })
                setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })

                const { ship1Status, ship2Status, ship1_clip_pxl_x, ship1_clip_pxl_y, ship2_clip_pxl_x, ship2_clip_pxl_y, acquired, source_id, ship2_attribution, ship1_bunkering, ship2_heading, ship2_lat, ship2_lon, ship2_length, ship2_moving, ship1_attribution, ship2_dark, ship2_dark_time, ship2_bunkering, ship2_ship_type, ship2_status, ship2_object_id, ship1_dark, ship1_dark_time, ship1_status, ship1_heading, ship1_lat, ship1_lon, ship1_length, ship1_moving, ship1_object_id, ship1_ship_type, frontend_rotation } = feature.properties;
                const ship1Properties = {
                    acquired,
                    attribution: ship1_attribution,
                    heading: ship1_heading,
                    latitude: ship1_lat,
                    longitude: ship1_lon,
                    length: ship1_length,
                    moving: ship1_moving,
                    object_id: ship1_object_id,
                    ship_type: ship1_ship_type,
                    source_id,
                    status: ship1_status,
                    bunkering: ship1_bunkering,
                    dark: ship1_dark,
                    dark_time: ship1_dark_time,
                    ship1Status,
                    ship2Status,
                    frontend_rotation,
                    fromDecoupleModel: true,
                    xPixel: ship1_clip_pxl_x,
                    yPixel: ship1_clip_pxl_y
                }
                const ship2Properties = {
                    acquired,
                    attribution: ship2_attribution,
                    heading: ship2_heading,
                    latitude: ship2_lat,
                    longitude: ship2_lon,
                    length: ship2_length,
                    moving: ship2_moving,
                    object_id: ship2_object_id,
                    ship_type: ship2_ship_type,
                    source_id,
                    status: ship2_status,
                    bunkering: ship2_bunkering,
                    dark: ship2_dark,
                    dark_time: ship2_dark_time,
                    ship1Status,
                    ship2Status,
                    frontend_rotation,
                    fromDecoupleModel: true,
                    xPixel: ship2_clip_pxl_x,
                    yPixel: ship2_clip_pxl_y
                }
                
                const shipFeature = {
                    geometry: {
                        type: 'Point',
                        coordinates: feature.geometry.coordinates
                    },
                    properties: ship1Properties,
                    type: "Feature",
                    id: parseInt(Math.random() * 10000),
                    ship2Properties,
                    ship1Properties,
                    isShipOne: true
                }
                if (ship1Status === 'blue' || ship1Status === 'orange') {
                    setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
                    getShipDetails(shipFeature, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, ship1Status)
                } else {
                    let shipIndex = 1;
                    if (selectedShips.length > 0) {
                        let highestValue = 0
                        selectedShips.forEach(ship => {
                            if (ship.shipIndex > highestValue) {
                                highestValue = ship.shipIndex
                            }
                        })
                        shipIndex = highestValue + 1
                    }
                    console.log('redbunkering:', { shipCategory: 'red', initialData: { ...shipFeature, isClicked: true }, shipIndex, visible: true, pathVisible: true, shipData: { ...shipFeature.properties, name: 'Dark Ship' }, showCircle: true, path: [], color: getUniqueColor(colorArr, setColorArr) })
                    setSelectedShips([...selectedShips, { shipCategory: 'red', initialData: { ...shipFeature, isClicked: true }, shipIndex, visible: true, pathVisible: true, shipData: { ...shipFeature.properties, name: 'Dark Ship' }, showCircle: true, path: [], color: getUniqueColor(colorArr, setColorArr) }])
                    setSelectShipWaiting(false)
                }
            }
        })
    }

    const onAisEachFeaturePoint = (feature, layer, setTerracottaUrlFromSourceID) => {
        layer.on({
            click: async function (e) {

                setSelectShipWaiting(true)
                setTileLayerUrl(null)
                setViewSatelliteImage(false)
                setPathInterpolationData(null)
                setViewPathInterpolation(false)
                setSpoofingTimelineShips([])
                setSatelliteData({ geojson: null, key: parseInt(Math.random() * 10000) })
                setSatelliteTimelineData({ geojson: null, key: parseInt(Math.random() * 10000) })
                setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })

                // const { ship1Status, ship2Status, ship1_clip_pxl_x,ship1_clip_pxl_y,ship2_clip_pxl_x,ship2_clip_pxl_y,acquired, source_id, ship2_attribution,ship1_bunkering,ship2_heading, ship2_lat,ship2_lon,ship2_length,ship2_moving,ship1_attribution, ship2_dark,ship2_dark_time,ship2_bunkering,ship2_ship_type, ship2_status,ship2_object_id,ship1_dark, ship1_dark_time, ship1_status, ship1_heading, ship1_lat, ship1_lon, ship1_length, ship1_moving, ship1_object_id, ship1_ship_type } = feature.properties;

                const { ship1Status, duration, latitude, longitude, mmsi_1, mmsi_2, status, synmax_ship_id_1, synmax_ship_id_2, timestamp_t0, timestamp_t1, frontend_rotation } = feature.properties;

                const ship1Properties = {
                    acquired: timestamp_t0 * 1000,
                    synmax_ship_id: synmax_ship_id_1,
                    latitude: latitude,
                    longitude: longitude,
                    ship1Status: "ais",
                    ship2Status: "ais",
                    status: status,
                    frontend_rotation,
                    bunkering: "ais_bunkering",
                    fromDecoupleModel: true
                }

                const ship2Properties = {
                    acquired: (timestamp_t1 ? timestamp_t1 : timestamp_t0) * 1000,
                    synmax_ship_id: synmax_ship_id_2,
                    latitude: latitude,
                    longitude: longitude,
                    ship1Status: "ais",
                    ship2Status: "ais",
                    status: status,
                    frontend_rotation,
                    bunkering: "ais_bunkering",
                    fromDecoupleModel: true
                }

                const shipFeature = {
                    geometry: {
                        type: 'Point',
                        coordinates: feature.geometry.coordinates
                    },
                    properties: ship1Properties,
                    type: "Feature",
                    id: parseInt(Math.random() * 10000),
                    ship2Properties,
                    ship1Properties,
                    isShipOne: true
                }
                setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
                getShipDetails(shipFeature, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, 'ais')
            }
        })
    }

    const setTerracottaUrlFromSourceID = async (coverageSourceId) => {
        const token = localStorage.getItem('token')
        const res = await axios.post(`${frontendAPIURL}/image_url`, { "source_id": coverageSourceId },
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
        setTerracottaUrl(res.data.url)
    }

    let shipIndex = 0

    if (selectedShips.length > 0) {
        shipIndex = selectedShips[selectedShips.length - 1].shipIndex
    }

    return (
        <>
            { filters.decoupleBunkering && bunkeringData.events.length > 0 && (
                <MarkerClusterGroup
                    disableClusteringAtZoom={7}
                    maxClusterRadius={40}
                    iconCreateFunction={function (cluster) {
                        return L.divIcon({
                        html: cluster.getChildCount(),
                        className: 'blackCluster',
                        // iconSize: L.point(40, 40),
                        });
                    }}
                >
                    <GeoJSON
                        key={'blueships' + bunkeringData.key + selectedShips.length + selectedPolygons.length + deselectedShipsObjectIds.length + shipIndex}
                        data={convertArrToGeojson(bunkeringData.events)}
                        pointToLayer={(feature, latlng) => pointToLayer(feature, latlng)}
                        onEachFeature={(a, b) => onEachFeaturePoint(a, b, setTerracottaUrlFromSourceID)}
                    />
            
                </MarkerClusterGroup>
            )}

            {filters.bunkeringAis && filters.ais && aisBunkeringData.events.length > 0 &&  (
                <MarkerClusterGroup
                disableClusteringAtZoom={7}
                maxClusterRadius={40}
                iconCreateFunction={function (cluster) {
                    return L.divIcon({
                    html: cluster.getChildCount(),
                    className: 'blackCluster',
                    // iconSize: L.point(40, 40),
                    });
                }}
            >
                <GeoJSON
                    key={'aisships' + aisBunkeringData.key + selectedShips.length + selectedPolygons.length + deselectedShipsObjectIds.length + shipIndex}
                    data={convertAisArrToGeojson(aisBunkeringData.events)}
                    pointToLayer={(feature, latlng) => pointAisToLayer(feature, latlng)}
                    onEachFeature={(a, b) => onAisEachFeaturePoint(a, b, setTerracottaUrlFromSourceID)}
                />
            </MarkerClusterGroup>
            )}

        </>

    )
}

export const convertArrToGeojson = (arr) => {

    const finalGeojson = {
        type: 'FeatureCollection',
        features: arr.map((ship, i) => {
            const { ship1_attribution, ship1_dark, ship2_attribution, ship2_dark } = ship;
            let ship1Status = ''
            let ship2Status = ''
            if (ship1_attribution === 'None') {
                ship1Status = 'red'
            } else {
                if (ship1_dark) {
                    ship1Status = 'orange'
                } else {
                    ship1Status = 'blue'
                }
            }

            if (ship2_attribution === 'None') {
                ship2Status = 'red'
            } else {
                if (ship2_dark) {
                    ship2Status = 'orange'
                } else {
                    ship2Status = 'blue'
                }
            }
            return ({
                type: 'Feature',
                id: i,
                geometry: {
                    type: 'Point',
                    coordinates: [(ship.ship2_lon + ship.ship1_lon) / 2, (ship.ship2_lat + ship.ship1_lat) / 2],
                },
                properties: {
                    ...ship,
                    ship1Status,
                    ship2Status
                },
            })
        })
    }

    return finalGeojson;
}

export const convertAisArrToGeojson = (arr) => {

    const aisFinalGeojson = {
        type: "FeatureCollection",
        features: arr.map((ship, i) => {
            return ({
                type: 'Feature',
                id: i,
                geometry: {
                    type: 'Point',
                    coordinates: [ ship.longitude, ship.latitude],
                },
                properties: {
                    ...ship
                },
            })
        })
    }

    return aisFinalGeojson;
}

export default Bunkering