import React, { useState, useContext, useEffect } from 'react';
import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';
import { TheiaContext } from '../../Theia';
import { Button } from '@mui/material';

const CurrentLocationLoader = () => {
    const { progressLoading, setProgressLoading, isAbortController, setIsAbortController, setCurrentLocation } = useContext(TheiaContext);
    const [isIconClicked, setIsIconClicked] = useState(false);
    const [percentDone, setPercentDone] = useState(0);
    const increment = 2.5;

    useEffect(() => {
        let interval;
        let progress = 0;

        if (progressLoading) {
            interval = setInterval(() => {
                progress += increment;
                if (progress >= 98) {
                    progress = 98; 
                }
                setPercentDone(progress);
            }, 850); 
        } 
    
        return () => {
            clearInterval(interval);
        };
    }, [progressLoading]);

    const cancelProcess = () => {
        if (isAbortController) {
            isAbortController.abort(); // Cancel ongoing request
        }

        // Create a new AbortController for the next request
        const newAbortController = new AbortController();
        setIsAbortController(newAbortController);

        setProgressLoading(false);
        setIsIconClicked(false);
        setCurrentLocation(false);
    }

    return (
        !isAbortController.signal.aborted && <div className='pathPredictionLoaderUI'>
            <div className="">
                {isIconClicked ? (
                    <div className="pathPredictionLoaderUI__percent">
                        <div className='pathPredictionLoaderUI__strong'>{Math.round(percentDone)}</div>% Done
                        <div className="pathPredictionLoaderUI__close">
                            <CloseIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => setIsIconClicked(false)}
                            />
                        </div>
                        <Button variant='contained' onClick={cancelProcess} >Cancel the process</Button>
                    </div>
                ) : (
                    <UpdateIcon
                        style={{ width: '4rem', height: '4rem' }}
                        fontSize="large"
                        color='primary'
                        onClick={() => setIsIconClicked(true)}
                    />
                )}
            </div>
        </div>
    );
};

export default CurrentLocationLoader;
