import React, { memo, useContext, useState } from 'react'
import { CircleMarker, GeoJSON, Polyline } from 'react-leaflet';

import { TheiaContext } from '../../Theia'
import ObservedShips from "./ObservedShips"
import { PathRender } from '../PathRender';
import { pointToLayer, toHoursAndMinutes } from '../../utils'


export const SelectedShips = memo(({ selectedAISShipsGeojson, refreshKey }) => {
  const {
    selectedShips,
    setSelectedShips,
    setTileLayerUrl,
    viewPathSlider,
    setPathInterpolationData,
    setViewPathInterpolation,
    setViewSatelliteImage,
    pathInterpolationData,
    viewPathInterpolation,
    viewAnalyzePath,
    setDeselectedShipsObjectIds,
    isAttributionDataUpdated,
    setCurrentLocationData
  } = useContext(TheiaContext);
    const [selectedSectionId, setSelectedSectionId] = useState(null)

  const deselectShip = (shipIndex) => {

    setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) });
    
    setSelectedShips(selectedShips => selectedShips.filter((ship, i) => ship.shipIndex !== shipIndex))
    const deselectedShip = selectedShips.filter((ship, i) => ship.shipIndex === shipIndex)[0]
    
    console.log(selectedShips, "selectedShips00001111")
    if (deselectedShip?.initialData?.properties.object_id) {
      setDeselectedShipsObjectIds(prevIds => [...prevIds, deselectedShip.initialData.properties.object_id])
    }
    console.log(selectedShips, "selectedShips00001111")

  }

  console.log(selectedShips, "selectedShips00001111")

  const clickShip = (shipIndex) => {
    console.log(selectedShips, shipIndex, "selectedShips00001111")
    setTileLayerUrl(null)
    setViewSatelliteImage(false)
    setPathInterpolationData(null)
    setViewPathInterpolation(false)
    setSelectedShips(selectedShips => ([...selectedShips.filter(ship => ship.shipIndex !== shipIndex), selectedShips.filter(ship => ship.shipIndex === shipIndex)[0]]))
  }

  const onEachFeaturePoint = (feature, layer) => {
    layer.on({
      click: async function (e) {
        clickShip(feature.properties.shipIndex)
      },
      contextmenu: () => {
        deselectShip(feature.properties.shipIndex)
      }
    });
  }
  const onEachFeature = (feature, layer) => {
    const { average_reported_speed, end_lat, end_lon, estimated_speed, is_valid, start_lat, start_lon, synmax_ship_id, time_between_pings, timestamp, travel_distance } = feature.properties;

    const { h, m, s } = toHoursAndMinutes(time_between_pings)
    layer.on({
      click: function (e) {
        layer.bindPopup(`
          <div>
            <div>estimated_speed: ${estimated_speed} knots</div>
            <div>is_valid: ${is_valid}</div>
            <div>time_between_pings: ${h + ':' + m + ':' + s} (HH:MM:SS)</div>
            <div>travel_distance: ${travel_distance} km</div>
          </div>
        `)
      }
    })
    setSelectedSectionId(feature.id)
  }

  const getStyle = (feature) => {
    const { is_valid, time_between_pings, travel_distance } = feature.properties;
    let opacity = '0';

    if ((is_valid === 'true') && (time_between_pings > 10800 || travel_distance > 10)) {
      opacity = '1'
    }

    return { color: 'red', weight: 5, opacity }
  }

  return (
    <>
      {!viewPathSlider && (<>

        {/* <GeoJSON
         key={'interpolatedpath' + viewPathInterpolation}
         data={pathInterpolationData}
         style={{ color: 'red', weight: 5}}
        onEachFeature={onEachFeature}
       /> */}

        {/* {pathInterpolationData && viewPathInterpolation && pathInterpolationData.features.map((feat, i) =>
       (<Polyline key={i} pathOptions={limeOptions} positions={reverseLatLng(feat.geometry.coordinates)} />)
      )} */}
        {pathInterpolationData && viewPathInterpolation && (
          <GeoJSON
            key={'interpolatedpath' + viewPathInterpolation + selectedSectionId}
            data={pathInterpolationData}
            style={getStyle}
            onEachFeature={onEachFeature}
          />
        )}
      </>
      )
      }

      <GeoJSON
        key={'aisships' + selectedAISShipsGeojson.features.length + isAttributionDataUpdated}
        data={selectedAISShipsGeojson}
        pointToLayer={(feature, latlng) => pointToLayer(feature, latlng, 'selected')}
        onEachFeature={onEachFeaturePoint}
      />
      {selectedShips.map(({ color, key,extendedPathSpoofing, initialData, path, visible, pathVisible, observations, shipIndex, showCircle , shipCategory}, i) => {
        console.log("initialData",initialData)
        return (
          <div key={`selected-ships-${i}`}>
            {initialData.properties.spoofingShips && initialData.properties.spoofingShips.length > 0 && initialData.properties.spoofingShips.map((ship,i) => {
              console.log("first")
            return <Polyline 
                      key={i + 'reddottedline'}
                      positions={[ [initialData.properties.latitude, initialData.properties.longitude],[ship.latitude,ship.longitude]]} 
                      color='red' dashArray={'20, 20'} dashOffset='20' weight={5}/>
            })}
            {visible && showCircle === undefined ? (
              <>
                {!viewPathSlider &&
                  <CircleMarker
                    key={i + visible + selectedAISShipsGeojson.features.length + parseInt(Math.random() * 10000)}
                    center={{ lat: initialData.geometry.coordinates[1], lng: initialData.geometry.coordinates[0] }}
                    radius={50}
                    color={color}
                    fillOpacity={0.3}
                    weight={i === selectedShips.length - 1 ? 5 : 1}
                    oncontextmenu={() => deselectShip(shipIndex)}
                    onclick={() => clickShip(shipIndex)}
                    shipIndex={shipIndex}
                  >
                  </CircleMarker>
                }
                {pathVisible && (<PathRender pathData={path} index={i} color={color} refreshKey={refreshKey} viewAnalyzePath={viewAnalyzePath} extendedPathSpoofing={extendedPathSpoofing} />)}
                {(observations?.length > 0) && (
                  <ObservedShips
                    observedShips={observations.length ? observations : []}
                    // isBlueShips={!isDemoShip}
                    selectedShips={selectedShips}
                    setSelectedShips={setSelectedShips}
                    shipKey={key}
                  // setShipImgArr={setShipImgArr}
                  />
                )}
              </>
            ) : visible && showCircle ?
              (
                <>
                  {!viewPathSlider &&
                    <CircleMarker
                      key={i + visible + selectedAISShipsGeojson.features.length + parseInt(Math.random() * 10000)}
                      center={{ lat: initialData.geometry.coordinates[1], lng: initialData.geometry.coordinates[0] }}
                      radius={50}
                      color={color}
                      fillOpacity={0.3}
                      weight={i === selectedShips.length - 1 ? 5 : 1}
                      oncontextmenu={() => deselectShip(shipIndex)}
                      onclick={() => clickShip(shipIndex)}
                      shipIndex={shipIndex}
                    >
                    </CircleMarker>
                  }
                  {pathVisible && (<PathRender pathData={path} index={i} color={color} refreshKey={refreshKey} viewAnalyzePath={viewAnalyzePath} extendedPathSpoofing={extendedPathSpoofing} />)}
                  {(observations?.length > 0) && (
                    <ObservedShips
                      observedShips={observations.length ? observations : []}
                      // isBlueShips={!isDemoShip}
                      selectedShips={selectedShips}
                      setSelectedShips={setSelectedShips}
                      shipKey={key}
                      shipCategory={shipCategory}
                    // setShipImgArr={setShipImgArr}
                    />
                  )}
                </>
              ) : (
                <>
              
                  {pathVisible && (<PathRender pathData={path} index={i} color={color} refreshKey={refreshKey} viewAnalyzePath={viewAnalyzePath} extendedPathSpoofing={extendedPathSpoofing} />)}
                  {(observations?.length > 0) && (
                    <ObservedShips
                      observedShips={observations.length ? observations : []}
                      // isBlueShips={!isDemoShip}
                      selectedShips={selectedShips}
                      setSelectedShips={setSelectedShips}
                      shipKey={key}
                      shipCategory={shipCategory}
                    // setShipImgArr={setShipImgArr}
                    />
                  )}
                </>
              )
            }
          </div>
        )
      })}
    </>
  )
})


const reverseLatLng = (latLngArr) => {
  const newArr = [[latLngArr[0][1], latLngArr[0][0]], [latLngArr[1][1], latLngArr[1][0]]]
  return newArr;
}