// import React from 'react'
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { collection, getDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../App'
import { getAuth } from "firebase/auth";
import { useState } from 'react';
import FisihingIcon from '../../../Icons/FisihingIcon';
import CargoIcon from '../../../Icons/CargoIcon';
import TankerIcon from '../../../Icons/TankerIcon';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'white',
  color: "#474747",
  border: '0px solid #fff',
  borderRadius:"10px",
  boxShadow: 24,
  p: 4,
  outline: "none"
};



export default function Welcome() {
    const [open, setOpen] = React.useState(false);
    const [userData, setUserData] = useState({})
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);


    const auth = getAuth();
    const user = auth.currentUser;
    const { uid, email } = user;
    useEffect(async()=>{
        const docRef = doc(db, 'users', uid);
        const docSnap = await getDoc(docRef);
        const userData = docSnap.data();
        console.log(userData, "<====userData");
        setUserData(userData)
        if(userData.isNewUser === true){
            setOpen(true);
        }else if(userData.isNewUser === false){
            setOpen(false);
        } else {
            setOpen(true)
        }
    },[user])

    const handleClose = async(event, reason) => {
        if (reason !== 'backdropClick') {
            let {email, role, firstName, lastName} = userData;
            if(firstName && lastName) {
                await setDoc(doc(db, 'users', uid), {
                    email,
                    role,
                    firstName,
                    lastName,
                    isNewUser: false,
                  });
              setOpen(false)
            }else {
                await setDoc(doc(db, 'users', uid), {
                    ...userData,
                    isNewUser: false,
                  });
              setOpen(false)
            }
        }
      }
  
    return (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{zIndex:"1px"}} >
                <CloseIcon
                style={{ cursor: 'pointer', float:"right", position:"absolute", right:"12px", top:"10px" }}
                onClick={handleClose}
                />
                <Typography style={{color: "#474747", textAlign:"center"}} id="modal-modal-title" fontSize="32px" variant="h6" component="h2">
                  Welcome to Theia
                </Typography>

            <div className='welcomeMessagePara'>
              <p>
              Your window to strategic and tactical maritime events. From illicit activities to ship-to-ship transfers, Theia tracks light, grey, and dark ships. Our team has integrated multi-source intelligence, datasets, live AIS data and predictions based historic dark ship data. These predictions are corroborated by satellite imagery for real-world verification. </p>
              
              <p>
              Our team has put together documentation to help you navigate the Theia portal. It can be found at <a rel='noreferrer' target='_blank' href='https://apidocs.theia.synmax.com/'>https://apidocs.theia.synmax.com</a>
              </p>
              <p>
              For help and support, please contact:
              <br/>
              Product Inquiries: dean@synmax.com
              <br/>
              IT/Login Inquiries: kristen@synmax.com
              </p>

              <p>
              We hope you enjoy using Theia as much as we enjoyed creating it.
              </p>

              <p>
              <strong>SynMax-Theia Team</strong>
              </p>
            </div>
            <div>

            </div>
             </div>
             <div>
                {/* <TankerIcon fill={"#474747"} /> */}
            </div>
          </Box>
        </Modal>
    );
}
