import React, { useState, useRef, useContext } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button, Menu, MenuItem, Dialog, Snackbar, SnackbarContent } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CSVReader from 'react-csv-reader'

import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect } from 'react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { TheiaContext } from '../Theia'
import { SearchBar } from './SearchBar';
import { frontendAPIURL, getShipDetails } from '../utils';

const Navbar = ({ logout, date, setDate }) => {
    const { initialDate, setRefreshViewClicked, showSpinner, uploadedGeojsons, setShowSpinner, userData, uploadedCSVs, setUploadedCSVs, setUploadedGejson, selectedShips, setMapCenter, setSelectedShips, colorArr, setColorArr, setSelectShipWaiting, setIsSnackbarOpen, setHaveShipInfo, setCurrentLocationData } = useContext(TheiaContext);
    const [isOpen, setIsOpen] = useState(false);
    const customInputRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [isPasswordChanging, setIsPasswordChanging] = useState(false)
    const [isPwChangeSnackBarOpen, setIsPwChangeSnackBarOpen] = useState(false)
    const open = Boolean(anchorEl);
    const openActions = Boolean(actionsAnchorEl);
    const inputFile = useRef(null)
    const csvUploadRef = useRef(null);
    const [isIncorrectCSVFormat, setIsIncorrectCSVFormat] = useState(false)

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseActions = () => {
        setActionsAnchorEl(null);
    };

    let shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.synmax_ship_id
    if (!shipId) {
        shipId = selectedShips[selectedShips?.length - 1]?.initialData?.properties?.attribution
    }

    const dd = selectedShips?.find((item) => (item?.initialData?.properties?.attribution || item?.initialData.properties.synmax_ship_id ) === shipId)

    const onChangeHandler = (_date, keyboardInputValue) => {
        console.log(_date?.$d, '_date')
        if(shipId) {
            loadSearchedShip(shipId, _date?.$d)
        }
        if (_date) {
            setDate(_date);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickActions = (event) => {
        setActionsAnchorEl(event.currentTarget);
    };

    const changePassword = async () => {
        const token = localStorage.getItem('token')
        try {
            setIsPasswordChanging(true)
            const res = await axios.post('https://hyperion-beta.synmax.com/users/change-password', {
                headers: { Authorization: 'Bearer ' + token },
                uid: userData.uid,
                newPassword
            });

            if (res.data.message) {
                setIsPasswordChanging(false)
                setIsPwChangeSnackBarOpen(true)
                setShowChangePasswordDialog(false)
            }
        } catch (err) {
            setIsPasswordChanging(false)
            console.log(err, ' :err')
            setShowChangePasswordDialog(false)
        }
    }
    const uploadGeojson = (e) => {
        e.preventDefault()
        const fileReader = new FileReader();
        const name = e.target.files[0].name;
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            setUploadedGejson([...uploadedGeojsons, { name, data: JSON.parse(e.target.result), key: parseInt(Math.random() * 10000), isVisible: true, uId: uploadedGeojsons.length + 1 }])
            inputFile.current.value = "";
        };
    }

    const loadCSVToPoints = (data, name) => {
        const firstRow = data[0]

        if (firstRow[0] != 'name' || firstRow[1] != 'lat' || firstRow[2] != 'lon') {
            setIsIncorrectCSVFormat(true)
        } else {
            const allPoints = data.slice(1)
            setUploadedCSVs([...uploadedCSVs, { name, allPoints, isVisible: true, uId: uploadedCSVs.length + 1 }])
        }
    }

    const loadSearchedShip = async (shipId, date) => {

        const dateString = moment(date).format('YYYY-MM-DD')
        var dateObject = moment.utc(dateString, "YYYY-MM-DD");
        var milliseconds = dateObject.valueOf();
        const token = localStorage.getItem('token')
        setShowSpinner(true)
        const res = await axios.post(`${frontendAPIURL}/ais_for_ships`,
            { "id": "cfrontend", "start": `${dateString} 00:00:00`, "end": `${dateString} 23:59:59`, "shipids": [shipId] }
            , {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
        setShowSpinner(false)

        if (res.data.length > 0) {

            const observationsSortedByTimestamp = res.data.sort(compare)

            const latestObservation = observationsSortedByTimestamp[observationsSortedByTimestamp.length - 1]
                const observationGeoJsonFeature = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [latestObservation.longitude, latestObservation.latitude],
                    },
                    properties: {
                        ...latestObservation,
                    },
                }
                console.log(observationGeoJsonFeature, "updatedShipsWithReplacedValues")

                setMapCenter({ lat: latestObservation.latitude, lng: latestObservation.longitude, zoomLevel: 12 })
                setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })

                const dd = selectedShips?.find((item) => (item.initialData.properties.attribution || item.initialData.properties.synmax_ship_id ) === observationGeoJsonFeature.properties.synmax_ship_id)

            const propertiesToAdd = (dd.initialData.properties.fromDecoupleModel ? { ...dd } : { ...dd.initialData.properties, acquired: milliseconds });

            console.log(propertiesToAdd, "dddddddd")

                getShipDetails({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [latestObservation.longitude, latestObservation.latitude],
                    },
                        properties: {
                            ...propertiesToAdd,
                            Date: dateString,
                            latitude: latestObservation.latitude, 
                            longitude: latestObservation.longitude, ...latestObservation
                        },
                }, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, dd?.shipCategory, dateString, true, res.data)
            
        } else {
            setIsSnackbarOpen(true)
        }
    }

    return (
        <div className='navbar'>
            <Dialog
                open={showChangePasswordDialog}
                onClose={() => setShowChangePasswordDialog(false)}
            >
                <div className="changePassword">
                    <div className="changePassword__heading">Change Password: </div>
                    <div className="changePassword__input">
                        <TextField
                            id="outlined-basic"
                            label="New Password"
                            variant="standard"
                            type="password"
                            name="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>
                    <LoadingButton loading={isPasswordChanging} variant="outlined" color='primary' onClick={changePassword}>Update</LoadingButton>
                </div>
            </Dialog>

            <Snackbar
                open={isPwChangeSnackBarOpen}
                autoHideDuration={4000}
                onClose={() => setIsPasswordChanging(false)}
                message="Password Changed"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}

            />

            <Snackbar
                open={isIncorrectCSVFormat}
                autoHideDuration={4000}
                onClose={() => setIsIncorrectCSVFormat(false)}

                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <SnackbarContent style={{
                    backgroundColor: 'red',
                }}
                    message={<span id="client-snackbar">Incorrect Format</span>}
                />

            </Snackbar>

            <div className="contentBox">
                <div className='subContainer'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="contentBox__right">
                            <div className="imgBox">
                                <img src="./theia-logo.svg" alt="Synmax Logo" />
                            </div>
                        </div>
                        <div className="contentBox__center">
                            {showSpinner && (
                                <div className="contentBox__center--cancelRequest" onClick={() => setShowSpinner(false)}>
                                    <div><NotInterestedIcon
                                        style={{ marginRight: '0.3rem', color: '#fff', fontSize: "1.6rem" }}
                                    />
                                    </div>
                                    <div>Cancel Request</div></div>
                            )}
                            <div className="contentBox__center--text">{moment(date.$d).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD') ? `Live View - Last Updated ${initialDate}` : moment(date.$d).format('YYYY-MM-DD')}</div>
                            <div className="contentBox__center--icon">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        onChange={onChangeHandler}
                                        onClose={() => { setIsOpen(false); }}
                                        open={isOpen}
                                        value={date}
                                        views={['year', 'month', 'day']}
                                        PopperProps={{ anchorEl: customInputRef.current }}
                                        renderInput={({
                                            ref,
                                            inputProps,
                                            disabled,
                                            onChange,
                                            value,
                                            ...other
                                        }) => (
                                            <div ref={ref}>
                                                <input
                                                    style={{ display: 'none', marginLeft: 100 }}
                                                    value={date.toISOString()}
                                                    onChange={onChange}
                                                    disabled={disabled}
                                                    ref={customInputRef}
                                                    {...inputProps}
                                                />

                                                <CalendarMonthIcon
                                                    style={{ cursor: 'pointer', color: '#fff', fontSize: "1.6rem" }}
                                                    onClick={() => { setIsOpen(!isOpen); }}
                                                />

                                            </div>)} />

                                </LocalizationProvider>
                            </div>
                            {moment(date.$d).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') &&
                                <>
                                    <div className="contentBox__center--refresh">
                                        <div className="contentBox__refresh" onClick={() => setRefreshViewClicked(prevValue => prevValue + 1)}>
                                            <div className="contentBox__refresh--icon">
                                                <RefreshIcon
                                                    style={{ cursor: 'pointer', color: '#fff', fontSize: "1.6rem" }}
                                                />
                                            </div>
                                            <div className="contentBox__refresh--text">Refresh Live View</div>
                                        </div>
                                    </div>
                                    <div className="contentBox__center--refresh">
                                        {/* <div className="contentBox__refresh" onClick={() => setViewDetectionShipClicked(prevValue => prevValue + 1)}>
                                            <div className="contentBox__refresh--icon">
                                                <RefreshIcon
                                                    style={{ cursor: 'pointer', color: '#fff', fontSize: "1.6rem" }}
                                                />
                                            </div>
                                            <div className="contentBox__refresh--text">View Detection ship</div>
                                        </div> */}
                                    </div>
                                </>

                            }

                        </div>
                    </div>
                </div>

                <div>
                    <div className="contentBox__end">
                        <div className="contentBox__search">
                            <SearchBar date={date} />
                        </div>

                        {/* <a href="https://apidocs.theia-nga.synmax.com/" target='_blank' rel='noreferrer'>
                            <div style={{ color: '#fff', fontSize: '1.4rem', fontWeight: 500, marginBottom: 2, letterSpacing: '0.3px', marginRight: 10, marginLeft: 5 }}>
                                Documentation
                            </div>
                        </a> */}

                        <Button
                            id="basic-button"

                            onClick={() => window.open("https://apidocs.theia.synmax.com", '_blank')}
                        >
                            <div className="navLinks__link">
                                <div className="navLinks__name">Documentation</div>
                            </div>
                        </Button>

                        <Button
                            id="basic-button"
                            aria-controls={openActions ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openActions ? 'true' : undefined}
                            onClick={handleClickActions}
                        >
                            <div className="navLinks__link">
                                <div className="navLinks__name">Actions</div>
                            </div>
                        </Button>

                        <Menu
                            id="theia-nav-menu"
                            anchorEl={actionsAnchorEl}
                            open={openActions}
                            onClose={handleCloseActions}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >

                            <MenuItem
                                onClick={() => {
                                    inputFile.current.click();
                                    handleCloseActions();
                                }}
                            >
                                <div className="navLinks__item">
                                    <div className="navLinks__item--icon">
                                        <CloudUploadIcon
                                            style={{
                                                color: '#23283D',
                                                paddingTop: 5,
                                                fontSize: '24px',
                                                marginRight: 6,
                                            }}
                                        />
                                    </div>
                                    <div className="navLinks__item--text" id="logout">
                                        Upload Geojson
                                    </div>

                                </div>
                            </MenuItem>

                            <MenuItem
                                onClick={() => {
                                    csvUploadRef.current.click();
                                    handleCloseActions();
                                }}
                            >
                                <div>
                                    <div className="navLinks__item">
                                        <div className="navLinks__item--icon">
                                            <CloudUploadIcon
                                                style={{
                                                    color: '#23283D',
                                                    paddingTop: 5,
                                                    fontSize: '24px',
                                                    marginRight: 6,
                                                }}
                                            />
                                        </div>
                                        <div className="navLinks__item--text" id="logout">
                                            Upload CSV

                                        </div>

                                    </div>
                                    <div style={{ fontSize: 12, marginTop: -10, fontWeight: 400 }}>Please Make sure the column names are: Name, Lat, Lon</div>
                                </div>


                            </MenuItem>
                        </Menu>
                        <input type="file" id="imgupload" style={{ display: 'none' }} ref={inputFile} onChange={uploadGeojson} />
                        {/* <input type="file" id="csvupload" style={{display:'none'}} ref={csvUploadRef} onChange={uploadCSV}/>  */}
                        <CSVReader inputStyle={{ display: 'none' }} onFileLoaded={(data, fileInfo, originalFile) => {
                            csvUploadRef.current.value = "";
                            console.log(fileInfo, ':fileinfo')
                            loadCSVToPoints(data, fileInfo.name)
                        }} ref={csvUploadRef} />
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <div className="navLinks__link">
                                <div className="navLinks__name">Account</div>
                            </div>
                        </Button>

                        <Menu
                            id="theia-nav-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >

                            <MenuItem
                                onClick={() => {
                                    logout();
                                    handleClose();
                                }}
                            >
                                <div className="navLinks__item">
                                    <div className="navLinks__item--icon">
                                        <LogoutIcon
                                            style={{
                                                color: '#23283D',
                                                paddingTop: 5,
                                                fontSize: '24px',
                                                marginRight: 6,
                                            }}
                                        />
                                    </div>
                                    <div className="navLinks__item--text" id="logout">
                                        Logout
                                    </div>
                                </div>
                            </MenuItem>

                            <MenuItem
                                onClick={() => {
                                    setShowChangePasswordDialog(true)
                                    handleClose();
                                }}
                            >
                                <div className="navLinks__item">
                                    <div className="navLinks__item--icon">
                                        <ChangeCircleOutlinedIcon
                                            style={{
                                                color: '#23283D',
                                                paddingTop: 5,
                                                fontSize: '24px',
                                                marginRight: 6,
                                            }}
                                        />
                                    </div>
                                    <div className="navLinks__item--text" id="logout">
                                        Change Password
                                    </div>
                                </div>
                            </MenuItem>
                        </Menu>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar

function compare(a, b) {
    if (a.timestamp < b.timestamp) {
        return -1;
    }
    if (a.timestamp > b.timestamp) {
        return 1;
    }
    return 0;
}