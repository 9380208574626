export const allPorts = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14314',
      geometry: { type: 'Point', coordinates: [20.62966191, -0.94560244] },
      geometry_name: 'shape',
      properties: {
        portname: 'Watsi-Genge',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -0.9456,
        longitude: 20.62966,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 204280,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14315',
      geometry: { type: 'Point', coordinates: [-132.00969253, 53.24742403] },
      geometry_name: 'shape',
      properties: {
        portname: 'Charlotte (Skidegate)',
        code: 'CASKI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.24742,
        longitude: -132.00969,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6148858,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14316',
      geometry: { type: 'Point', coordinates: [-151.41553244, 59.60158521] },
      geometry_name: 'shape',
      properties: {
        portname: 'Homer',
        code: 'USHOI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.60159,
        longitude: -151.41553,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5864145,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14317',
      geometry: { type: 'Point', coordinates: [-74.00209775, 40.70610421] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hartsdale',
        code: 'USHAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.7061,
        longitude: -74.0021,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5120141,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14318',
      geometry: { type: 'Point', coordinates: [-0.21511851, 53.64560882] },
      geometry_name: 'shape',
      properties: {
        portname: 'Killingholme',
        code: 'GBKGH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.64561,
        longitude: -0.21512,
        iso3: null,
        iso3_op: 'GBR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2641323,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14319',
      geometry: { type: 'Point', coordinates: [-0.26933945, 50.83231674] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shoreham',
        code: 'GBSHO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.83232,
        longitude: -0.26934,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2637916,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14320',
      geometry: { type: 'Point', coordinates: [0.71549509, 51.53414751] },
      geometry_name: 'shape',
      properties: {
        portname: 'Southend',
        code: 'GBSND',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.53415,
        longitude: 0.7155,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2637433,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14321',
      geometry: { type: 'Point', coordinates: [-60.69712386, -31.65013845] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santa Fe',
        code: 'ARSFN',
        prttype: 'River',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -31.65014,
        longitude: -60.69712,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3836277,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14322',
      geometry: { type: 'Point', coordinates: [-71.63471503, 10.44090463] },
      geometry_name: 'shape',
      properties: {
        portname: 'Punta De Palmas',
        code: 'VEPLV',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.4409,
        longitude: -71.63472,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3629605,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14323',
      geometry: { type: 'Point', coordinates: [-83.07669185, 10.00432705] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moin Bay',
        code: 'CRMOB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.00433,
        longitude: -83.07669,
        iso3: 'CRI',
        iso3_op: 'CRI',
        country: 'Costa Rica',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3622850,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14324',
      geometry: { type: 'Point', coordinates: [-65.98286045, 45.20849824] },
      geometry_name: 'shape',
      properties: {
        portname: 'Canaport',
        code: 'CACPT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.2085,
        longitude: -65.98286,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14325',
      geometry: { type: 'Point', coordinates: [-122.51324472, 47.62285229] },
      geometry_name: 'shape',
      properties: {
        portname: 'Winslow',
        code: 'USWSE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.62285,
        longitude: -122.51324,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14326',
      geometry: { type: 'Point', coordinates: [-151.71198216, 60.71330494] },
      geometry_name: 'shape',
      properties: {
        portname: 'West Foreland',
        code: 'USWFO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.7133,
        longitude: -151.71198,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14327',
      geometry: { type: 'Point', coordinates: [-122.20404733, 37.51320336] },
      geometry_name: 'shape',
      properties: {
        portname: 'Redwood City',
        code: 'USRWC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.5132,
        longitude: -122.20405,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14328',
      geometry: { type: 'Point', coordinates: [-89.3605033, 29.24043339] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shawano',
        code: 'USSWS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.24043,
        longitude: -89.3605,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14329',
      geometry: { type: 'Point', coordinates: [-91.80580111, 29.50304844] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mars',
        code: 'USXJB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 29.50305,
        longitude: -91.8058,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14330',
      geometry: { type: 'Point', coordinates: [-1.29003109, 60.45831097] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sullom Voe',
        code: 'GBSUL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.45831,
        longitude: -1.29003,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14331',
      geometry: { type: 'Point', coordinates: [14.87225582, 38.5593004] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salina',
        code: 'ITSLA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.5593,
        longitude: 14.87226,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14332',
      geometry: { type: 'Point', coordinates: [23.51073838, 38.04273965] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eleusis',
        code: 'GREEU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.04274,
        longitude: 23.51074,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 264371,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14333',
      geometry: { type: 'Point', coordinates: [24.94031944, 37.43767341] },
      geometry_name: 'shape',
      properties: {
        portname: 'Syros (Syra)',
        code: 'GRJSY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.43767,
        longitude: 24.94032,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14334',
      geometry: { type: 'Point', coordinates: [124.40971929, 10.62831918] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cebu',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 10.62832,
        longitude: 124.40972,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: 'CPA Cebu Port Authority',
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14335',
      geometry: { type: 'Point', coordinates: [124.28380482, 10.61767675] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coco Grove Nature Resort',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.61768,
        longitude: 124.2838,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14336',
      geometry: { type: 'Point', coordinates: [55.31132846, -20.93108125] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pointe Des Galets',
        code: 'REPDG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.93108,
        longitude: 55.31133,
        iso3: 'REU',
        iso3_op: 'REU',
        country: 'Réunion',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 935616,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14337',
      geometry: { type: 'Point', coordinates: [29.66218348, 45.1584857] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sulina (Port Et Zone Franche)',
        code: 'ROSUL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.15849,
        longitude: 29.66218,
        iso3: 'ROU',
        iso3_op: 'ROU',
        country: 'Romania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 665790,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14338',
      geometry: { type: 'Point', coordinates: [50.19732685, 26.48684132] },
      geometry_name: 'shape',
      properties: {
        portname: 'Damman',
        code: 'SADMN',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.48684,
        longitude: 50.19733,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 110336,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14339',
      geometry: { type: 'Point', coordinates: [-117.12304922, 32.50821185] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tijuana',
        code: 'MXTIJ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 32.50821,
        longitude: -117.12305,
        iso3: null,
        iso3_op: 'MEX',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3981609,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14340',
      geometry: { type: 'Point', coordinates: [-54.63312378, -34.84587558] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jose Ignacio Terminal',
        code: 'UYJIT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -34.84588,
        longitude: -54.63312,
        iso3: 'URY',
        iso3_op: 'URY',
        country: 'Uruguay',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3442229,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14341',
      geometry: { type: 'Point', coordinates: [50.33049242, 29.21618549] },
      geometry_name: 'shape',
      properties: {
        portname: 'Khark Island',
        code: 'IRKHK',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.21619,
        longitude: 50.33049,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 130216,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14342',
      geometry: { type: 'Point', coordinates: [49.17770933, 30.46914291] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bandar Mahshahr',
        code: 'IRMRX',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.46914,
        longitude: 49.17771,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 141663,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14343',
      geometry: { type: 'Point', coordinates: [9.01367655, 39.09044877] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porto Foxi (Sarroch)',
        code: 'ITPFX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.09045,
        longitude: 9.01368,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2523222,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14345',
      geometry: { type: 'Point', coordinates: [-1.92330858, 16.88259248] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rharous',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.88259,
        longitude: -1.92331,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2456871,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14346',
      geometry: { type: 'Point', coordinates: [48.46240782, -20.8659176] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ambohitsara',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -20.86592,
        longitude: 48.46241,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1079695,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14347',
      geometry: { type: 'Point', coordinates: [121.59488516, -4.18279858] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pomala',
        code: 'IDPUM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.1828,
        longitude: 121.59489,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1630916,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14348',
      geometry: { type: 'Point', coordinates: [109.57271574, -0.7691897] },
      geometry_name: 'shape',
      properties: {
        portname: 'Telok Ayer',
        code: 'IDTKA',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.76919,
        longitude: 109.57272,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14349',
      geometry: { type: 'Point', coordinates: [22.14080388, 1.06497516] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lifake',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.06498,
        longitude: 22.1408,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 211910,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14351',
      geometry: { type: 'Point', coordinates: [5.2062523, 5.61172809] },
      geometry_name: 'shape',
      properties: {
        portname: 'Escravos',
        code: 'NGESC',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.61173,
        longitude: 5.20625,
        iso3: 'NGA',
        iso3_op: '',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14352',
      geometry: { type: 'Point', coordinates: [106.32849974, 10.34402398] },
      geometry_name: 'shape',
      properties: {
        portname: 'My Tho',
        code: 'VNMUT',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.34402,
        longitude: 106.3285,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: 'My Thoi',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1592858,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14353',
      geometry: { type: 'Point', coordinates: [22.11767981, -1.37228607] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yafala',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -1.37229,
        longitude: 22.11768,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 204108,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14344',
      geometry: { type: 'Point', coordinates: [31.33382921, 6.48443878] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gutthom',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.48444,
        longitude: 31.33383,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-25T06:54:45.106Z',
        geonameid: 381936,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14350',
      geometry: { type: 'Point', coordinates: [18.92355, -3.82254] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vum',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.82254,
        longitude: 18.92355,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-25T06:59:34.880Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14354',
      geometry: { type: 'Point', coordinates: [20.97019221, 0.46143228] },
      geometry_name: 'shape',
      properties: {
        portname: 'Isokolokoto',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 0.46143,
        longitude: 20.97019,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 215784,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14356',
      geometry: { type: 'Point', coordinates: [5.4587464, 5.99881395] },
      geometry_name: 'shape',
      properties: {
        portname: 'Koko',
        code: 'NGKOK',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.99881,
        longitude: 5.45875,
        iso3: 'NGA',
        iso3_op: 'NGA',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2334154,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14357',
      geometry: { type: 'Point', coordinates: [-63.66422948, 45.84869466] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pugwash',
        code: 'CAPUG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.84869,
        longitude: -63.66423,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6114045,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14358',
      geometry: { type: 'Point', coordinates: [5.63240498, 50.65459135] },
      geometry_name: 'shape',
      properties: {
        portname: 'Liege (Luik)',
        code: 'BELGG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.65459,
        longitude: 5.6324,
        iso3: 'BEL',
        iso3_op: 'BEL',
        country: 'Belgium',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2792413,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14359',
      geometry: { type: 'Point', coordinates: [127.69245494, 26.24469705] },
      geometry_name: 'shape',
      properties: {
        portname: 'Naha',
        code: 'JPNAH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 26.2447,
        longitude: 127.69245,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1855056,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14360',
      geometry: { type: 'Point', coordinates: [116.69786959, -20.67559961] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cossack Pioneer',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.6756,
        longitude: 116.69787,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: 'Aucop, Oil Terminal',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14361',
      geometry: { type: 'Point', coordinates: [-90.36438874, 29.93481962] },
      geometry_name: 'shape',
      properties: {
        portname: 'Des Plaines',
        code: 'USDES',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.93482,
        longitude: -90.36439,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14362',
      geometry: { type: 'Point', coordinates: [0.63419807, 51.42122067] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oakham Ness',
        code: 'GBOAK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.42122,
        longitude: 0.6342,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14363',
      geometry: { type: 'Point', coordinates: [-4.86914651, 55.7450481] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hunterston',
        code: 'GBHST',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.74505,
        longitude: -4.86915,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14364',
      geometry: { type: 'Point', coordinates: [-4.8197339, 56.06673387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Faslane',
        code: 'GBFAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.06673,
        longitude: -4.81973,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14365',
      geometry: { type: 'Point', coordinates: [2.64450174, 39.56616026] },
      geometry_name: 'shape',
      properties: {
        portname: 'Palma Mallorca',
        code: 'ESPMI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.56616,
        longitude: 2.6445,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2512989,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14366',
      geometry: { type: 'Point', coordinates: [130.96632834, 33.95200534] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moji',
        code: 'JPMOJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.95201,
        longitude: 130.96633,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1852225,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14367',
      geometry: { type: 'Point', coordinates: [141.00557327, 38.27450786] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sendai',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.27451,
        longitude: 141.00557,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 8555918,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14368',
      geometry: { type: 'Point', coordinates: [51.22023432, 43.60966596] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Of Aqtau (Aktau)',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.60967,
        longitude: 51.22023,
        iso3: 'KAZ',
        iso3_op: 'KAZ',
        country: 'Kazakhstan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 610612,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14369',
      geometry: { type: 'Point', coordinates: [94.73081589, 16.78112249] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bassein',
        code: 'MMBSX',
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.78112,
        longitude: 94.73082,
        iso3: 'MMR',
        iso3_op: 'MMR',
        country: 'Myanmar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1328421,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14370',
      geometry: { type: 'Point', coordinates: [-78.141906, 8.40628707] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Palma',
        code: 'PAPLP',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.40629,
        longitude: -78.14191,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3707058,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14371',
      geometry: { type: 'Point', coordinates: [-31.17110381, 39.3790176] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lajes',
        code: 'PTLAJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.37902,
        longitude: -31.1711,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Azores Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3372954,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14372',
      geometry: { type: 'Point', coordinates: [51.56731119, 24.91737827] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mesaieed',
        code: 'QAUMS',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.91738,
        longitude: 51.56731,
        iso3: 'QAT',
        iso3_op: 'QAT',
        country: 'Qatar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 289525,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14373',
      geometry: { type: 'Point', coordinates: [-65.03439035, -41.69761993] },
      geometry_name: 'shape',
      properties: {
        portname: 'Punta Colorada',
        code: 'ARPCO',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.69762,
        longitude: -65.03439,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3840093,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14374',
      geometry: { type: 'Point', coordinates: [-78.23165199, 26.62641513] },
      geometry_name: 'shape',
      properties: {
        portname: 'South Riding Point',
        code: 'BSSRP',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.62642,
        longitude: -78.23165,
        iso3: 'BHS',
        iso3_op: 'BHS',
        country: 'Bahamas',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3572375,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14375',
      geometry: { type: 'Point', coordinates: [103.77973593, 1.45200759] },
      geometry_name: 'shape',
      properties: {
        portname: 'Woodlands',
        code: 'SGWDL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.45201,
        longitude: 103.77974,
        iso3: null,
        iso3_op: 'SGP',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1882316,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14376',
      geometry: { type: 'Point', coordinates: [134.46387739, 34.78907274] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aioi',
        code: 'JPAIO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.78907,
        longitude: 134.46388,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1865661,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14377',
      geometry: { type: 'Point', coordinates: [129.11645574, 37.55231072] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mukho',
        code: 'KRMUK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.55231,
        longitude: 129.11646,
        iso3: 'KOR',
        iso3_op: 'KOR',
        country: 'Republic of Korea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1840938,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14378',
      geometry: { type: 'Point', coordinates: [150.77662367, 59.540239] },
      geometry_name: 'shape',
      properties: {
        portname: 'Arman',
        code: 'RUARM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.54024,
        longitude: 150.77662,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2127060,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14379',
      geometry: { type: 'Point', coordinates: [-97.39382587, 20.94442243] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tuxpan',
        code: 'MXTUX',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.94442,
        longitude: -97.39383,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3532385,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14380',
      geometry: { type: 'Point', coordinates: [-71.55189694, 10.76143527] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Miranda / Maracaibo L.',
        code: 'VEMIV',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.76144,
        longitude: -71.5519,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3629659,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14381',
      geometry: { type: 'Point', coordinates: [-71.45479901, 10.37679418] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Salina / Maracaibo L',
        code: 'VELSV',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.37679,
        longitude: -71.4548,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3636327,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14382',
      geometry: { type: 'Point', coordinates: [97.61749704, 16.49321957] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moulmein',
        code: 'MMMNU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.49322,
        longitude: 97.6175,
        iso3: null,
        iso3_op: 'MMR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1308465,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14383',
      geometry: { type: 'Point', coordinates: [29.67017677, 31.05358999] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sidi Kerir',
        code: 'EGSKT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.05359,
        longitude: 29.67018,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 353407,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14384',
      geometry: { type: 'Point', coordinates: [11.25443343, 55.20851605] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stignaesvaerkets Havn',
        code: 'DKSTG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.20852,
        longitude: 11.25443,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3221881,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14387',
      geometry: { type: 'Point', coordinates: [24.00622537, 60.0414776] },
      geometry_name: 'shape',
      properties: {
        portname: 'Inkoo',
        code: 'FIINK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.04148,
        longitude: 24.00623,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14388',
      geometry: { type: 'Point', coordinates: [-74.189616, 22.75116352] },
      geometry_name: 'shape',
      properties: {
        portname: 'Crooked Island',
        code: 'BSCRI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 22.75116,
        longitude: -74.18962,
        iso3: 'BHS',
        iso3_op: 'BHS',
        country: 'Bahamas',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3572620,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14389',
      geometry: { type: 'Point', coordinates: [-71.08720532, 18.21610622] },
      geometry_name: 'shape',
      properties: {
        portname: 'Barahona',
        code: 'DOBRX',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.21611,
        longitude: -71.08721,
        iso3: 'DOM',
        iso3_op: 'DOM',
        country: 'Dominican Republic',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3492985,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14390',
      geometry: { type: 'Point', coordinates: [56.20610841, 27.14680992] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bandar Abbas',
        code: 'IRBND',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 27.14681,
        longitude: 56.20611,
        iso3: 'IRN',
        iso3_op: 'IRN,AFG',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 141681,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14391',
      geometry: { type: 'Point', coordinates: [-42.01826048, -22.96956734] },
      geometry_name: 'shape',
      properties: {
        portname: 'Forno',
        code: 'BRFNO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -22.96957,
        longitude: -42.01826,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3468615,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14392',
      geometry: { type: 'Point', coordinates: [33.99318968, 35.32798397] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalecik',
        code: 'CYKAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.32798,
        longitude: 33.99319,
        iso3: 'CYP',
        iso3_op: 'CYP',
        country: 'Cyprus',
        lastcheckdate: null,
        remarks: 'Gastria',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14393',
      geometry: { type: 'Point', coordinates: [29.86203933, 31.16935704] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Iskandariya',
        code: 'EGALY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.16936,
        longitude: 29.86204,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 361058,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14394',
      geometry: { type: 'Point', coordinates: [33.27519234, 28.13602087] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Shukheir',
        code: 'EGRSH',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.13602,
        longitude: 33.27519,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 350190,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14395',
      geometry: { type: 'Point', coordinates: [54.89052547, 26.55451852] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lingah',
        code: 'IRLIN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.55452,
        longitude: 54.89053,
        iso3: null,
        iso3_op: 'IRN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 141665,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14396',
      geometry: { type: 'Point', coordinates: [47.84922679, 30.51163668] },
      geometry_name: 'shape',
      properties: {
        portname: 'Basrah',
        code: 'IQBSR',
        prttype: 'River',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.51164,
        longitude: 47.84923,
        iso3: 'IRQ',
        iso3_op: 'IRQ',
        country: 'Iraq',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 99532,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14397',
      geometry: { type: 'Point', coordinates: [130.77865296, 33.93026503] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hibiki',
        code: 'JPHBK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.93027,
        longitude: 130.77865,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1859307,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14398',
      geometry: { type: 'Point', coordinates: [130.78126883, 33.88704621] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yawata',
        code: 'JPYWT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.88705,
        longitude: 130.78127,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1859307,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14399',
      geometry: { type: 'Point', coordinates: [50.01443125, 26.79268388] },
      geometry_name: 'shape',
      properties: {
        portname: 'Juaymah Terminal',
        code: 'SAJUT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.79268,
        longitude: 50.01443,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14400',
      geometry: { type: 'Point', coordinates: [-61.56864377, 10.6792305] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tembladora',
        code: 'TTTEM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.67923,
        longitude: -61.56864,
        iso3: 'TTO',
        iso3_op: 'TTO',
        country: 'Trinidad and Tobago',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3573890,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14401',
      geometry: { type: 'Point', coordinates: [29.01451735, 41.00241559] },
      geometry_name: 'shape',
      properties: {
        portname: 'Haydarpasa',
        code: 'TRHAY',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.00242,
        longitude: 29.01452,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 745044,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14402',
      geometry: { type: 'Point', coordinates: [26.75209797, 38.66760942] },
      geometry_name: 'shape',
      properties: {
        portname: 'Foca',
        code: 'TRFOC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.66761,
        longitude: 26.7521,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 324106,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14403',
      geometry: { type: 'Point', coordinates: [-64.93865128, 18.3388666] },
      geometry_name: 'shape',
      properties: {
        portname: 'St Thomas Island',
        code: 'VISTT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.33887,
        longitude: -64.93865,
        iso3: 'VIR',
        iso3_op: 'VIR',
        country: 'United States Virgin Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4795467,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14404',
      geometry: { type: 'Point', coordinates: [-69.99845951, 12.48093618] },
      geometry_name: 'shape',
      properties: {
        portname: 'Barcadera',
        code: 'AWBAR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.48094,
        longitude: -69.99846,
        iso3: 'ABW',
        iso3_op: 'ABW',
        country: 'Aruba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3577246,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14405',
      geometry: { type: 'Point', coordinates: [-69.02590281, 12.19426709] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bullen Bay',
        code: 'ANBUB',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.19427,
        longitude: -69.0259,
        iso3: 'ANT',
        iso3_op: 'ANT',
        country: 'Netherlands Antilles',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3513853,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14406',
      geometry: { type: 'Point', coordinates: [54.37680887, 24.51601984] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zayed',
        code: 'AEMZD',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.51602,
        longitude: 54.37681,
        iso3: 'ARE',
        iso3_op: 'ARE',
        country: 'United Arab Emirates',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 292968,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14407',
      geometry: { type: 'Point', coordinates: [-61.82788463, 17.6414405] },
      geometry_name: 'shape',
      properties: {
        portname: 'Barbuda',
        code: 'AGBBQ',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.64144,
        longitude: -61.82788,
        iso3: 'ATG',
        iso3_op: 'ATG',
        country: 'Antigua and Barbuda',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14408',
      geometry: { type: 'Point', coordinates: [120.17442495, 31.92038915] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jiangyin',
        code: 'CNJIA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.92039,
        longitude: 120.17442,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1796236,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14409',
      geometry: { type: 'Point', coordinates: [92.90128159, 20.14000529] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sittwe',
        code: 'MMAKY',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.14001,
        longitude: 92.90128,
        iso3: 'MMR',
        iso3_op: 'MMR',
        country: 'Myanmar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1295765,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14411',
      geometry: { type: 'Point', coordinates: [-89.79324973, 29.57611129] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pointe A La Hache',
        code: 'USPHC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.57611,
        longitude: -89.79325,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4337717,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14412',
      geometry: { type: 'Point', coordinates: [80.20868288, 50.43992603] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Of Semey',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.43993,
        longitude: 80.20868,
        iso3: 'KAZ',
        iso3_op: 'KAZ',
        country: 'Kazakhstan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1519422,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14413',
      geometry: { type: 'Point', coordinates: [4.41740165, 51.24331711] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antwerp',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.24332,
        longitude: 4.4174,
        iso3: 'BEL',
        iso3_op: 'BEL',
        country: 'Belgium',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2803138,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14414',
      geometry: { type: 'Point', coordinates: [21.69516181, 36.91472396] },
      geometry_name: 'shape',
      properties: {
        portname: 'Navarino',
        code: 'GRNAV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.91472,
        longitude: 21.69516,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 7534441,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14415',
      geometry: { type: 'Point', coordinates: [51.86885834, 47.07208018] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Of Atyrau',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.07208,
        longitude: 51.86886,
        iso3: 'KAZ',
        iso3_op: 'KAZ',
        country: 'Kazakhstan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 610529,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14416',
      geometry: { type: 'Point', coordinates: [16.58492345, -5.87761937] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malingu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -5.87762,
        longitude: 16.58492,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 8255753,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14417',
      geometry: { type: 'Point', coordinates: [22.11230052, 1.53925] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bolafa',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.53925,
        longitude: 22.1123,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 218456,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14418',
      geometry: { type: 'Point', coordinates: [14.51313641, 35.89424621] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malta (Valetta)',
        code: 'MTMLA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.89425,
        longitude: 14.51314,
        iso3: 'MLT',
        iso3_op: 'MLT',
        country: 'Malta',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2562305,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14420',
      geometry: { type: 'Point', coordinates: [17.15382384, -4.41232245] },
      geometry_name: 'shape',
      properties: {
        portname: "N'Gi",
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.41232,
        longitude: 17.15382,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2312039,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14421',
      geometry: { type: 'Point', coordinates: [32.6647146, 29.11106576] },
      geometry_name: 'shape',
      properties: {
        portname: "Za'Farana",
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.11107,
        longitude: 32.66471,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 346201,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14422',
      geometry: { type: 'Point', coordinates: [-172.21695424, -13.7447483] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saleologa',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -13.74475,
        longitude: -172.21695,
        iso3: 'WSM',
        iso3_op: 'WSM',
        country: 'Samoa',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4035092,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14424',
      geometry: { type: 'Point', coordinates: [25.42749658, -0.61863814] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lilo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -0.61864,
        longitude: 25.4275,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 8282970,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14426',
      geometry: { type: 'Point', coordinates: [19.18074652, -0.2472578] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bangu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -0.24726,
        longitude: 19.18075,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2317361,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14427',
      geometry: { type: 'Point', coordinates: [160.49173053, -9.53525288] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aola Bay',
        code: 'SBAOB',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -9.53525,
        longitude: 160.49173,
        iso3: null,
        iso3_op: 'SLB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2109807,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14428',
      geometry: { type: 'Point', coordinates: [-64.00852705, 45.23110981] },
      geometry_name: 'shape',
      properties: {
        portname: 'Walton',
        code: 'CAWTN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.23111,
        longitude: -64.00853,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6175743,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14429',
      geometry: { type: 'Point', coordinates: [-72.65550779, 40.91828164] },
      geometry_name: 'shape',
      properties: {
        portname: 'Northville (Riverhead)',
        code: 'USNVR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.91828,
        longitude: -72.65551,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5129314,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14430',
      geometry: { type: 'Point', coordinates: [-75.60730124, 20.7272485] },
      geometry_name: 'shape',
      properties: {
        portname: 'Felton',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'approximate',
        latitude: 20.72725,
        longitude: -75.6073,
        iso3: 'CUB',
        iso3_op: null,
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3558943,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14431',
      geometry: { type: 'Point', coordinates: [-75.33572961, 20.67864275] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanamo',
        code: 'CUTAN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.67864,
        longitude: -75.33573,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3541446,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14432',
      geometry: { type: 'Point', coordinates: [-122.91238095, 49.19980957] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fraser Mills',
        code: 'CAFRM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.19981,
        longitude: -122.91238,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5957613,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14433',
      geometry: { type: 'Point', coordinates: [0.36059677, 49.43485398] },
      geometry_name: 'shape',
      properties: {
        portname: 'Berville',
        code: 'FRBEV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.43485,
        longitude: 0.3606,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3033136,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14434',
      geometry: { type: 'Point', coordinates: [-70.87961455, 48.34382503] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bagotville',
        code: 'CABGT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.34383,
        longitude: -70.87961,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14435',
      geometry: { type: 'Point', coordinates: [-95.1928, 29.74113387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Norsworthy',
        code: 'USNSW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 29.74113,
        longitude: -95.1928,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14436',
      geometry: { type: 'Point', coordinates: [15.633918, 68.08476952] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hamaroy',
        code: 'NOHMY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 68.08477,
        longitude: 15.63392,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14437',
      geometry: { type: 'Point', coordinates: [15.25179405, 37.10968056] },
      geometry_name: 'shape',
      properties: {
        portname: 'Melilli',
        code: 'ITMEL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.10968,
        longitude: 15.25179,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14438',
      geometry: { type: 'Point', coordinates: [10.34783268, 56.24891294] },
      geometry_name: 'shape',
      properties: {
        portname: 'Studstrupvaerkets Havn',
        code: 'DKSSV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.24891,
        longitude: 10.34783,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15504',
      geometry: { type: 'Point', coordinates: [124.75312003, 8.50848692] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bugo',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.50849,
        longitude: 124.75312,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1723257,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14439',
      geometry: { type: 'Point', coordinates: [18.65782094, 54.40853283] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nowy Port',
        code: 'PLNOW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.40853,
        longitude: 18.65782,
        iso3: 'POL',
        iso3_op: 'POL',
        country: 'Poland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14440',
      geometry: { type: 'Point', coordinates: [25.43118571, 36.38610269] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thira',
        code: 'GRJTR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.3861,
        longitude: 25.43119,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14441',
      geometry: { type: 'Point', coordinates: [141.38523818, 40.96874677] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mutsuogawara',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.96875,
        longitude: 141.38524,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14442',
      geometry: { type: 'Point', coordinates: [51.59254574, 25.91414299] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Laffan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.91414,
        longitude: 51.59255,
        iso3: null,
        iso3_op: 'QAT',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 442527,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14443',
      geometry: { type: 'Point', coordinates: [155.86378812, -7.0889154] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shortland Harbour',
        code: 'SBSHH',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -7.08892,
        longitude: 155.86379,
        iso3: null,
        iso3_op: 'SLB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2107273,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14444',
      geometry: { type: 'Point', coordinates: [125.68134336, -8.53444867] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hera fishing port',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.53445,
        longitude: 125.68134,
        iso3: 'TLS',
        iso3_op: 'TLS',
        country: 'Timor-Leste',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1645457,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14445',
      geometry: { type: 'Point', coordinates: [109.22867488, 13.78130398] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thi Nai',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.7813,
        longitude: 109.22867,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1568574,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14446',
      geometry: { type: 'Point', coordinates: [54.13299747, 24.32186662] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Zubbaya',
        code: 'AEDUY',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.32187,
        longitude: 54.133,
        iso3: 'ARE',
        iso3_op: 'ARE',
        country: 'United Arab Emirates',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 292968,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14447',
      geometry: { type: 'Point', coordinates: [-0.36337185, 53.70468769] },
      geometry_name: 'shape',
      properties: {
        portname: 'New Holland',
        code: 'GBNHO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.70469,
        longitude: -0.36337,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2641633,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14448',
      geometry: { type: 'Point', coordinates: [10.85775296, 33.88625987] },
      geometry_name: 'shape',
      properties: {
        portname: 'Djerba',
        code: 'TNDJE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.88626,
        longitude: 10.85775,
        iso3: 'TUN',
        iso3_op: 'TUN',
        country: 'Tunisia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2470384,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14449',
      geometry: { type: 'Point', coordinates: [-73.10177685, -36.72829843] },
      geometry_name: 'shape',
      properties: {
        portname: 'Talca',
        code: 'CLTLX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -36.7283,
        longitude: -73.10178,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3870282,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14450',
      geometry: { type: 'Point', coordinates: [103.42385672, 3.9780868] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kuantan',
        code: 'MYKUA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 3.97809,
        longitude: 103.42386,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 8306375,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14451',
      geometry: { type: 'Point', coordinates: [103.45552947, 4.58998665] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kerteh Terminal',
        code: 'MYKET',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.58999,
        longitude: 103.45553,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1734316,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14452',
      geometry: { type: 'Point', coordinates: [126.38245025, 34.78085957] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mokpo',
        code: 'KRMOK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.78086,
        longitude: 126.38245,
        iso3: 'KOR',
        iso3_op: 'KOR',
        country: 'Republic of Korea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1841066,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14453',
      geometry: { type: 'Point', coordinates: [101.36367768, 3.02802088] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Kelang',
        code: 'MYPKG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 3.02802,
        longitude: 101.36368,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1738412,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14454',
      geometry: { type: 'Point', coordinates: [-70.21380595, 11.66943293] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guaranao Bay',
        code: 'VEGUB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.66943,
        longitude: -70.21381,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3640119,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14455',
      geometry: { type: 'Point', coordinates: [55.89432469, 26.68226588] },
      geometry_name: 'shape',
      properties: {
        portname: 'Henjam',
        code: 'IRHEJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.68227,
        longitude: 55.89432,
        iso3: null,
        iso3_op: 'IRN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 131542,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14456',
      geometry: { type: 'Point', coordinates: [133.9644027, 34.60399544] },
      geometry_name: 'shape',
      properties: {
        portname: 'Okayama',
        code: 'JPOKJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.604,
        longitude: 133.9644,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1854383,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14457',
      geometry: { type: 'Point', coordinates: [117.23743188, 39.08764305] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tianjinxingang',
        code: 'CNTXG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.08764,
        longitude: 117.23743,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1792947,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14458',
      geometry: { type: 'Point', coordinates: [48.48299518, 29.97458307] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fao',
        code: 'IQFAO',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.97458,
        longitude: 48.483,
        iso3: 'IRQ',
        iso3_op: 'IRQ',
        country: 'Iraq',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 99446,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14459',
      geometry: { type: 'Point', coordinates: [-99.90401287, 16.84868005] },
      geometry_name: 'shape',
      properties: {
        portname: 'Acapulco',
        code: 'MXACA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.84868,
        longitude: -99.90401,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3533462,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14460',
      geometry: { type: 'Point', coordinates: [105.95815912, -6.01374622] },
      geometry_name: 'shape',
      properties: {
        portname: 'Banten',
        code: 'IDCIG',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.01375,
        longitude: 105.95816,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1650134,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14461',
      geometry: { type: 'Point', coordinates: [32.34742449, 29.65409518] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ain Sukhna',
        code: 'EGAIS',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.6541,
        longitude: 32.34742,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 7521348,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14462',
      geometry: { type: 'Point', coordinates: [-5.05426673, 55.83818268] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rothesay',
        code: 'GBRAY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.83818,
        longitude: -5.05427,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2639083,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14463',
      geometry: { type: 'Point', coordinates: [-82.42033939, 42.97120347] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Huron',
        code: 'USPHN',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.9712,
        longitude: -82.42034,
        iso3: 'USA',
        iso3_op: 'CAN',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6152244,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14464',
      geometry: { type: 'Point', coordinates: [-123.43220648, 48.12044306] },
      geometry_name: 'shape',
      properties: {
        portname: 'Patterson',
        code: 'USPAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.12044,
        longitude: -123.43221,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14465',
      geometry: { type: 'Point', coordinates: [-2.83623031, 53.28605912] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stanlow',
        code: 'GBSOW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.28606,
        longitude: -2.83623,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14466',
      geometry: { type: 'Point', coordinates: [25.6717757, 60.38291361] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porvoo',
        code: 'FIPRV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.38291,
        longitude: 25.67178,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14467',
      geometry: { type: 'Point', coordinates: [130.47128531, 32.60706443] },
      geometry_name: 'shape',
      properties: {
        portname: 'Misumi',
        code: 'JPMIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.60706,
        longitude: 130.47129,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1862794,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14468',
      geometry: { type: 'Point', coordinates: [139.3575, 34.74983387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oshima',
        code: 'JPOSM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 34.74983,
        longitude: 139.3575,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 8660346,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14469',
      geometry: { type: 'Point', coordinates: [136.86532639, 35.0787665] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nagoya',
        code: 'JPNGO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.07877,
        longitude: 136.86533,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1856057,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14470',
      geometry: { type: 'Point', coordinates: [36.44388144, 26.25248516] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dhuba',
        code: 'SADHU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.25249,
        longitude: 36.44388,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 108773,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14471',
      geometry: { type: 'Point', coordinates: [-77.13473052, -11.95654585] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Pampilla',
        code: 'PELPP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -11.95655,
        longitude: -77.13473,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3936933,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14472',
      geometry: { type: 'Point', coordinates: [-72.14034934, 21.81421507] },
      geometry_name: 'shape',
      properties: {
        portname: 'Providenciales',
        code: 'TCPLS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.81422,
        longitude: -72.14035,
        iso3: 'TCA',
        iso3_op: 'TCA',
        country: 'Turks and Caicos islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3577004,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14473',
      geometry: { type: 'Point', coordinates: [-61.51812662, 10.652075] },
      geometry_name: 'shape',
      properties: {
        portname: 'Trinidad',
        code: 'BOTDD',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.65208,
        longitude: -61.51813,
        iso3: 'TTO',
        iso3_op: 'TTO',
        country: 'Trinidad and Tobago',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3573890,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14474',
      geometry: { type: 'Point', coordinates: [29.77665837, 40.74509425] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tutunciftlik',
        code: 'TRTUT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.74509,
        longitude: 29.77666,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 737961,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14475',
      geometry: { type: 'Point', coordinates: [28.32469655, 37.05085654] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gokova',
        code: 'TRGKV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.05086,
        longitude: 28.3247,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 324390,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14476',
      geometry: { type: 'Point', coordinates: [27.58975651, 37.22762669] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gulluk',
        code: 'TRGUL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.22763,
        longitude: 27.58976,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 313680,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14477',
      geometry: { type: 'Point', coordinates: [121.09081625, 31.79178414] },
      geometry_name: 'shape',
      properties: {
        portname: 'Haimen',
        code: 'CNHME',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.79178,
        longitude: 121.09082,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 8400694,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14478',
      geometry: { type: 'Point', coordinates: [80.29178965, 13.08637595] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chennai',
        code: 'INMAA',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.08638,
        longitude: 80.29179,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1264527,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14480',
      geometry: { type: 'Point', coordinates: [17.45106088, -6.10839893] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -6.1084,
        longitude: 17.45106,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-26T06:45:00.498Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14483',
      geometry: { type: 'Point', coordinates: [-90.31017749, 29.9393455] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ama',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.93935,
        longitude: -90.31018,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4314712,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14484',
      geometry: { type: 'Point', coordinates: [76.93749856, 52.26504434] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Of Pavlodar',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.26504,
        longitude: 76.9375,
        iso3: 'KAZ',
        iso3_op: 'KAZ',
        country: 'Kazakhstan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1520240,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14485',
      geometry: { type: 'Point', coordinates: [-2.06355608, 47.30823024] },
      geometry_name: 'shape',
      properties: {
        portname: 'Montoir',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.30823,
        longitude: -2.06356,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2992206,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14486',
      geometry: { type: 'Point', coordinates: [16.5421, -4.99576613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kingushi Aval',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -4.99577,
        longitude: 16.5421,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2314366,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14488',
      geometry: { type: 'Point', coordinates: [-5.99628209, 37.35760691] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sevilla (Seville)',
        code: 'ESSVQ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.35761,
        longitude: -5.99628,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2510911,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14490',
      geometry: { type: 'Point', coordinates: [18.73044781, -4.67662427] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kikundi',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.67662,
        longitude: 18.73045,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2314775,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14491',
      geometry: { type: 'Point', coordinates: [-122.64345719, 47.55647115] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bremerton',
        code: 'USPWT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.55647,
        longitude: -122.64346,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5788054,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14493',
      geometry: { type: 'Point', coordinates: [107.08110728, 20.94840084] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hongai',
        code: 'VNHON',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.9484,
        longitude: 107.08111,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: 'Hon Gai',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1581168,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14494',
      geometry: { type: 'Point', coordinates: [22.39548046, -1.56328697] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wamba',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -1.56329,
        longitude: 22.39548,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 204319,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14495',
      geometry: { type: 'Point', coordinates: [20.4595, -0.27756613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malela',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -0.27757,
        longitude: 20.4595,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 216261,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14496',
      geometry: { type: 'Point', coordinates: [-73.67092686, 45.43194627] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lachine',
        code: 'CALAC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.43195,
        longitude: -73.67093,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6545041,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14497',
      geometry: { type: 'Point', coordinates: [-72.54409974, 46.33498447] },
      geometry_name: 'shape',
      properties: {
        portname: 'Trois-Rivieres (Three Rivers)',
        code: 'CATRR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.33498,
        longitude: -72.5441,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6169141,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14498',
      geometry: { type: 'Point', coordinates: [52.97818789, 67.64154305] },
      geometry_name: 'shape',
      properties: {
        portname: 'Naryan Mar',
        code: 'RUNNM',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 67.64154,
        longitude: 52.97819,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 523392,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14499',
      geometry: { type: 'Point', coordinates: [-53.07504018, 46.6599773] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cape Race',
        code: 'CACRE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.65998,
        longitude: -53.07504,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14500',
      geometry: { type: 'Point', coordinates: [7.10957513, 58.02451395] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lindesnes',
        code: 'NOLDS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.02451,
        longitude: 7.10958,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14501',
      geometry: { type: 'Point', coordinates: [15.41978127, 68.69851478] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sortland',
        code: 'NOSLX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 68.69851,
        longitude: 15.41978,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14502',
      geometry: { type: 'Point', coordinates: [6.81309215, 53.45566771] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eemshaven',
        code: 'NLEEM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.45567,
        longitude: 6.81309,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14503',
      geometry: { type: 'Point', coordinates: [33.17307056, 28.94411552] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Budran',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.94412,
        longitude: 33.17307,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 362208,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14504',
      geometry: { type: 'Point', coordinates: [140.68823988, 35.92849149] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kashima',
        code: 'JPKSM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.92849,
        longitude: 140.68824,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1860748,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14505',
      geometry: { type: 'Point', coordinates: [39.74008083, -3.95236899] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mtwapa',
        code: null,
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.95237,
        longitude: 39.74008,
        iso3: null,
        iso3_op: 'KEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 186301,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14506',
      geometry: { type: 'Point', coordinates: [35.19440749, 33.27516296] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sour',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.27516,
        longitude: 35.19441,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Commercial, Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 267008,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14507',
      geometry: { type: 'Point', coordinates: [25.93863138, 43.84212847] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ruse',
        code: 'BGRDU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.84213,
        longitude: 25.93863,
        iso3: 'BGR',
        iso3_op: 'ROU',
        country: 'Bulgaria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 727523,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14508',
      geometry: { type: 'Point', coordinates: [157.11374919, -8.12094858] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ringi Cove',
        code: 'SBRIN',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.12095,
        longitude: 157.11375,
        iso3: 'SLB',
        iso3_op: 'SLB',
        country: 'Solomon islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2107139,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14509',
      geometry: { type: 'Point', coordinates: [158.6540495, -7.77671311] },
      geometry_name: 'shape',
      properties: {
        portname: 'Allardyce Harbour',
        code: 'SBALB',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Closed',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.77671,
        longitude: 158.65405,
        iso3: 'SLB',
        iso3_op: 'SLB',
        country: 'Solomon islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 8439997,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14510',
      geometry: { type: 'Point', coordinates: [11.82691985, -15.80344479] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porto Alexandre',
        code: 'AOPLE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -15.80344,
        longitude: 11.82692,
        iso3: 'AGO',
        iso3_op: 'AGO',
        country: 'Angola',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3345790,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14511',
      geometry: { type: 'Point', coordinates: [12.19824305, -5.39637569] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malongo',
        code: 'AOMAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.39638,
        longitude: 12.19824,
        iso3: null,
        iso3_op: 'AGO',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2239852,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14512',
      geometry: { type: 'Point', coordinates: [-75.95280679, 21.0766218] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vita',
        code: 'CUVIT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.07662,
        longitude: -75.95281,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3542935,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14513',
      geometry: { type: 'Point', coordinates: [117.75317256, 38.9987542] },
      geometry_name: 'shape',
      properties: {
        portname: 'Xingang',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.99875,
        longitude: 117.75317,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1792947,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14514',
      geometry: { type: 'Point', coordinates: [-0.99902609, 46.31292129] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marans',
        code: 'FRMRN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.31292,
        longitude: -0.99903,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2996103,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14515',
      geometry: { type: 'Point', coordinates: [134.85506799, 34.7046955] },
      geometry_name: 'shape',
      properties: {
        portname: 'Higashi-Harima',
        code: 'JPTSG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.7047,
        longitude: 134.85507,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1862832,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14516',
      geometry: { type: 'Point', coordinates: [115.2433404, 5.27616231] },
      geometry_name: 'shape',
      properties: {
        portname: 'Labuan',
        code: 'MYLBU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.27616,
        longitude: 115.24334,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 7302815,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14517',
      geometry: { type: 'Point', coordinates: [9.50927827, 53.65255524] },
      geometry_name: 'shape',
      properties: {
        portname: 'Butzfleth',
        code: 'DEBUZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.65256,
        longitude: 9.50928,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2940487,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14518',
      geometry: { type: 'Point', coordinates: [9.69912244, 59.0539847] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brevik',
        code: 'NOBVK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.05398,
        longitude: 9.69912,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3160007,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14519',
      geometry: { type: 'Point', coordinates: [-71.80326416, 9.22312659] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coloncha',
        code: 'VECOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 9.22313,
        longitude: -71.80326,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3646933,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14520',
      geometry: { type: 'Point', coordinates: [-71.53717405, 10.75932604] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Tablazo / Maracaibo L',
        code: 'VEETV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.75933,
        longitude: -71.53717,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3789547,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14521',
      geometry: { type: 'Point', coordinates: [121.04326364, 13.75936006] },
      geometry_name: 'shape',
      properties: {
        portname: 'Balogo',
        code: 'PHBLG',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 13.75936,
        longitude: 121.04326,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1726119,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14522',
      geometry: { type: 'Point', coordinates: [126.85043968, 36.95050822] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pyongtaek',
        code: 'KRPTK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.95051,
        longitude: 126.85044,
        iso3: null,
        iso3_op: 'KOR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1838343,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14523',
      geometry: { type: 'Point', coordinates: [-69.35586702, 11.48838555] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cumarebo',
        code: 'VECMR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.48839,
        longitude: -69.35587,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3629696,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14524',
      geometry: { type: 'Point', coordinates: [-70.22181156, 11.71858137] },
      geometry_name: 'shape',
      properties: {
        portname: 'Las Piedras',
        code: 'VELSP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.71858,
        longitude: -70.22181,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3635464,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14525',
      geometry: { type: 'Point', coordinates: [-70.19536982, -20.74879531] },
      geometry_name: 'shape',
      properties: {
        portname: 'Patillos',
        code: 'CLPTI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.7488,
        longitude: -70.19537,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3875005,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14526',
      geometry: { type: 'Point', coordinates: [101.15214254, 12.66904039] },
      geometry_name: 'shape',
      properties: {
        portname: 'Map Ta Phut',
        code: 'THMAT',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.66904,
        longitude: 101.15214,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1616685,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14527',
      geometry: { type: 'Point', coordinates: [-4.88938262, 55.87591707] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wemyss Bay',
        code: 'GBWMB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.87592,
        longitude: -4.88938,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2634545,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14528',
      geometry: { type: 'Point', coordinates: [-4.35128697, 55.8694699] },
      geometry_name: 'shape',
      properties: {
        portname: 'Clydeport',
        code: 'GBCYP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.86947,
        longitude: -4.35129,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2652730,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14529',
      geometry: { type: 'Point', coordinates: [115.46166467, -20.78873052] },
      geometry_name: 'shape',
      properties: {
        portname: 'Barrow Island',
        code: 'AUBWB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.78873,
        longitude: 115.46166,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14530',
      geometry: { type: 'Point', coordinates: [13.04609154, 67.90357009] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moskenes',
        code: 'NOMSK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 67.90357,
        longitude: 13.04609,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14531',
      geometry: { type: 'Point', coordinates: [19.00223376, 69.5098571] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ramfjordnes',
        code: 'NORMJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 69.50986,
        longitude: 19.00223,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14532',
      geometry: { type: 'Point', coordinates: [-2.97256914, 53.37802996] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dingle',
        code: 'GBDIN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.37803,
        longitude: -2.97257,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14533',
      geometry: { type: 'Point', coordinates: [174.03564598, -39.05830526] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Taranaki',
        code: 'NZNPL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -39.05831,
        longitude: 174.03565,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14534',
      geometry: { type: 'Point', coordinates: [174.48896323, -35.83593955] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marsden Point',
        code: 'NZMAP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -35.83594,
        longitude: 174.48896,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14535',
      geometry: { type: 'Point', coordinates: [-4.47651381, 54.14730761] },
      geometry_name: 'shape',
      properties: {
        portname: 'Douglas',
        code: 'GBDGS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.14731,
        longitude: -4.47651,
        iso3: 'IMN',
        iso3_op: 'IMY',
        country: 'Isle of Man',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14536',
      geometry: { type: 'Point', coordinates: [4.10796839, 51.95621129] },
      geometry_name: 'shape',
      properties: {
        portname: 'Europoort (Europort)',
        code: 'NLEUR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.95621,
        longitude: 4.10797,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14537',
      geometry: { type: 'Point', coordinates: [-70.83029586, -52.95594127] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cabo Negro',
        code: 'CLCNX',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -52.95594,
        longitude: -70.8303,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3874787,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14538',
      geometry: { type: 'Point', coordinates: [118.05831995, 24.4077748] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zhangzhou',
        code: 'CNZZU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.40777,
        longitude: 118.05832,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1785018,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14539',
      geometry: { type: 'Point', coordinates: [69.70131524, 22.44433318] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vadinar',
        code: 'INVAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.44433,
        longitude: 69.70132,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1269317,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14540',
      geometry: { type: 'Point', coordinates: [75.76652875, 11.26474735] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kozhikode',
        code: 'INCCJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.26475,
        longitude: 75.76653,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1265873,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14541',
      geometry: { type: 'Point', coordinates: [136.13697201, 36.20391826] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fukui',
        code: 'JPFKJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.20392,
        longitude: 136.13697,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6822141,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14542',
      geometry: { type: 'Point', coordinates: [138.43509472, 38.08519867] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ryotsu',
        code: 'JPSDO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.0852,
        longitude: 138.43509,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6825492,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14543',
      geometry: { type: 'Point', coordinates: [-61.0116876, 13.98203709] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cul De Sac',
        code: 'LCCDS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.98204,
        longitude: -61.01169,
        iso3: 'LCA',
        iso3_op: 'LCA',
        country: 'Saint Lucia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3576812,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14544',
      geometry: { type: 'Point', coordinates: [152.8452301, -10.68796222] },
      geometry_name: 'shape',
      properties: {
        portname: 'Misima Island',
        code: 'PGMIS',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -10.68796,
        longitude: 152.84523,
        iso3: 'PNG',
        iso3_op: 'PNG',
        country: 'Papua New Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14545',
      geometry: { type: 'Point', coordinates: [11.66346634, 58.38151679] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skredsvik',
        code: 'SESKR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 58.38152,
        longitude: 11.66347,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14546',
      geometry: { type: 'Point', coordinates: [42.66979297, 15.31516269] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saleef Port',
        code: 'YESAL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.31516,
        longitude: 42.66979,
        iso3: 'YEM',
        iso3_op: 'YEM',
        country: 'Yemen',
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 77843,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14547',
      geometry: { type: 'Point', coordinates: [-68.83356198, 12.05588493] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fuikbaai',
        code: 'ANFUI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.05588,
        longitude: -68.83356,
        iso3: 'ANT',
        iso3_op: 'ANT',
        country: 'Netherlands Antilles',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3513445,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14548',
      geometry: { type: 'Point', coordinates: [56.05440536, 25.97175617] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mina Saqr',
        code: 'AEMSA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.97176,
        longitude: 56.05441,
        iso3: 'ARE',
        iso3_op: 'ARE',
        country: 'United Arab Emirates',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 291693,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14549',
      geometry: { type: 'Point', coordinates: [35.47341432, 33.88400424] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ed Daliah',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.884,
        longitude: 35.47341,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6275284,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14550',
      geometry: { type: 'Point', coordinates: [140.61034126, 36.42370398] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hitachinaka',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.4237,
        longitude: 140.61034,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6822096,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14552',
      geometry: { type: 'Point', coordinates: [43.47540416, -22.9570088] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manombo',
        code: 'MGMOB',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -22.95701,
        longitude: 43.4754,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1060595,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14553',
      geometry: { type: 'Point', coordinates: [135.43083775, 34.64160249] },
      geometry_name: 'shape',
      properties: {
        portname: 'Osaka',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.6416,
        longitude: 135.43084,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1853909,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14554',
      geometry: { type: 'Point', coordinates: [-77.60574475, 43.25258343] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rochester',
        code: 'USROC',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.25258,
        longitude: -77.60574,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5134096,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14556',
      geometry: { type: 'Point', coordinates: [29.02856111, -2.22421389] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kashofu I',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.22421,
        longitude: 29.02856,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 8435273,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14557',
      geometry: { type: 'Point', coordinates: [-58.75609756, 48.54146023] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aguathuna (Port Au Port)',
        code: 'CAPAP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.54146,
        longitude: -58.7561,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6111666,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14558',
      geometry: { type: 'Point', coordinates: [-122.7091432, 48.82638759] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ferndale',
        code: 'USFDT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.82639,
        longitude: -122.70914,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5794276,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14559',
      geometry: { type: 'Point', coordinates: [-64.69516172, 44.05227264] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brooklyn',
        code: 'CABKN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.05227,
        longitude: -64.69516,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5909491,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14560',
      geometry: { type: 'Point', coordinates: [137.78227757, -35.77151816] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ballast Head',
        code: 'AUBAH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -35.77152,
        longitude: 137.78228,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14561',
      geometry: { type: 'Point', coordinates: [-81.67583934, 52.22112455] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fort Albany',
        code: 'CAFAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.22112,
        longitude: -81.67584,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14562',
      geometry: { type: 'Point', coordinates: [-89.2189901, 48.43254384] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Arthur',
        code: 'CAPOA',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.43254,
        longitude: -89.21899,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14563',
      geometry: { type: 'Point', coordinates: [-68.47011122, 48.51689611] },
      geometry_name: 'shape',
      properties: {
        portname: 'Father Point',
        code: 'CAFAP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.5169,
        longitude: -68.47011,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14564',
      geometry: { type: 'Point', coordinates: [-85.62269373, 44.76622392] },
      geometry_name: 'shape',
      properties: {
        portname: 'Traver',
        code: 'USTVY',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.76622,
        longitude: -85.62269,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14565',
      geometry: { type: 'Point', coordinates: [-73.75109674, 42.62609269] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ramseur',
        code: 'USRSR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 42.62609,
        longitude: -73.7511,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14566',
      geometry: { type: 'Point', coordinates: [9.8787102, 63.31983183] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thamshavn',
        code: 'NOTHA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.31983,
        longitude: 9.87871,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14567',
      geometry: { type: 'Point', coordinates: [-3.16181748, 55.98038244] },
      geometry_name: 'shape',
      properties: {
        portname: 'Granton',
        code: 'GBGRN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.98038,
        longitude: -3.16182,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14568',
      geometry: { type: 'Point', coordinates: [-22.0375523, 64.04534163] },
      geometry_name: 'shape',
      properties: {
        portname: 'Straumsvik',
        code: 'ISSTR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.04534,
        longitude: -22.03755,
        iso3: 'ISL',
        iso3_op: 'ISL',
        country: 'Iceland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14569',
      geometry: { type: 'Point', coordinates: [33.03232389, 34.57053594] },
      geometry_name: 'shape',
      properties: {
        portname: 'Akrotiri',
        code: 'CYAKT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.57054,
        longitude: 33.03232,
        iso3: 'CYP',
        iso3_op: 'CYP',
        country: 'Cyprus',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14570',
      geometry: { type: 'Point', coordinates: [-15.68331392, 27.75726525] },
      geometry_name: 'shape',
      properties: {
        portname: 'Arguineguin',
        code: 'ESARI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.75727,
        longitude: -15.68331,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2515638,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14571',
      geometry: { type: 'Point', coordinates: [139.80021212, 35.6368281] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tokyo',
        code: 'JPTYO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.63683,
        longitude: 139.80021,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1850147,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14572',
      geometry: { type: 'Point', coordinates: [130.40854354, 33.60391001] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hakata',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.60391,
        longitude: 130.40854,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1863967,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14573',
      geometry: { type: 'Point', coordinates: [67.32649923, 24.76535491] },
      geometry_name: 'shape',
      properties: {
        portname: 'Muhammad Bin Qasim',
        code: 'PKBQM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.76535,
        longitude: 67.3265,
        iso3: null,
        iso3_op: 'PAK',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1174872,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14574',
      geometry: { type: 'Point', coordinates: [123.55671802, 10.82046398] },
      geometry_name: 'shape',
      properties: {
        portname: 'Barcelona',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.82046,
        longitude: 123.55672,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14575',
      geometry: { type: 'Point', coordinates: [28.39389881, 44.17919674] },
      geometry_name: 'shape',
      properties: {
        portname: 'Basarabi',
        code: 'ROBAB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.1792,
        longitude: 28.3939,
        iso3: 'ROU',
        iso3_op: 'ROU',
        country: 'Romania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 672620,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14576',
      geometry: { type: 'Point', coordinates: [16.6481, -6.10396613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Buka Ipangu',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -6.10397,
        longitude: 16.6481,
        iso3: 'AGO',
        iso3_op: 'AGO',
        country: 'Angola',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2238702,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14577',
      geometry: { type: 'Point', coordinates: [27.85798634, 42.17156297] },
      geometry_name: 'shape',
      properties: {
        portname: 'Michurin',
        code: 'BGMIC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.17156,
        longitude: 27.85799,
        iso3: 'BGR',
        iso3_op: 'BGR',
        country: 'Bulgaria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 729125,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14578',
      geometry: { type: 'Point', coordinates: [-14.61395961, 10.64948233] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Kamsar',
        code: 'GNKMR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.64948,
        longitude: -14.61396,
        iso3: 'GIN',
        iso3_op: 'GIN',
        country: 'Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2420056,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14579',
      geometry: { type: 'Point', coordinates: [19.86086229, -4.14248039] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dibaya',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -4.14248,
        longitude: 19.86086,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2316091,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14580',
      geometry: { type: 'Point', coordinates: [22.82536699, -4.11095923] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bena Dibele',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -4.11096,
        longitude: 22.82537,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 219071,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14581',
      geometry: { type: 'Point', coordinates: [21.01084143, -4.64903119] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bashishombe',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'approximate',
        latitude: -4.64903,
        longitude: 21.01084,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 8249904,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14582',
      geometry: { type: 'Point', coordinates: [20.98430242, -5.45786982] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mapangu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'approximate',
        latitude: -5.45787,
        longitude: 20.9843,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 8277276,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14583',
      geometry: { type: 'Point', coordinates: [30.4430686, 1.39310332] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kasenyi',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 1.3931,
        longitude: 30.44307,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 214034,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14584',
      geometry: { type: 'Point', coordinates: [31.24024288, 2.14211555] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mahagi',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 2.14212,
        longitude: 31.24024,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 210062,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14587',
      geometry: { type: 'Point', coordinates: [28.34162137, -9.28897612] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kilwa',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -9.28898,
        longitude: 28.34162,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 212987,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14588',
      geometry: { type: 'Point', coordinates: [16.52729936, -3.07378693] },
      geometry_name: 'shape',
      properties: {
        portname: 'Masia',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -3.07379,
        longitude: 16.5273,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2313013,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14589',
      geometry: { type: 'Point', coordinates: [18.62843813, 3.64042611] },
      geometry_name: 'shape',
      properties: {
        portname: 'Libenge',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 3.64043,
        longitude: 18.62844,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2313762,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14590',
      geometry: { type: 'Point', coordinates: [18.60014292, 4.3545842] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zongo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 4.35458,
        longitude: 18.60014,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2310763,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14591',
      geometry: { type: 'Point', coordinates: [22.22002006, -4.00570066] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kamba',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'approximate',
        latitude: -4.0057,
        longitude: 22.22002,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 214696,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14592',
      geometry: { type: 'Point', coordinates: [-82.34714181, 23.12360279] },
      geometry_name: 'shape',
      properties: {
        portname: 'Havana',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.1236,
        longitude: -82.34714,
        iso3: null,
        iso3_op: 'CUB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3553478,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14593',
      geometry: { type: 'Point', coordinates: [23.2285611, -4.63082438] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ibaka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -4.63082,
        longitude: 23.22856,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 216110,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14594',
      geometry: { type: 'Point', coordinates: [17.12901529, -4.55037333] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gabia',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'approximate',
        latitude: -4.55037,
        longitude: 17.12902,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2315782,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14595',
      geometry: { type: 'Point', coordinates: [16.57870356, -5.69572697] },
      geometry_name: 'shape',
      properties: {
        portname: 'Popokabaka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -5.69573,
        longitude: 16.5787,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2311675,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14596',
      geometry: { type: 'Point', coordinates: [28.6202505, -10.39208129] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kasenga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -10.39208,
        longitude: 28.62025,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 922966,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14597',
      geometry: { type: 'Point', coordinates: [23.85129877, -5.18554532] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pania-Mutombo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -5.18555,
        longitude: 23.8513,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 206396,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14598',
      geometry: { type: 'Point', coordinates: [20.87784077, 3.32727384] },
      geometry_name: 'shape',
      properties: {
        portname: 'Businga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 3.32727,
        longitude: 20.87784,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 217637,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14599',
      geometry: { type: 'Point', coordinates: [18.14202098, -2.73073498] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kutu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -2.73073,
        longitude: 18.14202,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2313874,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14600',
      geometry: { type: 'Point', coordinates: [0.98527321, 5.90743716] },
      geometry_name: 'shape',
      properties: {
        portname: 'Keta',
        code: 'GHKIT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.90744,
        longitude: 0.98527,
        iso3: 'GHA',
        iso3_op: 'GHA',
        country: 'Ghana',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2299645,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14601',
      geometry: { type: 'Point', coordinates: [80.23297583, 6.03219083] },
      geometry_name: 'shape',
      properties: {
        portname: 'Galle',
        code: 'LKGAL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.03219,
        longitude: 80.23298,
        iso3: null,
        iso3_op: 'LKA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1246294,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14602',
      geometry: { type: 'Point', coordinates: [-73.64318392, 18.50289556] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baraderes',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Closed',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.5029,
        longitude: -73.64318,
        iso3: 'HTI',
        iso3_op: 'HTI',
        country: 'Haiti',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3730800,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14603',
      geometry: { type: 'Point', coordinates: [3.02636542, 50.62585905] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lille',
        code: 'FRLLE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.62586,
        longitude: 3.02637,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2998324,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14604',
      geometry: { type: 'Point', coordinates: [-148.31394852, 70.31250127] },
      geometry_name: 'shape',
      properties: {
        portname: 'Prudhoe',
        code: 'USPUO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 70.3125,
        longitude: -148.31395,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5872126,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14605',
      geometry: { type: 'Point', coordinates: [-18.29607201, 65.02647182] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 65.02647,
        longitude: -18.29607,
        iso3: 'ISL',
        iso3_op: 'ISL',
        country: 'Iceland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14606',
      geometry: { type: 'Point', coordinates: [99.8559641, 6.31061353] },
      geometry_name: 'shape',
      properties: {
        portname: 'Langkawi',
        code: 'MYLGK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 6.31061,
        longitude: 99.85596,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14607',
      geometry: { type: 'Point', coordinates: [127.1977, -8.34346613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sevivara Point',
        code: 'IDSVP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -8.34347,
        longitude: 127.1977,
        iso3: 'TLS',
        iso3_op: 'TLS',
        country: 'Timor-Leste',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1942993,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14608',
      geometry: { type: 'Point', coordinates: [120.28510799, 22.60862552] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kaohsiung',
        code: 'TWKHH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.60863,
        longitude: 120.28511,
        iso3: 'TWN',
        iso3_op: 'CHN',
        country: 'Taiwan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1673820,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14609',
      geometry: { type: 'Point', coordinates: [-73.90320741, 40.9351635] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yonkers',
        code: 'USYON',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.93516,
        longitude: -73.90321,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5145215,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14610',
      geometry: { type: 'Point', coordinates: [125.15525468, 6.09409089] },
      geometry_name: 'shape',
      properties: {
        portname: 'General Santos',
        code: 'PHDAD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 6.09409,
        longitude: 125.15525,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1713022,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14611',
      geometry: { type: 'Point', coordinates: [21.71863988, 1.32375785] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kailanga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 1.32376,
        longitude: 21.71864,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 215211,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14612',
      geometry: { type: 'Point', coordinates: [30.22012627, 59.94857629] },
      geometry_name: 'shape',
      properties: {
        portname: 'St Petersburg',
        code: 'RULED',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.94858,
        longitude: 30.22013,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 498817,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14613',
      geometry: { type: 'Point', coordinates: [-88.60515831, 15.73405797] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Barrios',
        code: 'GTPBR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.73406,
        longitude: -88.60516,
        iso3: null,
        iso3_op: 'GTM',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3591062,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14614',
      geometry: { type: 'Point', coordinates: [-13.71249846, 9.51827571] },
      geometry_name: 'shape',
      properties: {
        portname: 'Conakry',
        code: 'GNCKY',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 9.51828,
        longitude: -13.7125,
        iso3: null,
        iso3_op: 'GIN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2422465,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14615',
      geometry: { type: 'Point', coordinates: [-72.53569629, 18.2317976] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jacmel',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.2318,
        longitude: -72.5357,
        iso3: 'HTI',
        iso3_op: 'HTI',
        country: 'Haiti',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3723779,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14616',
      geometry: { type: 'Point', coordinates: [-82.25585897, 26.74852792] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bocagrande',
        code: 'USBCG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 26.74853,
        longitude: -82.25586,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4148401,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14617',
      geometry: { type: 'Point', coordinates: [138.49723318, 35.00662477] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shimizu',
        code: 'JPSMZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.00662,
        longitude: 138.49723,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1852416,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14618',
      geometry: { type: 'Point', coordinates: [-64.75497492, 17.70080007] },
      geometry_name: 'shape',
      properties: {
        portname: 'Limetree Bay',
        code: 'VILIB',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.7008,
        longitude: -64.75497,
        iso3: null,
        iso3_op: 'VIR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4796042,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14619',
      geometry: { type: 'Point', coordinates: [-5.80022609, 54.84893479] },
      geometry_name: 'shape',
      properties: {
        portname: 'Larne',
        code: 'GBLAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.84893,
        longitude: -5.80023,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2644849,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14620',
      geometry: { type: 'Point', coordinates: [1.2407, 41.11093387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tarragona',
        code: 'ESTAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.11093,
        longitude: 1.2407,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3108288,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14621',
      geometry: { type: 'Point', coordinates: [24.71692189, 0.59753403] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yanonge',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.59753,
        longitude: 24.71692,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 203671,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14622',
      geometry: { type: 'Point', coordinates: [-61.74912886, 12.05017351] },
      geometry_name: 'shape',
      properties: {
        portname: "Saint George'S",
        code: 'GDSTG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.05017,
        longitude: -61.74913,
        iso3: 'GRD',
        iso3_op: 'GRD',
        country: 'Grenada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3579925,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14623',
      geometry: { type: 'Point', coordinates: [-122.33822867, 47.59378543] },
      geometry_name: 'shape',
      properties: {
        portname: 'Seattle',
        code: 'USSEA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.59379,
        longitude: -122.33823,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5815574,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14624',
      geometry: { type: 'Point', coordinates: [-7.16988369, 62.15336226] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vestmanhavn',
        code: 'FOVES',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 62.15336,
        longitude: -7.16988,
        iso3: 'FRO',
        iso3_op: 'FRO',
        country: 'Faroe Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2610343,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14625',
      geometry: { type: 'Point', coordinates: [25.43588227, 65.00016765] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oulu',
        code: 'FIOUL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.00017,
        longitude: 25.43588,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 643492,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14626',
      geometry: { type: 'Point', coordinates: [14.1304826, 66.31407188] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mo I Rana',
        code: 'NOMQN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 66.31407,
        longitude: 14.13048,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3145614,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14627',
      geometry: { type: 'Point', coordinates: [14.53760763, 35.81691268] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marsaxlokk',
        code: 'MTMAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.81691,
        longitude: 14.53761,
        iso3: 'MLT',
        iso3_op: 'MLT',
        country: 'Malta',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2562247,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14628',
      geometry: { type: 'Point', coordinates: [121.95043936, 11.92781925] },
      geometry_name: 'shape',
      properties: {
        portname: 'Caticlan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 11.92782,
        longitude: 121.95044,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1717886,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14629',
      geometry: { type: 'Point', coordinates: [26.11098054, 35.21252759] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sitia',
        code: 'GRJSH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.21253,
        longitude: 26.11098,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 253759,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14630',
      geometry: { type: 'Point', coordinates: [58.9238211, 23.25729535] },
      geometry_name: 'shape',
      properties: {
        portname: 'Qurayyat',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 23.2573,
        longitude: 58.92382,
        iso3: 'OMN',
        iso3_op: null,
        country: 'Oman',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 286839,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14631',
      geometry: { type: 'Point', coordinates: [10.12580787, 56.71706145] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hadsund',
        code: 'DKHSU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.71706,
        longitude: 10.12581,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2620952,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14632',
      geometry: { type: 'Point', coordinates: [10.42879025, 42.81354089] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rio Marina',
        code: 'ITRMA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.81354,
        longitude: 10.42879,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3169330,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14633',
      geometry: { type: 'Point', coordinates: [-3.36081071, 47.72968015] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lorient',
        code: 'FRLRT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.72968,
        longitude: -3.36081,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2997577,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14634',
      geometry: { type: 'Point', coordinates: [20.50903221, 2.39416322] },
      geometry_name: 'shape',
      properties: {
        portname: 'Binga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.39416,
        longitude: 20.50903,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 218855,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14635',
      geometry: { type: 'Point', coordinates: [13.12374576, 59.34148571] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gruvon',
        code: 'SEGRU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.34149,
        longitude: 13.12375,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2710226,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14636',
      geometry: { type: 'Point', coordinates: [5.29368497, 61.76961557] },
      geometry_name: 'shape',
      properties: {
        portname: 'Svelgen',
        code: 'NOSVE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.76962,
        longitude: 5.29368,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3135132,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14637',
      geometry: { type: 'Point', coordinates: [64.67432607, 25.2027644] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ormara',
        code: 'PKORW',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.20276,
        longitude: 64.67433,
        iso3: null,
        iso3_op: 'PAK',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1168700,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14638',
      geometry: { type: 'Point', coordinates: [35.92341661, 34.48355837] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Minie',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.48356,
        longitude: 35.92342,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 279396,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14639',
      geometry: { type: 'Point', coordinates: [10.69888542, 53.89635805] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lubeck',
        code: 'DELBC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.89636,
        longitude: 10.69889,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2875601,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14640',
      geometry: { type: 'Point', coordinates: [-8.44534182, 54.63133349] },
      geometry_name: 'shape',
      properties: {
        portname: 'Killybegs',
        code: 'IEKBS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.63133,
        longitude: -8.44534,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2963295,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14641',
      geometry: { type: 'Point', coordinates: [9.49647762, 55.24759929] },
      geometry_name: 'shape',
      properties: {
        portname: 'Haderslev',
        code: 'DKHAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.2476,
        longitude: 9.49648,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2620964,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14642',
      geometry: { type: 'Point', coordinates: [134.6236958, 34.78481184] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hirohata',
        code: 'JPHRH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.78481,
        longitude: 134.6237,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1862627,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14643',
      geometry: { type: 'Point', coordinates: [48.28193823, 28.86344702] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kuwait Navy Base',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.86345,
        longitude: 48.28194,
        iso3: 'KWT',
        iso3_op: 'KWT',
        country: 'Kuwait',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 285768,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14644',
      geometry: { type: 'Point', coordinates: [-80.42417725, -0.61084214] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bahia de Caraquez',
        code: 'ECBHA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.61084,
        longitude: -80.42418,
        iso3: 'ECU',
        iso3_op: 'ECU',
        country: 'Ecuador',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3660401,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14645',
      geometry: { type: 'Point', coordinates: [124.22256688, 7.35110742] },
      geometry_name: 'shape',
      properties: {
        portname: 'Polloc',
        code: 'PHPLC',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 7.35111,
        longitude: 124.22257,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1693112,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14646',
      geometry: { type: 'Point', coordinates: [49.18275828, -18.76998231] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vavony',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -18.76998,
        longitude: 49.18276,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1054187,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14647',
      geometry: { type: 'Point', coordinates: [-80.19719329, 42.78291234] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Dover',
        code: 'CAPDV',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.78291,
        longitude: -80.19719,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6111731,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14648',
      geometry: { type: 'Point', coordinates: [9.80337468, 56.63843524] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hobro',
        code: 'DKHBO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.63844,
        longitude: 9.80337,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2620167,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14649',
      geometry: { type: 'Point', coordinates: [55.37686628, 25.36268929] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sharjah',
        code: 'AESHJ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.36269,
        longitude: 55.37687,
        iso3: 'ARE',
        iso3_op: 'ARE',
        country: 'United Arab Emirates',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 292672,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14650',
      geometry: { type: 'Point', coordinates: [-89.96531196, 29.67435497] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alliance',
        code: 'USAIH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.67435,
        longitude: -89.96531,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4314617,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14651',
      geometry: { type: 'Point', coordinates: [23.00834636, 37.91644986] },
      geometry_name: 'shape',
      properties: {
        portname: 'Isthmia',
        code: 'GRITM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.91645,
        longitude: 23.00835,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 261721,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14652',
      geometry: { type: 'Point', coordinates: [2.42593346, 6.34929077] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cotonou',
        code: 'BJCOO',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 6.34929,
        longitude: 2.42593,
        iso3: 'BEN',
        iso3_op: 'MLI,NER',
        country: 'Benin',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2394819,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14653',
      geometry: { type: 'Point', coordinates: [-71.19491697, 46.78910851] },
      geometry_name: 'shape',
      properties: {
        portname: 'Levis',
        code: 'CALEV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.78911,
        longitude: -71.19492,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6325521,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14654',
      geometry: { type: 'Point', coordinates: [12.28200567, 44.4945166] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porto Corsini',
        code: 'ITPCS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.49452,
        longitude: 12.28201,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3170098,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14655',
      geometry: { type: 'Point', coordinates: [-57.60936101, 47.60924337] },
      geometry_name: 'shape',
      properties: {
        portname: 'Burgeo',
        code: 'CABUO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.60924,
        longitude: -57.60936,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5911440,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14656',
      geometry: { type: 'Point', coordinates: [23.32182024, 38.76343394] },
      geometry_name: 'shape',
      properties: {
        portname: 'Limni',
        code: 'GRLII',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.76343,
        longitude: 23.32182,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 258306,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14657',
      geometry: { type: 'Point', coordinates: [-8.32325222, 51.84788208] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cobh',
        code: 'IECOB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.84788,
        longitude: -8.32325,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2965260,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14658',
      geometry: { type: 'Point', coordinates: [8.92996281, 44.40205026] },
      geometry_name: 'shape',
      properties: {
        portname: 'Genova',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.40205,
        longitude: 8.92996,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3176219,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14659',
      geometry: { type: 'Point', coordinates: [7.46003765, 53.22294914] },
      geometry_name: 'shape',
      properties: {
        portname: 'Leer',
        code: 'DELEE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.22295,
        longitude: 7.46004,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2879697,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14660',
      geometry: { type: 'Point', coordinates: [132.13415623, 33.9566741] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yanai',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.95667,
        longitude: 132.13416,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1848550,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14661',
      geometry: { type: 'Point', coordinates: [129.4949777, 28.38602004] },
      geometry_name: 'shape',
      properties: {
        portname: 'Naze',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.38602,
        longitude: 129.49498,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1855540,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14662',
      geometry: { type: 'Point', coordinates: [49.84528088, 40.34485793] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baku',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.34486,
        longitude: 49.84528,
        iso3: 'AZE',
        iso3_op: 'AZE',
        country: 'Azerbaijan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 587084,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14663',
      geometry: { type: 'Point', coordinates: [-65.31996999, 43.75675708] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shelburne',
        code: 'CASBU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.75676,
        longitude: -65.31997,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6145887,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14664',
      geometry: { type: 'Point', coordinates: [41.42923259, 41.40556094] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hopa',
        code: 'TRHOP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.40556,
        longitude: 41.42923,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 745530,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14665',
      geometry: { type: 'Point', coordinates: [17.5066882, 62.50186774] },
      geometry_name: 'shape',
      properties: {
        portname: 'Soraker',
        code: 'SESOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 62.50187,
        longitude: 17.50669,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2675280,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14666',
      geometry: { type: 'Point', coordinates: [35.14311027, 33.12491133] },
      geometry_name: 'shape',
      properties: {
        portname: 'En Naqoura',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.12491,
        longitude: 35.14311,
        iso3: 'LBN',
        iso3_op: 'LBN',
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 278832,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14667',
      geometry: { type: 'Point', coordinates: [35.7158067, 34.31068674] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Heri',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.31069,
        longitude: 35.71581,
        iso3: 'LBN',
        iso3_op: 'LBN',
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 280001,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14668',
      geometry: { type: 'Point', coordinates: [-79.24546338, 42.87225665] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Colborne',
        code: 'CAPCO',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.87226,
        longitude: -79.24546,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6111704,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14669',
      geometry: { type: 'Point', coordinates: [16.52999736, -4.17277179] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pomoyo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -4.17277,
        longitude: 16.53,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2311686,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14670',
      geometry: { type: 'Point', coordinates: [-6.2858381, 36.53249399] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cadiz',
        code: 'ESCAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.53249,
        longitude: -6.28584,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2520600,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14671',
      geometry: { type: 'Point', coordinates: [21.40915535, -5.33897243] },
      geometry_name: 'shape',
      properties: {
        portname: 'Luebo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -5.33897,
        longitude: 21.40916,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 210939,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14672',
      geometry: { type: 'Point', coordinates: [-78.98234799, -8.22857065] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salaverry',
        code: 'PESVY',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.22857,
        longitude: -78.98235,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3692747,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14673',
      geometry: { type: 'Point', coordinates: [-81.1057678, -5.08470488] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paita',
        code: 'PEPAI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.0847,
        longitude: -81.10577,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3694112,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14674',
      geometry: { type: 'Point', coordinates: [-2.08906473, 47.64521095] },
      geometry_name: 'shape',
      properties: {
        portname: 'Redon',
        code: 'FRRDN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.64521,
        longitude: -2.08906,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2984192,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14675',
      geometry: { type: 'Point', coordinates: [48.32648559, -21.32497261] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ambalavontaka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -21.32497,
        longitude: 48.32649,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1083840,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14676',
      geometry: { type: 'Point', coordinates: [36.0924372, -18.39596715] },
      geometry_name: 'shape',
      properties: {
        portname: 'Luabo',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -18.39597,
        longitude: 36.09244,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Luabo: (I)-No Vessel Restriction- (Ii) -No Truck Access- (Iii) -Network Coverage- (Iv) -No Hazard To Navigation--Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1043458,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14677',
      geometry: { type: 'Point', coordinates: [-55.82975369, 47.07353942] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fortune',
        code: 'CAFOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.07354,
        longitude: -55.82975,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5955964,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14678',
      geometry: { type: 'Point', coordinates: [20.36470025, -4.32593417] },
      geometry_name: 'shape',
      properties: {
        portname: 'Basongo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -4.32593,
        longitude: 20.3647,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 219409,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14679',
      geometry: { type: 'Point', coordinates: [17.77737991, -0.60454667] },
      geometry_name: 'shape',
      properties: {
        portname: 'Irebu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -0.60455,
        longitude: 17.77738,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2315389,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14680',
      geometry: { type: 'Point', coordinates: [49.1978434, -18.7018368] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ampanotoamaizina',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -18.70184,
        longitude: 49.19784,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1078487,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14681',
      geometry: { type: 'Point', coordinates: [-93.8648354, 29.71910219] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sabine',
        code: 'USSAB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.7191,
        longitude: -93.86484,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4724542,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14682',
      geometry: { type: 'Point', coordinates: [-81.46334079, 30.67839887] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fernandina Beach',
        code: 'USFEB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.6784,
        longitude: -81.46334,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4155006,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14683',
      geometry: { type: 'Point', coordinates: [19.8051535, 1.22927061] },
      geometry_name: 'shape',
      properties: {
        portname: 'Basankusu',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 1.22927,
        longitude: 19.80515,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2317285,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14684',
      geometry: { type: 'Point', coordinates: [140.78378677, 36.82914191] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kitaibaraki',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.82914,
        longitude: 140.78379,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2112232,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14685',
      geometry: { type: 'Point', coordinates: [-96.5491297, 28.64405759] },
      geometry_name: 'shape',
      properties: {
        portname: 'Point Comfort',
        code: 'USPCR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.64406,
        longitude: -96.54913,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4719846,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14686',
      geometry: { type: 'Point', coordinates: [18.57278561, 42.45022371] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zelenika',
        code: 'YUZEL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.45022,
        longitude: 18.57279,
        iso3: 'MNE',
        iso3_op: 'MNE',
        country: 'Montenegro',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3186616,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14687',
      geometry: { type: 'Point', coordinates: [100.56578149, 13.70733823] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bangkok',
        code: 'THBKK',
        prttype: 'River',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.70734,
        longitude: 100.56578,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1609350,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14688',
      geometry: { type: 'Point', coordinates: [18.57872753, 4.35808302] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bangui',
        code: null,
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 4.35808,
        longitude: 18.57873,
        iso3: 'CAF',
        iso3_op: 'CAF',
        country: 'Central African Republic',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2389853,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14689',
      geometry: { type: 'Point', coordinates: [15.55271938, -4.05352426] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maluku',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -4.05352,
        longitude: 15.55272,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2313120,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14690',
      geometry: { type: 'Point', coordinates: [-73.89067987, 18.56787027] },
      geometry_name: 'shape',
      properties: {
        portname: 'Corail',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.56787,
        longitude: -73.89068,
        iso3: 'HTI',
        iso3_op: 'HTI',
        country: 'Haiti',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3727398,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14691',
      geometry: { type: 'Point', coordinates: [106.63215537, 17.45737049] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dong Hoi',
        code: 'VNDOH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.45737,
        longitude: 106.63216,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1582886,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14692',
      geometry: { type: 'Point', coordinates: [35.42614111, 33.69440054] },
      geometry_name: 'shape',
      properties: {
        portname: 'Es Saadiyat',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 33.6944,
        longitude: 35.42614,
        iso3: 'LBN',
        iso3_op: 'LBN',
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 278112,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14693',
      geometry: { type: 'Point', coordinates: [124.32699784, 10.15140516] },
      geometry_name: 'shape',
      properties: {
        portname: 'Talibon',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.15141,
        longitude: 124.327,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1683961,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15979',
      geometry: { type: 'Point', coordinates: [-7.72703391, 4.36885588] },
      geometry_name: 'shape',
      properties: {
        portname: 'Harper',
        code: 'LRHAR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.36886,
        longitude: -7.72703,
        iso3: null,
        iso3_op: 'LBR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2276492,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14694',
      geometry: { type: 'Point', coordinates: [18.40466062, 2.72481016] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dongo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 2.72481,
        longitude: 18.40466,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2316013,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14695',
      geometry: { type: 'Point', coordinates: [29.13003277, -3.43393974] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalundu',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -3.43394,
        longitude: 29.13003,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 214786,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14696',
      geometry: { type: 'Point', coordinates: [17.18377226, -1.05557031] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lukolela',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'approximate',
        latitude: -1.05557,
        longitude: 17.18377,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2313371,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14697',
      geometry: { type: 'Point', coordinates: [29.1296, -3.4418] },
      geometry_name: 'shape',
      properties: {
        portname: 'Uvira',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -3.4418,
        longitude: 29.1296,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: 'Altername: Kivovo',
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 204405,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14698',
      geometry: { type: 'Point', coordinates: [19.40103298, 1.78397324] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lusengo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 1.78397,
        longitude: 19.40103,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2313284,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14699',
      geometry: { type: 'Point', coordinates: [12.32715586, 37.9323396] },
      geometry_name: 'shape',
      properties: {
        portname: 'Favignana',
        code: 'ITFAV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.93234,
        longitude: 12.32716,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2524780,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14700',
      geometry: { type: 'Point', coordinates: [-1.09310816, 50.80806396] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portsmouth',
        code: 'GBPME',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.80806,
        longitude: -1.09311,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2639996,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14701',
      geometry: { type: 'Point', coordinates: [29.34554994, -3.37743256] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bujumbura',
        code: null,
        prttype: 'River',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: 182810,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.37743,
        longitude: 29.34555,
        iso3: 'BDI',
        iso3_op: 'BDI',
        country: 'Burundi',
        lastcheckdate: '2014-06-10T00:00:00Z',
        remarks: null,
        url_lca:
          'http://dlca.logcluster.org/display/public/DLCA/2.1+Burundi+Port+of+Bujumbura',
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 425378,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14702',
      geometry: { type: 'Point', coordinates: [18.38223611, 0.63911996] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lulonga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 0.63912,
        longitude: 18.38224,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2313339,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14703',
      geometry: { type: 'Point', coordinates: [49.00956664, -19.19447699] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antsiramihanana',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -19.19448,
        longitude: 49.00957,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1069130,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14704',
      geometry: { type: 'Point', coordinates: [8.30850329, 39.14698884] },
      geometry_name: 'shape',
      properties: {
        portname: 'Carloforte',
        code: 'ITCLF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.14699,
        longitude: 8.3085,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2525348,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14705',
      geometry: { type: 'Point', coordinates: [150.78931306, -2.58504027] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kavieng',
        code: 'PGKVG',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.58504,
        longitude: 150.78931,
        iso3: 'PNG',
        iso3_op: 'PNG',
        country: 'Papua New Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2094342,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14706',
      geometry: { type: 'Point', coordinates: [35.62566206, 34.03493592] },
      geometry_name: 'shape',
      properties: {
        portname: 'Es Safra',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.03494,
        longitude: 35.62566,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 278097,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14707',
      geometry: { type: 'Point', coordinates: [6.74899578, 7.79960919] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lokoja',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 7.79961,
        longitude: 6.749,
        iso3: 'NGA',
        iso3_op: 'NGA',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2331939,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14708',
      geometry: { type: 'Point', coordinates: [13.93969861, 66.81393468] },
      geometry_name: 'shape',
      properties: {
        portname: 'Glomfjord',
        code: 'NOGLO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 66.81393,
        longitude: 13.9397,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3155487,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14709',
      geometry: { type: 'Point', coordinates: [37.34272619, 19.11574282] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sawakin',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.11574,
        longitude: 37.34273,
        iso3: 'SDN',
        iso3_op: 'SDN',
        country: 'Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 367544,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14710',
      geometry: { type: 'Point', coordinates: [-60.74806836, 46.1001383] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baddeck',
        code: 'CABAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.10014,
        longitude: -60.74807,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5889156,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14711',
      geometry: { type: 'Point', coordinates: [-17.4251274, 14.68863286] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dakar',
        code: 'SNDKR',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: 15,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 14.68863,
        longitude: -17.42513,
        iso3: null,
        iso3_op: 'MLI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2253354,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14712',
      geometry: { type: 'Point', coordinates: [125.12507685, 10.15906706] },
      geometry_name: 'shape',
      properties: {
        portname: 'Liloan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 10.15907,
        longitude: 125.12508,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1706394,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14713',
      geometry: { type: 'Point', coordinates: [25.19399985, 0.50676543] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kisangani',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 0.50677,
        longitude: 25.194,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 212730,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14714',
      geometry: { type: 'Point', coordinates: [29.20112997, -5.94773511] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalemie',
        code: null,
        prttype: 'Lake',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -5.94774,
        longitude: 29.20113,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 214974,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14715',
      geometry: { type: 'Point', coordinates: [20.5696132, -4.33197643] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ilebo',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -4.33198,
        longitude: 20.56961,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 215976,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14716',
      geometry: { type: 'Point', coordinates: [47.93691352, 30.05037991] },
      geometry_name: 'shape',
      properties: {
        portname: 'Umm Qasr',
        code: 'IQUQR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 30.05038,
        longitude: 47.93691,
        iso3: 'IRQ',
        iso3_op: 'IRQ',
        country: 'Iraq',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 89824,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14717',
      geometry: { type: 'Point', coordinates: [16.22455936, -2.15393] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bolobo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -2.15393,
        longitude: 16.22456,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2316748,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14718',
      geometry: { type: 'Point', coordinates: [124.79862953, 10.67585675] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baybay',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 10.67586,
        longitude: 124.79863,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: '2013-11-30T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1725799,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14719',
      geometry: { type: 'Point', coordinates: [124.74379061, 10.37069984] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hilongos',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 10.3707,
        longitude: 124.74379,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: '2013-11-30T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1711742,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14720',
      geometry: { type: 'Point', coordinates: [44.27342393, -20.29035571] },
      geometry_name: 'shape',
      properties: {
        portname: 'Morondava',
        code: 'MGMOQ',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: 2,
        maxlength: 137,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.29036,
        longitude: 44.27342,
        iso3: null,
        iso3_op: 'MDG',
        country: null,
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1058381,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14721',
      geometry: { type: 'Point', coordinates: [-0.89768507, 16.99874513] },
      geometry_name: 'shape',
      properties: {
        portname: 'Temera',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 16.99875,
        longitude: -0.89769,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2449964,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14722',
      geometry: { type: 'Point', coordinates: [0.00569514, 5.6304246] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tema',
        code: 'GHTEM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 5.63042,
        longitude: 0.0057,
        iso3: 'GHA',
        iso3_op: 'MLI,NER',
        country: 'Ghana',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2294700,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14723',
      geometry: { type: 'Point', coordinates: [23.43443819, -4.97534325] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lusambo',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -4.97534,
        longitude: 23.43444,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 210379,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14724',
      geometry: { type: 'Point', coordinates: [15.32049298, -4.29837803] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kinshasa',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -4.29838,
        longitude: 15.32049,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2314302,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14725',
      geometry: { type: 'Point', coordinates: [28.00718287, 7.70904703] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wau',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 7.70905,
        longitude: 28.00718,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 363885,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14726',
      geometry: { type: 'Point', coordinates: [20.18482083, 2.37442281] },
      geometry_name: 'shape',
      properties: {
        portname: 'Akula',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 2.37442,
        longitude: 20.18482,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 220435,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14727',
      geometry: { type: 'Point', coordinates: [16.92354058, -3.02476118] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mushie',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -3.02476,
        longitude: 16.92354,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2312249,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14728',
      geometry: { type: 'Point', coordinates: [-6.98245632, 13.31304837] },
      geometry_name: 'shape',
      properties: {
        portname: 'Niamina',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.31305,
        longitude: -6.98246,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2452480,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14729',
      geometry: { type: 'Point', coordinates: [-16.91728484, 32.64166056] },
      geometry_name: 'shape',
      properties: {
        portname: 'Funchal',
        code: 'PTFNC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.64166,
        longitude: -16.91728,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Madeira Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2267827,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14730',
      geometry: { type: 'Point', coordinates: [35.98604771, 34.60731525] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cheikh Zennad',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.60732,
        longitude: 35.98605,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 278172,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14731',
      geometry: { type: 'Point', coordinates: [-6.07129489, 13.70401506] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kirango',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.70402,
        longitude: -6.07129,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6910525,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14732',
      geometry: { type: 'Point', coordinates: [-73.23909727, 45.85786563] },
      geometry_name: 'shape',
      properties: {
        portname: 'Contrecoeur',
        code: 'CACOC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.85787,
        longitude: -73.2391,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5927024,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14733',
      geometry: { type: 'Point', coordinates: [-4.01566731, 5.31056857] },
      geometry_name: 'shape',
      properties: {
        portname: 'Abidjan',
        code: 'CIABJ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 5.31057,
        longitude: -4.01567,
        iso3: 'CIV',
        iso3_op: 'CIV',
        country: "Côte d'Ivoire",
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2293538,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14734',
      geometry: { type: 'Point', coordinates: [45.33835749, 2.02372273] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mogadishu',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 2.02372,
        longitude: 45.33836,
        iso3: 'SOM',
        iso3_op: 'SOM',
        country: 'Somalia',
        lastcheckdate: '2013-11-07T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 53654,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14735',
      geometry: { type: 'Point', coordinates: [12.70784404, 59.05471711] },
      geometry_name: 'shape',
      properties: {
        portname: 'Amal',
        code: 'SEAMA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.05472,
        longitude: 12.70784,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2726240,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14736',
      geometry: { type: 'Point', coordinates: [1.2734373, 6.13796029] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lome',
        code: 'TGLFW',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 6.13796,
        longitude: 1.27344,
        iso3: 'TGO',
        iso3_op: 'TGO,MLI,NER',
        country: 'Togo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2365267,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14737',
      geometry: { type: 'Point', coordinates: [-10.79588745, 6.33922443] },
      geometry_name: 'shape',
      properties: {
        portname: 'Monrovia',
        code: 'LRMLW',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 6.33922,
        longitude: -10.79589,
        iso3: null,
        iso3_op: 'LBR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2274895,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14738',
      geometry: { type: 'Point', coordinates: [-13.21555812, 8.49312656] },
      geometry_name: 'shape',
      properties: {
        portname: 'Freetown',
        code: 'SLFNA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 8.49313,
        longitude: -13.21556,
        iso3: null,
        iso3_op: 'SLE',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2409306,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14739',
      geometry: { type: 'Point', coordinates: [-15.57692783, 11.85996173] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bissau',
        code: 'GWOXB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 11.85996,
        longitude: -15.57693,
        iso3: null,
        iso3_op: 'GNB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2374775,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14740',
      geometry: { type: 'Point', coordinates: [29.05244167, -1.999325] },
      geometry_name: 'shape',
      properties: {
        portname: 'Katonda',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -1.99933,
        longitude: 29.05244,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 213740,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14741',
      geometry: { type: 'Point', coordinates: [29.06153302, -2.03513992] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bugarura',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -2.03514,
        longitude: 29.06153,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 217852,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14742',
      geometry: { type: 'Point', coordinates: [124.35045249, 11.40488997] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Isidro',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 11.40489,
        longitude: 124.35045,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: '2013-12-10T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1689750,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14743',
      geometry: { type: 'Point', coordinates: [44.99735097, 10.43780536] },
      geometry_name: 'shape',
      properties: {
        portname: 'Berbera',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 10.43781,
        longitude: 44.99735,
        iso3: null,
        iso3_op: 'SOM,YEM, ETH',
        country: null,
        lastcheckdate: '2013-11-07T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 64435,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14744',
      geometry: { type: 'Point', coordinates: [125.45457009, 9.81532573] },
      geometry_name: 'shape',
      properties: {
        portname: 'Surigao',
        code: 'PHSUG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 9.81533,
        longitude: 125.45457,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1685218,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14745',
      geometry: { type: 'Point', coordinates: [29.09116667, -1.94095556] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kiumba',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -1.94096,
        longitude: 29.09117,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 212519,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14746',
      geometry: { type: 'Point', coordinates: [4.18935039, 50.4963349] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Louviere',
        code: 'BELLO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.49633,
        longitude: 4.18935,
        iso3: 'BEL',
        iso3_op: 'BEL',
        country: 'Belgium',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2793508,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14747',
      geometry: { type: 'Point', coordinates: [7.70850976, 61.23498033] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ardalstangen',
        code: 'NOARD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.23498,
        longitude: 7.70851,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3162970,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14748',
      geometry: { type: 'Point', coordinates: [-75.532945, 10.40574738] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cartagena',
        code: 'COCTG',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 10.40575,
        longitude: -75.53294,
        iso3: null,
        iso3_op: 'COL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3687238,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14749',
      geometry: { type: 'Point', coordinates: [98.71622715, 3.79246347] },
      geometry_name: 'shape',
      properties: {
        portname: 'Belawan',
        code: 'IDBLW',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 3.79246,
        longitude: 98.71623,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1215412,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14750',
      geometry: { type: 'Point', coordinates: [123.06938335, 0.49763503] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gorontalo',
        code: 'IDGTO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.49764,
        longitude: 123.06938,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1643837,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14751',
      geometry: { type: 'Point', coordinates: [-16.46727593, 14.13010416] },
      geometry_name: 'shape',
      properties: {
        portname: 'Foundiougne',
        code: 'SNFOU',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.1301,
        longitude: -16.46728,
        iso3: 'SEN',
        iso3_op: 'SEN',
        country: 'Senegal',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2251791,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14752',
      geometry: { type: 'Point', coordinates: [49.24861515, -18.59748729] },
      geometry_name: 'shape',
      properties: {
        portname: 'Andranokoditra',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -18.59749,
        longitude: 49.24862,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1075296,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14753',
      geometry: { type: 'Point', coordinates: [6.75225134, 51.21650039] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dusseldorf',
        code: 'DEDUS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.2165,
        longitude: 6.75225,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2934246,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14754',
      geometry: { type: 'Point', coordinates: [28.85054795, -2.49332426] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bukavu',
        code: null,
        prttype: 'Lake',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -2.49332,
        longitude: 28.85055,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 217831,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14755',
      geometry: { type: 'Point', coordinates: [29.22245339, -1.68590283] },
      geometry_name: 'shape',
      properties: {
        portname: 'Goma',
        code: null,
        prttype: 'Lake',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -1.6859,
        longitude: 29.22245,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 216281,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14756',
      geometry: { type: 'Point', coordinates: [50.00626431, -13.354195] },
      geometry_name: 'shape',
      properties: {
        portname: 'Iharana',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: 6,
        maxlength: 100,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -13.3542,
        longitude: 50.00626,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: 'Alt name: Vohemar',
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1064311,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14757',
      geometry: { type: 'Point', coordinates: [30.74853749, 46.48652489] },
      geometry_name: 'shape',
      properties: {
        portname: 'Odessa',
        code: 'UAODS',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 46.48652,
        longitude: 30.74854,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 698740,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14758',
      geometry: { type: 'Point', coordinates: [32.66325481, 46.65935837] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kherson',
        code: 'UAKHE',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 46.65936,
        longitude: 32.66325,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 706448,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14759',
      geometry: { type: 'Point', coordinates: [34.17137193, 44.49546722] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yalta',
        code: 'UAYAL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 44.49547,
        longitude: 34.17137,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 688533,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14760',
      geometry: { type: 'Point', coordinates: [36.4814504, 45.3615599] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kerch',
        code: 'UAKEH',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 45.36156,
        longitude: 36.48145,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 706524,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14761',
      geometry: { type: 'Point', coordinates: [28.28888086, 45.42038877] },
      geometry_name: 'shape',
      properties: {
        portname: 'Reni',
        code: 'UARNI',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 45.42039,
        longitude: 28.28888,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 695670,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14762',
      geometry: { type: 'Point', coordinates: [37.57886409, 47.09612996] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mariupol',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 47.09613,
        longitude: 37.57886,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-03T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 701824,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14763',
      geometry: { type: 'Point', coordinates: [107.07185167, 10.33871081] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vung Tau',
        code: 'VNVUT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.33871,
        longitude: 107.07185,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1562414,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14764',
      geometry: { type: 'Point', coordinates: [-80.94518968, 44.58452472] },
      geometry_name: 'shape',
      properties: {
        portname: 'Owen Sound',
        code: 'CAOWS',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.58452,
        longitude: -80.94519,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6095645,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14765',
      geometry: { type: 'Point', coordinates: [-80.03586073, 45.33900542] },
      geometry_name: 'shape',
      properties: {
        portname: 'Parry Sound',
        code: 'CAPRS',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.33901,
        longitude: -80.03586,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6098747,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14766',
      geometry: { type: 'Point', coordinates: [-79.21533292, 43.22714282] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Weller',
        code: 'CAPWE',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.22714,
        longitude: -79.21533,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6112052,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14767',
      geometry: { type: 'Point', coordinates: [-79.91489288, 9.26823421] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gatun',
        code: 'PAGTN',
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.26823,
        longitude: -79.91489,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3709294,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14768',
      geometry: { type: 'Point', coordinates: [17.93300766, -4.20396139] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mushuni',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.20396,
        longitude: 17.93301,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2312243,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14769',
      geometry: { type: 'Point', coordinates: [20.44252933, -1.50698798] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wafania',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.50699,
        longitude: 20.44253,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 204356,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14770',
      geometry: { type: 'Point', coordinates: [-67.56091009, 48.83753914] },
      geometry_name: 'shape',
      properties: {
        portname: 'Matane',
        code: 'CAYME',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.83754,
        longitude: -67.56091,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6696260,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14771',
      geometry: { type: 'Point', coordinates: [21.25720584, -0.75335631] },
      geometry_name: 'shape',
      properties: {
        portname: 'Isongo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -0.75336,
        longitude: 21.25721,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 215763,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14772',
      geometry: { type: 'Point', coordinates: [11.4504058, 63.78489996] },
      geometry_name: 'shape',
      properties: {
        portname: 'Verdal',
        code: 'NOVER',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.7849,
        longitude: 11.45041,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3132176,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14773',
      geometry: { type: 'Point', coordinates: [-129.99210621, 55.92517523] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stewart',
        code: 'CASTW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.92518,
        longitude: -129.99211,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6156427,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14774',
      geometry: { type: 'Point', coordinates: [16.24109314, -2.64175888] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tshumbiri',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -2.64176,
        longitude: 16.24109,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2311078,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14775',
      geometry: { type: 'Point', coordinates: [20.89144781, -4.11383881] },
      geometry_name: 'shape',
      properties: {
        portname: 'Butala',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -4.11384,
        longitude: 20.89145,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 217567,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14776',
      geometry: { type: 'Point', coordinates: [18.90355877, -3.70276316] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eolo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -3.70276,
        longitude: 18.90356,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2315868,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14777',
      geometry: { type: 'Point', coordinates: [49.27788488, -18.52480308] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tampina',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -18.5248,
        longitude: 49.27788,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1055927,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14778',
      geometry: { type: 'Point', coordinates: [48.94761938, -19.24633957] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marovintsy',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -19.24634,
        longitude: 48.94762,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1059543,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14779',
      geometry: { type: 'Point', coordinates: [16.56654073, -1.88791932] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yumbi',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -1.88792,
        longitude: 16.56654,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2310820,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14780',
      geometry: { type: 'Point', coordinates: [19.76289451, 1.88886883] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mobeka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 1.88887,
        longitude: 19.76289,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2312723,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14781',
      geometry: { type: 'Point', coordinates: [122.58278328, 10.69083402] },
      geometry_name: 'shape',
      properties: {
        portname: 'Iloilo',
        code: 'PHCRT',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 10.69083,
        longitude: 122.58278,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1711005,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14782',
      geometry: { type: 'Point', coordinates: [45.00747966, 12.79654735] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aden',
        code: 'YEADE',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 12.79655,
        longitude: 45.00748,
        iso3: null,
        iso3_op: 'YEM',
        country: null,
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 415189,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14783',
      geometry: { type: 'Point', coordinates: [19.57509074, -4.05108682] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mangai',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -4.05109,
        longitude: 19.57509,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2313083,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14784',
      geometry: { type: 'Point', coordinates: [21.3352815, -4.02604549] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lodi',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -4.02605,
        longitude: 21.33528,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 211649,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14785',
      geometry: { type: 'Point', coordinates: [21.67708163, -4.02722814] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bokila',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'unknown',
        latitude: -4.02723,
        longitude: 21.67708,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 218545,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14786',
      geometry: { type: 'Point', coordinates: [21.23904209, 64.62200024] },
      geometry_name: 'shape',
      properties: {
        portname: 'Burea',
        code: 'SEBUR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.622,
        longitude: 21.23904,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 606370,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14787',
      geometry: { type: 'Point', coordinates: [0.36504104, 49.76122907] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fecamp',
        code: 'FRFEC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.76123,
        longitude: 0.36504,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3018794,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14788',
      geometry: { type: 'Point', coordinates: [-16.57261039, 13.44666389] },
      geometry_name: 'shape',
      properties: {
        portname: 'Banjul',
        code: 'GMBJL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 13.44666,
        longitude: -16.57261,
        iso3: null,
        iso3_op: 'GMB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2413876,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14789',
      geometry: { type: 'Point', coordinates: [-74.90792113, 20.6544142] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moa',
        code: 'CUMOA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 20.65441,
        longitude: -74.90792,
        iso3: 'CUB',
        iso3_op: null,
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3546791,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14790',
      geometry: { type: 'Point', coordinates: [-77.32129556, 19.90243924] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pilon',
        code: 'CUPIL',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.90244,
        longitude: -77.3213,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3544171,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14791',
      geometry: { type: 'Point', coordinates: [35.8313, 34.45253387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tripoli',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.45253,
        longitude: 35.8313,
        iso3: 'LBN',
        iso3_op: 'LBN,SYR',
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: 'Commercial, Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 266826,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14792',
      geometry: { type: 'Point', coordinates: [35.76540093, 35.50997523] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lattakia',
        code: 'SYLTK',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 35.50998,
        longitude: 35.7654,
        iso3: null,
        iso3_op: 'SYR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 173576,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14793',
      geometry: { type: 'Point', coordinates: [35.87337206, 34.90932814] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tartous',
        code: 'SYTTS',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 34.90933,
        longitude: 35.87337,
        iso3: null,
        iso3_op: 'SYR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 163345,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14794',
      geometry: { type: 'Point', coordinates: [-2.98520717, 16.70479243] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kabara',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 16.70479,
        longitude: -2.98521,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'Timbuktu',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2456013,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14795',
      geometry: { type: 'Point', coordinates: [-4.20005074, 14.49752269] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mopti',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 14.49752,
        longitude: -4.20005,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2453348,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14796',
      geometry: { type: 'Point', coordinates: [43.66441987, -23.37751802] },
      geometry_name: 'shape',
      properties: {
        portname: 'Toliara',
        code: 'MGTLE',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: 8,
        maxlength: 313,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -23.37752,
        longitude: 43.66442,
        iso3: null,
        iso3_op: 'MDG',
        country: null,
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1055429,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14797',
      geometry: { type: 'Point', coordinates: [49.4265689, -18.15438665] },
      geometry_name: 'shape',
      properties: {
        portname: 'Toamasina',
        code: 'MGTMM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: 12,
        maxlength: 1020,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -18.15439,
        longitude: 49.42657,
        iso3: null,
        iso3_op: 'MDG',
        country: null,
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1053384,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14798',
      geometry: { type: 'Point', coordinates: [35.51783762, 33.9015091] },
      geometry_name: 'shape',
      properties: {
        portname: 'Beirut',
        code: null,
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 33.90151,
        longitude: 35.51784,
        iso3: 'LBN',
        iso3_op: 'SYR',
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: 'Commercial',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 276781,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14799',
      geometry: { type: 'Point', coordinates: [-7.55752087, 12.85769273] },
      geometry_name: 'shape',
      properties: {
        portname: 'Koulikoro',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.85769,
        longitude: -7.55752,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2454530,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14800',
      geometry: { type: 'Point', coordinates: [30.32082756, 8.18492189] },
      geometry_name: 'shape',
      properties: {
        portname: 'Adok',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 8.18492,
        longitude: 30.32083,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 380086,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14801',
      geometry: { type: 'Point', coordinates: [29.80528141, 9.2906734] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bentiu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 9.29067,
        longitude: 29.80528,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 377472,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14802',
      geometry: { type: 'Point', coordinates: [31.55338489, 6.20395902] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bor',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 6.20396,
        longitude: 31.55338,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 377241,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14803',
      geometry: { type: 'Point', coordinates: [31.61431789, 4.83060122] },
      geometry_name: 'shape',
      properties: {
        portname: 'Juba',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 4.8306,
        longitude: 31.61432,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 373303,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14804',
      geometry: { type: 'Point', coordinates: [31.65087057, 9.52636336] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malakal',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 9.52636,
        longitude: 31.65087,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 370737,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14805',
      geometry: { type: 'Point', coordinates: [34.99603177, 29.51307774] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aqaba',
        code: 'JOAQJ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 29.51308,
        longitude: 34.99603,
        iso3: null,
        iso3_op: 'SYR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 250774,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14806',
      geometry: { type: 'Point', coordinates: [21.63578149, -0.4790545] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wema',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.47905,
        longitude: 21.63578,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 204257,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14807',
      geometry: { type: 'Point', coordinates: [23.61932166, 2.64322765] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ibembo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.64323,
        longitude: 23.61932,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 216101,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14808',
      geometry: { type: 'Point', coordinates: [18.45502504, -2.90273573] },
      geometry_name: 'shape',
      properties: {
        portname: 'Muntu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.90274,
        longitude: 18.45503,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2312266,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14809',
      geometry: { type: 'Point', coordinates: [22.7951, -1.85786613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Botende',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -1.85787,
        longitude: 22.7951,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 218032,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14810',
      geometry: { type: 'Point', coordinates: [150.14768071, -5.54959734] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kimbe',
        code: 'PGKIM',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.5496,
        longitude: 150.14768,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2093967,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14811',
      geometry: { type: 'Point', coordinates: [142.35151556, -3.14373076] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aitape',
        code: 'PGATP',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.14373,
        longitude: 142.35152,
        iso3: 'PNG',
        iso3_op: 'PNG',
        country: 'Papua New Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2101169,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14812',
      geometry: { type: 'Point', coordinates: [39.64961632, -4.05996646] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mombasa',
        code: 'KEMBA',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -4.05997,
        longitude: 39.64962,
        iso3: null,
        iso3_op: 'KEN,COD,UGA,SSD,SOM,ETH',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 186301,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14813',
      geometry: { type: 'Point', coordinates: [28.89420125, 40.98117731] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zeytinburnu',
        code: 'TRZEY',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.98118,
        longitude: 28.8942,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 737071,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14814',
      geometry: { type: 'Point', coordinates: [-73.74865666, 18.19029983] },
      geometry_name: 'shape',
      properties: {
        portname: 'Les Cayes',
        code: 'HTACA',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.1903,
        longitude: -73.74866,
        iso3: 'HTI',
        iso3_op: 'HTI',
        country: 'Haiti',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3728097,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14815',
      geometry: { type: 'Point', coordinates: [-87.8977, 43.01863387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Milwaukee',
        code: 'USMKE',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.01863,
        longitude: -87.8977,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5263045,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14816',
      geometry: { type: 'Point', coordinates: [-87.86688212, 43.38835417] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Washington',
        code: 'USPWA',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.38835,
        longitude: -87.86688,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5267776,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14817',
      geometry: { type: 'Point', coordinates: [-76.51721271, 43.46273487] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oswego',
        code: 'USOSW',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.46273,
        longitude: -76.51721,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5130081,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14818',
      geometry: { type: 'Point', coordinates: [-124.18244925, 41.74689962] },
      geometry_name: 'shape',
      properties: {
        portname: 'Crescent City',
        code: 'USCEC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.7469,
        longitude: -124.18245,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5562087,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14819',
      geometry: { type: 'Point', coordinates: [-68.80071624, 44.57406541] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bucksport',
        code: 'USBUP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.57407,
        longitude: -68.80072,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4959551,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14820',
      geometry: { type: 'Point', coordinates: [-54.28425929, 49.40496913] },
      geometry_name: 'shape',
      properties: {
        portname: 'Carmanville',
        code: 'CACMV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.40497,
        longitude: -54.28426,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5917294,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14821',
      geometry: { type: 'Point', coordinates: [-123.17109134, 49.68615688] },
      geometry_name: 'shape',
      properties: {
        portname: 'Squamish',
        code: 'CASQA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.68616,
        longitude: -123.17109,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6154636,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14822',
      geometry: { type: 'Point', coordinates: [-166.55334081, 53.87115055] },
      geometry_name: 'shape',
      properties: {
        portname: 'Unalaska',
        code: 'USUAA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.87115,
        longitude: -166.55334,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5877208,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14823',
      geometry: { type: 'Point', coordinates: [-166.5367381, 53.89044496] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dutch Harbor',
        code: 'USDUT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.89044,
        longitude: -166.53674,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5861117,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14824',
      geometry: { type: 'Point', coordinates: [-93.88166055, 29.98026718] },
      geometry_name: 'shape',
      properties: {
        portname: 'Atreco',
        code: 'USATR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.98027,
        longitude: -93.88166,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4671603,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14825',
      geometry: { type: 'Point', coordinates: [-93.25289761, 30.21029363] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lake Charles',
        code: 'USLCH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.21029,
        longitude: -93.2529,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4330236,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14826',
      geometry: { type: 'Point', coordinates: [-91.2235016, 30.283194] },
      geometry_name: 'shape',
      properties: {
        portname: 'Plaquemine',
        code: 'USPLQ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.28319,
        longitude: -91.2235,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4337436,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14827',
      geometry: { type: 'Point', coordinates: [-77.95081166, 34.24448192] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wilmington',
        code: 'USILM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.24448,
        longitude: -77.95081,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4499379,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14828',
      geometry: { type: 'Point', coordinates: [-124.20106214, 43.36045857] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coos Bay',
        code: 'USCOB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.36046,
        longitude: -124.20106,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5720495,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14829',
      geometry: { type: 'Point', coordinates: [-73.55446873, 45.50597969] },
      geometry_name: 'shape',
      properties: {
        portname: 'Montreal',
        code: 'CAMTR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.50598,
        longitude: -73.55447,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6077243,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14830',
      geometry: { type: 'Point', coordinates: [-60.19995596, 46.14201483] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sydney',
        code: 'CASYD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.14201,
        longitude: -60.19996,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6160752,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14831',
      geometry: { type: 'Point', coordinates: [-65.56251965, 46.99998545] },
      geometry_name: 'shape',
      properties: {
        portname: 'Newcastle',
        code: 'CANCT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.99999,
        longitude: -65.56252,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6087456,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14832',
      geometry: { type: 'Point', coordinates: [-53.81836379, 47.42380704] },
      geometry_name: 'shape',
      properties: {
        portname: 'Long Harbour',
        code: 'CALOH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.42381,
        longitude: -53.81836,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6058972,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14833',
      geometry: { type: 'Point', coordinates: [-81.49227778, 31.13100592] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brunswick',
        code: 'USSSI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.13101,
        longitude: -81.49228,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4184845,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14834',
      geometry: { type: 'Point', coordinates: [-76.48021113, 38.98251941] },
      geometry_name: 'shape',
      properties: {
        portname: 'Annapolis',
        code: 'USANP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.98252,
        longitude: -76.48021,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4347242,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14835',
      geometry: { type: 'Point', coordinates: [-64.7143648, 44.04121448] },
      geometry_name: 'shape',
      properties: {
        portname: 'Liverpool',
        code: 'CALIV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.04121,
        longitude: -64.71436,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6057856,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14836',
      geometry: { type: 'Point', coordinates: [-75.68674877, 44.58557808] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brockville',
        code: 'CABCK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.58558,
        longitude: -75.68675,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5909294,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14837',
      geometry: { type: 'Point', coordinates: [-68.78088836, 44.78090137] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bangor',
        code: 'USBGR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.7809,
        longitude: -68.78089,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4957280,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14838',
      geometry: { type: 'Point', coordinates: [-72.38053658, 46.40246135] },
      geometry_name: 'shape',
      properties: {
        portname: 'Becancour',
        code: 'CABEC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.40246,
        longitude: -72.38054,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5897209,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14839',
      geometry: { type: 'Point', coordinates: [-122.69529825, 47.64433387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Silverdale',
        code: 'USSIF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.64433,
        longitude: -122.6953,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5810490,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14840',
      geometry: { type: 'Point', coordinates: [-123.63869249, 48.76042323] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cowichan Bay',
        code: 'CACCB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.76042,
        longitude: -123.63869,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 7870334,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14841',
      geometry: { type: 'Point', coordinates: [-55.05327575, 49.2469531] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lewisporte',
        code: 'CALWP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.24695,
        longitude: -55.05328,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6053446,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14842',
      geometry: { type: 'Point', coordinates: [-125.24202321, 50.02730247] },
      geometry_name: 'shape',
      properties: {
        portname: 'Campbell River',
        code: 'CACAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.0273,
        longitude: -125.24202,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5914132,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14843',
      geometry: { type: 'Point', coordinates: [-123.46101282, 48.13011389] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Angeles',
        code: 'USCLM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.13011,
        longitude: -123.46101,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5807212,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14844',
      geometry: { type: 'Point', coordinates: [-5.12597464, 55.53554609] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lamlash',
        code: 'GBLAH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.53555,
        longitude: -5.12597,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2644984,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14845',
      geometry: { type: 'Point', coordinates: [-3.23193742, 56.05716971] },
      geometry_name: 'shape',
      properties: {
        portname: 'Burntisland',
        code: 'GBBTL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.05717,
        longitude: -3.23194,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2654255,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14846',
      geometry: { type: 'Point', coordinates: [43.75326991, -12.29011464] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fomboni',
        code: 'KMFOU',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.29011,
        longitude: 43.75327,
        iso3: 'COM',
        iso3_op: 'COM',
        country: 'Comoros',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 921889,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14847',
      geometry: { type: 'Point', coordinates: [-6.65834772, 55.20727221] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portrush',
        code: 'GBPTR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.20727,
        longitude: -6.65835,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2640004,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14848',
      geometry: { type: 'Point', coordinates: [26.54462429, 37.32355072] },
      geometry_name: 'shape',
      properties: {
        portname: 'Patmos',
        code: 'GRPMS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.32355,
        longitude: 26.54462,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 255688,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14849',
      geometry: { type: 'Point', coordinates: [12.27652646, 45.22449762] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chioggia',
        code: 'ITCHI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.2245,
        longitude: 12.27653,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3178784,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14850',
      geometry: { type: 'Point', coordinates: [13.76333547, 45.6480433] },
      geometry_name: 'shape',
      properties: {
        portname: 'Trieste',
        code: 'ITTRS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.64804,
        longitude: 13.76334,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3165185,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14851',
      geometry: { type: 'Point', coordinates: [11.22508788, 54.50216291] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puttgarden',
        code: 'DEPUT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.50216,
        longitude: 11.22509,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2851594,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14852',
      geometry: { type: 'Point', coordinates: [13.64984657, 54.5152015] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sassnitz',
        code: 'DESAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.5152,
        longitude: 13.64985,
        iso3: null,
        iso3_op: 'DEU',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2841333,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14853',
      geometry: { type: 'Point', coordinates: [-80.55082469, 41.9691062] },
      geometry_name: 'shape',
      properties: {
        portname: 'Conneaut',
        code: 'USCDY',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.96911,
        longitude: -80.55082,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5150986,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14854',
      geometry: { type: 'Point', coordinates: [-83.07667203, 42.30510911] },
      geometry_name: 'shape',
      properties: {
        portname: 'Windsor',
        code: 'CAWND',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.30511,
        longitude: -83.07667,
        iso3: 'USA',
        iso3_op: 'CAN',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6182962,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14855',
      geometry: { type: 'Point', coordinates: [-77.78956636, 34.21483705] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wrightsville',
        code: 'USXOG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.21484,
        longitude: -77.78957,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4500091,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14856',
      geometry: { type: 'Point', coordinates: [-75.11968218, 38.78215395] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lewes',
        code: 'USLWP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.78215,
        longitude: -75.11968,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4143354,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14857',
      geometry: { type: 'Point', coordinates: [1.31883968, 36.52145511] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tenes',
        code: 'DZTEN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.52146,
        longitude: 1.31884,
        iso3: 'DZA',
        iso3_op: 'DZA',
        country: 'Algeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2477148,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14858',
      geometry: { type: 'Point', coordinates: [10.49314218, 59.41479717] },
      geometry_name: 'shape',
      properties: {
        portname: 'Horten',
        code: 'NOHOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.4148,
        longitude: 10.49314,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3151770,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14859',
      geometry: { type: 'Point', coordinates: [10.49802285, 59.78179814] },
      geometry_name: 'shape',
      properties: {
        portname: 'Slemmestad',
        code: 'NOSLM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.7818,
        longitude: 10.49802,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3138304,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14860',
      geometry: { type: 'Point', coordinates: [17.86503212, 62.89587103] },
      geometry_name: 'shape',
      properties: {
        portname: 'Svano',
        code: 'SESVX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 62.89587,
        longitude: 17.86503,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2670454,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14861',
      geometry: { type: 'Point', coordinates: [7.73397124, 63.11497762] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kristiansund',
        code: 'NOKSU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.11498,
        longitude: 7.73397,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3149312,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16065',
      geometry: { type: 'Point', coordinates: [126.05316436, 9.75568961] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dapa',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 9.75569,
        longitude: 126.05316,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1715617,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14862',
      geometry: { type: 'Point', coordinates: [-2.90788586, 53.29666518] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ellesmere Port',
        code: 'GBELL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.29667,
        longitude: -2.90789,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2650096,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14863',
      geometry: { type: 'Point', coordinates: [-0.18991784, 53.62561802] },
      geometry_name: 'shape',
      properties: {
        portname: 'Immingham',
        code: 'GBIMM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.62562,
        longitude: -0.18992,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2646256,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14864',
      geometry: { type: 'Point', coordinates: [16.46507176, 43.53884195] },
      geometry_name: 'shape',
      properties: {
        portname: 'Solin',
        code: 'HRSLN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.53884,
        longitude: 16.46507,
        iso3: 'HRV',
        iso3_op: 'HRV',
        country: 'Croatia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3190359,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14865',
      geometry: { type: 'Point', coordinates: [-0.74536774, 45.1983937] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pauillac',
        code: 'FRPAP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.19839,
        longitude: -0.74537,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2988350,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14866',
      geometry: { type: 'Point', coordinates: [27.98468014, 45.28240253] },
      geometry_name: 'shape',
      properties: {
        portname: 'Braila',
        code: 'ROBRA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.2824,
        longitude: 27.98468,
        iso3: 'ROU',
        iso3_op: 'ROU',
        country: 'Romania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 683902,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14867',
      geometry: { type: 'Point', coordinates: [14.53705453, 45.30216047] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bakar',
        code: 'HRBAK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.30216,
        longitude: 14.53705,
        iso3: 'HRV',
        iso3_op: 'HRV',
        country: 'Croatia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3204612,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14868',
      geometry: { type: 'Point', coordinates: [-0.95604604, 45.93617833] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rochefort',
        code: 'FRRCO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.93618,
        longitude: -0.95605,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2983276,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14869',
      geometry: { type: 'Point', coordinates: [19.58878714, 41.81386266] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shengjin',
        code: 'ALSHG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.81386,
        longitude: 19.58879,
        iso3: 'ALB',
        iso3_op: 'ALB',
        country: 'Albania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3184136,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14870',
      geometry: { type: 'Point', coordinates: [0.73702739, 50.94917284] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rye',
        code: 'GBRYE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.94917,
        longitude: 0.73703,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2638909,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14871',
      geometry: { type: 'Point', coordinates: [1.86573805, 50.96679178] },
      geometry_name: 'shape',
      properties: {
        portname: 'Calais',
        code: 'FRCQF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.96679,
        longitude: 1.86574,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3029162,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14872',
      geometry: { type: 'Point', coordinates: [0.3521988, 51.45728536] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tilbury',
        code: 'GBTIL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.45729,
        longitude: 0.3522,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2635836,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14873',
      geometry: { type: 'Point', coordinates: [0.72344458, 51.5795087] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rochford',
        code: 'GBRFD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.57951,
        longitude: 0.72344,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2639265,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14874',
      geometry: { type: 'Point', coordinates: [0.6865, 51.73103387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maldon',
        code: 'GBMAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.73103,
        longitude: 0.6865,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2643160,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14875',
      geometry: { type: 'Point', coordinates: [-9.37338372, 52.58749716] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tarbert',
        code: 'GBTAB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.5875,
        longitude: -9.37338,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2961267,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14876',
      geometry: { type: 'Point', coordinates: [8.76428011, 53.09549011] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bremen',
        code: 'DEBRE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.09549,
        longitude: 8.76428,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2944388,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14877',
      geometry: { type: 'Point', coordinates: [-2.74925963, 53.33978074] },
      geometry_name: 'shape',
      properties: {
        portname: 'Runcorn',
        code: 'GBRUN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.33978,
        longitude: -2.74926,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2638960,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14878',
      geometry: { type: 'Point', coordinates: [99.8803026, 9.20917508] },
      geometry_name: 'shape',
      properties: {
        portname: 'Khanom',
        code: 'THKHA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.20918,
        longitude: 99.8803,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 8125982,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14879',
      geometry: { type: 'Point', coordinates: [-8.50210581, 33.25902004] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Jadida',
        code: 'MAELJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.25902,
        longitude: -8.50211,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2550078,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14880',
      geometry: { type: 'Point', coordinates: [130.84722373, 33.9176164] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kitakyushu',
        code: 'JPTBT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.91762,
        longitude: 130.84722,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1859307,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14881',
      geometry: { type: 'Point', coordinates: [6.57062725, 37.00584978] },
      geometry_name: 'shape',
      properties: {
        portname: 'Collo',
        code: 'DZCOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.00585,
        longitude: 6.57063,
        iso3: null,
        iso3_op: 'DZA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2501162,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14882',
      geometry: { type: 'Point', coordinates: [9.87852308, 37.27438664] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bizerte',
        code: 'TNBIZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.27439,
        longitude: 9.87852,
        iso3: null,
        iso3_op: 'TUN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2472706,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14883',
      geometry: { type: 'Point', coordinates: [-9.24486092, 32.30577295] },
      geometry_name: 'shape',
      properties: {
        portname: 'Safi',
        code: 'MASFI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.30577,
        longitude: -9.24486,
        iso3: null,
        iso3_op: 'MAR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2537881,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14884',
      geometry: { type: 'Point', coordinates: [13.73670258, -10.75145109] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porto Amboim',
        code: 'AOPBN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -10.75145,
        longitude: 13.7367,
        iso3: 'AGO',
        iso3_op: 'AGO',
        country: 'Angola',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3346661,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14885',
      geometry: { type: 'Point', coordinates: [100.56839214, 7.22689713] },
      geometry_name: 'shape',
      properties: {
        portname: 'Songkhla',
        code: 'THSGZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 7.2269,
        longitude: 100.56839,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1606147,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14886',
      geometry: { type: 'Point', coordinates: [132.05864299, 33.90938851] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hirao',
        code: 'JPHRA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.90939,
        longitude: 132.05864,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1862513,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14887',
      geometry: { type: 'Point', coordinates: [131.76597368, 34.05620833] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tonda',
        code: 'JPTND',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.05621,
        longitude: 131.76597,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1850036,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14888',
      geometry: { type: 'Point', coordinates: [135.19245525, 34.15601375] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kainan',
        code: 'JPKAI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.15601,
        longitude: 135.19246,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1860785,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14889',
      geometry: { type: 'Point', coordinates: [134.61552797, 34.14768002] },
      geometry_name: 'shape',
      properties: {
        portname: 'Naruto',
        code: 'JPNRU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.14768,
        longitude: 134.61553,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1855580,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14890',
      geometry: { type: 'Point', coordinates: [-67.49879815, -46.47078569] },
      geometry_name: 'shape',
      properties: {
        portname: 'Caleta Olivia',
        code: 'ARCVI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -46.47079,
        longitude: -67.4988,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3863379,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14891',
      geometry: { type: 'Point', coordinates: [173.27735865, -41.26191197] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nelson',
        code: 'NZNSN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.26191,
        longitude: 173.27736,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2186280,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14892',
      geometry: { type: 'Point', coordinates: [117.88818564, -35.0299278] },
      geometry_name: 'shape',
      properties: {
        portname: 'Albany',
        code: 'AUALH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -35.02993,
        longitude: 117.88819,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2077963,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14893',
      geometry: { type: 'Point', coordinates: [-38.47379478, -3.71124943] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fortaleza',
        code: 'BRFOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.71125,
        longitude: -38.47379,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3399415,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14894',
      geometry: { type: 'Point', coordinates: [113.04342199, 3.16872457] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bintulu',
        code: 'MYBTU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 3.16872,
        longitude: 113.04342,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1737486,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14895',
      geometry: { type: 'Point', coordinates: [123.14314147, 9.59446696] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bais',
        code: 'PHBAI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.59447,
        longitude: 123.14314,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1728772,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14896',
      geometry: { type: 'Point', coordinates: [109.4935, 18.23103387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sanya',
        code: 'CNSYX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.23103,
        longitude: 109.4935,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1796556,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14897',
      geometry: { type: 'Point', coordinates: [111.06270141, 21.48050767] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shuidong',
        code: 'CNSDG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.48051,
        longitude: 111.0627,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1794499,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14898',
      geometry: { type: 'Point', coordinates: [114.16482136, 22.30198398] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hong Kong',
        code: 'HKHKG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.30198,
        longitude: 114.16482,
        iso3: 'HKG',
        iso3_op: 'HKG',
        country: 'Hong Kong',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1819729,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14899',
      geometry: { type: 'Point', coordinates: [116.73699122, 23.35180087] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shantou',
        code: 'CNSWA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.3518,
        longitude: 116.73699,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1795940,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14900',
      geometry: { type: 'Point', coordinates: [127.78650506, 26.22730179] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nishihara',
        code: 'JPNHR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.2273,
        longitude: 127.78651,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1855341,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14901',
      geometry: { type: 'Point', coordinates: [130.38002877, 32.77916495] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shimabara',
        code: 'JPSMB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.77916,
        longitude: 130.38003,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1852479,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14902',
      geometry: { type: 'Point', coordinates: [131.0067275, 33.77352058] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kanda',
        code: 'JPKND',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.77352,
        longitude: 131.00673,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1860234,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14903',
      geometry: { type: 'Point', coordinates: [139.85400143, 34.98865445] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tateyama',
        code: 'JPTTY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.98865,
        longitude: 139.854,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1850523,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14904',
      geometry: { type: 'Point', coordinates: [134.82683265, 35.64481862] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tsuiyama',
        code: 'JPTYM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.64482,
        longitude: 134.82683,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1849726,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14905',
      geometry: { type: 'Point', coordinates: [27.79244024, 40.39613415] },
      geometry_name: 'shape',
      properties: {
        portname: 'Erdek',
        code: 'TRERK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.39613,
        longitude: 27.79244,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 747482,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14906',
      geometry: { type: 'Point', coordinates: [140.71146897, 41.76983591] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hakodate',
        code: 'JPHKD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.76984,
        longitude: 140.71147,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2130188,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14907',
      geometry: { type: 'Point', coordinates: [37.7790574, 44.73042073] },
      geometry_name: 'shape',
      properties: {
        portname: 'Novorossiysk',
        code: 'RUNVS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.73042,
        longitude: 37.77906,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 518255,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14908',
      geometry: { type: 'Point', coordinates: [145.32791657, -42.1539636] },
      geometry_name: 'shape',
      properties: {
        portname: 'Strahan',
        code: 'AUSRN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -42.15396,
        longitude: 145.32792,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2148132,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14909',
      geometry: { type: 'Point', coordinates: [174.00483189, -41.28537384] },
      geometry_name: 'shape',
      properties: {
        portname: 'Picton',
        code: 'NZPCN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.28537,
        longitude: 174.00483,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6243776,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14910',
      geometry: { type: 'Point', coordinates: [146.85943212, -41.13517904] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bell Bay',
        code: 'AUBEL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.13518,
        longitude: 146.85943,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2176330,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14911',
      geometry: { type: 'Point', coordinates: [-62.27290965, -38.7879696] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bahia Blanca',
        code: 'ARBHI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -38.78797,
        longitude: -62.27291,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3865086,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14912',
      geometry: { type: 'Point', coordinates: [137.62835389, -33.92360834] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wallaroo',
        code: 'AUWAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.92361,
        longitude: 137.62835,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2059263,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14913',
      geometry: { type: 'Point', coordinates: [153.35727547, -29.43600509] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yamba',
        code: 'AUYBA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -29.43601,
        longitude: 153.35728,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2142648,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14914',
      geometry: { type: 'Point', coordinates: [151.2398785, -23.83063377] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gladstone',
        code: 'AUGLT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -23.83063,
        longitude: 151.23988,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2165478,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14915',
      geometry: { type: 'Point', coordinates: [-149.56887167, -17.53531907] },
      geometry_name: 'shape',
      properties: {
        portname: 'Papeete',
        code: 'PFPPT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.53532,
        longitude: -149.56887,
        iso3: 'PYF',
        iso3_op: 'PYF',
        country: 'French Polynesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4033936,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14916',
      geometry: { type: 'Point', coordinates: [126.35562281, 8.19006494] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bislig',
        code: 'PHBPH',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.19006,
        longitude: 126.35562,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1724767,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14917',
      geometry: { type: 'Point', coordinates: [113.43780756, 23.09566141] },
      geometry_name: 'shape',
      properties: {
        portname: 'Huangpu',
        code: 'CNHUA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.09566,
        longitude: 113.43781,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1916870,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14918',
      geometry: { type: 'Point', coordinates: [131.09706776, 31.46343851] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shibushi',
        code: 'JPSBS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.46344,
        longitude: 131.09707,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1852588,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14919',
      geometry: { type: 'Point', coordinates: [131.81112594, 33.12480126] },
      geometry_name: 'shape',
      properties: {
        portname: 'Usuki',
        code: 'JPUSK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.1248,
        longitude: 131.81113,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1849094,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14920',
      geometry: { type: 'Point', coordinates: [141.63626474, 42.63821906] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tomakomai',
        code: 'JPTMK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.63822,
        longitude: 141.63626,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2127733,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14921',
      geometry: { type: 'Point', coordinates: [110.28296175, 20.02024699] },
      geometry_name: 'shape',
      properties: {
        portname: 'Haikou',
        code: 'CNHAK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.02025,
        longitude: 110.28296,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1809078,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14922',
      geometry: { type: 'Point', coordinates: [132.88523829, 42.80684137] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nakhodka',
        code: 'RUNJK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.80684,
        longitude: 132.88524,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2019528,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14923',
      geometry: { type: 'Point', coordinates: [148.24799283, -20.01751474] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bowen',
        code: 'AUZBO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.01751,
        longitude: 148.24799,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2174444,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14924',
      geometry: { type: 'Point', coordinates: [145.77892418, -16.93120147] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cairns',
        code: 'AUCNS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.9312,
        longitude: 145.77892,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2172797,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14925',
      geometry: { type: 'Point', coordinates: [116.8474897, 6.88110166] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kudat',
        code: 'MYKUD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.8811,
        longitude: 116.84749,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1736458,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14926',
      geometry: { type: 'Point', coordinates: [-64.26399325, 10.57642179] },
      geometry_name: 'shape',
      properties: {
        portname: 'Araya',
        code: 'VEARA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.57642,
        longitude: -64.26399,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3649005,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14927',
      geometry: { type: 'Point', coordinates: [75.80718988, 11.16603004] },
      geometry_name: 'shape',
      properties: {
        portname: 'Beypore',
        code: 'INBEY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.16603,
        longitude: 75.80719,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1276378,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14928',
      geometry: { type: 'Point', coordinates: [69.69478285, 22.74312514] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mundra',
        code: 'INMUN',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.74313,
        longitude: 69.69478,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1262497,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14929',
      geometry: { type: 'Point', coordinates: [121.84354005, 13.32811257] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gasan',
        code: 'PHGAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.32811,
        longitude: 121.84354,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1713154,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14930',
      geometry: { type: 'Point', coordinates: [123.73506037, 13.36123916] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tabaco',
        code: 'PHTBC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.36124,
        longitude: 123.73506,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1685117,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14931',
      geometry: { type: 'Point', coordinates: [135.36668171, 33.7315957] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanabe',
        code: 'JPTAE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.7316,
        longitude: 135.36668,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1850708,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14932',
      geometry: { type: 'Point', coordinates: [131.9254345, 33.95791179] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hikari',
        code: 'JPHKR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.95791,
        longitude: 131.92543,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1896348,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14933',
      geometry: { type: 'Point', coordinates: [131.17122639, 33.97585924] },
      geometry_name: 'shape',
      properties: {
        portname: 'Onoda',
        code: 'JPOND',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.97586,
        longitude: 131.17123,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1854018,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14934',
      geometry: { type: 'Point', coordinates: [120.32468219, 36.05714399] },
      geometry_name: 'shape',
      properties: {
        portname: 'Qingdao',
        code: 'CNTAO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.05714,
        longitude: 120.32468,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1797929,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14935',
      geometry: { type: 'Point', coordinates: [122.1205751, 37.50550588] },
      geometry_name: 'shape',
      properties: {
        portname: 'Weihai',
        code: 'CNWEI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.50551,
        longitude: 122.12058,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1791673,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14936',
      geometry: { type: 'Point', coordinates: [141.45310892, 38.44568316] },
      geometry_name: 'shape',
      properties: {
        portname: 'Onagawa',
        code: 'JPONG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.44568,
        longitude: 141.45311,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2111401,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14937',
      geometry: { type: 'Point', coordinates: [131.88320954, 43.11245295] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vladivostok',
        code: 'RUVVO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.11245,
        longitude: 131.88321,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2013348,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14938',
      geometry: { type: 'Point', coordinates: [39.06453248, 44.09487657] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tuapse',
        code: 'RUTUA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.09488,
        longitude: 39.06453,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 480716,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14939',
      geometry: { type: 'Point', coordinates: [24.48092709, 58.3859919] },
      geometry_name: 'shape',
      properties: {
        portname: 'Parnu',
        code: 'EEPAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.38599,
        longitude: 24.48093,
        iso3: 'EST',
        iso3_op: 'EST',
        country: 'Estonia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 589580,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14940',
      geometry: { type: 'Point', coordinates: [22.94924423, 59.82253221] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hanko',
        code: 'FIHKO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.82253,
        longitude: 22.94924,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 659101,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14941',
      geometry: { type: 'Point', coordinates: [24.38142099, 60.08685979] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kantvik',
        code: 'FIKNT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.08686,
        longitude: 24.38142,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 654243,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14942',
      geometry: { type: 'Point', coordinates: [31.35740773, 69.63998504] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pechenga',
        code: 'RUPCE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 69.63999,
        longitude: 31.35741,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 511865,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14943',
      geometry: { type: 'Point', coordinates: [170.29970017, 69.69782985] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pevek',
        code: 'RUPWE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 69.69783,
        longitude: 170.2997,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2122090,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14944',
      geometry: { type: 'Point', coordinates: [-72.98321647, -36.71416642] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lirquen',
        code: 'CLLQN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -36.71417,
        longitude: -72.98322,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3883093,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14945',
      geometry: { type: 'Point', coordinates: [-67.47408099, 6.18392862] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Carreno',
        code: 'COPCR',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.18393,
        longitude: -67.47408,
        iso3: 'COL',
        iso3_op: 'COL',
        country: 'Colombia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3671519,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14946',
      geometry: { type: 'Point', coordinates: [-69.90302307, 12.42711098] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sint Nicolaas',
        code: 'AWSNL',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.42711,
        longitude: -69.90302,
        iso3: 'ABW',
        iso3_op: 'ABW',
        country: 'Aruba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3577089,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14947',
      geometry: { type: 'Point', coordinates: [-64.78655156, 32.29105493] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hamilton',
        code: 'BMBDA',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.29105,
        longitude: -64.78655,
        iso3: null,
        iso3_op: 'BMU',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3573197,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14948',
      geometry: { type: 'Point', coordinates: [-65.91263541, -47.75347048] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Deseado',
        code: 'ARPUD',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -47.75347,
        longitude: -65.91264,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3840104,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14949',
      geometry: { type: 'Point', coordinates: [-71.61742525, -33.5977668] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Antonio',
        code: 'CLSAI',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.59777,
        longitude: -71.61743,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3872395,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14950',
      geometry: { type: 'Point', coordinates: [-70.0384492, 12.51911588] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oranjestad',
        code: 'AWORJ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.51912,
        longitude: -70.03845,
        iso3: null,
        iso3_op: 'ABW',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3577154,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14951',
      geometry: { type: 'Point', coordinates: [-66.94141674, 10.59814284] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Guaira',
        code: 'VELAG',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.59814,
        longitude: -66.94142,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3637721,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14952',
      geometry: { type: 'Point', coordinates: [-97.86337204, 22.21337367] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tampico',
        code: 'MXTAM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.21337,
        longitude: -97.86337,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3824166,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14953',
      geometry: { type: 'Point', coordinates: [-73.23962512, -3.73095924] },
      geometry_name: 'shape',
      properties: {
        portname: 'Iquitos',
        code: 'PEIQT',
        prttype: 'River',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.73096,
        longitude: -73.23963,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3696183,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14954',
      geometry: { type: 'Point', coordinates: [-85.96903949, 16.00621454] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Castilla',
        code: 'HNPCA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.00621,
        longitude: -85.96904,
        iso3: 'HND',
        iso3_op: 'HND',
        country: 'Honduras',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3603167,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14955',
      geometry: { type: 'Point', coordinates: [-87.16870876, 12.48083544] },
      geometry_name: 'shape',
      properties: {
        portname: 'Corinto',
        code: 'NICIO',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.48084,
        longitude: -87.16871,
        iso3: 'NIC',
        iso3_op: 'NIC',
        country: 'Nicaragua',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3620269,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14956',
      geometry: { type: 'Point', coordinates: [-72.10884003, -17.00166903] },
      geometry_name: 'shape',
      properties: {
        portname: 'Matarani',
        code: 'PEMRI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.00167,
        longitude: -72.10884,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3935187,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14957',
      geometry: { type: 'Point', coordinates: [-88.29910974, 18.49294618] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chetumal',
        code: 'MXCTM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.49295,
        longitude: -88.29911,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3531023,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14958',
      geometry: { type: 'Point', coordinates: [50.1585, 26.64234709] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Tanura',
        code: 'SARTA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.64235,
        longitude: 50.1585,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 102891,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14959',
      geometry: { type: 'Point', coordinates: [29.83019022, 40.7498128] },
      geometry_name: 'shape',
      properties: {
        portname: 'Derince',
        code: 'TRDRC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.74981,
        longitude: 29.83019,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 748208,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14960',
      geometry: { type: 'Point', coordinates: [36.33564815, 41.30185115] },
      geometry_name: 'shape',
      properties: {
        portname: 'Samsun',
        code: 'TRSSX',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.30185,
        longitude: 36.33565,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 740264,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14961',
      geometry: { type: 'Point', coordinates: [-70.83274117, -27.06112462] },
      geometry_name: 'shape',
      properties: {
        portname: 'Caldera',
        code: 'CLCLD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -27.06112,
        longitude: -70.83274,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3897322,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14962',
      geometry: { type: 'Point', coordinates: [-83.16786645, 8.63715945] },
      geometry_name: 'shape',
      properties: {
        portname: 'Golfito',
        code: 'CRGLF',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.63716,
        longitude: -83.16787,
        iso3: 'CRI',
        iso3_op: 'CRI',
        country: 'Costa Rica',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3623656,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14963',
      geometry: { type: 'Point', coordinates: [37.48562346, 41.04401995] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fatsa',
        code: 'TRFAS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.04402,
        longitude: 37.48562,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 747155,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14964',
      geometry: { type: 'Point', coordinates: [26.29817658, 38.32183927] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cesme',
        code: 'TRCES',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.32184,
        longitude: 26.29818,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 318755,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14965',
      geometry: { type: 'Point', coordinates: [-79.67203027, 8.86448173] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vacamonte',
        code: 'PAVAC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.86448,
        longitude: -79.67203,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3700243,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14966',
      geometry: { type: 'Point', coordinates: [-60.52165521, -31.71916259] },
      geometry_name: 'shape',
      properties: {
        portname: 'Parana',
        code: 'ARPRA',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -31.71916,
        longitude: -60.52166,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3841956,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14967',
      geometry: { type: 'Point', coordinates: [-59.17411497, -33.74136686] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ibicuy',
        code: 'ARIBY',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.74137,
        longitude: -59.17411,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3433503,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14968',
      geometry: { type: 'Point', coordinates: [-63.09017655, 10.12887483] },
      geometry_name: 'shape',
      properties: {
        portname: 'Caripito',
        code: 'VECAR',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 10.12887,
        longitude: -63.09018,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3646534,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14969',
      geometry: { type: 'Point', coordinates: [44.12242358, -25.00382491] },
      geometry_name: 'shape',
      properties: {
        portname: 'Androka',
        code: 'MGADK',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -25.00382,
        longitude: 44.12242,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1074223,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14970',
      geometry: { type: 'Point', coordinates: [-66.70090341, 18.48132144] },
      geometry_name: 'shape',
      properties: {
        portname: 'Arecibo',
        code: 'PRARE',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.48132,
        longitude: -66.7009,
        iso3: 'PRI',
        iso3_op: 'PRI',
        country: 'Puerto Rico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4562635,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14971',
      geometry: { type: 'Point', coordinates: [-79.62099546, 9.02529746] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paraiso',
        code: 'PAPAI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.0253,
        longitude: -79.621,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3703373,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14972',
      geometry: { type: 'Point', coordinates: [-66.15630245, 17.93794357] },
      geometry_name: 'shape',
      properties: {
        portname: 'Las Mareas',
        code: 'PRLAM',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.93794,
        longitude: -66.1563,
        iso3: 'PRI',
        iso3_op: 'PRI',
        country: 'Puerto Rico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4565954,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14973',
      geometry: { type: 'Point', coordinates: [26.96203867, 38.8029266] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aliaga',
        code: 'TRALI',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.80293,
        longitude: 26.96204,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 324106,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14974',
      geometry: { type: 'Point', coordinates: [-1.749, 4.88503387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Takoradi',
        code: 'GHTKD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.88503,
        longitude: -1.749,
        iso3: 'GHA',
        iso3_op: 'GHA',
        country: 'Ghana',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2294915,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14975',
      geometry: { type: 'Point', coordinates: [26.67012666, 40.40620029] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gelibolu',
        code: 'TRGEL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.4062,
        longitude: 26.67013,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 746983,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14976',
      geometry: { type: 'Point', coordinates: [-13.05907548, 8.57545658] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pepel',
        code: 'SLPEP',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.57546,
        longitude: -13.05908,
        iso3: 'SLE',
        iso3_op: 'SLE',
        country: 'Sierra Leone',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2404607,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14977',
      geometry: { type: 'Point', coordinates: [-0.61809353, 5.33771591] },
      geometry_name: 'shape',
      properties: {
        portname: 'Winneba',
        code: 'GHWEA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 5.33772,
        longitude: -0.61809,
        iso3: null,
        iso3_op: 'GHA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2294034,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14978',
      geometry: { type: 'Point', coordinates: [-6.26438431, 54.10303129] },
      geometry_name: 'shape',
      properties: {
        portname: 'Warrenpoint',
        code: 'GBWPT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.10303,
        longitude: -6.26438,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2634743,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14979',
      geometry: { type: 'Point', coordinates: [9.93534904, 54.65651522] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kappeln',
        code: 'DEKAP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.65652,
        longitude: 9.93535,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2893023,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14980',
      geometry: { type: 'Point', coordinates: [18.41341672, 54.79497663] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wladyslawowo',
        code: 'PLWLA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.79498,
        longitude: 18.41342,
        iso3: 'POL',
        iso3_op: 'POL',
        country: 'Poland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3081752,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14981',
      geometry: { type: 'Point', coordinates: [11.49047297, 54.83640943] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bandholm',
        code: 'DKBDX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.83641,
        longitude: 11.49047,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2624330,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14982',
      geometry: { type: 'Point', coordinates: [11.0989816, 55.67512454] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalundborg',
        code: 'DKKAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.67512,
        longitude: 11.09898,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2619154,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14983',
      geometry: { type: 'Point', coordinates: [-6.09707395, 56.69175953] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kilchoan',
        code: 'GBKOA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.69176,
        longitude: -6.09707,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2645692,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14984',
      geometry: { type: 'Point', coordinates: [12.4797883, 56.89034102] },
      geometry_name: 'shape',
      properties: {
        portname: 'Falkenberg',
        code: 'SEFAG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.89034,
        longitude: 12.47979,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2715573,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14985',
      geometry: { type: 'Point', coordinates: [9.93830629, 57.04611372] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aalborg',
        code: 'DKAAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.04611,
        longitude: 9.93831,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2624886,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14986',
      geometry: { type: 'Point', coordinates: [13.81962577, 58.716758] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mariestad',
        code: 'SEMAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.71676,
        longitude: 13.81963,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2692613,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14987',
      geometry: { type: 'Point', coordinates: [14.0897638, 59.31508887] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kristinehamn',
        code: 'SEKHN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.31509,
        longitude: 14.08976,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2699282,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14988',
      geometry: { type: 'Point', coordinates: [21.2508251, 64.68635441] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skelleftehamn',
        code: 'SESKE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.68635,
        longitude: 21.25083,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 602909,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14989',
      geometry: { type: 'Point', coordinates: [17.15723895, 60.68714599] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gavle',
        code: 'SEGVX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.68715,
        longitude: 17.15724,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2712414,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14990',
      geometry: { type: 'Point', coordinates: [-72.70217334, 19.10953058] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saint-Marc',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.10953,
        longitude: -72.70217,
        iso3: null,
        iso3_op: 'HTI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3717588,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14991',
      geometry: { type: 'Point', coordinates: [113.93394239, -7.69759621] },
      geometry_name: 'shape',
      properties: {
        portname: 'Panarukan',
        code: 'IDPRN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.6976,
        longitude: 113.93394,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1632903,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14992',
      geometry: { type: 'Point', coordinates: [140.39086434, -8.47876968] },
      geometry_name: 'shape',
      properties: {
        portname: 'Merauke',
        code: 'IDMKQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.47877,
        longitude: 140.39086,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2082539,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14993',
      geometry: { type: 'Point', coordinates: [131.02837351, -1.3066378] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kasim',
        code: 'IDKAS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.30664,
        longitude: 131.02837,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1641014,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14994',
      geometry: { type: 'Point', coordinates: [118.89419613, -2.66758491] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mamuju',
        code: 'IDMJU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.66758,
        longitude: 118.8942,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1636556,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14995',
      geometry: { type: 'Point', coordinates: [119.85741727, -0.70932451] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pantoloan',
        code: 'IDPTL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.70932,
        longitude: 119.85742,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1968071,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14996',
      geometry: { type: 'Point', coordinates: [-81.19493297, 23.04681953] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cardenas',
        code: 'CUCAR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.04682,
        longitude: -81.19493,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3565432,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14997',
      geometry: { type: 'Point', coordinates: [-77.32557481, 20.22413685] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ceiba Hueca',
        code: 'CUCEI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.22414,
        longitude: -77.32557,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3564537,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14998',
      geometry: { type: 'Point', coordinates: [-83.38501461, 14.02004168] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Cabezas',
        code: 'NIPUZ',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.02004,
        longitude: -83.38501,
        iso3: 'NIC',
        iso3_op: 'NIC',
        country: 'Nicaragua',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3617154,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14999',
      geometry: { type: 'Point', coordinates: [35.72335727, 34.32698498] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chekka',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.32698,
        longitude: 35.72336,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational, Fuel Oil And Others',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6280494,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15000',
      geometry: { type: 'Point', coordinates: [35.78766358, 34.38993325] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Qalamoun',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.38993,
        longitude: 35.78766,
        iso3: 'LBN',
        iso3_op: 'LBN',
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 279231,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15001',
      geometry: { type: 'Point', coordinates: [-81.21262089, 42.66129762] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Stanley',
        code: 'CAPST',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.6613,
        longitude: -81.21262,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6112027,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15002',
      geometry: { type: 'Point', coordinates: [131.50360509, 33.30222487] },
      geometry_name: 'shape',
      properties: {
        portname: 'Beppu',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.30222,
        longitude: 131.50361,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1864750,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15003',
      geometry: { type: 'Point', coordinates: [-79.92626659, 32.79075808] },
      geometry_name: 'shape',
      properties: {
        portname: 'Charleston',
        code: 'USCHS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.79076,
        longitude: -79.92627,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4574324,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15004',
      geometry: { type: 'Point', coordinates: [-117.38385922, 33.19456248] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oceanside',
        code: 'USXJX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.19456,
        longitude: -117.38386,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5378771,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15005',
      geometry: { type: 'Point', coordinates: [-118.00240179, 33.65665435] },
      geometry_name: 'shape',
      properties: {
        portname: 'Huntington Beach',
        code: 'USHTD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.65665,
        longitude: -118.0024,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5358705,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15006',
      geometry: { type: 'Point', coordinates: [-87.81386431, 42.58359987] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kenosha',
        code: 'USENW',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.5836,
        longitude: -87.81386,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5258393,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15007',
      geometry: { type: 'Point', coordinates: [-90.06162385, 29.94223194] },
      geometry_name: 'shape',
      properties: {
        portname: 'New Orleans',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.94223,
        longitude: -90.06162,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4335045,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15008',
      geometry: { type: 'Point', coordinates: [-79.33510625, 42.48829671] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dunkirk',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.4883,
        longitude: -79.33511,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5115495,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15009',
      geometry: { type: 'Point', coordinates: [121.8657, 13.5333] },
      geometry_name: 'shape',
      properties: {
        portname: 'Balanacan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.5333,
        longitude: 121.8657,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1728539,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15010',
      geometry: { type: 'Point', coordinates: [-133.92226206, 56.96261007] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kake',
        code: 'USKAE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.96261,
        longitude: -133.92226,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5554194,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15011',
      geometry: { type: 'Point', coordinates: [18.45585623, -33.91803549] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cape Town',
        code: 'ZACPT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.91804,
        longitude: 18.45586,
        iso3: 'ZAF',
        iso3_op: 'ZAF',
        country: 'South Africa',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3369157,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15012',
      geometry: { type: 'Point', coordinates: [-90.18664882, 29.92578546] },
      geometry_name: 'shape',
      properties: {
        portname: 'Avondale',
        code: 'USAVD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.92579,
        longitude: -90.18665,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4315225,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15013',
      geometry: { type: 'Point', coordinates: [-68.51286521, 48.47676675] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rimouski',
        code: 'CARIM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.47677,
        longitude: -68.51287,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6354895,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15014',
      geometry: { type: 'Point', coordinates: [-155.05365248, 19.73036831] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hilo',
        code: 'USITO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.73037,
        longitude: -155.05365,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5855927,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15015',
      geometry: { type: 'Point', coordinates: [-2.93422189, 35.2938024] },
      geometry_name: 'shape',
      properties: {
        portname: 'Melilla',
        code: 'ESMLN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.2938,
        longitude: -2.93422,
        iso3: null,
        iso3_op: 'ESP',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2513947,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15016',
      geometry: { type: 'Point', coordinates: [-53.21923806, 47.69080068] },
      geometry_name: 'shape',
      properties: {
        portname: 'Harbour Grace',
        code: 'CAHRE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.6908,
        longitude: -53.21924,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5970492,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15017',
      geometry: { type: 'Point', coordinates: [122.0481777, 13.50508248] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santa Cruz',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.50508,
        longitude: 122.04818,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1688271,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15018',
      geometry: { type: 'Point', coordinates: [21.51991545, 2.14182564] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lisala',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 2.14183,
        longitude: 21.51992,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 211734,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15019',
      geometry: { type: 'Point', coordinates: [35.9778474, 34.51917754] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Aabde',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.51918,
        longitude: 35.97785,
        iso3: 'LBN',
        iso3_op: 'LBN',
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 280561,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15020',
      geometry: { type: 'Point', coordinates: [35.06727306, -17.44141731] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mutarara',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.44142,
        longitude: 35.06727,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Mutarara: (I) - No Vessel Restriction- (Ii) - No Truck Access- (Iii) - Network Coverage - Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1035305,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15021',
      geometry: { type: 'Point', coordinates: [14.16964953, 40.8094122] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bagnoli',
        code: 'ITBLN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.80941,
        longitude: 14.16965,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3182518,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15022',
      geometry: { type: 'Point', coordinates: [-5.69290056, 43.55034026] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gijon',
        code: 'ESGIJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.55034,
        longitude: -5.6929,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3121424,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15023',
      geometry: { type: 'Point', coordinates: [28.6419, 44.16453387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Constanta',
        code: 'ROCND',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.16453,
        longitude: 28.6419,
        iso3: 'ROU',
        iso3_op: 'ROU',
        country: 'Romania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 680963,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15024',
      geometry: { type: 'Point', coordinates: [17.17350879, 61.19034914] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vallvik',
        code: 'SEVAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.19035,
        longitude: 17.17351,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2665331,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15025',
      geometry: { type: 'Point', coordinates: [-9.77355835, 31.5100404] },
      geometry_name: 'shape',
      properties: {
        portname: 'Essaouira',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.51004,
        longitude: -9.77356,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2549263,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15026',
      geometry: { type: 'Point', coordinates: [12.63440653, 66.02528488] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sandnessjoen',
        code: 'NOSSJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 66.02528,
        longitude: 12.63441,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3140301,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15027',
      geometry: { type: 'Point', coordinates: [20.97475621, 70.02858704] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skjervoy',
        code: 'NOSKY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 70.02859,
        longitude: 20.97476,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 777682,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15028',
      geometry: { type: 'Point', coordinates: [14.99872136, 42.00080717] },
      geometry_name: 'shape',
      properties: {
        portname: 'Termoli',
        code: 'ITTMI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.00081,
        longitude: 14.99872,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3165773,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15029',
      geometry: { type: 'Point', coordinates: [-3.79649329, 43.46274127] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santander',
        code: 'ESSDR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.46274,
        longitude: -3.79649,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3109718,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15030',
      geometry: { type: 'Point', coordinates: [26.84850571, -8.00258955] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kabala',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.00259,
        longitude: 26.84851,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 215675,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15031',
      geometry: { type: 'Point', coordinates: [-3.2775432, 47.80298241] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hennebont',
        code: 'FRHET',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.80298,
        longitude: -3.27754,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3013521,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15032',
      geometry: { type: 'Point', coordinates: [16.73467492, -6.47390841] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mayamba',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -6.47391,
        longitude: 16.73467,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2312950,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15033',
      geometry: { type: 'Point', coordinates: [14.26006146, 40.84360922] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portici',
        code: 'ITPTC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.84361,
        longitude: 14.26006,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3170116,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15034',
      geometry: { type: 'Point', coordinates: [16.8501, 41.13093387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bari',
        code: 'ITBRI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.13093,
        longitude: 16.8501,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3182351,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17776',
      geometry: { type: 'Point', coordinates: [-75.72809339, 20.90401392] },
      geometry_name: 'shape',
      properties: {
        portname: 'Macabi',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 20.90401,
        longitude: -75.72809,
        iso3: 'CUB',
        iso3_op: null,
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3548677,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15035',
      geometry: { type: 'Point', coordinates: [5.42628544, 53.18368179] },
      geometry_name: 'shape',
      properties: {
        portname: 'Harlingen',
        code: 'NLHAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.18368,
        longitude: 5.42629,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2754817,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15036',
      geometry: { type: 'Point', coordinates: [18.16121342, -4.00890045] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tshimbane',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.0089,
        longitude: 18.16121,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2311101,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15037',
      geometry: { type: 'Point', coordinates: [13.44096732, 58.63535632] },
      geometry_name: 'shape',
      properties: {
        portname: 'Honsater',
        code: 'SEHON',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.63536,
        longitude: 13.44097,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2705069,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15038',
      geometry: { type: 'Point', coordinates: [27.26373833, 61.50151514] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ristiina',
        code: 'FIRIS',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.50152,
        longitude: 27.26374,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 639248,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15039',
      geometry: { type: 'Point', coordinates: [27.67483398, 62.87669074] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kuopio',
        code: 'FIKUO',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 62.87669,
        longitude: 27.67483,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 650224,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15040',
      geometry: { type: 'Point', coordinates: [35.00592399, -17.37890929] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chola',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -17.37891,
        longitude: 35.00592,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Chola: ( I) -10 Mt Vessel Only- (Ii) -Truck Access- (Iii) -No Network Coverage- (Iv) -Hazard To Navigation: Snakes, Crocodiles, Hippo--Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1048363,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15041',
      geometry: { type: 'Point', coordinates: [34.89773469, -17.17096864] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chemba',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -17.17097,
        longitude: 34.89773,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Chemba: (I) -No Vessel Restriction- (Ii) -Truck Access- (Iii) -Network Coverage -(Iv )-No Hazard To Navigation--Planning',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1050087,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15042',
      geometry: { type: 'Point', coordinates: [-72.38863254, 18.71135983] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fond Mombin',
        code: 'HTFOM',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Closed',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.71136,
        longitude: -72.38863,
        iso3: null,
        iso3_op: 'HTI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3725289,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15043',
      geometry: { type: 'Point', coordinates: [-0.35455246, 16.95238742] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bourem',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.95239,
        longitude: -0.35455,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2459657,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15044',
      geometry: { type: 'Point', coordinates: [-1.4041093, 17.03199902] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bamba',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.032,
        longitude: -1.40411,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2460582,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15045',
      geometry: { type: 'Point', coordinates: [44.0180996, -18.06201982] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maintirano',
        code: 'MGMXT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: 5,
        maxlength: 66.2,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -18.06202,
        longitude: 44.0181,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1061912,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15046',
      geometry: { type: 'Point', coordinates: [125.43989127, 11.60648177] },
      geometry_name: 'shape',
      properties: {
        portname: 'Borongan',
        code: 'PHBGV',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.60648,
        longitude: 125.43989,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1723893,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15047',
      geometry: { type: 'Point', coordinates: [123.39757504, 12.51314585] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aroroy',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.51315,
        longitude: 123.39758,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1730143,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15048',
      geometry: { type: 'Point', coordinates: [-88.26452289, 44.27755028] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kaukauna',
        code: 'USKAU',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.27755,
        longitude: -88.26452,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5258296,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15049',
      geometry: { type: 'Point', coordinates: [104.77893188, -2.98040804] },
      geometry_name: 'shape',
      properties: {
        portname: 'Palembang',
        code: 'IDPLM',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.98041,
        longitude: 104.77893,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1633070,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15050',
      geometry: { type: 'Point', coordinates: [63.47646318, 25.26328859] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pasni',
        code: 'PKPSI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.26329,
        longitude: 63.47646,
        iso3: 'PAK',
        iso3_op: 'PAK',
        country: 'Pakistan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1168312,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15051',
      geometry: { type: 'Point', coordinates: [26.92443723, -6.29604861] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kialo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -6.29605,
        longitude: 26.92444,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 213405,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15052',
      geometry: { type: 'Point', coordinates: [17.51168503, -4.9770756] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ipaka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'approximate',
        latitude: -4.97708,
        longitude: 17.51169,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2315402,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15053',
      geometry: { type: 'Point', coordinates: [-65.47224888, 47.02932854] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chatham',
        code: 'CACTM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.02933,
        longitude: -65.47225,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5920447,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15054',
      geometry: { type: 'Point', coordinates: [-71.82622611, 19.66191884] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fort Liberte',
        code: 'HTFLI',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.66192,
        longitude: -71.82623,
        iso3: null,
        iso3_op: 'HTI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3725170,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15055',
      geometry: { type: 'Point', coordinates: [-82.96469288, 46.1804283] },
      geometry_name: 'shape',
      properties: {
        portname: 'Blind River',
        code: 'CABLR',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.18043,
        longitude: -82.96469,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5903851,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15056',
      geometry: { type: 'Point', coordinates: [-83.28733511, 44.65878407] },
      geometry_name: 'shape',
      properties: {
        portname: 'Harrisville',
        code: 'USHVW',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.65878,
        longitude: -83.28734,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4995424,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15057',
      geometry: { type: 'Point', coordinates: [29.03895106, -1.75511134] },
      geometry_name: 'shape',
      properties: {
        portname: 'Makelele',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.75511,
        longitude: 29.03895,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 382747,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15058',
      geometry: { type: 'Point', coordinates: [151.00902391, -5.30907964] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bialla',
        code: 'PGBAA',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.30908,
        longitude: 151.00902,
        iso3: 'PNG',
        iso3_op: 'PNG',
        country: 'Papua New Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 7648670,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15059',
      geometry: { type: 'Point', coordinates: [141.2961232, -2.67829887] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vanimo',
        code: 'PGVAI',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.6783,
        longitude: 141.29612,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2084442,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15060',
      geometry: { type: 'Point', coordinates: [-74.11205435, 18.64293819] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jeremie',
        code: 'HTJEE',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.64294,
        longitude: -74.11205,
        iso3: 'HTI',
        iso3_op: 'HTI',
        country: 'Haiti',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3723593,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15061',
      geometry: { type: 'Point', coordinates: [-79.66885633, 43.44066213] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oakville',
        code: 'CAOAK',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.44066,
        longitude: -79.66886,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6092122,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15062',
      geometry: { type: 'Point', coordinates: [-71.00906688, 42.39857272] },
      geometry_name: 'shape',
      properties: {
        portname: 'Revere',
        code: 'USRVR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.39857,
        longitude: -71.00907,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4832294,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15063',
      geometry: { type: 'Point', coordinates: [-66.98453991, 44.90667312] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eastport',
        code: 'USEPM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.90667,
        longitude: -66.98454,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4963486,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15064',
      geometry: { type: 'Point', coordinates: [-60.46144168, 46.90291246] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dingwall',
        code: 'CADWL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.90291,
        longitude: -60.46144,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5939449,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15065',
      geometry: { type: 'Point', coordinates: [-90.40054568, 29.990203] },
      geometry_name: 'shape',
      properties: {
        portname: 'Good Hope',
        code: 'USGDH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.9902,
        longitude: -90.40055,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4325905,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15066',
      geometry: { type: 'Point', coordinates: [-81.14338791, 32.14427781] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Wentworth',
        code: 'USPWN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.14428,
        longitude: -81.14339,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4217075,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15067',
      geometry: { type: 'Point', coordinates: [-73.18257602, 41.17087514] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bridgeport',
        code: 'USBDR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.17088,
        longitude: -73.18258,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5282804,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15068',
      geometry: { type: 'Point', coordinates: [-61.01811477, 45.51041956] },
      geometry_name: 'shape',
      properties: {
        portname: 'Arichat',
        code: 'CAARI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.51042,
        longitude: -61.01811,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5886894,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15069',
      geometry: { type: 'Point', coordinates: [-122.72147652, 45.64386667] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vancouver',
        code: 'USBCC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.64387,
        longitude: -122.72148,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5814616,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15070',
      geometry: { type: 'Point', coordinates: [-123.86154859, 46.18569058] },
      geometry_name: 'shape',
      properties: {
        portname: 'Astoria',
        code: 'USAST',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.18569,
        longitude: -123.86155,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5711847,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15071',
      geometry: { type: 'Point', coordinates: [-53.07646532, 48.51537809] },
      geometry_name: 'shape',
      properties: {
        portname: 'Catalina',
        code: 'CACAT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.51538,
        longitude: -53.07647,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5918243,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15072',
      geometry: { type: 'Point', coordinates: [-123.64039205, 48.86553587] },
      geometry_name: 'shape',
      properties: {
        portname: 'Crofton',
        code: 'CACRO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.86554,
        longitude: -123.64039,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5932743,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15073',
      geometry: { type: 'Point', coordinates: [-89.69846246, 29.47126614] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Sulphur',
        code: 'USSUL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.47127,
        longitude: -89.69846,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4337859,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15074',
      geometry: { type: 'Point', coordinates: [-121.8198617, 38.01856107] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antioch',
        code: 'USANZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.01856,
        longitude: -121.81986,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5324200,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15075',
      geometry: { type: 'Point', coordinates: [-70.76121664, 41.70424423] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marion',
        code: 'USMZZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.70424,
        longitude: -70.76122,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4943144,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15076',
      geometry: { type: 'Point', coordinates: [-66.12235675, 43.83332674] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yarmouth',
        code: 'CAYQI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.83333,
        longitude: -66.12236,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6185217,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15077',
      geometry: { type: 'Point', coordinates: [-75.65097544, 44.59051422] },
      geometry_name: 'shape',
      properties: {
        portname: 'Morristown',
        code: 'USMJG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.59051,
        longitude: -75.65098,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5127556,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15078',
      geometry: { type: 'Point', coordinates: [-126.84496142, 49.97874519] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zeballos',
        code: 'CAZEB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.97875,
        longitude: -126.84496,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6185911,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15079',
      geometry: { type: 'Point', coordinates: [-131.69082757, 55.3542968] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ketchikan',
        code: 'USKTN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.3543,
        longitude: -131.69083,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5554428,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15080',
      geometry: { type: 'Point', coordinates: [-122.3815757, 47.81171426] },
      geometry_name: 'shape',
      properties: {
        portname: 'Edmonds',
        code: 'USEOW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.81171,
        longitude: -122.38158,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5793427,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15081',
      geometry: { type: 'Point', coordinates: [11.85115758, 54.95630739] },
      geometry_name: 'shape',
      properties: {
        portname: 'Orehoved',
        code: 'DKORE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.95631,
        longitude: 11.85116,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2615391,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15082',
      geometry: { type: 'Point', coordinates: [11.88503603, 54.99455272] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vordingborg',
        code: 'DKVOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.99455,
        longitude: 11.88504,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2610021,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15083',
      geometry: { type: 'Point', coordinates: [12.92767958, 55.58977221] },
      geometry_name: 'shape',
      properties: {
        portname: 'Limhamn',
        code: 'SELIM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.58977,
        longitude: 12.92768,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2695019,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15084',
      geometry: { type: 'Point', coordinates: [-4.63817485, 55.46915064] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ayr',
        code: 'GBAYR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.46915,
        longitude: -4.63817,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2656708,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15085',
      geometry: { type: 'Point', coordinates: [23.01011863, 37.91788732] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalamaki',
        code: 'GRKLM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 37.91789,
        longitude: 23.01012,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 261623,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15086',
      geometry: { type: 'Point', coordinates: [16.17253301, 41.88541348] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vieste',
        code: 'ITVIE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.88541,
        longitude: 16.17253,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3164387,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15087',
      geometry: { type: 'Point', coordinates: [14.90050804, 44.98996816] },
      geometry_name: 'shape',
      properties: {
        portname: 'Senj',
        code: 'HRSEN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.98997,
        longitude: 14.90051,
        iso3: 'HRV',
        iso3_op: 'HRV',
        country: 'Croatia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3191055,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15088',
      geometry: { type: 'Point', coordinates: [-1.21808956, 46.16013623] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Pallice',
        code: 'FRLPE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.16014,
        longitude: -1.21809,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3007458,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15089',
      geometry: { type: 'Point', coordinates: [9.74890617, 59.00353625] },
      geometry_name: 'shape',
      properties: {
        portname: 'Langesund',
        code: 'NOLAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.00354,
        longitude: 9.74891,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3148354,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15090',
      geometry: { type: 'Point', coordinates: [16.46489372, 57.16234476] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paskallavik',
        code: 'SEPAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.16234,
        longitude: 16.46489,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2684386,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15091',
      geometry: { type: 'Point', coordinates: [-87.42196366, 41.66337358] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gary',
        code: 'USGYY',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.66337,
        longitude: -87.42196,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4920607,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15092',
      geometry: { type: 'Point', coordinates: [-83.11307074, 42.09685881] },
      geometry_name: 'shape',
      properties: {
        portname: 'Amherstburg',
        code: 'CAAMH',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.09686,
        longitude: -83.11307,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5884472,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15093',
      geometry: { type: 'Point', coordinates: [-122.14066384, 38.04440815] },
      geometry_name: 'shape',
      properties: {
        portname: 'Benicia',
        code: 'USBNC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.04441,
        longitude: -122.14066,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5327550,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15094',
      geometry: { type: 'Point', coordinates: [-123.80371397, 39.42453569] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fort Bragg',
        code: 'USFOB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.42454,
        longitude: -123.80371,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5350039,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15095',
      geometry: { type: 'Point', coordinates: [-72.90349843, 41.29319975] },
      geometry_name: 'shape',
      properties: {
        portname: 'New Haven',
        code: 'USHVN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.2932,
        longitude: -72.9035,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4839366,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15096',
      geometry: { type: 'Point', coordinates: [22.14967975, -34.1798441] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mossel Bay',
        code: 'ZAMZY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.17984,
        longitude: 22.14968,
        iso3: 'ZAF',
        iso3_op: 'ZAF',
        country: 'South Africa',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 973709,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15097',
      geometry: { type: 'Point', coordinates: [39.4138189, -8.74605316] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kilwa Kivinje',
        code: 'TZKIK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.74605,
        longitude: 39.41382,
        iso3: null,
        iso3_op: 'TZA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 157379,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15098',
      geometry: { type: 'Point', coordinates: [135.31291384, 35.45309587] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maizuru',
        code: 'JPMAI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.4531,
        longitude: 135.31291,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1857766,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15099',
      geometry: { type: 'Point', coordinates: [79.86323559, 9.7022989] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kayts',
        code: 'LKKAY',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.7023,
        longitude: 79.86324,
        iso3: 'LKA',
        iso3_op: 'LKA',
        country: 'Sri Lanka',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1240779,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15100',
      geometry: { type: 'Point', coordinates: [-55.86762011, -27.32010611] },
      geometry_name: 'shape',
      properties: {
        portname: 'Encarnacion',
        code: 'PYENO',
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -27.32011,
        longitude: -55.86762,
        iso3: 'PRY',
        iso3_op: 'PRY',
        country: 'Paraguay',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3438735,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15101',
      geometry: { type: 'Point', coordinates: [-63.55456525, 8.143952] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ciudad Bolivar',
        code: 'VECBL',
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.14395,
        longitude: -63.55457,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3645532,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15102',
      geometry: { type: 'Point', coordinates: [28.87948943, 70.14803168] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nesseby',
        code: 'NONSB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 70.14803,
        longitude: 28.87949,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 778560,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15103',
      geometry: { type: 'Point', coordinates: [-4.1979278, 51.05203956] },
      geometry_name: 'shape',
      properties: {
        portname: 'Appledore',
        code: 'GBAPP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 51.05204,
        longitude: -4.19793,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2657233,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15104',
      geometry: { type: 'Point', coordinates: [1.40864425, 51.32986862] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ramsgate',
        code: 'GBRMG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.32987,
        longitude: 1.40864,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2639660,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15105',
      geometry: { type: 'Point', coordinates: [-4.9375648, 50.54109221] },
      geometry_name: 'shape',
      properties: {
        portname: 'Padstow',
        code: 'GBPAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.54109,
        longitude: -4.93756,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2640685,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15106',
      geometry: { type: 'Point', coordinates: [-1.31604039, 50.81578469] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hamble',
        code: 'GBHAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 50.81578,
        longitude: -1.31604,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2647585,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15107',
      geometry: { type: 'Point', coordinates: [5.71640925, 50.88614144] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maastricht',
        code: 'NLMST',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.88614,
        longitude: 5.71641,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2751283,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15108',
      geometry: { type: 'Point', coordinates: [-2.98880549, 51.16218566] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bridgwater',
        code: 'GBBRW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.16219,
        longitude: -2.98881,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2654730,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15109',
      geometry: { type: 'Point', coordinates: [3.1906931, 51.33353802] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zeebrugge',
        code: 'BEZEE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.33354,
        longitude: 3.19069,
        iso3: 'BEL',
        iso3_op: 'BEL',
        country: 'Belgium',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2783307,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15110',
      geometry: { type: 'Point', coordinates: [-2.9862532, 51.55836787] },
      geometry_name: 'shape',
      properties: {
        portname: 'Newport',
        code: 'GBNPT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.55837,
        longitude: -2.98625,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2641598,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15111',
      geometry: { type: 'Point', coordinates: [6.25111758, 51.82956459] },
      geometry_name: 'shape',
      properties: {
        portname: 'Emmerich',
        code: 'DEEMM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.82956,
        longitude: 6.25112,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2930509,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15112',
      geometry: { type: 'Point', coordinates: [-7.10609892, 52.26115804] },
      geometry_name: 'shape',
      properties: {
        portname: 'Waterford',
        code: 'IEWAT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.26116,
        longitude: -7.1061,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2960992,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15113',
      geometry: { type: 'Point', coordinates: [-9.73572066, 52.25868719] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tralee',
        code: 'IETRA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.25869,
        longitude: -9.73572,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2961123,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15114',
      geometry: { type: 'Point', coordinates: [8.46255731, 53.22822244] },
      geometry_name: 'shape',
      properties: {
        portname: 'Elsfleth',
        code: 'DEELS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.22822,
        longitude: 8.46256,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2930726,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15115',
      geometry: { type: 'Point', coordinates: [-2.60581147, 53.38636172] },
      geometry_name: 'shape',
      properties: {
        portname: 'Warrington',
        code: 'GBWRN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.38636,
        longitude: -2.60581,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2634739,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15116',
      geometry: { type: 'Point', coordinates: [10.09692828, 33.91427887] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gabes',
        code: 'TNGAE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.91428,
        longitude: 10.09693,
        iso3: 'TUN',
        iso3_op: 'TUN',
        country: 'Tunisia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2468369,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15117',
      geometry: { type: 'Point', coordinates: [-6.84223602, 34.03030054] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rabat',
        code: 'MARBA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.0303,
        longitude: -6.84224,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2538475,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15118',
      geometry: { type: 'Point', coordinates: [132.97114901, 34.10718013] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mategata',
        code: 'JPMAG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.10718,
        longitude: 132.97115,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6417033,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15119',
      geometry: { type: 'Point', coordinates: [132.99386655, 34.25051173] },
      geometry_name: 'shape',
      properties: {
        portname: 'Miyaura',
        code: 'JPOMS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.25051,
        longitude: 132.99387,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1926087,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15120',
      geometry: { type: 'Point', coordinates: [136.84370235, 34.48844823] },
      geometry_name: 'shape',
      properties: {
        portname: 'Toba',
        code: 'JPTOB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.48845,
        longitude: 136.8437,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1850345,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15121',
      geometry: { type: 'Point', coordinates: [0.07992022, 35.93472944] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mostaganem',
        code: 'DZMOS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.93473,
        longitude: 0.07992,
        iso3: 'DZA',
        iso3_op: 'DZA',
        country: 'Algeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2487134,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15122',
      geometry: { type: 'Point', coordinates: [3.91685447, 36.91635811] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dellys',
        code: 'DZDEL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.91636,
        longitude: 3.91685,
        iso3: null,
        iso3_op: 'DZA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2500583,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15123',
      geometry: { type: 'Point', coordinates: [98.9087615, 8.04692978] },
      geometry_name: 'shape',
      properties: {
        portname: 'Krabi',
        code: 'THKBV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.04693,
        longitude: 98.90876,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1152633,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15124',
      geometry: { type: 'Point', coordinates: [132.5436218, 34.24276367] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kure',
        code: 'JPKRE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.24276,
        longitude: 132.54362,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1858296,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15125',
      geometry: { type: 'Point', coordinates: [7.42140101, 1.64429546] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santo Antonio',
        code: 'STSAA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.6443,
        longitude: 7.4214,
        iso3: 'STP',
        iso3_op: 'STP',
        country: 'Sao Tome and Principe',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2410805,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15126',
      geometry: { type: 'Point', coordinates: [122.07733854, 6.90241415] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zamboanga',
        code: 'PHBLU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.90241,
        longitude: 122.07734,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1679432,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15127',
      geometry: { type: 'Point', coordinates: [126.21900025, 6.95072765] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mati',
        code: 'PHMXI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.95073,
        longitude: 126.219,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1700360,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15128',
      geometry: { type: 'Point', coordinates: [148.4126833, -8.67149571] },
      geometry_name: 'shape',
      properties: {
        portname: 'Buna',
        code: 'PGBNA',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -8.6715,
        longitude: 148.41268,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2098831,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15129',
      geometry: { type: 'Point', coordinates: [168.34373743, -46.59831912] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bluff',
        code: 'NZBLU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -46.59832,
        longitude: 168.34374,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2206939,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15130',
      geometry: { type: 'Point', coordinates: [137.63869552, -35.65565983] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kingscote',
        code: 'AUKGC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -35.65566,
        longitude: 137.6387,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2068429,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15131',
      geometry: { type: 'Point', coordinates: [122.20753845, -18.00399575] },
      geometry_name: 'shape',
      properties: {
        portname: 'Broome',
        code: 'AUBME',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -18.004,
        longitude: 122.20754,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2075720,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15132',
      geometry: { type: 'Point', coordinates: [-39.0321, -14.78390047] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ilheus',
        code: 'BRIOS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -14.7839,
        longitude: -39.0321,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3461408,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15133',
      geometry: { type: 'Point', coordinates: [-37.04809121, -10.9078295] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aracaju',
        code: 'BRAJU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -10.90783,
        longitude: -37.04809,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3471872,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15134',
      geometry: { type: 'Point', coordinates: [-140.09469768, -8.92177998] },
      geometry_name: 'shape',
      properties: {
        portname: 'Taiohae',
        code: 'PFTAI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.92178,
        longitude: -140.0947,
        iso3: 'PYF',
        iso3_op: 'PYF',
        country: 'French Polynesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 8063344,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15135',
      geometry: { type: 'Point', coordinates: [113.97317287, 4.38349131] },
      geometry_name: 'shape',
      properties: {
        portname: 'Miri',
        code: 'MYMYY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.38349,
        longitude: 113.97317,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1738050,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15136',
      geometry: { type: 'Point', coordinates: [100.37337207, 5.41033962] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bagan Luar',
        code: 'MYBWH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.41034,
        longitude: 100.37337,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1781984,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15137',
      geometry: { type: 'Point', coordinates: [118.11667377, 5.83815189] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sandakan',
        code: 'MYSDK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.83815,
        longitude: 118.11667,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1734052,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15138',
      geometry: { type: 'Point', coordinates: [32.86656692, 36.06968563] },
      geometry_name: 'shape',
      properties: {
        portname: 'Anamur',
        code: 'TRANA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.06969,
        longitude: 32.86657,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 323828,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15139',
      geometry: { type: 'Point', coordinates: [-63.81913542, 10.9774759] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pampatar',
        code: 'VEPMP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.97748,
        longitude: -63.81914,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3630893,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15140',
      geometry: { type: 'Point', coordinates: [123.85658339, 8.33246831] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jimenez',
        code: 'PHJNZ',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.33247,
        longitude: 123.85658,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1710181,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15141',
      geometry: { type: 'Point', coordinates: [120.8070788, 27.96917859] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wenzhou',
        code: 'CNWNZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.96918,
        longitude: 120.80708,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1791388,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15142',
      geometry: { type: 'Point', coordinates: [132.06321549, 34.88376478] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hamada',
        code: 'JPHMD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 34.88376,
        longitude: 132.06322,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1863310,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15143',
      geometry: { type: 'Point', coordinates: [27.8687864, 40.36566378] },
      geometry_name: 'shape',
      properties: {
        portname: 'Edincik',
        code: 'TREDK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.36566,
        longitude: 27.86879,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 747714,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15144',
      geometry: { type: 'Point', coordinates: [28.58538636, 43.80999189] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mangalia',
        code: 'ROMAG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.80999,
        longitude: 28.58539,
        iso3: 'ROU',
        iso3_op: 'ROU',
        country: 'Romania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 673921,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15145',
      geometry: { type: 'Point', coordinates: [138.19018971, -35.52642128] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rapid Bay',
        code: 'AURAB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -35.52642,
        longitude: 138.19019,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2062673,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15146',
      geometry: { type: 'Point', coordinates: [138.01268936, -33.17084991] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Pirie',
        code: 'AUPPI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.17085,
        longitude: 138.01269,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2063030,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15147',
      geometry: { type: 'Point', coordinates: [137.76028557, -32.49391591] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Augusta',
        code: 'AUPUG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.49392,
        longitude: 137.76029,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2063056,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15148',
      geometry: { type: 'Point', coordinates: [114.59219383, -28.77671826] },
      geometry_name: 'shape',
      properties: {
        portname: 'Geraldton',
        code: 'AUGET',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -28.77672,
        longitude: 114.59219,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2070998,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15149',
      geometry: { type: 'Point', coordinates: [131.40872711, 31.57223942] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aburatsu',
        code: 'JPABU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.57224,
        longitude: 131.40873,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1865760,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15150',
      geometry: { type: 'Point', coordinates: [130.39990739, 32.21901311] },
      geometry_name: 'shape',
      properties: {
        portname: 'Minamata',
        code: 'JPMIN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 32.21901,
        longitude: 130.39991,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1857208,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15151',
      geometry: { type: 'Point', coordinates: [129.72267876, 33.16377025] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sasebo',
        code: 'JPSSB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.16377,
        longitude: 129.72268,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1852899,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15152',
      geometry: { type: 'Point', coordinates: [129.95937237, 33.46867628] },
      geometry_name: 'shape',
      properties: {
        portname: 'Karatsu',
        code: 'JPKAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.46868,
        longitude: 129.95937,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1860063,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15153',
      geometry: { type: 'Point', coordinates: [144.84842635, 43.04502354] },
      geometry_name: 'shape',
      properties: {
        portname: 'Akkeshi',
        code: 'JPAKE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.04502,
        longitude: 144.84843,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2130677,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15154',
      geometry: { type: 'Point', coordinates: [130.84741807, -12.46466627] },
      geometry_name: 'shape',
      properties: {
        portname: 'Darwin',
        code: 'AUDRW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.46467,
        longitude: 130.84742,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2073124,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15155',
      geometry: { type: 'Point', coordinates: [103.8407334, 1.26740442] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanjong Pagar',
        code: 'SGTPG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.2674,
        longitude: 103.84073,
        iso3: 'SGP',
        iso3_op: 'SGP',
        country: 'Singapore',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1880131,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15156',
      geometry: { type: 'Point', coordinates: [118.6081, 4.47823387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Semporna',
        code: 'MYSMM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 4.47823,
        longitude: 118.6081,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1733697,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15157',
      geometry: { type: 'Point', coordinates: [114.95099704, 4.87558441] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bandar Seri Begawan',
        code: 'BNBWN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.87558,
        longitude: 114.951,
        iso3: 'BRN',
        iso3_op: 'BRN',
        country: 'Brunei Darussalam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1820906,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15158',
      geometry: { type: 'Point', coordinates: [-55.08756386, 5.60895481] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paranam',
        code: 'SRPRM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.60895,
        longitude: -55.08756,
        iso3: 'SUR',
        iso3_op: 'SUR',
        country: 'Suriname',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3383327,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15159',
      geometry: { type: 'Point', coordinates: [-58.62053801, 6.39639661] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bartica',
        code: 'GYGFO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.3964,
        longitude: -58.62054,
        iso3: 'GUY',
        iso3_op: 'GUY',
        country: 'Guyana',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3379507,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15160',
      geometry: { type: 'Point', coordinates: [121.86911168, 6.54592993] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Holland',
        code: 'PHPHD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.54593,
        longitude: 121.86911,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1692865,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15161',
      geometry: { type: 'Point', coordinates: [76.58331718, 8.87820783] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quilon',
        code: 'INQUI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.87821,
        longitude: 76.58332,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1259091,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15162',
      geometry: { type: 'Point', coordinates: [122.8011302, 9.36794215] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tolong',
        code: 'PHTLG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.36794,
        longitude: 122.80113,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1697205,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15163',
      geometry: { type: 'Point', coordinates: [-69.57089393, 11.45946109] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coro',
        code: 'VECZE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.45946,
        longitude: -69.57089,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3645213,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15164',
      geometry: { type: 'Point', coordinates: [79.77496705, 11.7165907] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cuddalore',
        code: 'INCDL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.71659,
        longitude: 79.77497,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1273802,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15165',
      geometry: { type: 'Point', coordinates: [133.25629858, 34.43983396] },
      geometry_name: 'shape',
      properties: {
        portname: 'Matsunaga',
        code: 'JPMNG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.43983,
        longitude: 133.2563,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1857514,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15166',
      geometry: { type: 'Point', coordinates: [133.9277835, 34.45187322] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hibi',
        code: 'JPHIB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.45187,
        longitude: 133.92778,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1862888,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15167',
      geometry: { type: 'Point', coordinates: [136.63247112, 34.95442991] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yokkaichi',
        code: 'JPYKK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.95443,
        longitude: 136.63247,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1848373,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15168',
      geometry: { type: 'Point', coordinates: [141.2863555, 38.41708541] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ishinomaki',
        code: 'JPISM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.41709,
        longitude: 141.28636,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2112576,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15169',
      geometry: { type: 'Point', coordinates: [121.60249639, 38.93865669] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dalian',
        code: 'CNDLC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.93866,
        longitude: 121.6025,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1814087,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15170',
      geometry: { type: 'Point', coordinates: [34.78449709, 64.5283856] },
      geometry_name: 'shape',
      properties: {
        portname: 'Belomorsk',
        code: 'RUBEM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.52839,
        longitude: 34.7845,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 577922,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15171',
      geometry: { type: 'Point', coordinates: [-52.33336243, -31.78185645] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pelotas',
        code: 'BRPET',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -31.78186,
        longitude: -52.33336,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3454244,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15172',
      geometry: { type: 'Point', coordinates: [-67.61644578, 5.69592297] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Ayacucho',
        code: 'VEPYH',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.69592,
        longitude: -67.61645,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3629710,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15173',
      geometry: { type: 'Point', coordinates: [117.02233586, 30.50504831] },
      geometry_name: 'shape',
      properties: {
        portname: 'Anqing',
        code: 'CNAQG',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.50505,
        longitude: 117.02234,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1817993,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15174',
      geometry: { type: 'Point', coordinates: [117.77274618, 30.98313363] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tongling',
        code: 'CNTOL',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.98313,
        longitude: 117.77275,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1792622,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15175',
      geometry: { type: 'Point', coordinates: [-46.29355961, -23.96262968] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santos',
        code: 'BRSSZ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -23.96263,
        longitude: -46.29356,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3449433,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15176',
      geometry: { type: 'Point', coordinates: [-84.71524157, 9.91275038] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Caldera',
        code: 'CRCAL',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.91275,
        longitude: -84.71524,
        iso3: null,
        iso3_op: 'CRI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3624515,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15177',
      geometry: { type: 'Point', coordinates: [-102.17371617, 17.95307637] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lazaro Cardenas',
        code: 'MXLZC',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.95308,
        longitude: -102.17372,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4026082,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15178',
      geometry: { type: 'Point', coordinates: [-104.31504801, 19.05300465] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manzanillo',
        code: 'MXZLO',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.053,
        longitude: -104.31505,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3996663,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15179',
      geometry: { type: 'Point', coordinates: [-106.42091405, 23.18715997] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mazatlan',
        code: 'MXMZT',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.18716,
        longitude: -106.42091,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3996322,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15180',
      geometry: { type: 'Point', coordinates: [-58.70126989, -38.57627268] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quequen',
        code: 'ARQQN',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -38.57627,
        longitude: -58.70127,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3429664,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15181',
      geometry: { type: 'Point', coordinates: [-48.655, -26.91096809] },
      geometry_name: 'shape',
      properties: {
        portname: 'Itajai',
        code: 'BRITJ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -26.91097,
        longitude: -48.655,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3460845,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15182',
      geometry: { type: 'Point', coordinates: [-70.403259, -23.64919863] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antofagasta',
        code: 'CLANF',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -23.6492,
        longitude: -70.40326,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3899539,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15183',
      geometry: { type: 'Point', coordinates: [-40.3372883, -20.32233489] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vitoria',
        code: 'BRVIX',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.32233,
        longitude: -40.33729,
        iso3: null,
        iso3_op: 'BRA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3444924,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15184',
      geometry: { type: 'Point', coordinates: [48.80079687, -19.89986407] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mahanoro',
        code: 'MGVVB',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -19.89986,
        longitude: 48.8008,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1062553,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15185',
      geometry: { type: 'Point', coordinates: [-79.9072564, 9.33755103] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cristobal',
        code: 'PACTB',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.33755,
        longitude: -79.90726,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3711828,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15186',
      geometry: { type: 'Point', coordinates: [-73.4771352, -42.14248981] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quemchi',
        code: 'CLQMC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -42.14249,
        longitude: -73.47714,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3874390,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15187',
      geometry: { type: 'Point', coordinates: [124.20120195, 7.25252606] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cotabato',
        code: 'PHCBO',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 7.25253,
        longitude: 124.2012,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1716771,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15188',
      geometry: { type: 'Point', coordinates: [-89.31930382, 13.48703279] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Libertad',
        code: 'SVLLD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.48703,
        longitude: -89.3193,
        iso3: 'SLV',
        iso3_op: 'SLV',
        country: 'El Salvador',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3585157,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15189',
      geometry: { type: 'Point', coordinates: [30.14773882, 36.29548017] },
      geometry_name: 'shape',
      properties: {
        portname: 'Finike',
        code: 'TRFIN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.29548,
        longitude: 30.14774,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 314923,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15190',
      geometry: { type: 'Point', coordinates: [-64.69746226, 17.7475039] },
      geometry_name: 'shape',
      properties: {
        portname: 'Christiansted',
        code: 'VICTD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.7475,
        longitude: -64.69746,
        iso3: 'VIR',
        iso3_op: 'VIR',
        country: 'United States Virgin Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4795479,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15191',
      geometry: { type: 'Point', coordinates: [-58.41581779, -33.87045984] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nueva Palmira',
        code: 'UYNVP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.87046,
        longitude: -58.41582,
        iso3: 'URY',
        iso3_op: 'URY',
        country: 'Uruguay',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3441481,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15192',
      geometry: { type: 'Point', coordinates: [-70.20889774, -22.09614133] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tocopilla',
        code: 'CLTOQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -22.09614,
        longitude: -70.2089,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3869716,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15193',
      geometry: { type: 'Point', coordinates: [-63.24282514, 10.67419774] },
      geometry_name: 'shape',
      properties: {
        portname: 'Carupano',
        code: 'VECUP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.6742,
        longitude: -63.24283,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3646382,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15194',
      geometry: { type: 'Point', coordinates: [-94.41633849, 18.13229106] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coatzacoalcos',
        code: 'MXCOA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.13229,
        longitude: -94.41634,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3530517,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15195',
      geometry: { type: 'Point', coordinates: [-67.15729012, 18.21607708] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mayaguez',
        code: 'PRMAZ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.21608,
        longitude: -67.15729,
        iso3: null,
        iso3_op: 'PRI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4566385,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15196',
      geometry: { type: 'Point', coordinates: [-77.78553933, 24.72413838] },
      geometry_name: 'shape',
      properties: {
        portname: 'Andros Town',
        code: 'BSASD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.72414,
        longitude: -77.78554,
        iso3: 'BHS',
        iso3_op: 'BHS',
        country: 'Bahamas',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3572906,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15197',
      geometry: { type: 'Point', coordinates: [-77.32629258, 25.07488667] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nassau',
        code: 'BSNAS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.07489,
        longitude: -77.32629,
        iso3: 'BHS',
        iso3_op: 'BHS',
        country: 'Bahamas',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3571824,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15198',
      geometry: { type: 'Point', coordinates: [39.00121869, 41.03658472] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gorele',
        code: 'TRGOR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.03658,
        longitude: 39.00122,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 746565,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15199',
      geometry: { type: 'Point', coordinates: [-58.95209238, -34.15497705] },
      geometry_name: 'shape',
      properties: {
        portname: 'Campana',
        code: 'ARCMP',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.15498,
        longitude: -58.95209,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3435810,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15200',
      geometry: { type: 'Point', coordinates: [-58.98641366, -34.11436218] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zarate',
        code: 'ARZAE',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.11436,
        longitude: -58.98641,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3427213,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15201',
      geometry: { type: 'Point', coordinates: [-60.61862677, -32.97700299] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rosario',
        code: 'ARROS',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.977,
        longitude: -60.61863,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3838583,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15202',
      geometry: { type: 'Point', coordinates: [109.6919182, -6.85924784] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pekalongan',
        code: 'IDPEX',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.85925,
        longitude: 109.69192,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1631766,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15203',
      geometry: { type: 'Point', coordinates: [122.79623831, -0.95339995] },
      geometry_name: 'shape',
      properties: {
        portname: 'Luwuk',
        code: 'IDLUW',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.9534,
        longitude: 122.79624,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1637001,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15204',
      geometry: { type: 'Point', coordinates: [-81.54033921, 23.06549544] },
      geometry_name: 'shape',
      properties: {
        portname: 'Matanzas',
        code: 'CUQMA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.0655,
        longitude: -81.54034,
        iso3: null,
        iso3_op: 'CUB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3547398,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15205',
      geometry: { type: 'Point', coordinates: [-60.64530386, -32.05853923] },
      geometry_name: 'shape',
      properties: {
        portname: 'Diamante',
        code: 'ARDME',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.05854,
        longitude: -60.6453,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3859384,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15206',
      geometry: { type: 'Point', coordinates: [-2.33868227, 53.47828883] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eccles',
        code: 'GBECS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.47829,
        longitude: -2.33868,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2650278,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15207',
      geometry: { type: 'Point', coordinates: [-73.75894687, -42.48079528] },
      geometry_name: 'shape',
      properties: {
        portname: 'Castro',
        code: 'CLWCA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -42.4808,
        longitude: -73.75895,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3896218,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15208',
      geometry: { type: 'Point', coordinates: [-70.32229764, -18.47636028] },
      geometry_name: 'shape',
      properties: {
        portname: 'Arica',
        code: 'CLARI',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -18.47636,
        longitude: -70.3223,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3899361,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15209',
      geometry: { type: 'Point', coordinates: [51.5492299, 25.29709714] },
      geometry_name: 'shape',
      properties: {
        portname: 'Doha',
        code: 'QADOH',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.2971,
        longitude: 51.54923,
        iso3: null,
        iso3_op: 'QAT',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 290030,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15210',
      geometry: { type: 'Point', coordinates: [7.00264177, 4.77543681] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Harcourt',
        code: 'NGPHC',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.77544,
        longitude: 7.00264,
        iso3: 'NGA',
        iso3_op: 'NGA',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2324774,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15211',
      geometry: { type: 'Point', coordinates: [-10.05010088, 5.85866742] },
      geometry_name: 'shape',
      properties: {
        portname: 'Buchanan',
        code: 'LRUCN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.85867,
        longitude: -10.0501,
        iso3: null,
        iso3_op: 'LBR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2278158,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15212',
      geometry: { type: 'Point', coordinates: [-16.26820086, 12.58786643] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ziguinchor',
        code: 'SNZIG',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.58787,
        longitude: -16.2682,
        iso3: 'SEN',
        iso3_op: 'SEN',
        country: 'Senegal',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2243940,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15213',
      geometry: { type: 'Point', coordinates: [1.51489083, 6.20950316] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kpeme',
        code: 'TGKPE',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.2095,
        longitude: 1.51489,
        iso3: 'TGO',
        iso3_op: 'TGO',
        country: 'Togo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2365514,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15214',
      geometry: { type: 'Point', coordinates: [57.7668705, 25.64535008] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jask',
        code: 'IRJAK',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.64535,
        longitude: 57.76687,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 130276,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15215',
      geometry: { type: 'Point', coordinates: [8.1633589, 53.52750131] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wilhelmshaven',
        code: 'DEWVN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.5275,
        longitude: 8.16336,
        iso3: null,
        iso3_op: 'DEU',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2808720,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15216',
      geometry: { type: 'Point', coordinates: [-3.0094694, 53.91786105] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fleetwood',
        code: 'GBFLE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.91786,
        longitude: -3.00947,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2649312,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15217',
      geometry: { type: 'Point', coordinates: [36.18749221, 36.59349865] },
      geometry_name: 'shape',
      properties: {
        portname: 'Iskenderun',
        code: 'TRISK',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 36.5935,
        longitude: 36.18749,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 311111,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15218',
      geometry: { type: 'Point', coordinates: [-5.66820676, 54.66467641] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bangor',
        code: 'GBBNG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.66468,
        longitude: -5.66821,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2656396,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15219',
      geometry: { type: 'Point', coordinates: [9.87799668, 54.75585121] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gelting',
        code: 'DEGEL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.75585,
        longitude: 9.878,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2921444,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15220',
      geometry: { type: 'Point', coordinates: [12.63179876, 56.4282054] },
      geometry_name: 'shape',
      properties: {
        portname: 'Torekov',
        code: 'SETOV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.42821,
        longitude: 12.6318,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2668244,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15221',
      geometry: { type: 'Point', coordinates: [-6.67533291, 55.14429335] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coleraine',
        code: 'GBCLR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.14429,
        longitude: -6.67533,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2652586,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15222',
      geometry: { type: 'Point', coordinates: [11.63647805, 54.80237232] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sakskobing',
        code: 'DKSAX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.80237,
        longitude: 11.63648,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2614328,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15223',
      geometry: { type: 'Point', coordinates: [9.86000096, 55.85679054] },
      geometry_name: 'shape',
      properties: {
        portname: 'Horsens',
        code: 'DKHOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.85679,
        longitude: 9.86,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2619771,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15224',
      geometry: { type: 'Point', coordinates: [-2.08748538, 57.1446869] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aberdeen',
        code: 'GBABD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.14469,
        longitude: -2.08749,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2657832,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15225',
      geometry: { type: 'Point', coordinates: [11.37106266, 59.11838849] },
      geometry_name: 'shape',
      properties: {
        portname: 'Halden',
        code: 'NOHAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.11839,
        longitude: 11.37106,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3154209,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15226',
      geometry: { type: 'Point', coordinates: [10.92189679, 59.21061701] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fredrikstad',
        code: 'NOFRK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.21062,
        longitude: 10.9219,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3156529,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15227',
      geometry: { type: 'Point', coordinates: [12.31719856, 58.38356117] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vanersborg',
        code: 'SEVAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.38356,
        longitude: 12.3172,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2665171,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15228',
      geometry: { type: 'Point', coordinates: [-77.13249254, 17.89081021] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Esquivel',
        code: 'JMPEV',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.89081,
        longitude: -77.13249,
        iso3: 'JAM',
        iso3_op: 'JAM',
        country: 'Jamaica',
        lastcheckdate: '2019-05-22T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3489002,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15229',
      geometry: { type: 'Point', coordinates: [23.30966566, 59.91813665] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skogby',
        code: 'FISKB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.91814,
        longitude: 23.30967,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 843299,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15230',
      geometry: { type: 'Point', coordinates: [18.59772323, 60.06023377] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hallstavik',
        code: 'SEHAK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 60.06023,
        longitude: 18.59772,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2708426,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15231',
      geometry: { type: 'Point', coordinates: [21.4581845, 61.12755164] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rauma',
        code: 'FIRAU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.12755,
        longitude: 21.45818,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 639734,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15232',
      geometry: { type: 'Point', coordinates: [17.11263484, 61.72441494] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hudiksvall',
        code: 'SEHUV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.72441,
        longitude: 17.11263,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2704613,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15233',
      geometry: { type: 'Point', coordinates: [24.15261311, 65.85204318] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tornio',
        code: 'FITOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.85204,
        longitude: 24.15261,
        iso3: 'SWE',
        iso3_op: 'FIN',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 634093,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15234',
      geometry: { type: 'Point', coordinates: [23.42665241, 65.78674951] },
      geometry_name: 'shape',
      properties: {
        portname: 'Batskarsnas',
        code: 'SEBAT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.78675,
        longitude: 23.42665,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 606660,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15235',
      geometry: { type: 'Point', coordinates: [3.7014847, 43.40707131] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sete',
        code: 'FRSET',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.40707,
        longitude: 3.70148,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2974733,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15236',
      geometry: { type: 'Point', coordinates: [12.30471828, 58.29849421] },
      geometry_name: 'shape',
      properties: {
        portname: 'Trollhattan',
        code: 'SETHN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.29849,
        longitude: 12.30472,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2667303,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15237',
      geometry: { type: 'Point', coordinates: [35.65674771, 34.25712676] },
      geometry_name: 'shape',
      properties: {
        portname: 'Batroun',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.25713,
        longitude: 35.65675,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 280328,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15238',
      geometry: { type: 'Point', coordinates: [-78.73142089, 1.81905758] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tumaco',
        code: 'COTCO',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.81906,
        longitude: -78.73142,
        iso3: null,
        iso3_op: 'COL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3666640,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15239',
      geometry: { type: 'Point', coordinates: [113.9465764, -7.044748] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalianget',
        code: 'IDKAT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.04475,
        longitude: 113.94658,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1641977,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15240',
      geometry: { type: 'Point', coordinates: [117.48763056, 0.08552692] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bontang',
        code: 'IDBXT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.08553,
        longitude: 117.48763,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1648186,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15241',
      geometry: { type: 'Point', coordinates: [-17.04030337, 20.91411458] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nouadhibou',
        code: 'MRNDB',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.91411,
        longitude: -17.0403,
        iso3: 'MRT',
        iso3_op: 'MRT',
        country: 'Mauritania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2377457,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15242',
      geometry: { type: 'Point', coordinates: [-77.61715076, 20.68880594] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guayabal',
        code: 'CUGYB',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.68881,
        longitude: -77.61715,
        iso3: null,
        iso3_op: 'CUB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3557534,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15243',
      geometry: { type: 'Point', coordinates: [-79.98983116, 21.75277129] },
      geometry_name: 'shape',
      properties: {
        portname: 'Casilda',
        code: 'CUCAS',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.75277,
        longitude: -79.98983,
        iso3: null,
        iso3_op: 'CUB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3565055,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15244',
      geometry: { type: 'Point', coordinates: [-71.34956679, -17.64996861] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ilo',
        code: 'PEILQ',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.64997,
        longitude: -71.34957,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3938415,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15245',
      geometry: { type: 'Point', coordinates: [-76.21871721, -13.70988937] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pisco',
        code: 'PEPIO',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -13.70989,
        longitude: -76.21872,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3932145,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15246',
      geometry: { type: 'Point', coordinates: [143.21050649, -9.06786855] },
      geometry_name: 'shape',
      properties: {
        portname: 'Daru',
        code: 'PGDAU',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -9.06787,
        longitude: 143.21051,
        iso3: 'PNG',
        iso3_op: 'PNG',
        country: 'Papua New Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2098329,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15247',
      geometry: { type: 'Point', coordinates: [-79.58367533, 43.55165228] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Credit',
        code: 'CAPCR',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.55165,
        longitude: -79.58368,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6111708,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15248',
      geometry: { type: 'Point', coordinates: [-77.37747351, 44.15532343] },
      geometry_name: 'shape',
      properties: {
        portname: 'Belleville',
        code: 'CABLV',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.15532,
        longitude: -77.37747,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5897884,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15249',
      geometry: { type: 'Point', coordinates: [35.63294047, 34.19237389] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Berbara',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.19237,
        longitude: 35.63294,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 276993,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15250',
      geometry: { type: 'Point', coordinates: [-155.82663893, 20.0351845] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kawaihae',
        code: 'USKWH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.03518,
        longitude: -155.82664,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5848748,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15251',
      geometry: { type: 'Point', coordinates: [29.43594447, -3.97864594] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rumonge',
        code: null,
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.97865,
        longitude: 29.43594,
        iso3: 'BDI',
        iso3_op: 'BDI',
        country: 'Burundi',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 423549,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15252',
      geometry: { type: 'Point', coordinates: [-85.66429456, 30.15229305] },
      geometry_name: 'shape',
      properties: {
        portname: 'Panama City',
        code: 'USPFN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.15229,
        longitude: -85.66429,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4167694,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15253',
      geometry: { type: 'Point', coordinates: [-121.78589964, 36.80028142] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moss Landing',
        code: 'USMLG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.80028,
        longitude: -121.7859,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5375042,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15254',
      geometry: { type: 'Point', coordinates: [-122.38973088, 37.94813454] },
      geometry_name: 'shape',
      properties: {
        portname: 'Richmond',
        code: 'USRCH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.94813,
        longitude: -122.38973,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5387428,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15255',
      geometry: { type: 'Point', coordinates: [-9.86481721, 52.2775726] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fenit',
        code: 'IEFEN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.27757,
        longitude: -9.86482,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2964329,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15256',
      geometry: { type: 'Point', coordinates: [-122.71282381, 45.56363864] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portland',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.56364,
        longitude: -122.71282,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5746545,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15257',
      geometry: { type: 'Point', coordinates: [20.45982388, -3.39395699] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bumbuli',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.39396,
        longitude: 20.45982,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 217718,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15258',
      geometry: { type: 'Point', coordinates: [19.78725292, -3.42476639] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bisenge',
        code: null,
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -3.42477,
        longitude: 19.78725,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2317066,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15259',
      geometry: { type: 'Point', coordinates: [123.78982736, 8.05563602] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tubod',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 8.05564,
        longitude: 123.78983,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1681149,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15260',
      geometry: { type: 'Point', coordinates: [-90.13932519, 29.91192388] },
      geometry_name: 'shape',
      properties: {
        portname: 'Westwego',
        code: 'USWWO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.91192,
        longitude: -90.13933,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4345968,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15261',
      geometry: { type: 'Point', coordinates: [-64.66039944, 48.34663] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chandler',
        code: 'CACHR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.34663,
        longitude: -64.6604,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6943811,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15262',
      geometry: { type: 'Point', coordinates: [-123.3689, 48.43483387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Victoria',
        code: 'CAVIC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.43483,
        longitude: -123.3689,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6174041,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15263',
      geometry: { type: 'Point', coordinates: [-159.35948762, 21.95088488] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nawiliwili',
        code: 'USNWL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.95088,
        longitude: -159.35949,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5851427,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15264',
      geometry: { type: 'Point', coordinates: [18.83855226, -5.04659917] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kikwit',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'approximate',
        latitude: -5.0466,
        longitude: 18.83855,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2314705,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15265',
      geometry: { type: 'Point', coordinates: [21.37174947, -3.49127076] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dekese',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -3.49127,
        longitude: 21.37175,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 217400,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15266',
      geometry: { type: 'Point', coordinates: [-97.49374084, 25.89872875] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brownsville',
        code: 'USBRO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.89873,
        longitude: -97.49374,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4676740,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15267',
      geometry: { type: 'Point', coordinates: [-121.98066399, 37.42916094] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santa Clara',
        code: 'USSCZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 37.42916,
        longitude: -121.98066,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5393015,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15268',
      geometry: { type: 'Point', coordinates: [-121.32440568, 37.95057255] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stockton',
        code: 'USSCK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.95057,
        longitude: -121.32441,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5399020,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15269',
      geometry: { type: 'Point', coordinates: [140.57421533, 36.30984792] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oarai',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.30985,
        longitude: 140.57422,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2111567,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15270',
      geometry: { type: 'Point', coordinates: [-69.63022149, 18.4213905] },
      geometry_name: 'shape',
      properties: {
        portname: 'Boca Chica',
        code: 'DOBCC',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.42139,
        longitude: -69.63022,
        iso3: 'DOM',
        iso3_op: 'DOM',
        country: 'Dominican Republic',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3511336,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15271',
      geometry: { type: 'Point', coordinates: [-76.31626781, 36.8688192] },
      geometry_name: 'shape',
      properties: {
        portname: 'Norfolk',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.86882,
        longitude: -76.31627,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4776222,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15272',
      geometry: { type: 'Point', coordinates: [-4.17667776, 57.68864478] },
      geometry_name: 'shape',
      properties: {
        portname: 'Invergordon',
        code: 'GBIVG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.68864,
        longitude: -4.17668,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2646115,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15273',
      geometry: { type: 'Point', coordinates: [-7.03578724, 43.53643699] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ribadeo',
        code: 'ESRIB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.53644,
        longitude: -7.03579,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3111859,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15274',
      geometry: { type: 'Point', coordinates: [9.70324647, 39.93572062] },
      geometry_name: 'shape',
      properties: {
        portname: 'Arbatax',
        code: 'ITATX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.93572,
        longitude: 9.70325,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2525691,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15275',
      geometry: { type: 'Point', coordinates: [24.70144381, 40.77923921] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thasos',
        code: 'GRTSO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.77924,
        longitude: 24.70144,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 734094,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15276',
      geometry: { type: 'Point', coordinates: [-8.48199529, 54.27677381] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sligo',
        code: 'IESLI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.27677,
        longitude: -8.482,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2961423,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15277',
      geometry: { type: 'Point', coordinates: [18.69360994, 54.39085867] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gdansk',
        code: 'PLGDN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.39086,
        longitude: 18.69361,
        iso3: 'POL',
        iso3_op: 'POL',
        country: 'Poland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3099434,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15278',
      geometry: { type: 'Point', coordinates: [141.58846852, 38.88737671] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kesennuma',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.88738,
        longitude: 141.58847,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2112263,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15279',
      geometry: { type: 'Point', coordinates: [-3.57736627, 50.35065294] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dartmouth',
        code: 'GBDTM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.35065,
        longitude: -3.57737,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2651498,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15280',
      geometry: { type: 'Point', coordinates: [3.12341842, 41.84941012] },
      geometry_name: 'shape',
      properties: {
        portname: 'Palamos',
        code: 'ESPAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.84941,
        longitude: 3.12342,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3114566,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15281',
      geometry: { type: 'Point', coordinates: [20.68057843, -1.74939397] },
      geometry_name: 'shape',
      properties: {
        portname: 'Monkoto',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.74939,
        longitude: 20.68058,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 208694,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15282',
      geometry: { type: 'Point', coordinates: [-3.22552023, 48.7890594] },
      geometry_name: 'shape',
      properties: {
        portname: 'Treguier',
        code: 'FRTRE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.78906,
        longitude: -3.22552,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2971929,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15283',
      geometry: { type: 'Point', coordinates: [-6.35701385, 36.62045981] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rota',
        code: 'ESROT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.62046,
        longitude: -6.35701,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2511700,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15284',
      geometry: { type: 'Point', coordinates: [-3.02073479, 36.75016265] },
      geometry_name: 'shape',
      properties: {
        portname: 'Adra',
        code: 'ESADR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 36.75016,
        longitude: -3.02073,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2522430,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15285',
      geometry: { type: 'Point', coordinates: [15.29042145, 37.06879382] },
      geometry_name: 'shape',
      properties: {
        portname: 'Siracusa',
        code: 'ITSIR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.06879,
        longitude: 15.29042,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2523083,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15286',
      geometry: { type: 'Point', coordinates: [18.75382654, -4.73209682] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mosango',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.7321,
        longitude: 18.75383,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2312516,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15287',
      geometry: { type: 'Point', coordinates: [10.92179014, 56.41421816] },
      geometry_name: 'shape',
      properties: {
        portname: 'Grenaa',
        code: 'DKGRE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.41422,
        longitude: 10.92179,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2621230,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15288',
      geometry: { type: 'Point', coordinates: [9.23789138, 58.72016939] },
      geometry_name: 'shape',
      properties: {
        portname: 'Risor',
        code: 'NORIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.72017,
        longitude: 9.23789,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3141747,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15289',
      geometry: { type: 'Point', coordinates: [17.53597688, 59.55250377] },
      geometry_name: 'shape',
      properties: {
        portname: 'Balsta',
        code: 'SEBAA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.5525,
        longitude: 17.53598,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2723567,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15290',
      geometry: { type: 'Point', coordinates: [20.87778294, -0.27632345] },
      geometry_name: 'shape',
      properties: {
        portname: 'Boende',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -0.27632,
        longitude: 20.87778,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 218680,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15291',
      geometry: { type: 'Point', coordinates: [-74.52826874, -8.38650818] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pucallpa',
        code: 'PEPCL',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.38651,
        longitude: -74.52827,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3693345,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15292',
      geometry: { type: 'Point', coordinates: [28.77588822, 61.17056021] },
      geometry_name: 'shape',
      properties: {
        portname: 'Imatra',
        code: 'FIIMA',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.17056,
        longitude: 28.77589,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 656688,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15293',
      geometry: { type: 'Point', coordinates: [6.76534653, 6.13492243] },
      geometry_name: 'shape',
      properties: {
        portname: 'Onitsha',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.13492,
        longitude: 6.76535,
        iso3: 'NGA',
        iso3_op: 'NGA',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2326016,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15294',
      geometry: { type: 'Point', coordinates: [35.64334153, 34.12244071] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jbail',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.12244,
        longitude: 35.64334,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 273203,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15295',
      geometry: { type: 'Point', coordinates: [-72.71619712, 19.45130043] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gonaives',
        code: 'HTGVS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Closed',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.4513,
        longitude: -72.7162,
        iso3: null,
        iso3_op: 'HTI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3724696,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15296',
      geometry: { type: 'Point', coordinates: [10.30372405, 43.56892276] },
      geometry_name: 'shape',
      properties: {
        portname: 'Livorno',
        code: 'ITLIV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.56892,
        longitude: 10.30372,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3174659,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15297',
      geometry: { type: 'Point', coordinates: [-73.10729719, 18.44913501] },
      geometry_name: 'shape',
      properties: {
        portname: 'Miragoane',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.44914,
        longitude: -73.1073,
        iso3: 'HTI',
        iso3_op: 'HTI',
        country: 'Haiti',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 3720824,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15298',
      geometry: { type: 'Point', coordinates: [124.279441, 12.412143] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Antonio',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.41214,
        longitude: 124.27944,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1690370,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15299',
      geometry: { type: 'Point', coordinates: [-3.75233286, 16.13097961] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tonka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.13098,
        longitude: -3.75233,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 2449018,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15300',
      geometry: { type: 'Point', coordinates: [45.32918026, -16.10434247] },
      geometry_name: 'shape',
      properties: {
        portname: 'Soalala',
        code: 'MGDWB',
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.10434,
        longitude: 45.32918,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'Bing',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1056487,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15301',
      geometry: { type: 'Point', coordinates: [109.14451354, 11.90338296] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cam Ranh',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.90338,
        longitude: 109.14451,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: 'Ba Ngoi',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1586350,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15302',
      geometry: { type: 'Point', coordinates: [124.02943619, 10.52123426] },
      geometry_name: 'shape',
      properties: {
        portname: 'Danao',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.52123,
        longitude: 124.02944,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1715804,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15303',
      geometry: { type: 'Point', coordinates: [120.30097207, 16.60875989] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Fernando',
        code: 'PHSFE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.60876,
        longitude: 120.30097,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1690033,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15304',
      geometry: { type: 'Point', coordinates: [48.02051331, -22.14144467] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manakara',
        code: 'MGWVK',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: 2.5,
        maxlength: 214,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -22.14144,
        longitude: 48.02051,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1061605,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15305',
      geometry: { type: 'Point', coordinates: [124.045411, 13.040989] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bacon',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.04099,
        longitude: 124.04541,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1729554,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15306',
      geometry: { type: 'Point', coordinates: [52.62573264, 24.18829153] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jebel Dhanna',
        code: 'AEJED',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.18829,
        longitude: 52.62573,
        iso3: null,
        iso3_op: 'ARE',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 6951086,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15307',
      geometry: { type: 'Point', coordinates: [121.52968281, 12.59360131] },
      geometry_name: 'shape',
      properties: {
        portname: 'Roxas',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 12.5936,
        longitude: 121.52968,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1691442,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15308',
      geometry: { type: 'Point', coordinates: [105.83577267, 9.98372987] },
      geometry_name: 'shape',
      properties: {
        portname: 'Can Tho',
        code: 'VNVCA',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.98373,
        longitude: 105.83577,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: 'Cai cui',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 1586203,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15309',
      geometry: { type: 'Point', coordinates: [-88.06902635, 44.44925238] },
      geometry_name: 'shape',
      properties: {
        portname: 'De Pere',
        code: 'USDRW',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.44925,
        longitude: -88.06903,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5250201,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15310',
      geometry: { type: 'Point', coordinates: [-88.45647194, 46.75789779] },
      geometry_name: 'shape',
      properties: {
        portname: "L'Anse",
        code: 'USLAJ',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.7579,
        longitude: -88.45647,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 4998431,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15311',
      geometry: { type: 'Point', coordinates: [-87.65145842, 44.08983323] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manitowoc',
        code: 'USMTW',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.08983,
        longitude: -87.65146,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-24T11:52:47.493Z',
        geonameid: 5261585,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15312',
      geometry: { type: 'Point', coordinates: [28.91568323, -2.10473392] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalehe',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.10473,
        longitude: 28.91568,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 215002,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15313',
      geometry: { type: 'Point', coordinates: [24.08260788, -1.35509644] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bondo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.3551,
        longitude: 24.08261,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 218257,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15314',
      geometry: { type: 'Point', coordinates: [19.38117263, -0.470352] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lotumbe',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -0.47035,
        longitude: 19.38117,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2313518,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15315',
      geometry: { type: 'Point', coordinates: [-65.65750241, 47.61618118] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bathurst',
        code: 'CABAT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 47.61618,
        longitude: -65.6575,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6696259,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15316',
      geometry: { type: 'Point', coordinates: [152.16447296, -4.20196518] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rabaul',
        code: 'PGRAB',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.20197,
        longitude: 152.16447,
        iso3: 'PNG',
        iso3_op: 'PNG',
        country: 'Papua New Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2087894,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15317',
      geometry: { type: 'Point', coordinates: [-65.75508188, 44.62631241] },
      geometry_name: 'shape',
      properties: {
        portname: 'Digby',
        code: 'CADIG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.62631,
        longitude: -65.75508,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5939236,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15318',
      geometry: { type: 'Point', coordinates: [-134.42727738, 58.3029704] },
      geometry_name: 'shape',
      properties: {
        portname: 'Juneau',
        code: 'USJNU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.30297,
        longitude: -134.42728,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5554072,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15319',
      geometry: { type: 'Point', coordinates: [-139.7327846, 59.54896612] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yakutat',
        code: 'USYAK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.54897,
        longitude: -139.73278,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5558615,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15320',
      geometry: { type: 'Point', coordinates: [-151.16774847, 61.04711692] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tyonek',
        code: 'USTYE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.04712,
        longitude: -151.16775,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5877053,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15321',
      geometry: { type: 'Point', coordinates: [-90.4053963, 29.98140671] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hahnville',
        code: 'USHAH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.98141,
        longitude: -90.4054,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4326799,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15322',
      geometry: { type: 'Point', coordinates: [-90.41098525, 29.99579915] },
      geometry_name: 'shape',
      properties: {
        portname: 'Norco',
        code: 'USNCM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.9958,
        longitude: -90.41099,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4335326,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15323',
      geometry: { type: 'Point', coordinates: [-94.08788626, 30.0752041] },
      geometry_name: 'shape',
      properties: {
        portname: 'Beaumont',
        code: 'USBPT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.0752,
        longitude: -94.08789,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4672989,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15324',
      geometry: { type: 'Point', coordinates: [-81.10624665, 32.09244731] },
      geometry_name: 'shape',
      properties: {
        portname: 'Savannah',
        code: 'USSAV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.09245,
        longitude: -81.10625,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4221552,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15325',
      geometry: { type: 'Point', coordinates: [-79.29112846, 33.36696778] },
      geometry_name: 'shape',
      properties: {
        portname: 'Georgetown',
        code: 'USGGE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.36697,
        longitude: -79.29113,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4579662,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15326',
      geometry: { type: 'Point', coordinates: [-76.58441279, 39.26737697] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baltimore',
        code: 'USBAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.26738,
        longitude: -76.58441,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4347778,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15327',
      geometry: { type: 'Point', coordinates: [-124.10423396, 43.71314905] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gardiner',
        code: 'USGDR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.71315,
        longitude: -124.10423,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5728011,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15328',
      geometry: { type: 'Point', coordinates: [-61.36481891, 45.61480856] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Hawkesbury',
        code: 'CAPHW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.61481,
        longitude: -61.36482,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6111867,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15329',
      geometry: { type: 'Point', coordinates: [-122.94791564, 46.10455968] },
      geometry_name: 'shape',
      properties: {
        portname: 'Longview',
        code: 'USLOG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.10456,
        longitude: -122.94792,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5801617,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15330',
      geometry: { type: 'Point', coordinates: [-82.56045555, 27.51257993] },
      geometry_name: 'shape',
      properties: {
        portname: 'Palmetto',
        code: 'USPMT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.51258,
        longitude: -82.56046,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4167632,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15331',
      geometry: { type: 'Point', coordinates: [-123.71446849, 48.92543638] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chemainus',
        code: 'CACHM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.92544,
        longitude: -123.71447,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5920668,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15332',
      geometry: { type: 'Point', coordinates: [-93.9568213, 29.83950185] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Arthur',
        code: 'USPOA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.8395,
        longitude: -93.95682,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4720039,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15333',
      geometry: { type: 'Point', coordinates: [-88.03727172, 30.66704397] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mobile',
        code: 'USMOB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.66704,
        longitude: -88.03727,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4076598,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15334',
      geometry: { type: 'Point', coordinates: [-76.07216419, 38.57507873] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cambridge',
        code: 'USCGE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.57508,
        longitude: -76.07216,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4350175,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15335',
      geometry: { type: 'Point', coordinates: [-70.88130903, 42.5227158] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salem',
        code: 'USSLM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.52272,
        longitude: -70.88131,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4950065,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15336',
      geometry: { type: 'Point', coordinates: [-64.31909904, 45.39192345] },
      geometry_name: 'shape',
      properties: {
        portname: 'Parrsboro',
        code: 'CAPAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.39192,
        longitude: -64.3191,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6098717,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15337',
      geometry: { type: 'Point', coordinates: [-122.86304691, 49.28496606] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Moody',
        code: 'CAPMO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.28497,
        longitude: -122.86305,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6111962,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15338',
      geometry: { type: 'Point', coordinates: [-127.69287909, 52.35256897] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ocean Falls',
        code: 'CAOFA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.35257,
        longitude: -127.69288,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6092351,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15339',
      geometry: { type: 'Point', coordinates: [-126.79524238, 52.3739897] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bella Coola',
        code: 'CABLC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.37399,
        longitude: -126.79524,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 8062543,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15340',
      geometry: { type: 'Point', coordinates: [-135.44144746, 59.23195094] },
      geometry_name: 'shape',
      properties: {
        portname: 'Haines',
        code: 'USHNS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.23195,
        longitude: -135.44145,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5846901,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15341',
      geometry: { type: 'Point', coordinates: [-9.10193093, 52.61279298] },
      geometry_name: 'shape',
      properties: {
        portname: 'Foynes',
        code: 'IEFOV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.61279,
        longitude: -9.10193,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2964225,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15342',
      geometry: { type: 'Point', coordinates: [10.52235778, 54.85327076] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marstal',
        code: 'DKMRS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.85327,
        longitude: 10.52236,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2617030,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15343',
      geometry: { type: 'Point', coordinates: [-1.36059849, 54.91372158] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sunderland',
        code: 'GBSUN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.91372,
        longitude: -1.3606,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2636531,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15344',
      geometry: { type: 'Point', coordinates: [-5.47655889, 56.41201525] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oban',
        code: 'GBOBA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.41202,
        longitude: -5.47656,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2641108,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15345',
      geometry: { type: 'Point', coordinates: [-8.88661758, 37.95335779] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sines',
        code: 'PTSIE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.95336,
        longitude: -8.88662,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Portugal',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2262917,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15346',
      geometry: { type: 'Point', coordinates: [-1.16885034, 44.66318616] },
      geometry_name: 'shape',
      properties: {
        portname: 'Arcachon',
        code: 'FRARC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.66319,
        longitude: -1.16885,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3037253,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15347',
      geometry: { type: 'Point', coordinates: [1.08880685, 49.93457236] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dieppe',
        code: 'FRDPE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.93457,
        longitude: 1.08881,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3021411,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15348',
      geometry: { type: 'Point', coordinates: [-9.05119711, 53.2692752] },
      geometry_name: 'shape',
      properties: {
        portname: 'Galway',
        code: 'IEGWY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.26928,
        longitude: -9.0512,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2964180,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15349',
      geometry: { type: 'Point', coordinates: [16.85241564, 54.58629237] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ustka',
        code: 'PLUST',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.58629,
        longitude: 16.85242,
        iso3: 'POL',
        iso3_op: 'POL',
        country: 'Poland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3082756,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15350',
      geometry: { type: 'Point', coordinates: [8.86377302, 56.79281785] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nykobing Mors',
        code: 'DKNYM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.79282,
        longitude: 8.86377,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2615964,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15351',
      geometry: { type: 'Point', coordinates: [17.10591619, 58.66565325] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oxelosund',
        code: 'SEOXE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.66565,
        longitude: 17.10592,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2684653,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15352',
      geometry: { type: 'Point', coordinates: [5.26743819, 59.14668153] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skudeneshavn',
        code: 'NOSKU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.14668,
        longitude: 5.26744,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3138429,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15353',
      geometry: { type: 'Point', coordinates: [-83.1459716, 42.21553387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wyandotte',
        code: 'USWYD',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.21553,
        longitude: -83.14597,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5015599,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15354',
      geometry: { type: 'Point', coordinates: [-132.82367655, 55.20278781] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hydaburg',
        code: 'USHYG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.20279,
        longitude: -132.82368,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5553646,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15355',
      geometry: { type: 'Point', coordinates: [39.50802831, -8.93960096] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kilwa Masoko',
        code: 'TZKIM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.9396,
        longitude: 39.50803,
        iso3: 'TZA',
        iso3_op: 'TZA',
        country: 'United Republic of Tanzania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 157377,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15356',
      geometry: { type: 'Point', coordinates: [11.23101844, 64.86047756] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rorvik',
        code: 'NORVK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.86048,
        longitude: 11.23102,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3141310,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15357',
      geometry: { type: 'Point', coordinates: [-1.32965991, 54.83823816] },
      geometry_name: 'shape',
      properties: {
        portname: 'Seaham',
        code: 'GBSEA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.83824,
        longitude: -1.32966,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2638302,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15358',
      geometry: { type: 'Point', coordinates: [-2.47518482, 51.72158692] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sharpness',
        code: 'GBSSS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.72159,
        longitude: -2.47518,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2638117,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15359',
      geometry: { type: 'Point', coordinates: [-1.20538599, 54.69662902] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hartlepool',
        code: 'GBHTP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.69663,
        longitude: -1.20539,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2647400,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15360',
      geometry: { type: 'Point', coordinates: [13.54282009, 45.79342579] },
      geometry_name: 'shape',
      properties: {
        portname: 'Monfalcone',
        code: 'ITMNF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.79343,
        longitude: 13.54282,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3173153,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15361',
      geometry: { type: 'Point', coordinates: [7.78828379, 48.58065815] },
      geometry_name: 'shape',
      properties: {
        portname: 'Strasbourg',
        code: 'FRSXB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.58066,
        longitude: 7.78828,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2973783,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15362',
      geometry: { type: 'Point', coordinates: [1.82754623, 50.10167275] },
      geometry_name: 'shape',
      properties: {
        portname: 'Abbeville',
        code: 'FRABB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.10167,
        longitude: 1.82755,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3038789,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15363',
      geometry: { type: 'Point', coordinates: [-4.63445896, 50.33523304] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fowey',
        code: 'GBFOY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.33523,
        longitude: -4.63446,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2649126,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15364',
      geometry: { type: 'Point', coordinates: [-3.50117747, 50.54623078] },
      geometry_name: 'shape',
      properties: {
        portname: 'Teignmouth',
        code: 'GBTNM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.54623,
        longitude: -3.50118,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2636132,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15365',
      geometry: { type: 'Point', coordinates: [-1.3005, 50.76209785] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cowes',
        code: 'GBCOW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.7621,
        longitude: -1.3005,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2652204,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15366',
      geometry: { type: 'Point', coordinates: [-1.34398575, 50.83919123] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fawley',
        code: 'GBFAW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.83919,
        longitude: -1.34399,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2649618,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15367',
      geometry: { type: 'Point', coordinates: [0.24458651, 51.47444744] },
      geometry_name: 'shape',
      properties: {
        portname: 'Purfleet',
        code: 'GBPFT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.47445,
        longitude: 0.24459,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2639845,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15368',
      geometry: { type: 'Point', coordinates: [4.85057904, 51.66599446] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oosterhout',
        code: 'NLOOS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.66599,
        longitude: 4.85058,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2749450,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15369',
      geometry: { type: 'Point', coordinates: [4.64499043, 51.79265933] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dordrecht',
        code: 'NLDOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.79266,
        longitude: 4.64499,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2756669,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15370',
      geometry: { type: 'Point', coordinates: [0.96349059, 51.8530821] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wivenhoe',
        code: 'GBWIV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.85308,
        longitude: 0.96349,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2633717,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15371',
      geometry: { type: 'Point', coordinates: [1.08224815, 51.94392568] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mistley',
        code: 'GBMIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.94393,
        longitude: 1.08225,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2642416,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15372',
      geometry: { type: 'Point', coordinates: [12.24652742, 57.10729908] },
      geometry_name: 'shape',
      properties: {
        portname: 'Varberg',
        code: 'SEVAG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.1073,
        longitude: 12.24653,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2664996,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15373',
      geometry: { type: 'Point', coordinates: [-0.0416382, 52.87184476] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fosdyke',
        code: 'GBFDK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.87184,
        longitude: -0.04164,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2649165,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15374',
      geometry: { type: 'Point', coordinates: [122.79597432, 10.51976308] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pulupandan',
        code: 'PHPUL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.51976,
        longitude: 122.79597,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1692489,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15375',
      geometry: { type: 'Point', coordinates: [139.97734843, 35.68369708] },
      geometry_name: 'shape',
      properties: {
        portname: 'Funabashi',
        code: 'JPOGA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.6837,
        longitude: 139.97735,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1863905,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15376',
      geometry: { type: 'Point', coordinates: [132.34183346, 34.34854458] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hatsukaichi',
        code: 'JPAKT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.34854,
        longitude: 132.34183,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1863018,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15377',
      geometry: { type: 'Point', coordinates: [-5.80628343, 35.78241666] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tangier',
        code: 'MATNG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.78242,
        longitude: -5.80628,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2530335,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15378',
      geometry: { type: 'Point', coordinates: [130.92484113, 33.94068615] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shimonoseki',
        code: 'JPSHS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.94069,
        longitude: 130.92484,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1852225,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15379',
      geometry: { type: 'Point', coordinates: [131.80315868, 34.04415827] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tokuyama',
        code: 'JPTKY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.04416,
        longitude: 131.80316,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1850152,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15380',
      geometry: { type: 'Point', coordinates: [150.03324796, -5.30027582] },
      geometry_name: 'shape',
      properties: {
        portname: 'Talasea',
        code: 'PGTLW',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.30028,
        longitude: 150.03325,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2085944,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15381',
      geometry: { type: 'Point', coordinates: [172.96129591, -43.80942674] },
      geometry_name: 'shape',
      properties: {
        portname: 'Akaroa',
        code: 'NZAKA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -43.80943,
        longitude: 172.9613,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6222028,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15382',
      geometry: { type: 'Point', coordinates: [147.33205194, -42.8835006] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hobart',
        code: 'AUHBA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -42.8835,
        longitude: 147.33205,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2163355,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15383',
      geometry: { type: 'Point', coordinates: [175.54689023, -37.14873319] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thames',
        code: 'NZTMZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -37.14873,
        longitude: 175.54689,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2208333,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15384',
      geometry: { type: 'Point', coordinates: [-52.07475635, -32.04857599] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rio Grande',
        code: 'BRRIG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.04858,
        longitude: -52.07476,
        iso3: null,
        iso3_op: 'BRA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3451138,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15385',
      geometry: { type: 'Point', coordinates: [146.1248512, -17.59912001] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mourilyan',
        code: 'AUMOU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.59912,
        longitude: 146.12485,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2156472,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15386',
      geometry: { type: 'Point', coordinates: [118.58633015, 4.95261583] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bakapit',
        code: 'MYBKP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.95262,
        longitude: 118.58633,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1781820,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15387',
      geometry: { type: 'Point', coordinates: [124.88078226, 11.77391444] },
      geometry_name: 'shape',
      properties: {
        portname: 'Catbalogan',
        code: 'PHCTS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.77391,
        longitude: 124.88078,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1717911,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15388',
      geometry: { type: 'Point', coordinates: [123.85055113, 9.6489227] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tagbilaran',
        code: 'PHTAG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.64892,
        longitude: 123.85055,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1684497,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15389',
      geometry: { type: 'Point', coordinates: [109.06402974, 21.47172467] },
      geometry_name: 'shape',
      properties: {
        portname: 'Beihai',
        code: 'CNBHY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.47172,
        longitude: 109.06403,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1816705,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15390',
      geometry: { type: 'Point', coordinates: [135.19951696, 35.53679174] },
      geometry_name: 'shape',
      properties: {
        portname: 'Miyazu',
        code: 'JPMIY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.53679,
        longitude: 135.19952,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1856703,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15391',
      geometry: { type: 'Point', coordinates: [130.54828286, 31.37761351] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kiire',
        code: 'JPKII',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.37761,
        longitude: 130.54828,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1859501,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15392',
      geometry: { type: 'Point', coordinates: [131.86471451, 33.07659616] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tsukumi',
        code: 'JPTMI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.0766,
        longitude: 131.86471,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1849661,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15393',
      geometry: { type: 'Point', coordinates: [126.53971829, 33.52169489] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cheju',
        code: 'KRCHA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.52169,
        longitude: 126.53972,
        iso3: null,
        iso3_op: 'KOR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1846266,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15394',
      geometry: { type: 'Point', coordinates: [127.67325692, 34.74586137] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yosu',
        code: 'KRYOS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.74586,
        longitude: 127.67326,
        iso3: 'KOR',
        iso3_op: 'KOR',
        country: 'Republic of Korea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1884138,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15395',
      geometry: { type: 'Point', coordinates: [136.97668519, 37.05113133] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nanao',
        code: 'JPNNO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.05113,
        longitude: 136.97669,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1855670,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15396',
      geometry: { type: 'Point', coordinates: [138.25626009, 37.18454596] },
      geometry_name: 'shape',
      properties: {
        portname: 'Naoetsu',
        code: 'JPNAO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.18455,
        longitude: 138.25626,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1855620,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15397',
      geometry: { type: 'Point', coordinates: [24.52571585, 65.6690769] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kemi',
        code: 'FIKEM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.66908,
        longitude: 24.52572,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 653281,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15398',
      geometry: { type: 'Point', coordinates: [-67.47091072, -45.86212341] },
      geometry_name: 'shape',
      properties: {
        portname: 'Comodoro Rivadavia',
        code: 'ARCRD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -45.86212,
        longitude: -67.47091,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3860443,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15399',
      geometry: { type: 'Point', coordinates: [173.02088424, -41.13608721] },
      geometry_name: 'shape',
      properties: {
        portname: 'Motueka',
        code: 'NZMZP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.13609,
        longitude: 173.02088,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2184361,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15400',
      geometry: { type: 'Point', coordinates: [176.91415012, -39.4758192] },
      geometry_name: 'shape',
      properties: {
        portname: 'Napier',
        code: 'NZNPE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -39.47582,
        longitude: 176.91415,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2186313,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15401',
      geometry: { type: 'Point', coordinates: [178.0243362, -38.67633063] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gisborne',
        code: 'NZGIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -38.67633,
        longitude: 178.02434,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2206854,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15402',
      geometry: { type: 'Point', coordinates: [174.77451563, -36.84359677] },
      geometry_name: 'shape',
      properties: {
        portname: 'Auckland',
        code: 'NZAKL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -36.8436,
        longitude: 174.77452,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2193733,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15403',
      geometry: { type: 'Point', coordinates: [137.9151, -34.43266613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ardrossan',
        code: 'AUARD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -34.43267,
        longitude: 137.9151,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2077599,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15404',
      geometry: { type: 'Point', coordinates: [152.89481158, -31.42666279] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Macquarie',
        code: 'AUPQQ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -31.42666,
        longitude: 152.89481,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2152659,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15405',
      geometry: { type: 'Point', coordinates: [153.07578911, -27.44280065] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brisbane',
        code: 'AUBNE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -27.4428,
        longitude: 153.07579,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2174003,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15406',
      geometry: { type: 'Point', coordinates: [150.5596329, -23.38039361] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rockhampton',
        code: 'AUROK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -23.38039,
        longitude: 150.55963,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2151437,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15407',
      geometry: { type: 'Point', coordinates: [128.10024083, -15.4614652] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wyndham',
        code: 'AUWYN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -15.46147,
        longitude: 128.10024,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2057705,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15408',
      geometry: { type: 'Point', coordinates: [-81.13355697, -4.17875693] },
      geometry_name: 'shape',
      properties: {
        portname: 'Los Organos',
        code: 'PELOO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.17876,
        longitude: -81.13356,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3695161,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15409',
      geometry: { type: 'Point', coordinates: [-51.17468738, -0.05695894] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santana',
        code: 'BRSAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.05696,
        longitude: -51.17469,
        iso3: null,
        iso3_op: 'BRA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3391360,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15410',
      geometry: { type: 'Point', coordinates: [109.85378371, 1.67051024] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lundu',
        code: 'MYLUN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.67051,
        longitude: 109.85378,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1737012,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15411',
      geometry: { type: 'Point', coordinates: [74.11603181, 14.80136412] },
      geometry_name: 'shape',
      properties: {
        portname: 'Karwar',
        code: 'INKRW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.80136,
        longitude: 74.11603,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1267635,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15412',
      geometry: { type: 'Point', coordinates: [115.34402382, 22.78009816] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shanwei',
        code: 'CNSWE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.7801,
        longitude: 115.34402,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1795928,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15413',
      geometry: { type: 'Point', coordinates: [141.96927203, 39.64012017] },
      geometry_name: 'shape',
      properties: {
        portname: 'Miyako',
        code: 'JPMYK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.64012,
        longitude: 141.96927,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2111884,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15414',
      geometry: { type: 'Point', coordinates: [115.76191647, -32.23544242] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kwinana',
        code: 'AUKWI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.23544,
        longitude: 115.76192,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2068079,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15415',
      geometry: { type: 'Point', coordinates: [-39.06537863, -16.45440796] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porto Seguro',
        code: 'BRBPS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.45441,
        longitude: -39.06538,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3452640,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15416',
      geometry: { type: 'Point', coordinates: [103.90182711, 1.43695078] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pasir Gudang',
        code: 'MYPGU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.43695,
        longitude: 103.90183,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1732722,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15417',
      geometry: { type: 'Point', coordinates: [123.63519019, 10.37587934] },
      geometry_name: 'shape',
      properties: {
        portname: 'Toledo',
        code: 'PHTLD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 10.37588,
        longitude: 123.63519,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1681602,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15418',
      geometry: { type: 'Point', coordinates: [79.83362479, 11.923211] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pondicherry',
        code: 'INPNY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.92321,
        longitude: 79.83362,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1259425,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15419',
      geometry: { type: 'Point', coordinates: [120.59262397, 18.19263938] },
      geometry_name: 'shape',
      properties: {
        portname: 'Laoag',
        code: 'PHLAO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.19264,
        longitude: 120.59262,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1707404,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15420',
      geometry: { type: 'Point', coordinates: [68.60669562, 23.23798129] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jakhau',
        code: 'INJAK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.23798,
        longitude: 68.6067,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1269467,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15421',
      geometry: { type: 'Point', coordinates: [26.9496773, 60.47252754] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kotka',
        code: 'FIKTK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.47253,
        longitude: 26.94968,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 650946,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15422',
      geometry: { type: 'Point', coordinates: [128.84989735, 32.6954346] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fukue',
        code: 'JPFUJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.69543,
        longitude: 128.8499,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1863997,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15423',
      geometry: { type: 'Point', coordinates: [129.1270196, 37.49251512] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tonghae',
        code: 'KRTGH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.49252,
        longitude: 129.12702,
        iso3: 'KOR',
        iso3_op: 'KOR',
        country: 'Republic of Korea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6809122,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15424',
      geometry: { type: 'Point', coordinates: [39.5233503, 68.05371788] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gremikha',
        code: 'RUGRH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 68.05372,
        longitude: 39.52335,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 558929,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15425',
      geometry: { type: 'Point', coordinates: [33.41428383, 69.07754919] },
      geometry_name: 'shape',
      properties: {
        portname: 'Severomorsk',
        code: 'RUSRM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 69.07755,
        longitude: 33.41428,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 496278,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15426',
      geometry: { type: 'Point', coordinates: [115.96762812, 29.72409509] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jiujiang',
        code: 'CNJIU',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.7241,
        longitude: 115.96763,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1805179,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15427',
      geometry: { type: 'Point', coordinates: [119.62842001, 32.18893989] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zhenjiang',
        code: 'CNZHE',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.18894,
        longitude: 119.62842,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1784642,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15428',
      geometry: { type: 'Point', coordinates: [-63.91814725, -8.74392436] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porto Velho',
        code: 'BRPVH',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.74392,
        longitude: -63.91815,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3662762,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15429',
      geometry: { type: 'Point', coordinates: [31.99813345, 36.53687997] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alanya',
        code: 'TRALA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.53688,
        longitude: 31.99813,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 324190,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15430',
      geometry: { type: 'Point', coordinates: [-73.09995605, -36.69300073] },
      geometry_name: 'shape',
      properties: {
        portname: 'Talcahuano',
        code: 'CLTAL',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -36.693,
        longitude: -73.09996,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3870282,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15431',
      geometry: { type: 'Point', coordinates: [-64.57942408, 10.24104473] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pamatacual',
        code: 'VEPAM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.24104,
        longitude: -64.57942,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3630901,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15432',
      geometry: { type: 'Point', coordinates: [34.64819642, 31.82475024] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ashdod',
        code: 'ILASH',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.82475,
        longitude: 34.6482,
        iso3: 'ISR',
        iso3_op: 'ISR',
        country: 'Israel',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 295629,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15433',
      geometry: { type: 'Point', coordinates: [-44.36797523, -2.5794089] },
      geometry_name: 'shape',
      properties: {
        portname: 'Itaqui',
        code: 'BRITQ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.57941,
        longitude: -44.36798,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3397889,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15434',
      geometry: { type: 'Point', coordinates: [14.57381629, 53.42766009] },
      geometry_name: 'shape',
      properties: {
        portname: 'Szczecin',
        code: 'PLSZZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.42766,
        longitude: 14.57382,
        iso3: 'POL',
        iso3_op: 'POL',
        country: 'Poland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3083829,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15435',
      geometry: { type: 'Point', coordinates: [-110.87200403, 27.91859079] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guaymas',
        code: 'MXGYM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.91859,
        longitude: -110.872,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4005143,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15436',
      geometry: { type: 'Point', coordinates: [27.15476515, 38.4419071] },
      geometry_name: 'shape',
      properties: {
        portname: 'Izmir',
        code: 'TRIZM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.44191,
        longitude: 27.15477,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 311046,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15437',
      geometry: { type: 'Point', coordinates: [-84.84824163, 9.97723897] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puntarenas',
        code: 'CRPAS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.97724,
        longitude: -84.84824,
        iso3: null,
        iso3_op: 'CRI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3622228,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15438',
      geometry: { type: 'Point', coordinates: [39.17029223, 21.46388187] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jeddah',
        code: 'SAJED',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.46388,
        longitude: 39.17029,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 105343,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15439',
      geometry: { type: 'Point', coordinates: [-61.64776788, 10.68183406] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chaguaramas',
        code: 'TTCHA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.68183,
        longitude: -61.64777,
        iso3: 'TTO',
        iso3_op: 'TTO',
        country: 'Trinidad and Tobago',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3574806,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15440',
      geometry: { type: 'Point', coordinates: [-61.2228375, 13.14924309] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kingstown',
        code: 'VCKTN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.14924,
        longitude: -61.22284,
        iso3: 'VCT',
        iso3_op: 'VCT',
        country: 'Saint Vincent and the Grenadines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3577887,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15441',
      geometry: { type: 'Point', coordinates: [-88.18635758, 17.49392428] },
      geometry_name: 'shape',
      properties: {
        portname: 'Belize City',
        code: 'BZBZE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.49392,
        longitude: -88.18636,
        iso3: 'BLZ',
        iso3_op: 'BLZ',
        country: 'Belize',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3582677,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15442',
      geometry: { type: 'Point', coordinates: [-64.88362654, 17.71438361] },
      geometry_name: 'shape',
      properties: {
        portname: 'Frederiksted',
        code: 'VIFRD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.71438,
        longitude: -64.88363,
        iso3: 'VIR',
        iso3_op: 'VIR',
        country: 'United States Virgin Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4795761,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15443',
      geometry: { type: 'Point', coordinates: [-112.11704591, 24.78903223] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Carlos',
        code: 'MXSCR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.78903,
        longitude: -112.11705,
        iso3: null,
        iso3_op: 'MEX',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3987485,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15444',
      geometry: { type: 'Point', coordinates: [-68.30146962, -54.80274962] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ushuaia',
        code: 'ARUSH',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -54.80275,
        longitude: -68.30147,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3833367,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15445',
      geometry: { type: 'Point', coordinates: [-70.15617868, -20.20776523] },
      geometry_name: 'shape',
      properties: {
        portname: 'Iquique',
        code: 'CLIQQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.20777,
        longitude: -70.15618,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3887127,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15446',
      geometry: { type: 'Point', coordinates: [-82.39136249, 9.28894527] },
      geometry_name: 'shape',
      properties: {
        portname: 'Almirante',
        code: 'PAPAM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.28895,
        longitude: -82.39136,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3714896,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15447',
      geometry: { type: 'Point', coordinates: [-87.94039765, 15.84177904] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Cortes',
        code: 'HNPCR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.84178,
        longitude: -87.9404,
        iso3: 'HND',
        iso3_op: 'HND',
        country: 'Honduras',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3830312,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15448',
      geometry: { type: 'Point', coordinates: [123.49917903, -1.59009938] },
      geometry_name: 'shape',
      properties: {
        portname: 'Banggai',
        code: 'IDBGG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.5901,
        longitude: 123.49918,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1650328,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15449',
      geometry: { type: 'Point', coordinates: [-82.76352206, 23.00815547] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mariel',
        code: 'CUMAR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.00816,
        longitude: -82.76352,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3547600,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15450',
      geometry: { type: 'Point', coordinates: [-77.11853501, 20.35252338] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manzanillo',
        code: 'CUMZO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.35252,
        longitude: -77.11854,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3547867,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15451',
      geometry: { type: 'Point', coordinates: [-68.96051534, 18.41689483] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Romana',
        code: 'DOLRM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.41689,
        longitude: -68.96052,
        iso3: 'DOM',
        iso3_op: 'DOM',
        country: 'Dominican Republic',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3500957,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15452',
      geometry: { type: 'Point', coordinates: [-95.20240568, 16.16581345] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salina Cruz',
        code: 'MXSCX',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.16581,
        longitude: -95.20241,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3520064,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15453',
      geometry: { type: 'Point', coordinates: [-62.70803741, 17.28855819] },
      geometry_name: 'shape',
      properties: {
        portname: 'Basseterre',
        code: 'KNBAS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.28856,
        longitude: -62.70804,
        iso3: 'KNA',
        iso3_op: 'KNA',
        country: 'Saint Kitts and Nevis',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3575551,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15454',
      geometry: { type: 'Point', coordinates: [-82.80100841, 21.88757706] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nueva Gerona',
        code: 'CUGER',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.88758,
        longitude: -82.80101,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3545867,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15455',
      geometry: { type: 'Point', coordinates: [-76.16100741, 24.86792646] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rock Sound',
        code: 'BSRSD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.86793,
        longitude: -76.16101,
        iso3: 'BHS',
        iso3_op: 'BHS',
        country: 'Bahamas',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3571592,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15456',
      geometry: { type: 'Point', coordinates: [33.89405494, 36.31419335] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tasucu',
        code: 'TRTAS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.31419,
        longitude: 33.89405,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 299643,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15457',
      geometry: { type: 'Point', coordinates: [27.43013987, 37.0335917] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bodrum',
        code: 'TRBXN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.03359,
        longitude: 27.43014,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 320995,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15458',
      geometry: { type: 'Point', coordinates: [-60.72839173, -32.73493558] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Lorenzo',
        code: 'ARSLO',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.73494,
        longitude: -60.72839,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3948970,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15459',
      geometry: { type: 'Point', coordinates: [-62.83686614, 8.28620245] },
      geometry_name: 'shape',
      properties: {
        portname: 'Matanzas',
        code: 'VEMTV',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.2862,
        longitude: -62.83687,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3632629,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15460',
      geometry: { type: 'Point', coordinates: [50.15027978, -14.25454409] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sambava',
        code: 'MGSVB',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -14.25454,
        longitude: 50.15028,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1056899,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15461',
      geometry: { type: 'Point', coordinates: [-82.43724997, 8.36288424] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pedregal',
        code: 'PAPED',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.36288,
        longitude: -82.43725,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3703229,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15462',
      geometry: { type: 'Point', coordinates: [-88.74928848, 15.82449073] },
      geometry_name: 'shape',
      properties: {
        portname: 'Livingston',
        code: 'GTLIV',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.82449,
        longitude: -88.74929,
        iso3: null,
        iso3_op: 'GTM',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3593376,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15463',
      geometry: { type: 'Point', coordinates: [37.87507357, 40.99545592] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ordu',
        code: 'TRORD',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.99546,
        longitude: 37.87507,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 741100,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15464',
      geometry: { type: 'Point', coordinates: [156.84461233, -8.10374952] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gizo',
        code: 'SBGZO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.10375,
        longitude: 156.84461,
        iso3: null,
        iso3_op: 'SLB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2108857,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15465',
      geometry: { type: 'Point', coordinates: [38.1397277, -17.29381047] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pebane',
        code: 'MZPEB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.29381,
        longitude: 38.13973,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1028970,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15466',
      geometry: { type: 'Point', coordinates: [-9.03938432, 4.98857232] },
      geometry_name: 'shape',
      properties: {
        portname: 'Greenville',
        code: 'LRGRE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.98857,
        longitude: -9.03938,
        iso3: null,
        iso3_op: 'LBR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2276600,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15467',
      geometry: { type: 'Point', coordinates: [33.08063063, 28.36661061] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Gharib',
        code: 'EGRAG',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 28.36661,
        longitude: 33.08063,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 7802750,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15468',
      geometry: { type: 'Point', coordinates: [-10.0292565, 53.48625306] },
      geometry_name: 'shape',
      properties: {
        portname: 'Clifden',
        code: 'IECLI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.48625,
        longitude: -10.02926,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2965449,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15469',
      geometry: { type: 'Point', coordinates: [9.55729944, 54.51224075] },
      geometry_name: 'shape',
      properties: {
        portname: 'Schleswig',
        code: 'DESLS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.51224,
        longitude: 9.5573,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2838634,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15470',
      geometry: { type: 'Point', coordinates: [-5.014148, 54.96444584] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cairnryan',
        code: 'GBCYN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.96445,
        longitude: -5.01415,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2654054,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15471',
      geometry: { type: 'Point', coordinates: [14.69266844, 55.09308969] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ronne',
        code: 'DKRNN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.09309,
        longitude: 14.69267,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2614553,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15472',
      geometry: { type: 'Point', coordinates: [11.7542014, 55.22448787] },
      geometry_name: 'shape',
      properties: {
        portname: 'Naestved',
        code: 'DKNVD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.22449,
        longitude: 11.7542,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2616038,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15473',
      geometry: { type: 'Point', coordinates: [10.70990494, 54.93987214] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rudkobing',
        code: 'DKRKB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.93987,
        longitude: 10.7099,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2614432,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15474',
      geometry: { type: 'Point', coordinates: [9.04908666, 56.56910319] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skive',
        code: 'DKSKV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.5691,
        longitude: 9.04909,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2613731,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15475',
      geometry: { type: 'Point', coordinates: [9.90373104, 57.06449414] },
      geometry_name: 'shape',
      properties: {
        portname: 'Norresundby',
        code: 'DKNRS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.06449,
        longitude: 9.90373,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2616235,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15476',
      geometry: { type: 'Point', coordinates: [6.25480135, 58.33722157] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rekefjord',
        code: 'NOREK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.33722,
        longitude: 6.2548,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3142022,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15477',
      geometry: { type: 'Point', coordinates: [9.61707922, 59.12335689] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porsgrunn',
        code: 'NOPOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.12336,
        longitude: 9.61708,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3142657,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15478',
      geometry: { type: 'Point', coordinates: [11.10291345, 59.26861413] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sarpsborg',
        code: 'NOSPG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.26861,
        longitude: 11.10291,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3140084,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15479',
      geometry: { type: 'Point', coordinates: [13.52461884, 59.37137708] },
      geometry_name: 'shape',
      properties: {
        portname: 'Karlstad',
        code: 'SEKSD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.37138,
        longitude: 13.52462,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2701680,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15480',
      geometry: { type: 'Point', coordinates: [10.23376178, 59.73301443] },
      geometry_name: 'shape',
      properties: {
        portname: 'Drammen',
        code: 'NODRM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.73301,
        longitude: 10.23376,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3159016,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15481',
      geometry: { type: 'Point', coordinates: [-77.10971391, 18.40765162] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ocho Rios',
        code: 'JMOCJ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.40765,
        longitude: -77.10971,
        iso3: null,
        iso3_op: 'JAM',
        country: null,
        lastcheckdate: '2019-05-22T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3489239,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15482',
      geometry: { type: 'Point', coordinates: [11.29719284, 63.75243348] },
      geometry_name: 'shape',
      properties: {
        portname: 'Levanger',
        code: 'NOLEV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.75243,
        longitude: 11.29719,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3147698,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15483',
      geometry: { type: 'Point', coordinates: [1.3709596, 50.06130278] },
      geometry_name: 'shape',
      properties: {
        portname: 'Le Treport',
        code: 'FRLTR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.0613,
        longitude: 1.37096,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2999093,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15484',
      geometry: { type: 'Point', coordinates: [-82.18465084, 41.46980323] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lorain',
        code: 'USLOR',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.4698,
        longitude: -82.18465,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5161262,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15485',
      geometry: { type: 'Point', coordinates: [157.19949398, -8.22155447] },
      geometry_name: 'shape',
      properties: {
        portname: 'Noro',
        code: 'SBNOR',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.22155,
        longitude: 157.19949,
        iso3: 'SLB',
        iso3_op: 'SLB',
        country: 'Solomon islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2104863,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15486',
      geometry: { type: 'Point', coordinates: [-87.56584664, 44.14553511] },
      geometry_name: 'shape',
      properties: {
        portname: 'Two Rivers',
        code: 'USTWR',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.14554,
        longitude: -87.56585,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5276609,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15487',
      geometry: { type: 'Point', coordinates: [-95.01912733, 29.72874255] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baytown',
        code: 'USHPY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.72874,
        longitude: -95.01913,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4672731,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15488',
      geometry: { type: 'Point', coordinates: [-88.56245837, 30.34884473] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pascagoula',
        code: 'USPGL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.34884,
        longitude: -88.56246,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4440397,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15489',
      geometry: { type: 'Point', coordinates: [113.21789578, -7.7299941] },
      geometry_name: 'shape',
      properties: {
        portname: 'Probolinggo',
        code: 'IDPRO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.72999,
        longitude: 113.2179,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1630634,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15490',
      geometry: { type: 'Point', coordinates: [116.07257152, -8.73027325] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lembar',
        code: 'IDAMP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.73027,
        longitude: 116.07257,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1638059,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15491',
      geometry: { type: 'Point', coordinates: [118.71664194, -8.44935705] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bima',
        code: 'IDBMU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.44936,
        longitude: 118.71664,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1648759,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15492',
      geometry: { type: 'Point', coordinates: [-80.92234186, -2.21995912] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Libertad',
        code: 'ECLLD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.21996,
        longitude: -80.92234,
        iso3: 'ECU',
        iso3_op: 'ECU',
        country: 'Ecuador',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3655131,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15493',
      geometry: { type: 'Point', coordinates: [-77.44212871, 20.15829799] },
      geometry_name: 'shape',
      properties: {
        portname: 'Media Luna',
        code: 'CUMEL',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.1583,
        longitude: -77.44213,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3547260,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15494',
      geometry: { type: 'Point', coordinates: [-77.74545002, -10.80043718] },
      geometry_name: 'shape',
      properties: {
        portname: 'Supe',
        code: 'PESUP',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -10.80044,
        longitude: -77.74545,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3928274,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15495',
      geometry: { type: 'Point', coordinates: [-0.97594162, 37.59892702] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cartagena',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.59893,
        longitude: -0.97594,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2520058,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15496',
      geometry: { type: 'Point', coordinates: [-86.22843838, 43.06850419] },
      geometry_name: 'shape',
      properties: {
        portname: 'Grand Haven',
        code: 'USGHN',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.0685,
        longitude: -86.22844,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4994325,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15497',
      geometry: { type: 'Point', coordinates: [-76.70329657, 34.71672581] },
      geometry_name: 'shape',
      properties: {
        portname: 'Morehead City',
        code: 'USMRH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.71673,
        longitude: -76.7033,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4480153,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15498',
      geometry: { type: 'Point', coordinates: [-120.85574517, 35.37000208] },
      geometry_name: 'shape',
      properties: {
        portname: 'Morro Bay',
        code: 'USMJK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.37,
        longitude: -120.85575,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5374920,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15499',
      geometry: { type: 'Point', coordinates: [20.81602686, 2.47841677] },
      geometry_name: 'shape',
      properties: {
        portname: 'Molanda',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.47842,
        longitude: 20.81603,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 208884,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15500',
      geometry: { type: 'Point', coordinates: [124.38315363, 11.05199278] },
      geometry_name: 'shape',
      properties: {
        portname: 'Palompon',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.05199,
        longitude: 124.38315,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1696017,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15501',
      geometry: { type: 'Point', coordinates: [-53.96126609, 48.16011871] },
      geometry_name: 'shape',
      properties: {
        portname: 'Clarenville',
        code: 'CACLE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.16012,
        longitude: -53.96127,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5923118,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15502',
      geometry: { type: 'Point', coordinates: [-76.50740249, 37.23804787] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yorktown',
        code: 'USYKW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.23805,
        longitude: -76.5074,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4046946,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15503',
      geometry: { type: 'Point', coordinates: [-77.43917415, 37.53348044] },
      geometry_name: 'shape',
      properties: {
        portname: 'Richmond',
        code: 'USRIC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.53348,
        longitude: -77.43917,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4781708,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15505',
      geometry: { type: 'Point', coordinates: [27.91234673, 43.19695224] },
      geometry_name: 'shape',
      properties: {
        portname: 'Varna',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.19695,
        longitude: 27.91235,
        iso3: 'BGR',
        iso3_op: 'BGR',
        country: 'Bulgaria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 726050,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15506',
      geometry: { type: 'Point', coordinates: [18.27856746, 57.62914662] },
      geometry_name: 'shape',
      properties: {
        portname: 'Visby',
        code: 'SEVBY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.62915,
        longitude: 18.27857,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2662689,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15507',
      geometry: { type: 'Point', coordinates: [14.83013218, 36.71760741] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pozzallo',
        code: 'ITPZL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.71761,
        longitude: 14.83013,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2523693,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15508',
      geometry: { type: 'Point', coordinates: [13.19513008, 38.70750275] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ustica',
        code: 'ITUST',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.7075,
        longitude: 13.19513,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2522818,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15509',
      geometry: { type: 'Point', coordinates: [16.62990125, 38.82755317] },
      geometry_name: 'shape',
      properties: {
        portname: 'Catanzaro',
        code: 'ITQCZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.82755,
        longitude: 16.6299,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2525059,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15510',
      geometry: { type: 'Point', coordinates: [13.10588754, 54.30567798] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stralsund',
        code: 'DESTL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.30568,
        longitude: 13.10589,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2826287,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15511',
      geometry: { type: 'Point', coordinates: [27.17270117, 60.53449906] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hamina',
        code: 'FIHMN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.5345,
        longitude: 27.1727,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 659169,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15512',
      geometry: { type: 'Point', coordinates: [21.2217353, 62.35493067] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kaskinen',
        code: 'FIKAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 62.35493,
        longitude: 21.22174,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 653758,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15513',
      geometry: { type: 'Point', coordinates: [-3.51224849, 50.39798342] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brixham',
        code: 'GBBRX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.39798,
        longitude: -3.51225,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2654663,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15514',
      geometry: { type: 'Point', coordinates: [-8.73600186, 42.27630143] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moana',
        code: 'ESMOA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.2763,
        longitude: -8.736,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3116653,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15515',
      geometry: { type: 'Point', coordinates: [-9.0548697, 42.77516563] },
      geometry_name: 'shape',
      properties: {
        portname: 'Muros',
        code: 'ESMRS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.77517,
        longitude: -9.05487,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3115824,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15516',
      geometry: { type: 'Point', coordinates: [-1.98729167, 43.32278631] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Sebastian',
        code: 'ESEAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.32279,
        longitude: -1.98729,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3110044,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15517',
      geometry: { type: 'Point', coordinates: [26.79065906, -7.68127472] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kabumbulu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.68127,
        longitude: 26.79066,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 215414,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15518',
      geometry: { type: 'Point', coordinates: [26.94994129, -6.74657727] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ankoro',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.74658,
        longitude: 26.94994,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 220265,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15519',
      geometry: { type: 'Point', coordinates: [5.07636217, 36.7395258] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bejaia',
        code: 'DZBJA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.73953,
        longitude: 5.07636,
        iso3: 'DZA',
        iso3_op: 'DZA',
        country: 'Algeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2505329,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15520',
      geometry: { type: 'Point', coordinates: [19.22902156, 0.34751575] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bolomba',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 0.34752,
        longitude: 19.22902,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2316739,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15521',
      geometry: { type: 'Point', coordinates: [16.97451503, -4.85701179] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kenge',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.85701,
        longitude: 16.97452,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2314937,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15522',
      geometry: { type: 'Point', coordinates: [35.3833, -17.81786613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Caia',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -17.81787,
        longitude: 35.3833,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Caia: (I) -No Vessel Restriction- (Ii) -Truck Access- (Iii) -Network Coverage- (Iv) -No Hazard To Navigation--Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1024692,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15523',
      geometry: { type: 'Point', coordinates: [-7.40998832, 37.21383996] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ayamonte',
        code: 'ESAYA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.21384,
        longitude: -7.40999,
        iso3: null,
        iso3_op: 'ESP',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2521456,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15524',
      geometry: { type: 'Point', coordinates: [18.4927215, 40.1451355] },
      geometry_name: 'shape',
      properties: {
        portname: 'Otranto',
        code: 'ITOTO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.14514,
        longitude: 18.49272,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3171785,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15525',
      geometry: { type: 'Point', coordinates: [8.75675639, 42.56302983] },
      geometry_name: 'shape',
      properties: {
        portname: 'Calvi',
        code: 'FRCLY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.56303,
        longitude: 8.75676,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3029090,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15526',
      geometry: { type: 'Point', coordinates: [-10.19087076, 29.35820206] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sidi Ifni',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.3582,
        longitude: -10.19087,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2534515,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15527',
      geometry: { type: 'Point', coordinates: [21.26328287, 64.67548806] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ronnskar',
        code: 'SEROR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.67549,
        longitude: 21.26328,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 603280,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15528',
      geometry: { type: 'Point', coordinates: [28.46255344, 61.13393387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Joutseno',
        code: 'FIJOU',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.13393,
        longitude: 28.46255,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 655563,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15529',
      geometry: { type: 'Point', coordinates: [-11.43903672, 14.45255056] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kayes',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.45255,
        longitude: -11.43904,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2455518,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15530',
      geometry: { type: 'Point', coordinates: [124.34924095, 11.77780073] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maripipi',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.7778,
        longitude: 124.34924,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1700887,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15531',
      geometry: { type: 'Point', coordinates: [124.0004816, 12.00356019] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cataingan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.00356,
        longitude: 124.00048,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1717994,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15532',
      geometry: { type: 'Point', coordinates: [-55.1663343, 5.81282588] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paramaribo',
        code: 'SRPBM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.81283,
        longitude: -55.16633,
        iso3: 'SUR',
        iso3_op: 'SUR',
        country: 'Suriname',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3383330,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15533',
      geometry: { type: 'Point', coordinates: [18.91375333, -1.50598541] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kiri',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -1.50599,
        longitude: 18.91375,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2314195,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15534',
      geometry: { type: 'Point', coordinates: [13.18102109, 32.904118] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tripoli',
        code: 'LYTIP',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 32.90412,
        longitude: 13.18102,
        iso3: 'LBY',
        iso3_op: 'LBY',
        country: 'Libya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2210247,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15535',
      geometry: { type: 'Point', coordinates: [120.9604903, 14.59803754] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manila',
        code: 'PHMNL',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.59804,
        longitude: 120.96049,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1701668,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15536',
      geometry: { type: 'Point', coordinates: [18.12241467, -0.73528121] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bikoro',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -0.73528,
        longitude: 18.12241,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2317110,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15537',
      geometry: { type: 'Point', coordinates: [123.6758713, 12.92280816] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pilar',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.92281,
        longitude: 123.67587,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1693840,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15538',
      geometry: { type: 'Point', coordinates: [-59.95021145, 46.19842472] },
      geometry_name: 'shape',
      properties: {
        portname: 'Glace Bay',
        code: 'CAGLB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.19842,
        longitude: -59.95021,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5961564,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15539',
      geometry: { type: 'Point', coordinates: [104.46528681, -0.31586958] },
      geometry_name: 'shape',
      properties: {
        portname: 'Penuba',
        code: 'IDPNG',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -0.31587,
        longitude: 104.46529,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1631429,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15540',
      geometry: { type: 'Point', coordinates: [-87.06274911, 45.75213124] },
      geometry_name: 'shape',
      properties: {
        portname: 'Escanaba',
        code: 'USESC',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.75213,
        longitude: -87.06275,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4992232,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15541',
      geometry: { type: 'Point', coordinates: [-92.10965942, 46.76652842] },
      geometry_name: 'shape',
      properties: {
        portname: 'Duluth',
        code: 'USDLH',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.76653,
        longitude: -92.10966,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5024719,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15542',
      geometry: { type: 'Point', coordinates: [-91.25468283, 47.28768987] },
      geometry_name: 'shape',
      properties: {
        portname: 'Silver Bay',
        code: 'USSIB',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.28769,
        longitude: -91.25468,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5047308,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15543',
      geometry: { type: 'Point', coordinates: [-87.43376733, 44.60794021] },
      geometry_name: 'shape',
      properties: {
        portname: 'Algoma',
        code: 'USAMZ',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.60794,
        longitude: -87.43377,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5243712,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15544',
      geometry: { type: 'Point', coordinates: [-84.95923057, 45.37636033] },
      geometry_name: 'shape',
      properties: {
        portname: 'Petoskey',
        code: 'USPET',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.37636,
        longitude: -84.95923,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5005484,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15545',
      geometry: { type: 'Point', coordinates: [18.40914058, -4.36891815] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bilili',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.36892,
        longitude: 18.40914,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2317103,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15546',
      geometry: { type: 'Point', coordinates: [-55.16330375, 47.03911433] },
      geometry_name: 'shape',
      properties: {
        portname: 'Burin',
        code: 'CABUI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.03911,
        longitude: -55.1633,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5911495,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15547',
      geometry: { type: 'Point', coordinates: [20.47071377, 0.91714044] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mompanga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 0.91714,
        longitude: 20.47071,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 208801,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15548',
      geometry: { type: 'Point', coordinates: [22.72872325, 1.2114883] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lokolenge',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 1.21149,
        longitude: 22.72872,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 211536,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15549',
      geometry: { type: 'Point', coordinates: [18.60154655, -4.53364075] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bulungu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.53364,
        longitude: 18.60155,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2316259,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15550',
      geometry: { type: 'Point', coordinates: [-61.98345033, 45.13427663] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sherbrooke',
        code: 'CASBR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.13428,
        longitude: -61.98345,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 7284311,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15551',
      geometry: { type: 'Point', coordinates: [-72.86642058, 18.43480743] },
      geometry_name: 'shape',
      properties: {
        portname: 'Petit Goave',
        code: 'HTPEG',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.43481,
        longitude: -72.86642,
        iso3: null,
        iso3_op: 'HTI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3718962,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15552',
      geometry: { type: 'Point', coordinates: [155.63831298, -6.21827105] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kieta',
        code: 'PGKIE',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.21827,
        longitude: 155.63831,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2094027,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15553',
      geometry: { type: 'Point', coordinates: [50.28237429, -14.90110963] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antalaha',
        code: 'MGANM',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: 3,
        maxlength: 183,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -14.90111,
        longitude: 50.28237,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1071296,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15554',
      geometry: { type: 'Point', coordinates: [-124.2210583, 43.411939] },
      geometry_name: 'shape',
      properties: {
        portname: 'North Bend',
        code: 'USOTH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.41194,
        longitude: -124.22106,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5742974,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15555',
      geometry: { type: 'Point', coordinates: [-61.00811266, 46.63784574] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cheticamp',
        code: 'CACHT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.63785,
        longitude: -61.00811,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5921077,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15556',
      geometry: { type: 'Point', coordinates: [-71.2138948, 46.81965547] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quebec',
        code: 'CAQUE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.81966,
        longitude: -71.21389,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6325494,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15557',
      geometry: { type: 'Point', coordinates: [-55.75185084, 47.1013433] },
      geometry_name: 'shape',
      properties: {
        portname: 'Grand Bank',
        code: 'CAGRB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.10134,
        longitude: -55.75185,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5964230,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15558',
      geometry: { type: 'Point', coordinates: [-94.18731732, 58.77725623] },
      geometry_name: 'shape',
      properties: {
        portname: 'Churchill',
        code: 'CACHV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.77726,
        longitude: -94.18732,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5922111,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15559',
      geometry: { type: 'Point', coordinates: [-165.43043365, 64.50145623] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nome',
        code: 'USOME',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.50146,
        longitude: -165.43043,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5870133,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15560',
      geometry: { type: 'Point', coordinates: [-93.73346199, 30.08280232] },
      geometry_name: 'shape',
      properties: {
        portname: 'Orange',
        code: 'USORG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.0828,
        longitude: -93.73346,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4716805,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15561',
      geometry: { type: 'Point', coordinates: [-74.88037993, 40.07702465] },
      geometry_name: 'shape',
      properties: {
        portname: 'Burlington',
        code: 'USBTV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.07702,
        longitude: -74.88038,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5096075,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15562',
      geometry: { type: 'Point', coordinates: [-73.06953785, 40.94746631] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Jefferson',
        code: 'USPTJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.94747,
        longitude: -73.06954,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5132013,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15563',
      geometry: { type: 'Point', coordinates: [-73.94399971, 42.06993948] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saugerties',
        code: 'USSUV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.06994,
        longitude: -73.944,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5136353,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15564',
      geometry: { type: 'Point', coordinates: [-75.70933902, 45.42079735] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ottawa',
        code: 'CAOTT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.4208,
        longitude: -75.70934,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6094817,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15565',
      geometry: { type: 'Point', coordinates: [-62.64548472, 45.59006581] },
      geometry_name: 'shape',
      properties: {
        portname: 'New Glasgow',
        code: 'CANGL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.59007,
        longitude: -62.64548,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6087579,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15566',
      geometry: { type: 'Point', coordinates: [-62.7063, 45.67535407] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pictou',
        code: 'CAPTO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.67535,
        longitude: -62.7063,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6104221,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15567',
      geometry: { type: 'Point', coordinates: [-122.8897864, 46.10582606] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kelso',
        code: 'USKLS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.10583,
        longitude: -122.88979,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5799571,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15568',
      geometry: { type: 'Point', coordinates: [-55.14968115, 47.16502761] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marystown',
        code: 'CAMTN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.16503,
        longitude: -55.14968,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6067472,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15569',
      geometry: { type: 'Point', coordinates: [-94.83932292, 29.28047535] },
      geometry_name: 'shape',
      properties: {
        portname: 'Galveston',
        code: 'USGLS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.28048,
        longitude: -94.83932,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4692883,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15570',
      geometry: { type: 'Point', coordinates: [-90.10469766, 29.90523598] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marrero',
        code: 'USMAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.90524,
        longitude: -90.1047,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4332628,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15571',
      geometry: { type: 'Point', coordinates: [-75.60711998, 38.36440768] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salisbury',
        code: 'USSLS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.36441,
        longitude: -75.60712,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4368711,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15572',
      geometry: { type: 'Point', coordinates: [-74.02732266, 40.73473492] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hoboken',
        code: 'USHOB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.73473,
        longitude: -74.02732,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5099133,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15573',
      geometry: { type: 'Point', coordinates: [-70.70907013, 41.75208764] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wareham',
        code: 'USWMV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.75209,
        longitude: -70.70907,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4832324,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15574',
      geometry: { type: 'Point', coordinates: [-75.51266652, 44.70897059] },
      geometry_name: 'shape',
      properties: {
        portname: 'Prescott',
        code: 'CAPRE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.70897,
        longitude: -75.51267,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6113027,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15575',
      geometry: { type: 'Point', coordinates: [-62.5255234, 44.9165348] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sheet Harbour',
        code: 'CASHH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.91653,
        longitude: -62.52552,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6145815,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15576',
      geometry: { type: 'Point', coordinates: [-62.2508393, 46.34964264] },
      geometry_name: 'shape',
      properties: {
        portname: 'Souris',
        code: 'CASOU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.34964,
        longitude: -62.25084,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6151455,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15577',
      geometry: { type: 'Point', coordinates: [-53.26655809, 47.59181342] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bay Roberts',
        code: 'CABYR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.59181,
        longitude: -53.26656,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5895424,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15578',
      geometry: { type: 'Point', coordinates: [-122.94360072, 48.59785684] },
      geometry_name: 'shape',
      properties: {
        portname: 'Orcas',
        code: 'USXJY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.59786,
        longitude: -122.9436,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5805789,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15579',
      geometry: { type: 'Point', coordinates: [-55.34141482, 49.15195475] },
      geometry_name: 'shape',
      properties: {
        portname: 'Botwood',
        code: 'CABWD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.15195,
        longitude: -55.34141,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5906229,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15580',
      geometry: { type: 'Point', coordinates: [-57.75967914, 49.4606754] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lomond',
        code: 'CALMD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 49.46068,
        longitude: -57.75968,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6058543,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15581',
      geometry: { type: 'Point', coordinates: [-128.68922474, 54.00396268] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kitimat',
        code: 'CAKTM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.00396,
        longitude: -128.68922,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5993072,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15582',
      geometry: { type: 'Point', coordinates: [-165.78475823, 54.13225668] },
      geometry_name: 'shape',
      properties: {
        portname: 'Akutan',
        code: 'USAKU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.13226,
        longitude: -165.78476,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5879048,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15583',
      geometry: { type: 'Point', coordinates: [131.70249002, 32.5939809] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nobeoka',
        code: 'JPNOB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.59398,
        longitude: 131.70249,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1855095,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15584',
      geometry: { type: 'Point', coordinates: [12.63376061, 55.67455508] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kastrup',
        code: 'DKKTP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.67456,
        longitude: 12.63376,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2619030,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15585',
      geometry: { type: 'Point', coordinates: [22.42307247, 38.4320277] },
      geometry_name: 'shape',
      properties: {
        portname: 'Itea',
        code: 'GRITA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.43203,
        longitude: 22.42307,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 261715,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15586',
      geometry: { type: 'Point', coordinates: [10.32184952, 42.8128288] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portoferraio',
        code: 'ITPFE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.81283,
        longitude: 10.32185,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3170094,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15587',
      geometry: { type: 'Point', coordinates: [0.0753154, 49.36254478] },
      geometry_name: 'shape',
      properties: {
        portname: 'Deauville',
        code: 'FRDOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.36254,
        longitude: 0.07532,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3021668,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15588',
      geometry: { type: 'Point', coordinates: [19.08774529, 42.08546367] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bar',
        code: 'YUBAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.08546,
        longitude: 19.08775,
        iso3: 'MNE',
        iso3_op: 'MNE',
        country: 'Montenegro',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3204509,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15589',
      geometry: { type: 'Point', coordinates: [7.16597271, 53.70488545] },
      geometry_name: 'shape',
      properties: {
        portname: 'Norderney',
        code: 'DENRD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.70489,
        longitude: 7.16597,
        iso3: null,
        iso3_op: 'DEU',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2862041,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15590',
      geometry: { type: 'Point', coordinates: [6.79342862, 58.08544296] },
      geometry_name: 'shape',
      properties: {
        portname: 'Farsund',
        code: 'NOFAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.08544,
        longitude: 6.79343,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3157799,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15591',
      geometry: { type: 'Point', coordinates: [8.3835696, 58.24714241] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lillesand',
        code: 'NOLIL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.24714,
        longitude: 8.38357,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3147467,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15592',
      geometry: { type: 'Point', coordinates: [-0.36, 44.93533387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Izon',
        code: 'FRIZO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.93533,
        longitude: -0.36,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3012593,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15593',
      geometry: { type: 'Point', coordinates: [15.15204814, -26.64406003] },
      geometry_name: 'shape',
      properties: {
        portname: 'Luderitz',
        code: 'NALUD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -26.64406,
        longitude: 15.15205,
        iso3: 'NAM',
        iso3_op: 'NAM',
        country: 'Namibia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3355672,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15594',
      geometry: { type: 'Point', coordinates: [13.10453654, -7.83617995] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ambriz',
        code: 'AOAZZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.83618,
        longitude: 13.10454,
        iso3: null,
        iso3_op: 'AGO',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2243914,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15595',
      geometry: { type: 'Point', coordinates: [140.89106754, 36.93979475] },
      geometry_name: 'shape',
      properties: {
        portname: 'Onahama',
        code: 'JPONA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.93979,
        longitude: 140.89107,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2111398,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15596',
      geometry: { type: 'Point', coordinates: [-48.50130323, -25.5013568] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paranagua',
        code: 'BRPNG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -25.50136,
        longitude: -48.5013,
        iso3: null,
        iso3_op: 'BRA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3455070,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15597',
      geometry: { type: 'Point', coordinates: [-20.31866729, 65.82559536] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skagastrond',
        code: 'ISSKA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.8256,
        longitude: -20.31867,
        iso3: 'ISL',
        iso3_op: 'ISL',
        country: 'Iceland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3416248,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15598',
      geometry: { type: 'Point', coordinates: [1.25422227, 51.94715581] },
      geometry_name: 'shape',
      properties: {
        portname: 'Harwich',
        code: 'GBHRW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.94716,
        longitude: 1.25422,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2647383,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15599',
      geometry: { type: 'Point', coordinates: [7.01444885, 43.55101298] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cannes',
        code: 'FRCEQ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.55101,
        longitude: 7.01445,
        iso3: null,
        iso3_op: 'FRA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3028808,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15600',
      geometry: { type: 'Point', coordinates: [13.73379242, 45.55560114] },
      geometry_name: 'shape',
      properties: {
        portname: 'Koper',
        code: 'SIKOP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.5556,
        longitude: 13.73379,
        iso3: null,
        iso3_op: 'SVN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3197753,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15601',
      geometry: { type: 'Point', coordinates: [11.12103869, 42.43531867] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porto Santo Stefano',
        code: 'ITPSS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.43532,
        longitude: 11.12104,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3170071,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15602',
      geometry: { type: 'Point', coordinates: [13.84768234, 44.87588485] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pula',
        code: 'HRPUY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.87588,
        longitude: 13.84768,
        iso3: 'HRV',
        iso3_op: 'HRV',
        country: 'Croatia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3192224,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15603',
      geometry: { type: 'Point', coordinates: [-3.16014452, 48.70416445] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pontrieux',
        code: 'FRPOX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.70416,
        longitude: -3.16014,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2986121,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15604',
      geometry: { type: 'Point', coordinates: [-5.04652863, 50.26052423] },
      geometry_name: 'shape',
      properties: {
        portname: 'Truro',
        code: 'GBTRU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.26052,
        longitude: -5.04653,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2635412,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15605',
      geometry: { type: 'Point', coordinates: [-4.70853081, 50.35085723] },
      geometry_name: 'shape',
      properties: {
        portname: 'Par',
        code: 'GBPAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.35086,
        longitude: -4.70853,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2640623,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15606',
      geometry: { type: 'Point', coordinates: [1.39704604, 51.22405402] },
      geometry_name: 'shape',
      properties: {
        portname: 'Deal',
        code: 'GBDEX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 51.22405,
        longitude: 1.39705,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2651468,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15607',
      geometry: { type: 'Point', coordinates: [-3.15709121, 51.45939205] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cardiff',
        code: 'GBCDF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.45939,
        longitude: -3.15709,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2653822,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15608',
      geometry: { type: 'Point', coordinates: [-2.70364881, 51.50408478] },
      geometry_name: 'shape',
      properties: {
        portname: 'Avonmouth',
        code: 'GBAVO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.50408,
        longitude: -2.70365,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2656737,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15609',
      geometry: { type: 'Point', coordinates: [-2.7263221, 51.49371303] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portbury',
        code: 'GBPRU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.49371,
        longitude: -2.72632,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2640075,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15610',
      geometry: { type: 'Point', coordinates: [0.51447151, 51.5123058] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coryton',
        code: 'GBCOY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.51231,
        longitude: 0.51447,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2652300,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15611',
      geometry: { type: 'Point', coordinates: [-4.95364054, 51.6951524] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pembroke',
        code: 'GBPEM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.69515,
        longitude: -4.95364,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2640503,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15612',
      geometry: { type: 'Point', coordinates: [-6.99119549, 52.14743416] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dunmore East',
        code: 'IEDNM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.14743,
        longitude: -6.9912,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2964492,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15613',
      geometry: { type: 'Point', coordinates: [6.59487248, 53.2206627] },
      geometry_name: 'shape',
      properties: {
        portname: 'Groningen',
        code: 'NLGRQ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.22066,
        longitude: 6.59487,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2755251,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15614',
      geometry: { type: 'Point', coordinates: [-1.86159286, 35.10097983] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ghazaouet',
        code: 'DZGHA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.10098,
        longitude: -1.86159,
        iso3: 'DZA',
        iso3_op: 'DZA',
        country: 'Algeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2496042,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15615',
      geometry: { type: 'Point', coordinates: [-3.92558688, 35.24740671] },
      geometry_name: 'shape',
      properties: {
        portname: 'Al Hoceima',
        code: 'MAAHU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.24741,
        longitude: -3.92559,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2558545,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15616',
      geometry: { type: 'Point', coordinates: [6.91165257, 36.88517791] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skikda',
        code: 'DZSKI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.88518,
        longitude: 6.91165,
        iso3: 'DZA',
        iso3_op: 'DZA',
        country: 'Algeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2479536,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15617',
      geometry: { type: 'Point', coordinates: [140.95717573, 37.83572263] },
      geometry_name: 'shape',
      properties: {
        portname: 'Soma',
        code: 'JPSMA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.83572,
        longitude: 140.95718,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6822169,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15618',
      geometry: { type: 'Point', coordinates: [39.19235355, -6.1570729] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zanzibar',
        code: 'TZZNZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.15707,
        longitude: 39.19235,
        iso3: 'TZA',
        iso3_op: 'TZA',
        country: 'United Republic of Tanzania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 148730,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15619',
      geometry: { type: 'Point', coordinates: [98.41470997, 7.87303567] },
      geometry_name: 'shape',
      properties: {
        portname: 'Phuket',
        code: 'THHKT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 7.87304,
        longitude: 98.41471,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1151254,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15620',
      geometry: { type: 'Point', coordinates: [98.51346043, 8.39960684] },
      geometry_name: 'shape',
      properties: {
        portname: 'Phangnga',
        code: 'THPHA',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.39961,
        longitude: 98.51346,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1151464,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15621',
      geometry: { type: 'Point', coordinates: [102.11341897, 12.60207911] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chanthaburi',
        code: 'THCHA',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 12.60208,
        longitude: 102.11342,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1611269,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15622',
      geometry: { type: 'Point', coordinates: [170.62441964, -45.81223761] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Chalmers',
        code: 'NZPOE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -45.81224,
        longitude: 170.62442,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2208238,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15623',
      geometry: { type: 'Point', coordinates: [145.29763694, -40.76640125] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stanley',
        code: 'AUSTA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -40.7664,
        longitude: 145.29764,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2148528,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15624',
      geometry: { type: 'Point', coordinates: [140.01313776, -37.48074325] },
      geometry_name: 'shape',
      properties: {
        portname: 'Beachport',
        code: 'AUBCH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -37.48074,
        longitude: 140.01314,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2176552,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15625',
      geometry: { type: 'Point', coordinates: [150.89455628, -34.46973788] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Kembla',
        code: 'AUPKL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.46974,
        longitude: 150.89456,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2152671,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15626',
      geometry: { type: 'Point', coordinates: [-72.95898796, -36.61918612] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tome',
        code: 'CLTOM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -36.61919,
        longitude: -72.95899,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3869657,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15627',
      geometry: { type: 'Point', coordinates: [-71.50970909, -31.90907946] },
      geometry_name: 'shape',
      properties: {
        portname: 'Los Vilos',
        code: 'CLLOS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -31.90908,
        longitude: -71.50971,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3881285,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15628',
      geometry: { type: 'Point', coordinates: [-50.14362393, -29.98563378] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tramandai',
        code: 'BRTRM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -29.98563,
        longitude: -50.14362,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3446232,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15629',
      geometry: { type: 'Point', coordinates: [-48.64935257, -28.22984136] },
      geometry_name: 'shape',
      properties: {
        portname: 'Imbituba',
        code: 'BRIBB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -28.22984,
        longitude: -48.64935,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3461370,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15630',
      geometry: { type: 'Point', coordinates: [152.90264871, -25.28393127] },
      geometry_name: 'shape',
      properties: {
        portname: 'Urangan',
        code: 'AUURN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -25.28393,
        longitude: 152.90265,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2145392,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15631',
      geometry: { type: 'Point', coordinates: [-70.20964036, -19.59413727] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pisagua',
        code: 'CLPIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -19.59414,
        longitude: -70.20964,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3875795,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15632',
      geometry: { type: 'Point', coordinates: [-151.7526738, -16.50595132] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vaitape',
        code: 'PFVAI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.50595,
        longitude: -151.75267,
        iso3: 'PYF',
        iso3_op: 'PYF',
        country: 'French Polynesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4033077,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15633',
      geometry: { type: 'Point', coordinates: [136.06499105, 35.65826765] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tsuruga',
        code: 'JPTRG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.65827,
        longitude: 136.06499,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1849592,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15634',
      geometry: { type: 'Point', coordinates: [117.66116367, 4.24747171] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wallace Bay',
        code: 'MYWAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.24747,
        longitude: 117.66116,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1734188,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15635',
      geometry: { type: 'Point', coordinates: [111.95178114, 21.83310465] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yangjiang',
        code: 'CNYJI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 21.8331,
        longitude: 111.95178,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1806408,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15636',
      geometry: { type: 'Point', coordinates: [69.08174858, 22.47320699] },
      geometry_name: 'shape',
      properties: {
        portname: 'Okha',
        code: 'INOKH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.47321,
        longitude: 69.08175,
        iso3: null,
        iso3_op: 'IND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1261066,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15637',
      geometry: { type: 'Point', coordinates: [114.26322116, 22.58088791] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yantian',
        code: 'CNYTN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.58089,
        longitude: 114.26322,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1787075,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15638',
      geometry: { type: 'Point', coordinates: [129.5672136, 33.02102326] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sakito',
        code: 'JPSTO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.02102,
        longitude: 129.56721,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1853099,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15639',
      geometry: { type: 'Point', coordinates: [133.67176491, 34.22591921] },
      geometry_name: 'shape',
      properties: {
        portname: 'Takuma',
        code: 'JPTKM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.22592,
        longitude: 133.67176,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1850777,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15640',
      geometry: { type: 'Point', coordinates: [133.78000062, 34.28827208] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marugame',
        code: 'JPMAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.28827,
        longitude: 133.78,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1857665,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15641',
      geometry: { type: 'Point', coordinates: [139.66180501, 35.28697066] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yokosuka',
        code: 'JPYOS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.28697,
        longitude: 139.66181,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1848313,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15642',
      geometry: { type: 'Point', coordinates: [141.50490447, 40.54524905] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hachinohe',
        code: 'JPHHE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.54525,
        longitude: 141.5049,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2130203,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15643',
      geometry: { type: 'Point', coordinates: [29.62054822, 40.78565608] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hereke',
        code: 'TRHER',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.78566,
        longitude: 29.62055,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 745660,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15644',
      geometry: { type: 'Point', coordinates: [34.58464702, 41.94791217] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ayancik',
        code: 'TRAYA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.94791,
        longitude: 34.58465,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 751516,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15645',
      geometry: { type: 'Point', coordinates: [140.58298506, 42.10789859] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mori',
        code: 'JPMOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.1079,
        longitude: 140.58299,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2129142,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15646',
      geometry: { type: 'Point', coordinates: [144.37109332, 42.98313633] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kushiro',
        code: 'JPKUH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.98314,
        longitude: 144.37109,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2129376,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15647',
      geometry: { type: 'Point', coordinates: [169.84385224, -46.28637105] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kaitangata',
        code: 'NZKTG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -46.28637,
        longitude: 169.84385,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2208245,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15648',
      geometry: { type: 'Point', coordinates: [171.25537212, -44.38903002] },
      geometry_name: 'shape',
      properties: {
        portname: 'Timaru',
        code: 'NZTIU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -44.38903,
        longitude: 171.25537,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2181133,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15649',
      geometry: { type: 'Point', coordinates: [172.72449137, -43.60721666] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lyttelton',
        code: 'NZLYT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -43.60722,
        longitude: 172.72449,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6220981,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15650',
      geometry: { type: 'Point', coordinates: [172.70467797, -43.55791083] },
      geometry_name: 'shape',
      properties: {
        portname: 'Christchurch',
        code: 'NZCHC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -43.55791,
        longitude: 172.70468,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2192362,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15651',
      geometry: { type: 'Point', coordinates: [-58.71119937, -38.57665459] },
      geometry_name: 'shape',
      properties: {
        portname: 'Necochea',
        code: 'ARNEC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -38.57665,
        longitude: -58.7112,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3430443,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15652',
      geometry: { type: 'Point', coordinates: [176.18293444, -37.65752243] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tauranga',
        code: 'NZTRG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -37.65752,
        longitude: 176.18293,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2208032,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15653',
      geometry: { type: 'Point', coordinates: [173.87059333, -35.94184922] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dargaville',
        code: 'NZDGR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -35.94185,
        longitude: 173.87059,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2191911,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15654',
      geometry: { type: 'Point', coordinates: [115.34871514, -33.64838628] },
      geometry_name: 'shape',
      properties: {
        portname: 'Busselton',
        code: 'AUBUS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -33.64839,
        longitude: 115.34872,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2075265,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15655',
      geometry: { type: 'Point', coordinates: [152.38567362, -24.77259893] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bundaberg',
        code: 'AUBDB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -24.7726,
        longitude: 152.38567,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2173323,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15656',
      geometry: { type: 'Point', coordinates: [111.82374736, 2.28820431] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sibu',
        code: 'MYSBW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.2882,
        longitude: 111.82375,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1735902,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15657',
      geometry: { type: 'Point', coordinates: [120.99971782, 6.05276309] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jolo',
        code: 'PHJOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.05276,
        longitude: 120.99972,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1710141,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15658',
      geometry: { type: 'Point', coordinates: [102.16879153, 6.20070009] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tumpat',
        code: 'MYTUM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.2007,
        longitude: 102.16879,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1736356,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15659',
      geometry: { type: 'Point', coordinates: [32.30253569, 30.34046771] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fayid',
        code: 'EGFAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.34047,
        longitude: 32.30254,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 357039,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15660',
      geometry: { type: 'Point', coordinates: [130.42241948, 33.01706788] },
      geometry_name: 'shape',
      properties: {
        portname: 'Miike',
        code: 'JPMII',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.01707,
        longitude: 130.42242,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1857313,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15661',
      geometry: { type: 'Point', coordinates: [132.55233745, 33.22906393] },
      geometry_name: 'shape',
      properties: {
        portname: 'Uwajima',
        code: 'JPUWA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.22906,
        longitude: 132.55234,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1926020,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15662',
      geometry: { type: 'Point', coordinates: [133.18555595, 34.27879894] },
      geometry_name: 'shape',
      properties: {
        portname: 'Innoshima',
        code: 'JPINS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.2788,
        longitude: 133.18556,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1861586,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15663',
      geometry: { type: 'Point', coordinates: [133.08974295, 34.39433057] },
      geometry_name: 'shape',
      properties: {
        portname: 'Itozaki',
        code: 'JPITO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.39433,
        longitude: 133.08974,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1861266,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15664',
      geometry: { type: 'Point', coordinates: [146.83101602, -19.25696686] },
      geometry_name: 'shape',
      properties: {
        portname: 'Townsville',
        code: 'AUTSV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -19.25697,
        longitude: 146.83102,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2146142,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15665',
      geometry: { type: 'Point', coordinates: [103.8918128, 1.42132271] },
      geometry_name: 'shape',
      properties: {
        portname: 'Seletar',
        code: 'SGSLT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.42132,
        longitude: 103.89181,
        iso3: null,
        iso3_op: 'SGP',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1880294,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15666',
      geometry: { type: 'Point', coordinates: [-71.52781063, 10.71036785] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Estacada',
        code: 'VELEC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.71037,
        longitude: -71.52781,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3637976,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15667',
      geometry: { type: 'Point', coordinates: [122.69469241, 14.2849933] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jose Panganiban',
        code: 'PHJPM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.28499,
        longitude: 122.69469,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1710103,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15668',
      geometry: { type: 'Point', coordinates: [120.22846791, 16.01745667] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lingayen',
        code: 'PHLIN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.01746,
        longitude: 120.22847,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1706188,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15669',
      geometry: { type: 'Point', coordinates: [72.23132468, 21.75976971] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bhavnagar',
        code: 'INBHU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.75977,
        longitude: 72.23132,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1276032,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15670',
      geometry: { type: 'Point', coordinates: [129.06014024, 35.12430105] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pusan',
        code: 'KRPUS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.1243,
        longitude: 129.06014,
        iso3: 'KOR',
        iso3_op: 'KOR',
        country: 'Republic of Korea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1838524,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15671',
      geometry: { type: 'Point', coordinates: [127.44446208, 39.15952493] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wonsan',
        code: 'KPWON',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.15952,
        longitude: 127.44446,
        iso3: null,
        iso3_op: 'PRK',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1866923,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15672',
      geometry: { type: 'Point', coordinates: [140.00789975, 40.210404] },
      geometry_name: 'shape',
      properties: {
        portname: 'Noshiro',
        code: 'JPNSR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 40.2104,
        longitude: 140.0079,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2128867,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15673',
      geometry: { type: 'Point', coordinates: [122.27059537, 40.69828586] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yingkou',
        code: 'CNYIK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.69829,
        longitude: 122.2706,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2033370,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15674',
      geometry: { type: 'Point', coordinates: [29.28350659, 40.8366593] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tuzla',
        code: 'TRTUZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.83666,
        longitude: 29.28351,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 738549,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15675',
      geometry: { type: 'Point', coordinates: [142.76151876, 46.62973401] },
      geometry_name: 'shape',
      properties: {
        portname: 'Korsakov',
        code: 'RUKOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.62973,
        longitude: 142.76152,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2124286,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15676',
      geometry: { type: 'Point', coordinates: [22.2193, 60.44253387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Turku',
        code: 'FITKU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.44253,
        longitude: 22.2193,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 633679,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15677',
      geometry: { type: 'Point', coordinates: [114.29363457, 30.58048301] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wuhan',
        code: 'CNWUH',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.58048,
        longitude: 114.29363,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1791247,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15678',
      geometry: { type: 'Point', coordinates: [26.40266408, 40.15029079] },
      geometry_name: 'shape',
      properties: {
        portname: 'Canakkale',
        code: 'TRCKZ',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.15029,
        longitude: 26.40266,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 749780,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15679',
      geometry: { type: 'Point', coordinates: [-58.37343682, -34.590584] },
      geometry_name: 'shape',
      properties: {
        portname: 'Buenos Aires',
        code: 'ARBUE',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.59058,
        longitude: -58.37344,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3435910,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15680',
      geometry: { type: 'Point', coordinates: [47.83071345, -22.81379073] },
      geometry_name: 'shape',
      properties: {
        portname: 'Farafangana',
        code: 'MGRVA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -22.81379,
        longitude: 47.83071,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1065158,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15681',
      geometry: { type: 'Point', coordinates: [-71.62628137, -33.03935226] },
      geometry_name: 'shape',
      properties: {
        portname: 'Valparaiso',
        code: 'CLVAP',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.03935,
        longitude: -71.62628,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3868626,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15682',
      geometry: { type: 'Point', coordinates: [-40.24220282, -20.28376449] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tubarao',
        code: 'BRTUB',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.28376,
        longitude: -40.2422,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3445994,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15683',
      geometry: { type: 'Point', coordinates: [-48.49364675, -1.44050259] },
      geometry_name: 'shape',
      properties: {
        portname: 'Belem',
        code: 'BRBEL',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.4405,
        longitude: -48.49365,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3405870,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15684',
      geometry: { type: 'Point', coordinates: [-89.83379932, 13.57452987] },
      geometry_name: 'shape',
      properties: {
        portname: 'Acajutla',
        code: 'SVAQJ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.57453,
        longitude: -89.8338,
        iso3: 'SLV',
        iso3_op: 'SLV',
        country: 'El Salvador',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3587498,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15685',
      geometry: { type: 'Point', coordinates: [-78.76856515, 26.51868688] },
      geometry_name: 'shape',
      properties: {
        portname: 'Freeport',
        code: 'BSFPO',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.51869,
        longitude: -78.76857,
        iso3: 'BHS',
        iso3_op: 'BHS',
        country: 'Bahamas',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3572375,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15686',
      geometry: { type: 'Point', coordinates: [-68.00371787, 10.47301093] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Cabello',
        code: 'VEPBL',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.47301,
        longitude: -68.00372,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3629706,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15687',
      geometry: { type: 'Point', coordinates: [-109.06023333, 25.59075746] },
      geometry_name: 'shape',
      properties: {
        portname: 'Topolobampo',
        code: 'MXTPB',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.59076,
        longitude: -109.06023,
        iso3: null,
        iso3_op: 'MEX',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3981345,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15688',
      geometry: { type: 'Point', coordinates: [-71.52718269, -32.78126946] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quintero',
        code: 'CLQTV',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.78127,
        longitude: -71.52718,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3873983,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15689',
      geometry: { type: 'Point', coordinates: [-71.34915993, -29.96873219] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guayacan',
        code: 'CLGYC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -29.96873,
        longitude: -71.34916,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3888317,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15690',
      geometry: { type: 'Point', coordinates: [-70.48942709, -25.40791502] },
      geometry_name: 'shape',
      properties: {
        portname: 'Taltal',
        code: 'CLTTC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -25.40792,
        longitude: -70.48943,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3870243,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15691',
      geometry: { type: 'Point', coordinates: [-70.2302499, 11.62577495] },
      geometry_name: 'shape',
      properties: {
        portname: 'Punta Cardon',
        code: 'VEPCN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.62577,
        longitude: -70.23025,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3629614,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15692',
      geometry: { type: 'Point', coordinates: [26.69751474, 39.32861971] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ayvalik',
        code: 'TRAYV',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.32862,
        longitude: 26.69751,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 322673,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15693',
      geometry: { type: 'Point', coordinates: [-95.76790468, 18.77026525] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alvarado',
        code: 'MXAVD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.77027,
        longitude: -95.7679,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3532964,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15694',
      geometry: { type: 'Point', coordinates: [-112.26645826, 27.34134564] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santa Rosalia',
        code: 'MXSRL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.34135,
        longitude: -112.26646,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3983805,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15695',
      geometry: { type: 'Point', coordinates: [33.76604815, 41.97850424] },
      geometry_name: 'shape',
      properties: {
        portname: 'Inebolu',
        code: 'TRINE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.9785,
        longitude: 33.76605,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 745175,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15696',
      geometry: { type: 'Point', coordinates: [-81.28203238, -4.5765452] },
      geometry_name: 'shape',
      properties: {
        portname: 'Talara',
        code: 'PETYL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.57655,
        longitude: -81.28203,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3691582,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15697',
      geometry: { type: 'Point', coordinates: [-56.99537291, 5.94831384] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nieuw Nickerie',
        code: 'SRICK',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.94831,
        longitude: -56.99537,
        iso3: 'SUR',
        iso3_op: 'SUR',
        country: 'Suriname',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3383427,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15698',
      geometry: { type: 'Point', coordinates: [-59.625, 13.09953387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bridgetown',
        code: 'BBBGI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.09953,
        longitude: -59.625,
        iso3: null,
        iso3_op: 'BRB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3374036,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15699',
      geometry: { type: 'Point', coordinates: [-66.6143463, 17.97097899] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ponce',
        code: 'PRPSE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.97098,
        longitude: -66.61435,
        iso3: 'PRI',
        iso3_op: 'PRI',
        country: 'Puerto Rico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4566880,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15700',
      geometry: { type: 'Point', coordinates: [-66.7589, 18.00203387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guayanilla',
        code: 'PRGUY',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.00203,
        longitude: -66.7589,
        iso3: null,
        iso3_op: 'PRI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4565111,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15701',
      geometry: { type: 'Point', coordinates: [101.44849171, 1.68610133] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dumai',
        code: 'IDDUM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.6861,
        longitude: 101.44849,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1645133,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15702',
      geometry: { type: 'Point', coordinates: [120.24986904, -9.64120691] },
      geometry_name: 'shape',
      properties: {
        portname: 'Waingapu',
        code: 'IDWGP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -9.64121,
        longitude: 120.24987,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1622318,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15703',
      geometry: { type: 'Point', coordinates: [-80.45628492, 22.14121033] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cienfuegos',
        code: 'CUCFG',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.14121,
        longitude: -80.45628,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3564124,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15704',
      geometry: { type: 'Point', coordinates: [-86.76354302, 12.20179623] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Sandino',
        code: 'NIPSN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.2018,
        longitude: -86.76354,
        iso3: 'NIC',
        iso3_op: 'NIC',
        country: 'Nicaragua',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3617143,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15705',
      geometry: { type: 'Point', coordinates: [-94.41357247, 18.07357114] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nanchital',
        code: 'MXNAN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.07357,
        longitude: -94.41357,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3522845,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15706',
      geometry: { type: 'Point', coordinates: [-59.99796814, -33.47639105] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ramallo',
        code: 'ARRAM',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.47639,
        longitude: -59.99797,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3839444,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15707',
      geometry: { type: 'Point', coordinates: [81.09534062, 6.12842546] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hambantota',
        code: 'LKHBA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.12843,
        longitude: 81.09534,
        iso3: 'LKA',
        iso3_op: 'LKA',
        country: 'Sri Lanka',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1244926,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15708',
      geometry: { type: 'Point', coordinates: [-80.49806693, 8.24315008] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aguadulce',
        code: 'PAAGD',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.24315,
        longitude: -80.49807,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3715042,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15709',
      geometry: { type: 'Point', coordinates: [-52.64378637, 5.14878852] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kourou',
        code: 'GFQKR',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.14879,
        longitude: -52.64379,
        iso3: 'GUF',
        iso3_op: 'GUF',
        country: 'French Guiana',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3381303,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15710',
      geometry: { type: 'Point', coordinates: [-91.91519215, 14.29539876] },
      geometry_name: 'shape',
      properties: {
        portname: 'Champerico',
        code: 'GTCHR',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.2954,
        longitude: -91.91519,
        iso3: 'GTM',
        iso3_op: 'GTM',
        country: 'Guatemala',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3598787,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15711',
      geometry: { type: 'Point', coordinates: [-170.69365203, -14.27541188] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pago Pago',
        code: 'ASPPG',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -14.27541,
        longitude: -170.69365,
        iso3: 'ASM',
        iso3_op: 'ASM',
        country: 'American Samoa',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5881576,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15712',
      geometry: { type: 'Point', coordinates: [32.3009, 31.24333387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Said',
        code: 'EGPSD',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.24333,
        longitude: 32.3009,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 358619,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15713',
      geometry: { type: 'Point', coordinates: [146.9797, -6.73496613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lae',
        code: 'PGLAE',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.73497,
        longitude: 146.9797,
        iso3: 'PNG',
        iso3_op: 'PNG',
        country: 'Papua New Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2092740,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15714',
      geometry: { type: 'Point', coordinates: [-79.83821522, -2.19278358] },
      geometry_name: 'shape',
      properties: {
        portname: 'Duran',
        code: 'ECDUN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.19278,
        longitude: -79.83822,
        iso3: 'ECU',
        iso3_op: 'ECU',
        country: 'Ecuador',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3658192,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15715',
      geometry: { type: 'Point', coordinates: [34.88575897, 32.4671211] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hadera',
        code: 'ILHAD',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.46712,
        longitude: 34.88576,
        iso3: null,
        iso3_op: 'ISR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 294946,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15716',
      geometry: { type: 'Point', coordinates: [9.98742921, 53.53577192] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hamburg',
        code: 'DEHAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.53577,
        longitude: 9.98743,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2911298,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15717',
      geometry: { type: 'Point', coordinates: [7.15835042, 53.62578103] },
      geometry_name: 'shape',
      properties: {
        portname: 'Norddeich',
        code: 'DENOE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.62578,
        longitude: 7.15835,
        iso3: null,
        iso3_op: 'DEU',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2862136,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15718',
      geometry: { type: 'Point', coordinates: [-0.872, 53.70080356] },
      geometry_name: 'shape',
      properties: {
        portname: 'Goole',
        code: 'GBGOO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.7008,
        longitude: -0.872,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2648319,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15719',
      geometry: { type: 'Point', coordinates: [-6.34002937, 53.71678817] },
      geometry_name: 'shape',
      properties: {
        portname: 'Drogheda',
        code: 'IEDRO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.71679,
        longitude: -6.34003,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2964661,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15720',
      geometry: { type: 'Point', coordinates: [-0.61444254, 54.48998807] },
      geometry_name: 'shape',
      properties: {
        portname: 'Whitby',
        code: 'GBWTB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.48999,
        longitude: -0.61444,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2634135,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15721',
      geometry: { type: 'Point', coordinates: [11.72627041, 54.66663956] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nysted',
        code: 'DKNTD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.66664,
        longitude: 11.72627,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2615911,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15722',
      geometry: { type: 'Point', coordinates: [10.66497275, 55.4520619] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kerteminde',
        code: 'DKKTD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.45206,
        longitude: 10.66497,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2618944,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15723',
      geometry: { type: 'Point', coordinates: [9.49587935, 55.49069661] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kolding',
        code: 'DKKOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.4907,
        longitude: 9.49588,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2618528,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15724',
      geometry: { type: 'Point', coordinates: [-4.48172289, 55.92998445] },
      geometry_name: 'shape',
      properties: {
        portname: 'Old Kilpatrick',
        code: 'GBOKK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.92998,
        longitude: -4.48172,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2641014,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15725',
      geometry: { type: 'Point', coordinates: [-5.44684841, 56.01384456] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ardrishaig',
        code: 'GBASG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.01384,
        longitude: -5.44685,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2657126,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15726',
      geometry: { type: 'Point', coordinates: [74.6949, 13.34653387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malpe',
        code: 'INMAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.34653,
        longitude: 74.6949,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1264037,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15727',
      geometry: { type: 'Point', coordinates: [-2.71775415, 56.06014915] },
      geometry_name: 'shape',
      properties: {
        portname: 'North Berwick',
        code: 'GBNBW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.06015,
        longitude: -2.71775,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2641419,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15728',
      geometry: { type: 'Point', coordinates: [10.05016551, 56.46012008] },
      geometry_name: 'shape',
      properties: {
        portname: 'Randers',
        code: 'DKRAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.46012,
        longitude: 10.05017,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2615006,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15729',
      geometry: { type: 'Point', coordinates: [9.9834523, 56.65357758] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mariager',
        code: 'DKMRR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.65358,
        longitude: 9.98345,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2617076,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15730',
      geometry: { type: 'Point', coordinates: [17.02617497, 59.37849651] },
      geometry_name: 'shape',
      properties: {
        portname: 'Strangnas',
        code: 'SESTQ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.3785,
        longitude: 17.02617,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2671392,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15731',
      geometry: { type: 'Point', coordinates: [10.64227848, 59.91187733] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lysaker',
        code: 'NOLYS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.91188,
        longitude: 10.64228,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3146631,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15732',
      geometry: { type: 'Point', coordinates: [17.17059692, 61.26021333] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sandarne',
        code: 'SESAE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.26021,
        longitude: 17.1706,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2680350,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15733',
      geometry: { type: 'Point', coordinates: [25.29094973, 65.21230963] },
      geometry_name: 'shape',
      properties: {
        portname: 'Haukipudas',
        code: 'FIHAU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.21231,
        longitude: 25.29095,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 658629,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15734',
      geometry: { type: 'Point', coordinates: [12.13260775, -15.19543902] },
      geometry_name: 'shape',
      properties: {
        portname: 'Namibe',
        code: 'AOMSZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -15.19544,
        longitude: 12.13261,
        iso3: 'AGO',
        iso3_op: 'AGO',
        country: 'Angola',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3347019,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15735',
      geometry: { type: 'Point', coordinates: [-87.70173592, 43.75393541] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sheboygan',
        code: 'USSBZ',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.75394,
        longitude: -87.70174,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5272893,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15736',
      geometry: { type: 'Point', coordinates: [27.90852753, -33.02484545] },
      geometry_name: 'shape',
      properties: {
        portname: 'East London',
        code: 'ZAELS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -33.02485,
        longitude: 27.90853,
        iso3: 'ZAF',
        iso3_op: 'ZAF, MWI, ZWE',
        country: 'South Africa',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1006984,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15737',
      geometry: { type: 'Point', coordinates: [132.24054337, 34.21882878] },
      geometry_name: 'shape',
      properties: {
        portname: 'Otake',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.21883,
        longitude: 132.24054,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1853662,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15738',
      geometry: { type: 'Point', coordinates: [-157.87272162, 21.31195773] },
      geometry_name: 'shape',
      properties: {
        portname: 'Honolulu',
        code: 'USHNL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.31196,
        longitude: -157.87272,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5856195,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15739',
      geometry: { type: 'Point', coordinates: [-74.21570869, 11.24915662] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santa Marta',
        code: 'COSMR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.24916,
        longitude: -74.21571,
        iso3: null,
        iso3_op: 'COL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3668605,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15740',
      geometry: { type: 'Point', coordinates: [106.80255688, -6.10433612] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jakarta',
        code: 'IDJKT',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.10434,
        longitude: 106.80256,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1642911,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15741',
      geometry: { type: 'Point', coordinates: [106.87614697, -6.10492096] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanjung Priok',
        code: 'IDTPP',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.10492,
        longitude: 106.87615,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1624866,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15742',
      geometry: { type: 'Point', coordinates: [123.59050745, -10.15375117] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kupang',
        code: 'IDKOE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -10.15375,
        longitude: 123.59051,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2057087,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15743',
      geometry: { type: 'Point', coordinates: [125.19402559, 1.4389017] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bitung',
        code: 'IDBIT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.4389,
        longitude: 125.19403,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1648636,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15744',
      geometry: { type: 'Point', coordinates: [-79.91251468, -2.73513355] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puna',
        code: 'ECPUN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.73513,
        longitude: -79.91251,
        iso3: 'ECU',
        iso3_op: 'ECU',
        country: 'Ecuador',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3652660,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15745',
      geometry: { type: 'Point', coordinates: [-75.70484263, 20.91922378] },
      geometry_name: 'shape',
      properties: {
        portname: 'Banes',
        code: 'CUBAN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.91922,
        longitude: -75.70484,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3567869,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15746',
      geometry: { type: 'Point', coordinates: [-81.23059743, -4.25051159] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cabo Blanco',
        code: 'PECAB',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.25051,
        longitude: -81.2306,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3699169,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15747',
      geometry: { type: 'Point', coordinates: [-82.44532915, 27.92634748] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Tampa',
        code: 'USPOT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.92635,
        longitude: -82.44533,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4169182,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15748',
      geometry: { type: 'Point', coordinates: [-84.98267409, 29.72355443] },
      geometry_name: 'shape',
      properties: {
        portname: 'Apalachicola',
        code: 'USAAF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.72355,
        longitude: -84.98267,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4146148,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15749',
      geometry: { type: 'Point', coordinates: [-118.42606626, 33.91127669] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Segundo',
        code: 'USELS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.91128,
        longitude: -118.42607,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5345860,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15750',
      geometry: { type: 'Point', coordinates: [19.31628806, -2.42925862] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ngali',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.42926,
        longitude: 19.31629,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2312081,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15751',
      geometry: { type: 'Point', coordinates: [120.21227161, 11.99246008] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coron',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.99246,
        longitude: 120.21227,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1716834,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15752',
      geometry: { type: 'Point', coordinates: [124.84118907, 10.13209693] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maasin',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.1321,
        longitude: 124.84119,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1704758,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15753',
      geometry: { type: 'Point', coordinates: [-172.63129746, -13.51245329] },
      geometry_name: 'shape',
      properties: {
        portname: 'Asau',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -13.51245,
        longitude: -172.6313,
        iso3: 'WSM',
        iso3_op: 'WSM',
        country: 'Samoa',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 7106456,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15754',
      geometry: { type: 'Point', coordinates: [17.36589355, -3.30989485] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bandundu',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -3.30989,
        longitude: 17.36589,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2317397,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15755',
      geometry: { type: 'Point', coordinates: [124.47209277, 10.05920152] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ubay',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.0592,
        longitude: 124.47209,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1680475,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15756',
      geometry: { type: 'Point', coordinates: [-89.84819577, 29.60709986] },
      geometry_name: 'shape',
      properties: {
        portname: 'Davant',
        code: 'USDVT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 29.6071,
        longitude: -89.8482,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4321669,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15757',
      geometry: { type: 'Point', coordinates: [-95.2169, 29.72073387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pasadena',
        code: 'USPSD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.72073,
        longitude: -95.2169,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4717782,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15758',
      geometry: { type: 'Point', coordinates: [-93.32327329, 29.79289863] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cameron',
        code: 'USCXM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.7929,
        longitude: -93.32327,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4318695,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15759',
      geometry: { type: 'Point', coordinates: [-122.21451908, 47.99438967] },
      geometry_name: 'shape',
      properties: {
        portname: 'Everett',
        code: 'USEVT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.99439,
        longitude: -122.21452,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5793933,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15760',
      geometry: { type: 'Point', coordinates: [-77.26763558, 37.30712381] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hopewell',
        code: 'USHPW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.30712,
        longitude: -77.26764,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4764826,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15761',
      geometry: { type: 'Point', coordinates: [-122.31829433, 37.58615426] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Mateo',
        code: 'USSMK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.58615,
        longitude: -122.31829,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5392423,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15762',
      geometry: { type: 'Point', coordinates: [123.42023581, 10.47903982] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Carlos',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 10.47904,
        longitude: 123.42024,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1690219,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15763',
      geometry: { type: 'Point', coordinates: [-77.03908861, 38.80530679] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alexandria',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.80531,
        longitude: -77.03909,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4744091,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15764',
      geometry: { type: 'Point', coordinates: [127.6187245, 39.83085726] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hungnam',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.83086,
        longitude: 127.61872,
        iso3: 'PRK',
        iso3_op: 'PRK',
        country: "Dem People's Rep of Korea",
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1877030,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15765',
      geometry: { type: 'Point', coordinates: [7.12205617, 43.5874316] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antibes',
        code: 'FRANT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.58743,
        longitude: 7.12206,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3037456,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15766',
      geometry: { type: 'Point', coordinates: [11.70031751, 58.0124241] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wallhamn',
        code: 'SEWAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.01242,
        longitude: 11.70032,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3332132,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15767',
      geometry: { type: 'Point', coordinates: [23.49152976, 39.16343133] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skiathos',
        code: 'GRJSI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.16343,
        longitude: 23.49153,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 253643,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15768',
      geometry: { type: 'Point', coordinates: [-0.21832327, 39.64769545] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sagunto',
        code: 'ESSAG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.6477,
        longitude: -0.21832,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2511619,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15769',
      geometry: { type: 'Point', coordinates: [-1.57458902, 43.46665514] },
      geometry_name: 'shape',
      properties: {
        portname: 'Biarritz',
        code: 'FRBIQ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.46666,
        longitude: -1.57459,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3032797,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15770',
      geometry: { type: 'Point', coordinates: [18.18332465, 60.40559294] },
      geometry_name: 'shape',
      properties: {
        portname: 'Forsmark',
        code: 'SEFOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 60.40559,
        longitude: 18.18332,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2713942,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15771',
      geometry: { type: 'Point', coordinates: [5.01857003, 60.81044565] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mongstad',
        code: 'NOMON',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.81045,
        longitude: 5.01857,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3145514,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15772',
      geometry: { type: 'Point', coordinates: [-3.52711672, 50.46141311] },
      geometry_name: 'shape',
      properties: {
        portname: 'Torquay',
        code: 'GBTOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.46141,
        longitude: -3.52712,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2635650,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15773',
      geometry: { type: 'Point', coordinates: [20.3883, 39.28813387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Parga',
        code: 'GRPAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 39.28813,
        longitude: 20.3883,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 255736,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15774',
      geometry: { type: 'Point', coordinates: [-4.33860675, 48.09638851] },
      geometry_name: 'shape',
      properties: {
        portname: 'Douarnenez',
        code: 'FRDRZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.09639,
        longitude: -4.33861,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3020996,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15775',
      geometry: { type: 'Point', coordinates: [2.31491723, 48.8643096] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paris',
        code: 'FRPAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.86431,
        longitude: 2.31492,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2988507,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15776',
      geometry: { type: 'Point', coordinates: [-7.93552713, 37.01511248] },
      geometry_name: 'shape',
      properties: {
        portname: 'Faro',
        code: 'PTFAO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.01511,
        longitude: -7.93553,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Portugal',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2268339,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15777',
      geometry: { type: 'Point', coordinates: [-8.5303879, 37.12787279] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portimao',
        code: 'PTPRM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.12787,
        longitude: -8.53039,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Portugal',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2264456,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15778',
      geometry: { type: 'Point', coordinates: [0.11083304, 38.84352895] },
      geometry_name: 'shape',
      properties: {
        portname: 'Denia',
        code: 'ESDNA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.84353,
        longitude: 0.11083,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2518878,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15779',
      geometry: { type: 'Point', coordinates: [24.70319761, 40.85758299] },
      geometry_name: 'shape',
      properties: {
        portname: 'Keramoti',
        code: 'GRKER',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.85758,
        longitude: 24.7032,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 735821,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15780',
      geometry: { type: 'Point', coordinates: [-0.56907318, 44.84193518] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bordeaux',
        code: 'FRBOD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.84194,
        longitude: -0.56907,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3031582,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15781',
      geometry: { type: 'Point', coordinates: [9.12518772, 58.64460433] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lyngor',
        code: 'NOLNG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.6446,
        longitude: 9.12519,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3146671,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15782',
      geometry: { type: 'Point', coordinates: [14.03493836, 58.95018058] },
      geometry_name: 'shape',
      properties: {
        portname: 'Otterbacken',
        code: 'SEOTT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.95018,
        longitude: 14.03494,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2685128,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15783',
      geometry: { type: 'Point', coordinates: [17.07121514, 59.5907017] },
      geometry_name: 'shape',
      properties: {
        portname: 'Enkoping',
        code: 'SEENK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.5907,
        longitude: 17.07122,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2716166,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15784',
      geometry: { type: 'Point', coordinates: [18.72569086, 59.7548622] },
      geometry_name: 'shape',
      properties: {
        portname: 'Norrtalje',
        code: 'SENOE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.75486,
        longitude: 18.72569,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2688250,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15785',
      geometry: { type: 'Point', coordinates: [24.47932073, 0.75781345] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yangambi',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'approximate',
        latitude: 0.75781,
        longitude: 24.47932,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 203717,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15786',
      geometry: { type: 'Point', coordinates: [25.81224515, -1.39475654] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lowa',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -1.39476,
        longitude: 25.81225,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 211248,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15787',
      geometry: { type: 'Point', coordinates: [29.34147922, -5.55584285] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kabimba',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -5.55584,
        longitude: 29.34148,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 215531,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15788',
      geometry: { type: 'Point', coordinates: [28.18524452, 61.06873985] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lappeenranta',
        code: 'FILPP',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.06874,
        longitude: 28.18524,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 648900,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15789',
      geometry: { type: 'Point', coordinates: [35.65500572, 34.2805874] },
      geometry_name: 'shape',
      properties: {
        portname: 'Selaata',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.28059,
        longitude: 35.65501,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fuel Oil And Others',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 267191,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15790',
      geometry: { type: 'Point', coordinates: [53.31111, 36.84111] },
      geometry_name: 'shape',
      properties: {
        portname: 'Amirabad',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.84111,
        longitude: 53.31111,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 143604,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15791',
      geometry: { type: 'Point', coordinates: [121.64312943, -8.84538391] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ende',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.84538,
        longitude: 121.64313,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1644932,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15792',
      geometry: { type: 'Point', coordinates: [96.24981045, 16.66538854] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thilawa',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.66539,
        longitude: 96.24981,
        iso3: 'MMR',
        iso3_op: 'MMR',
        country: 'Myanmar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1291543,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15793',
      geometry: { type: 'Point', coordinates: [-72.3636479, 18.69607042] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lafiteau',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Closed',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.69607,
        longitude: -72.36365,
        iso3: null,
        iso3_op: 'HTI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3723085,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15794',
      geometry: { type: 'Point', coordinates: [-6.26519265, 13.45039888] },
      geometry_name: 'shape',
      properties: {
        portname: 'Segou',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.4504,
        longitude: -6.26519,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2451478,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15795',
      geometry: { type: 'Point', coordinates: [-5.01531753, 14.13729771] },
      geometry_name: 'shape',
      properties: {
        portname: 'Diafarabe',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.1373,
        longitude: -5.01532,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2459126,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15796',
      geometry: { type: 'Point', coordinates: [-3.39110426, 16.26279839] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dire',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.2628,
        longitude: -3.3911,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2458623,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15797',
      geometry: { type: 'Point', coordinates: [46.31543005, -15.72557062] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mahajanga',
        code: 'MGMJN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: 4.5,
        maxlength: 819,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -15.72557,
        longitude: 46.31543,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1062663,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15798',
      geometry: { type: 'Point', coordinates: [-88.40023766, 44.25639344] },
      geometry_name: 'shape',
      properties: {
        portname: 'Appleton',
        code: 'USATW',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.25639,
        longitude: -88.40024,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5244080,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15799',
      geometry: { type: 'Point', coordinates: [125.64772929, 7.09776917] },
      geometry_name: 'shape',
      properties: {
        portname: 'Davao',
        code: 'PHBIY',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 7.09777,
        longitude: 125.64773,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1715348,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15800',
      geometry: { type: 'Point', coordinates: [123.59259943, 9.24938031] },
      geometry_name: 'shape',
      properties: {
        portname: 'Larena',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.24938,
        longitude: 123.5926,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1707223,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15801',
      geometry: { type: 'Point', coordinates: [18.59279773, 63.16975243] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kopmanholmen',
        code: 'SEKMH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.16975,
        longitude: 18.5928,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2699777,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15802',
      geometry: { type: 'Point', coordinates: [48.44333638, -20.92599071] },
      geometry_name: 'shape',
      properties: {
        portname: 'Andranomahitsy',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -20.92599,
        longitude: 48.44334,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1075197,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15803',
      geometry: { type: 'Point', coordinates: [48.27802889, -21.41380563] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marohita',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -21.41381,
        longitude: 48.27803,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1060091,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15804',
      geometry: { type: 'Point', coordinates: [49.30446146, -18.32038219] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marofinaritra',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -18.32038,
        longitude: 49.30446,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1060201,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15805',
      geometry: { type: 'Point', coordinates: [48.53280195, -20.58106108] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nosy Varika',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -20.58106,
        longitude: 48.5328,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1058080,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15806',
      geometry: { type: 'Point', coordinates: [48.47793218, -20.77412153] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ambahy',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -20.77412,
        longitude: 48.47793,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1084588,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15807',
      geometry: { type: 'Point', coordinates: [33.03661272, 34.66970281] },
      geometry_name: 'shape',
      properties: {
        portname: 'Limassol',
        code: 'CYLMS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.6697,
        longitude: 33.03661,
        iso3: 'CYP',
        iso3_op: 'CYP',
        country: 'Cyprus',
        lastcheckdate: null,
        remarks: 'Lemesos',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 146384,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15808',
      geometry: { type: 'Point', coordinates: [62.33025156, 25.11313244] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gwadar',
        code: 'PKGWD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.11313,
        longitude: 62.33025,
        iso3: 'PAK',
        iso3_op: 'PAK',
        country: 'Pakistan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1177446,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15809',
      geometry: { type: 'Point', coordinates: [23.60568945, 1.22881617] },
      geometry_name: 'shape',
      properties: {
        portname: 'Basoko',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 1.22882,
        longitude: 23.60569,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 219414,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15810',
      geometry: { type: 'Point', coordinates: [14.32414384, 55.92857762] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ahus',
        code: 'SEAHU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.92858,
        longitude: 14.32414,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2727363,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15811',
      geometry: { type: 'Point', coordinates: [-79.04913457, 43.17451483] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lewiston',
        code: 'USLUT',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.17451,
        longitude: -79.04913,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5124307,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15812',
      geometry: { type: 'Point', coordinates: [-79.8289824, 43.2738778] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hamilton',
        code: 'CAHAM',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.27388,
        longitude: -79.82898,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5969782,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15813',
      geometry: { type: 'Point', coordinates: [22.97496055, -0.95584911] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yalusaka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.95585,
        longitude: 22.97496,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 203905,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15814',
      geometry: { type: 'Point', coordinates: [22.63542924, -1.82277684] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lomami',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -1.82278,
        longitude: 22.63543,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 211446,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15815',
      geometry: { type: 'Point', coordinates: [20.19136176, -0.94079061] },
      geometry_name: 'shape',
      properties: {
        portname: 'Waka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.94079,
        longitude: 20.19136,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 204348,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15816',
      geometry: { type: 'Point', coordinates: [20.21207616, 1.00412016] },
      geometry_name: 'shape',
      properties: {
        portname: 'Waka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.00412,
        longitude: 20.21208,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 204347,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15817',
      geometry: { type: 'Point', coordinates: [143.64335624, -3.57221317] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wewak',
        code: 'PGWWK',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.57221,
        longitude: 143.64336,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2083537,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15818',
      geometry: { type: 'Point', coordinates: [136.83728808, -4.82799645] },
      geometry_name: 'shape',
      properties: {
        portname: 'Amamapare',
        code: 'IDAMA',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.828,
        longitude: 136.83729,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1651588,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15819',
      geometry: { type: 'Point', coordinates: [147.37342072, -2.04131765] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lorengau',
        code: 'PGLOR',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.04132,
        longitude: 147.37342,
        iso3: 'PNG',
        iso3_op: 'PNG',
        country: 'Papua New Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2092164,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15820',
      geometry: { type: 'Point', coordinates: [-68.89987866, 44.45354414] },
      geometry_name: 'shape',
      properties: {
        portname: 'Searsport',
        code: 'USSRP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.45354,
        longitude: -68.89988,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4978097,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15821',
      geometry: { type: 'Point', coordinates: [-63.11947908, 46.23392209] },
      geometry_name: 'shape',
      properties: {
        portname: 'Charlottetown',
        code: 'CACHA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.23392,
        longitude: -63.11948,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5920288,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15822',
      geometry: { type: 'Point', coordinates: [-80.63108312, 51.27699634] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moosonee',
        code: 'CAMOU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.277,
        longitude: -80.63108,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6078371,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15823',
      geometry: { type: 'Point', coordinates: [-132.14039055, 54.00999103] },
      geometry_name: 'shape',
      properties: {
        portname: 'Masset',
        code: 'CAZMT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.00999,
        longitude: -132.14039,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6067677,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15824',
      geometry: { type: 'Point', coordinates: [-90.98393152, 30.10757776] },
      geometry_name: 'shape',
      properties: {
        portname: 'Donaldsonville',
        code: 'USDNA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.10758,
        longitude: -90.98393,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4322811,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15825',
      geometry: { type: 'Point', coordinates: [-72.52856277, 41.2691372] },
      geometry_name: 'shape',
      properties: {
        portname: 'Clinton',
        code: 'USCLB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.26914,
        longitude: -72.52856,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5284202,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15826',
      geometry: { type: 'Point', coordinates: [-70.18559892, 42.05160247] },
      geometry_name: 'shape',
      properties: {
        portname: 'Provincetown',
        code: 'USPVC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.0516,
        longitude: -70.1856,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4948087,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15827',
      geometry: { type: 'Point', coordinates: [-73.85440739, 42.21078121] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alsen',
        code: 'USAEN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 42.21078,
        longitude: -73.85441,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5107073,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15828',
      geometry: { type: 'Point', coordinates: [-74.74401459, 45.0131901] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cornwall',
        code: 'CACWL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.01319,
        longitude: -74.74401,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5928065,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15829',
      geometry: { type: 'Point', coordinates: [-122.84856142, 46.00763499] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalama',
        code: 'USKAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.00763,
        longitude: -122.84856,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5799410,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15830',
      geometry: { type: 'Point', coordinates: [-122.89909314, 47.05494822] },
      geometry_name: 'shape',
      properties: {
        portname: 'Olympia',
        code: 'USOLM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.05495,
        longitude: -122.89909,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5805687,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15831',
      geometry: { type: 'Point', coordinates: [-53.12614213, 47.38914308] },
      geometry_name: 'shape',
      properties: {
        portname: 'Holyrood',
        code: 'CAHOD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.38914,
        longitude: -53.12614,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5976393,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15832',
      geometry: { type: 'Point', coordinates: [-64.48462488, 48.82764347] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gaspe',
        code: 'CAGPE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.82764,
        longitude: -64.48462,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5959878,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15833',
      geometry: { type: 'Point', coordinates: [-95.33153595, 28.9422753] },
      geometry_name: 'shape',
      properties: {
        portname: 'Freeport',
        code: 'USFRP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.94228,
        longitude: -95.33154,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4692355,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15834',
      geometry: { type: 'Point', coordinates: [-117.1153, 32.65163387] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Diego',
        code: 'USSAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.65163,
        longitude: -117.1153,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5391811,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15835',
      geometry: { type: 'Point', coordinates: [-74.26307787, 40.50359225] },
      geometry_name: 'shape',
      properties: {
        portname: 'Perth Amboy',
        code: 'USPAY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.50359,
        longitude: -74.26308,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5102578,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15836',
      geometry: { type: 'Point', coordinates: [-70.17702459, 43.79643496] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yarmouth',
        code: 'USYAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.79643,
        longitude: -70.17702,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4983572,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15837',
      geometry: { type: 'Point', coordinates: [0.13175663, 49.47766319] },
      geometry_name: 'shape',
      properties: {
        portname: 'Le Havre',
        code: 'FRLEH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.47766,
        longitude: 0.13176,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3003796,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15838',
      geometry: { type: 'Point', coordinates: [-123.9351, 49.16603387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nanaimo',
        code: 'CANNO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.16603,
        longitude: -123.9351,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6085772,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15839',
      geometry: { type: 'Point', coordinates: [-56.06691301, 49.50043952] },
      geometry_name: 'shape',
      properties: {
        portname: 'Springdale',
        code: 'CASPD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.50044,
        longitude: -56.06691,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6154159,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15840',
      geometry: { type: 'Point', coordinates: [-149.89661931, 61.22781452] },
      geometry_name: 'shape',
      properties: {
        portname: 'Anchorage',
        code: 'USANC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.22781,
        longitude: -149.89662,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5879400,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15841',
      geometry: { type: 'Point', coordinates: [9.59994821, 54.91213959] },
      geometry_name: 'shape',
      properties: {
        portname: 'Grasten',
        code: 'DKGRA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.91214,
        longitude: 9.59995,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2621258,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15842',
      geometry: { type: 'Point', coordinates: [-2.87944772, 56.4497538] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tayport',
        code: 'GBTAY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.44975,
        longitude: -2.87945,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2636158,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15843',
      geometry: { type: 'Point', coordinates: [-2.96058698, 57.67931254] },
      geometry_name: 'shape',
      properties: {
        portname: 'Buckie',
        code: 'GBBUC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.67931,
        longitude: -2.96059,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2654411,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15844',
      geometry: { type: 'Point', coordinates: [21.31701919, 37.64933387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Katakolon',
        code: 'GRKAK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.64933,
        longitude: 21.31702,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 260698,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15845',
      geometry: { type: 'Point', coordinates: [23.56474787, 37.96340617] },
      geometry_name: 'shape',
      properties: {
        portname: 'Perama',
        code: 'GRPER',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.96341,
        longitude: 23.56475,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 255588,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15846',
      geometry: { type: 'Point', coordinates: [15.55812459, 38.1823518] },
      geometry_name: 'shape',
      properties: {
        portname: 'Messina',
        code: 'ITMSN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.18235,
        longitude: 15.55812,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2524170,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15847',
      geometry: { type: 'Point', coordinates: [10.54443785, 42.92927488] },
      geometry_name: 'shape',
      properties: {
        portname: 'Piombino',
        code: 'ITPIO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.92927,
        longitude: 10.54444,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3170674,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15848',
      geometry: { type: 'Point', coordinates: [17.71154142, 58.9734032] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oaxen',
        code: 'SEOAX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.9734,
        longitude: 17.71154,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2687540,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15849',
      geometry: { type: 'Point', coordinates: [9.41159271, 53.78444179] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gluckstadt',
        code: 'DEGLU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.78444,
        longitude: 9.41159,
        iso3: null,
        iso3_op: 'DEU',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2919763,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15850',
      geometry: { type: 'Point', coordinates: [-122.2605976, 38.08733607] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vallejo',
        code: 'USVLO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.08734,
        longitude: -122.2606,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5405380,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15851',
      geometry: { type: 'Point', coordinates: [-76.52962695, 38.13595818] },
      geometry_name: 'shape',
      properties: {
        portname: 'Piney Point',
        code: 'USPYP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.13596,
        longitude: -76.52963,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4365569,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15852',
      geometry: { type: 'Point', coordinates: [-75.56182531, 39.65790219] },
      geometry_name: 'shape',
      properties: {
        portname: 'New Castle',
        code: 'USNQS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.6579,
        longitude: -75.56183,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4143834,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15853',
      geometry: { type: 'Point', coordinates: [-75.1292, 39.92753387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Camden',
        code: 'USCDE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.92753,
        longitude: -75.1292,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4501018,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15854',
      geometry: { type: 'Point', coordinates: [-70.67014581, 41.52292081] },
      geometry_name: 'shape',
      properties: {
        portname: 'Woods Hole',
        code: 'USWOH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.52292,
        longitude: -70.67015,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4956152,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15855',
      geometry: { type: 'Point', coordinates: [7.20149697, 53.35697258] },
      geometry_name: 'shape',
      properties: {
        portname: 'Emden',
        code: 'DEEME',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.35697,
        longitude: 7.2015,
        iso3: null,
        iso3_op: 'DEU',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2930596,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15856',
      geometry: { type: 'Point', coordinates: [135.43503392, 34.56802827] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sakai',
        code: 'JPSAK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.56803,
        longitude: 135.43503,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1853195,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15857',
      geometry: { type: 'Point', coordinates: [-24.99318806, 16.8880009] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mindelo',
        code: 'CVMIN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.888,
        longitude: -24.99319,
        iso3: 'CPV',
        iso3_op: 'CPV',
        country: 'Cape Verde',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3374462,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15858',
      geometry: { type: 'Point', coordinates: [9.58987946, 59.0995261] },
      geometry_name: 'shape',
      properties: {
        portname: 'Heroya',
        code: 'NOHRY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.09953,
        longitude: 9.58988,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3152868,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15859',
      geometry: { type: 'Point', coordinates: [5.11398523, 61.93440373] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maloy',
        code: 'NOMAY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.9344,
        longitude: 5.11399,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3146487,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15860',
      geometry: { type: 'Point', coordinates: [0.56998672, 51.5110005] },
      geometry_name: 'shape',
      properties: {
        portname: 'Canvey Island',
        code: 'GBCAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.511,
        longitude: 0.56999,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 7911271,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15861',
      geometry: { type: 'Point', coordinates: [1.02576843, 51.36347797] },
      geometry_name: 'shape',
      properties: {
        portname: 'Whitstable',
        code: 'GBWTS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.36348,
        longitude: 1.02577,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2634021,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15862',
      geometry: { type: 'Point', coordinates: [-1.48452314, 43.4977276] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bayonne',
        code: 'FRBAY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.49773,
        longitude: -1.48452,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3034475,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15863',
      geometry: { type: 'Point', coordinates: [28.3073638, 44.246332] },
      geometry_name: 'shape',
      properties: {
        portname: 'Medgidia',
        code: 'ROMED',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.24633,
        longitude: 28.30736,
        iso3: 'ROU',
        iso3_op: 'ROU',
        country: 'Romania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 673636,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15864',
      geometry: { type: 'Point', coordinates: [-0.24853864, 44.91416974] },
      geometry_name: 'shape',
      properties: {
        portname: 'Libourne',
        code: 'FRLIB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.91417,
        longitude: -0.24854,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2998517,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15865',
      geometry: { type: 'Point', coordinates: [-3.83931683, 48.58758531] },
      geometry_name: 'shape',
      properties: {
        portname: 'Morlaix',
        code: 'FRMXN',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.58759,
        longitude: -3.83932,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2991772,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15866',
      geometry: { type: 'Point', coordinates: [-3.46052922, 48.73105455] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lannion',
        code: 'FRLAI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.73105,
        longitude: -3.46053,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3007609,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15867',
      geometry: { type: 'Point', coordinates: [-5.53355889, 50.11721331] },
      geometry_name: 'shape',
      properties: {
        portname: 'Penzance',
        code: 'GBPEN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.11721,
        longitude: -5.53356,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2640377,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15868',
      geometry: { type: 'Point', coordinates: [6.76336771, 51.450865] },
      geometry_name: 'shape',
      properties: {
        portname: 'Duisburg',
        code: 'DEDUI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.45086,
        longitude: 6.76337,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2934691,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15869',
      geometry: { type: 'Point', coordinates: [-4.98593272, 52.01080514] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fishguard',
        code: 'GBFIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.01081,
        longitude: -4.98593,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2649372,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15870',
      geometry: { type: 'Point', coordinates: [-4.09464356, 53.26484063] },
      geometry_name: 'shape',
      properties: {
        portname: 'Beaumaris',
        code: 'GBBMR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 53.26484,
        longitude: -4.09464,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2656073,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15871',
      geometry: { type: 'Point', coordinates: [-2.98132899, 53.35425323] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Sunlight',
        code: 'GBPSU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 53.35425,
        longitude: -2.98133,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2639990,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15872',
      geometry: { type: 'Point', coordinates: [124.76157687, 8.57646201] },
      geometry_name: 'shape',
      properties: {
        portname: 'Villanueva',
        code: 'PHVNV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.57646,
        longitude: 124.76158,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1679873,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15873',
      geometry: { type: 'Point', coordinates: [119.94751419, 15.53810731] },
      geometry_name: 'shape',
      properties: {
        portname: 'Masinloc',
        code: 'PHMSC',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.53811,
        longitude: 119.94751,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1700665,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15874',
      geometry: { type: 'Point', coordinates: [125.33637931, 8.97918735] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nasipit',
        code: 'PHNAS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.97919,
        longitude: 125.33638,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1698063,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15875',
      geometry: { type: 'Point', coordinates: [121.938062, 10.73832958] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Jose',
        code: 'PHSJI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.73833,
        longitude: 121.93806,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1689540,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15876',
      geometry: { type: 'Point', coordinates: [133.83911593, 34.34712029] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sakaide',
        code: 'JPSKD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.34712,
        longitude: 133.83912,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1853190,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15877',
      geometry: { type: 'Point', coordinates: [137.05816531, 36.80182227] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fushiki',
        code: 'JPFSK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.80182,
        longitude: 137.05817,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1863793,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15878',
      geometry: { type: 'Point', coordinates: [7.7604358, 36.89173305] },
      geometry_name: 'shape',
      properties: {
        portname: 'Annaba',
        code: 'DZAAE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.89173,
        longitude: 7.76044,
        iso3: 'DZA',
        iso3_op: 'DZA',
        country: 'Algeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2506999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15879',
      geometry: { type: 'Point', coordinates: [39.71859938, -9.99977642] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lindi',
        code: 'TZLDI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -9.99978,
        longitude: 39.7186,
        iso3: null,
        iso3_op: 'TZA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 878281,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15880',
      geometry: { type: 'Point', coordinates: [39.1087, -5.06556613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanga',
        code: 'TZTGT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.06557,
        longitude: 39.1087,
        iso3: null,
        iso3_op: 'TZA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 149606,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15881',
      geometry: { type: 'Point', coordinates: [139.71582119, 35.24715295] },
      geometry_name: 'shape',
      properties: {
        portname: 'Uraga',
        code: 'JPKRH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.24715,
        longitude: 139.71582,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1849223,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15882',
      geometry: { type: 'Point', coordinates: [141.50583866, 52.2323845] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lazarev',
        code: 'RULAZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.23238,
        longitude: 141.50584,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2123836,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15883',
      geometry: { type: 'Point', coordinates: [140.50745352, 42.98274291] },
      geometry_name: 'shape',
      properties: {
        portname: 'Iwanai',
        code: 'JPIWN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.98274,
        longitude: 140.50745,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2129868,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15884',
      geometry: { type: 'Point', coordinates: [115.66958768, -33.32228125] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bunbury',
        code: 'AUBUY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.32228,
        longitude: 115.66959,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2075432,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15885',
      geometry: { type: 'Point', coordinates: [137.59089753, -33.03952384] },
      geometry_name: 'shape',
      properties: {
        portname: 'Whyalla',
        code: 'AUWYA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.03952,
        longitude: 137.5909,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2058430,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15886',
      geometry: { type: 'Point', coordinates: [133.65455312, -32.14909247] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thevenard',
        code: 'AUTHE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.14909,
        longitude: 133.65455,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2060362,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15887',
      geometry: { type: 'Point', coordinates: [153.14146557, -30.30341398] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coffs Harbour',
        code: 'AUCFS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -30.30341,
        longitude: 153.14147,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2171085,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15888',
      geometry: { type: 'Point', coordinates: [-48.70915626, -25.42867066] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antonina',
        code: 'BRANT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -25.42867,
        longitude: -48.70916,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3472048,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15889',
      geometry: { type: 'Point', coordinates: [118.58395583, -20.31921688] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Hedland',
        code: 'AUPHE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.31922,
        longitude: 118.58396,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2063042,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15890',
      geometry: { type: 'Point', coordinates: [-35.72040282, -9.67491847] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maceio',
        code: 'BRMCZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -9.67492,
        longitude: -35.7204,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3395981,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15891',
      geometry: { type: 'Point', coordinates: [179.19569907, -8.50274227] },
      geometry_name: 'shape',
      properties: {
        portname: 'Funafuti',
        code: 'TVFUN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.50274,
        longitude: 179.1957,
        iso3: null,
        iso3_op: 'TUV',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2110394,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15892',
      geometry: { type: 'Point', coordinates: [111.16914283, 1.35465872] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lingga',
        code: 'MYLGG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.35466,
        longitude: 111.16914,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1735756,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15893',
      geometry: { type: 'Point', coordinates: [114.32406453, 4.61937054] },
      geometry_name: 'shape',
      properties: {
        portname: 'Seria',
        code: 'BNSER',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 4.61937,
        longitude: 114.32406,
        iso3: 'BRN',
        iso3_op: 'BRN',
        country: 'Brunei Darussalam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1820187,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15894',
      geometry: { type: 'Point', coordinates: [120.90876225, 14.48319067] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cavite',
        code: 'PHCVE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.48319,
        longitude: 120.90876,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1717641,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15895',
      geometry: { type: 'Point', coordinates: [133.24485346, 35.53122722] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sakaiminato',
        code: 'JPSMN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.53123,
        longitude: 133.24485,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1853174,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15896',
      geometry: { type: 'Point', coordinates: [32.9851395, 41.89864109] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cide',
        code: 'TRCID',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.89864,
        longitude: 32.98514,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 749183,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15897',
      geometry: { type: 'Point', coordinates: [145.5870803, 43.34216535] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nemuro',
        code: 'JPNEM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.34217,
        longitude: 145.58708,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2128975,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15898',
      geometry: { type: 'Point', coordinates: [141.64048643, 43.94501786] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rumoi',
        code: 'JPRMI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.94502,
        longitude: 141.64049,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2128382,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15899',
      geometry: { type: 'Point', coordinates: [29.78624265, 59.97977668] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kronshtadt',
        code: 'RUKDT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.97978,
        longitude: 29.78624,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 540771,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15900',
      geometry: { type: 'Point', coordinates: [28.62218484, 60.35814724] },
      geometry_name: 'shape',
      properties: {
        portname: 'Primorsk',
        code: 'RUPRI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.35815,
        longitude: 28.62218,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 505269,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15901',
      geometry: { type: 'Point', coordinates: [179.3667616, 63.06420002] },
      geometry_name: 'shape',
      properties: {
        portname: 'Beringovskiy',
        code: 'RUBER',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.0642,
        longitude: 179.36676,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2126710,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15902',
      geometry: { type: 'Point', coordinates: [147.1233032, -41.42772097] },
      geometry_name: 'shape',
      properties: {
        portname: 'Launceston',
        code: 'AULST',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.42772,
        longitude: 147.1233,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2160517,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15903',
      geometry: { type: 'Point', coordinates: [146.16923092, -41.15592151] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ulverstone',
        code: 'AUULV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.15592,
        longitude: 146.16923,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2145532,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15904',
      geometry: { type: 'Point', coordinates: [-73.42828231, -39.88714778] },
      geometry_name: 'shape',
      properties: {
        portname: 'Corral',
        code: 'CLCRR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -39.88715,
        longitude: -73.42828,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3893516,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15905',
      geometry: { type: 'Point', coordinates: [141.6156964, -38.35444408] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portland',
        code: 'AUPTJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -38.35444,
        longitude: 141.6157,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2152667,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15906',
      geometry: { type: 'Point', coordinates: [144.91076497, -37.84196509] },
      geometry_name: 'shape',
      properties: {
        portname: 'Melbourne',
        code: 'AUMEL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -37.84197,
        longitude: 144.91076,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2158177,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15907',
      geometry: { type: 'Point', coordinates: [-58.53529999, -34.44358358] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Fernando',
        code: 'ARSFE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.44358,
        longitude: -58.5353,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3429095,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15908',
      geometry: { type: 'Point', coordinates: [-51.21868579, -30.02035891] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porto Alegre',
        code: 'BRPOA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -30.02036,
        longitude: -51.21869,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3452925,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15909',
      geometry: { type: 'Point', coordinates: [113.65441741, -24.89315564] },
      geometry_name: 'shape',
      properties: {
        portname: 'Carnarvon',
        code: 'AUCVQ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -24.89316,
        longitude: 113.65442,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2074865,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15910',
      geometry: { type: 'Point', coordinates: [165.72176657, -21.30202301] },
      geometry_name: 'shape',
      properties: {
        portname: 'Poro',
        code: 'NCPOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -21.30202,
        longitude: 165.72177,
        iso3: null,
        iso3_op: 'NCL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2138671,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15911',
      geometry: { type: 'Point', coordinates: [149.17796639, -21.14417925] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mackay',
        code: 'AUMKY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -21.14418,
        longitude: 149.17797,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2159220,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15912',
      geometry: { type: 'Point', coordinates: [102.91689833, 1.82598374] },
      geometry_name: 'shape',
      properties: {
        portname: 'Batu Pahat',
        code: 'MYBAT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.82598,
        longitude: 102.9169,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1732687,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15913',
      geometry: { type: 'Point', coordinates: [102.56613596, 2.04754256] },
      geometry_name: 'shape',
      properties: {
        portname: 'Muar',
        code: 'MYMUA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.04754,
        longitude: 102.56614,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1732869,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15914',
      geometry: { type: 'Point', coordinates: [-55.09152361, 5.61685502] },
      geometry_name: 'shape',
      properties: {
        portname: 'Smalkalden',
        code: 'SRSMA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.61686,
        longitude: -55.09152,
        iso3: 'SUR',
        iso3_op: 'SUR',
        country: 'Suriname',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3383043,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15915',
      geometry: { type: 'Point', coordinates: [-64.59331244, 10.24474229] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guanta',
        code: 'VEGUT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.24474,
        longitude: -64.59331,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3640179,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15916',
      geometry: { type: 'Point', coordinates: [124.23370047, 8.2309091] },
      geometry_name: 'shape',
      properties: {
        portname: 'Iligan',
        code: 'PHIGN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.23091,
        longitude: 124.2337,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1711084,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15917',
      geometry: { type: 'Point', coordinates: [72.83170105, 20.41275997] },
      geometry_name: 'shape',
      properties: {
        portname: 'Daman',
        code: 'INDAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.41276,
        longitude: 72.8317,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1273618,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15918',
      geometry: { type: 'Point', coordinates: [131.20111331, 34.39104139] },
      geometry_name: 'shape',
      properties: {
        portname: 'Senzaki',
        code: 'JPSZK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.39104,
        longitude: 131.20111,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1852699,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15919',
      geometry: { type: 'Point', coordinates: [139.7830788, 35.52390537] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kawasaki',
        code: 'JPKWS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.52391,
        longitude: 139.78308,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1859642,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15920',
      geometry: { type: 'Point', coordinates: [140.86813989, 35.73690627] },
      geometry_name: 'shape',
      properties: {
        portname: 'Choshi',
        code: 'JPCHO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.73691,
        longitude: 140.86814,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2112996,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15921',
      geometry: { type: 'Point', coordinates: [124.38709472, 40.11619198] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dandong',
        code: 'CNDDG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.11619,
        longitude: 124.38709,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2037886,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15922',
      geometry: { type: 'Point', coordinates: [141.88937377, 39.26738769] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kamaishi',
        code: 'JPKIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.26739,
        longitude: 141.88937,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2112444,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15923',
      geometry: { type: 'Point', coordinates: [119.57693948, 39.91046727] },
      geometry_name: 'shape',
      properties: {
        portname: 'Qinhuangdao',
        code: 'CNSHP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.91047,
        longitude: 119.57694,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1797595,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15924',
      geometry: { type: 'Point', coordinates: [142.91346828, 42.12791527] },
      geometry_name: 'shape',
      properties: {
        portname: 'Samani',
        code: 'JPSAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.12792,
        longitude: 142.91347,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2128333,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15925',
      geometry: { type: 'Point', coordinates: [143.63940421, 42.67610535] },
      geometry_name: 'shape',
      properties: {
        portname: 'Otsu',
        code: 'JPOTU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.67611,
        longitude: 143.6394,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2128543,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15926',
      geometry: { type: 'Point', coordinates: [134.70029488, 33.93299334] },
      geometry_name: 'shape',
      properties: {
        portname: 'Anan',
        code: 'JPANA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.93299,
        longitude: 134.70029,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1865309,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15927',
      geometry: { type: 'Point', coordinates: [149.90924923, -37.07122984] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eden',
        code: 'AUQDN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -37.07123,
        longitude: 149.90925,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2167799,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15928',
      geometry: { type: 'Point', coordinates: [115.76066441, -32.09836613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fremantle',
        code: 'AUFRE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.09837,
        longitude: 115.76066,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2071223,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15929',
      geometry: { type: 'Point', coordinates: [-41.70842465, -2.87860426] },
      geometry_name: 'shape',
      properties: {
        portname: 'Parnaiba',
        code: 'BRPHB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -2.8786,
        longitude: -41.70842,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3393001,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15930',
      geometry: { type: 'Point', coordinates: [114.197723, 4.57511584] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kuala Belait',
        code: 'BNKUB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.57512,
        longitude: 114.19772,
        iso3: 'BRN',
        iso3_op: 'BRN',
        country: 'Brunei Darussalam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1820491,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15931',
      geometry: { type: 'Point', coordinates: [115.06535543, 5.01518828] },
      geometry_name: 'shape',
      properties: {
        portname: 'Muara',
        code: 'BNMUA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.01519,
        longitude: 115.06536,
        iso3: 'BRN',
        iso3_op: 'BRN',
        country: 'Brunei Darussalam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1820363,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15932',
      geometry: { type: 'Point', coordinates: [103.15095444, 5.33304042] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kuala Terengganu',
        code: 'MYTGG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.33304,
        longitude: 103.15095,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1734705,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15933',
      geometry: { type: 'Point', coordinates: [123.63807314, 10.38674482] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sangi',
        code: 'PHSNG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.38674,
        longitude: 123.63807,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1689887,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15934',
      geometry: { type: 'Point', coordinates: [108.62149053, 19.09783032] },
      geometry_name: 'shape',
      properties: {
        portname: 'Basuo',
        code: 'CNBAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.09783,
        longitude: 108.62149,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1816858,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15935',
      geometry: { type: 'Point', coordinates: [85.82542449, 19.79512479] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puri',
        code: 'INPUR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 19.79512,
        longitude: 85.82542,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1259184,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15936',
      geometry: { type: 'Point', coordinates: [132.87167833, 34.05470675] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kikuma',
        code: 'JPKIK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.05471,
        longitude: 132.87168,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1859484,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15937',
      geometry: { type: 'Point', coordinates: [76.31908726, 9.49208987] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alleppey',
        code: 'INALF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.49209,
        longitude: 76.31909,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1278985,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15938',
      geometry: { type: 'Point', coordinates: [137.3191202, 34.72385365] },
      geometry_name: 'shape',
      properties: {
        portname: 'Toyohashi',
        code: 'JPTHS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.72385,
        longitude: 137.31912,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1849846,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15939',
      geometry: { type: 'Point', coordinates: [125.69892385, 37.99530951] },
      geometry_name: 'shape',
      properties: {
        portname: 'Haeju',
        code: 'KPHAE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.99531,
        longitude: 125.69892,
        iso3: 'PRK',
        iso3_op: 'PRK',
        country: "Dem People's Rep of Korea",
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1877615,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15940',
      geometry: { type: 'Point', coordinates: [141.03206412, 38.31964904] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shiogama',
        code: 'JPSGM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.31965,
        longitude: 141.03206,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2111049,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15941',
      geometry: { type: 'Point', coordinates: [139.81801842, 38.92698438] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sakata',
        code: 'JPSKT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.92698,
        longitude: 139.81802,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1853140,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15942',
      geometry: { type: 'Point', coordinates: [141.72319442, 39.06737579] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ofunato',
        code: 'JPOFT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.06738,
        longitude: 141.72319,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2111530,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15943',
      geometry: { type: 'Point', coordinates: [140.96625998, 42.3328739] },
      geometry_name: 'shape',
      properties: {
        portname: 'Muroran',
        code: 'JPMUR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.33287,
        longitude: 140.96626,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2129101,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15944',
      geometry: { type: 'Point', coordinates: [140.27207285, 49.08931978] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vanino',
        code: 'RUVNN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.08932,
        longitude: 140.27207,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2119932,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15945',
      geometry: { type: 'Point', coordinates: [38.09484226, 63.90276892] },
      geometry_name: 'shape',
      properties: {
        portname: 'Onega',
        code: 'RUONG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.90277,
        longitude: 38.09484,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 515246,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15946',
      geometry: { type: 'Point', coordinates: [118.35295789, 31.33411227] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wuhu',
        code: 'CNWHI',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.33411,
        longitude: 118.35296,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1791236,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15947',
      geometry: { type: 'Point', coordinates: [118.72817132, 32.08986669] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nanjing',
        code: 'CNNKG',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.08987,
        longitude: 118.72817,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1799962,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15948',
      geometry: { type: 'Point', coordinates: [-59.25968736, -29.1233992] },
      geometry_name: 'shape',
      properties: {
        portname: 'Goya',
        code: 'AROYA',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -29.1234,
        longitude: -59.25969,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3433715,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15949',
      geometry: { type: 'Point', coordinates: [-64.18837581, 10.47462792] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Sucre',
        code: 'VECUM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.47463,
        longitude: -64.18838,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3629639,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15950',
      geometry: { type: 'Point', coordinates: [-83.02553553, 9.99063711] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Limon',
        code: 'CRLIO',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.99064,
        longitude: -83.02554,
        iso3: null,
        iso3_op: 'CRI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3622247,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15951',
      geometry: { type: 'Point', coordinates: [-79.56073049, 8.95775196] },
      geometry_name: 'shape',
      properties: {
        portname: 'Balboa',
        code: 'PABLB',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.95775,
        longitude: -79.56073,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3714426,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15952',
      geometry: { type: 'Point', coordinates: [108.57072612, -6.7090737] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cirebon',
        code: 'IDCBN',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.70907,
        longitude: 108.57073,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1646170,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15953',
      geometry: { type: 'Point', coordinates: [114.55509759, -3.33321291] },
      geometry_name: 'shape',
      properties: {
        portname: 'Banjarmasin',
        code: 'IDBDJ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.33321,
        longitude: 114.5551,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1650213,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15954',
      geometry: { type: 'Point', coordinates: [-77.143809, -12.0487322] },
      geometry_name: 'shape',
      properties: {
        portname: 'Callao',
        code: 'PECLL',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.04873,
        longitude: -77.14381,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3946083,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15955',
      geometry: { type: 'Point', coordinates: [28.92918709, 40.35468382] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mudanya',
        code: 'TRMUD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.35468,
        longitude: 28.92919,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 741487,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15956',
      geometry: { type: 'Point', coordinates: [27.30776903, 40.404527] },
      geometry_name: 'shape',
      properties: {
        portname: 'Karabiga',
        code: 'TRKRB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.40453,
        longitude: 27.30777,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 744574,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15957',
      geometry: { type: 'Point', coordinates: [35.14904433, 42.02469402] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sinop',
        code: 'TRSIC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.02469,
        longitude: 35.14904,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 739600,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15958',
      geometry: { type: 'Point', coordinates: [-72.95443029, -41.4814988] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Montt',
        code: 'CLPMC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.4815,
        longitude: -72.95443,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3874960,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15959',
      geometry: { type: 'Point', coordinates: [-73.13443927, -36.72597116] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Vicente',
        code: 'CLSVE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -36.72597,
        longitude: -73.13444,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3871285,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15960',
      geometry: { type: 'Point', coordinates: [-57.52323559, 6.25055065] },
      geometry_name: 'shape',
      properties: {
        portname: 'New Amsterdam',
        code: 'GYQSK',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.25055,
        longitude: -57.52324,
        iso3: 'GUY',
        iso3_op: 'GUY',
        country: 'Guyana',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3376762,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15961',
      geometry: { type: 'Point', coordinates: [-89.69839302, 21.28052676] },
      geometry_name: 'shape',
      properties: {
        portname: 'Progreso',
        code: 'MXPGO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.28053,
        longitude: -89.69839,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3521108,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15962',
      geometry: { type: 'Point', coordinates: [-87.84274913, 13.3403911] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Union',
        code: 'SVLUN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.34039,
        longitude: -87.84275,
        iso3: null,
        iso3_op: 'SLV',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3584772,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15963',
      geometry: { type: 'Point', coordinates: [-105.29255719, 21.54348689] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Blas',
        code: 'MXSBS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.54349,
        longitude: -105.29256,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3987544,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15964',
      geometry: { type: 'Point', coordinates: [29.1054291, 36.6217892] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fethiye',
        code: 'TRFET',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.62179,
        longitude: 29.10543,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 314967,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15965',
      geometry: { type: 'Point', coordinates: [40.51256217, 41.03432447] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rize',
        code: 'TRRIZ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.03432,
        longitude: 40.51256,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 740483,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15966',
      geometry: { type: 'Point', coordinates: [80.03650597, 9.81756328] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kankesanturai',
        code: 'LKKNK',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.81756,
        longitude: 80.03651,
        iso3: null,
        iso3_op: 'LKA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1241587,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15967',
      geometry: { type: 'Point', coordinates: [-58.85245661, -27.49502249] },
      geometry_name: 'shape',
      properties: {
        portname: 'Corrientes',
        code: 'ARCNQ',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -27.49502,
        longitude: -58.85246,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3435217,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15968',
      geometry: { type: 'Point', coordinates: [-61.62730034, 10.24143522] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Brea',
        code: 'TTLAB',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.24144,
        longitude: -61.6273,
        iso3: 'TTO',
        iso3_op: 'TTO',
        country: 'Trinidad and Tobago',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3574353,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15969',
      geometry: { type: 'Point', coordinates: [-87.42632029, 13.40241] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Lorenzo',
        code: 'HNSLO',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.40241,
        longitude: -87.42632,
        iso3: 'HND',
        iso3_op: 'HND',
        country: 'Honduras',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3601977,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15970',
      geometry: { type: 'Point', coordinates: [-2.41208489, 53.43711204] },
      geometry_name: 'shape',
      properties: {
        portname: 'Carrington',
        code: 'GBCRT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.43711,
        longitude: -2.41208,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2653673,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15971',
      geometry: { type: 'Point', coordinates: [-67.15612635, 18.43499026] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aguadilla',
        code: 'PRBQN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.43499,
        longitude: -67.15613,
        iso3: null,
        iso3_op: 'PRI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4562506,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15972',
      geometry: { type: 'Point', coordinates: [-82.12353854, 8.95155392] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chiriqui Grande',
        code: 'PACHG',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.95155,
        longitude: -82.12354,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3712401,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15973',
      geometry: { type: 'Point', coordinates: [32.56591586, 29.94282621] },
      geometry_name: 'shape',
      properties: {
        portname: 'Suez',
        code: 'EGSUZ',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.94283,
        longitude: 32.56592,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 359796,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15974',
      geometry: { type: 'Point', coordinates: [100.92318686, 12.62932768] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sattahip',
        code: 'THSAT',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.62933,
        longitude: 100.92319,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1606386,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15975',
      geometry: { type: 'Point', coordinates: [-171.75885695, -13.82715859] },
      geometry_name: 'shape',
      properties: {
        portname: 'Apia',
        code: 'WSAPW',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -13.82716,
        longitude: -171.75886,
        iso3: 'WSM',
        iso3_op: 'WSM',
        country: 'Samoa',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4035413,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15976',
      geometry: { type: 'Point', coordinates: [45.25864185, -12.78426436] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dzaoudzi',
        code: 'KMDZA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.78426,
        longitude: 45.25864,
        iso3: 'MYT',
        iso3_op: 'MYT',
        country: 'Mayotte',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 921900,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15977',
      geometry: { type: 'Point', coordinates: [-72.19540608, 19.75913927] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cap-Haitien',
        code: 'HTCAP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.75914,
        longitude: -72.19541,
        iso3: 'HTI',
        iso3_op: 'HTI',
        country: 'Haiti',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3728474,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15978',
      geometry: { type: 'Point', coordinates: [30.07086464, 31.32275175] },
      geometry_name: 'shape',
      properties: {
        portname: 'Abu Qir',
        code: 'EGAKI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.32275,
        longitude: 30.07086,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 362288,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15980',
      geometry: { type: 'Point', coordinates: [48.1714861, 30.42876555] },
      geometry_name: 'shape',
      properties: {
        portname: 'Khorramshahr',
        code: 'IRKHO',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.42877,
        longitude: 48.17149,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 127319,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15981',
      geometry: { type: 'Point', coordinates: [-2.24487075, 4.87328156] },
      geometry_name: 'shape',
      properties: {
        portname: 'Axim',
        code: 'GHAXI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.87328,
        longitude: -2.24487,
        iso3: 'GHA',
        iso3_op: 'GHA',
        country: 'Ghana',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2303611,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15982',
      geometry: { type: 'Point', coordinates: [102.25083423, -3.78501446] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bengkulu',
        code: 'IDBKS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.78501,
        longitude: 102.25083,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1649150,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15983',
      geometry: { type: 'Point', coordinates: [119.40315005, -5.13222282] },
      geometry_name: 'shape',
      properties: {
        portname: 'Makassar',
        code: 'IDUPG',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.13222,
        longitude: 119.40315,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1622786,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15984',
      geometry: { type: 'Point', coordinates: [117.66646602, 4.14480001] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nunukan',
        code: 'IDNNX',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.1448,
        longitude: 117.66647,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1633811,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15985',
      geometry: { type: 'Point', coordinates: [116.81594465, -1.25527372] },
      geometry_name: 'shape',
      properties: {
        portname: 'Balikpapan',
        code: 'IDBPN',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.25527,
        longitude: 116.81594,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1650527,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15986',
      geometry: { type: 'Point', coordinates: [108.96726272, 0.91919235] },
      geometry_name: 'shape',
      properties: {
        portname: 'Singkawang',
        code: 'IDSKW',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.91919,
        longitude: 108.96726,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1626916,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15987',
      geometry: { type: 'Point', coordinates: [-79.88674384, -2.23236523] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guayaquil',
        code: 'ECGYE',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.23237,
        longitude: -79.88674,
        iso3: 'ECU',
        iso3_op: 'ECU',
        country: 'Ecuador',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3657509,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15988',
      geometry: { type: 'Point', coordinates: [-75.74249003, 20.82411498] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antilla',
        code: 'CUANT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.82411,
        longitude: -75.74249,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3568726,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15989',
      geometry: { type: 'Point', coordinates: [-74.50094486, 20.35127223] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baracoa',
        code: 'CUBCA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.35127,
        longitude: -74.50094,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3567834,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15990',
      geometry: { type: 'Point', coordinates: [-77.58693854, 20.04993739] },
      geometry_name: 'shape',
      properties: {
        portname: 'Niquero',
        code: 'CUNIQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.04994,
        longitude: -77.58694,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3545981,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15991',
      geometry: { type: 'Point', coordinates: [-79.57142568, -7.39845051] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pacasmayo',
        code: 'PEPAC',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.39845,
        longitude: -79.57143,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3694178,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15992',
      geometry: { type: 'Point', coordinates: [53.36563328, 26.81366956] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lavan',
        code: 'IRLVP',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.81367,
        longitude: 53.36563,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 125794,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15993',
      geometry: { type: 'Point', coordinates: [-76.32835746, 17.88748441] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Morant',
        code: 'JMPMO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.88748,
        longitude: -76.32836,
        iso3: 'JAM',
        iso3_op: 'JAM',
        country: 'Jamaica',
        lastcheckdate: '2019-05-22T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3488982,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15994',
      geometry: { type: 'Point', coordinates: [-77.16457938, 17.83538914] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salt River',
        code: 'JMSRI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 17.83539,
        longitude: -77.16458,
        iso3: 'JAM',
        iso3_op: 'JAM',
        country: 'Jamaica',
        lastcheckdate: '2019-05-22T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3488657,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15995',
      geometry: { type: 'Point', coordinates: [-77.84723779, 18.0230553] },
      geometry_name: 'shape',
      properties: {
        portname: 'Black River',
        code: 'JMBLR',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.02306,
        longitude: -77.84724,
        iso3: 'JAM',
        iso3_op: 'JAM',
        country: 'Jamaica',
        lastcheckdate: '2019-05-22T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3491355,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15996',
      geometry: { type: 'Point', coordinates: [107.02393764, 10.59835191] },
      geometry_name: 'shape',
      properties: {
        portname: 'Phu My',
        code: 'VNPHU',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.59835,
        longitude: 107.02394,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1570357,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15997',
      geometry: { type: 'Point', coordinates: [-12.50185039, 7.53061089] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bonthe',
        code: 'SLBTE',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 7.53061,
        longitude: -12.50185,
        iso3: 'SLE',
        iso3_op: 'SLE',
        country: 'Sierra Leone',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2409914,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15998',
      geometry: { type: 'Point', coordinates: [103.62216418, -1.58304206] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jambi',
        code: 'IDDJB',
        prttype: 'River',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.58304,
        longitude: 103.62216,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1642858,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.15999',
      geometry: { type: 'Point', coordinates: [-5.71453253, -15.91854233] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jamestown',
        code: 'SHSHN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -15.91854,
        longitude: -5.71453,
        iso3: 'SHN',
        iso3_op: 'SHN',
        country: 'Saint Helena',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3370903,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16000',
      geometry: { type: 'Point', coordinates: [-0.30294559, 53.74154117] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hull',
        code: 'GBHUL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.74154,
        longitude: -0.30295,
        iso3: null,
        iso3_op: 'GBR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2645425,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16001',
      geometry: { type: 'Point', coordinates: [11.44952239, 53.89833134] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wismar',
        code: 'DEWIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.89833,
        longitude: 11.44952,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2807465,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16002',
      geometry: { type: 'Point', coordinates: [-6.13294615, 54.03460356] },
      geometry_name: 'shape',
      properties: {
        portname: 'Greenore',
        code: 'IEGRN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.0346,
        longitude: -6.13295,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2963857,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16003',
      geometry: { type: 'Point', coordinates: [-6.33747934, 54.16806821] },
      geometry_name: 'shape',
      properties: {
        portname: 'Newry',
        code: 'GBNRY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.16807,
        longitude: -6.33748,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2641581,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16004',
      geometry: { type: 'Point', coordinates: [-4.4374061, 54.86826987] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wigtown',
        code: 'GBWIG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.86827,
        longitude: -4.43741,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2633932,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16005',
      geometry: { type: 'Point', coordinates: [-3.83673729, 54.89259291] },
      geometry_name: 'shape',
      properties: {
        portname: 'Palnackie',
        code: 'GBPAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 54.89259,
        longitude: -3.83674,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2640666,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16006',
      geometry: { type: 'Point', coordinates: [10.37942722, 55.40853818] },
      geometry_name: 'shape',
      properties: {
        portname: 'Odense',
        code: 'DKODE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.40854,
        longitude: 10.37943,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2615876,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16007',
      geometry: { type: 'Point', coordinates: [13.44391195, 59.32532297] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skoghall',
        code: 'SESKO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.32532,
        longitude: 13.44391,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2677456,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16008',
      geometry: { type: 'Point', coordinates: [8.60090043, 56.49319083] },
      geometry_name: 'shape',
      properties: {
        portname: 'Struer',
        code: 'DKSTR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.49319,
        longitude: 8.6009,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2612204,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16009',
      geometry: { type: 'Point', coordinates: [-6.19557092, 57.41271136] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portree',
        code: 'GBPRT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.41271,
        longitude: -6.19557,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2640006,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16010',
      geometry: { type: 'Point', coordinates: [9.6417408, 59.16948867] },
      geometry_name: 'shape',
      properties: {
        portname: 'Menstad',
        code: 'NOMEN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.16949,
        longitude: 9.64174,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3146027,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16011',
      geometry: { type: 'Point', coordinates: [10.59292255, 59.73766883] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fagerstrand',
        code: 'NOFAX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 59.73767,
        longitude: 10.59292,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3157889,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16012',
      geometry: { type: 'Point', coordinates: [6.54979742, 60.0675296] },
      geometry_name: 'shape',
      properties: {
        portname: 'Odda',
        code: 'NOODD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.06753,
        longitude: 6.5498,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3143830,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16013',
      geometry: { type: 'Point', coordinates: [24.92281349, 60.15620954] },
      geometry_name: 'shape',
      properties: {
        portname: 'Helsinki',
        code: 'FIHEL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.15621,
        longitude: 24.92281,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 658225,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16014',
      geometry: { type: 'Point', coordinates: [6.14619317, 60.37214409] },
      geometry_name: 'shape',
      properties: {
        portname: 'Norheimsund',
        code: 'NONHS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.37214,
        longitude: 6.14619,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3144110,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16015',
      geometry: { type: 'Point', coordinates: [26.01235292, 60.39241216] },
      geometry_name: 'shape',
      properties: {
        portname: 'Isnas',
        code: 'FIISN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.39241,
        longitude: 26.01235,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 656578,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16016',
      geometry: { type: 'Point', coordinates: [-22.08535915, 64.31437766] },
      geometry_name: 'shape',
      properties: {
        portname: 'Akranes',
        code: 'ISAKR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.31438,
        longitude: -22.08536,
        iso3: null,
        iso3_op: 'ISL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3418653,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16017',
      geometry: { type: 'Point', coordinates: [24.14258352, 65.8333468] },
      geometry_name: 'shape',
      properties: {
        portname: 'Haparanda',
        code: 'SEHAA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.83335,
        longitude: 24.14258,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 605859,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16018',
      geometry: { type: 'Point', coordinates: [-86.65366474, 46.41271746] },
      geometry_name: 'shape',
      properties: {
        portname: 'Munising',
        code: 'USMUI',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.41272,
        longitude: -86.65366,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5003065,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16019',
      geometry: { type: 'Point', coordinates: [35.4069, 33.64933387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ej Jiye',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.64933,
        longitude: 35.4069,
        iso3: 'LBN',
        iso3_op: 'LBN',
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: 'Fuel Oil And Others',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 279865,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16020',
      geometry: { type: 'Point', coordinates: [150.45180925, -10.30948737] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alotau',
        code: 'PGGUR',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -10.30949,
        longitude: 150.45181,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2133990,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16021',
      geometry: { type: 'Point', coordinates: [159.22153388, -9.07573473] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yandina',
        code: 'SBXYA',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -9.07573,
        longitude: 159.22153,
        iso3: 'SLB',
        iso3_op: 'SLB',
        country: 'Solomon islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2101593,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16022',
      geometry: { type: 'Point', coordinates: [-82.55535042, 41.39185067] },
      geometry_name: 'shape',
      properties: {
        portname: 'Huron',
        code: 'USHUB',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.39185,
        longitude: -82.55535,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5158298,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16023',
      geometry: { type: 'Point', coordinates: [-88.44958378, 44.19882017] },
      geometry_name: 'shape',
      properties: {
        portname: 'Menasha',
        code: 'USMEZ',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.19882,
        longitude: -88.44958,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5262596,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16024',
      geometry: { type: 'Point', coordinates: [35.72977288, 34.36019549] },
      geometry_name: 'shape',
      properties: {
        portname: 'Enfe',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.3602,
        longitude: 35.72977,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 278928,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16025',
      geometry: { type: 'Point', coordinates: [-96.62395648, 28.62210378] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Lavaca',
        code: 'USPLV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.6221,
        longitude: -96.62396,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4720067,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16026',
      geometry: { type: 'Point', coordinates: [-89.99976074, 29.23863208] },
      geometry_name: 'shape',
      properties: {
        portname: 'Grand Isle',
        code: 'USGIJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.23863,
        longitude: -89.99976,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4326198,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16027',
      geometry: { type: 'Point', coordinates: [-89.09492424, 30.36185912] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gulfport',
        code: 'USGPT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.36186,
        longitude: -89.09492,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4428667,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16028',
      geometry: { type: 'Point', coordinates: [-122.29777568, 37.77528034] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alameda',
        code: 'USNGZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.77528,
        longitude: -122.29778,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5322737,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16029',
      geometry: { type: 'Point', coordinates: [-8.25262156, 51.8290646] },
      geometry_name: 'shape',
      properties: {
        portname: 'Whitegate',
        code: 'IEWHI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.82906,
        longitude: -8.25262,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2960952,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16030',
      geometry: { type: 'Point', coordinates: [19.0231551, -3.29781628] },
      geometry_name: 'shape',
      properties: {
        portname: 'Buna',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -3.29782,
        longitude: 19.02316,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2316237,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16031',
      geometry: { type: 'Point', coordinates: [131.18664949, 33.61524131] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nakatsu',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.61524,
        longitude: 131.18665,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1855757,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16032',
      geometry: { type: 'Point', coordinates: [10.2985, 36.81413387] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Goulette',
        code: 'TNLGN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.81413,
        longitude: 10.2985,
        iso3: 'TUN',
        iso3_op: 'TUN',
        country: 'Tunisia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2470656,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16033',
      geometry: { type: 'Point', coordinates: [-122.58196577, 47.85664464] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Gamble',
        code: 'USPGH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.85664,
        longitude: -122.58197,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5807223,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16034',
      geometry: { type: 'Point', coordinates: [-122.68827338, 48.2213725] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coupeville',
        code: 'USCBL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.22137,
        longitude: -122.68827,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5791132,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16035',
      geometry: { type: 'Point', coordinates: [130.29042478, 31.26791652] },
      geometry_name: 'shape',
      properties: {
        portname: 'Makurazaki',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.26792,
        longitude: 130.29042,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1857712,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16036',
      geometry: { type: 'Point', coordinates: [-80.11840707, 26.08434128] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Everglades',
        code: 'USPEF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.08434,
        longitude: -80.11841,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4169139,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16037',
      geometry: { type: 'Point', coordinates: [-82.5448, 27.33473387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sarasota',
        code: 'USSRB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.33473,
        longitude: -82.5448,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4172131,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16038',
      geometry: { type: 'Point', coordinates: [-76.85642466, 37.92985844] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tappahannock',
        code: 'USTKV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.92986,
        longitude: -76.85642,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4788822,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16039',
      geometry: { type: 'Point', coordinates: [32.65396009, 0.2896701] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Bell',
        code: null,
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.28967,
        longitude: 32.65396,
        iso3: 'UGA',
        iso3_op: 'UGA',
        country: 'Uganda',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 226744,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16040',
      geometry: { type: 'Point', coordinates: [120.48451686, 18.01785365] },
      geometry_name: 'shape',
      properties: {
        portname: 'Currimao',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.01785,
        longitude: 120.48452,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1716446,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16041',
      geometry: { type: 'Point', coordinates: [123.04057656, 13.50529104] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pasacao',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.50529,
        longitude: 123.04058,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1694631,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16042',
      geometry: { type: 'Point', coordinates: [-127.49212003, 50.71538247] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Hardy',
        code: 'CAPHY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.71538,
        longitude: -127.49212,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6111862,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16043',
      geometry: { type: 'Point', coordinates: [32.67837122, 13.16677419] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kosti',
        code: null,
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.16677,
        longitude: 32.67837,
        iso3: 'SDN',
        iso3_op: 'SDN',
        country: 'Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 371760,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16044',
      geometry: { type: 'Point', coordinates: [-6.36548749, 57.59143386] },
      geometry_name: 'shape',
      properties: {
        portname: 'Uig',
        code: 'GBUIG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 57.59143,
        longitude: -6.36549,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2635219,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16045',
      geometry: { type: 'Point', coordinates: [-7.15761143, 57.59658294] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lochmaddy',
        code: 'GBLMA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.59658,
        longitude: -7.15761,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2643811,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16046',
      geometry: { type: 'Point', coordinates: [14.16912082, 57.7842582] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jonkoping',
        code: 'SEJKG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.78426,
        longitude: 14.16912,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2702979,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16047',
      geometry: { type: 'Point', coordinates: [25.86268833, 36.82737324] },
      geometry_name: 'shape',
      properties: {
        portname: 'Amorgos',
        code: 'GRAMO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.82737,
        longitude: 25.86269,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 265142,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16048',
      geometry: { type: 'Point', coordinates: [27.28984136, 36.89698005] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kos',
        code: 'GRKGS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.89698,
        longitude: 27.28984,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 259245,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16049',
      geometry: { type: 'Point', coordinates: [-0.15237376, 38.99447855] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gandia',
        code: 'ESGAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.99448,
        longitude: -0.15237,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2517367,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16050',
      geometry: { type: 'Point', coordinates: [14.02580204, 40.76567932] },
      geometry_name: 'shape',
      properties: {
        portname: 'Procida',
        code: 'ITPRO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.76568,
        longitude: 14.0258,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3169807,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16051',
      geometry: { type: 'Point', coordinates: [12.62998732, 41.44556729] },
      geometry_name: 'shape',
      properties: {
        portname: 'Anzio',
        code: 'ITANZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.44557,
        longitude: 12.62999,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3183005,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16052',
      geometry: { type: 'Point', coordinates: [10.24283581, 43.86161561] },
      geometry_name: 'shape',
      properties: {
        portname: 'Viareggio',
        code: 'ITVIA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.86162,
        longitude: 10.24284,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3164433,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16053',
      geometry: { type: 'Point', coordinates: [-2.47881302, 36.83675257] },
      geometry_name: 'shape',
      properties: {
        portname: 'Almeria',
        code: 'ESLEI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.83675,
        longitude: -2.47881,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2521886,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16054',
      geometry: { type: 'Point', coordinates: [11.78923234, 42.08987633] },
      geometry_name: 'shape',
      properties: {
        portname: 'Civitavecchia',
        code: 'ITCVV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.08988,
        longitude: 11.78923,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3178587,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16055',
      geometry: { type: 'Point', coordinates: [7.58633533, 47.56192243] },
      geometry_name: 'shape',
      properties: {
        portname: 'Basel',
        code: 'CHBSL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.56192,
        longitude: 7.58634,
        iso3: 'CHE',
        iso3_op: 'CHE',
        country: 'Switzerland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2661604,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16056',
      geometry: { type: 'Point', coordinates: [16.9529, -5.06526613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kapanga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -5.06527,
        longitude: 16.9529,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2315076,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16057',
      geometry: { type: 'Point', coordinates: [18.35392852, 1.38115143] },
      geometry_name: 'shape',
      properties: {
        portname: 'Libanda',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.38115,
        longitude: 18.35393,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2313769,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16058',
      geometry: { type: 'Point', coordinates: [35.68633583, -18.02958904] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mopeia',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -18.02959,
        longitude: 35.68634,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Mopeia:- (I) -No Vessel Restriction- (Ii) -Truck Access- (Iii) -No Network Coverage- (Iv) -No Hazard To Navigation--Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1037108,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16059',
      geometry: { type: 'Point', coordinates: [2.27757184, 48.94108284] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gennevilliers',
        code: 'FRGVL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.94108,
        longitude: 2.27757,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3016321,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16060',
      geometry: { type: 'Point', coordinates: [-25.67238203, 37.73590726] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ponta Delgada',
        code: 'PTPDL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.73591,
        longitude: -25.67238,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Azores Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3372783,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16061',
      geometry: { type: 'Point', coordinates: [-28.62685343, 38.52806507] },
      geometry_name: 'shape',
      properties: {
        portname: 'Horta',
        code: 'PTHOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.52807,
        longitude: -28.62685,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Azores Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3372988,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16062',
      geometry: { type: 'Point', coordinates: [17.19430455, -3.95358435] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fayala',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -3.95358,
        longitude: 17.1943,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2315823,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16063',
      geometry: { type: 'Point', coordinates: [21.49258564, 65.32176743] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pitea',
        code: 'SEPIT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.32177,
        longitude: 21.49259,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 603570,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16064',
      geometry: { type: 'Point', coordinates: [20.27637778, 63.80441165] },
      geometry_name: 'shape',
      properties: {
        portname: 'Umea',
        code: 'SEUME',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.80441,
        longitude: 20.27638,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 602150,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16066',
      geometry: { type: 'Point', coordinates: [19.59381596, -3.41060419] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bindja',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.4106,
        longitude: 19.59382,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2317089,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16067',
      geometry: { type: 'Point', coordinates: [49.28512916, -12.27508709] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antsiranana',
        code: 'MGDIE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: 8.5,
        maxlength: 414,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.27509,
        longitude: 49.28513,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1069129,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16068',
      geometry: { type: 'Point', coordinates: [-64.93266809, 47.79160591] },
      geometry_name: 'shape',
      properties: {
        portname: 'Caraquet',
        code: 'CACAQ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 47.79161,
        longitude: -64.93267,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6942803,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16069',
      geometry: { type: 'Point', coordinates: [123.30495214, 9.52955571] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tandayag',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 9.52956,
        longitude: 123.30495,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1683293,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16070',
      geometry: { type: 'Point', coordinates: [121.92822294, 13.99993677] },
      geometry_name: 'shape',
      properties: {
        portname: 'Atimonan',
        code: 'PHATI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.99994,
        longitude: 121.92822,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1729987,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16071',
      geometry: { type: 'Point', coordinates: [27.02991822, -7.21298384] },
      geometry_name: 'shape',
      properties: {
        portname: 'Muyumba',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -7.21298,
        longitude: 27.02992,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 207742,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16072',
      geometry: { type: 'Point', coordinates: [26.91013486, -6.0500157] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kabalo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -6.05002,
        longitude: 26.91013,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 215668,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16073',
      geometry: { type: 'Point', coordinates: [109.21552204, 12.20648091] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nha Trang',
        code: 'VNNHA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.20648,
        longitude: 109.21552,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1572151,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16074',
      geometry: { type: 'Point', coordinates: [49.32012845, -18.40220408] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ankarefo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -18.4022,
        longitude: 49.32013,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1073058,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16075',
      geometry: { type: 'Point', coordinates: [95.29462177, 5.56424018] },
      geometry_name: 'shape',
      properties: {
        portname: 'Uleelheue',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.56424,
        longitude: 95.29462,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1213389,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16076',
      geometry: { type: 'Point', coordinates: [32.4072971, 34.75588668] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paphos',
        code: 'CYPFO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.75589,
        longitude: 32.4073,
        iso3: null,
        iso3_op: 'CYP',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 146214,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16077',
      geometry: { type: 'Point', coordinates: [124.08598564, 12.58498691] },
      geometry_name: 'shape',
      properties: {
        portname: 'Matnog',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 12.58499,
        longitude: 124.08599,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1700269,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16078',
      geometry: { type: 'Point', coordinates: [-64.17119055, 45.06865885] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hantsport',
        code: 'CAHPT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.06866,
        longitude: -64.17119,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5970414,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16079',
      geometry: { type: 'Point', coordinates: [-73.11835486, 46.04715653] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sorel',
        code: 'CASOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.04716,
        longitude: -73.11835,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6151350,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16080',
      geometry: { type: 'Point', coordinates: [21.95636436, 1.24193808] },
      geometry_name: 'shape',
      properties: {
        portname: 'Likulu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 1.24194,
        longitude: 21.95636,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 211836,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16081',
      geometry: { type: 'Point', coordinates: [-88.02625141, 44.49840672] },
      geometry_name: 'shape',
      properties: {
        portname: 'Green Bay',
        code: 'USGRB',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.49841,
        longitude: -88.02625,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5254962,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16082',
      geometry: { type: 'Point', coordinates: [-85.76176384, 45.02408059] },
      geometry_name: 'shape',
      properties: {
        portname: 'Leland',
        code: 'USLLD',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.02408,
        longitude: -85.76176,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4999064,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16083',
      geometry: { type: 'Point', coordinates: [-85.25786268, 45.31709156] },
      geometry_name: 'shape',
      properties: {
        portname: 'Charlevoix',
        code: 'USCSX',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.31709,
        longitude: -85.25786,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4988575,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16084',
      geometry: { type: 'Point', coordinates: [-78.82674679, 43.86579074] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oshawa',
        code: 'CAQWA',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.86579,
        longitude: -78.82675,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6094578,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16085',
      geometry: { type: 'Point', coordinates: [21.04674249, -2.05597197] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ikali',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -2.05597,
        longitude: 21.04674,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 216044,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16086',
      geometry: { type: 'Point', coordinates: [24.18408788, -1.60324689] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moma',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -1.60325,
        longitude: 24.18409,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 208855,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16087',
      geometry: { type: 'Point', coordinates: [20.95538139, -0.60779431] },
      geometry_name: 'shape',
      properties: {
        portname: 'Inkaka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -0.60779,
        longitude: 20.95538,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 215864,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16088',
      geometry: { type: 'Point', coordinates: [20.20854373, -0.2138353] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bokote',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.21384,
        longitude: 20.20854,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 218501,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16089',
      geometry: { type: 'Point', coordinates: [18.87906137, 0.3782701] },
      geometry_name: 'shape',
      properties: {
        portname: 'Boyenge',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.37827,
        longitude: 18.87906,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2316339,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16090',
      geometry: { type: 'Point', coordinates: [20.75493587, 2.82819228] },
      geometry_name: 'shape',
      properties: {
        portname: 'Likimi',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.82819,
        longitude: 20.75494,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 211862,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16091',
      geometry: { type: 'Point', coordinates: [25.51594696, -0.79451487] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kirundu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.79451,
        longitude: 25.51595,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 212758,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16092',
      geometry: { type: 'Point', coordinates: [19.74321044, -0.24487754] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lisafa',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -0.24488,
        longitude: 19.74321,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2313693,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16093',
      geometry: { type: 'Point', coordinates: [-7.62093883, 52.08631856] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dungarvan',
        code: 'IEDGV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.08632,
        longitude: -7.62094,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2964528,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16094',
      geometry: { type: 'Point', coordinates: [-71.04967994, 42.35911476] },
      geometry_name: 'shape',
      properties: {
        portname: 'Boston',
        code: 'USBOS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.35911,
        longitude: -71.04968,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4930956,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16095',
      geometry: { type: 'Point', coordinates: [-67.59791525, 44.53048126] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jonesport',
        code: 'USJON',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.53048,
        longitude: -67.59792,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4968499,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16096',
      geometry: { type: 'Point', coordinates: [-63.78886162, 46.38935643] },
      geometry_name: 'shape',
      properties: {
        portname: 'Summerside',
        code: 'CASUM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.38936,
        longitude: -63.78886,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6159244,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16097',
      geometry: { type: 'Point', coordinates: [-53.99274455, 47.28977843] },
      geometry_name: 'shape',
      properties: {
        portname: 'Argentia',
        code: 'CANWP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.28978,
        longitude: -53.99274,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 7732472,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16098',
      geometry: { type: 'Point', coordinates: [-57.94163413, 48.95883529] },
      geometry_name: 'shape',
      properties: {
        portname: 'Corner Brook',
        code: 'CACBK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.95884,
        longitude: -57.94163,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5927969,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16099',
      geometry: { type: 'Point', coordinates: [-130.28512261, 54.33247915] },
      geometry_name: 'shape',
      properties: {
        portname: 'Prince Rupert',
        code: 'CAPRR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.33248,
        longitude: -130.28512,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6113406,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16100',
      geometry: { type: 'Point', coordinates: [-164.43501503, 64.54579628] },
      geometry_name: 'shape',
      properties: {
        portname: 'Solomon',
        code: 'USSLK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.5458,
        longitude: -164.43502,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5874691,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16101',
      geometry: { type: 'Point', coordinates: [-90.42516846, 29.99858758] },
      geometry_name: 'shape',
      properties: {
        portname: 'Taft',
        code: 'USTFT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 29.99859,
        longitude: -90.42517,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4343084,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16102',
      geometry: { type: 'Point', coordinates: [-90.8340617, 30.03850675] },
      geometry_name: 'shape',
      properties: {
        portname: 'Convent',
        code: 'USCNV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.03851,
        longitude: -90.83406,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4320643,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16103',
      geometry: { type: 'Point', coordinates: [-90.15300271, 30.40024306] },
      geometry_name: 'shape',
      properties: {
        portname: 'Madisonville',
        code: 'USMVQ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.40024,
        longitude: -90.153,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4332261,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16104',
      geometry: { type: 'Point', coordinates: [-75.26790984, 39.84462017] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paulsboro',
        code: 'USPAU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.84462,
        longitude: -75.26791,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4503516,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16105',
      geometry: { type: 'Point', coordinates: [-72.09257109, 41.35927591] },
      geometry_name: 'shape',
      properties: {
        portname: 'New London',
        code: 'USNLD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.35928,
        longitude: -72.09257,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4839416,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16106',
      geometry: { type: 'Point', coordinates: [-70.60104465, 41.45540015] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vineyard Haven',
        code: 'USVYH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.4554,
        longitude: -70.60104,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4953938,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16107',
      geometry: { type: 'Point', coordinates: [-70.72422812, 42.19520076] },
      geometry_name: 'shape',
      properties: {
        portname: 'Scituate',
        code: 'USSCU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.1952,
        longitude: -70.72423,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4950395,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16108',
      geometry: { type: 'Point', coordinates: [-73.75507764, 42.62997946] },
      geometry_name: 'shape',
      properties: {
        portname: 'Albany',
        code: 'USALB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.62998,
        longitude: -73.75508,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5106834,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16109',
      geometry: { type: 'Point', coordinates: [-73.69663218, 42.7254621] },
      geometry_name: 'shape',
      properties: {
        portname: 'Troy',
        code: 'USTRY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.72546,
        longitude: -73.69663,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5141502,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16110',
      geometry: { type: 'Point', coordinates: [-64.50473254, 44.37145674] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bridgewater',
        code: 'CABRW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.37146,
        longitude: -64.50473,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5908723,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16111',
      geometry: { type: 'Point', coordinates: [-123.09157878, 47.20565585] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shelton',
        code: 'USSQR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.20566,
        longitude: -123.09158,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5810176,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16112',
      geometry: { type: 'Point', coordinates: [-80.32206599, 27.44912421] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fort Pierce',
        code: 'USFPR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.44912,
        longitude: -80.32207,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4156018,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16113',
      geometry: { type: 'Point', coordinates: [-94.89584186, 29.37641008] },
      geometry_name: 'shape',
      properties: {
        portname: 'Texas City',
        code: 'USTXT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.37641,
        longitude: -94.89584,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4736134,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16114',
      geometry: { type: 'Point', coordinates: [-89.94355397, 29.8694526] },
      geometry_name: 'shape',
      properties: {
        portname: 'Braithwaite',
        code: 'USBWT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.86945,
        longitude: -89.94355,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4317764,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16115',
      geometry: { type: 'Point', coordinates: [-84.67263254, 29.85252687] },
      geometry_name: 'shape',
      properties: {
        portname: 'Carrabelle',
        code: 'USCRB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.85253,
        longitude: -84.67263,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4150103,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16116',
      geometry: { type: 'Point', coordinates: [-91.18857918, 30.46127346] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baton Rouge',
        code: 'USBTR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.46127,
        longitude: -91.18858,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4315588,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16117',
      geometry: { type: 'Point', coordinates: [-71.39181886, 41.79828157] },
      geometry_name: 'shape',
      properties: {
        portname: 'Providence',
        code: 'USPVD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.79828,
        longitude: -71.39182,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5224151,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16118',
      geometry: { type: 'Point', coordinates: [-70.75824764, 43.07927244] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portsmouth',
        code: 'USPSM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.07927,
        longitude: -70.75825,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5091383,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16119',
      geometry: { type: 'Point', coordinates: [5.50105837, 5.35299128] },
      geometry_name: 'shape',
      properties: {
        portname: 'Burutu',
        code: 'NGBUR',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.35299,
        longitude: 5.50106,
        iso3: 'NGA',
        iso3_op: '',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2346317,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16120',
      geometry: { type: 'Point', coordinates: [-122.49020689, 48.75327906] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bellingham',
        code: 'USBLI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.75328,
        longitude: -122.49021,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5786899,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16121',
      geometry: { type: 'Point', coordinates: [-56.85596588, 49.54357737] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hampden',
        code: 'CAHMP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.54358,
        longitude: -56.85597,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5969990,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16122',
      geometry: { type: 'Point', coordinates: [-56.17899343, 49.94340693] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baie Verte',
        code: 'CABVE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.94341,
        longitude: -56.17899,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5891545,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16123',
      geometry: { type: 'Point', coordinates: [-131.21796722, 52.30178053] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jedway',
        code: 'CAJED',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.30178,
        longitude: -131.21797,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5986080,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16124',
      geometry: { type: 'Point', coordinates: [-135.32349578, 59.44983622] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skagway',
        code: 'USSGY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.44984,
        longitude: -135.3235,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5557317,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16125',
      geometry: { type: 'Point', coordinates: [-148.68473543, 60.77646447] },
      geometry_name: 'shape',
      properties: {
        portname: 'Whittier',
        code: 'USWQF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.77646,
        longitude: -148.68474,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5878018,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16126',
      geometry: { type: 'Point', coordinates: [-117.8996855, 33.60264397] },
      geometry_name: 'shape',
      properties: {
        portname: 'Newport Beach',
        code: 'USJNP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.60264,
        longitude: -117.89969,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5376890,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16127',
      geometry: { type: 'Point', coordinates: [-2.95490236, 55.97213511] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cockenzie',
        code: 'GBCNZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.97214,
        longitude: -2.9549,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2652681,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16128',
      geometry: { type: 'Point', coordinates: [-5.77491855, 56.53884404] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lochaline',
        code: 'GBLOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.53884,
        longitude: -5.77492,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2643855,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16129',
      geometry: { type: 'Point', coordinates: [23.24927903, 37.38543558] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ermioni',
        code: 'GRERM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.38544,
        longitude: 23.24928,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 262607,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16130',
      geometry: { type: 'Point', coordinates: [-0.48775668, 38.33734095] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alicante',
        code: 'ESALC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.33734,
        longitude: -0.48776,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2521978,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16131',
      geometry: { type: 'Point', coordinates: [-0.13236815, 38.53467532] },
      geometry_name: 'shape',
      properties: {
        portname: 'Benidorm',
        code: 'ESBEM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.53468,
        longitude: -0.13237,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2521088,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16132',
      geometry: { type: 'Point', coordinates: [-6.13466364, 53.29532643] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dun Laoghaire',
        code: 'IEDLG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.29533,
        longitude: -6.13466,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2964506,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16133',
      geometry: { type: 'Point', coordinates: [8.69594445, 56.95323092] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thisted',
        code: 'DKTED',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.95323,
        longitude: 8.69594,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2611755,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16134',
      geometry: { type: 'Point', coordinates: [5.3148335, 59.28418438] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kopervik',
        code: 'NOKOP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.28418,
        longitude: 5.31483,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3149512,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16135',
      geometry: { type: 'Point', coordinates: [-80.09391616, 42.13361872] },
      geometry_name: 'shape',
      properties: {
        portname: 'Erie',
        code: 'USERI',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.13362,
        longitude: -80.09392,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5188843,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16136',
      geometry: { type: 'Point', coordinates: [-69.05309459, 48.74034123] },
      geometry_name: 'shape',
      properties: {
        portname: 'Forestville',
        code: 'CAFRV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.74034,
        longitude: -69.05309,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6944112,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16137',
      geometry: { type: 'Point', coordinates: [16.86864886, -29.25647765] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Nolloth',
        code: 'ZANOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -29.25648,
        longitude: 16.86865,
        iso3: 'ZAF',
        iso3_op: 'ZAF',
        country: 'South Africa',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3362713,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16138',
      geometry: { type: 'Point', coordinates: [-0.30926944, 35.85318414] },
      geometry_name: 'shape',
      properties: {
        portname: 'Arzew',
        code: 'DZAZW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.85318,
        longitude: -0.30927,
        iso3: 'DZA',
        iso3_op: 'DZA',
        country: 'Algeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2506385,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16139',
      geometry: { type: 'Point', coordinates: [135.34622221, 34.72673387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nishinomiya',
        code: 'JPNYA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.72673,
        longitude: 135.34622,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1855207,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16140',
      geometry: { type: 'Point', coordinates: [29.09163865, 41.13452512] },
      geometry_name: 'shape',
      properties: {
        portname: 'Beykoz',
        code: 'TRBEY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.13453,
        longitude: 29.09164,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 750662,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16141',
      geometry: { type: 'Point', coordinates: [79.91473896, 8.97575623] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mannar',
        code: 'LKMAN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.97576,
        longitude: 79.91474,
        iso3: 'LKA',
        iso3_op: 'LKA',
        country: 'Sri Lanka',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1236150,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16142',
      geometry: { type: 'Point', coordinates: [10.66997965, 59.32829635] },
      geometry_name: 'shape',
      properties: {
        portname: 'Larkollen',
        code: 'NOLRK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.3283,
        longitude: 10.66998,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3148138,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16143',
      geometry: { type: 'Point', coordinates: [-3.56552701, 54.64928675] },
      geometry_name: 'shape',
      properties: {
        portname: 'Workington',
        code: 'GBWOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.64929,
        longitude: -3.56553,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2633553,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16144',
      geometry: { type: 'Point', coordinates: [14.04983314, 45.0400824] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rasa',
        code: 'HRRAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.04008,
        longitude: 14.04983,
        iso3: 'HRV',
        iso3_op: 'HRV',
        country: 'Croatia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3191963,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16145',
      geometry: { type: 'Point', coordinates: [1.02511506, 51.80545637] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brightlingsea',
        code: 'GBBLS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.80546,
        longitude: 1.02512,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2654711,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16146',
      geometry: { type: 'Point', coordinates: [4.41252673, 51.90460787] },
      geometry_name: 'shape',
      properties: {
        portname: 'Schiedam',
        code: 'NLSCI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.90461,
        longitude: 4.41253,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2747596,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16147',
      geometry: { type: 'Point', coordinates: [-0.01734342, 52.96805794] },
      geometry_name: 'shape',
      properties: {
        portname: 'Boston',
        code: 'GBBOS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.96806,
        longitude: -0.01734,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2655138,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16148',
      geometry: { type: 'Point', coordinates: [7.37204616, 53.0940202] },
      geometry_name: 'shape',
      properties: {
        portname: 'Papenburg',
        code: 'DEPAP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.09402,
        longitude: 7.37205,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2855525,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16149',
      geometry: { type: 'Point', coordinates: [-2.9532704, 53.32117257] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eastham',
        code: 'GBEAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.32117,
        longitude: -2.95327,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2650429,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16150',
      geometry: { type: 'Point', coordinates: [-0.77643061, 53.39547796] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gainsborough',
        code: 'GBGAI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.39548,
        longitude: -0.77643,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2648945,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16151',
      geometry: { type: 'Point', coordinates: [-7.59609071, 33.6029696] },
      geometry_name: 'shape',
      properties: {
        portname: 'Casablanca',
        code: 'MACAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.60297,
        longitude: -7.59609,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2553604,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16152',
      geometry: { type: 'Point', coordinates: [132.24187492, 34.17367365] },
      geometry_name: 'shape',
      properties: {
        portname: 'Iwakuni',
        code: 'JPIWK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.17367,
        longitude: 132.24187,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1861212,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16153',
      geometry: { type: 'Point', coordinates: [32.04994172, -28.78947243] },
      geometry_name: 'shape',
      properties: {
        portname: 'Richards Bay',
        code: 'ZARCB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -28.78947,
        longitude: 32.04994,
        iso3: 'ZAF',
        iso3_op: 'ZAF',
        country: 'South Africa',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 962367,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16154',
      geometry: { type: 'Point', coordinates: [12.1931851, -5.54985621] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cabinda',
        code: 'AOCAB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.54986,
        longitude: 12.19319,
        iso3: 'AGO',
        iso3_op: 'AGO',
        country: 'Angola',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2243271,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16155',
      geometry: { type: 'Point', coordinates: [149.31609951, -9.08040062] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tufi',
        code: 'PGTFI',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -9.0804,
        longitude: 149.3161,
        iso3: 'PNG',
        iso3_op: 'PNG',
        country: 'Papua New Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2085070,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16156',
      geometry: { type: 'Point', coordinates: [147.04798271, -7.05588313] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salamaua',
        code: 'PGSMU',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.05588,
        longitude: 147.04798,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2087367,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16157',
      geometry: { type: 'Point', coordinates: [-69.21818445, -51.61277624] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rio Gallegos',
        code: 'ARRGL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -51.61278,
        longitude: -69.21818,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3838859,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16158',
      geometry: { type: 'Point', coordinates: [168.33784529, -46.41514668] },
      geometry_name: 'shape',
      properties: {
        portname: 'Invercargill',
        code: 'NZIVC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -46.41515,
        longitude: 168.33785,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2189529,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16159',
      geometry: { type: 'Point', coordinates: [145.90797393, -41.0545955] },
      geometry_name: 'shape',
      properties: {
        portname: 'Burnie',
        code: 'AUBWT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.0546,
        longitude: 145.90797,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2173125,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16160',
      geometry: { type: 'Point', coordinates: [145.38086983, -40.85343256] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Latta',
        code: 'AUPLA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -40.85343,
        longitude: 145.38087,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2152662,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16161',
      geometry: { type: 'Point', coordinates: [138.86093979, 35.08305198] },
      geometry_name: 'shape',
      properties: {
        portname: 'Numazu',
        code: 'JPNUM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.08305,
        longitude: 138.86094,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1854902,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16162',
      geometry: { type: 'Point', coordinates: [-34.83496116, -6.97756943] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cabedelo',
        code: 'BRCDO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.97757,
        longitude: -34.83496,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3404558,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16163',
      geometry: { type: 'Point', coordinates: [118.34969124, 5.02030015] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lahad Datu',
        code: 'MYLDU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.0203,
        longitude: 118.34969,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1733953,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16164',
      geometry: { type: 'Point', coordinates: [115.54320806, 5.06726143] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sipitang',
        code: 'MYSPT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.06726,
        longitude: 115.54321,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1733868,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16165',
      geometry: { type: 'Point', coordinates: [120.01966109, 11.89469571] },
      geometry_name: 'shape',
      properties: {
        portname: 'Culion',
        code: 'PHCUJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.8947,
        longitude: 120.01966,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1716526,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16166',
      geometry: { type: 'Point', coordinates: [120.82804867, 31.99964822] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nantong',
        code: 'CNNTG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.99965,
        longitude: 120.82805,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1799722,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16167',
      geometry: { type: 'Point', coordinates: [132.49198369, 33.6169185] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nagahama',
        code: 'JPNGH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 33.61692,
        longitude: 132.49198,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1926080,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16168',
      geometry: { type: 'Point', coordinates: [33.64092009, 34.92301568] },
      geometry_name: 'shape',
      properties: {
        portname: 'Larnaca',
        code: 'CYLCA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.92302,
        longitude: 33.64092,
        iso3: 'CYP',
        iso3_op: 'CYP',
        country: 'Cyprus',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 146400,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16169',
      geometry: { type: 'Point', coordinates: [33.93945638, 35.13086743] },
      geometry_name: 'shape',
      properties: {
        portname: 'Famagusta',
        code: 'CYFMG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.13087,
        longitude: 33.93946,
        iso3: 'CYP',
        iso3_op: 'CYP',
        country: 'Cyprus',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 146617,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16170',
      geometry: { type: 'Point', coordinates: [142.0304712, 49.06014811] },
      geometry_name: 'shape',
      properties: {
        portname: 'Uglegorsk',
        code: 'RUUGL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.06015,
        longitude: 142.03047,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2120261,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16171',
      geometry: { type: 'Point', coordinates: [39.80987661, 64.57941574] },
      geometry_name: 'shape',
      properties: {
        portname: 'Severodvinsk',
        code: 'RUSEW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.57942,
        longitude: 39.80988,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 496285,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16172',
      geometry: { type: 'Point', coordinates: [140.0684825, 39.73988319] },
      geometry_name: 'shape',
      properties: {
        portname: 'Akita',
        code: 'JPAXT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.73988,
        longitude: 140.06848,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2113126,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16173',
      geometry: { type: 'Point', coordinates: [164.99384548, -21.33264926] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nepoui',
        code: 'NCNEP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -21.33265,
        longitude: 164.99385,
        iso3: null,
        iso3_op: 'NCL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2139729,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16174',
      geometry: { type: 'Point', coordinates: [132.6979781, 32.92134033] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sukumo',
        code: 'JPSUK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.92134,
        longitude: 132.69798,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1851462,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16175',
      geometry: { type: 'Point', coordinates: [132.91720924, 34.33261979] },
      geometry_name: 'shape',
      properties: {
        portname: 'Takehara',
        code: 'JPTHR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.33262,
        longitude: 132.91721,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1850860,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16176',
      geometry: { type: 'Point', coordinates: [30.70299276, 36.88332272] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antalya',
        code: 'TRAYT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.88332,
        longitude: 30.70299,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 323777,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16177',
      geometry: { type: 'Point', coordinates: [175.00067438, -39.94638541] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wanganui',
        code: 'NZWAG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -39.94639,
        longitude: 175.00067,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2179670,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16178',
      geometry: { type: 'Point', coordinates: [131.23691801, 33.93671232] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ube',
        code: 'JPUBJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.93671,
        longitude: 131.23692,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1849498,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16179',
      geometry: { type: 'Point', coordinates: [141.87794144, -12.62068772] },
      geometry_name: 'shape',
      properties: {
        portname: 'Weipa',
        code: 'AUWEI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.62069,
        longitude: 141.87794,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2144209,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16180',
      geometry: { type: 'Point', coordinates: [-35.21680216, -5.78921979] },
      geometry_name: 'shape',
      properties: {
        portname: 'Natal',
        code: 'BRNAT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.78922,
        longitude: -35.2168,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3394023,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16181',
      geometry: { type: 'Point', coordinates: [123.31025696, 9.31297129] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dumaguete',
        code: 'PHDGT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.31297,
        longitude: 123.31026,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1714201,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16182',
      geometry: { type: 'Point', coordinates: [-68.12281966, 10.48825929] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Palito',
        code: 'VEELP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.48826,
        longitude: -68.12282,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3481687,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16183',
      geometry: { type: 'Point', coordinates: [73.62238005, 15.85652383] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vengurla',
        code: 'INVEN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.85652,
        longitude: 73.62238,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1253257,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16184',
      geometry: { type: 'Point', coordinates: [118.06713525, 24.4604175] },
      geometry_name: 'shape',
      properties: {
        portname: 'Xiamen',
        code: 'CNXMN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.46042,
        longitude: 118.06714,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1790645,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16185',
      geometry: { type: 'Point', coordinates: [32.26888439, 30.58671815] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ismailia',
        code: 'EGISM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.58672,
        longitude: 32.26888,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 361055,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16186',
      geometry: { type: 'Point', coordinates: [136.74269031, 34.52116861] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ise',
        code: 'JPISE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.52117,
        longitude: 136.74269,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1861450,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16187',
      geometry: { type: 'Point', coordinates: [135.39778793, 34.70339574] },
      geometry_name: 'shape',
      properties: {
        portname: 'Amagasaki',
        code: 'JPAMA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.7034,
        longitude: 135.39779,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1865387,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16188',
      geometry: { type: 'Point', coordinates: [26.36011788, 40.18429029] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eceabat',
        code: 'TRECE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.18429,
        longitude: 26.36012,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 747728,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16189',
      geometry: { type: 'Point', coordinates: [70.36948814, 20.90134161] },
      geometry_name: 'shape',
      properties: {
        portname: 'Veraval',
        code: 'INVVA',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.90134,
        longitude: 70.36949,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1253237,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16190',
      geometry: { type: 'Point', coordinates: [72.63587036, 21.07929381] },
      geometry_name: 'shape',
      properties: {
        portname: 'Surat',
        code: 'INSTV',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.07929,
        longitude: 72.63587,
        iso3: null,
        iso3_op: 'IND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1255364,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16191',
      geometry: { type: 'Point', coordinates: [29.18963671, 40.88714811] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kartal',
        code: 'TRKAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.88715,
        longitude: 29.18964,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 743932,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16192',
      geometry: { type: 'Point', coordinates: [32.38736101, 41.74557863] },
      geometry_name: 'shape',
      properties: {
        portname: 'Amasra',
        code: 'TRAMA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.74558,
        longitude: 32.38736,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 752016,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16193',
      geometry: { type: 'Point', coordinates: [28.74862038, 60.69542171] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vyborg',
        code: 'RUVYG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.69542,
        longitude: 28.74862,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 470546,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16194',
      geometry: { type: 'Point', coordinates: [-58.50382312, -33.01582425] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gualeguaychu',
        code: 'ARGHU',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.01582,
        longitude: -58.50382,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3433658,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16195',
      geometry: { type: 'Point', coordinates: [121.49627271, 31.22847051] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shanghai',
        code: 'CNSHA',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.22847,
        longitude: 121.49627,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1796236,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16196',
      geometry: { type: 'Point', coordinates: [111.33819171, 23.46946419] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wuzhou',
        code: 'CNWUZ',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.46946,
        longitude: 111.33819,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1790840,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16197',
      geometry: { type: 'Point', coordinates: [86.55844214, 67.45789797] },
      geometry_name: 'shape',
      properties: {
        portname: 'Igarka',
        code: 'RUIAA',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 67.4579,
        longitude: 86.55844,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1505991,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16198',
      geometry: { type: 'Point', coordinates: [-71.33541133, -29.95781497] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coquimbo',
        code: 'CLCQQ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -29.95781,
        longitude: -71.33541,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3893629,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16199',
      geometry: { type: 'Point', coordinates: [-79.12116624, -8.07881842] },
      geometry_name: 'shape',
      properties: {
        portname: 'Trujillo',
        code: 'PETRU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.07882,
        longitude: -79.12117,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3691175,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16200',
      geometry: { type: 'Point', coordinates: [-80.65405504, -3.66759431] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zorritos',
        code: 'PEZOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.66759,
        longitude: -80.65406,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3690608,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16201',
      geometry: { type: 'Point', coordinates: [-116.63307066, 31.85752569] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ensenada',
        code: 'MXESE',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.85753,
        longitude: -116.63307,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4006702,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16202',
      geometry: { type: 'Point', coordinates: [29.15260653, 40.4226925] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gemlik',
        code: 'TRGEM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.42269,
        longitude: 29.15261,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 746958,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16203',
      geometry: { type: 'Point', coordinates: [-71.24253005, -28.47165496] },
      geometry_name: 'shape',
      properties: {
        portname: 'Huasco',
        code: 'CLHSO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -28.47165,
        longitude: -71.24253,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3887758,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16204',
      geometry: { type: 'Point', coordinates: [-76.8242, 17.98213387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kingston',
        code: 'JMKIN',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.98213,
        longitude: -76.8242,
        iso3: 'JAM',
        iso3_op: 'JAM',
        country: 'Jamaica',
        lastcheckdate: '2019-05-22T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3489854,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16205',
      geometry: { type: 'Point', coordinates: [49.74048814, -15.43883922] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maroantsetra',
        code: 'MGWMN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: 2,
        maxlength: 190,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -15.43884,
        longitude: 49.74049,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1060283,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16206',
      geometry: { type: 'Point', coordinates: [-64.06821077, 10.89539648] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Guamache',
        code: 'VEEGU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.8954,
        longitude: -64.06821,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3480941,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16207',
      geometry: { type: 'Point', coordinates: [-92.65444861, 18.53127257] },
      geometry_name: 'shape',
      properties: {
        portname: 'Frontera',
        code: 'MXFRN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.53127,
        longitude: -92.65445,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3527545,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16208',
      geometry: { type: 'Point', coordinates: [-57.97632987, -31.38609216] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salto',
        code: 'UYSTY',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -31.38609,
        longitude: -57.97633,
        iso3: 'URY',
        iso3_op: 'URY',
        country: 'Uruguay',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3440714,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16209',
      geometry: { type: 'Point', coordinates: [38.38656044, 40.9222214] },
      geometry_name: 'shape',
      properties: {
        portname: 'Giresun',
        code: 'TRGIR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.92222,
        longitude: 38.38656,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 746881,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16210',
      geometry: { type: 'Point', coordinates: [39.73714432, 41.00330748] },
      geometry_name: 'shape',
      properties: {
        portname: 'Trabzon',
        code: 'TRTZX',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.00331,
        longitude: 39.73714,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 738648,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16211',
      geometry: { type: 'Point', coordinates: [-70.25511714, 11.7962094] },
      geometry_name: 'shape',
      properties: {
        portname: 'Amuay',
        code: 'VEAMY',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.79621,
        longitude: -70.25512,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3649293,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16212',
      geometry: { type: 'Point', coordinates: [-60.99389621, 14.01029047] },
      geometry_name: 'shape',
      properties: {
        portname: 'Castries',
        code: 'LCSLU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.01029,
        longitude: -60.9939,
        iso3: 'LCA',
        iso3_op: 'LCA',
        country: 'Saint Lucia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3576812,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16213',
      geometry: { type: 'Point', coordinates: [-86.76215201, 15.79556328] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Ceiba',
        code: 'HNLCE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.79556,
        longitude: -86.76215,
        iso3: null,
        iso3_op: 'HND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3608248,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16214',
      geometry: { type: 'Point', coordinates: [-87.45888188, 15.78159246] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tela',
        code: 'HNTEA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.78159,
        longitude: -87.45888,
        iso3: null,
        iso3_op: 'HND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3600931,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16215',
      geometry: { type: 'Point', coordinates: [-2.42343901, 53.42607587] },
      geometry_name: 'shape',
      properties: {
        portname: 'Partington',
        code: 'GBPTT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.42608,
        longitude: -2.42344,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2640593,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16216',
      geometry: { type: 'Point', coordinates: [27.50263719, 40.96587106] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tekirdag',
        code: 'TRTEK',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.96587,
        longitude: 27.50264,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 738927,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16217',
      geometry: { type: 'Point', coordinates: [-55.91408487, -27.35398696] },
      geometry_name: 'shape',
      properties: {
        portname: 'Posadas',
        code: 'ARPSS',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -27.35399,
        longitude: -55.91408,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3429886,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16218',
      geometry: { type: 'Point', coordinates: [-58.16325588, -26.18156613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Formosa',
        code: 'ARFMA',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -26.18157,
        longitude: -58.16326,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3433899,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16219',
      geometry: { type: 'Point', coordinates: [-59.64062699, -33.68589021] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Pedro',
        code: 'ARXPD',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.68589,
        longitude: -59.64063,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3428576,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16220',
      geometry: { type: 'Point', coordinates: [-70.86929324, -53.12392165] },
      geometry_name: 'shape',
      properties: {
        portname: 'Punta Arenas',
        code: 'CLPUQ',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -53.12392,
        longitude: -70.86929,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3874787,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16221',
      geometry: { type: 'Point', coordinates: [-87.65269636, 13.29433962] },
      geometry_name: 'shape',
      properties: {
        portname: 'Amapala',
        code: 'HNAMP',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.29434,
        longitude: -87.6527,
        iso3: null,
        iso3_op: 'HND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3615213,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16222',
      geometry: { type: 'Point', coordinates: [-52.33651837, 4.93580705] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cayenne',
        code: 'GFCAY',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.93581,
        longitude: -52.33652,
        iso3: 'GUF',
        iso3_op: 'GUF',
        country: 'French Guiana',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3382160,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16223',
      geometry: { type: 'Point', coordinates: [-60.32194578, -33.22636612] },
      geometry_name: 'shape',
      properties: {
        portname: 'Villa Constitucion',
        code: 'ARVCN',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.22637,
        longitude: -60.32195,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3832778,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16224',
      geometry: { type: 'Point', coordinates: [-58.10386801, -32.31612742] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paysandu',
        code: 'UYPDU',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.31613,
        longitude: -58.10387,
        iso3: 'URY',
        iso3_op: 'URY',
        country: 'Uruguay',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3441243,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16225',
      geometry: { type: 'Point', coordinates: [145.80304605, -5.21118018] },
      geometry_name: 'shape',
      properties: {
        portname: 'Madang',
        code: 'PGMAG',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.21118,
        longitude: 145.80305,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2091996,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16226',
      geometry: { type: 'Point', coordinates: [35.01136362, 32.81338008] },
      geometry_name: 'shape',
      properties: {
        portname: 'Haifa',
        code: 'ILHFA',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.81338,
        longitude: 35.01136,
        iso3: 'ISR',
        iso3_op: 'ISR',
        country: 'Israel',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 294801,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16227',
      geometry: { type: 'Point', coordinates: [31.76729066, 31.45161106] },
      geometry_name: 'shape',
      properties: {
        portname: 'Damietta',
        code: 'EGDAM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.45161,
        longitude: 31.76729,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 358048,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16228',
      geometry: { type: 'Point', coordinates: [147.15491847, -9.46418229] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Moresby',
        code: 'PGPOM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -9.46418,
        longitude: 147.15492,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2088122,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16229',
      geometry: { type: 'Point', coordinates: [104.93738588, 11.55953925] },
      geometry_name: 'shape',
      properties: {
        portname: 'Phnom Penh',
        code: 'KHPNH',
        prttype: 'River',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.55954,
        longitude: 104.93739,
        iso3: 'KHM',
        iso3_op: 'KHM',
        country: 'Cambodia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1821306,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16230',
      geometry: { type: 'Point', coordinates: [108.28992003, -2.84890781] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manggar',
        code: 'IDMAN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.84891,
        longitude: 108.28992,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1636426,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16231',
      geometry: { type: 'Point', coordinates: [-173.98513457, -18.65199292] },
      geometry_name: 'shape',
      properties: {
        portname: 'Neiafu',
        code: 'TONEI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -18.65199,
        longitude: -173.98513,
        iso3: 'TON',
        iso3_op: 'TON',
        country: 'Tonga',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4032420,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16232',
      geometry: { type: 'Point', coordinates: [-74.75961853, 10.96287854] },
      geometry_name: 'shape',
      properties: {
        portname: 'Barranquilla',
        code: 'COBAQ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.96288,
        longitude: -74.75962,
        iso3: 'COL',
        iso3_op: 'COL',
        country: 'Colombia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3689147,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16233',
      geometry: { type: 'Point', coordinates: [109.12878735, -6.84997157] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tegal',
        code: 'IDTEG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.84997,
        longitude: 109.12879,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1624494,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16234',
      geometry: { type: 'Point', coordinates: [140.71354357, -2.54542855] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jayapura',
        code: 'IDDJJ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.54543,
        longitude: 140.71354,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2082600,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16235',
      geometry: { type: 'Point', coordinates: [130.50112924, -3.10407528] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bula',
        code: 'IDBUA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.10408,
        longitude: 130.50113,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1647845,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16236',
      geometry: { type: 'Point', coordinates: [120.75515531, -1.38241929] },
      geometry_name: 'shape',
      properties: {
        portname: 'Poso',
        code: 'IDPSJ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.38242,
        longitude: 120.75516,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1630723,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16237',
      geometry: { type: 'Point', coordinates: [-77.26521782, 21.53704235] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nuevitas',
        code: 'CUNVT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.53704,
        longitude: -77.26522,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3545841,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16238',
      geometry: { type: 'Point', coordinates: [-77.6160328, -11.12379011] },
      geometry_name: 'shape',
      properties: {
        portname: 'Huacho',
        code: 'PEHCO',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -11.12379,
        longitude: -77.61603,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3940002,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16239',
      geometry: { type: 'Point', coordinates: [-78.60699103, -9.07507173] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chimbote',
        code: 'PECHM',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -9.07507,
        longitude: -78.60699,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3698304,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16240',
      geometry: { type: 'Point', coordinates: [-79.43634929, -7.69522763] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Chicama',
        code: 'PEPCH',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.69523,
        longitude: -79.43635,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3693250,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16241',
      geometry: { type: 'Point', coordinates: [-23.50009106, 14.91202099] },
      geometry_name: 'shape',
      properties: {
        portname: 'Praia',
        code: 'CVRAI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.91202,
        longitude: -23.50009,
        iso3: 'CPV',
        iso3_op: 'CPV',
        country: 'Cape Verde',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3374333,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16242',
      geometry: { type: 'Point', coordinates: [34.77765623, 32.10312157] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tel Aviv',
        code: 'ILJFA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.10312,
        longitude: 34.77766,
        iso3: 'ISR',
        iso3_op: 'ISR',
        country: 'Israel',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 293397,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16243',
      geometry: { type: 'Point', coordinates: [-1.05314567, 5.200216] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saltpond',
        code: 'GHSPD',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 5.20022,
        longitude: -1.05315,
        iso3: 'GHA',
        iso3_op: 'GHA',
        country: 'Ghana',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2295672,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16244',
      geometry: { type: 'Point', coordinates: [-1.70708498, 4.93686471] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sekondi',
        code: 'GHSEK',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.93686,
        longitude: -1.70708,
        iso3: null,
        iso3_op: 'GHA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2295458,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16245',
      geometry: { type: 'Point', coordinates: [10.76379322, 34.72510023] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sfax',
        code: 'TNSFA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 34.7251,
        longitude: 10.76379,
        iso3: null,
        iso3_op: 'TUN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2467454,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16246',
      geometry: { type: 'Point', coordinates: [43.24770038, -11.70482638] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moroni',
        code: 'KMYVA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -11.70483,
        longitude: 43.2477,
        iso3: 'COM',
        iso3_op: 'COM',
        country: 'Comoros',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 921772,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16247',
      geometry: { type: 'Point', coordinates: [-77.60412861, 17.86239506] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Kaiser',
        code: 'JMPKS',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.8624,
        longitude: -77.60413,
        iso3: 'JAM',
        iso3_op: 'JAM',
        country: 'Jamaica',
        lastcheckdate: '2019-05-22T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3488998,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16248',
      geometry: { type: 'Point', coordinates: [-0.6420229, 35.70886484] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oran',
        code: 'DZORN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 35.70886,
        longitude: -0.64202,
        iso3: 'DZA',
        iso3_op: 'DZA',
        country: 'Algeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2485926,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16249',
      geometry: { type: 'Point', coordinates: [-6.35360721, 54.0067524] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dundalk',
        code: 'IEDDK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.00675,
        longitude: -6.35361,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2964540,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16250',
      geometry: { type: 'Point', coordinates: [11.0695, 54.36033387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Grossenbrode',
        code: 'DEGRO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.36033,
        longitude: 11.0695,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2916655,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16251',
      geometry: { type: 'Point', coordinates: [-6.08252792, 4.95219995] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sassandra',
        code: 'CIZSS',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.9522,
        longitude: -6.08253,
        iso3: 'CIV',
        iso3_op: 'CIV',
        country: "Côte d'Ivoire",
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2281951,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16252',
      geometry: { type: 'Point', coordinates: [-5.60040298, 55.42347711] },
      geometry_name: 'shape',
      properties: {
        portname: 'Campbeltown',
        code: 'GBCBT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.42348,
        longitude: -5.6004,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2653915,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16253',
      geometry: { type: 'Point', coordinates: [-6.18848373, 55.62807043] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Ellen',
        code: 'GBPLN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.62807,
        longitude: -6.18848,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2640067,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16254',
      geometry: { type: 'Point', coordinates: [9.61685656, 55.51425906] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skaerbaek',
        code: 'DKSKB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.51426,
        longitude: 9.61686,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2613538,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16255',
      geometry: { type: 'Point', coordinates: [16.64954097, 56.87937484] },
      geometry_name: 'shape',
      properties: {
        portname: 'Borgholm',
        code: 'SEBOM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.87937,
        longitude: 16.64954,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2720437,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16256',
      geometry: { type: 'Point', coordinates: [8.59371816, 58.33768446] },
      geometry_name: 'shape',
      properties: {
        portname: 'Grimstad',
        code: 'NOGTD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.33768,
        longitude: 8.59372,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3155041,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16257',
      geometry: { type: 'Point', coordinates: [10.0480676, 59.04078999] },
      geometry_name: 'shape',
      properties: {
        portname: 'Larvik',
        code: 'NOLAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.04079,
        longitude: 10.04807,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3148129,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16258',
      geometry: { type: 'Point', coordinates: [18.07919005, 59.33005334] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stockholm',
        code: 'SESTO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.33005,
        longitude: 18.07919,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2673730,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16259',
      geometry: { type: 'Point', coordinates: [23.2635293, 59.90300643] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lappohja',
        code: 'FILAP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.90301,
        longitude: 23.26353,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 648859,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16260',
      geometry: { type: 'Point', coordinates: [10.40701639, 63.44020514] },
      geometry_name: 'shape',
      properties: {
        portname: 'Trondheim',
        code: 'NOTRD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.44021,
        longitude: 10.40702,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3133880,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16261',
      geometry: { type: 'Point', coordinates: [35.28596049, 33.45287852] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sarafand',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 33.45288,
        longitude: 35.28596,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 278040,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16262',
      geometry: { type: 'Point', coordinates: [-79.57315491, 42.86456631] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Maitland',
        code: 'CAPTM',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.86457,
        longitude: -79.57315,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6111943,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16263',
      geometry: { type: 'Point', coordinates: [-78.88388429, 42.87935991] },
      geometry_name: 'shape',
      properties: {
        portname: 'Buffalo',
        code: 'USBUF',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.87936,
        longitude: -78.88388,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5110629,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16264',
      geometry: { type: 'Point', coordinates: [35.48365724, 33.84336535] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Ouzaai',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.84337,
        longitude: 35.48366,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6274921,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16265',
      geometry: { type: 'Point', coordinates: [35.58605492, 33.92897764] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dbaiye',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.92898,
        longitude: 35.58605,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 280733,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16266',
      geometry: { type: 'Point', coordinates: [29.76576048, -7.03112491] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moba',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -7.03112,
        longitude: 29.76576,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 209000,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16267',
      geometry: { type: 'Point', coordinates: [-79.35304012, 43.64082161] },
      geometry_name: 'shape',
      properties: {
        portname: 'Toronto',
        code: 'CATOR',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.64082,
        longitude: -79.35304,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6167865,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16268',
      geometry: { type: 'Point', coordinates: [35.64935186, 34.09131294] },
      geometry_name: 'shape',
      properties: {
        portname: 'Halat',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.09131,
        longitude: 35.64935,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 274729,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16269',
      geometry: { type: 'Point', coordinates: [-119.69352644, 34.40743986] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santa Barbara',
        code: 'USSBA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.40744,
        longitude: -119.69353,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5392952,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16270',
      geometry: { type: 'Point', coordinates: [29.88146288, 31.19534167] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alexandria',
        code: 'EGALY',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 31.19534,
        longitude: 29.88146,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 361058,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16271',
      geometry: { type: 'Point', coordinates: [17.6865519, -2.73685996] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nioki',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.73686,
        longitude: 17.68655,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2311968,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16272',
      geometry: { type: 'Point', coordinates: [124.23398766, 13.58185279] },
      geometry_name: 'shape',
      properties: {
        portname: 'Virac',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.58185,
        longitude: 124.23399,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1679802,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16273',
      geometry: { type: 'Point', coordinates: [11.11258507, 33.4832141] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zarzis',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.48321,
        longitude: 11.11259,
        iso3: 'TUN',
        iso3_op: 'TUN',
        country: 'Tunisia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2470173,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16274',
      geometry: { type: 'Point', coordinates: [76.20995244, 42.45329951] },
      geometry_name: 'shape',
      properties: {
        portname: 'Balykchy',
        code: null,
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.4533,
        longitude: 76.20995,
        iso3: 'KGZ',
        iso3_op: 'KGZ',
        country: 'Kyrgyzstan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1527004,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16275',
      geometry: { type: 'Point', coordinates: [-69.72971946, 48.14088195] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tadoussac',
        code: 'CATAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.14088,
        longitude: -69.72972,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6639665,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16276',
      geometry: { type: 'Point', coordinates: [-80.05171679, 26.76458531] },
      geometry_name: 'shape',
      properties: {
        portname: 'Palm Beach',
        code: 'USPAB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.76459,
        longitude: -80.05172,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 4167505,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16277',
      geometry: { type: 'Point', coordinates: [-118.2401, 33.77713387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wilmington',
        code: 'USWTN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.77713,
        longitude: -118.2401,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 5409802,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16278',
      geometry: { type: 'Point', coordinates: [33.20761095, 0.41425097] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jinja',
        code: null,
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.41425,
        longitude: 33.20761,
        iso3: 'UGA',
        iso3_op: 'UGA',
        country: 'Uganda',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 233114,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16279',
      geometry: { type: 'Point', coordinates: [130.99301308, 30.73032907] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nishinoomote',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.73033,
        longitude: 130.99301,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1855203,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16280',
      geometry: { type: 'Point', coordinates: [100.08983572, 20.26754377] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chiang Saen',
        code: null,
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.26754,
        longitude: 100.08984,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1611187,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16281',
      geometry: { type: 'Point', coordinates: [9.81724392, 44.10148316] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Spezia',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.10148,
        longitude: 9.81724,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3175081,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16282',
      geometry: { type: 'Point', coordinates: [-6.45707442, 52.33545477] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wexford',
        code: 'IEWEX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.33545,
        longitude: -6.45707,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 7838931,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16283',
      geometry: { type: 'Point', coordinates: [-16.0806816, 14.13143006] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kaolack',
        code: 'SNKLC',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.13143,
        longitude: -16.08068,
        iso3: 'SEN',
        iso3_op: 'SEN',
        country: 'Senegal',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2250805,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16284',
      geometry: { type: 'Point', coordinates: [-0.323, 39.45913387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Valencia',
        code: 'ESVLC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.45913,
        longitude: -0.323,
        iso3: null,
        iso3_op: 'ESP',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2509954,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16285',
      geometry: { type: 'Point', coordinates: [9.83569583, 43.04939877] },
      geometry_name: 'shape',
      properties: {
        portname: 'Capraia',
        code: 'ITCPA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.0494,
        longitude: 9.8357,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3180649,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16286',
      geometry: { type: 'Point', coordinates: [17.34567803, 61.89488127] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stocka',
        code: 'SESTA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.89488,
        longitude: 17.34568,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2673772,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16287',
      geometry: { type: 'Point', coordinates: [24.50737466, 40.95813295] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nea Karvali',
        code: 'GRNKV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.95813,
        longitude: 24.50737,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 735014,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16288',
      geometry: { type: 'Point', coordinates: [2.18330751, 41.37581945] },
      geometry_name: 'shape',
      properties: {
        portname: 'Barcelona',
        code: 'ESBCN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.37582,
        longitude: 2.18331,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3128760,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16289',
      geometry: { type: 'Point', coordinates: [8.73971185, 41.92382761] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ajaccio',
        code: 'FRAJA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.92383,
        longitude: 8.73971,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3038334,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16290',
      geometry: { type: 'Point', coordinates: [26.99662451, -7.8447395] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mulongo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.84474,
        longitude: 26.99662,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 208182,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16291',
      geometry: { type: 'Point', coordinates: [-4.11129572, 47.99028769] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quimper',
        code: 'FRUIP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.99029,
        longitude: -4.1113,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2984701,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16292',
      geometry: { type: 'Point', coordinates: [16.53897819, -5.30844747] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dinga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.30845,
        longitude: 16.53898,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2316079,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16293',
      geometry: { type: 'Point', coordinates: [18.73316198, -4.83768044] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lusanga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.83768,
        longitude: 18.73316,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2313291,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16294',
      geometry: { type: 'Point', coordinates: [20.38171641, 1.34576601] },
      geometry_name: 'shape',
      properties: {
        portname: 'Djombo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.34577,
        longitude: 20.38172,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 217126,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16295',
      geometry: { type: 'Point', coordinates: [35.94756539, -18.28689622] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marromeu',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -18.2869,
        longitude: 35.94757,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Marromeu: (I) -No Vessel Restriction- (Ii) -Truck Access - (Iii) -Network Coverage-(Iv)-No Hazard To Navigation--Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1040397,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16296',
      geometry: { type: 'Point', coordinates: [23.73014575, 39.12080608] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skopelos',
        code: 'GRSKO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.12081,
        longitude: 23.73015,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 253581,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16297',
      geometry: { type: 'Point', coordinates: [23.44178727, 40.27652226] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yerakini',
        code: 'GRYER',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 40.27652,
        longitude: 23.44179,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 733787,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16298',
      geometry: { type: 'Point', coordinates: [-2.43906265, 50.56902462] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portland',
        code: 'GBPTL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.56902,
        longitude: -2.43906,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 6692041,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16299',
      geometry: { type: 'Point', coordinates: [10.42130443, 59.26087792] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tonsberg',
        code: 'NOTON',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.26088,
        longitude: 10.4213,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3134331,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16300',
      geometry: { type: 'Point', coordinates: [13.16427916, 58.50777505] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lidkoping',
        code: 'SELDK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.50778,
        longitude: 13.16428,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2696329,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16301',
      geometry: { type: 'Point', coordinates: [-21.98008775, 64.06708694] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hafnarfjordur',
        code: 'ISHAF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.06709,
        longitude: -21.98009,
        iso3: null,
        iso3_op: 'ISL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 3416706,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16302',
      geometry: { type: 'Point', coordinates: [9.18916801, 53.91529402] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brunsbuttel',
        code: 'DEBRB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.91529,
        longitude: 9.18917,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2943049,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16303',
      geometry: { type: 'Point', coordinates: [21.48222185, -0.36641879] },
      geometry_name: 'shape',
      properties: {
        portname: 'Isambo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -0.36642,
        longitude: 21.48222,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 215811,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16304',
      geometry: { type: 'Point', coordinates: [35.55779522, 33.89926639] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ed Daoura',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.89927,
        longitude: 35.5578,
        iso3: 'LBN',
        iso3_op: 'LBN',
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: 'Fishing And Recreational, Fuel Oil And Others',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 275739,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16305',
      geometry: { type: 'Point', coordinates: [-4.23634015, 15.40051966] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.40052,
        longitude: -4.23634,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2461209,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16306',
      geometry: { type: 'Point', coordinates: [107.58781419, 16.49738866] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hue',
        code: 'VNHUI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 16.49739,
        longitude: 107.58781,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1580240,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16307',
      geometry: { type: 'Point', coordinates: [-14.41329799, -7.92565026] },
      geometry_name: 'shape',
      properties: {
        portname: 'Georgetown',
        code: 'SHASI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.92565,
        longitude: -14.4133,
        iso3: 'SHN',
        iso3_op: 'SHN',
        country: 'Saint Helena',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 2411397,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16308',
      geometry: { type: 'Point', coordinates: [49.40829773, -18.16636641] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manangareza',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -18.16637,
        longitude: 49.4083,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1061361,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16309',
      geometry: { type: 'Point', coordinates: [49.06075249, -19.06499337] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sondrara',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -19.06499,
        longitude: 49.06075,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1056107,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16310',
      geometry: { type: 'Point', coordinates: [48.77043904, -20.00764889] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ambodiharina',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -20.00765,
        longitude: 48.77044,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:53:30.519Z',
        updatedate: '2021-02-24T11:53:30.519Z',
        geonameid: 1081502,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16311',
      geometry: { type: 'Point', coordinates: [48.49069797, -20.52664886] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ambodisaina',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -20.52665,
        longitude: 48.4907,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1080997,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16312',
      geometry: { type: 'Point', coordinates: [-63.56770198, 44.63246424] },
      geometry_name: 'shape',
      properties: {
        portname: 'Halifax',
        code: 'CAHAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.63246,
        longitude: -63.5677,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5969423,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16313',
      geometry: { type: 'Point', coordinates: [121.62958622, 18.36124405] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aparri',
        code: 'PHAPR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.36124,
        longitude: 121.62959,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1730398,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16314',
      geometry: { type: 'Point', coordinates: [22.60593439, 2.12261611] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yambinga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.12262,
        longitude: 22.60593,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 203841,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16315',
      geometry: { type: 'Point', coordinates: [150.66236078, -10.60964323] },
      geometry_name: 'shape',
      properties: {
        portname: 'Samarai',
        code: 'PGSQT',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -10.60964,
        longitude: 150.66236,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2132606,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16316',
      geometry: { type: 'Point', coordinates: [19.55674569, 1.27275445] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bokakata',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.27275,
        longitude: 19.55675,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2316913,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16317',
      geometry: { type: 'Point', coordinates: [23.35629548, -1.05472225] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ikela',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -1.05472,
        longitude: 23.3563,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 216031,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16318',
      geometry: { type: 'Point', coordinates: [17.54150572, -4.79128604] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalonda',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'approximate',
        latitude: -4.79129,
        longitude: 17.54151,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2315186,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16319',
      geometry: { type: 'Point', coordinates: [17.6421, -5.11296613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kimbao',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'unknown',
        latitude: -5.11297,
        longitude: 17.6421,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2314618,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16320',
      geometry: { type: 'Point', coordinates: [19.43874027, -3.41465335] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oshwe',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -3.41465,
        longitude: 19.43874,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2311807,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16321',
      geometry: { type: 'Point', coordinates: [-80.21556543, 44.50487833] },
      geometry_name: 'shape',
      properties: {
        portname: 'Collingwood',
        code: 'CACOL',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.50488,
        longitude: -80.21557,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5925975,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16322',
      geometry: { type: 'Point', coordinates: [-84.61757018, 45.84962414] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mackinac Island',
        code: 'USMCD',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.84962,
        longitude: -84.61757,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5000451,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16323',
      geometry: { type: 'Point', coordinates: [-92.02315069, 46.69136823] },
      geometry_name: 'shape',
      properties: {
        portname: 'Superior',
        code: 'USSUW',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.69137,
        longitude: -92.02315,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5275191,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16324',
      geometry: { type: 'Point', coordinates: [-86.28928009, 43.21899537] },
      geometry_name: 'shape',
      properties: {
        portname: 'Muskegon',
        code: 'USMKG',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.219,
        longitude: -86.28928,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5003132,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16325',
      geometry: { type: 'Point', coordinates: [-86.39674506, 48.72058711] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marathon',
        code: 'CAMAR',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.72059,
        longitude: -86.39675,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6065867,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16326',
      geometry: { type: 'Point', coordinates: [-57.79452378, -18.960144] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Suarez',
        code: 'BOPSZ',
        prttype: 'Lake',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -18.96014,
        longitude: -57.79452,
        iso3: 'BOL',
        iso3_op: 'BOL',
        country: 'Bolivia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3444199,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16327',
      geometry: { type: 'Point', coordinates: [19.98121095, -0.19822519] },
      geometry_name: 'shape',
      properties: {
        portname: 'Busira',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.19823,
        longitude: 19.98121,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2316202,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16328',
      geometry: { type: 'Point', coordinates: [18.67116844, 0.86449239] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mampoko',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.86449,
        longitude: 18.67117,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2313100,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16329',
      geometry: { type: 'Point', coordinates: [18.69834649, 2.99450564] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ekuta',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.99451,
        longitude: 18.69835,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2315906,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16330',
      geometry: { type: 'Point', coordinates: [17.74417546, -3.60528757] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pono',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.60529,
        longitude: 17.74418,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2311676,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16331',
      geometry: { type: 'Point', coordinates: [28.99154592, -1.8591734] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kinyezire',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.85917,
        longitude: 28.99155,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 212841,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16332',
      geometry: { type: 'Point', coordinates: [-70.92610312, 42.25581541] },
      geometry_name: 'shape',
      properties: {
        portname: 'North Weymouth',
        code: 'USNWY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.25582,
        longitude: -70.9261,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4945810,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16333',
      geometry: { type: 'Point', coordinates: [123.61671847, 12.36841569] },
      geometry_name: 'shape',
      properties: {
        portname: 'Masbate',
        code: 'PHMBT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.36842,
        longitude: 123.61672,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1700712,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16334',
      geometry: { type: 'Point', coordinates: [-64.307779, 44.37604116] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lunenburg',
        code: 'CALUN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.37604,
        longitude: -64.30778,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6062069,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16335',
      geometry: { type: 'Point', coordinates: [-127.48454259, 50.42304595] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Alice',
        code: 'CAPAC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.42305,
        longitude: -127.48454,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6111640,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16336',
      geometry: { type: 'Point', coordinates: [-130.29401902, 54.22784191] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Edward',
        code: 'CAPTE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.22784,
        longitude: -130.29402,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6111742,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16337',
      geometry: { type: 'Point', coordinates: [-132.39035845, 56.47424425] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wrangell',
        code: 'USWRG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.47424,
        longitude: -132.39036,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5558586,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16338',
      geometry: { type: 'Point', coordinates: [-136.22676235, 57.95772145] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pelican',
        code: 'USPEC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.95772,
        longitude: -136.22676,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5556304,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16339',
      geometry: { type: 'Point', coordinates: [-145.76159129, 60.55031887] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cordova',
        code: 'USCDV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.55032,
        longitude: -145.76159,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5859888,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16340',
      geometry: { type: 'Point', coordinates: [-70.24861939, 43.65735684] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portland',
        code: 'USPWM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.65736,
        longitude: -70.24862,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4975802,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16341',
      geometry: { type: 'Point', coordinates: [-65.11196303, 43.70011829] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lockeport',
        code: 'CALCP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.70012,
        longitude: -65.11196,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6058237,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16342',
      geometry: { type: 'Point', coordinates: [-66.00297991, 44.43763947] },
      geometry_name: 'shape',
      properties: {
        portname: 'Weymouth',
        code: 'CAWEY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.43764,
        longitude: -66.00298,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6179663,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16343',
      geometry: { type: 'Point', coordinates: [-74.92478286, 44.95592543] },
      geometry_name: 'shape',
      properties: {
        portname: 'Massena',
        code: 'USMSS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.95593,
        longitude: -74.92478,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5126194,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16344',
      geometry: { type: 'Point', coordinates: [-60.8050139, 45.96255623] },
      geometry_name: 'shape',
      properties: {
        portname: 'Iona',
        code: 'CAION',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.96256,
        longitude: -60.80501,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5983667,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16345',
      geometry: { type: 'Point', coordinates: [-59.97184682, 45.91858747] },
      geometry_name: 'shape',
      properties: {
        portname: 'Louisbourg',
        code: 'CALOU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.91859,
        longitude: -59.97185,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6060789,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16346',
      geometry: { type: 'Point', coordinates: [-123.91829773, 46.16506632] },
      geometry_name: 'shape',
      properties: {
        portname: 'Warrenton',
        code: 'USWTO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.16507,
        longitude: -123.9183,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5759289,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16347',
      geometry: { type: 'Point', coordinates: [-80.69206889, 32.37253385] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Royal',
        code: 'USPRL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.37253,
        longitude: -80.69207,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4591896,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16348',
      geometry: { type: 'Point', coordinates: [-124.81265271, 49.23432845] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Alberni',
        code: 'CAPAB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.23433,
        longitude: -124.81265,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6111632,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16349',
      geometry: { type: 'Point', coordinates: [-123.48964109, 49.52036492] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Mellon',
        code: 'CAPML',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.52036,
        longitude: -123.48964,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6111956,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16350',
      geometry: { type: 'Point', coordinates: [-124.92703097, 49.67222135] },
      geometry_name: 'shape',
      properties: {
        portname: 'Comox',
        code: 'CACOX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.67222,
        longitude: -124.92703,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5926432,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16351',
      geometry: { type: 'Point', coordinates: [-126.66436433, 49.91722459] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tahsis',
        code: 'CAPTA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.91722,
        longitude: -126.66436,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6161139,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16352',
      geometry: { type: 'Point', coordinates: [-132.97501335, 56.80680937] },
      geometry_name: 'shape',
      properties: {
        portname: 'Petersburg',
        code: 'USPBU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.80681,
        longitude: -132.97501,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5556338,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16353',
      geometry: { type: 'Point', coordinates: [-149.4395308, 60.1177101] },
      geometry_name: 'shape',
      properties: {
        portname: 'Seward',
        code: 'USSEW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.11771,
        longitude: -149.43953,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5873776,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16354',
      geometry: { type: 'Point', coordinates: [-0.32022605, 49.19494379] },
      geometry_name: 'shape',
      properties: {
        portname: 'Caen',
        code: 'FRCFR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.19494,
        longitude: -0.32023,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3029241,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16355',
      geometry: { type: 'Point', coordinates: [10.08103446, 55.5658782] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bogense',
        code: 'DKBGZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.56588,
        longitude: 10.08103,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2623857,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16356',
      geometry: { type: 'Point', coordinates: [16.55378491, 57.37277827] },
      geometry_name: 'shape',
      properties: {
        portname: 'Figeholm',
        code: 'SEFIG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.37278,
        longitude: 16.55378,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2715094,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16357',
      geometry: { type: 'Point', coordinates: [-4.86149377, 55.24416858] },
      geometry_name: 'shape',
      properties: {
        portname: 'Girvan',
        code: 'GBGIR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.24417,
        longitude: -4.86149,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2648626,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16358',
      geometry: { type: 'Point', coordinates: [31.1095055, 70.37312858] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vardo',
        code: 'NOVAO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 70.37313,
        longitude: 31.10951,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 777019,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16359',
      geometry: { type: 'Point', coordinates: [11.13504459, 55.33451933] },
      geometry_name: 'shape',
      properties: {
        portname: 'Korsor',
        code: 'DKKRR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.33452,
        longitude: 11.13504,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2618361,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16360',
      geometry: { type: 'Point', coordinates: [11.76601943, 55.94531302] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rorvig',
        code: 'DKRRV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.94531,
        longitude: 11.76602,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2614515,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16361',
      geometry: { type: 'Point', coordinates: [22.93194243, 37.94232478] },
      geometry_name: 'shape',
      properties: {
        portname: 'Corinth',
        code: 'GRCRG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.94232,
        longitude: 22.93194,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 259289,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16362',
      geometry: { type: 'Point', coordinates: [15.2400462, 38.22118502] },
      geometry_name: 'shape',
      properties: {
        portname: 'Milazzo',
        code: 'ITMLZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.22119,
        longitude: 15.24005,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2524155,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16363',
      geometry: { type: 'Point', coordinates: [-1.16117172, 46.14620794] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Rochelle',
        code: 'FRLRH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.14621,
        longitude: -1.16117,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3006787,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16364',
      geometry: { type: 'Point', coordinates: [16.36621614, 56.65843682] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalmar',
        code: 'SEKLR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.65844,
        longitude: 16.36622,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2702261,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16365',
      geometry: { type: 'Point', coordinates: [5.74298461, 58.97165728] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stavanger',
        code: 'NOSVG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.97166,
        longitude: 5.74298,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3137115,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16366',
      geometry: { type: 'Point', coordinates: [-83.46405732, 41.68859563] },
      geometry_name: 'shape',
      properties: {
        portname: 'Toledo',
        code: 'USTOL',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.6886,
        longitude: -83.46406,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5174035,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16367',
      geometry: { type: 'Point', coordinates: [-75.5223, 39.71675054] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wilmington',
        code: 'USILG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.71675,
        longitude: -75.5223,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4145381,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16368',
      geometry: { type: 'Point', coordinates: [-75.41707543, 39.81000165] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marcus Hook',
        code: 'USMAH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.81,
        longitude: -75.41708,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4559431,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16369',
      geometry: { type: 'Point', coordinates: [40.1868473, -10.26305296] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mtwara',
        code: 'TZMYW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -10.26305,
        longitude: 40.18685,
        iso3: 'TZA',
        iso3_op: 'TZA',
        country: 'United Republic of Tanzania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 877747,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16370',
      geometry: { type: 'Point', coordinates: [138.53494043, 37.36372776] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kashiwazaki',
        code: 'JPAIK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.36373,
        longitude: 138.53494,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1859908,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16371',
      geometry: { type: 'Point', coordinates: [-22.92236887, 16.17877075] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sal Rei',
        code: 'CVSAR',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.17877,
        longitude: -22.92237,
        iso3: 'CPV',
        iso3_op: 'CPV',
        country: 'Cape Verde',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3374235,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16372',
      geometry: { type: 'Point', coordinates: [16.54940014, 59.60346123] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vasteras',
        code: 'SEVST',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.60346,
        longitude: 16.5494,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2664454,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16373',
      geometry: { type: 'Point', coordinates: [23.918474, 64.27914077] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalajoki',
        code: 'FIKJO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.27914,
        longitude: 23.91847,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 654837,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16374',
      geometry: { type: 'Point', coordinates: [-4.62642896, 53.31075933] },
      geometry_name: 'shape',
      properties: {
        portname: 'Holyhead',
        code: 'GBHLY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.31076,
        longitude: -4.62643,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2646686,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16375',
      geometry: { type: 'Point', coordinates: [-8.64899698, 42.43558055] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pontevedra',
        code: 'ESPEV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.43558,
        longitude: -8.649,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3113209,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16376',
      geometry: { type: 'Point', coordinates: [-3.04205133, 48.78301745] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paimpol',
        code: 'FRPAI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.78302,
        longitude: -3.04205,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2988796,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16377',
      geometry: { type: 'Point', coordinates: [1.0569334, 49.44773302] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rouen',
        code: 'FRURO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.44773,
        longitude: 1.05693,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2982652,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16378',
      geometry: { type: 'Point', coordinates: [-1.98637964, 50.7187796] },
      geometry_name: 'shape',
      properties: {
        portname: 'Poole',
        code: 'GBPOO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.71878,
        longitude: -1.98638,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2640101,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16379',
      geometry: { type: 'Point', coordinates: [0.05644286, 50.78729216] },
      geometry_name: 'shape',
      properties: {
        portname: 'Newhaven',
        code: 'GBNHV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.78729,
        longitude: 0.05644,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2641637,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16380',
      geometry: { type: 'Point', coordinates: [-0.21054312, 50.83046904] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portslade',
        code: 'GBPTS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.83047,
        longitude: -0.21054,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2639998,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16381',
      geometry: { type: 'Point', coordinates: [0.89419094, 51.33578386] },
      geometry_name: 'shape',
      properties: {
        portname: 'Faversham',
        code: 'GBFAV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.33578,
        longitude: 0.89419,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2649622,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16382',
      geometry: { type: 'Point', coordinates: [1.31487217, 51.9483085] },
      geometry_name: 'shape',
      properties: {
        portname: 'Felixstowe',
        code: 'GBFXT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.94831,
        longitude: 1.31487,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2649579,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16383',
      geometry: { type: 'Point', coordinates: [6.1696921, 52.24668549] },
      geometry_name: 'shape',
      properties: {
        portname: 'Deventer',
        code: 'NLDEV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.24669,
        longitude: 6.16969,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2756987,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16384',
      geometry: { type: 'Point', coordinates: [-6.95878427, 52.38400701] },
      geometry_name: 'shape',
      properties: {
        portname: 'New Ross',
        code: 'IENRS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.38401,
        longitude: -6.95878,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2962252,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16385',
      geometry: { type: 'Point', coordinates: [-3.26327811, 53.31750424] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mostyn',
        code: 'GBCHE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.3175,
        longitude: -3.26328,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2642137,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16386',
      geometry: { type: 'Point', coordinates: [-2.9087888, 53.35065501] },
      geometry_name: 'shape',
      properties: {
        portname: 'Garston',
        code: 'GBGTN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.35066,
        longitude: -2.90879,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2648827,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16387',
      geometry: { type: 'Point', coordinates: [-2.97277528, 53.34428133] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bromborough',
        code: 'GBBHK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 53.34428,
        longitude: -2.97278,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2654596,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16388',
      geometry: { type: 'Point', coordinates: [101.25568685, 6.89973765] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pattani',
        code: 'THPAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.89974,
        longitude: 101.25569,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1607978,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16389',
      geometry: { type: 'Point', coordinates: [122.26920751, 12.57773905] },
      geometry_name: 'shape',
      properties: {
        portname: 'Romblon',
        code: 'PHRLN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.57774,
        longitude: 122.26921,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1691538,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16390',
      geometry: { type: 'Point', coordinates: [-6.81253905, 34.06048] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sale',
        code: 'MASAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.06048,
        longitude: -6.81254,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2537763,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16391',
      geometry: { type: 'Point', coordinates: [10.64496272, 35.82794989] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sousse',
        code: 'TNSUS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.82795,
        longitude: 10.64496,
        iso3: 'TUN',
        iso3_op: 'TUN',
        country: 'Tunisia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2464915,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16392',
      geometry: { type: 'Point', coordinates: [13.38805678, -12.58638438] },
      geometry_name: 'shape',
      properties: {
        portname: 'Benguela',
        code: 'AOBUG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.58638,
        longitude: 13.38806,
        iso3: 'AGO',
        iso3_op: 'AGO',
        country: 'Angola',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3351663,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16393',
      geometry: { type: 'Point', coordinates: [38.97340464, -5.42860274] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pangani',
        code: 'TZPAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.4286,
        longitude: 38.9734,
        iso3: null,
        iso3_op: 'TZA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 150793,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16394',
      geometry: { type: 'Point', coordinates: [99.50938502, 7.41068711] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kantang',
        code: 'THKAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 7.41069,
        longitude: 99.50939,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1153072,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16395',
      geometry: { type: 'Point', coordinates: [131.86033671, 34.00599855] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kudamatsu',
        code: 'JPKUD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.006,
        longitude: 131.86034,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1858498,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16396',
      geometry: { type: 'Point', coordinates: [171.59732263, -41.75437276] },
      geometry_name: 'shape',
      properties: {
        portname: 'Westport',
        code: 'NZWSZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.75437,
        longitude: 171.59732,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2206900,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16397',
      geometry: { type: 'Point', coordinates: [176.17345257, -37.63451015] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mount Maunganui',
        code: 'NZMMU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -37.63451,
        longitude: 176.17345,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2208041,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16398',
      geometry: { type: 'Point', coordinates: [-54.94364133, -34.9657342] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maldonado',
        code: 'UYMDO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.96573,
        longitude: -54.94364,
        iso3: 'URY',
        iso3_op: 'URY',
        country: 'Uruguay',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3441894,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16399',
      geometry: { type: 'Point', coordinates: [152.44030142, -3.66311321] },
      geometry_name: 'shape',
      properties: {
        portname: 'Namatanai',
        code: 'PGATN',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.66311,
        longitude: 152.4403,
        iso3: 'PNG',
        iso3_op: 'PNG',
        country: 'Papua New Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2090020,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16400',
      geometry: { type: 'Point', coordinates: [135.87533419, -34.74006004] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Lincoln',
        code: 'AUPLO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.74006,
        longitude: 135.87533,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2063036,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16401',
      geometry: { type: 'Point', coordinates: [121.89517034, -33.87046863] },
      geometry_name: 'shape',
      properties: {
        portname: 'Esperance',
        code: 'AUEPR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.87047,
        longitude: 121.89517,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2071860,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16402',
      geometry: { type: 'Point', coordinates: [-45.39926771, -23.79864885] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sao Sebastiao',
        code: 'BRSSO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -23.79865,
        longitude: -45.39927,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3448257,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16403',
      geometry: { type: 'Point', coordinates: [117.19288144, -20.63754826] },
      geometry_name: 'shape',
      properties: {
        portname: 'Point Samson',
        code: 'AUPSM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.63755,
        longitude: 117.19288,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2063176,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16404',
      geometry: { type: 'Point', coordinates: [146.33061351, -18.53056883] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lucinda',
        code: 'AULUC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -18.53057,
        longitude: 146.33061,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2159397,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16405',
      geometry: { type: 'Point', coordinates: [-51.05427837, 0.02596409] },
      geometry_name: 'shape',
      properties: {
        portname: 'Macapa',
        code: 'BRMCP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.02596,
        longitude: -51.05428,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3396016,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16406',
      geometry: { type: 'Point', coordinates: [73.50295151, 4.17090069] },
      geometry_name: 'shape',
      properties: {
        portname: 'Male',
        code: 'MVMLE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.1709,
        longitude: 73.50295,
        iso3: null,
        iso3_op: 'MDV',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1282027,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16407',
      geometry: { type: 'Point', coordinates: [116.07988199, 5.99128234] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kota Kinabalu',
        code: 'MYBKI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.99128,
        longitude: 116.07988,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1733432,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16408',
      geometry: { type: 'Point', coordinates: [122.2413302, 13.94494683] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hondagua',
        code: 'PHHON',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.94495,
        longitude: 122.24133,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1711538,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16409',
      geometry: { type: 'Point', coordinates: [70.09886596, 21.10882442] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mangrol',
        code: 'INMGR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.10882,
        longitude: 70.09887,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1263752,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16410',
      geometry: { type: 'Point', coordinates: [118.72848291, 9.74241004] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Princesa',
        code: 'PHPPS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.74241,
        longitude: 118.72848,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1692685,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16411',
      geometry: { type: 'Point', coordinates: [113.55892675, 22.1969368] },
      geometry_name: 'shape',
      properties: {
        portname: 'Macau',
        code: 'MOFMM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.19694,
        longitude: 113.55893,
        iso3: 'MAC',
        iso3_op: 'MAC',
        country: 'Macau',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1821274,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16412',
      geometry: { type: 'Point', coordinates: [119.36519747, 26.03987138] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fuzhou',
        code: 'CNFOC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 26.03987,
        longitude: 119.3652,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1810821,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16413',
      geometry: { type: 'Point', coordinates: [138.21307166, 34.62214993] },
      geometry_name: 'shape',
      properties: {
        portname: 'Omaezaki',
        code: 'JPOMZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.62215,
        longitude: 138.21307,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6822196,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16414',
      geometry: { type: 'Point', coordinates: [139.89654726, 35.36818825] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kisarazu',
        code: 'JPKZU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.36819,
        longitude: 139.89655,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1859393,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16415',
      geometry: { type: 'Point', coordinates: [141.01510658, 43.18955956] },
      geometry_name: 'shape',
      properties: {
        portname: 'Otaru',
        code: 'JPOTR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.18956,
        longitude: 141.01511,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2128574,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16416',
      geometry: { type: 'Point', coordinates: [39.7205413, 43.58213757] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sochi',
        code: 'RUSOC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.58214,
        longitude: 39.72054,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 491422,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16417',
      geometry: { type: 'Point', coordinates: [170.50865812, -45.88967733] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dunedin',
        code: 'NZDUD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -45.88968,
        longitude: 170.50866,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2191562,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16418',
      geometry: { type: 'Point', coordinates: [170.97986595, -45.10999976] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oamaru',
        code: 'NZOAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -45.11,
        longitude: 170.97987,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6215045,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16419',
      geometry: { type: 'Point', coordinates: [174.7867747, -41.27851184] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wellington',
        code: 'NZWLG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.27851,
        longitude: 174.78677,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2179537,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16420',
      geometry: { type: 'Point', coordinates: [142.47552789, -38.40177036] },
      geometry_name: 'shape',
      properties: {
        portname: 'Warrnambool',
        code: 'AUWMB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -38.40177,
        longitude: 142.47553,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2144528,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16421',
      geometry: { type: 'Point', coordinates: [174.3485551, -35.75613859] },
      geometry_name: 'shape',
      properties: {
        portname: 'Whangarei',
        code: 'NZWRE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -35.75614,
        longitude: 174.34856,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6230919,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16422',
      geometry: { type: 'Point', coordinates: [-58.28829325, -34.00375798] },
      geometry_name: 'shape',
      properties: {
        portname: 'Carmelo',
        code: 'UYCAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.00376,
        longitude: -58.28829,
        iso3: 'URY',
        iso3_op: 'URY',
        country: 'Uruguay',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3443341,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16423',
      geometry: { type: 'Point', coordinates: [150.86110717, -23.58545897] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Alma',
        code: 'AUPTL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -23.58546,
        longitude: 150.86111,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2152687,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16424',
      geometry: { type: 'Point', coordinates: [-56.68704177, 5.76321976] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wageningen',
        code: 'SRAGI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 5.76322,
        longitude: -56.68704,
        iso3: 'SUR',
        iso3_op: 'SUR',
        country: 'Suriname',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3382783,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16425',
      geometry: { type: 'Point', coordinates: [123.84534963, 8.14077644] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ozamis',
        code: 'PHOZC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.14078,
        longitude: 123.84535,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1696899,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16426',
      geometry: { type: 'Point', coordinates: [74.2632716, 14.71107483] },
      geometry_name: 'shape',
      properties: {
        portname: 'Belekeri',
        code: 'INBLK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.71107,
        longitude: 74.26327,
        iso3: null,
        iso3_op: 'IND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1276544,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16427',
      geometry: { type: 'Point', coordinates: [120.40340404, 31.96409228] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zhangjiagang',
        code: 'CNZJG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.96409,
        longitude: 120.4034,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1785204,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16428',
      geometry: { type: 'Point', coordinates: [26.9761, 37.75633387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Samos',
        code: 'GRSMI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.75633,
        longitude: 26.9761,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 254115,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16429',
      geometry: { type: 'Point', coordinates: [-73.66312195, -37.59782448] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lebu',
        code: 'CLLEB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -37.59782,
        longitude: -73.66312,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3883457,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16430',
      geometry: { type: 'Point', coordinates: [-38.47627044, -12.79052674] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aratu',
        code: 'BRARB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.79053,
        longitude: -38.47627,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3471705,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16431',
      geometry: { type: 'Point', coordinates: [103.73605298, 1.45978089] },
      geometry_name: 'shape',
      properties: {
        portname: 'Johor Bahru',
        code: 'MYJHB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.45978,
        longitude: 103.73605,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1732752,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16432',
      geometry: { type: 'Point', coordinates: [110.3679788, 1.5550858] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kuching',
        code: 'MYKCH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.55509,
        longitude: 110.36798,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1734470,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16433',
      geometry: { type: 'Point', coordinates: [115.00875266, 4.75263819] },
      geometry_name: 'shape',
      properties: {
        portname: 'Limbang',
        code: 'MYLMN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.75264,
        longitude: 115.00875,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1737714,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16434',
      geometry: { type: 'Point', coordinates: [-68.27460148, 10.92303847] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chichiriviche',
        code: 'VECHV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.92304,
        longitude: -68.2746,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3645806,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16435',
      geometry: { type: 'Point', coordinates: [123.29906051, 14.05485304] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tandoc',
        code: 'PHTDC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.05485,
        longitude: 123.29906,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1683281,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16436',
      geometry: { type: 'Point', coordinates: [74.44106449, 14.27594313] },
      geometry_name: 'shape',
      properties: {
        portname: 'Honavar',
        code: 'INHON',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.27594,
        longitude: 74.44106,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1269976,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16437',
      geometry: { type: 'Point', coordinates: [121.19531699, 13.42604924] },
      geometry_name: 'shape',
      properties: {
        portname: 'Calapan',
        code: 'PHCLP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 13.42605,
        longitude: 121.19532,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1720561,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16438',
      geometry: { type: 'Point', coordinates: [70.03156468, 22.54896386] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bedi',
        code: 'INBED',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.54896,
        longitude: 70.03156,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1276627,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16439',
      geometry: { type: 'Point', coordinates: [121.76065463, 25.15540779] },
      geometry_name: 'shape',
      properties: {
        portname: 'Keelung',
        code: 'TWKEL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.15541,
        longitude: 121.76065,
        iso3: 'TWN',
        iso3_op: 'TWN',
        country: 'Taiwan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1678228,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16440',
      geometry: { type: 'Point', coordinates: [121.55538744, 29.87347126] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ningbo',
        code: 'CNNGB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 29.87347,
        longitude: 121.55539,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1799397,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16441',
      geometry: { type: 'Point', coordinates: [130.176334, 32.59368547] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kuchinotsu',
        code: 'JPKUC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.59369,
        longitude: 130.17633,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1858512,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16442',
      geometry: { type: 'Point', coordinates: [133.44268275, 34.452302] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fukuyama',
        code: 'JPFKY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.4523,
        longitude: 133.44268,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1863917,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16443',
      geometry: { type: 'Point', coordinates: [138.94885793, 34.670028] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shimoda',
        code: 'JPSMD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.67003,
        longitude: 138.94886,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1852357,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16444',
      geometry: { type: 'Point', coordinates: [128.57469154, 35.19631953] },
      geometry_name: 'shape',
      properties: {
        portname: 'Masan',
        code: 'KRMAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.19632,
        longitude: 128.57469,
        iso3: 'KOR',
        iso3_op: 'KOR',
        country: 'Republic of Korea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1841245,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16445',
      geometry: { type: 'Point', coordinates: [129.36424369, 35.45739468] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ulsan',
        code: 'KRUSN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.45739,
        longitude: 129.36424,
        iso3: 'KOR',
        iso3_op: 'KOR',
        country: 'Republic of Korea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1833747,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16446',
      geometry: { type: 'Point', coordinates: [126.54439557, 35.96501739] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kunsan',
        code: 'KRKUV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.96502,
        longitude: 126.5444,
        iso3: null,
        iso3_op: 'KOR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1842025,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16447',
      geometry: { type: 'Point', coordinates: [120.30815353, 37.66872565] },
      geometry_name: 'shape',
      properties: {
        portname: 'Longkou',
        code: 'CNLKU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.66873,
        longitude: 120.30815,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1802550,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16448',
      geometry: { type: 'Point', coordinates: [29.10295499, 40.94778471] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maltepe',
        code: 'TRMAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.94778,
        longitude: 29.10295,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 741763,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16449',
      geometry: { type: 'Point', coordinates: [22.01451192, 60.47054685] },
      geometry_name: 'shape',
      properties: {
        portname: 'Naantali',
        code: 'FINLI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.47055,
        longitude: 22.01451,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 645211,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16450',
      geometry: { type: 'Point', coordinates: [-72.90776578, 11.55311199] },
      geometry_name: 'shape',
      properties: {
        portname: 'Riohacha',
        code: 'CORCH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.55311,
        longitude: -72.90777,
        iso3: 'COL',
        iso3_op: 'COL',
        country: 'Colombia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3670745,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16451',
      geometry: { type: 'Point', coordinates: [24.42115418, 64.6601889] },
      geometry_name: 'shape',
      properties: {
        portname: 'Raahe',
        code: 'FIRAA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.66019,
        longitude: 24.42115,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 640276,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16452',
      geometry: { type: 'Point', coordinates: [70.98685469, 20.71907349] },
      geometry_name: 'shape',
      properties: {
        portname: 'Diu',
        code: 'INDIU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.71907,
        longitude: 70.98685,
        iso3: null,
        iso3_op: 'IND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1272502,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16453',
      geometry: { type: 'Point', coordinates: [113.12346068, 22.60356444] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jiangmen',
        code: 'CNJMN',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.60356,
        longitude: 113.12346,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1806299,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16454',
      geometry: { type: 'Point', coordinates: [31.42241794, 41.26813903] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eregli',
        code: 'TRELI',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.26814,
        longitude: 31.42242,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 747471,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16455',
      geometry: { type: 'Point', coordinates: [86.16852105, 69.39973535] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dudinka',
        code: 'RUDUD',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 69.39974,
        longitude: 86.16852,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1507116,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16456',
      geometry: { type: 'Point', coordinates: [149.04379605, -5.49099634] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gilau',
        code: 'PGGIL',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.491,
        longitude: 149.0438,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2096967,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16457',
      geometry: { type: 'Point', coordinates: [29.6424757, 36.19824436] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kas',
        code: 'TRKAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.19824,
        longitude: 29.64248,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 308948,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16458',
      geometry: { type: 'Point', coordinates: [-96.13951165, 19.20581482] },
      geometry_name: 'shape',
      properties: {
        portname: 'Veracruz',
        code: 'MXVER',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.20581,
        longitude: -96.13951,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3514783,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16459',
      geometry: { type: 'Point', coordinates: [-59.99738696, -3.15375689] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manaus',
        code: 'BRMAO',
        prttype: 'River',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.15376,
        longitude: -59.99739,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3663517,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16460',
      geometry: { type: 'Point', coordinates: [-64.55885508, 10.24251359] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pertigalete',
        code: 'VEPRG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.24251,
        longitude: -64.55886,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3630321,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16461',
      geometry: { type: 'Point', coordinates: [-92.39724482, 14.70560868] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Madero',
        code: 'MXPMD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.70561,
        longitude: -92.39724,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3520989,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16462',
      geometry: { type: 'Point', coordinates: [-105.24505342, 20.66067005] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Vallarta',
        code: 'MXPVR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.66067,
        longitude: -105.24505,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3991328,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16463',
      geometry: { type: 'Point', coordinates: [27.26469012, 37.87015918] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kusadasi',
        code: 'TRKUS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.87016,
        longitude: 27.26469,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 305359,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16464',
      geometry: { type: 'Point', coordinates: [31.7878129, 41.45390908] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zonguldak',
        code: 'TRZON',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.45391,
        longitude: 31.78781,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 737022,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16465',
      geometry: { type: 'Point', coordinates: [-73.16792203, -37.02491924] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coronel',
        code: 'CLCNL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -37.02492,
        longitude: -73.16792,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3893532,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16466',
      geometry: { type: 'Point', coordinates: [-73.15953159, -37.09771464] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lota',
        code: 'CLLTA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -37.09771,
        longitude: -73.15953,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3881276,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16467',
      geometry: { type: 'Point', coordinates: [28.27674175, 36.85310805] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marmaris',
        code: 'TRMRM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.85311,
        longitude: 28.27674,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 304782,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16468',
      geometry: { type: 'Point', coordinates: [-84.1687, 9.42403387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quepos',
        code: 'CRXQP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.42403,
        longitude: -84.1687,
        iso3: 'CRI',
        iso3_op: 'CRI',
        country: 'Costa Rica',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3622193,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16469',
      geometry: { type: 'Point', coordinates: [-94.53473222, 17.97553033] },
      geometry_name: 'shape',
      properties: {
        portname: 'Minatitlan',
        code: 'MXMTT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.97553,
        longitude: -94.53473,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3523183,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16470',
      geometry: { type: 'Point', coordinates: [29.92893357, 40.76026693] },
      geometry_name: 'shape',
      properties: {
        portname: 'Izmit',
        code: 'TRIZT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.76027,
        longitude: 29.92893,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 745028,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16471',
      geometry: { type: 'Point', coordinates: [-57.64372517, -25.27471988] },
      geometry_name: 'shape',
      properties: {
        portname: 'Asuncion',
        code: 'PYASU',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -25.27472,
        longitude: -57.64373,
        iso3: 'PRY',
        iso3_op: 'PRY',
        country: 'Paraguay',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3439389,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16472',
      geometry: { type: 'Point', coordinates: [-72.50030623, -51.7373533] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Natales',
        code: 'CLPNT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -51.73735,
        longitude: -72.50031,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3874958,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16473',
      geometry: { type: 'Point', coordinates: [48.98017734, -19.32419038] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vatomandry',
        code: 'MGVAT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -19.32419,
        longitude: 48.98018,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1054257,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16474',
      geometry: { type: 'Point', coordinates: [-3.0227478, 53.46171864] },
      geometry_name: 'shape',
      properties: {
        portname: 'Liverpool',
        code: 'GBLIV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.46172,
        longitude: -3.02275,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2644210,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16475',
      geometry: { type: 'Point', coordinates: [-2.27518379, 53.46689287] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manchester',
        code: 'GBMNC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.46689,
        longitude: -2.27518,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2643123,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16476',
      geometry: { type: 'Point', coordinates: [-65.03806473, -42.76489509] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Madryn',
        code: 'ARPMY',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -42.7649,
        longitude: -65.03806,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3840092,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16477',
      geometry: { type: 'Point', coordinates: [80.01956628, 9.65036709] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jaffna',
        code: 'LKJAF',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.65037,
        longitude: 80.01957,
        iso3: 'LKA',
        iso3_op: 'LKA',
        country: 'Sri Lanka',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1242833,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16478',
      geometry: { type: 'Point', coordinates: [7.15209961, 4.68387645] },
      geometry_name: 'shape',
      properties: {
        portname: 'Onne',
        code: 'NGONN',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.68388,
        longitude: 7.1521,
        iso3: 'NGA',
        iso3_op: 'NGA',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2325987,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16479',
      geometry: { type: 'Point', coordinates: [100.37084495, -1.0001698] },
      geometry_name: 'shape',
      properties: {
        portname: 'Padang',
        code: 'IDPDG',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.00017,
        longitude: 100.37084,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1633419,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16480',
      geometry: { type: 'Point', coordinates: [96.38228104, 2.47858653] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sinabang',
        code: 'IDSNG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.47859,
        longitude: 96.38228,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1213721,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16481',
      geometry: { type: 'Point', coordinates: [114.38446827, -8.20595908] },
      geometry_name: 'shape',
      properties: {
        portname: 'Banyuwangi',
        code: 'IDBUG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.20596,
        longitude: 114.38447,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1650077,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16482',
      geometry: { type: 'Point', coordinates: [110.42514571, -6.94670418] },
      geometry_name: 'shape',
      properties: {
        portname: 'Semarang',
        code: 'IDSRG',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.9467,
        longitude: 110.42515,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1627896,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16483',
      geometry: { type: 'Point', coordinates: [128.17626916, -3.69410436] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ambon',
        code: 'IDAMQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.6941,
        longitude: 128.17627,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1651531,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16484',
      geometry: { type: 'Point', coordinates: [122.57728527, -3.97244641] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kendari',
        code: 'IDKDI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.97245,
        longitude: 122.57729,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1640344,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16485',
      geometry: { type: 'Point', coordinates: [124.84052646, 1.49880876] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manado',
        code: 'IDMDC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.49881,
        longitude: 124.84053,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1636544,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16486',
      geometry: { type: 'Point', coordinates: [117.98446422, 0.98721488] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sangkulirang',
        code: 'IDSKI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.98721,
        longitude: 117.98446,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1628757,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16487',
      geometry: { type: 'Point', coordinates: [-83.75967997, 12.01143258] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bluefields',
        code: 'NIBEF',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.01143,
        longitude: -83.75968,
        iso3: 'NIC',
        iso3_op: 'NIC',
        country: 'Nicaragua',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3620680,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16488',
      geometry: { type: 'Point', coordinates: [-70.70183557, 19.79850387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Plata',
        code: 'DOPOP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.7985,
        longitude: -70.70184,
        iso3: 'DOM',
        iso3_op: 'DOM',
        country: 'Dominican Republic',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3493175,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16489',
      geometry: { type: 'Point', coordinates: [-72.01563393, -17.0311389] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mollendo',
        code: 'PEMLQ',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.03114,
        longitude: -72.01563,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3934707,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16490',
      geometry: { type: 'Point', coordinates: [-73.61796699, -16.2304655] },
      geometry_name: 'shape',
      properties: {
        portname: 'Atico',
        code: 'PEATI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.23047,
        longitude: -73.61797,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3947150,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16491',
      geometry: { type: 'Point', coordinates: [-78.17118249, -10.09737581] },
      geometry_name: 'shape',
      properties: {
        portname: 'Huarmey',
        code: 'PEHUY',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -10.09738,
        longitude: -78.17118,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3939168,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16492',
      geometry: { type: 'Point', coordinates: [-22.98276297, 16.75424751] },
      geometry_name: 'shape',
      properties: {
        portname: 'Palmeira',
        code: 'CVPAL',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.75425,
        longitude: -22.98276,
        iso3: 'CPV',
        iso3_op: 'CPV',
        country: 'Cape Verde',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3374403,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16493',
      geometry: { type: 'Point', coordinates: [57.49566535, -20.15276382] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Louis',
        code: 'MUPLU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.15276,
        longitude: 57.49567,
        iso3: 'MUS',
        iso3_op: 'MUS',
        country: 'Mauritius',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 934154,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16494',
      geometry: { type: 'Point', coordinates: [6.73686238, 0.34630317] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sao Tome',
        code: 'STTMS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 0.3463,
        longitude: 6.73686,
        iso3: null,
        iso3_op: 'STP',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2410763,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16495',
      geometry: { type: 'Point', coordinates: [9.50073482, 53.92447248] },
      geometry_name: 'shape',
      properties: {
        portname: 'Itzehoe',
        code: 'DEITZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.92447,
        longitude: 9.50073,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2895569,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16496',
      geometry: { type: 'Point', coordinates: [9.29616708, 54.01916747] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hochdonn',
        code: 'DEHOD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.01917,
        longitude: 9.29617,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2903298,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16497',
      geometry: { type: 'Point', coordinates: [13.452278, 54.09729346] },
      geometry_name: 'shape',
      properties: {
        portname: 'Greifswald',
        code: 'DEGRD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.09729,
        longitude: 13.45228,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2917788,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16498',
      geometry: { type: 'Point', coordinates: [-3.59130513, 54.54851018] },
      geometry_name: 'shape',
      properties: {
        portname: 'Whitehaven',
        code: 'GBWHV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.54851,
        longitude: -3.59131,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2634096,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16499',
      geometry: { type: 'Point', coordinates: [9.42670112, 55.03933387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aabenraa',
        code: 'DKAAB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.03933,
        longitude: 9.4267,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2625070,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16500',
      geometry: { type: 'Point', coordinates: [6.65542374, 58.2953566] },
      geometry_name: 'shape',
      properties: {
        portname: 'Flekkefjord',
        code: 'NOFFD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.29536,
        longitude: 6.65542,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3157090,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16501',
      geometry: { type: 'Point', coordinates: [11.28999214, 55.25289663] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skaelskor',
        code: 'DKSSK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.2529,
        longitude: 11.28999,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2613694,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16502',
      geometry: { type: 'Point', coordinates: [21.09380808, 55.72878314] },
      geometry_name: 'shape',
      properties: {
        portname: 'Klaipeda',
        code: 'LTKLJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.72878,
        longitude: 21.09381,
        iso3: 'LTU',
        iso3_op: 'LTU',
        country: 'Lithuania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 598098,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16503',
      geometry: { type: 'Point', coordinates: [15.30160109, 56.17552591] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ronneby',
        code: 'SERNB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.17553,
        longitude: 15.3016,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2681825,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16504',
      geometry: { type: 'Point', coordinates: [18.19327494, 57.38793673] },
      geometry_name: 'shape',
      properties: {
        portname: 'Klintehamn',
        code: 'SEKLI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.38794,
        longitude: 18.19327,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2700497,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16505',
      geometry: { type: 'Point', coordinates: [-3.0844444, 58.43905911] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wick',
        code: 'GBWIC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.43906,
        longitude: -3.08444,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2633982,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16506',
      geometry: { type: 'Point', coordinates: [17.1419936, 60.94345441] },
      geometry_name: 'shape',
      properties: {
        portname: 'Norrsundet',
        code: 'SENOT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.94345,
        longitude: 17.14199,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2688255,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16507',
      geometry: { type: 'Point', coordinates: [9.84125514, 63.31525651] },
      geometry_name: 'shape',
      properties: {
        portname: 'Orkanger',
        code: 'NOORK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.31526,
        longitude: 9.84126,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3143423,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16508',
      geometry: { type: 'Point', coordinates: [11.23804954, 64.07731843] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malm',
        code: 'NOMLM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 64.07732,
        longitude: 11.23805,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3146508,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16509',
      geometry: { type: 'Point', coordinates: [9.24833248, 56.96705299] },
      geometry_name: 'shape',
      properties: {
        portname: 'Logstor',
        code: 'DKLGR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.96705,
        longitude: 9.24833,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2617467,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16510',
      geometry: { type: 'Point', coordinates: [113.26581722, 23.11757037] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guangzhou',
        code: 'CNCAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.11757,
        longitude: 113.26582,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1809858,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16511',
      geometry: { type: 'Point', coordinates: [-82.94679467, 41.51745763] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Clinton',
        code: 'USPTT',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.51746,
        longitude: -82.94679,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5167259,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16512',
      geometry: { type: 'Point', coordinates: [-87.7801633, 42.73182377] },
      geometry_name: 'shape',
      properties: {
        portname: 'Racine',
        code: 'USRAC',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.73182,
        longitude: -87.78016,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5268249,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16513',
      geometry: { type: 'Point', coordinates: [-82.41283794, 42.9853733] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sarnia',
        code: 'CASNI',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.98537,
        longitude: -82.41284,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6141190,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16514',
      geometry: { type: 'Point', coordinates: [-90.88477341, 46.59293369] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ashland',
        code: 'USASX',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.59293,
        longitude: -90.88477,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5244247,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16515',
      geometry: { type: 'Point', coordinates: [99.5783289, 11.20683032] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bang Saphan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.20683,
        longitude: 99.57833,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1157662,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16516',
      geometry: { type: 'Point', coordinates: [-81.71721654, 43.7468774] },
      geometry_name: 'shape',
      properties: {
        portname: 'Goderich',
        code: 'CAGOH',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.74688,
        longitude: -81.71722,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5962442,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16517',
      geometry: { type: 'Point', coordinates: [-76.48210802, 44.22580081] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kingston',
        code: 'CAKIN',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.2258,
        longitude: -76.48211,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5992500,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16518',
      geometry: { type: 'Point', coordinates: [-91.20152104, 29.68953218] },
      geometry_name: 'shape',
      properties: {
        portname: 'Morgan City',
        code: 'USMGN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.68953,
        longitude: -91.20152,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4333811,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16519',
      geometry: { type: 'Point', coordinates: [35.65491742, 34.22684668] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kfar Aabida',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.22685,
        longitude: 35.65492,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 272807,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16520',
      geometry: { type: 'Point', coordinates: [-87.2098612, 30.40444126] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pensacola',
        code: 'USPNS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.40444,
        longitude: -87.20986,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4168228,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16521',
      geometry: { type: 'Point', coordinates: [-3.98229044, 48.72413658] },
      geometry_name: 'shape',
      properties: {
        portname: 'Roscoff',
        code: 'FRROS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.72414,
        longitude: -3.98229,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2982809,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16522',
      geometry: { type: 'Point', coordinates: [25.75010715, -2.53715342] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lokandu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.53715,
        longitude: 25.75011,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 211572,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16523',
      geometry: { type: 'Point', coordinates: [25.71752802, -2.25936687] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kailenge',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -2.25937,
        longitude: 25.71753,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 215209,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16524',
      geometry: { type: 'Point', coordinates: [117.82946115, 8.77352843] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brookes Point',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.77353,
        longitude: 117.82946,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1723763,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16525',
      geometry: { type: 'Point', coordinates: [20.03957361, 32.10398736] },
      geometry_name: 'shape',
      properties: {
        portname: 'Benghazi',
        code: 'LYBEN',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 32.10399,
        longitude: 20.03957,
        iso3: null,
        iso3_op: 'LBY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 88319,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16526',
      geometry: { type: 'Point', coordinates: [98.60073316, 9.98089309] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ranong',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.98089,
        longitude: 98.60073,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1150965,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16527',
      geometry: { type: 'Point', coordinates: [-90.06691203, 29.91803722] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gretna',
        code: 'USGTL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.91804,
        longitude: -90.06691,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4326575,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16528',
      geometry: { type: 'Point', coordinates: [-71.05551065, 48.43055126] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chicoutimi',
        code: 'CACHI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.43055,
        longitude: -71.05551,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5921225,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16529',
      geometry: { type: 'Point', coordinates: [-53.22400803, 47.73896516] },
      geometry_name: 'shape',
      properties: {
        portname: 'Carbonear',
        code: 'CACRB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.73897,
        longitude: -53.22401,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5916722,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16530',
      geometry: { type: 'Point', coordinates: [124.15008458, 24.34262174] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ishigaki',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.34262,
        longitude: 124.15008,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1861416,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16531',
      geometry: { type: 'Point', coordinates: [25.81564069, -1.65472617] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kowe',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.65473,
        longitude: 25.81564,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 212301,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16532',
      geometry: { type: 'Point', coordinates: [17.9629784, 40.64479475] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brindisi',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.64479,
        longitude: 17.96298,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3181528,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16533',
      geometry: { type: 'Point', coordinates: [-4.23189791, 57.49350358] },
      geometry_name: 'shape',
      properties: {
        portname: 'Inverness',
        code: 'GBINV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.4935,
        longitude: -4.2319,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2646088,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16534',
      geometry: { type: 'Point', coordinates: [16.52705517, 57.59003661] },
      geometry_name: 'shape',
      properties: {
        portname: 'Blankaholm',
        code: 'SEBLA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.59004,
        longitude: 16.52706,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2721454,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16535',
      geometry: { type: 'Point', coordinates: [11.81990754, 58.08751836] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stenungsund',
        code: 'SESTE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.08752,
        longitude: 11.81991,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2673875,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16536',
      geometry: { type: 'Point', coordinates: [8.0173, 58.13993387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kristiansand',
        code: 'NOKRS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.13993,
        longitude: 8.0173,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3149318,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16537',
      geometry: { type: 'Point', coordinates: [25.37429541, 37.10750546] },
      geometry_name: 'shape',
      properties: {
        portname: 'Naxos',
        code: 'GRJNX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.10751,
        longitude: 25.3743,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 256632,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16538',
      geometry: { type: 'Point', coordinates: [12.24178395, 44.6771525] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porto Garibaldi',
        code: 'ITPGA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.67715,
        longitude: 12.24178,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3170087,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16539',
      geometry: { type: 'Point', coordinates: [16.44207884, 43.5051021] },
      geometry_name: 'shape',
      properties: {
        portname: 'Split',
        code: 'HRSPU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.5051,
        longitude: 16.44208,
        iso3: 'HRV',
        iso3_op: 'HRV',
        country: 'Croatia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3190261,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16540',
      geometry: { type: 'Point', coordinates: [15.22584551, 44.11750527] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zadar',
        code: 'HRZAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.11751,
        longitude: 15.22585,
        iso3: 'HRV',
        iso3_op: 'HRV',
        country: 'Croatia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3186952,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16541',
      geometry: { type: 'Point', coordinates: [20.97473695, 64.15875678] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sikea',
        code: 'SESIK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.15876,
        longitude: 20.97474,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 602974,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16542',
      geometry: { type: 'Point', coordinates: [16.58326576, 41.20734739] },
      geometry_name: 'shape',
      properties: {
        portname: 'Molfetta',
        code: 'ITMOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.20735,
        longitude: 16.58327,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3173287,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16543',
      geometry: { type: 'Point', coordinates: [15.91218433, 41.62395129] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manfredonia',
        code: 'ITMFR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.62395,
        longitude: 15.91218,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3174092,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16544',
      geometry: { type: 'Point', coordinates: [14.71007438, 42.17199296] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vasto',
        code: 'ITVSO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.17199,
        longitude: 14.71007,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3164672,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16545',
      geometry: { type: 'Point', coordinates: [-9.18990768, 42.94446637] },
      geometry_name: 'shape',
      properties: {
        portname: 'Corcubion',
        code: 'ESCCN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.94447,
        longitude: -9.18991,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3124614,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16546',
      geometry: { type: 'Point', coordinates: [25.92924733, -2.94715748] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kindu',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -2.94716,
        longitude: 25.92925,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 212902,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16547',
      geometry: { type: 'Point', coordinates: [14.75147529, 40.6760688] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salerno',
        code: 'ITSAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.67607,
        longitude: 14.75148,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3168673,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16548',
      geometry: { type: 'Point', coordinates: [-1.5681, 47.20643387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nantes',
        code: 'FRNTE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.20643,
        longitude: -1.5681,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2990969,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16549',
      geometry: { type: 'Point', coordinates: [-3.10029441, 48.7899573] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lezardrieux',
        code: 'FRLEZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.78996,
        longitude: -3.10029,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2998663,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16550',
      geometry: { type: 'Point', coordinates: [14.44400795, 40.75363766] },
      geometry_name: 'shape',
      properties: {
        portname: 'Torre Annunziata',
        code: 'ITTOA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.75364,
        longitude: 14.44401,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3165475,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16551',
      geometry: { type: 'Point', coordinates: [5.3643026, 43.3078633] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marseille',
        code: 'FRMRS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.30786,
        longitude: 5.3643,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2995469,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16552',
      geometry: { type: 'Point', coordinates: [-1.50964472, 55.1303525] },
      geometry_name: 'shape',
      properties: {
        portname: 'Blyth',
        code: 'GBBLY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.13035,
        longitude: -1.50964,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2655315,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16553',
      geometry: { type: 'Point', coordinates: [-3.42797677, 56.38171579] },
      geometry_name: 'shape',
      properties: {
        portname: 'Perth',
        code: 'GBPER',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.38172,
        longitude: -3.42798,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2640358,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16554',
      geometry: { type: 'Point', coordinates: [24.76385316, 59.44703384] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tallinn',
        code: 'EETLL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.44703,
        longitude: 24.76385,
        iso3: 'EST',
        iso3_op: 'EST',
        country: 'Estonia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 588409,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16555',
      geometry: { type: 'Point', coordinates: [5.93281842, 59.73337963] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skanevik',
        code: 'NOSEI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.73338,
        longitude: 5.93282,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3139380,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16556',
      geometry: { type: 'Point', coordinates: [141.7996788, 40.19424951] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kuji',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.19425,
        longitude: 141.79968,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2129427,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16557',
      geometry: { type: 'Point', coordinates: [12.2071305, 65.47302001] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bronnoysund',
        code: 'NOBNN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.47302,
        longitude: 12.20713,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3159954,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16558',
      geometry: { type: 'Point', coordinates: [28.88450314, 61.86784867] },
      geometry_name: 'shape',
      properties: {
        portname: 'Savonlinna',
        code: 'FISVL',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.86785,
        longitude: 28.8845,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 637292,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16559',
      geometry: { type: 'Point', coordinates: [20.14164962, -0.46735625] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bomputu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.46736,
        longitude: 20.14165,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 218269,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16560',
      geometry: { type: 'Point', coordinates: [25.87529078, -2.74198763] },
      geometry_name: 'shape',
      properties: {
        portname: 'Elila',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -2.74199,
        longitude: 25.87529,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 216757,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16561',
      geometry: { type: 'Point', coordinates: [28.88247279, -8.48123761] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pweto',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -8.48124,
        longitude: 28.88247,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 206113,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16562',
      geometry: { type: 'Point', coordinates: [25.85927473, -9.19494347] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bukama',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -9.19494,
        longitude: 25.85927,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 217834,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16563',
      geometry: { type: 'Point', coordinates: [18.27213292, -1.93268973] },
      geometry_name: 'shape',
      properties: {
        portname: 'Inongo',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -1.93269,
        longitude: 18.27213,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2315417,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16564',
      geometry: { type: 'Point', coordinates: [105.71074719, 18.82878451] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cua Lo',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.82878,
        longitude: 105.71075,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1584601,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16565',
      geometry: { type: 'Point', coordinates: [-76.08903443, 44.23612327] },
      geometry_name: 'shape',
      properties: {
        portname: 'Clayton',
        code: 'USCLY',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.23612,
        longitude: -76.08903,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5112870,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16566',
      geometry: { type: 'Point', coordinates: [124.284352, 12.504558] },
      geometry_name: 'shape',
      properties: {
        portname: 'Allen',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 12.50456,
        longitude: 124.28435,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1731340,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16567',
      geometry: { type: 'Point', coordinates: [123.87037162, 10.83156503] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tabuelan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.83157,
        longitude: 123.87037,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1684821,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16568',
      geometry: { type: 'Point', coordinates: [44.53905619, -19.71319066] },
      geometry_name: 'shape',
      properties: {
        portname: 'Belo Tsiribihina',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -19.71319,
        longitude: 44.53906,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1067531,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16569',
      geometry: { type: 'Point', coordinates: [48.78403548, -19.99192894] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salehy',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -19.99193,
        longitude: 48.78404,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1056947,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16570',
      geometry: { type: 'Point', coordinates: [-87.61847837, 45.1000822] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marinette',
        code: 'USMOY',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.10008,
        longitude: -87.61848,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5261852,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16571',
      geometry: { type: 'Point', coordinates: [-123.1112136, 49.28625939] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vancouver',
        code: 'CAVAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.28626,
        longitude: -123.11121,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6173331,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16572',
      geometry: { type: 'Point', coordinates: [-84.46838033, 45.65374205] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cheboygan',
        code: 'USCHG',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.65374,
        longitude: -84.46838,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4988617,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16573',
      geometry: { type: 'Point', coordinates: [24.21443501, -1.99190418] },
      geometry_name: 'shape',
      properties: {
        portname: 'Elingampangu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -1.9919,
        longitude: 24.21444,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 216746,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16574',
      geometry: { type: 'Point', coordinates: [20.33703265, -1.17543201] },
      geometry_name: 'shape',
      properties: {
        portname: 'Besow',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.17543,
        longitude: 20.33703,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 219023,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16575',
      geometry: { type: 'Point', coordinates: [22.30611705, -0.61676031] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bokungu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.61676,
        longitude: 22.30612,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 218479,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16576',
      geometry: { type: 'Point', coordinates: [-79.25717767, 42.9722369] },
      geometry_name: 'shape',
      properties: {
        portname: 'Welland',
        code: 'CAWEL',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 42.97224,
        longitude: -79.25718,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6177869,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16577',
      geometry: { type: 'Point', coordinates: [20.73645003, 0.7344953] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baringa',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.7345,
        longitude: 20.73645,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 219480,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16578',
      geometry: { type: 'Point', coordinates: [24.27284663, 0.78496492] },
      geometry_name: 'shape',
      properties: {
        portname: 'Isangi',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.78496,
        longitude: 24.27285,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 215800,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16579',
      geometry: { type: 'Point', coordinates: [22.7801, -1.86086613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bokwankusu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -1.86087,
        longitude: 22.7801,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 218467,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16580',
      geometry: { type: 'Point', coordinates: [-62.53707982, 46.17942349] },
      geometry_name: 'shape',
      properties: {
        portname: 'Georgetown',
        code: 'CAGGW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.17942,
        longitude: -62.53708,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5960531,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16581',
      geometry: { type: 'Point', coordinates: [-126.12555743, 49.68020045] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gold River',
        code: 'CAGOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.6802,
        longitude: -126.12556,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5962735,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16582',
      geometry: { type: 'Point', coordinates: [-146.3416, 61.12833387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Valdez',
        code: 'USVDZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.12833,
        longitude: -146.3416,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5877389,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16583',
      geometry: { type: 'Point', coordinates: [-93.94013254, 29.99235053] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Neches',
        code: 'USPON',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.99235,
        longitude: -93.94013,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4720072,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16584',
      geometry: { type: 'Point', coordinates: [-90.67903825, 30.04747071] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gramercy',
        code: 'USGRY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.04747,
        longitude: -90.67904,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4326081,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16585',
      geometry: { type: 'Point', coordinates: [-90.56521826, 30.0554276] },
      geometry_name: 'shape',
      properties: {
        portname: 'Reserve',
        code: 'USRSF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.05543,
        longitude: -90.56522,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4338615,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16586',
      geometry: { type: 'Point', coordinates: [-91.0288, 30.20453387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Geismar',
        code: 'USGSM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.20453,
        longitude: -91.0288,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4325565,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16587',
      geometry: { type: 'Point', coordinates: [-70.92008426, 41.63466186] },
      geometry_name: 'shape',
      properties: {
        portname: 'New Bedford',
        code: 'USEWB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.63466,
        longitude: -70.92008,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4945121,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16588',
      geometry: { type: 'Point', coordinates: [-70.96833418, 42.24621688] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quincy',
        code: 'USMQI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.24622,
        longitude: -70.96833,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4948247,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16589',
      geometry: { type: 'Point', coordinates: [-75.20631599, 44.86534085] },
      geometry_name: 'shape',
      properties: {
        portname: 'Waddington',
        code: 'USWAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.86534,
        longitude: -75.20632,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5143016,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16590',
      geometry: { type: 'Point', coordinates: [-64.77247479, 46.0885641] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moncton',
        code: 'CAMNT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.08856,
        longitude: -64.77247,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6076211,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16591',
      geometry: { type: 'Point', coordinates: [-122.6368, 47.54226761] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Orchard',
        code: 'USPXO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.54227,
        longitude: -122.6368,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5807236,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16592',
      geometry: { type: 'Point', coordinates: [-89.55241215, 29.37367829] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ostrica',
        code: 'USOST',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.37368,
        longitude: -89.55241,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4336211,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16593',
      geometry: { type: 'Point', coordinates: [-122.12967674, 38.02610823] },
      geometry_name: 'shape',
      properties: {
        portname: 'Martinez',
        code: 'USMRZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.02611,
        longitude: -122.12968,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5370868,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16594',
      geometry: { type: 'Point', coordinates: [-77.45349576, 38.29658104] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fredericksburg',
        code: 'USFDB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 38.29658,
        longitude: -77.4535,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4760059,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16595',
      geometry: { type: 'Point', coordinates: [-70.65854522, 41.74149328] },
      geometry_name: 'shape',
      properties: {
        portname: 'Onset',
        code: 'USONL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.74149,
        longitude: -70.65855,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4946372,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16596',
      geometry: { type: 'Point', coordinates: [-70.50424916, 41.77005231] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sandwich',
        code: 'USSWC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.77005,
        longitude: -70.50425,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4950191,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16597',
      geometry: { type: 'Point', coordinates: [-75.48625156, 44.70787427] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ogdensburg',
        code: 'USOGS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.70787,
        longitude: -75.48625,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5129626,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16598',
      geometry: { type: 'Point', coordinates: [-123.8190465, 48.99935665] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ladysmith',
        code: 'CALAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.99936,
        longitude: -123.81905,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6047817,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16599',
      geometry: { type: 'Point', coordinates: [-124.5258, 49.83592682] },
      geometry_name: 'shape',
      properties: {
        portname: 'Powell River',
        code: 'CAPOW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.83593,
        longitude: -124.5258,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6112608,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16600',
      geometry: { type: 'Point', coordinates: [-5.9261928, 43.58164757] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aviles',
        code: 'ESAVS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.58165,
        longitude: -5.92619,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3129135,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16601',
      geometry: { type: 'Point', coordinates: [-60.20928992, 55.46162776] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hopedale',
        code: 'CAHOP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.46163,
        longitude: -60.20929,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6694966,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16602',
      geometry: { type: 'Point', coordinates: [-122.76067872, 48.1129225] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Townsend',
        code: 'USTWD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.11292,
        longitude: -122.76068,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5807239,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16603',
      geometry: { type: 'Point', coordinates: [12.67408788, 55.59398538] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dragor',
        code: 'DKDRA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.59399,
        longitude: 12.67409,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2622937,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16604',
      geometry: { type: 'Point', coordinates: [-3.39242682, 54.86684816] },
      geometry_name: 'shape',
      properties: {
        portname: 'Silloth',
        code: 'GBSIL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.86685,
        longitude: -3.39243,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2637836,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16605',
      geometry: { type: 'Point', coordinates: [-4.81728975, 55.64036038] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ardrossan',
        code: 'GBARD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.64036,
        longitude: -4.81729,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2657125,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16606',
      geometry: { type: 'Point', coordinates: [15.08961997, 37.49078875] },
      geometry_name: 'shape',
      properties: {
        portname: 'Catania',
        code: 'ITCTA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.49079,
        longitude: 15.08962,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2525068,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16607',
      geometry: { type: 'Point', coordinates: [23.35346419, 37.98236748] },
      geometry_name: 'shape',
      properties: {
        portname: 'Megara',
        code: 'GRMGR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 37.98237,
        longitude: 23.35346,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 257365,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16608',
      geometry: { type: 'Point', coordinates: [27.45531328, 42.48833684] },
      geometry_name: 'shape',
      properties: {
        portname: 'Burgas',
        code: 'BGBOJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.48834,
        longitude: 27.45531,
        iso3: 'BGR',
        iso3_op: 'BGR',
        country: 'Bulgaria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 732770,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16609',
      geometry: { type: 'Point', coordinates: [12.30950269, 45.43856978] },
      geometry_name: 'shape',
      properties: {
        portname: 'Venice',
        code: 'ITVCE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.43857,
        longitude: 12.3095,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3164603,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16610',
      geometry: { type: 'Point', coordinates: [-80.79531128, 41.90417632] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ashtabula',
        code: 'USASF',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.90418,
        longitude: -80.79531,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5146089,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16611',
      geometry: { type: 'Point', coordinates: [-131.58160401, 55.12893387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Metlakatla',
        code: 'USMTM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.12893,
        longitude: -131.5816,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5555695,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16612',
      geometry: { type: 'Point', coordinates: [-159.16869308, 55.89930737] },
      geometry_name: 'shape',
      properties: {
        portname: 'Perryville',
        code: 'USPYX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.89931,
        longitude: -159.16869,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5871444,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16613',
      geometry: { type: 'Point', coordinates: [-76.4957284, 39.21405485] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sparrows Point',
        code: 'USSRT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.21405,
        longitude: -76.49573,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4370085,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16614',
      geometry: { type: 'Point', coordinates: [-124.18409243, 40.79747467] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eureka',
        code: 'USEKA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.79747,
        longitude: -124.18409,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5563397,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16615',
      geometry: { type: 'Point', coordinates: [-71.21265585, 41.62558464] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tiverton',
        code: 'USTIV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.62558,
        longitude: -71.21266,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5225245,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16616',
      geometry: { type: 'Point', coordinates: [-6.0353883, 35.46902696] },
      geometry_name: 'shape',
      properties: {
        portname: 'Asilah',
        code: 'MAASI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.46903,
        longitude: -6.03539,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2557055,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16617',
      geometry: { type: 'Point', coordinates: [29.05757941, 41.16715244] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sariyer',
        code: 'TRSRY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.16715,
        longitude: 29.05758,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 740006,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16618',
      geometry: { type: 'Point', coordinates: [101.81753872, 0.74602991] },
      geometry_name: 'shape',
      properties: {
        portname: 'Buatan',
        code: 'IDBUN',
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.74603,
        longitude: 101.81754,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1647983,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16619',
      geometry: { type: 'Point', coordinates: [10.32226667, 59.48843942] },
      geometry_name: 'shape',
      properties: {
        portname: 'Holmestrand',
        code: 'NOHOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.48844,
        longitude: 10.32227,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3152132,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16620',
      geometry: { type: 'Point', coordinates: [18.45835735, 60.17304849] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hargshamn',
        code: 'SEHAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.17305,
        longitude: 18.45836,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2707764,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16621',
      geometry: { type: 'Point', coordinates: [5.6334599, 62.34257608] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fosnavag',
        code: 'NOFOS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 62.34258,
        longitude: 5.63346,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3156680,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16622',
      geometry: { type: 'Point', coordinates: [21.4883633, 65.28134756] },
      geometry_name: 'shape',
      properties: {
        portname: 'Munksund',
        code: 'SEMUN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.28135,
        longitude: 21.48836,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 604231,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16623',
      geometry: { type: 'Point', coordinates: [17.42035677, 68.43075186] },
      geometry_name: 'shape',
      properties: {
        portname: 'Narvik',
        code: 'NONVK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 68.43075,
        longitude: 17.42036,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3144987,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16624',
      geometry: { type: 'Point', coordinates: [12.28248784, 44.48619716] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ravenna',
        code: 'ITRAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.4862,
        longitude: 12.28249,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3169561,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16625',
      geometry: { type: 'Point', coordinates: [-0.53828713, 44.90720411] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bassens',
        code: 'FRBAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.9072,
        longitude: -0.53829,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3034691,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16626',
      geometry: { type: 'Point', coordinates: [-2.76221395, 47.63785565] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vannes',
        code: 'FRVNE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.63786,
        longitude: -2.76221,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2970777,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16627',
      geometry: { type: 'Point', coordinates: [-1.45426261, 50.90833774] },
      geometry_name: 'shape',
      properties: {
        portname: 'Southampton',
        code: 'GBSOU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.90834,
        longitude: -1.45426,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2637487,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16628',
      geometry: { type: 'Point', coordinates: [-4.20399782, 51.01939379] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bideford',
        code: 'GBBID',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.01939,
        longitude: -4.204,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2655707,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16629',
      geometry: { type: 'Point', coordinates: [0.05980929, 51.49851182] },
      geometry_name: 'shape',
      properties: {
        portname: 'North Woolwich',
        code: 'GBWOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.49851,
        longitude: 0.05981,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2641215,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16630',
      geometry: { type: 'Point', coordinates: [4.59869516, 51.69019838] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moerdijk',
        code: 'NLMOE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.6902,
        longitude: 4.5987,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2750769,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16631',
      geometry: { type: 'Point', coordinates: [0.92701124, 51.88354396] },
      geometry_name: 'shape',
      properties: {
        portname: 'Colchester',
        code: 'GBCOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.88354,
        longitude: 0.92701,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2652618,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16632',
      geometry: { type: 'Point', coordinates: [4.3243, 51.89793387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vlaardingen',
        code: 'NLVLA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.89793,
        longitude: 4.3243,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2745467,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16633',
      geometry: { type: 'Point', coordinates: [4.45692622, 51.88810503] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rotterdam',
        code: 'NLRTM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.88811,
        longitude: 4.45693,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2747891,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16634',
      geometry: { type: 'Point', coordinates: [4.81970845, 52.43066606] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zaandam',
        code: 'NLZAA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.43067,
        longitude: 4.81971,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2744118,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16635',
      geometry: { type: 'Point', coordinates: [0.15407191, 52.67811597] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wisbech',
        code: 'GBWIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.67812,
        longitude: 0.15407,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2633771,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16636',
      geometry: { type: 'Point', coordinates: [4.77792806, 52.96166406] },
      geometry_name: 'shape',
      properties: {
        portname: 'Den Helder',
        code: 'NLDHR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.96166,
        longitude: 4.77793,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2757220,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16637',
      geometry: { type: 'Point', coordinates: [134.69145636, 34.77546791] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mega',
        code: 'JPHIM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.77547,
        longitude: 134.69146,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1857429,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16638',
      geometry: { type: 'Point', coordinates: [-6.20865275, 53.35254597] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dublin',
        code: 'IEDUB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.35255,
        longitude: -6.20865,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2964574,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16639',
      geometry: { type: 'Point', coordinates: [-9.64076094, 30.42864116] },
      geometry_name: 'shape',
      properties: {
        portname: 'Agadir',
        code: 'MAAGA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.42864,
        longitude: -9.64076,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2561668,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16640',
      geometry: { type: 'Point', coordinates: [133.28172248, 33.97407778] },
      geometry_name: 'shape',
      properties: {
        portname: 'Niihama',
        code: 'JPIHA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.97408,
        longitude: 133.28172,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1855425,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16641',
      geometry: { type: 'Point', coordinates: [135.14398482, 34.11070352] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shimotsu',
        code: 'JPSMT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.1107,
        longitude: 135.14398,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1852173,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16642',
      geometry: { type: 'Point', coordinates: [120.62445213, 14.07677723] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nasugbu',
        code: 'PHNUG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.07678,
        longitude: 120.62445,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1698030,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16643',
      geometry: { type: 'Point', coordinates: [-7.39928771, 33.71770915] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mohammedia',
        code: 'MAMOH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.71771,
        longitude: -7.39929,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2542051,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16644',
      geometry: { type: 'Point', coordinates: [132.44310708, 34.191814] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kanokawa',
        code: 'JPKKW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.19181,
        longitude: 132.44311,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1860126,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16645',
      geometry: { type: 'Point', coordinates: [-6.15154759, 35.19982807] },
      geometry_name: 'shape',
      properties: {
        portname: 'Larache',
        code: 'MALAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.19983,
        longitude: -6.15155,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2543549,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16646',
      geometry: { type: 'Point', coordinates: [148.56418175, -10.16991886] },
      geometry_name: 'shape',
      properties: {
        portname: 'Abau',
        code: 'PGABW',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -10.16992,
        longitude: 148.56418,
        iso3: 'PNG',
        iso3_op: 'PNG',
        country: 'Papua New Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2134009,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16647',
      geometry: { type: 'Point', coordinates: [171.200848, -42.44696578] },
      geometry_name: 'shape',
      properties: {
        portname: 'Greymouth',
        code: 'NZGMN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -42.44697,
        longitude: 171.20085,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2206895,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16648',
      geometry: { type: 'Point', coordinates: [173.68255344, -42.4252715] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kaikoura',
        code: 'NZKBZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -42.42527,
        longitude: 173.68255,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2189272,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16649',
      geometry: { type: 'Point', coordinates: [146.36305778, -41.18177732] },
      geometry_name: 'shape',
      properties: {
        portname: 'Devonport',
        code: 'AUDPO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.18178,
        longitude: 146.36306,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2168943,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16650',
      geometry: { type: 'Point', coordinates: [-42.27353355, -2.76476221] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tutoia',
        code: 'BRTUT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.76476,
        longitude: -42.27353,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3385899,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16651',
      geometry: { type: 'Point', coordinates: [101.80014767, 2.52217981] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Dickson',
        code: 'MYPDI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.52218,
        longitude: 101.80015,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1734815,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16652',
      geometry: { type: 'Point', coordinates: [117.88075396, 4.24386212] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tawau',
        code: 'MYTWU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.24386,
        longitude: 117.88075,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1734199,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16653',
      geometry: { type: 'Point', coordinates: [125.09984136, 8.82968189] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gingoog',
        code: 'PHGIN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.82968,
        longitude: 125.09984,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1712875,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16654',
      geometry: { type: 'Point', coordinates: [-67.03029953, 10.61085069] },
      geometry_name: 'shape',
      properties: {
        portname: 'Catia La Mar',
        code: 'VECLM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.61085,
        longitude: -67.0303,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3646190,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16655',
      geometry: { type: 'Point', coordinates: [75.4893991, 11.74576727] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tellicherry',
        code: 'INTEL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.74577,
        longitude: 75.4894,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1254780,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16656',
      geometry: { type: 'Point', coordinates: [129.81926482, 33.30278178] },
      geometry_name: 'shape',
      properties: {
        portname: 'Imari',
        code: 'JPIMI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.30278,
        longitude: 129.81926,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1861677,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16657',
      geometry: { type: 'Point', coordinates: [33.33104243, 35.34058751] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kyrenia',
        code: 'CYKYR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.34059,
        longitude: 33.33104,
        iso3: null,
        iso3_op: 'CYP',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 146412,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16658',
      geometry: { type: 'Point', coordinates: [142.04434541, 47.04690963] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kholmsk',
        code: 'RUKHO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.04691,
        longitude: 142.04435,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2124615,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16659',
      geometry: { type: 'Point', coordinates: [25.58725331, 60.33694285] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tolkkinen',
        code: 'FITOK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.33694,
        longitude: 25.58725,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 634246,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16660',
      geometry: { type: 'Point', coordinates: [32.40783382, 67.13692471] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kandalaksha',
        code: 'RUKAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 67.13692,
        longitude: 32.40783,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 553190,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16661',
      geometry: { type: 'Point', coordinates: [177.42912032, -39.0318114] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wairoa',
        code: 'NZWIR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -39.03181,
        longitude: 177.42912,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2206808,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16662',
      geometry: { type: 'Point', coordinates: [144.35681057, -38.14364714] },
      geometry_name: 'shape',
      properties: {
        portname: 'Geelong',
        code: 'AUGEX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -38.14365,
        longitude: 144.35681,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2165798,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16663',
      geometry: { type: 'Point', coordinates: [174.12019539, -35.31457495] },
      geometry_name: 'shape',
      properties: {
        portname: 'Opua',
        code: 'NZOPX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -35.31457,
        longitude: 174.1202,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2185493,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16664',
      geometry: { type: 'Point', coordinates: [165.83229456, -21.39861058] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kouaoua',
        code: 'NCKOU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -21.39861,
        longitude: 165.83229,
        iso3: null,
        iso3_op: 'NCL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2140621,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16665',
      geometry: { type: 'Point', coordinates: [116.70487048, -20.66442219] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dampier',
        code: 'AUDAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.66442,
        longitude: 116.70487,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2073200,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16666',
      geometry: { type: 'Point', coordinates: [-54.4077327, 5.62676904] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moengo',
        code: 'SRMOJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.62677,
        longitude: -54.40773,
        iso3: 'SUR',
        iso3_op: 'SUR',
        country: 'Suriname',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3383494,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16667',
      geometry: { type: 'Point', coordinates: [131.86491684, 33.2497542] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saganoseki',
        code: 'JPSAG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.24975,
        longitude: 131.86492,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1853286,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16668',
      geometry: { type: 'Point', coordinates: [133.2044754, 34.40880312] },
      geometry_name: 'shape',
      properties: {
        portname: 'Onomichi',
        code: 'JPONO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.4088,
        longitude: 133.20448,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1853992,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16669',
      geometry: { type: 'Point', coordinates: [137.21962779, 34.81871492] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gamagori',
        code: 'JPGAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.81871,
        longitude: 137.21963,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1863693,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16670',
      geometry: { type: 'Point', coordinates: [131.39130473, 42.86487687] },
      geometry_name: 'shape',
      properties: {
        portname: 'Slavyanka',
        code: 'RUSKA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.86488,
        longitude: 131.3913,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2016430,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16671',
      geometry: { type: 'Point', coordinates: [145.25179059, -15.45800038] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cooktown',
        code: 'AUCTN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -15.458,
        longitude: 145.25179,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2170658,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16672',
      geometry: { type: 'Point', coordinates: [-37.13606455, -4.94875178] },
      geometry_name: 'shape',
      properties: {
        portname: 'Areia Branca',
        code: 'BRARE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.94875,
        longitude: -37.13606,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3407194,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16673',
      geometry: { type: 'Point', coordinates: [100.60863173, 4.23551521] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lumut',
        code: 'MYLUM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.23552,
        longitude: 100.60863,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1735450,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16674',
      geometry: { type: 'Point', coordinates: [103.42488047, 4.77736621] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dungun',
        code: 'MYDGN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.77737,
        longitude: 103.42488,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1734308,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16675',
      geometry: { type: 'Point', coordinates: [115.60037231, 5.21733053] },
      geometry_name: 'shape',
      properties: {
        portname: 'Weston',
        code: 'MYWES',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.21733,
        longitude: 115.60037,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1733200,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16676',
      geometry: { type: 'Point', coordinates: [-67.84497549, 10.44329453] },
      geometry_name: 'shape',
      properties: {
        portname: 'Turiamo',
        code: 'VETUR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.44329,
        longitude: -67.84498,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3625838,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16677',
      geometry: { type: 'Point', coordinates: [136.00358805, 33.72620106] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shingu',
        code: 'JPSHN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.7262,
        longitude: 136.00359,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1847947,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16678',
      geometry: { type: 'Point', coordinates: [136.20423193, 34.06987131] },
      geometry_name: 'shape',
      properties: {
        portname: 'Owase',
        code: 'JPOWA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.06987,
        longitude: 136.20423,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1853514,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16679',
      geometry: { type: 'Point', coordinates: [129.29031587, 34.19017774] },
      geometry_name: 'shape',
      properties: {
        portname: 'Izuhara',
        code: 'JPIZH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.19018,
        longitude: 129.29032,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1861116,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16680',
      geometry: { type: 'Point', coordinates: [131.40322122, 34.42028448] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hagi',
        code: 'JPHAG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 34.42028,
        longitude: 131.40322,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1863418,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16681',
      geometry: { type: 'Point', coordinates: [126.63017903, 35.97559155] },
      geometry_name: 'shape',
      properties: {
        portname: 'Changhang',
        code: 'KRCHG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 35.97559,
        longitude: 126.63018,
        iso3: null,
        iso3_op: 'KOR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1846560,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16682',
      geometry: { type: 'Point', coordinates: [142.05652173, 49.16145691] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shakhtersk',
        code: 'RUSHA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.16146,
        longitude: 142.05652,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2121367,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16683',
      geometry: { type: 'Point', coordinates: [140.77962303, 51.47000806] },
      geometry_name: 'shape',
      properties: {
        portname: 'De-Kastri',
        code: 'RUDKA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.47001,
        longitude: 140.77962,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2126018,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16684',
      geometry: { type: 'Point', coordinates: [33.0563, 68.97813387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Murmansk',
        code: 'RUMMK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 68.97813,
        longitude: 33.0563,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 524305,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16685',
      geometry: { type: 'Point', coordinates: [-62.65896644, 8.37684528] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ciudad Guayana',
        code: 'VECGU',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 8.37685,
        longitude: -62.65897,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3645528,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16686',
      geometry: { type: 'Point', coordinates: [115.07957359, 30.22647254] },
      geometry_name: 'shape',
      properties: {
        portname: 'Huangshi',
        code: 'CNHSI',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.22647,
        longitude: 115.07957,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1807234,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16687',
      geometry: { type: 'Point', coordinates: [117.18866299, 39.13366645] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tianjin',
        code: 'CNTSN',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.13367,
        longitude: 117.18866,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1792947,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16688',
      geometry: { type: 'Point', coordinates: [-38.50655873, -12.95828798] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salvador',
        code: 'BRSSA',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.95829,
        longitude: -38.50656,
        iso3: null,
        iso3_op: 'BRA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3450554,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16689',
      geometry: { type: 'Point', coordinates: [-66.09608844, 18.43248493] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Juan',
        code: 'PRSJU',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.43248,
        longitude: -66.09609,
        iso3: 'PRI',
        iso3_op: 'PRI',
        country: 'Puerto Rico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4568127,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16690',
      geometry: { type: 'Point', coordinates: [-56.1958, -34.89976613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Montevideo',
        code: 'UYMVD',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.89977,
        longitude: -56.1958,
        iso3: 'URY',
        iso3_op: 'URY',
        country: 'Uruguay',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3441575,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16691',
      geometry: { type: 'Point', coordinates: [-34.87441313, -8.04912287] },
      geometry_name: 'shape',
      properties: {
        portname: 'Recife',
        code: 'BRREC',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.04912,
        longitude: -34.87441,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3390760,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16692',
      geometry: { type: 'Point', coordinates: [-58.16897664, 6.80792716] },
      geometry_name: 'shape',
      properties: {
        portname: 'Georgetown',
        code: 'GYGEO',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.80793,
        longitude: -58.16898,
        iso3: 'GUY',
        iso3_op: 'GUY',
        country: 'Guyana',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3378644,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16693',
      geometry: { type: 'Point', coordinates: [-57.65635385, -18.99644107] },
      geometry_name: 'shape',
      properties: {
        portname: 'Corumba',
        code: 'BRCMG',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -18.99644,
        longitude: -57.65635,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3465342,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16694',
      geometry: { type: 'Point', coordinates: [-62.62996634, 17.13890189] },
      geometry_name: 'shape',
      properties: {
        portname: 'Charlestown',
        code: 'KNNEV',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.1389,
        longitude: -62.62997,
        iso3: 'KNA',
        iso3_op: 'KNA',
        country: 'Saint Kitts and Nevis',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3575479,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16695',
      geometry: { type: 'Point', coordinates: [-58.31025961, -33.11248001] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fray Bentos',
        code: 'UYFZB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.11248,
        longitude: -58.31026,
        iso3: 'URY',
        iso3_op: 'URY',
        country: 'Uruguay',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3442568,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16696',
      geometry: { type: 'Point', coordinates: [-70.63680319, -26.35234385] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chanaral',
        code: 'CLCNR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -26.35234,
        longitude: -70.6368,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3895554,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16697',
      geometry: { type: 'Point', coordinates: [-61.6833, 10.17543387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Point Fortin',
        code: 'TTPTF',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.17543,
        longitude: -61.6833,
        iso3: 'TTO',
        iso3_op: 'TTO',
        country: 'Trinidad and Tobago',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3573899,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16698',
      geometry: { type: 'Point', coordinates: [131.24612239, -0.8780153] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sorong',
        code: 'IDSOQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.87802,
        longitude: 131.24612,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1626542,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16699',
      geometry: { type: 'Point', coordinates: [127.47934036, -0.62884215] },
      geometry_name: 'shape',
      properties: {
        portname: 'Labuha',
        code: 'IDLAH',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.62884,
        longitude: 127.47934,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1638874,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16700',
      geometry: { type: 'Point', coordinates: [-79.46927345, 22.52695546] },
      geometry_name: 'shape',
      properties: {
        portname: 'Caibarien',
        code: 'CUCAI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.52696,
        longitude: -79.46927,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3566356,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16701',
      geometry: { type: 'Point', coordinates: [-75.24226371, -15.25787111] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Nicolas',
        code: 'PESNX',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -15.25787,
        longitude: -75.24226,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3929384,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16702',
      geometry: { type: 'Point', coordinates: [-64.64736677, 10.21357217] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto La Cruz',
        code: 'VEPCZ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.21357,
        longitude: -64.64737,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3629672,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16703',
      geometry: { type: 'Point', coordinates: [39.01777761, 22.73617819] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rabigh',
        code: 'SARAB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.73618,
        longitude: 39.01778,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 103035,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16704',
      geometry: { type: 'Point', coordinates: [-67.60803491, -54.93332624] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Williams',
        code: 'CLWPU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -54.93333,
        longitude: -67.60803,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3874926,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16705',
      geometry: { type: 'Point', coordinates: [26.8880539, 39.07052848] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dikili',
        code: 'TRDIK',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.07053,
        longitude: 26.88805,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 316726,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16706',
      geometry: { type: 'Point', coordinates: [81.19620112, 8.56691048] },
      geometry_name: 'shape',
      properties: {
        portname: 'Trincomalee',
        code: 'LKTRR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.56691,
        longitude: 81.1962,
        iso3: 'LKA',
        iso3_op: 'LKA',
        country: 'Sri Lanka',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1226260,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16707',
      geometry: { type: 'Point', coordinates: [-67.72108529, -49.30068045] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Julian',
        code: 'ARULA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -49.30068,
        longitude: -67.72109,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3840082,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16708',
      geometry: { type: 'Point', coordinates: [43.3586105, -21.74234666] },
      geometry_name: 'shape',
      properties: {
        portname: 'Morombe',
        code: 'MGMXM',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -21.74235,
        longitude: 43.35861,
        iso3: null,
        iso3_op: 'MDG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1058390,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16709',
      geometry: { type: 'Point', coordinates: [-85.87695336, 16.45589003] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guanaja',
        code: 'HNGJA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.45589,
        longitude: -85.87695,
        iso3: null,
        iso3_op: 'HND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3609293,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16710',
      geometry: { type: 'Point', coordinates: [-68.45085154, -53.29959397] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Sebastian',
        code: 'ARSSN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -53.29959,
        longitude: -68.45085,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3836558,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16711',
      geometry: { type: 'Point', coordinates: [-70.41570811, -23.08886997] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mejillones',
        code: 'CLMJS',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -23.08887,
        longitude: -70.41571,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3880143,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16712',
      geometry: { type: 'Point', coordinates: [-82.85913858, 8.27093156] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Armuelles',
        code: 'PAAML',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.27093,
        longitude: -82.85914,
        iso3: null,
        iso3_op: 'PAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3702431,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16713',
      geometry: { type: 'Point', coordinates: [48.2823978, 30.33011491] },
      geometry_name: 'shape',
      properties: {
        portname: 'Abadan',
        code: 'IRABD',
        prttype: 'River',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.33011,
        longitude: 48.2824,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 145459,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16714',
      geometry: { type: 'Point', coordinates: [-174.35141294, -19.80311814] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pangai',
        code: 'TOPAN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -19.80312,
        longitude: -174.35141,
        iso3: 'TON',
        iso3_op: 'TON',
        country: 'Tonga',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4032369,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16715',
      geometry: { type: 'Point', coordinates: [-15.47242087, 11.57904163] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bolama',
        code: 'GWBOL',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.57904,
        longitude: -15.47242,
        iso3: null,
        iso3_op: 'GNB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2374688,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16716',
      geometry: { type: 'Point', coordinates: [-2.72882011, 53.7599402] },
      geometry_name: 'shape',
      properties: {
        portname: 'Preston',
        code: 'GBPRE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.75994,
        longitude: -2.72882,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2639912,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16717',
      geometry: { type: 'Point', coordinates: [9.69685994, 54.31080629] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rendsburg',
        code: 'DEREN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.31081,
        longitude: 9.69686,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2848245,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16718',
      geometry: { type: 'Point', coordinates: [18.54462323, 54.52945575] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gdynia',
        code: 'PLGDY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.52946,
        longitude: 18.54462,
        iso3: 'POL',
        iso3_op: 'POL',
        country: 'Poland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3099424,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16719',
      geometry: { type: 'Point', coordinates: [-1.56813081, 54.96347425] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gateshead',
        code: 'GBGAT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.96347,
        longitude: -1.56813,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2648773,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16720',
      geometry: { type: 'Point', coordinates: [-3.60899355, 55.06351715] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dumfries',
        code: 'GBDUM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.06352,
        longitude: -3.60899,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2650798,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16721',
      geometry: { type: 'Point', coordinates: [-3.43977043, 56.02521121] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rosyth',
        code: 'GBROY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.02521,
        longitude: -3.43977,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2639104,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16722',
      geometry: { type: 'Point', coordinates: [-3.39665047, 56.02343118] },
      geometry_name: 'shape',
      properties: {
        portname: 'Inverkeithing',
        code: 'GBINK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.02343,
        longitude: -3.39665,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2646104,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16723',
      geometry: { type: 'Point', coordinates: [-3.1511, 56.11903387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kirkcaldy',
        code: 'GBKKD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 56.11903,
        longitude: -3.1511,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2645298,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16724',
      geometry: { type: 'Point', coordinates: [16.41686451, 56.35335165] },
      geometry_name: 'shape',
      properties: {
        portname: 'Degerhamn',
        code: 'SEDEG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 56.35335,
        longitude: 16.41686,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2717878,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16725',
      geometry: { type: 'Point', coordinates: [12.85610383, 56.66072029] },
      geometry_name: 'shape',
      properties: {
        portname: 'Halmstad',
        code: 'SEHAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.66072,
        longitude: 12.8561,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2708365,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16726',
      geometry: { type: 'Point', coordinates: [8.9322634, 58.62103656] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tvedestrand',
        code: 'NOTVE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.62104,
        longitude: 8.93226,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3133681,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16727',
      geometry: { type: 'Point', coordinates: [9.61513264, 59.20245896] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skien',
        code: 'NOSKE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.20246,
        longitude: 9.61513,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3139075,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16728',
      geometry: { type: 'Point', coordinates: [7.15724074, 62.73506974] },
      geometry_name: 'shape',
      properties: {
        portname: 'Molde',
        code: 'NOMOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 62.73507,
        longitude: 7.15724,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3145580,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16729',
      geometry: { type: 'Point', coordinates: [17.78349574, 62.93806092] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kramfors',
        code: 'SEKRF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 62.93806,
        longitude: 17.7835,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2699394,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16730',
      geometry: { type: 'Point', coordinates: [-79.906, 9.35323387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Colon',
        code: 'PAONX',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.35323,
        longitude: -79.906,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3712076,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16731',
      geometry: { type: 'Point', coordinates: [-82.7151665, 41.45712447] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sandusky',
        code: 'USSDY',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.45712,
        longitude: -82.71517,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5170691,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16732',
      geometry: { type: 'Point', coordinates: [-75.58648695, 9.52176882] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tolu',
        code: 'COTLU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.52177,
        longitude: -75.58649,
        iso3: null,
        iso3_op: 'COL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3666939,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16733',
      geometry: { type: 'Point', coordinates: [112.73319192, -7.20202946] },
      geometry_name: 'shape',
      properties: {
        portname: 'Surabaya',
        code: 'IDSUB',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.20203,
        longitude: 112.73319,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1625822,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16734',
      geometry: { type: 'Point', coordinates: [112.65108445, -7.14448875] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gresik',
        code: 'IDGRE',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.14449,
        longitude: 112.65108,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1643776,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16735',
      geometry: { type: 'Point', coordinates: [122.21890202, -8.61517152] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maumere',
        code: 'IDMOF',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.61517,
        longitude: 122.2189,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1635815,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16736',
      geometry: { type: 'Point', coordinates: [134.07484218, -0.86726693] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manokwari',
        code: 'IDMKW',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.86727,
        longitude: 134.07484,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1636308,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16737',
      geometry: { type: 'Point', coordinates: [117.59558253, 3.28409078] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tarakan',
        code: 'IDTRK',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 3.28409,
        longitude: 117.59558,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1624725,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16738',
      geometry: { type: 'Point', coordinates: [109.33551859, -0.01846996] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pontianak',
        code: 'IDPNK',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.01847,
        longitude: 109.33552,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1630789,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16739',
      geometry: { type: 'Point', coordinates: [-80.72626658, -0.93879538] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manta',
        code: 'ECMEC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.9388,
        longitude: -80.72627,
        iso3: 'ECU',
        iso3_op: 'ECU',
        country: 'Ecuador',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3654410,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16740',
      geometry: { type: 'Point', coordinates: [-69.61543856, 19.22557136] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sanchez',
        code: 'DOSNZ',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.22557,
        longitude: -69.61544,
        iso3: 'DOM',
        iso3_op: 'DOM',
        country: 'Dominican Republic',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3493198,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16741',
      geometry: { type: 'Point', coordinates: [-77.27094542, -11.58361952] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chancay',
        code: 'PECHY',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -11.58362,
        longitude: -77.27095,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3944399,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16742',
      geometry: { type: 'Point', coordinates: [-79.93761433, -6.83645118] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pimentel',
        code: 'PEPMT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.83645,
        longitude: -79.93761,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3693584,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16743',
      geometry: { type: 'Point', coordinates: [-76.33526916, 44.12997252] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cape Vincent',
        code: 'USCVI',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.12997,
        longitude: -76.33527,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5111490,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16744',
      geometry: { type: 'Point', coordinates: [121.96719256, 20.44782936] },
      geometry_name: 'shape',
      properties: {
        portname: 'Basco',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.44783,
        longitude: 121.96719,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1726449,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16745',
      geometry: { type: 'Point', coordinates: [-81.80798749, 24.55036757] },
      geometry_name: 'shape',
      properties: {
        portname: 'Key West',
        code: 'USEYW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.55037,
        longitude: -81.80799,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4160812,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16746',
      geometry: { type: 'Point', coordinates: [-86.27334933, 42.40640574] },
      geometry_name: 'shape',
      properties: {
        portname: 'South Haven',
        code: 'USSOH',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.40641,
        longitude: -86.27335,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5010517,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16747',
      geometry: { type: 'Point', coordinates: [124.36650862, 9.64943612] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jagna',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.64944,
        longitude: 124.36651,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1710378,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16748',
      geometry: { type: 'Point', coordinates: [18.26033416, 0.06342927] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mbandaka',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 0.06343,
        longitude: 18.26033,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2312895,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16749',
      geometry: { type: 'Point', coordinates: [25.43339585, -0.36945316] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ubundu',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -0.36945,
        longitude: 25.4334,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 204529,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16750',
      geometry: { type: 'Point', coordinates: [22.45898933, 2.17733323] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bumba',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 2.17733,
        longitude: 22.45899,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 217745,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16751',
      geometry: { type: 'Point', coordinates: [23.36510749, -2.31250735] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lomela',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.31251,
        longitude: 23.36511,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 211415,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16752',
      geometry: { type: 'Point', coordinates: [19.27594016, -2.24795255] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bekungu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.24795,
        longitude: 19.27594,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2317222,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16753',
      geometry: { type: 'Point', coordinates: [-89.95171185, 29.63221583] },
      geometry_name: 'shape',
      properties: {
        portname: 'Myrtle Grove',
        code: 'USMYG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.63222,
        longitude: -89.95171,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4334660,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16754',
      geometry: { type: 'Point', coordinates: [-124.6108622, 48.36624239] },
      geometry_name: 'shape',
      properties: {
        portname: 'Neah Bay',
        code: 'USNEB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.36624,
        longitude: -124.61086,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5804537,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16755',
      geometry: { type: 'Point', coordinates: [-120.75622476, 35.17231444] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port San Luis',
        code: 'USPSL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.17231,
        longitude: -120.75622,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5384353,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16756',
      geometry: { type: 'Point', coordinates: [-3.49119485, 53.32382698] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rhyl',
        code: 'GBRHY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.32383,
        longitude: -3.49119,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2639409,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16757',
      geometry: { type: 'Point', coordinates: [-1.78916465, 57.49190525] },
      geometry_name: 'shape',
      properties: {
        portname: 'Peterhead',
        code: 'GBPHD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.49191,
        longitude: -1.78916,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2640351,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16758',
      geometry: { type: 'Point', coordinates: [14.60150975, 40.63310828] },
      geometry_name: 'shape',
      properties: {
        portname: 'Amalfi',
        code: 'ITAMA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.63311,
        longitude: 14.60151,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3183130,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16759',
      geometry: { type: 'Point', coordinates: [9.62456345, 40.99564033] },
      geometry_name: 'shape',
      properties: {
        portname: 'Golfo Aranci',
        code: 'ITGAI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.99564,
        longitude: 9.62456,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6535672,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16760',
      geometry: { type: 'Point', coordinates: [13.7826387, 54.05004774] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wolgast',
        code: 'DEWOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.05005,
        longitude: 13.78264,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2806501,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16761',
      geometry: { type: 'Point', coordinates: [18.44180132, 60.34042182] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oregrund',
        code: 'SEOGR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.34042,
        longitude: 18.4418,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2686649,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16762',
      geometry: { type: 'Point', coordinates: [124.59220491, 12.06599908] },
      geometry_name: 'shape',
      properties: {
        portname: 'Calbayog',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.066,
        longitude: 124.5922,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1720402,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16763',
      geometry: { type: 'Point', coordinates: [20.57477145, -4.35860428] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malembe',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -4.3586,
        longitude: 20.57477,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 209851,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16764',
      geometry: { type: 'Point', coordinates: [21.06755449, 1.52195789] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bongandanga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.52196,
        longitude: 21.06755,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 218229,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16765',
      geometry: { type: 'Point', coordinates: [35.60889272, -18.0269193] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chupanga',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -18.02692,
        longitude: 35.60889,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Chupanga: (I) -No Vessel Restriction- (Ii) -Truck Access- (Iii) -Network Coverage- (Iv) -No Hazard To Navigation--Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1044165,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16766',
      geometry: { type: 'Point', coordinates: [-9.41940235, 38.69236815] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cascais',
        code: 'PTCAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.69237,
        longitude: -9.4194,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Portugal',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2269594,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16767',
      geometry: { type: 'Point', coordinates: [17.95229233, -3.73360466] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bagata',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.7336,
        longitude: 17.95229,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2317500,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16768',
      geometry: { type: 'Point', coordinates: [12.84202239, 56.4314346] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bastad',
        code: 'SEBAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.43143,
        longitude: 12.84202,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2723287,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16769',
      geometry: { type: 'Point', coordinates: [15.84759677, 59.39356434] },
      geometry_name: 'shape',
      properties: {
        portname: 'Arboga',
        code: 'SEARA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.39356,
        longitude: 15.8476,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2725471,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16770',
      geometry: { type: 'Point', coordinates: [141.9133226, 39.3551268] },
      geometry_name: 'shape',
      properties: {
        portname: 'Otsuchi',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.35513,
        longitude: 141.91332,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2111310,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16771',
      geometry: { type: 'Point', coordinates: [20.95370677, 64.74832009] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skelleftea',
        code: 'SESFT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.74832,
        longitude: 20.95371,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 602913,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16772',
      geometry: { type: 'Point', coordinates: [17.97679701, 69.23029079] },
      geometry_name: 'shape',
      properties: {
        portname: 'Finnsnes',
        code: 'NOFNE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 69.23029,
        longitude: 17.9768,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3157549,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16773',
      geometry: { type: 'Point', coordinates: [35.15505527, -17.3296037] },
      geometry_name: 'shape',
      properties: {
        portname: 'Charre',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -17.3296,
        longitude: 35.15506,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Charre: (I) -No Vessel Restriction- (Ii) -Truck Access- (Iii )-Network Coverage- (Iv) -No Hazard To Navigation--Planning',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1050250,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16774',
      geometry: { type: 'Point', coordinates: [21.35305404, -0.30739119] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bolondo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.30739,
        longitude: 21.35305,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 218383,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16775',
      geometry: { type: 'Point', coordinates: [-6.07847798, 13.67611751] },
      geometry_name: 'shape',
      properties: {
        portname: 'Markala',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.67612,
        longitude: -6.07848,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2453662,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16776',
      geometry: { type: 'Point', coordinates: [48.34596917, -21.24204613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mananjary',
        code: 'MGMNJ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: 1.5,
        maxlength: 157,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -21.24205,
        longitude: 48.34597,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1061335,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16777',
      geometry: { type: 'Point', coordinates: [107.34829772, 21.00587032] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cam Pha',
        code: 'VNCPH',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.00587,
        longitude: 107.3483,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1586360,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16778',
      geometry: { type: 'Point', coordinates: [48.26375129, -21.51733656] },
      geometry_name: 'shape',
      properties: {
        portname: 'Anakitaina',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -21.51734,
        longitude: 48.26375,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1077342,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16779',
      geometry: { type: 'Point', coordinates: [23.78500521, 2.72957674] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aketi',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 2.72958,
        longitude: 23.78501,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 220448,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16780',
      geometry: { type: 'Point', coordinates: [30.5761473, -8.23330134] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moliro',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -8.2333,
        longitude: 30.57615,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 208869,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16781',
      geometry: { type: 'Point', coordinates: [48.18456474, -21.63823061] },
      geometry_name: 'shape',
      properties: {
        portname: 'Namorona',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -21.63823,
        longitude: 48.18456,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1058280,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16782',
      geometry: { type: 'Point', coordinates: [44.96109298, -19.72578563] },
      geometry_name: 'shape',
      properties: {
        portname: 'Berevo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -19.72579,
        longitude: 44.96109,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1066908,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16783',
      geometry: { type: 'Point', coordinates: [32.90972999, 46.10740634] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skadovsk',
        code: 'UASKD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 46.10741,
        longitude: 32.90973,
        iso3: null,
        iso3_op: 'UKR',
        country: null,
        lastcheckdate: '2014-03-03T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 693709,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16784',
      geometry: { type: 'Point', coordinates: [124.00405878, 12.96663684] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sorsogon',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.96664,
        longitude: 124.00406,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1685755,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16785',
      geometry: { type: 'Point', coordinates: [159.9592056, -9.42726043] },
      geometry_name: 'shape',
      properties: {
        portname: 'Honiara',
        code: 'SBHIR',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -9.42726,
        longitude: 159.95921,
        iso3: 'SLB',
        iso3_op: 'SLB',
        country: 'Solomon islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2108502,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16786',
      geometry: { type: 'Point', coordinates: [34.43192482, 31.52247121] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gaza',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.52247,
        longitude: 34.43192,
        iso3: 'PSE',
        iso3_op: 'PSE',
        country: 'Gaza Strip',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 281133,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16787',
      geometry: { type: 'Point', coordinates: [-57.82582537, -51.69962734] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Stanley',
        code: 'FKPSY',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -51.69963,
        longitude: -57.82583,
        iso3: 'FLK',
        iso3_op: 'FLK',
        country: 'Falkland Islands (Malvinas)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3426691,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16788',
      geometry: { type: 'Point', coordinates: [9.74352522, 1.82693377] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bata',
        code: 'GQBSG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.82693,
        longitude: 9.74353,
        iso3: 'GNQ',
        iso3_op: 'GNQ',
        country: 'Equatorial Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2310046,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16789',
      geometry: { type: 'Point', coordinates: [35.7905817, 35.68173603] },
      geometry_name: 'shape',
      properties: {
        portname: 'Borj Islam',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.68174,
        longitude: 35.79058,
        iso3: 'SYR',
        iso3_op: null,
        country: 'Syrian Arab Republic',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 171147,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16790',
      geometry: { type: 'Point', coordinates: [103.74223572, 1.30154523] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanjong Penjuru',
        code: 'SGTPN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.30155,
        longitude: 103.74224,
        iso3: 'SGP',
        iso3_op: 'SGP',
        country: 'Singapore',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1880540,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16791',
      geometry: { type: 'Point', coordinates: [140.28576829, 48.97845633] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sovetskaya Gavan',
        code: 'RUSOG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.97846,
        longitude: 140.28577,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2121052,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16792',
      geometry: { type: 'Point', coordinates: [-52.27349326, 4.85372294] },
      geometry_name: 'shape',
      properties: {
        portname: 'Degrad Des Cannes',
        code: 'GFDDC',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.85372,
        longitude: -52.27349,
        iso3: 'GUF',
        iso3_op: 'GUF',
        country: 'French Guiana',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3381921,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16793',
      geometry: { type: 'Point', coordinates: [-62.2963519, 10.56971599] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guiria',
        code: 'VEGUI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.56972,
        longitude: -62.29635,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3639725,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16794',
      geometry: { type: 'Point', coordinates: [-112.08338836, 27.19138079] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Marcos Island',
        code: 'MXSMI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.19138,
        longitude: -112.08339,
        iso3: null,
        iso3_op: 'MEX',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4022086,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16795',
      geometry: { type: 'Point', coordinates: [107.63020931, -2.74522192] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanjung Pandan',
        code: 'IDTJQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.74522,
        longitude: 107.63021,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1624877,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16796',
      geometry: { type: 'Point', coordinates: [17.62425314, 59.20256549] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sodertalje',
        code: 'SESOE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.20257,
        longitude: 17.62425,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2676176,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16797',
      geometry: { type: 'Point', coordinates: [-82.08517629, 26.49164877] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pine Island',
        code: 'USPNI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 26.49165,
        longitude: -82.08518,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4168493,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16798',
      geometry: { type: 'Point', coordinates: [32.09256266, 1.69504192] },
      geometry_name: 'shape',
      properties: {
        portname: 'Masindi',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.69504,
        longitude: 32.09256,
        iso3: 'UGA',
        iso3_op: 'UGA',
        country: 'Uganda',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 229361,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16799',
      geometry: { type: 'Point', coordinates: [-85.30892638, 29.81493624] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port St Joe',
        code: 'USPSJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.81494,
        longitude: -85.30893,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4169166,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16800',
      geometry: { type: 'Point', coordinates: [9.39346634, 4.06583996] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tiko',
        code: 'CMTKC',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.06584,
        longitude: 9.39347,
        iso3: 'CMR',
        iso3_op: 'CMR',
        country: 'Cameroon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2221504,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16801',
      geometry: { type: 'Point', coordinates: [13.40313153, 9.2934374] },
      geometry_name: 'shape',
      properties: {
        portname: 'Garoua',
        code: 'CMGOU',
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.29344,
        longitude: 13.40313,
        iso3: 'CMR',
        iso3_op: 'CMR',
        country: 'Cameroon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2231320,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16802',
      geometry: { type: 'Point', coordinates: [-62.85026169, 17.89927631] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gustavia',
        code: 'GPGUS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.89928,
        longitude: -62.85026,
        iso3: 'GLP',
        iso3_op: 'GLP',
        country: 'Guadeloupe',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3579132,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16803',
      geometry: { type: 'Point', coordinates: [43.23669755, 13.31008665] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mokha',
        code: 'YEMOK',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Closed',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.31009,
        longitude: 43.2367,
        iso3: null,
        iso3_op: 'YEM',
        country: null,
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 78751,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16804',
      geometry: { type: 'Point', coordinates: [120.50022953, 14.43703009] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bataan',
        code: 'PHBTN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.43703,
        longitude: 120.50023,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 7622450,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16805',
      geometry: { type: 'Point', coordinates: [-68.99891932, 12.14809946] },
      geometry_name: 'shape',
      properties: {
        portname: 'St Michiels Bay',
        code: 'ANSMB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.1481,
        longitude: -68.99892,
        iso3: 'ANT',
        iso3_op: 'ANT',
        country: 'Netherlands Antilles',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6692389,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16806',
      geometry: { type: 'Point', coordinates: [108.39264773, 21.59596048] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fangcheng',
        code: 'CNFAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.59596,
        longitude: 108.39265,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 8740741,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16807',
      geometry: { type: 'Point', coordinates: [58.56663729, 23.62772442] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Qaboos',
        code: 'OMOPQ',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.62772,
        longitude: 58.56664,
        iso3: 'OMN',
        iso3_op: 'OMN',
        country: 'Oman',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 287286,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16808',
      geometry: { type: 'Point', coordinates: [9.43754748, 0.40435339] },
      geometry_name: 'shape',
      properties: {
        portname: 'Libreville',
        code: 'GALBV',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.40435,
        longitude: 9.43755,
        iso3: 'GAB',
        iso3_op: 'GAB COD',
        country: 'Gabon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2399697,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16809',
      geometry: { type: 'Point', coordinates: [9.50772321, 0.28722042] },
      geometry_name: 'shape',
      properties: {
        portname: 'Owendo',
        code: 'GAOWE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 0.28722,
        longitude: 9.50772,
        iso3: 'GAB',
        iso3_op: 'GAB',
        country: 'Gabon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2396657,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16810',
      geometry: { type: 'Point', coordinates: [42.62882759, 15.18496787] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Isa (Shore Tanks)',
        code: 'YERAI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.18497,
        longitude: 42.62883,
        iso3: 'YEM',
        iso3_op: '',
        country: 'Yemen',
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 74406,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16811',
      geometry: { type: 'Point', coordinates: [3.18117261, 42.25709985] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rosas',
        code: 'ESROS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.2571,
        longitude: 3.18117,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3111348,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16812',
      geometry: { type: 'Point', coordinates: [27.23430271, 31.35660131] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mersa Matruh',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.3566,
        longitude: 27.2343,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 352733,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16813',
      geometry: { type: 'Point', coordinates: [95.52619347, 5.59624526] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malahayati',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.59625,
        longitude: 95.52619,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6725508,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16814',
      geometry: { type: 'Point', coordinates: [97.14627125, 5.16986188] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lhokseumawe',
        code: 'IDLSW',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.16986,
        longitude: 97.14627,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1214658,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16815',
      geometry: { type: 'Point', coordinates: [114.43743055, -8.16132643] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gilimanuk',
        code: 'IDGIL',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.16133,
        longitude: 114.43743,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1644079,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16816',
      geometry: { type: 'Point', coordinates: [115.50860424, -8.53394083] },
      geometry_name: 'shape',
      properties: {
        portname: 'Padangbai',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.53394,
        longitude: 115.5086,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1633411,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16817',
      geometry: { type: 'Point', coordinates: [13.43335532, -5.84406125] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ango-Ango',
        code: 'CDANG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.84406,
        longitude: 13.43336,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2317520,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16818',
      geometry: { type: 'Point', coordinates: [-80.0091462, 22.94454173] },
      geometry_name: 'shape',
      properties: {
        portname: 'Isabela De Sagua',
        code: 'CUIDS',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.94454,
        longitude: -80.00915,
        iso3: null,
        iso3_op: 'CUB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3556629,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16819',
      geometry: { type: 'Point', coordinates: [-79.55109341, 21.6334082] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tunas De Zaza',
        code: 'CUTDZ',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.63341,
        longitude: -79.55109,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3534827,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16820',
      geometry: { type: 'Point', coordinates: [-76.29212129, -13.8019482] },
      geometry_name: 'shape',
      properties: {
        portname: 'General San Martin',
        code: 'PEGSM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -13.80195,
        longitude: -76.29212,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3931478,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16821',
      geometry: { type: 'Point', coordinates: [49.1527278, -18.84178256] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ambila Lemaitso',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -18.84178,
        longitude: 49.15273,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1082263,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16822',
      geometry: { type: 'Point', coordinates: [49.01665528, -19.15496524] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vohitramposina',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -19.15497,
        longitude: 49.01666,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1053690,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16823',
      geometry: { type: 'Point', coordinates: [-3.99991974, 5.25416441] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Bouet',
        code: 'CIPBT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.25416,
        longitude: -3.99992,
        iso3: 'CIV',
        iso3_op: 'CIV',
        country: "Côte d'Ivoire",
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2282335,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16824',
      geometry: { type: 'Point', coordinates: [122.70814417, 10.77580717] },
      geometry_name: 'shape',
      properties: {
        portname: 'Naluuyan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 10.77581,
        longitude: 122.70814,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 7418027,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16825',
      geometry: { type: 'Point', coordinates: [3.7342918, 51.08140988] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ghent',
        code: 'BEGNE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.08141,
        longitude: 3.73429,
        iso3: 'BEL',
        iso3_op: 'BEL',
        country: 'Belgium',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2797656,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16826',
      geometry: { type: 'Point', coordinates: [18.12961942, -0.97304425] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ikoko-Mutaka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -0.97304,
        longitude: 18.12962,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2315523,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16827',
      geometry: { type: 'Point', coordinates: [19.78670511, -0.8199628] },
      geometry_name: 'shape',
      properties: {
        portname: 'Imbanga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -0.81996,
        longitude: 19.78671,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2315464,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16828',
      geometry: { type: 'Point', coordinates: [-66.06647126, 45.26220224] },
      geometry_name: 'shape',
      properties: {
        portname: 'St John',
        code: 'CASJB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.2622,
        longitude: -66.06647,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6138517,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16829',
      geometry: { type: 'Point', coordinates: [-65.72840105, 49.22576798] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mont Louis',
        code: 'CAMOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.22577,
        longitude: -65.7284,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6077140,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16830',
      geometry: { type: 'Point', coordinates: [-71.28488523, 41.5874896] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mellville',
        code: 'USMVI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.58749,
        longitude: -71.28489,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5223324,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16831',
      geometry: { type: 'Point', coordinates: [3.77778648, 51.15091949] },
      geometry_name: 'shape',
      properties: {
        portname: 'Doornzelle',
        code: 'BEDOE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.15092,
        longitude: 3.77779,
        iso3: 'BEL',
        iso3_op: 'BEL',
        country: 'Belgium',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2799270,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16832',
      geometry: { type: 'Point', coordinates: [-44.31714792, -23.01014452] },
      geometry_name: 'shape',
      properties: {
        portname: 'Angra Dos Reis',
        code: 'BRADR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -23.01014,
        longitude: -44.31715,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3472177,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16833',
      geometry: { type: 'Point', coordinates: [50.25076633, 29.84134763] },
      geometry_name: 'shape',
      properties: {
        portname: 'Imam Hasan',
        code: 'IRIMH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.84135,
        longitude: 50.25077,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 135446,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16834',
      geometry: { type: 'Point', coordinates: [32.39073988, 30.27785461] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fanara',
        code: 'EGFAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.27785,
        longitude: 32.39074,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 357004,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16835',
      geometry: { type: 'Point', coordinates: [9.82514281, 2.33994598] },
      geometry_name: 'shape',
      properties: {
        portname: 'Campo',
        code: 'CMCMO',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.33995,
        longitude: 9.82514,
        iso3: 'GNQ',
        iso3_op: '',
        country: 'Equatorial Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2233381,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16836',
      geometry: { type: 'Point', coordinates: [69.8272338, 22.4312166] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sika',
        code: 'INSIK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.43122,
        longitude: 69.82723,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1256333,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16837',
      geometry: { type: 'Point', coordinates: [133.06121303, 35.46685005] },
      geometry_name: 'shape',
      properties: {
        portname: 'Matsue',
        code: 'JPMTE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.46685,
        longitude: 133.06121,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1857550,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16838',
      geometry: { type: 'Point', coordinates: [-67.43570428, 7.88212989] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Fernando De Apure',
        code: 'VESFD',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 7.88213,
        longitude: -67.4357,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3805673,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16839',
      geometry: { type: 'Point', coordinates: [124.05041, 6.55228] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalamansig',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.55228,
        longitude: 124.05041,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1709717,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16840',
      geometry: { type: 'Point', coordinates: [-61.52481008, 10.65626854] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Of Spain',
        code: 'TTPOS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.65627,
        longitude: -61.52481,
        iso3: 'TTO',
        iso3_op: 'TTO',
        country: 'Trinidad and Tobago',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3573890,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16841',
      geometry: { type: 'Point', coordinates: [-61.73232458, 15.99680154] },
      geometry_name: 'shape',
      properties: {
        portname: 'Basse Terre',
        code: 'GPBBR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.9968,
        longitude: -61.73232,
        iso3: 'GLP',
        iso3_op: 'GLP',
        country: 'Guadeloupe',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3579732,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16842',
      geometry: { type: 'Point', coordinates: [-81.38371315, 19.29552827] },
      geometry_name: 'shape',
      properties: {
        portname: 'Georgetown',
        code: 'KYGEC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.29553,
        longitude: -81.38371,
        iso3: 'CYM',
        iso3_op: 'CYM',
        country: 'Cayman Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3580661,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16843',
      geometry: { type: 'Point', coordinates: [100.90117134, 13.06917001] },
      geometry_name: 'shape',
      properties: {
        portname: 'Laem Chabang',
        code: 'THLCH',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.06917,
        longitude: 100.90117,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1897926,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16844',
      geometry: { type: 'Point', coordinates: [56.36604999, 25.34799685] },
      geometry_name: 'shape',
      properties: {
        portname: 'Khor Al Fakkan',
        code: 'AEKLF',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.348,
        longitude: 56.36605,
        iso3: 'ARE',
        iso3_op: 'ARE',
        country: 'United Arab Emirates',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 291696,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16845',
      geometry: { type: 'Point', coordinates: [-175.17951786, -21.13804861] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nukualofa',
        code: 'TOTBU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -21.13805,
        longitude: -175.17952,
        iso3: null,
        iso3_op: 'TON',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4032402,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16846',
      geometry: { type: 'Point', coordinates: [104.83945611, -2.98837396] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pladju',
        code: 'IDPLA',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.98837,
        longitude: 104.83946,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1631033,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16847',
      geometry: { type: 'Point', coordinates: [26.23457476, 60.45163006] },
      geometry_name: 'shape',
      properties: {
        portname: 'Loviisa',
        code: 'FILOV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.45163,
        longitude: 26.23457,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 647571,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16848',
      geometry: { type: 'Point', coordinates: [-84.37111228, 46.51652452] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sault Ste Marie',
        code: 'CASSM',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.51652,
        longitude: -84.37111,
        iso3: 'CAN',
        iso3_op: 'USA',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5009004,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16849',
      geometry: { type: 'Point', coordinates: [-58.43915567, 48.50018198] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stephenville',
        code: 'CAYJT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 48.50018,
        longitude: -58.43916,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6156245,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16850',
      geometry: { type: 'Point', coordinates: [-76.43000265, 36.97295251] },
      geometry_name: 'shape',
      properties: {
        portname: 'Norfolk-Newport News',
        code: 'USNEN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.97295,
        longitude: -76.43,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4776024,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16851',
      geometry: { type: 'Point', coordinates: [19.25643594, 1.14096019] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wenge',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.14096,
        longitude: 19.25644,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2310945,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16852',
      geometry: { type: 'Point', coordinates: [8.93749148, 42.64178599] },
      geometry_name: 'shape',
      properties: {
        portname: "L'Ile Rousse",
        code: 'FRILR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.64179,
        longitude: 8.93749,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2998330,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16853',
      geometry: { type: 'Point', coordinates: [97.60954678, 1.3058992] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gunung Sitoli',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.3059,
        longitude: 97.60955,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1215102,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16854',
      geometry: { type: 'Point', coordinates: [16.10493644, 59.42941964] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kungsor',
        code: 'SEKGR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.42942,
        longitude: 16.10494,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2698681,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16855',
      geometry: { type: 'Point', coordinates: [19.09395599, -0.39328403] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bepumba',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.39328,
        longitude: 19.09396,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2317177,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16856',
      geometry: { type: 'Point', coordinates: [35.61584577, 33.9861461] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jounie',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.98615,
        longitude: 35.61585,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Commercial',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6941107,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16857',
      geometry: { type: 'Point', coordinates: [31.9534564, 46.83687159] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oktyabrsk',
        code: 'UAOCT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 46.83687,
        longitude: 31.95346,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-03T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 709022,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16858',
      geometry: { type: 'Point', coordinates: [8.78450625, -0.71496175] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Gentil',
        code: 'GAPOG',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.71496,
        longitude: 8.78451,
        iso3: 'GAB',
        iso3_op: 'GAB',
        country: 'Gabon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2396518,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16859',
      geometry: { type: 'Point', coordinates: [0.59429937, 40.617424] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Carlos De La Rapita',
        code: 'ESSCR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.61742,
        longitude: 0.5943,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3110821,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16860',
      geometry: { type: 'Point', coordinates: [114.83217821, -8.18848365] },
      geometry_name: 'shape',
      properties: {
        portname: 'Celukan Bawang',
        code: 'IDCEB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.18848,
        longitude: 114.83218,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1647248,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16861',
      geometry: { type: 'Point', coordinates: [-70.01186665, 18.42217826] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rio Haina',
        code: 'DOHAI',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.42218,
        longitude: -70.01187,
        iso3: 'DOM',
        iso3_op: 'DOM',
        country: 'Dominican Republic',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3504833,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16862',
      geometry: { type: 'Point', coordinates: [22.79130115, 1.09329871] },
      geometry_name: 'shape',
      properties: {
        portname: 'Limune',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 1.0933,
        longitude: 22.7913,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 211780,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16863',
      geometry: { type: 'Point', coordinates: [19.083119, 1.10897557] },
      geometry_name: 'shape',
      properties: {
        portname: 'Losambo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.10898,
        longitude: 19.08312,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2313523,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16864',
      geometry: { type: 'Point', coordinates: [33.82883911, 31.15312129] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Arish',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.15312,
        longitude: 33.82884,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 361546,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16865',
      geometry: { type: 'Point', coordinates: [0.46764546, 40.4659672] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vinaroz',
        code: 'ESVZR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 40.46597,
        longitude: 0.46765,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3104584,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16866',
      geometry: { type: 'Point', coordinates: [22.6156641, 38.91218294] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stylis',
        code: 'GRSYS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.91218,
        longitude: 22.61566,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 253170,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16867',
      geometry: { type: 'Point', coordinates: [16.3818376, 56.52687551] },
      geometry_name: 'shape',
      properties: {
        portname: 'Morbylanga',
        code: 'SEMOR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 56.52688,
        longitude: 16.38184,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2691348,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16868',
      geometry: { type: 'Point', coordinates: [30.66402468, 46.31460736] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ilyichevsk',
        code: 'UAILK',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 46.31461,
        longitude: 30.66402,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 707688,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16869',
      geometry: { type: 'Point', coordinates: [49.10643632, -18.96114012] },
      geometry_name: 'shape',
      properties: {
        portname: 'Anevoranto',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -18.96114,
        longitude: 49.10644,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1076227,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16870',
      geometry: { type: 'Point', coordinates: [-91.8449, 18.64803387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ciudad Del Carmen',
        code: 'MXCME',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.64803,
        longitude: -91.8449,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3534827,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16871',
      geometry: { type: 'Point', coordinates: [124.66257868, 8.49514558] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cagayan De Oro',
        code: 'PHCGY',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 8.49515,
        longitude: 124.66258,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: '2013-12-07T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1721080,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16872',
      geometry: { type: 'Point', coordinates: [-6.61914815, 4.73874363] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Pedro',
        code: 'CISPY',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.73874,
        longitude: -6.61915,
        iso3: 'CIV',
        iso3_op: 'CIV',
        country: "Côte d'Ivoire",
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2282006,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16873',
      geometry: { type: 'Point', coordinates: [-83.81240207, 45.42246971] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rogers',
        code: 'USRJM',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.42247,
        longitude: -83.8124,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5007477,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16874',
      geometry: { type: 'Point', coordinates: [42.55279541, -0.38245871] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kismayu',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.38246,
        longitude: 42.5528,
        iso3: 'SOM',
        iso3_op: 'SOM',
        country: 'Somalia',
        lastcheckdate: '2013-11-07T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 55671,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16875',
      geometry: { type: 'Point', coordinates: [52.35125997, 27.66053644] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bandar Taheri',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.66054,
        longitude: 52.35126,
        iso3: null,
        iso3_op: 'IRN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 113596,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16876',
      geometry: { type: 'Point', coordinates: [-5.36361421, 13.95950592] },
      geometry_name: 'shape',
      properties: {
        portname: 'Macina',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.95951,
        longitude: -5.36361,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2455465,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16877',
      geometry: { type: 'Point', coordinates: [19.90304113, 63.61046888] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hornefors',
        code: 'SEHNF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.61047,
        longitude: 19.90304,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2704956,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16878',
      geometry: { type: 'Point', coordinates: [119.362918, 35.10048866] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lanshan',
        code: 'CNLSN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.10049,
        longitude: 119.36292,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1804461,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16879',
      geometry: { type: 'Point', coordinates: [29.04161392, -2.09502867] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chikoma',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -2.09503,
        longitude: 29.04161,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 205598,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16880',
      geometry: { type: 'Point', coordinates: [33.50763761, 44.61474722] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sevastopol',
        code: 'UASVP',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 44.61475,
        longitude: 33.50764,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 694423,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16881',
      geometry: { type: 'Point', coordinates: [36.77622971, 46.75370301] },
      geometry_name: 'shape',
      properties: {
        portname: 'Berdyansk',
        code: 'UAERD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 46.7537,
        longitude: 36.77623,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 712451,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16882',
      geometry: { type: 'Point', coordinates: [28.85054311, 45.32231438] },
      geometry_name: 'shape',
      properties: {
        portname: 'Izmail',
        code: 'UAIZM',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 45.32231,
        longitude: 28.85054,
        iso3: 'ROU',
        iso3_op: 'UKR',
        country: 'Romania',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 707308,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16883',
      geometry: { type: 'Point', coordinates: [33.37368524, 45.18808622] },
      geometry_name: 'shape',
      properties: {
        portname: 'Evpatoria',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 45.18809,
        longitude: 33.37369,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 688105,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16884',
      geometry: { type: 'Point', coordinates: [35.38766873, 45.02829185] },
      geometry_name: 'shape',
      properties: {
        portname: 'Feodosia',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 45.02829,
        longitude: 35.38767,
        iso3: null,
        iso3_op: 'UKR',
        country: null,
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 709161,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16885',
      geometry: { type: 'Point', coordinates: [17.70178676, -4.40005993] },
      geometry_name: 'shape',
      properties: {
        portname: "N'Gongo",
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.40006,
        longitude: 17.70179,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2315680,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16886',
      geometry: { type: 'Point', coordinates: [56.73827882, 24.37608695] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sohar',
        code: 'OMSOH',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.37609,
        longitude: 56.73828,
        iso3: 'OMN',
        iso3_op: 'OMN',
        country: 'Oman',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 286282,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16887',
      geometry: { type: 'Point', coordinates: [18.10395446, 2.11817991] },
      geometry_name: 'shape',
      properties: {
        portname: 'Imesse',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 2.11818,
        longitude: 18.10395,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2315460,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16888',
      geometry: { type: 'Point', coordinates: [18.61262245, -3.60138194] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mabenga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -3.60138,
        longitude: 18.61262,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2313241,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16889',
      geometry: { type: 'Point', coordinates: [49.18217544, 11.29018367] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bosaso',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 11.29018,
        longitude: 49.18218,
        iso3: null,
        iso3_op: 'SOM',
        country: null,
        lastcheckdate: '2013-11-07T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 64013,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16890',
      geometry: { type: 'Point', coordinates: [34.63525056, 36.79996813] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mersin',
        code: 'TRMER',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 36.79997,
        longitude: 34.63525,
        iso3: 'TUR',
        iso3_op: 'SYR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 304531,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16891',
      geometry: { type: 'Point', coordinates: [19.78875507, 0.41659457] },
      geometry_name: 'shape',
      properties: {
        portname: 'Balangala',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.41659,
        longitude: 19.78876,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2317470,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16892',
      geometry: { type: 'Point', coordinates: [22.7540303, -0.78077861] },
      geometry_name: 'shape',
      properties: {
        portname: 'Modombe',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.78078,
        longitude: 22.75403,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 208761,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16893',
      geometry: { type: 'Point', coordinates: [-67.05325487, 45.07353407] },
      geometry_name: 'shape',
      properties: {
        portname: 'St Andrews',
        code: 'CASAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.07353,
        longitude: -67.05325,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6137411,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16894',
      geometry: { type: 'Point', coordinates: [-75.81070304, 39.52613697] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chesapeake',
        code: 'USUFG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.52614,
        longitude: -75.8107,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4351189,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16895',
      geometry: { type: 'Point', coordinates: [23.5975437, 38.0117854] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skaramanga',
        code: 'GRSKA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.01179,
        longitude: 23.59754,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 253688,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16896',
      geometry: { type: 'Point', coordinates: [12.87754351, 38.02913974] },
      geometry_name: 'shape',
      properties: {
        portname: 'Castellammare Del Golfo',
        code: 'ITCTR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.02914,
        longitude: 12.87754,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2525095,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16897',
      geometry: { type: 'Point', coordinates: [1.0133, 49.38353387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Petit Couronne',
        code: 'FRPET',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.38353,
        longitude: 1.0133,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3002918,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16898',
      geometry: { type: 'Point', coordinates: [-0.89105483, 45.94238702] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tonnay Charente',
        code: 'FRTON',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.94239,
        longitude: -0.89105,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2972470,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16899',
      geometry: { type: 'Point', coordinates: [10.09528833, 34.28795789] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Skhirra',
        code: 'TNLSK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.28796,
        longitude: 10.09529,
        iso3: 'TUN',
        iso3_op: 'TUN',
        country: 'Tunisia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2473161,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16900',
      geometry: { type: 'Point', coordinates: [55.45798489, -4.62039284] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Victoria',
        code: 'SCPOV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.62039,
        longitude: 55.45798,
        iso3: null,
        iso3_op: 'SYC',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 241131,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16901',
      geometry: { type: 'Point', coordinates: [55.46093171, 25.41130956] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ajman',
        code: 'AEAJM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.41131,
        longitude: 55.46093,
        iso3: 'ARE',
        iso3_op: 'ARE',
        country: 'United Arab Emirates',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 292932,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16902',
      geometry: { type: 'Point', coordinates: [134.58713729, 34.04844974] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tokushima',
        code: 'JPTKS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.04845,
        longitude: 134.58714,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1850158,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16903',
      geometry: { type: 'Point', coordinates: [153.12751337, -26.80515105] },
      geometry_name: 'shape',
      properties: {
        portname: 'Caloundra Head',
        code: 'AUCAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -26.80515,
        longitude: 153.12751,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2172710,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16904',
      geometry: { type: 'Point', coordinates: [76.54088537, 8.9383384] },
      geometry_name: 'shape',
      properties: {
        portname: 'Neendhakara',
        code: 'INNEE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.93834,
        longitude: 76.54089,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1261323,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16905',
      geometry: { type: 'Point', coordinates: [127.91148785, 26.31869929] },
      geometry_name: 'shape',
      properties: {
        portname: 'Heianza',
        code: 'JPHEI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.3187,
        longitude: 127.91149,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1862933,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16906',
      geometry: { type: 'Point', coordinates: [143.36256969, 44.35532748] },
      geometry_name: 'shape',
      properties: {
        portname: 'Monbetsu',
        code: 'JPMBE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.35533,
        longitude: 143.36257,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2129163,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16907',
      geometry: { type: 'Point', coordinates: [40.5259166, 64.52186046] },
      geometry_name: 'shape',
      properties: {
        portname: 'Arkhangelsk',
        code: 'RUARH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.52186,
        longitude: 40.52592,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 581049,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16908',
      geometry: { type: 'Point', coordinates: [132.41769216, 33.45785833] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yawatahama',
        code: 'JPYWH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.45786,
        longitude: 132.41769,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 7303003,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16909',
      geometry: { type: 'Point', coordinates: [139.85199606, 39.87451295] },
      geometry_name: 'shape',
      properties: {
        portname: 'Funakawa',
        code: 'JPFNK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.87451,
        longitude: 139.852,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1863898,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16910',
      geometry: { type: 'Point', coordinates: [141.8560392, 46.67183659] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nevelsk',
        code: 'RUNEV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.67184,
        longitude: 141.85604,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2122894,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16911',
      geometry: { type: 'Point', coordinates: [-44.30709831, -2.52908889] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saa Luiz',
        code: 'BRSLZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.52909,
        longitude: -44.3071,
        iso3: null,
        iso3_op: 'BRA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3388368,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16912',
      geometry: { type: 'Point', coordinates: [83.45317344, 17.89361353] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bheemunipatnam',
        code: 'INBHM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.89361,
        longitude: 83.45317,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1275930,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16913',
      geometry: { type: 'Point', coordinates: [26.55638564, 39.09996574] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mytilene',
        code: 'GRMJT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.09997,
        longitude: 26.55639,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 256866,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16914',
      geometry: { type: 'Point', coordinates: [34.55747339, 31.68119082] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ashkelon',
        code: 'ILAKL',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.68119,
        longitude: 34.55747,
        iso3: 'ISR',
        iso3_op: 'ISR',
        country: 'Israel',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 295620,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16915',
      geometry: { type: 'Point', coordinates: [-72.8211788, -45.46551953] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chacabuco',
        code: 'CLCHB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -45.46552,
        longitude: -72.82118,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3950166,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16916',
      geometry: { type: 'Point', coordinates: [-88.61544201, 15.69533387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santo Tomas De Castilla',
        code: 'GTSTC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.69533,
        longitude: -88.61544,
        iso3: null,
        iso3_op: 'GTM',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3591059,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16917',
      geometry: { type: 'Point', coordinates: [20.99471958, 56.52254692] },
      geometry_name: 'shape',
      properties: {
        portname: 'Liepaya',
        code: 'LVLPX',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.52255,
        longitude: 20.99472,
        iso3: null,
        iso3_op: 'LVA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 457954,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16918',
      geometry: { type: 'Point', coordinates: [100.80991823, 13.16067093] },
      geometry_name: 'shape',
      properties: {
        portname: 'Koh Si Chang',
        code: 'THKSI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.16067,
        longitude: 100.80992,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1609479,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16919',
      geometry: { type: 'Point', coordinates: [34.32535197, 27.90569942] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sharm El Sheikh',
        code: 'EGSSH',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.9057,
        longitude: 34.32535,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 349340,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16920',
      geometry: { type: 'Point', coordinates: [56.35600041, 25.12638073] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fujairah',
        code: 'AEFJR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.12638,
        longitude: 56.356,
        iso3: 'ARE',
        iso3_op: 'ARE',
        country: 'United Arab Emirates',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 292878,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16921',
      geometry: { type: 'Point', coordinates: [56.44634558, 27.09374559] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hormuz',
        code: 'IRHOR',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.09375,
        longitude: 56.44635,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 131231,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16922',
      geometry: { type: 'Point', coordinates: [10.8667292, 53.95574683] },
      geometry_name: 'shape',
      properties: {
        portname: 'Travemunde',
        code: 'DETRV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.95575,
        longitude: 10.86673,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2863951,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16923',
      geometry: { type: 'Point', coordinates: [35.62828119, 34.15213517] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aamshite',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.15214,
        longitude: 35.62828,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational, Fuel Oil And Others',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 278944,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16924',
      geometry: { type: 'Point', coordinates: [-76.21676773, 36.29650759] },
      geometry_name: 'shape',
      properties: {
        portname: 'Elizabeth',
        code: 'USELZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.29651,
        longitude: -76.21677,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4465088,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16925',
      geometry: { type: 'Point', coordinates: [-2.01737876, 48.64372246] },
      geometry_name: 'shape',
      properties: {
        portname: 'St Malo',
        code: 'FRSML',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.64372,
        longitude: -2.01738,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2978640,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16926',
      geometry: { type: 'Point', coordinates: [23.82682478, 40.50866423] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stratoni',
        code: 'GRSTI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.50866,
        longitude: 23.82682,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 734133,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16927',
      geometry: { type: 'Point', coordinates: [15.67036178, 68.18324194] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tranoy',
        code: 'NOTRY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 68.18324,
        longitude: 15.67036,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3134055,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16928',
      geometry: { type: 'Point', coordinates: [-8.85337329, 40.14812484] },
      geometry_name: 'shape',
      properties: {
        portname: 'Figueira Da Foz',
        code: 'PTFDF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.14812,
        longitude: -8.85337,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Portugal',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2739590,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16929',
      geometry: { type: 'Point', coordinates: [39.22024459, -4.65991887] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vanga',
        code: null,
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.65992,
        longitude: 39.22024,
        iso3: 'KEN',
        iso3_op: 'KEN',
        country: 'Kenya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 178575,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16930',
      geometry: { type: 'Point', coordinates: [45.10066477, -19.78099839] },
      geometry_name: 'shape',
      properties: {
        portname: 'Begiro',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -19.781,
        longitude: 45.10066,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1068264,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16931',
      geometry: { type: 'Point', coordinates: [123.82337951, 9.85146179] },
      geometry_name: 'shape',
      properties: {
        portname: 'Catagbacan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.85146,
        longitude: 123.82338,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1718016,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16932',
      geometry: { type: 'Point', coordinates: [-73.51103594, 18.52772269] },
      geometry_name: 'shape',
      properties: {
        portname: 'Petit Trou De Nippes',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Planned',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.52772,
        longitude: -73.51104,
        iso3: 'HTI',
        iso3_op: 'HTI',
        country: 'Haiti',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3718938,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16933',
      geometry: { type: 'Point', coordinates: [18.73107008, -4.67439509] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mitshakilaf',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.6744,
        longitude: 18.73107,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2312740,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16934',
      geometry: { type: 'Point', coordinates: [18.36763497, -2.85833583] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bokoko',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.85834,
        longitude: 18.36763,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2316831,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16935',
      geometry: { type: 'Point', coordinates: [-63.60624205, 50.23702924] },
      geometry_name: 'shape',
      properties: {
        portname: 'Havre St Pierre',
        code: 'CAHSP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.23703,
        longitude: -63.60624,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5972291,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16936',
      geometry: { type: 'Point', coordinates: [-70.8728099, 42.81308392] },
      geometry_name: 'shape',
      properties: {
        portname: 'Newbury',
        code: 'USNEY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.81308,
        longitude: -70.87281,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4945256,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16937',
      geometry: { type: 'Point', coordinates: [4.58720653, 52.46209061] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ijmuiden',
        code: 'NLIJM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.46209,
        longitude: 4.58721,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2753376,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16938',
      geometry: { type: 'Point', coordinates: [16.64420452, 57.75796296] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vastervik',
        code: 'SEVVK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.75796,
        longitude: 16.6442,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2664203,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16939',
      geometry: { type: 'Point', coordinates: [33.11860715, 29.044251] },
      geometry_name: 'shape',
      properties: {
        portname: 'Abu Zenima',
        code: 'EGAZA',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.04425,
        longitude: 33.11861,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 361930,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16940',
      geometry: { type: 'Point', coordinates: [5.5017679, 59.78070547] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lervik',
        code: 'NOLER',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.78071,
        longitude: 5.50177,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3147822,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16941',
      geometry: { type: 'Point', coordinates: [99.45485344, 3.35692693] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kuala Tanjung',
        code: 'IDTJB',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 3.35693,
        longitude: 99.45485,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1214836,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16942',
      geometry: { type: 'Point', coordinates: [99.80361243, 2.97848004] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanjung Balai',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.97848,
        longitude: 99.80361,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1213547,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16943',
      geometry: { type: 'Point', coordinates: [-76.19090962, -13.47125418] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tambo De Mora',
        code: 'PETDM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -13.47125,
        longitude: -76.19091,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3927962,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16944',
      geometry: { type: 'Point', coordinates: [-8.22985412, 43.47763229] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Ferrol',
        code: 'ESFER',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.47763,
        longitude: -8.22985,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3123493,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16945',
      geometry: { type: 'Point', coordinates: [132.71027502, 33.85073556] },
      geometry_name: 'shape',
      properties: {
        portname: 'Matsuyama',
        code: 'JPMYJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.85074,
        longitude: 132.71028,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1926091,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16946',
      geometry: { type: 'Point', coordinates: [123.75611567, 13.1460673] },
      geometry_name: 'shape',
      properties: {
        portname: 'Legazpi',
        code: 'PHLGP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.14607,
        longitude: 123.75612,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1706889,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16947',
      geometry: { type: 'Point', coordinates: [140.08939211, 35.60926498] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chiba',
        code: 'JPCHB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.60926,
        longitude: 140.08939,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2113015,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16948',
      geometry: { type: 'Point', coordinates: [177.50746473, 64.73830309] },
      geometry_name: 'shape',
      properties: {
        portname: 'Anadyr',
        code: 'RUDYR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.7383,
        longitude: 177.50746,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2127202,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16949',
      geometry: { type: 'Point', coordinates: [136.60803401, 36.62588628] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kanazawa',
        code: 'JPKNZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.62589,
        longitude: 136.60803,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1860243,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16950',
      geometry: { type: 'Point', coordinates: [139.72479302, 35.24016839] },
      geometry_name: 'shape',
      properties: {
        portname: 'Uraga',
        code: 'JPURG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.24017,
        longitude: 139.72479,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1849221,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16951',
      geometry: { type: 'Point', coordinates: [133.27879245, 33.38182477] },
      geometry_name: 'shape',
      properties: {
        portname: 'Suzaki',
        code: 'JPSUZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.38182,
        longitude: 133.27879,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1851390,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16952',
      geometry: { type: 'Point', coordinates: [141.17625692, 41.28055545] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ominato',
        code: 'JPOMT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.28056,
        longitude: 141.17626,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2128680,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16953',
      geometry: { type: 'Point', coordinates: [40.24670672, 20.15811372] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lith',
        code: 'SALIT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.15811,
        longitude: 40.24671,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 109253,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16954',
      geometry: { type: 'Point', coordinates: [139.0666408, 37.93576598] },
      geometry_name: 'shape',
      properties: {
        portname: 'Niigata',
        code: 'JPKIJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.93577,
        longitude: 139.06664,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1855431,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16955',
      geometry: { type: 'Point', coordinates: [-61.4596, 10.31553387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pointe A Pierre',
        code: 'TTPTP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.31553,
        longitude: -61.4596,
        iso3: 'TTO',
        iso3_op: 'TTO',
        country: 'Trinidad and Tobago',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3573902,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16956',
      geometry: { type: 'Point', coordinates: [-77.54486403, 25.01814845] },
      geometry_name: 'shape',
      properties: {
        portname: 'Clifton Point',
        code: 'BSCLP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 25.01815,
        longitude: -77.54486,
        iso3: 'BHS',
        iso3_op: 'BHS',
        country: 'Bahamas',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3572636,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16957',
      geometry: { type: 'Point', coordinates: [-57.53676047, -38.03705409] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mar Del Plata',
        code: 'ARMDQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -38.03705,
        longitude: -57.53676,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3430863,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16958',
      geometry: { type: 'Point', coordinates: [-62.08932252, 10.63415506] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto De Hierro',
        code: 'VEPHO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.63416,
        longitude: -62.08932,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3629695,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16959',
      geometry: { type: 'Point', coordinates: [-60.17903621, -33.34919976] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Nicolas De Los Los Arroyos',
        code: 'ARSNS',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.3492,
        longitude: -60.17904,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3836846,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16960',
      geometry: { type: 'Point', coordinates: [-2.84824714, 53.99889272] },
      geometry_name: 'shape',
      properties: {
        portname: 'Glasson Dock',
        code: 'GBGLD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.99889,
        longitude: -2.84825,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2648562,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16961',
      geometry: { type: 'Point', coordinates: [-87.14394816, 41.64368905] },
      geometry_name: 'shape',
      properties: {
        portname: 'Burns Harbour',
        code: 'USBNB',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.64369,
        longitude: -87.14395,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4918559,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16962',
      geometry: { type: 'Point', coordinates: [50.12238266, 26.47390744] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dammam',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 26.47391,
        longitude: 50.12238,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 110336,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16963',
      geometry: { type: 'Point', coordinates: [18.44070915, -34.1923952] },
      geometry_name: 'shape',
      properties: {
        portname: 'Simonstown',
        code: 'ZASMN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.1924,
        longitude: 18.44071,
        iso3: 'ZAF',
        iso3_op: 'ZAF',
        country: 'South Africa',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3361618,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16964',
      geometry: { type: 'Point', coordinates: [-2.36717888, 16.74946463] },
      geometry_name: 'shape',
      properties: {
        portname: 'Modiokoy',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.74946,
        longitude: -2.36718,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2453413,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16965',
      geometry: { type: 'Point', coordinates: [-5.83369691, 13.68929129] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dioro',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.68929,
        longitude: -5.8337,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2458634,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16966',
      geometry: { type: 'Point', coordinates: [105.86167173, 21.02891658] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hanoi',
        code: 'VNHAN',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.02892,
        longitude: 105.86167,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1581130,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16967',
      geometry: { type: 'Point', coordinates: [44.69434957, -19.7054086] },
      geometry_name: 'shape',
      properties: {
        portname: 'Serinam',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -19.70541,
        longitude: 44.69435,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1056598,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16968',
      geometry: { type: 'Point', coordinates: [48.9914927, -19.25666021] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manakampahiny',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -19.25666,
        longitude: 48.99149,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1061663,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16969',
      geometry: { type: 'Point', coordinates: [-61.54062543, 16.24128198] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pointe-a-Pitre',
        code: 'GPPTP',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.24128,
        longitude: -61.54063,
        iso3: null,
        iso3_op: 'GLP',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3578599,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16970',
      geometry: { type: 'Point', coordinates: [20.6031, -0.27486613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bosanga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -0.27487,
        longitude: 20.6031,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 217662,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16971',
      geometry: { type: 'Point', coordinates: [-79.26283068, 43.20641373] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dalhousie',
        code: 'CADHS',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.20641,
        longitude: -79.26283,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6111712,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16972',
      geometry: { type: 'Point', coordinates: [18.3581, 1.21293387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ibeka',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 1.21293,
        longitude: 18.3581,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2315943,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16973',
      geometry: { type: 'Point', coordinates: [20.1921, -0.87196613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Makoko',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -0.87197,
        longitude: 20.1921,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 210021,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16974',
      geometry: { type: 'Point', coordinates: [35.7909638, 36.76783206] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yumurtalik Bay',
        code: 'TRYUM',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.76783,
        longitude: 35.79096,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 296137,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16975',
      geometry: { type: 'Point', coordinates: [-151.39268008, 60.68319134] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nikiski',
        code: 'USNKI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.68319,
        longitude: -151.39268,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5871931,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16976',
      geometry: { type: 'Point', coordinates: [-81.52384818, 30.80734291] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kings Bay',
        code: 'USKBA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.80734,
        longitude: -81.52385,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 7260333,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16977',
      geometry: { type: 'Point', coordinates: [-66.6367322, 50.19841096] },
      geometry_name: 'shape',
      properties: {
        portname: 'Clark City',
        code: 'CACLC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.19841,
        longitude: -66.63673,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5923168,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16978',
      geometry: { type: 'Point', coordinates: [40.98899682, 42.99385011] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sukhumi',
        code: 'GESUI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.99385,
        longitude: 40.989,
        iso3: 'GEO',
        iso3_op: 'GEO',
        country: 'Georgia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 611847,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16979',
      geometry: { type: 'Point', coordinates: [-74.22887393, 11.10897302] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pozos Colorados',
        code: 'COPOC',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.10897,
        longitude: -74.22887,
        iso3: null,
        iso3_op: 'COL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3671786,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16980',
      geometry: { type: 'Point', coordinates: [104.21933187, 1.06170596] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tandjung Uban',
        code: 'IDTUB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.06171,
        longitude: 104.21933,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1624815,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16981',
      geometry: { type: 'Point', coordinates: [106.14817175, -2.08497347] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pangkal Balam',
        code: 'IDPGX',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.08497,
        longitude: 106.14817,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1632659,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16982',
      geometry: { type: 'Point', coordinates: [119.62173863, -4.00265177] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pare Pare',
        code: 'IDPAP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.00265,
        longitude: 119.62174,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1632353,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16983',
      geometry: { type: 'Point', coordinates: [60.61411048, 25.29821805] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chah-Bahar',
        code: 'IRZBR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 25.29822,
        longitude: 60.61411,
        iso3: null,
        iso3_op: 'IRN,AFG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1161724,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16984',
      geometry: { type: 'Point', coordinates: [9.53174938, 53.62138159] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stadersand',
        code: 'DESTS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.62138,
        longitude: 9.53175,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2829855,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16985',
      geometry: { type: 'Point', coordinates: [132.43278721, 34.35740819] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hiroshima',
        code: 'JPHIJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.35741,
        longitude: 132.43279,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1862415,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16986',
      geometry: { type: 'Point', coordinates: [33.17508832, 28.89488715] },
      geometry_name: 'shape',
      properties: {
        portname: 'Abu Rudeis',
        code: 'EGAUE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.89489,
        longitude: 33.17509,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 362208,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16987',
      geometry: { type: 'Point', coordinates: [-13.41635309, 27.08950967] },
      geometry_name: 'shape',
      properties: {
        portname: 'Laayoune',
        code: 'MAEUN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.08951,
        longitude: -13.41635,
        iso3: 'ESH',
        iso3_op: 'ESH',
        country: 'Western Sahara',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2462881,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16988',
      geometry: { type: 'Point', coordinates: [106.69009956, 20.86793343] },
      geometry_name: 'shape',
      properties: {
        portname: 'Haiphong',
        code: 'VNHPH',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.86793,
        longitude: 106.6901,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: 'Hai Phong',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1581298,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16989',
      geometry: { type: 'Point', coordinates: [149.28740322, -21.28035334] },
      geometry_name: 'shape',
      properties: {
        portname: 'Haypoint',
        code: 'AUHPT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -21.28035,
        longitude: 149.2874,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2163754,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16990',
      geometry: { type: 'Point', coordinates: [111.31941467, 2.12392127] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanjong Mani',
        code: 'MYTMI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 2.12392,
        longitude: 111.31941,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1735825,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16991',
      geometry: { type: 'Point', coordinates: [129.65156413, 33.19166846] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ainoura',
        code: 'JPAIN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.19167,
        longitude: 129.65156,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1865664,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16992',
      geometry: { type: 'Point', coordinates: [35.86270205, 34.4573506] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Beddaoui',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.45735,
        longitude: 35.8627,
        iso3: 'LBN',
        iso3_op: 'LBN',
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 280401,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16993',
      geometry: { type: 'Point', coordinates: [-65.83230025, 18.04910318] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Yabucoa',
        code: 'PRPYA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.0491,
        longitude: -65.8323,
        iso3: 'PRI',
        iso3_op: 'PRI',
        country: 'Puerto Rico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4568908,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16994',
      geometry: { type: 'Point', coordinates: [-63.09302548, 18.17174431] },
      geometry_name: 'shape',
      properties: {
        portname: 'Blowing Point',
        code: 'AIBLP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.17174,
        longitude: -63.09303,
        iso3: null,
        iso3_op: 'AIA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3573496,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16995',
      geometry: { type: 'Point', coordinates: [-58.22112701, -32.47915877] },
      geometry_name: 'shape',
      properties: {
        portname: 'Concepcion Del Uruguay',
        code: 'ARCOU',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.47916,
        longitude: -58.22113,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3435264,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16996',
      geometry: { type: 'Point', coordinates: [-6.76976204, 62.00833664] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thorshavn',
        code: 'FOTHO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 62.00834,
        longitude: -6.76976,
        iso3: 'FRO',
        iso3_op: 'FRO',
        country: 'Faroe Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2611396,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16997',
      geometry: { type: 'Point', coordinates: [10.41312457, 54.88995362] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aeroskobing',
        code: 'DKARK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.88995,
        longitude: 10.41312,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2625001,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16998',
      geometry: { type: 'Point', coordinates: [99.93448789, 9.53836558] },
      geometry_name: 'shape',
      properties: {
        portname: 'Samui',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.53837,
        longitude: 99.93449,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1154689,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.16999',
      geometry: { type: 'Point', coordinates: [-69.56466513, 47.84997573] },
      geometry_name: 'shape',
      properties: {
        portname: 'Riviere Du Loup',
        code: 'CARDL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.84998,
        longitude: -69.56467,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6354897,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17000',
      geometry: { type: 'Point', coordinates: [-25.14960911, 36.94521938] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vila Do Porto',
        code: 'PTVDP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.94522,
        longitude: -25.14961,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Azores Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3372473,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17001',
      geometry: { type: 'Point', coordinates: [3.04850703, 43.02237022] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port La Nouvelle',
        code: 'FRNOU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.02237,
        longitude: 3.04851,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2985968,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17002',
      geometry: { type: 'Point', coordinates: [160.15325986, -9.10656525] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tulagi',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -9.10657,
        longitude: 160.15326,
        iso3: 'SLB',
        iso3_op: 'SLB',
        country: 'Solomon islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2102384,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17003',
      geometry: { type: 'Point', coordinates: [16.512849, -5.10664522] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kingushi Amont',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.10665,
        longitude: 16.51285,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2314366,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17004',
      geometry: { type: 'Point', coordinates: [21.08602016, -4.97685731] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bena Makina',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -4.97686,
        longitude: 21.08602,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 219147,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17005',
      geometry: { type: 'Point', coordinates: [-8.77592669, 42.59408438] },
      geometry_name: 'shape',
      properties: {
        portname: 'Villagarcia  (De Arosa)',
        code: 'ESVIL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.59408,
        longitude: -8.77593,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3105575,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17006',
      geometry: { type: 'Point', coordinates: [35.64464414, 34.00517242] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Maameltayne',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.00517,
        longitude: 35.64464,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 279636,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17007',
      geometry: { type: 'Point', coordinates: [106.40942936, 18.11108398] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vung Ang',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.11108,
        longitude: 106.40943,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1562788,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17008',
      geometry: { type: 'Point', coordinates: [103.95501242, 10.22465658] },
      geometry_name: 'shape',
      properties: {
        portname: 'Duong Dong',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.22466,
        longitude: 103.95501,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1569973,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17009',
      geometry: { type: 'Point', coordinates: [49.09501608, -18.97523873] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalomala',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -18.97524,
        longitude: 49.09502,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-01-21T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'APMF',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1063713,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17010',
      geometry: { type: 'Point', coordinates: [-54.76311992, 49.65045278] },
      geometry_name: 'shape',
      properties: {
        portname: 'Twillinggate',
        code: 'CATWI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.65045,
        longitude: -54.76312,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6170930,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17011',
      geometry: { type: 'Point', coordinates: [4.64663456, 52.46665065] },
      geometry_name: 'shape',
      properties: {
        portname: 'Velsen',
        code: 'NLVEL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.46665,
        longitude: 4.64663,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2745673,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17012',
      geometry: { type: 'Point', coordinates: [-76.08688798, 39.54573754] },
      geometry_name: 'shape',
      properties: {
        portname: 'Havre De Grace',
        code: 'USHDC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.54574,
        longitude: -76.08689,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4357617,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17013',
      geometry: { type: 'Point', coordinates: [1.59390634, 50.72564414] },
      geometry_name: 'shape',
      properties: {
        portname: 'Boulogne Sur Mer',
        code: 'FRBOL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.72564,
        longitude: 1.59391,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3031133,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17014',
      geometry: { type: 'Point', coordinates: [139.67625336, 35.43520983] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yokohama',
        code: 'JPYOK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.43521,
        longitude: 139.67625,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1848354,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17015',
      geometry: { type: 'Point', coordinates: [140.74569031, 40.82988763] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aomori',
        code: 'JPAOJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.82989,
        longitude: 140.74569,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2130658,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17016',
      geometry: { type: 'Point', coordinates: [130.80723486, 42.64953783] },
      geometry_name: 'shape',
      properties: {
        portname: 'Posyet',
        code: 'RUPSE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.64954,
        longitude: 130.80723,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2017728,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17017',
      geometry: { type: 'Point', coordinates: [131.45738071, 31.90512618] },
      geometry_name: 'shape',
      properties: {
        portname: 'Miyazaki',
        code: 'JPKMI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.90513,
        longitude: 131.45738,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1856717,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17018',
      geometry: { type: 'Point', coordinates: [110.4126608, 1.57743705] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sejingkat',
        code: 'MYSEJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.57744,
        longitude: 110.41266,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1743725,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17019',
      geometry: { type: 'Point', coordinates: [68.96939442, 22.23800291] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dwarkar',
        code: 'INDWA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.238,
        longitude: 68.96939,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1272140,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17020',
      geometry: { type: 'Point', coordinates: [97.9040857, 4.88476257] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kuala Beukah',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 4.88476,
        longitude: 97.90409,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1473455,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17021',
      geometry: { type: 'Point', coordinates: [98.2117403, 4.12407646] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pangkalang Susu',
        code: 'IDSSO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.12408,
        longitude: 98.21174,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1214297,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17022',
      geometry: { type: 'Point', coordinates: [105.99422022, -5.92286494] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanjung Sekong',
        code: 'IDMRK',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.92286,
        longitude: 105.99422,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1628108,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17023',
      geometry: { type: 'Point', coordinates: [142.51714087, 53.54714011] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moskalvo',
        code: 'RUMVO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.54714,
        longitude: 142.51714,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2123174,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17024',
      geometry: { type: 'Point', coordinates: [48.61447443, 28.10688938] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Al Mishab',
        code: 'SARAM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.10689,
        longitude: 48.61447,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 109113,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17025',
      geometry: { type: 'Point', coordinates: [-61.0601357, 14.60229297] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fort-De-France',
        code: 'MQFDF',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.60229,
        longitude: -61.06014,
        iso3: 'MTQ',
        iso3_op: 'MTQ',
        country: 'Martinique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3570675,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17026',
      geometry: { type: 'Point', coordinates: [-61.84589561, 17.12117103] },
      geometry_name: 'shape',
      properties: {
        portname: "St John'S",
        code: 'AGSJO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.12117,
        longitude: -61.8459,
        iso3: 'ATG',
        iso3_op: 'ATG',
        country: 'Antigua and Barbuda',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3576022,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17027',
      geometry: { type: 'Point', coordinates: [-6.66723511, 61.80558504] },
      geometry_name: 'shape',
      properties: {
        portname: 'Husevig',
        code: 'FOHUS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.80559,
        longitude: -6.66724,
        iso3: 'FRO',
        iso3_op: 'FRO',
        country: 'Faroe Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2619612,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17028',
      geometry: { type: 'Point', coordinates: [-72.34816471, 18.55709359] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port-Au-Prince',
        code: 'HTPAP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.55709,
        longitude: -72.34816,
        iso3: null,
        iso3_op: 'HTI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3718426,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17029',
      geometry: { type: 'Point', coordinates: [23.22352872, 59.88102493] },
      geometry_name: 'shape',
      properties: {
        portname: 'Koverhar',
        code: 'FIKVH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.88102,
        longitude: 23.22353,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 847321,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17030',
      geometry: { type: 'Point', coordinates: [136.55834198, 34.60975806] },
      geometry_name: 'shape',
      properties: {
        portname: 'Matsusaka',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.60976,
        longitude: 136.55834,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1857496,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17031',
      geometry: { type: 'Point', coordinates: [17.99487054, -33.00719668] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saldanha Bay',
        code: 'ZASDB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.0072,
        longitude: 17.99487,
        iso3: null,
        iso3_op: 'ZAF',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3361934,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17032',
      geometry: { type: 'Point', coordinates: [35.6415, 34.05716103] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Aaqaybe',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.05716,
        longitude: 35.6415,
        iso3: 'LBN',
        iso3_op: 'LBN',
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6279281,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17033',
      geometry: { type: 'Point', coordinates: [18.5506975, 1.74898168] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malanga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 1.74898,
        longitude: 18.5507,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2312641,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17034',
      geometry: { type: 'Point', coordinates: [122.48232569, 11.59597327] },
      geometry_name: 'shape',
      properties: {
        portname: 'Damaguit',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.59597,
        longitude: 122.48233,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1714194,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17035',
      geometry: { type: 'Point', coordinates: [-72.8312259, 19.94092847] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port-De-Paix',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.94093,
        longitude: -72.83123,
        iso3: 'HTI',
        iso3_op: 'HTI',
        country: 'Haiti',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3718420,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17036',
      geometry: { type: 'Point', coordinates: [-7.41402538, 37.19672828] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vila Real De Santo Antonio',
        code: 'PTVRL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.19673,
        longitude: -7.41403,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Portugal',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2261598,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17037',
      geometry: { type: 'Point', coordinates: [26.95759032, -7.42707183] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bundala',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -7.42707,
        longitude: 26.95759,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 212008,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17038',
      geometry: { type: 'Point', coordinates: [32.09899572, 1.69922624] },
      geometry_name: 'shape',
      properties: {
        portname: 'Masindi',
        code: null,
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.69923,
        longitude: 32.099,
        iso3: 'UGA',
        iso3_op: 'UGA',
        country: 'Uganda',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 229361,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17039',
      geometry: { type: 'Point', coordinates: [-2.10760906, 49.17666889] },
      geometry_name: 'shape',
      properties: {
        portname: 'St Helier',
        code: 'GBSTH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.17667,
        longitude: -2.10761,
        iso3: null,
        iso3_op: 'JEY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3042091,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17040',
      geometry: { type: 'Point', coordinates: [109.2462553, 13.77825982] },
      geometry_name: 'shape',
      properties: {
        portname: 'Qui Nhon',
        code: 'VNUIH',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.77826,
        longitude: 109.24626,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1568574,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17041',
      geometry: { type: 'Point', coordinates: [18.57209605, -2.96947397] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kolumboma',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.96947,
        longitude: 18.5721,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2314008,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17042',
      geometry: { type: 'Point', coordinates: [-66.8179614, 50.01902137] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Cartier',
        code: 'CAPCA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.01902,
        longitude: -66.81796,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6111696,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17043',
      geometry: { type: 'Point', coordinates: [-59.1417962, 47.58115567] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Aux Basques',
        code: 'CAPBQ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.58116,
        longitude: -59.1418,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5919815,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17044',
      geometry: { type: 'Point', coordinates: [-64.3540845, 49.81824774] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Menier',
        code: 'CAPME',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.81825,
        longitude: -64.35408,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6111957,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17045',
      geometry: { type: 'Point', coordinates: [50.63443443, 26.1446523] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sitra',
        code: 'BHSIT',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.14465,
        longitude: 50.63443,
        iso3: 'BHR',
        iso3_op: 'BHR',
        country: 'Bahrain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 290104,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17046',
      geometry: { type: 'Point', coordinates: [134.07005612, 34.34846873] },
      geometry_name: 'shape',
      properties: {
        portname: 'Takamatsu',
        code: 'JPTAK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.34847,
        longitude: 134.07006,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1851100,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17047',
      geometry: { type: 'Point', coordinates: [-20.26743476, 63.44383522] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vestmannaeyjar - Hofn',
        code: 'ISVES',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.44384,
        longitude: -20.26743,
        iso3: 'ISL',
        iso3_op: 'ISL',
        country: 'Iceland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3412093,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17048',
      geometry: { type: 'Point', coordinates: [0.3926109, 52.75973381] },
      geometry_name: 'shape',
      properties: {
        portname: "King'S Lynn",
        code: 'GBKLN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.75973,
        longitude: 0.39261,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2645456,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17049',
      geometry: { type: 'Point', coordinates: [3.22307625, 51.22815346] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bruges',
        code: 'BEBGS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.22815,
        longitude: 3.22308,
        iso3: 'BEL',
        iso3_op: 'BEL',
        country: 'Belgium',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2800931,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17050',
      geometry: { type: 'Point', coordinates: [-4.12710874, 52.9291366] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portmadoc',
        code: 'GBPMD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 52.92914,
        longitude: -4.12711,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2640045,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17051',
      geometry: { type: 'Point', coordinates: [113.89221436, 22.46200642] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chiwan',
        code: 'CNCWN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.46201,
        longitude: 113.89221,
        iso3: null,
        iso3_op: 'CHN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1814950,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17052',
      geometry: { type: 'Point', coordinates: [29.84303325, 31.15318317] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Meks',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.15318,
        longitude: 29.84303,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 360805,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17053',
      geometry: { type: 'Point', coordinates: [103.71966623, 1.30635367] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jurong',
        code: 'SGJUR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.30635,
        longitude: 103.71967,
        iso3: 'SGP',
        iso3_op: 'SGP',
        country: 'Singapore',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1881953,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17054',
      geometry: { type: 'Point', coordinates: [131.66476508, 32.43751514] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hosojima',
        code: 'JPHSM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.43752,
        longitude: 131.66477,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1862106,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17055',
      geometry: { type: 'Point', coordinates: [27.96166319, 40.35068544] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bandirmar',
        code: 'TRBDM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.35069,
        longitude: 27.96166,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 751077,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17056',
      geometry: { type: 'Point', coordinates: [48.52214307, 28.41756687] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Al Khafji',
        code: 'SARAR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.41757,
        longitude: 48.52214,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 102912,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17057',
      geometry: { type: 'Point', coordinates: [101.46473108, 0.54777905] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pakan Baru',
        code: 'IDPAK',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.54778,
        longitude: 101.46473,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1631761,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17058',
      geometry: { type: 'Point', coordinates: [-110.68389456, 24.3946395] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Juan De La Costa',
        code: 'MXSJC',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.39464,
        longitude: -110.68389,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3985884,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17059',
      geometry: { type: 'Point', coordinates: [18.58388737, 30.48744214] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Lanuf',
        code: 'LYRLA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.48744,
        longitude: 18.58389,
        iso3: null,
        iso3_op: 'LBY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2212984,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17060',
      geometry: { type: 'Point', coordinates: [102.15742552, 1.3443672] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sungai Pakning',
        code: 'IDSEQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.34437,
        longitude: 102.15743,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1625934,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17061',
      geometry: { type: 'Point', coordinates: [105.31802772, -5.47213802] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pandjang',
        code: 'IDSPA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.47214,
        longitude: 105.31803,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1632598,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17062',
      geometry: { type: 'Point', coordinates: [-82.34789477, 23.13668849] },
      geometry_name: 'shape',
      properties: {
        portname: 'Habana',
        code: 'CUHAV',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.13669,
        longitude: -82.34789,
        iso3: null,
        iso3_op: 'CUB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3553478,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17063',
      geometry: { type: 'Point', coordinates: [-75.83811678, 20.02725873] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santiago De Cuba',
        code: 'CUSCU',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.02726,
        longitude: -75.83812,
        iso3: null,
        iso3_op: 'CUB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3536729,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17064',
      geometry: { type: 'Point', coordinates: [-85.87536296, 11.25055053] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Juan Del Sur',
        code: 'NISJS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.25055,
        longitude: -85.87536,
        iso3: 'NIC',
        iso3_op: 'NIC',
        country: 'Nicaragua',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3616726,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17065',
      geometry: { type: 'Point', coordinates: [31.3206321, 21.80807704] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wadi Halfa',
        code: null,
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.80808,
        longitude: 31.32063,
        iso3: 'SDN',
        iso3_op: 'SDN',
        country: 'Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 364132,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17066',
      geometry: { type: 'Point', coordinates: [1.59851434, 6.22783756] },
      geometry_name: 'shape',
      properties: {
        portname: 'Anecho',
        code: 'TGANE',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 6.22784,
        longitude: 1.59851,
        iso3: 'TGO',
        iso3_op: 'TGO',
        country: 'Togo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2367990,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17067',
      geometry: { type: 'Point', coordinates: [-3.28587621, 5.13208252] },
      geometry_name: 'shape',
      properties: {
        portname: 'Assinie',
        code: 'CIASS',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.13208,
        longitude: -3.28588,
        iso3: 'CIV',
        iso3_op: 'CIV',
        country: "Côte d'Ivoire",
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2292663,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17068',
      geometry: { type: 'Point', coordinates: [-1.22142566, 54.58160612] },
      geometry_name: 'shape',
      properties: {
        portname: 'Middlesborough',
        code: 'GBMID',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.58161,
        longitude: -1.22143,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2642607,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17069',
      geometry: { type: 'Point', coordinates: [16.32533792, 58.48211686] },
      geometry_name: 'shape',
      properties: {
        portname: 'Soderkoping',
        code: 'SESOG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.48212,
        longitude: 16.32534,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2676215,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17070',
      geometry: { type: 'Point', coordinates: [11.17097253, 58.93604084] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stromstad',
        code: 'SESMD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.93604,
        longitude: 11.17097,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2671224,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17071',
      geometry: { type: 'Point', coordinates: [-6.77018335, 61.44429559] },
      geometry_name: 'shape',
      properties: {
        portname: 'Loppa',
        code: 'NOLOP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.4443,
        longitude: -6.77018,
        iso3: 'FRO',
        iso3_op: 'FRO',
        country: 'Faroe Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2617406,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17072',
      geometry: { type: 'Point', coordinates: [42.53642657, 16.89062761] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gizan',
        code: 'SAGIZ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.89063,
        longitude: 42.53643,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 105299,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17073',
      geometry: { type: 'Point', coordinates: [-74.45435394, 18.48804089] },
      geometry_name: 'shape',
      properties: {
        portname: "Anse-D'Hainault",
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.48804,
        longitude: -74.45435,
        iso3: 'HTI',
        iso3_op: 'HTI',
        country: 'Haiti',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3731117,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17074',
      geometry: { type: 'Point', coordinates: [20.40456806, 1.94074764] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ukatura',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 1.94075,
        longitude: 20.40457,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 204511,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17075',
      geometry: { type: 'Point', coordinates: [49.08255552, 30.42907312] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bandar Khomeini',
        code: 'IRBKM',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.42907,
        longitude: 49.08256,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 8521444,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17076',
      geometry: { type: 'Point', coordinates: [-69.50938592, 47.93430961] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gros Cacouna',
        code: 'CAGCA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.93431,
        longitude: -69.50939,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6695808,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17077',
      geometry: { type: 'Point', coordinates: [26.7976446, -8.05173393] },
      geometry_name: 'shape',
      properties: {
        portname: "Malemba-N'Kulu",
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.05173,
        longitude: 26.79764,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 209856,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17078',
      geometry: { type: 'Point', coordinates: [0.52730216, 40.5547433] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alcacer',
        code: 'ESAQE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 40.55474,
        longitude: 0.5273,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3130608,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17079',
      geometry: { type: 'Point', coordinates: [100.92633367, 13.17343607] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sriracha',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.17344,
        longitude: 100.92633,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1606250,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17080',
      geometry: { type: 'Point', coordinates: [52.58396787, 27.49225506] },
      geometry_name: 'shape',
      properties: {
        portname: 'Asalouyeh',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.49226,
        longitude: 52.58397,
        iso3: null,
        iso3_op: 'IRN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 142857,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17081',
      geometry: { type: 'Point', coordinates: [-172.02429195, -13.83328301] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mulifana',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -13.83328,
        longitude: -172.02429,
        iso3: 'WSM',
        iso3_op: 'WSM',
        country: 'Samoa',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4035196,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17082',
      geometry: { type: 'Point', coordinates: [51.51077271, 36.65375349] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nowshahr',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.65375,
        longitude: 51.51077,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 121959,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17083',
      geometry: { type: 'Point', coordinates: [47.97786067, -14.87936871] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antsohily',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: 3,
        maxlength: 185,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -14.87937,
        longitude: 47.97786,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1068971,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17084',
      geometry: { type: 'Point', coordinates: [-68.13906588, 49.23331618] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baie Comeau',
        code: 'CABCO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.23332,
        longitude: -68.13907,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5889745,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17085',
      geometry: { type: 'Point', coordinates: [-70.61765849, 41.74423664] },
      geometry_name: 'shape',
      properties: {
        portname: 'Monument',
        code: 'USMEB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 41.74424,
        longitude: -70.61766,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4944405,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17086',
      geometry: { type: 'Point', coordinates: [-52.70958855, 47.55844442] },
      geometry_name: 'shape',
      properties: {
        portname: 'St Johns',
        code: 'CASJF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.55844,
        longitude: -52.70959,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6324733,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17087',
      geometry: { type: 'Point', coordinates: [134.59755499, 34.00276087] },
      geometry_name: 'shape',
      properties: {
        portname: 'Komatsujima',
        code: 'JPKOM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.00276,
        longitude: 134.59755,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1858902,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17088',
      geometry: { type: 'Point', coordinates: [55.9491436, 25.8047612] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Al Khaimah',
        code: 'AERKT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.80476,
        longitude: 55.94914,
        iso3: null,
        iso3_op: 'ARE',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 291074,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17089',
      geometry: { type: 'Point', coordinates: [79.84926595, 10.7581209] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nagapattinam',
        code: 'INNPT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.75812,
        longitude: 79.84927,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1262260,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17090',
      geometry: { type: 'Point', coordinates: [50.21511078, 26.24269583] },
      geometry_name: 'shape',
      properties: {
        portname: 'Al Khobar',
        code: 'SAALK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.2427,
        longitude: 50.21511,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 109323,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17091',
      geometry: { type: 'Point', coordinates: [135.32519448, 34.42227027] },
      geometry_name: 'shape',
      properties: {
        portname: 'Izumisano',
        code: 'JPIZS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.42227,
        longitude: 135.32519,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1861091,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17092',
      geometry: { type: 'Point', coordinates: [28.58109842, 41.01858855] },
      geometry_name: 'shape',
      properties: {
        portname: 'Buyukcekmece',
        code: 'TRBCE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.01859,
        longitude: 28.5811,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6947641,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17093',
      geometry: { type: 'Point', coordinates: [41.65795606, 41.6497588] },
      geometry_name: 'shape',
      properties: {
        portname: 'Batumi',
        code: 'GEBUS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.64976,
        longitude: 41.65796,
        iso3: 'GEO',
        iso3_op: 'GEO',
        country: 'Georgia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 615532,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17094',
      geometry: { type: 'Point', coordinates: [41.65513973, 42.15248701] },
      geometry_name: 'shape',
      properties: {
        portname: 'Poti',
        code: 'GEPTI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.15249,
        longitude: 41.65514,
        iso3: 'GEO',
        iso3_op: 'GEO',
        country: 'Georgia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 612366,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17095',
      geometry: { type: 'Point', coordinates: [151.20736601, -33.96797396] },
      geometry_name: 'shape',
      properties: {
        portname: 'Botany Bay',
        code: 'AUBTB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.96797,
        longitude: 151.20737,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2174563,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17096',
      geometry: { type: 'Point', coordinates: [-70.64650683, -26.35779586] },
      geometry_name: 'shape',
      properties: {
        portname: 'Barquito',
        code: 'CLBAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -26.3578,
        longitude: -70.64651,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3891906,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17097',
      geometry: { type: 'Point', coordinates: [74.685582, 13.63632763] },
      geometry_name: 'shape',
      properties: {
        portname: 'Coondapur',
        code: 'INCOO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.63633,
        longitude: 74.68558,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1273834,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17098',
      geometry: { type: 'Point', coordinates: [129.87097254, 32.74824438] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nagasaki',
        code: 'JPNGS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.74824,
        longitude: 129.87097,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1856177,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17099',
      geometry: { type: 'Point', coordinates: [34.61482766, 64.95335324] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kem',
        code: 'RUKEM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 64.95335,
        longitude: 34.61483,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 551145,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17100',
      geometry: { type: 'Point', coordinates: [-43.17307771, -22.90285127] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rio De Janeiro',
        code: 'BRRIO',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -22.90285,
        longitude: -43.17308,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3451190,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17101',
      geometry: { type: 'Point', coordinates: [-64.67928167, 32.37977817] },
      geometry_name: 'shape',
      properties: {
        portname: 'St Georges',
        code: 'BMSGE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.37978,
        longitude: -64.67928,
        iso3: null,
        iso3_op: 'BMU',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3573061,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17102',
      geometry: { type: 'Point', coordinates: [22.65359757, 65.90829798] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tore',
        code: 'SETOE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.9083,
        longitude: 22.6536,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 602261,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17103',
      geometry: { type: 'Point', coordinates: [-115.18567509, 28.0450864] },
      geometry_name: 'shape',
      properties: {
        portname: 'Morro Redondo',
        code: 'MXMRE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.04509,
        longitude: -115.18568,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3978980,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17104',
      geometry: { type: 'Point', coordinates: [-77.98207795, 20.70361827] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santa Cruz Del Sur',
        code: 'CUSCS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.70362,
        longitude: -77.98208,
        iso3: null,
        iso3_op: 'CUB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3537840,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17105',
      geometry: { type: 'Point', coordinates: [-70.14210819, -52.89499997] },
      geometry_name: 'shape',
      properties: {
        portname: 'Clarencia',
        code: 'CLCLR',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -52.895,
        longitude: -70.14211,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3897293,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17106',
      geometry: { type: 'Point', coordinates: [-79.83751198, 9.3828832] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bahia De Las Minas',
        code: 'PAPBM',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.38288,
        longitude: -79.83751,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3714528,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17107',
      geometry: { type: 'Point', coordinates: [32.47197119, 29.87932839] },
      geometry_name: 'shape',
      properties: {
        portname: 'Adabiya',
        code: 'EGADA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.87933,
        longitude: 32.47197,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 361642,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17108',
      geometry: { type: 'Point', coordinates: [28.93988285, 36.75494638] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gocek',
        code: 'TRGCK',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.75495,
        longitude: 28.93988,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 306859,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17109',
      geometry: { type: 'Point', coordinates: [2.60029127, 6.47122771] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Novo',
        code: 'BJPTN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 6.47123,
        longitude: 2.60029,
        iso3: 'BEN',
        iso3_op: 'BEN',
        country: 'Benin',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2392087,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17110',
      geometry: { type: 'Point', coordinates: [-23.48768177, 65.87625611] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thingeyri',
        code: 'ISTEY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.87626,
        longitude: -23.48768,
        iso3: null,
        iso3_op: 'ISL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3412655,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17111',
      geometry: { type: 'Point', coordinates: [10.21666556, 56.15000028] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aarhus',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 56.15,
        longitude: 10.21667,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2624652,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17112',
      geometry: { type: 'Point', coordinates: [123.96155819, 9.95218635] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tubigon',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 9.95219,
        longitude: 123.96156,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1681182,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17113',
      geometry: { type: 'Point', coordinates: [11.96015859, 57.71778841] },
      geometry_name: 'shape',
      properties: {
        portname: 'Goteborg',
        code: 'SEGOT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.71779,
        longitude: 11.96016,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2711537,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17114',
      geometry: { type: 'Point', coordinates: [18.49548304, -1.58523311] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dokese',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -1.58523,
        longitude: 18.49548,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2316005,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17115',
      geometry: { type: 'Point', coordinates: [-24.0032038, 65.59794848] },
      geometry_name: 'shape',
      properties: {
        portname: 'Patreksfjordur - Hofn',
        code: 'ISPAT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.59795,
        longitude: -24.0032,
        iso3: 'ISL',
        iso3_op: 'ISL',
        country: 'Iceland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3412173,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17116',
      geometry: { type: 'Point', coordinates: [-3.98218989, 15.93095363] },
      geometry_name: 'shape',
      properties: {
        portname: 'Niafunke',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.93095,
        longitude: -3.98219,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2452830,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17117',
      geometry: { type: 'Point', coordinates: [-4.9886821, 5.14800619] },
      geometry_name: 'shape',
      properties: {
        portname: 'Grand Lahou',
        code: 'CIGLU',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 5.14801,
        longitude: -4.98868,
        iso3: 'CIV',
        iso3_op: 'CIV',
        country: "Côte d'Ivoire",
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2288105,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17118',
      geometry: { type: 'Point', coordinates: [20.62116403, -0.91042357] },
      geometry_name: 'shape',
      properties: {
        portname: 'Watsi-Kengo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.91042,
        longitude: 20.62116,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 204279,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17119',
      geometry: { type: 'Point', coordinates: [19.05825781, 57.86568931] },
      geometry_name: 'shape',
      properties: {
        portname: 'Farosund',
        code: 'SEFSD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.86569,
        longitude: 19.05826,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2715285,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17120',
      geometry: { type: 'Point', coordinates: [-9.45962178, 51.68009525] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bantry Bay',
        code: 'IEBYT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.6801,
        longitude: -9.45962,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2966356,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17121',
      geometry: { type: 'Point', coordinates: [10.61740317, 63.66826858] },
      geometry_name: 'shape',
      properties: {
        portname: 'Leksvik',
        code: 'NOLKV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.66827,
        longitude: 10.6174,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3147816,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17122',
      geometry: { type: 'Point', coordinates: [23.75067501, 65.73525248] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sandvik',
        code: 'SESAK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.73525,
        longitude: 23.75068,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 603094,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17123',
      geometry: { type: 'Point', coordinates: [-8.83988002, 41.69015252] },
      geometry_name: 'shape',
      properties: {
        portname: 'Viana Do Castelo',
        code: 'PTVDC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.69015,
        longitude: -8.83988,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Portugal',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2732773,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17124',
      geometry: { type: 'Point', coordinates: [133.00512815, 34.06962056] },
      geometry_name: 'shape',
      properties: {
        portname: 'Imabari',
        code: 'JPIMB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.06962,
        longitude: 133.00513,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1926134,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17125',
      geometry: { type: 'Point', coordinates: [159.05859415, -31.52319033] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lord Howe Is',
        code: 'AULDH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -31.52319,
        longitude: 159.05859,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6930427,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17126',
      geometry: { type: 'Point', coordinates: [-48.63420157, -26.23836613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sao Francisco Do Sul',
        code: 'BRSFS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -26.23837,
        longitude: -48.6342,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3449112,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17127',
      geometry: { type: 'Point', coordinates: [79.84619354, 10.91120222] },
      geometry_name: 'shape',
      properties: {
        portname: 'Karikal',
        code: 'INKRK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.9112,
        longitude: 79.84619,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1267887,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17128',
      geometry: { type: 'Point', coordinates: [122.94762305, 10.67302809] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bacolod',
        code: 'PHBCD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 10.67303,
        longitude: 122.94762,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1729564,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17129',
      geometry: { type: 'Point', coordinates: [133.56013231, 33.54142379] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kochi',
        code: 'JPKCZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.54142,
        longitude: 133.56013,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1859146,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17130',
      geometry: { type: 'Point', coordinates: [125.40772232, 38.72630951] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nampo',
        code: 'KPNAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.72631,
        longitude: 125.40772,
        iso3: 'PRK',
        iso3_op: 'PRK',
        country: "Dem People's Rep of Korea",
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1873757,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17131',
      geometry: { type: 'Point', coordinates: [28.8229, 40.95846111] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bakirkoy',
        code: 'TRBAK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.95846,
        longitude: 28.8229,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 751214,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17132',
      geometry: { type: 'Point', coordinates: [24.95299697, 60.16644232] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stromma',
        code: 'FISTR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.16644,
        longitude: 24.953,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17133',
      geometry: { type: 'Point', coordinates: [72.60984386, 22.28804457] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cambay',
        code: 'INCAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.28804,
        longitude: 72.60984,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1267090,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17134',
      geometry: { type: 'Point', coordinates: [135.11259165, 33.96056792] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yura',
        code: 'JPYUR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.96057,
        longitude: 135.11259,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1848042,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17135',
      geometry: { type: 'Point', coordinates: [137.60765213, 34.687447] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hamamatsu',
        code: 'JPHMM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.68745,
        longitude: 137.60765,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1863289,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17136',
      geometry: { type: 'Point', coordinates: [119.45295986, 34.73367879] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lianyungang',
        code: 'CNLYG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.73368,
        longitude: 119.45296,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1804461,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17137',
      geometry: { type: 'Point', coordinates: [119.51898302, 35.3679575] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rizhao',
        code: 'CNRZH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.36796,
        longitude: 119.51898,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1804461,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17138',
      geometry: { type: 'Point', coordinates: [86.68100523, 20.27604187] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paradip',
        code: 'INPRT',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.27604,
        longitude: 86.68101,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1260393,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17139',
      geometry: { type: 'Point', coordinates: [49.65120515, 27.01966095] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jubail',
        code: 'SAJUB',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.01966,
        longitude: 49.65121,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 109435,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17140',
      geometry: { type: 'Point', coordinates: [12.1199595, 32.91995644] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zuara',
        code: 'LYZUA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.91996,
        longitude: 12.11996,
        iso3: 'LBY',
        iso3_op: 'LBY',
        country: 'Libya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2208425,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17141',
      geometry: { type: 'Point', coordinates: [12.67659233, 32.79140092] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zawia Terminal',
        code: 'LYZAW',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.7914,
        longitude: 12.67659,
        iso3: 'LBY',
        iso3_op: 'LBY',
        country: 'Libya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2218970,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17142',
      geometry: { type: 'Point', coordinates: [15.2194024, 32.36625631] },
      geometry_name: 'shape',
      properties: {
        portname: 'Misurata',
        code: 'LYMRA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 32.36626,
        longitude: 15.2194,
        iso3: 'LBY',
        iso3_op: 'LBY',
        country: 'Libya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2214846,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17143',
      geometry: { type: 'Point', coordinates: [16.62850903, 31.21283161] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sirte',
        code: 'LYSRT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.21283,
        longitude: 16.62851,
        iso3: 'LBY',
        iso3_op: 'LBY',
        country: 'Libya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2210554,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17144',
      geometry: { type: 'Point', coordinates: [19.58224551, 30.41715105] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marsa El Brega',
        code: 'LYLMQ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.41715,
        longitude: 19.58225,
        iso3: null,
        iso3_op: 'LBY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2215210,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17145',
      geometry: { type: 'Point', coordinates: [20.07455725, 30.89874252] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zueitina',
        code: 'LYZUE',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.89874,
        longitude: 20.07456,
        iso3: null,
        iso3_op: 'LBY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 88380,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17146',
      geometry: { type: 'Point', coordinates: [22.17128662, 32.9126974] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras El Hilal',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.9127,
        longitude: 22.17129,
        iso3: 'LBY',
        iso3_op: 'LBY',
        country: 'Libya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 9963219,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17147',
      geometry: { type: 'Point', coordinates: [17.94706755, -3.96779123] },
      geometry_name: 'shape',
      properties: {
        portname: "N'Ta",
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -3.96779,
        longitude: 17.94707,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2311874,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17148',
      geometry: { type: 'Point', coordinates: [-11.34195172, 28.47757474] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tan Tan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.47757,
        longitude: -11.34195,
        iso3: null,
        iso3_op: 'MAR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2530241,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17149',
      geometry: { type: 'Point', coordinates: [32.39503767, 35.04046283] },
      geometry_name: 'shape',
      properties: {
        portname: 'Latchi',
        code: 'CYLAT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.04046,
        longitude: 32.39504,
        iso3: null,
        iso3_op: 'CYP',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 146408,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17150',
      geometry: { type: 'Point', coordinates: [101.01926333, 4.02217449] },
      geometry_name: 'shape',
      properties: {
        portname: 'Telok Anson',
        code: 'MYTAN',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.02217,
        longitude: 101.01926,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1735459,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17151',
      geometry: { type: 'Point', coordinates: [28.6957, 40.97633387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ambarli',
        code: 'TRAMB',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.97633,
        longitude: 28.6957,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 751558,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17152',
      geometry: { type: 'Point', coordinates: [-71.14452345, 9.94427963] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bachaquero / Maracaibo L',
        code: 'VEBAV',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.94428,
        longitude: -71.14452,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3648698,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17153',
      geometry: { type: 'Point', coordinates: [-90.80867408, 13.91860093] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Jose',
        code: 'CRSJO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.9186,
        longitude: -90.80867,
        iso3: 'GTM',
        iso3_op: 'GTM',
        country: 'Guatemala',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3591060,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17154',
      geometry: { type: 'Point', coordinates: [-66.87925309, 17.95302646] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guanica Harbour',
        code: 'PRGUX',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.95303,
        longitude: -66.87925,
        iso3: null,
        iso3_op: 'PRI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4565090,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17155',
      geometry: { type: 'Point', coordinates: [48.84323926, -13.09162311] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Saint Louis',
        code: 'MGPSL',
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: 4,
        maxlength: 120,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -13.09162,
        longitude: 48.84324,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Bing image + CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1068955,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17156',
      geometry: { type: 'Point', coordinates: [120.29334446, 14.82309551] },
      geometry_name: 'shape',
      properties: {
        portname: 'Subic Bay',
        code: 'PHSFS',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.8231,
        longitude: 120.29334,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1685577,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17157',
      geometry: { type: 'Point', coordinates: [-79.19678743, 43.1111053] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thorold',
        code: 'CATHD',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.11111,
        longitude: -79.19679,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6165719,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17158',
      geometry: { type: 'Point', coordinates: [-66.38054489, 50.19887955] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sept Iles (Seven Is.)',
        code: 'CASEI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.19888,
        longitude: -66.38054,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6144312,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17159',
      geometry: { type: 'Point', coordinates: [136.41938697, -13.85824693] },
      geometry_name: 'shape',
      properties: {
        portname: 'Milner Bay',
        code: 'AUMIB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -13.85825,
        longitude: 136.41939,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17160',
      geometry: { type: 'Point', coordinates: [-82.54444042, 42.61264332] },
      geometry_name: 'shape',
      properties: {
        portname: 'Algonac',
        code: 'USALG',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.61264,
        longitude: -82.54444,
        iso3: 'USA',
        iso3_op: 'CAN',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17161',
      geometry: { type: 'Point', coordinates: [13.84514484, 68.12617539] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vestvagoy',
        code: 'NOVVY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 68.12618,
        longitude: 13.84514,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17162',
      geometry: { type: 'Point', coordinates: [-4.36747753, 54.41721318] },
      geometry_name: 'shape',
      properties: {
        portname: 'Point Of Ayre',
        code: 'GBPTA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.41721,
        longitude: -4.36748,
        iso3: 'IMN',
        iso3_op: 'IMY',
        country: 'Isle of Man',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17163',
      geometry: { type: 'Point', coordinates: [-18.7564087, 65.835684] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 65.83568,
        longitude: -18.75641,
        iso3: 'ISL',
        iso3_op: 'ISL',
        country: 'Iceland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17164',
      geometry: { type: 'Point', coordinates: [-43.12426002, -22.89378221] },
      geometry_name: 'shape',
      properties: {
        portname: 'Niteroi',
        code: 'BRNTR',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -22.89378,
        longitude: -43.12426,
        iso3: null,
        iso3_op: 'BRA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3468615,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17165',
      geometry: { type: 'Point', coordinates: [33.56643243, 27.80363335] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zeit Bay',
        code: 'EGZEI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.80363,
        longitude: 33.56643,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 355198,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17166',
      geometry: { type: 'Point', coordinates: [130.87580041, 33.91063149] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kokura',
        code: 'JPKOK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.91063,
        longitude: 130.8758,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1859307,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17167',
      geometry: { type: 'Point', coordinates: [133.99659454, 34.4609057] },
      geometry_name: 'shape',
      properties: {
        portname: 'Naoshima',
        code: 'JPNAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.46091,
        longitude: 133.99659,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1862888,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17168',
      geometry: { type: 'Point', coordinates: [35.34846533, -17.69157302] },
      geometry_name: 'shape',
      properties: {
        portname: 'Noere',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.69157,
        longitude: 35.34847,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Noere: (I)-No Vessel Restriction- (Ii) -No Truck Access- (Iii) -Network Coverage- (Iv) -No Hazard To Navigation--Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1095830,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17169',
      geometry: { type: 'Point', coordinates: [166.4462405, -22.30171304] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ducos',
        code: 'NCDUS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -22.30171,
        longitude: 166.44624,
        iso3: 'NCL',
        iso3_op: 'NCL',
        country: 'New Caledonia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2139521,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17170',
      geometry: { type: 'Point', coordinates: [-77.72794429, 8.11571572] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Real',
        code: 'PAELE',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.11572,
        longitude: -77.72794,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3710058,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17171',
      geometry: { type: 'Point', coordinates: [124.05125129, 11.88241114] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pio V. Corpuz',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.88241,
        longitude: 124.05125,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1693394,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17172',
      geometry: { type: 'Point', coordinates: [129.76291398, 41.76436271] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chongjin',
        code: 'KPCHO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.76436,
        longitude: 129.76291,
        iso3: 'PRK',
        iso3_op: 'PRK',
        country: "Dem People's Rep of Korea",
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2044757,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17173',
      geometry: { type: 'Point', coordinates: [33.56539495, 66.27805609] },
      geometry_name: 'shape',
      properties: {
        portname: 'Keret',
        code: 'RUKER',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 66.27806,
        longitude: 33.56539,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 535674,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17174',
      geometry: { type: 'Point', coordinates: [38.0765697, 24.07415673] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yanbo',
        code: 'SAYNB',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.07416,
        longitude: 38.07657,
        iso3: 'SAU',
        iso3_op: 'SAU',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 100425,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17175',
      geometry: { type: 'Point', coordinates: [-62.69223951, 8.35968747] },
      geometry_name: 'shape',
      properties: {
        portname: 'Palua',
        code: 'VEPLA',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.35969,
        longitude: -62.69224,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3645528,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17176',
      geometry: { type: 'Point', coordinates: [-64.74163205, 17.69877974] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hovic',
        code: 'VIHOC',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.69878,
        longitude: -64.74163,
        iso3: null,
        iso3_op: 'VIR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4796512,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17177',
      geometry: { type: 'Point', coordinates: [108.21732843, 16.12040318] },
      geometry_name: 'shape',
      properties: {
        portname: 'Da Nang',
        code: 'VNDAD',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.1204,
        longitude: 108.21733,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1583992,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17178',
      geometry: { type: 'Point', coordinates: [-68.27788356, 12.15337713] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kralendijk',
        code: 'ANKRA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.15338,
        longitude: -68.27788,
        iso3: 'ANT',
        iso3_op: 'ANT',
        country: 'Netherlands Antilles',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3513563,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17179',
      geometry: { type: 'Point', coordinates: [17.61398477, 59.31630095] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rasta',
        code: 'SERST',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.3163,
        longitude: 17.61398,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2683058,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17180',
      geometry: { type: 'Point', coordinates: [135.20920096, 34.68803013] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kobe',
        code: 'JPUKB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.68803,
        longitude: 135.2092,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1859171,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17181',
      geometry: { type: 'Point', coordinates: [14.4321036, 45.32830614] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rijeka Bakar',
        code: 'HRRJK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.32831,
        longitude: 14.4321,
        iso3: 'HRV',
        iso3_op: 'HRV',
        country: 'Croatia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3191648,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17182',
      geometry: { type: 'Point', coordinates: [-4.21086952, 53.18493498] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Dinorwic',
        code: 'GBPDI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.18493,
        longitude: -4.21087,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6951032,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17183',
      geometry: { type: 'Point', coordinates: [50.83353481, 28.99410594] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bushehr',
        code: 'IRBUZ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.99411,
        longitude: 50.83353,
        iso3: null,
        iso3_op: 'IRN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 139817,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17184',
      geometry: { type: 'Point', coordinates: [-89.25773255, 45.9168711] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eagle River',
        code: 'USERQ',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.91687,
        longitude: -89.25773,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4991441,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17185',
      geometry: { type: 'Point', coordinates: [-75.15044068, 19.99104334] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guantanamo Bay',
        code: 'CUGUB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.99104,
        longitude: -75.15044,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3557689,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17186',
      geometry: { type: 'Point', coordinates: [-77.82783729, -10.6826756] },
      geometry_name: 'shape',
      properties: {
        portname: 'Paramonga',
        code: 'PEPAM',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -10.68268,
        longitude: -77.82784,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3933024,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17187',
      geometry: { type: 'Point', coordinates: [-64.35529515, 44.29054287] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Have',
        code: 'CALHA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.29054,
        longitude: -64.3553,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6179061,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17188',
      geometry: { type: 'Point', coordinates: [103.45940136, 4.2537637] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kemaman',
        code: 'MYKEM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.25376,
        longitude: 103.4594,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1768409,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17189',
      geometry: { type: 'Point', coordinates: [74.81537873, 12.91886347] },
      geometry_name: 'shape',
      properties: {
        portname: 'New Mangalore',
        code: 'INNML',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.91886,
        longitude: 74.81538,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1263780,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17190',
      geometry: { type: 'Point', coordinates: [118.25121724, 4.6856894] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kunak',
        code: 'MYKUN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.68569,
        longitude: 118.25122,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1733635,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17191',
      geometry: { type: 'Point', coordinates: [-71.63457923, 10.48907954] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bajo Grande / Maracaibo L',
        code: 'VEBJV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.48908,
        longitude: -71.63458,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3648662,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17192',
      geometry: { type: 'Point', coordinates: [47.78505987, 29.36863335] },
      geometry_name: 'shape',
      properties: {
        portname: 'Doha',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.36863,
        longitude: 47.78506,
        iso3: 'KWT',
        iso3_op: 'KWT',
        country: 'Kuwait',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 285855,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17193',
      geometry: { type: 'Point', coordinates: [-66.12850687, 48.10132266] },
      geometry_name: 'shape',
      properties: {
        portname: 'Carleton',
        code: 'CACLT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.10132,
        longitude: -66.12851,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6693777,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17194',
      geometry: { type: 'Point', coordinates: [-72.08035514, 41.5230519] },
      geometry_name: 'shape',
      properties: {
        portname: 'Norwich',
        code: 'USNOC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.52305,
        longitude: -72.08036,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17195',
      geometry: { type: 'Point', coordinates: [0.03669414, 51.49978934] },
      geometry_name: 'shape',
      properties: {
        portname: 'Silvertown',
        code: 'GBSVT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.49979,
        longitude: 0.03669,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17196',
      geometry: { type: 'Point', coordinates: [173.96611599, -41.45265373] },
      geometry_name: 'shape',
      properties: {
        portname: 'Blenheim',
        code: 'NZBHE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.45265,
        longitude: 173.96612,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17197',
      geometry: { type: 'Point', coordinates: [20.79141344, 60.28745563] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kumlinge',
        code: 'FIKUM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.28746,
        longitude: 20.79141,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17198',
      geometry: { type: 'Point', coordinates: [-69.94529076, -4.21622403] },
      geometry_name: 'shape',
      properties: {
        portname: 'Leticia',
        code: 'COLET',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.21622,
        longitude: -69.94529,
        iso3: 'COL',
        iso3_op: 'BRA',
        country: 'Colombia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3676623,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17199',
      geometry: { type: 'Point', coordinates: [114.98922256, 4.90119427] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanjong Salirong',
        code: 'BNTAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 4.90119,
        longitude: 114.98922,
        iso3: 'BRN',
        iso3_op: 'BRN',
        country: 'Brunei Darussalam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1820906,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17200',
      geometry: { type: 'Point', coordinates: [-24.99721603, 16.8908864] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porto Grande',
        code: 'CVPGR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.89089,
        longitude: -24.99722,
        iso3: 'CPV',
        iso3_op: 'CPV',
        country: 'Cape Verde',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3374462,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17201',
      geometry: { type: 'Point', coordinates: [33.31393624, 34.71906653] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vassiliko',
        code: 'CYVAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.71907,
        longitude: 33.31394,
        iso3: null,
        iso3_op: 'CYP',
        country: null,
        lastcheckdate: null,
        remarks: 'Vasilikos',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17202',
      geometry: { type: 'Point', coordinates: [33.73019159, 34.98005688] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dhekelia',
        code: 'CYDHK',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.98006,
        longitude: 33.73019,
        iso3: 'CYP',
        iso3_op: 'CYP',
        country: 'Cyprus',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17203',
      geometry: { type: 'Point', coordinates: [33.84479386, 27.23100496] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hurghada',
        code: 'EGHRG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.231,
        longitude: 33.84479,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 361291,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17204',
      geometry: { type: 'Point', coordinates: [-73.13152314, 18.47391061] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 18.47391,
        longitude: -73.13152,
        iso3: 'HTI',
        iso3_op: 'HTI',
        country: 'Haiti',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3720824,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17205',
      geometry: { type: 'Point', coordinates: [50.26099435, 29.83039239] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Bahrgan',
        code: 'IRRBA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.83039,
        longitude: 50.26099,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 134217,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17206',
      geometry: { type: 'Point', coordinates: [47.87923609, 30.19954196] },
      geometry_name: 'shape',
      properties: {
        portname: 'Khor Al Zubair',
        code: 'IQKAZ',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.19954,
        longitude: 47.87924,
        iso3: 'IRQ',
        iso3_op: 'IRQ',
        country: 'Iraq',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 98245,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17207',
      geometry: { type: 'Point', coordinates: [130.56430308, 31.58649843] },
      geometry_name: 'shape',
      properties: {
        portname: 'Amamioshima',
        code: 'JPASJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.5865,
        longitude: 130.5643,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 7303136,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17208',
      geometry: { type: 'Point', coordinates: [143.31985155, 42.22401267] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tokachi',
        code: 'JPTOK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.22401,
        longitude: 143.31985,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2130061,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17209',
      geometry: { type: 'Point', coordinates: [103.52134188, 10.60654226] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kompongsom',
        code: 'KHKOS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.60654,
        longitude: 103.52134,
        iso3: 'KHM',
        iso3_op: 'KHM',
        country: 'Cambodia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1831142,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17210',
      geometry: { type: 'Point', coordinates: [-92, 20.18333387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cayo Arcas Terminal',
        code: 'MXCAT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 20.18333,
        longitude: -92,
        iso3: null,
        iso3_op: 'MEX',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17211',
      geometry: { type: 'Point', coordinates: [100.38933985, 5.36473151] },
      geometry_name: 'shape',
      properties: {
        portname: 'Penang',
        code: 'MYPEN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.36473,
        longitude: 100.38934,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1735106,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17212',
      geometry: { type: 'Point', coordinates: [166.91063673, -0.52855883] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nauru Island',
        code: 'NRINU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.52856,
        longitude: 166.91064,
        iso3: 'NRU',
        iso3_op: 'NRU',
        country: 'Nauru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 7626461,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17213',
      geometry: { type: 'Point', coordinates: [154.67005459, -5.43228884] },
      geometry_name: 'shape',
      properties: {
        portname: 'Buka',
        code: 'PGBUA',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.43229,
        longitude: 154.67005,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 8714539,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17214',
      geometry: { type: 'Point', coordinates: [127.53027072, 39.76192831] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bumpyo',
        code: 'KPREM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 39.76193,
        longitude: 127.53027,
        iso3: 'PRK',
        iso3_op: 'PRK',
        country: "Dem People's Rep of Korea",
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1877449,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17215',
      geometry: { type: 'Point', coordinates: [137.22280953, 36.75565138] },
      geometry_name: 'shape',
      properties: {
        portname: 'Toyama',
        code: 'JPTOY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.75565,
        longitude: 137.22281,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1849876,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17216',
      geometry: { type: 'Point', coordinates: [22.30170372, 60.29706234] },
      geometry_name: 'shape',
      properties: {
        portname: 'Parainen',
        code: 'FIPAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.29706,
        longitude: 22.3017,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 642199,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17217',
      geometry: { type: 'Point', coordinates: [34.28562051, 26.10384376] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kosseir',
        code: 'EGKOS',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.10384,
        longitude: 34.28562,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 360531,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17218',
      geometry: { type: 'Point', coordinates: [-117.95829935, 33.63400223] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hunting',
        code: 'USHNT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 33.634,
        longitude: -117.9583,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5358705,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17219',
      geometry: { type: 'Point', coordinates: [-5.59923607, 57.84132066] },
      geometry_name: 'shape',
      properties: {
        portname: 'Loch Ewe',
        code: 'GBLHE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 57.84132,
        longitude: -5.59924,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2645398,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17220',
      geometry: { type: 'Point', coordinates: [53.01678739, 40.00550978] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Of Turkmenbashi',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.00551,
        longitude: 53.01679,
        iso3: 'TKM',
        iso3_op: 'TKM',
        country: 'Turkmenistan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 601594,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17222',
      geometry: { type: 'Point', coordinates: [17.68053331, -4.54818698] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dunda',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.54819,
        longitude: 17.68053,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 8445561,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17223',
      geometry: { type: 'Point', coordinates: [56.25453317, 26.20652055] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kashab',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.20652,
        longitude: 56.25453,
        iso3: 'OMN',
        iso3_op: 'OMN',
        country: 'Oman',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 287614,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17225',
      geometry: { type: 'Point', coordinates: [29.08925556, -2.18713889] },
      geometry_name: 'shape',
      properties: {
        portname: 'Musoko',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -2.18714,
        longitude: 29.08926,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 208128,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17226',
      geometry: { type: 'Point', coordinates: [29.02734444, -2.2248] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kashofu Ii',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.2248,
        longitude: 29.02734,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 8435273,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17227',
      geometry: { type: 'Point', coordinates: [117.175633, -20.59831002] },
      geometry_name: 'shape',
      properties: {
        portname: 'Roebourne',
        code: 'AURBU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.59831,
        longitude: 117.17563,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17228',
      geometry: { type: 'Point', coordinates: [-79.64807885, 0.98915308] },
      geometry_name: 'shape',
      properties: {
        portname: 'Balao',
        code: 'ECEBL',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.98915,
        longitude: -79.64808,
        iso3: null,
        iso3_op: 'ECU',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3652762,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17229',
      geometry: { type: 'Point', coordinates: [-123.51993693, 48.61391755] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bamberton',
        code: 'CABAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.61392,
        longitude: -123.51994,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17230',
      geometry: { type: 'Point', coordinates: [-118.42415482, 32.80884667] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Clemente',
        code: 'USXMO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 32.80885,
        longitude: -118.42415,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17231',
      geometry: { type: 'Point', coordinates: [-145.23447271, 60.33211784] },
      geometry_name: 'shape',
      properties: {
        portname: 'Castle Island',
        code: 'USCAI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 60.33212,
        longitude: -145.23447,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17232',
      geometry: { type: 'Point', coordinates: [23.43340934, 59.97791433] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tammisaari',
        code: 'FITAI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.97791,
        longitude: 23.43341,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17233',
      geometry: { type: 'Point', coordinates: [121.631187, 23.99350973] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hualien',
        code: 'TWHUN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 23.99351,
        longitude: 121.63119,
        iso3: 'TWN',
        iso3_op: 'CHN',
        country: 'Taiwan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1674504,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17234',
      geometry: { type: 'Point', coordinates: [33.16621392, 34.71254857] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moni Anchorage',
        code: 'CYMOI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.71255,
        longitude: 33.16621,
        iso3: 'CYP',
        iso3_op: 'CYP',
        country: 'Cyprus',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17235',
      geometry: { type: 'Point', coordinates: [33.22203277, 28.61796531] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wadi Feiran',
        code: 'EGWAF',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.61797,
        longitude: 33.22203,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 356893,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17236',
      geometry: { type: 'Point', coordinates: [28.23245289, 36.44404433] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rhodes',
        code: 'GRRHO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.44404,
        longitude: 28.23245,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17237',
      geometry: { type: 'Point', coordinates: [77.56344399, 8.09569703] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cape Comorin',
        code: 'INCCN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.0957,
        longitude: 77.56344,
        iso3: null,
        iso3_op: 'IND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1262204,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17238',
      geometry: { type: 'Point', coordinates: [56.06333573, 27.10375072] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shahid Rajee',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.10375,
        longitude: 56.06334,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 141681,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17239',
      geometry: { type: 'Point', coordinates: [126.6093, 37.45913387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Inchon',
        code: 'KRINC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.45913,
        longitude: 126.6093,
        iso3: null,
        iso3_op: 'KOR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1843564,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17240',
      geometry: { type: 'Point', coordinates: [35.60258869, 33.96979897] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zouq',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.9698,
        longitude: 35.60259,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fuel Oil And Others',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 6941107,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17241',
      geometry: { type: 'Point', coordinates: [34.97961416, -17.32995719] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tsoni',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -17.32996,
        longitude: 34.97961,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Tsoni: (I) - No Vessel Restriction- (Ii) - Truck Access- (Iii) - Network Coverage - Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1048363,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17242',
      geometry: { type: 'Point', coordinates: [123.21357281, 9.12224429] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malatapay',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.12224,
        longitude: 123.21357,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1679423,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17243',
      geometry: { type: 'Point', coordinates: [-8.69641534, 41.18175523] },
      geometry_name: 'shape',
      properties: {
        portname: 'Leixoes',
        code: 'PTLEI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.18176,
        longitude: -8.69642,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Portugal',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2735943,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17244',
      geometry: { type: 'Point', coordinates: [-56.07752589, 5.16111767] },
      geometry_name: 'shape',
      properties: {
        portname: 'Main Brook',
        code: 'CAMBR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.16112,
        longitude: -56.07753,
        iso3: 'SUR',
        iso3_op: 'SUR',
        country: 'Suriname',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3384578,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17245',
      geometry: { type: 'Point', coordinates: [17.26286768, 60.68417292] },
      geometry_name: 'shape',
      properties: {
        portname: 'Karskar',
        code: 'SEKAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.68417,
        longitude: 17.26287,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17246',
      geometry: { type: 'Point', coordinates: [105.70856976, 18.65489477] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nghe Tinh',
        code: 'VNNGT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.65489,
        longitude: 105.70857,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1562798,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17247',
      geometry: { type: 'Point', coordinates: [83.2999435, 17.69602552] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vishakhapatnam',
        code: 'INVTZ',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.69603,
        longitude: 83.29994,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1253102,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17248',
      geometry: { type: 'Point', coordinates: [19.56788608, -0.19240755] },
      geometry_name: 'shape',
      properties: {
        portname: 'Boleke',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -0.19241,
        longitude: 19.56789,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2316787,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17249',
      geometry: { type: 'Point', coordinates: [21.69423949, 0.13882797] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mompono',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.13883,
        longitude: 21.69424,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 208793,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17250',
      geometry: { type: 'Point', coordinates: [22.40123237, 0.16360963] },
      geometry_name: 'shape',
      properties: {
        portname: 'Berori',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.16361,
        longitude: 22.40123,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 219261,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17251',
      geometry: { type: 'Point', coordinates: [-123.91132433, 46.97416641] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hoquiam-Aberdeen Apt',
        code: 'USHQM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.97417,
        longitude: -123.91132,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 5797693,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17252',
      geometry: { type: 'Point', coordinates: [-90.35721284, 29.94149398] },
      geometry_name: 'shape',
      properties: {
        portname: 'Destrehan',
        code: 'USNEW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.94149,
        longitude: -90.35721,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4322034,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17253',
      geometry: { type: 'Point', coordinates: [-68.42693965, 44.49982439] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ellsworth',
        code: 'USEWT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.49982,
        longitude: -68.42694,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4982587,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17254',
      geometry: { type: 'Point', coordinates: [9.21050252, 44.30247018] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portofino',
        code: 'ITPTF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.30247,
        longitude: 9.2105,
        iso3: null,
        iso3_op: 'ITA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3170089,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17255',
      geometry: { type: 'Point', coordinates: [0.53702822, 49.47124033] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gravenchon',
        code: 'FRGRN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.47124,
        longitude: 0.53703,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2990056,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17256',
      geometry: { type: 'Point', coordinates: [2.35908922, 51.03991422] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dunkerque (Dunkirk)',
        code: 'FRDKK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.03991,
        longitude: 2.35909,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3020686,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17257',
      geometry: { type: 'Point', coordinates: [4.33218722, 51.15010988] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hemiksem (Hemixen)',
        code: 'BEHEX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.15011,
        longitude: 4.33219,
        iso3: 'BEL',
        iso3_op: 'BEL',
        country: 'Belgium',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2796085,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17258',
      geometry: { type: 'Point', coordinates: [-3.25182851, 51.40029795] },
      geometry_name: 'shape',
      properties: {
        portname: 'Barry Dock',
        code: 'GBBAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.4003,
        longitude: -3.25183,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2656235,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17259',
      geometry: { type: 'Point', coordinates: [134.66091541, 34.78502349] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shikama',
        code: 'JPSKM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.78502,
        longitude: 134.66092,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1851935,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17260',
      geometry: { type: 'Point', coordinates: [100.9009753, 13.12749413] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sriracha',
        code: 'THSRI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.12749,
        longitude: 100.90098,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1606250,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17261',
      geometry: { type: 'Point', coordinates: [131.66367695, 33.26032288] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oita',
        code: 'JPOIT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.26032,
        longitude: 131.66368,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1854487,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17262',
      geometry: { type: 'Point', coordinates: [145.21993364, -38.37517617] },
      geometry_name: 'shape',
      properties: {
        portname: 'Westernport',
        code: 'AUWEP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -38.37518,
        longitude: 145.21993,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17263',
      geometry: { type: 'Point', coordinates: [-67.56781724, 48.83160474] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mansonville',
        code: 'CAMAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 48.8316,
        longitude: -67.56782,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17264',
      geometry: { type: 'Point', coordinates: [-90.35024129, 29.93177732] },
      geometry_name: 'shape',
      properties: {
        portname: 'Streator',
        code: 'USSTR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.93178,
        longitude: -90.35024,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17265',
      geometry: { type: 'Point', coordinates: [-73.97958321, 41.9198513] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kings Mountain',
        code: 'USKIN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.91985,
        longitude: -73.97958,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17266',
      geometry: { type: 'Point', coordinates: [-2.76404688, 51.48654904] },
      geometry_name: 'shape',
      properties: {
        portname: 'Redcliff Bay',
        code: 'GBREB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.48655,
        longitude: -2.76405,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17267',
      geometry: { type: 'Point', coordinates: [8.90948944, 44.40487811] },
      geometry_name: 'shape',
      properties: {
        portname: 'Voltri',
        code: 'ITVTI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.40488,
        longitude: 8.90949,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17268',
      geometry: { type: 'Point', coordinates: [166.8605, -45.28506613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Doubtful Sound',
        code: 'NZDGO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -45.28507,
        longitude: 166.8605,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17269',
      geometry: { type: 'Point', coordinates: [66.9760909, 24.83646069] },
      geometry_name: 'shape',
      properties: {
        portname: 'Karachi',
        code: 'PKKHI',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 24.83646,
        longitude: 66.97609,
        iso3: 'PAK',
        iso3_op: 'PAK,AFG',
        country: 'Pakistan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1174872,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17270',
      geometry: { type: 'Point', coordinates: [22.69211183, 63.70803962] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pietarsaari',
        code: 'FIPRS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.70804,
        longitude: 22.69211,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17271',
      geometry: { type: 'Point', coordinates: [4.77926129, 53.00422342] },
      geometry_name: 'shape',
      properties: {
        portname: 'Texel',
        code: 'NLTEX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.00422,
        longitude: 4.77926,
        iso3: 'NLD',
        iso3_op: 'NLD',
        country: 'Netherlands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17272',
      geometry: { type: 'Point', coordinates: [-48.7490194, -1.54311793] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vila De Conde',
        code: 'BRVDC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.54312,
        longitude: -48.74902,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3405870,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17273',
      geometry: { type: 'Point', coordinates: [28.79438921, 30.95545909] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mersa El Hamra',
        code: 'EGMAH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.95546,
        longitude: 28.79439,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 356177,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17274',
      geometry: { type: 'Point', coordinates: [135.20971708, 34.34179067] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hannan',
        code: 'JPHAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.34179,
        longitude: 135.20972,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1861091,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17275',
      geometry: { type: 'Point', coordinates: [132.44170323, 34.2240854] },
      geometry_name: 'shape',
      properties: {
        portname: 'Etajima',
        code: 'JPETA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.22409,
        longitude: 132.4417,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1860126,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17276',
      geometry: { type: 'Point', coordinates: [130.38244854, 33.59727071] },
      geometry_name: 'shape',
      properties: {
        portname: 'Futajima',
        code: 'JPFUT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.59727,
        longitude: 130.38245,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1863967,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17277',
      geometry: { type: 'Point', coordinates: [129.60061616, 32.93693829] },
      geometry_name: 'shape',
      properties: {
        portname: 'Matsushima',
        code: 'JPMAT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 32.93694,
        longitude: 129.60062,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1852667,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17278',
      geometry: { type: 'Point', coordinates: [103.78371656, 1.47067456] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jambongan',
        code: 'MYJAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 1.47067,
        longitude: 103.78372,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1732752,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17279',
      geometry: { type: 'Point', coordinates: [43.4049753, 12.64610735] },
      geometry_name: 'shape',
      properties: {
        portname: 'Perim Island',
        code: 'YEPRM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.64611,
        longitude: 43.40498,
        iso3: null,
        iso3_op: '',
        country: null,
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 72736,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17280',
      geometry: { type: 'Point', coordinates: [-34.96422102, -8.39583156] },
      geometry_name: 'shape',
      properties: {
        portname: 'Suape',
        code: 'BRSUA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.39583,
        longitude: -34.96422,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3390760,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17281',
      geometry: { type: 'Point', coordinates: [-43.69785505, -22.98564034] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bahia De Sepetiba',
        code: 'BRSPB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -22.98564,
        longitude: -43.69786,
        iso3: 'BRA',
        iso3_op: 'BRA',
        country: 'Brazil',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3451190,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17282',
      geometry: { type: 'Point', coordinates: [110.39777862, 21.18238526] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zhanjiang',
        code: 'CNZHA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.18239,
        longitude: 110.39778,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1784990,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17283',
      geometry: { type: 'Point', coordinates: [121.86285741, 24.60863979] },
      geometry_name: 'shape',
      properties: {
        portname: 'Suao',
        code: 'TWSUO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.60864,
        longitude: 121.86286,
        iso3: 'TWN',
        iso3_op: 'CHN',
        country: 'Taiwan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1674199,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17284',
      geometry: { type: 'Point', coordinates: [122.1759119, 29.96283806] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zhoushan',
        code: 'CNZOS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.96284,
        longitude: 122.17591,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1886762,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17285',
      geometry: { type: 'Point', coordinates: [174.63614133, -39.83303362] },
      geometry_name: 'shape',
      properties: {
        portname: 'Waverley Harbour',
        code: 'NZWAV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -39.83303,
        longitude: 174.63614,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 2179581,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17286',
      geometry: { type: 'Point', coordinates: [128.70495735, 34.87565964] },
      geometry_name: 'shape',
      properties: {
        portname: 'Okpo',
        code: 'KROKP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.87566,
        longitude: 128.70496,
        iso3: null,
        iso3_op: 'KOR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1839839,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17287',
      geometry: { type: 'Point', coordinates: [44.19008597, 65.87328448] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mezen',
        code: 'RUMEZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 65.87328,
        longitude: 44.19009,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 527321,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17288',
      geometry: { type: 'Point', coordinates: [-97.20160345, 26.07307647] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Isabel-Brownsville',
        code: 'USPIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.07308,
        longitude: -97.2016,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 4720060,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17289',
      geometry: { type: 'Point', coordinates: [36.2375378, -18.66848954] },
      geometry_name: 'shape',
      properties: {
        portname: 'Matilde',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -18.66849,
        longitude: 36.23754,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Matilde: (I) -No Vessel Restriction- (Ii) -No Truck Access- (Iii) -Network Coverage- (Iv) -No Hazard To Navigation--Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 1039909,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17294',
      geometry: { type: 'Point', coordinates: [18.8414634, -3.22366471] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maleke',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -3.22366,
        longitude: 18.84146,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 8446853,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17296',
      geometry: { type: 'Point', coordinates: [18.57120146, 54.44625941] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sopot',
        code: 'PLSOP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.44626,
        longitude: 18.5712,
        iso3: 'POL',
        iso3_op: 'POL',
        country: 'Poland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3085151,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17297',
      geometry: { type: 'Point', coordinates: [153.07255533, -30.88153147] },
      geometry_name: 'shape',
      properties: {
        portname: 'Trial Bay',
        code: 'AUTRB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -30.88153,
        longitude: 153.07256,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17298',
      geometry: { type: 'Point', coordinates: [-65.84485804, 47.90227038] },
      geometry_name: 'shape',
      properties: {
        portname: 'Belledune',
        code: 'CABEL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.90227,
        longitude: -65.84486,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17299',
      geometry: { type: 'Point', coordinates: [-75.58886224, 35.26175709] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cape Hatteras',
        code: 'USCPH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.26176,
        longitude: -75.58886,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17300',
      geometry: { type: 'Point', coordinates: [9.66717904, 63.68885265] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brekstad',
        code: 'NOBKD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.68885,
        longitude: 9.66718,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17301',
      geometry: { type: 'Point', coordinates: [-7.31920753, 55.00659834] },
      geometry_name: 'shape',
      properties: {
        portname: 'Londonderry',
        code: 'GBLDY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.0066,
        longitude: -7.31921,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17291',
      geometry: { type: 'Point', coordinates: [17.4, -5.83] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -5.83,
        longitude: 17.4,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-27T06:49:46.578Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17292',
      geometry: { type: 'Point', coordinates: [17.42908827, -5.86255438] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -5.86255,
        longitude: 17.42909,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-27T06:54:35.920Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17293',
      geometry: { type: 'Point', coordinates: [17.58289686, -5.85708992] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -5.85709,
        longitude: 17.5829,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-27T06:59:19.697Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17295',
      geometry: { type: 'Point', coordinates: [23.2061, -2.26676613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ekolosongo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -2.26677,
        longitude: 23.2061,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-27T07:04:08.487Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17302',
      geometry: { type: 'Point', coordinates: [9.43583755, 55.02336565] },
      geometry_name: 'shape',
      properties: {
        portname: 'Enstedvaerkets Havn',
        code: 'DKENS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.02337,
        longitude: 9.43584,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17303',
      geometry: { type: 'Point', coordinates: [-24.99576018, 16.87768277] },
      geometry_name: 'shape',
      properties: {
        portname: 'St Vincent',
        code: 'VCSVD',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 16.87768,
        longitude: -24.99576,
        iso3: 'CPV',
        iso3_op: 'CPV',
        country: 'Cape Verde',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3374462,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17304',
      geometry: { type: 'Point', coordinates: [32.81445263, 35.14621328] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gemikonagi',
        code: 'CYMPB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.14621,
        longitude: 32.81445,
        iso3: 'CYP',
        iso3_op: 'CYP',
        country: 'Cyprus',
        lastcheckdate: null,
        remarks: 'Morphou',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17305',
      geometry: { type: 'Point', coordinates: [-91.3682, -0.26036613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Caleta Tagus',
        code: 'ECCAT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.26037,
        longitude: -91.3682,
        iso3: 'ECU',
        iso3_op: 'ECU',
        country: 'Ecuador',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3650861,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17306',
      geometry: { type: 'Point', coordinates: [33.58841917, 27.64302285] },
      geometry_name: 'shape',
      properties: {
        portname: 'Geisum Terminal',
        code: 'EGGEI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.64302,
        longitude: 33.58842,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17307',
      geometry: { type: 'Point', coordinates: [27.11998629, 36.66157176] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yali Island',
        code: 'GRYLI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.66157,
        longitude: 27.11999,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17308',
      geometry: { type: 'Point', coordinates: [20.90003277, 37.77783604] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zante',
        code: 'GRZAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.77784,
        longitude: 20.90003,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17309',
      geometry: { type: 'Point', coordinates: [-90.78617652, 13.92729145] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Quetzal',
        code: 'GTPRQ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.92729,
        longitude: -90.78618,
        iso3: 'GTM',
        iso3_op: 'GTM',
        country: 'Guatemala',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-24T11:54:14.295Z',
        geonameid: 3591060,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17310',
      geometry: { type: 'Point', coordinates: [73.65553955, 15.74754608] },
      geometry_name: 'shape',
      properties: {
        portname: 'Redi',
        code: 'INRED',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.74755,
        longitude: 73.65554,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17311',
      geometry: { type: 'Point', coordinates: [-93.18310252, 18.42973932] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dos Bocas Terminal',
        code: 'MXDBT',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.42974,
        longitude: -93.1831,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17312',
      geometry: { type: 'Point', coordinates: [35.37380851, -17.74455281] },
      geometry_name: 'shape',
      properties: {
        portname: 'Braz',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -17.74455,
        longitude: 35.37381,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Braz: (I) -No Vessel Restriction- (Ii) -No Truck Access- (Iii) -Network Coverage- (Iv) -No Hazard To Navigation--Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1095830,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17313',
      geometry: { type: 'Point', coordinates: [36.18311328, -18.55213627] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ilha Salia',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -18.55214,
        longitude: 36.18311,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Ilha Salia: (I) -No Vessel Restriction- (Ii) -No Truck Access- (Iii) -Network Coverage- (Iv) -No Hazard To Navigation--Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1039909,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17314',
      geometry: { type: 'Point', coordinates: [-16.3177495, 33.06401568] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porto',
        code: 'PTOPO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.06402,
        longitude: -16.31775,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Madeira Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2261651,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17315',
      geometry: { type: 'Point', coordinates: [29.74900373, 40.76228125] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yarimca',
        code: 'TRYAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.76228,
        longitude: 29.749,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 737961,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17316',
      geometry: { type: 'Point', coordinates: [120.49771175, 24.22092978] },
      geometry_name: 'shape',
      properties: {
        portname: 'Taichung',
        code: 'TWTXG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.22093,
        longitude: 120.49771,
        iso3: 'TWN',
        iso3_op: 'TWN',
        country: 'Taiwan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1668399,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17317',
      geometry: { type: 'Point', coordinates: [-62.78471405, 8.33062246] },
      geometry_name: 'shape',
      properties: {
        portname: 'Punta Cuchillo',
        code: 'VEPCU',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 8.33062,
        longitude: -62.78471,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3645528,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17318',
      geometry: { type: 'Point', coordinates: [55.27102837, 25.24864816] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Rashid',
        code: 'AEPRA',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.24865,
        longitude: 55.27103,
        iso3: 'ARE',
        iso3_op: 'ARE',
        country: 'United Arab Emirates',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 292223,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17319',
      geometry: { type: 'Point', coordinates: [118.64585938, 24.86119368] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quanzhou',
        code: 'CNQZJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 24.86119,
        longitude: 118.64586,
        iso3: 'CHN',
        iso3_op: 'CHN',
        country: 'China',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1797353,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17321',
      geometry: { type: 'Point', coordinates: [23.14454031, 37.92756203] },
      geometry_name: 'shape',
      properties: {
        portname: 'Agio Theodoroi',
        code: 'GRAGT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.92756,
        longitude: 23.14454,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 264068,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17322',
      geometry: { type: 'Point', coordinates: [29.09449722, -2.22193056] },
      geometry_name: 'shape',
      properties: {
        portname: 'Muguza',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -2.22193,
        longitude: 29.0945,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 217851,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17323',
      geometry: { type: 'Point', coordinates: [29.09043889, -2.18077778] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kabwirwa',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -2.18078,
        longitude: 29.09044,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 215509,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17324',
      geometry: { type: 'Point', coordinates: [49.46727276, 37.47659505] },
      geometry_name: 'shape',
      properties: {
        portname: 'Anzali',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.4766,
        longitude: 49.46727,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 141679,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17325',
      geometry: { type: 'Point', coordinates: [31.94476092, 46.77027006] },
      geometry_name: 'shape',
      properties: {
        portname: 'Halytsynove',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 46.77027,
        longitude: 31.94476,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 709022,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17326',
      geometry: { type: 'Point', coordinates: [48.43100144, -20.50315326] },
      geometry_name: 'shape',
      properties: {
        portname: "Ambavan'i Sahavato",
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: 1,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.50315,
        longitude: 48.431,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-06-30T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1082440,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17327',
      geometry: { type: 'Point', coordinates: [20.92018599, -5.58675964] },
      geometry_name: 'shape',
      properties: {
        portname: 'Djoku-Punda',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'approximate',
        latitude: -5.58676,
        longitude: 20.92019,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 217137,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17328',
      geometry: { type: 'Point', coordinates: [8.43536183, 55.46528781] },
      geometry_name: 'shape',
      properties: {
        portname: 'Esbjerg',
        code: 'DKEBJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.46529,
        longitude: 8.43536,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2622447,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17330',
      geometry: { type: 'Point', coordinates: [68.58585885, 37.19258189] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nijniy Pyanj',
        code: null,
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.19258,
        longitude: 68.58586,
        iso3: 'TJK',
        iso3_op: 'TJK',
        country: 'Tajikistan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1220910,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17331',
      geometry: { type: 'Point', coordinates: [99.79894336, 11.80816524] },
      geometry_name: 'shape',
      properties: {
        portname: 'Prachuap',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.80817,
        longitude: 99.79894,
        iso3: 'THA',
        iso3_op: 'THA',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1151074,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17332',
      geometry: { type: 'Point', coordinates: [41.7592907, 42.00045272] },
      geometry_name: 'shape',
      properties: {
        portname: 'Supsa',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.00045,
        longitude: 41.75929,
        iso3: 'GEO',
        iso3_op: 'GEO',
        country: 'Georgia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 611811,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17333',
      geometry: { type: 'Point', coordinates: [33.94636096, 26.7466606] },
      geometry_name: 'shape',
      properties: {
        portname: 'Safaga',
        code: 'EGSGA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.74666,
        longitude: 33.94636,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 358620,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17334',
      geometry: { type: 'Point', coordinates: [18.65234, -4.56031] },
      geometry_name: 'shape',
      properties: {
        portname: 'Songo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.56031,
        longitude: 18.65234,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 8446369,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17335',
      geometry: { type: 'Point', coordinates: [35.48943711, 23.94184399] },
      geometry_name: 'shape',
      properties: {
        portname: 'Berenice',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.94184,
        longitude: 35.48944,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 359073,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17336',
      geometry: { type: 'Point', coordinates: [133.33514809, 36.20264204] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oki',
        code: 'JPOKI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.20264,
        longitude: 133.33515,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1854260,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17337',
      geometry: { type: 'Point', coordinates: [20.71474163, 38.36565145] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ithaki (Ithaca)',
        code: 'GRITH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.36565,
        longitude: 20.71474,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 261708,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17338',
      geometry: { type: 'Point', coordinates: [24.12442047, 38.63095271] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kymi (Kimi)',
        code: 'GRKIM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.63095,
        longitude: 24.12442,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 259806,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17339',
      geometry: { type: 'Point', coordinates: [3.06146988, 36.76316163] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alger',
        code: 'DZALG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.76316,
        longitude: 3.06147,
        iso3: null,
        iso3_op: 'DZA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2507480,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17340',
      geometry: { type: 'Point', coordinates: [8.71263668, -0.63046316] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cap Lopez',
        code: 'GACLZ',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.63046,
        longitude: 8.71264,
        iso3: 'GAB',
        iso3_op: 'GAB',
        country: 'Gabon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2396518,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17341',
      geometry: { type: 'Point', coordinates: [10.01288118, -2.78758081] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gamba',
        code: 'GAGAX',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.78758,
        longitude: 10.01288,
        iso3: 'GAB',
        iso3_op: 'GAB',
        country: 'Gabon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2400547,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17342',
      geometry: { type: 'Point', coordinates: [20.71021394, 38.83000425] },
      geometry_name: 'shape',
      properties: {
        portname: 'Levkas',
        code: 'GRLEV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.83,
        longitude: 20.71021,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17343',
      geometry: { type: 'Point', coordinates: [9.37682834, 0.30895507] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ekuata',
        code: 'GAEKU',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.30896,
        longitude: 9.37683,
        iso3: 'GAB',
        iso3_op: 'GAB',
        country: 'Gabon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2400720,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17344',
      geometry: { type: 'Point', coordinates: [-15.92500021, 23.70155146] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dajla',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.70155,
        longitude: -15.925,
        iso3: 'ESH',
        iso3_op: 'ESH',
        country: 'Western Sahara',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2463447,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17345',
      geometry: { type: 'Point', coordinates: [25.68642697, -33.7970911] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ngqura',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -33.79709,
        longitude: 25.68643,
        iso3: null,
        iso3_op: 'ZAF, MWI, ZWE',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 964420,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17346',
      geometry: { type: 'Point', coordinates: [10.50202407, 59.32187276] },
      geometry_name: 'shape',
      properties: {
        portname: 'Slagenstangen',
        code: 'NOSLG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.32187,
        longitude: 10.50202,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17347',
      geometry: { type: 'Point', coordinates: [11.13939155, 55.88328421] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sejero',
        code: 'DKSJO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 55.88328,
        longitude: 11.13939,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17348',
      geometry: { type: 'Point', coordinates: [-14.02871309, 65.07583162] },
      geometry_name: 'shape',
      properties: {
        portname: 'Raoul Port',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Closed',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 65.07583,
        longitude: -14.02871,
        iso3: 'ISL',
        iso3_op: 'ISL',
        country: 'Iceland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17349',
      geometry: { type: 'Point', coordinates: [10.9267, -3.88156613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lucina',
        code: 'GALUC',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -3.88157,
        longitude: 10.9267,
        iso3: null,
        iso3_op: 'GAB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2399001,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17350',
      geometry: { type: 'Point', coordinates: [136.5381569, 34.27889568] },
      geometry_name: 'shape',
      properties: {
        portname: 'Minamiise',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.2789,
        longitude: 136.53816,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1860367,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17351',
      geometry: { type: 'Point', coordinates: [45.40535605, -19.68289254] },
      geometry_name: 'shape',
      properties: {
        portname: 'Masiakampy',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: 1,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -19.68289,
        longitude: 45.40536,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: '2014-06-30T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17352',
      geometry: { type: 'Point', coordinates: [29.71136362, 45.46653006] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ust Dunaysk',
        code: 'UAUDY',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 45.46653,
        longitude: 29.71136,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 710074,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17353',
      geometry: { type: 'Point', coordinates: [-57.84385545, -34.47431433] },
      geometry_name: 'shape',
      properties: {
        portname: 'Colonia',
        code: 'UYCYR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.47431,
        longitude: -57.84386,
        iso3: 'URY',
        iso3_op: 'URY',
        country: 'Uruguay',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3443013,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17354',
      geometry: { type: 'Point', coordinates: [35.94398286, 35.18357739] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baniyas',
        code: 'SYBAN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.18358,
        longitude: 35.94398,
        iso3: null,
        iso3_op: 'SYR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 171830,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17355',
      geometry: { type: 'Point', coordinates: [22.30948684, 1.50298868] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bosenge',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.50299,
        longitude: 22.30949,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 218086,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17356',
      geometry: { type: 'Point', coordinates: [24.06292346, 37.71261537] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lavrion (Laurium)',
        code: 'GRLAV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.71262,
        longitude: 24.06292,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 258543,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17357',
      geometry: { type: 'Point', coordinates: [30.37314176, 46.18324746] },
      geometry_name: 'shape',
      properties: {
        portname: 'Belgorod-Dnestrovskiy',
        code: 'UABGD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 46.18325,
        longitude: 30.37314,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 712160,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17359',
      geometry: { type: 'Point', coordinates: [8.49329997, 53.3389538] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brake',
        code: 'DEBKE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.33895,
        longitude: 8.4933,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2945662,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17360',
      geometry: { type: 'Point', coordinates: [17.9663, 0.43653387] },
      geometry_name: 'shape',
      properties: {
        portname: "N'Giri",
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'unknown',
        latitude: 0.43653,
        longitude: 17.9663,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 8057913,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17362',
      geometry: { type: 'Point', coordinates: [-82.80430594, 27.96419188] },
      geometry_name: 'shape',
      properties: {
        portname: 'Clear',
        code: 'USCXI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.96419,
        longitude: -82.80431,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 4151316,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17363',
      geometry: { type: 'Point', coordinates: [22.45668027, -3.46947682] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kole',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.46948,
        longitude: 22.45668,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 8447225,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17365',
      geometry: { type: 'Point', coordinates: [35.59680205, 33.95836976] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nahr El Kalb',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.95837,
        longitude: 35.5968,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 271839,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17367',
      geometry: { type: 'Point', coordinates: [16.12867484, -3.48149079] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kuzulu',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -3.48149,
        longitude: 16.12867,
        iso3: 'COD',
        iso3_op: 'COG',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2258686,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17368',
      geometry: { type: 'Point', coordinates: [30.15253608, 31.26969718] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Maadiya Port',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.2697,
        longitude: 30.15254,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 360675,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17369',
      geometry: { type: 'Point', coordinates: [-9.49498775, 52.63493246] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kilrush',
        code: 'IEKLR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.63493,
        longitude: -9.49499,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2963218,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17371',
      geometry: { type: 'Point', coordinates: [-64.54270723, 46.22559377] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shediac',
        code: 'CASHE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.22559,
        longitude: -64.54271,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17372',
      geometry: { type: 'Point', coordinates: [-18.81242943, 64.24768845] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 64.24769,
        longitude: -18.81243,
        iso3: 'ISL',
        iso3_op: 'ISL',
        country: 'Iceland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17373',
      geometry: { type: 'Point', coordinates: [33.60550073, 28.23557473] },
      geometry_name: 'shape',
      properties: {
        portname: 'Al-Tour',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.23557,
        longitude: 33.6055,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 359678,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17374',
      geometry: { type: 'Point', coordinates: [-15.37772088, 27.98047225] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salinetas',
        code: 'ESSAT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.98047,
        longitude: -15.37772,
        iso3: 'ESP',
        iso3_op: 'ESP',
        country: 'Spain',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2513952,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17375',
      geometry: { type: 'Point', coordinates: [-73.09050741, 18.44521793] },
      geometry_name: 'shape',
      properties: {
        portname: 'Miragoane',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.44522,
        longitude: -73.09051,
        iso3: 'HTI',
        iso3_op: 'HTI',
        country: 'Haiti',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3720824,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17376',
      geometry: { type: 'Point', coordinates: [48.40378479, 30.1476393] },
      geometry_name: 'shape',
      properties: {
        portname: 'Khoasrowabad',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 30.14764,
        longitude: 48.40378,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 127251,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17377',
      geometry: { type: 'Point', coordinates: [35.63130463, 34.04530413] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Bware',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.0453,
        longitude: 35.6313,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 278097,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17378',
      geometry: { type: 'Point', coordinates: [48.2791999, -13.4068825] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nossi-Be',
        code: 'MGNOS',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: 5,
        maxlength: 150,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -13.40688,
        longitude: 48.2792,
        iso3: null,
        iso3_op: 'MDG',
        country: null,
        lastcheckdate: '2013-12-04T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1076105,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17379',
      geometry: { type: 'Point', coordinates: [-67.90623511, 18.06068188] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mona Island',
        code: 'USOIS',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.06068,
        longitude: -67.90624,
        iso3: 'PRI',
        iso3_op: 'PRI',
        country: 'Puerto Rico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17380',
      geometry: { type: 'Point', coordinates: [27.26480382, 44.12380817] },
      geometry_name: 'shape',
      properties: {
        portname: 'Silistra',
        code: 'BGSLS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.12381,
        longitude: 27.2648,
        iso3: 'BGR',
        iso3_op: 'ROU',
        country: 'Bulgaria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 727221,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17381',
      geometry: { type: 'Point', coordinates: [20.36109721, 63.70181531] },
      geometry_name: 'shape',
      properties: {
        portname: 'Holmsund',
        code: 'SEHLD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.70182,
        longitude: 20.3611,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17382',
      geometry: { type: 'Point', coordinates: [29.54326193, 40.77108392] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dilovasi',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.77108,
        longitude: 29.54326,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 9965495,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17383',
      geometry: { type: 'Point', coordinates: [30.60470482, 36.83469194] },
      geometry_name: 'shape',
      properties: {
        portname: 'Akdeniz',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.83469,
        longitude: 30.6047,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 323777,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17384',
      geometry: { type: 'Point', coordinates: [107.05224031, 20.97233976] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quang Ninh',
        code: 'VNQNH',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.97234,
        longitude: 107.05224,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1580410,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17385',
      geometry: { type: 'Point', coordinates: [106.7181, 10.75853387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saigon',
        code: 'VNSGN',
        prttype: 'River',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.75853,
        longitude: 106.7181,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: 'Ho Chi Min City',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1566083,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17386',
      geometry: { type: 'Point', coordinates: [37.2355537, 19.60821533] },
      geometry_name: 'shape',
      properties: {
        portname: 'Green Harbor',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.60822,
        longitude: 37.23555,
        iso3: null,
        iso3_op: 'SDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 377039,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17387',
      geometry: { type: 'Point', coordinates: [123.91540711, 10.30435341] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cebu',
        code: 'PHCEB',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.30435,
        longitude: 123.91541,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: '2013-12-07T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'PHL CO',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1717512,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17389',
      geometry: { type: 'Point', coordinates: [103.4595978, -0.81315192] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanjung Balai Karimun',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.81315,
        longitude: 103.4596,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1639286,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17391',
      geometry: { type: 'Point', coordinates: [29.08801111, -1.96719444] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kishenyabuhyo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -1.96719,
        longitude: 29.08801,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 212699,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17393',
      geometry: { type: 'Point', coordinates: [29.0550633, -2.05502912] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mushange I & II',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -2.05503,
        longitude: 29.05506,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 207969,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17399',
      geometry: { type: 'Point', coordinates: [29.05744167, -2.05101389] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kaseke',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -2.05101,
        longitude: 29.05744,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 214059,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17401',
      geometry: { type: 'Point', coordinates: [31.02712031, 46.61594949] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yuzhnyy',
        code: 'UAYUZ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 46.61595,
        longitude: 31.02712,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 687997,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17402',
      geometry: { type: 'Point', coordinates: [31.97246797, 46.94275266] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nikolayev',
        code: 'UANIK',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 46.94275,
        longitude: 31.97247,
        iso3: 'UKR',
        iso3_op: 'UKR',
        country: 'Ukraine',
        lastcheckdate: '2014-03-02T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'http://www.worldportsource.com',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 700569,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17404',
      geometry: { type: 'Point', coordinates: [136.53013282, 34.68235267] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tsu',
        code: 'JPTSU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.68235,
        longitude: 136.53013,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1849796,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17405',
      geometry: { type: 'Point', coordinates: [32.36549726, 29.68416043] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Sokhna',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 29.68416,
        longitude: 32.3655,
        iso3: null,
        iso3_op: 'EGY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 7521348,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17407',
      geometry: { type: 'Point', coordinates: [19.10478438, 1.60550487] },
      geometry_name: 'shape',
      properties: {
        portname: 'Makanza',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 1.6055,
        longitude: 19.10478,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2313174,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17408',
      geometry: { type: 'Point', coordinates: [18.03767631, -3.38215885] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kese',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -3.38216,
        longitude: 18.03768,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2314926,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17409',
      geometry: { type: 'Point', coordinates: [-0.56516245, 45.00736806] },
      geometry_name: 'shape',
      properties: {
        portname: "Bec D'Ambes",
        code: 'FRBEC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.00737,
        longitude: -0.56516,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3037935,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17410',
      geometry: { type: 'Point', coordinates: [5.11832107, 43.40213526] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Mede',
        code: 'FRLMD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.40214,
        longitude: 5.11832,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3008190,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17411',
      geometry: { type: 'Point', coordinates: [20.73583306, 0.77317294] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bususulu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 0.77317,
        longitude: 20.73583,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 218041,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17412',
      geometry: { type: 'Point', coordinates: [20.75935809, 3.2197477] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bokolo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 3.21975,
        longitude: 20.75936,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 218491,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17413',
      geometry: { type: 'Point', coordinates: [-60.40053438, 53.34341589] },
      geometry_name: 'shape',
      properties: {
        portname: 'Goose Bay',
        code: 'CAGOO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.34342,
        longitude: -60.40053,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 5970458,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17414',
      geometry: { type: 'Point', coordinates: [14.02448626, 67.03565306] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gildeskal',
        code: 'NOGIL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 67.03565,
        longitude: 14.02449,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17415',
      geometry: { type: 'Point', coordinates: [-18.90819286, 66.1488343] },
      geometry_name: 'shape',
      properties: {
        portname: 'Siglufjordur - Hofn',
        code: 'ISSIG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 66.14883,
        longitude: -18.90819,
        iso3: null,
        iso3_op: 'ISL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17416',
      geometry: { type: 'Point', coordinates: [34.66566954, 28.97304697] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nuweibah',
        code: 'EGNUW',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.97305,
        longitude: 34.66567,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 350892,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17417',
      geometry: { type: 'Point', coordinates: [46.96285582, -25.06777528] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tolanaro',
        code: 'MGFTU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -25.06778,
        longitude: 46.96286,
        iso3: 'MDG',
        iso3_op: 'MDG',
        country: 'Madagascar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'Bing',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1055433,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17418',
      geometry: { type: 'Point', coordinates: [35.32617994, -17.60574427] },
      geometry_name: 'shape',
      properties: {
        portname: 'Goli Goli',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -17.60574,
        longitude: 35.32618,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Goli Goli: (I) -No Restriction- (Ii) -No Truck Access- (Iii) -No Network Coverage- Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17419',
      geometry: { type: 'Point', coordinates: [148.49462387, -8.8973205] },
      geometry_name: 'shape',
      properties: {
        portname: 'Oro Bay',
        code: 'PGROR',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.89732,
        longitude: 148.49462,
        iso3: null,
        iso3_op: 'PNG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2099740,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17420',
      geometry: { type: 'Point', coordinates: [155.546641, -6.19665775] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aneway Bay',
        code: 'PGANB',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Closed',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -6.19666,
        longitude: 155.54664,
        iso3: 'PNG',
        iso3_op: 'PNG',
        country: 'Papua New Guinea',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2100633,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17421',
      geometry: { type: 'Point', coordinates: [12.13773014, 58.10830194] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gota',
        code: 'SEGOA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.1083,
        longitude: 12.13773,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17422',
      geometry: { type: 'Point', coordinates: [108.79071702, 15.1335937] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quang Ngai',
        code: 'VNXNG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 15.13359,
        longitude: 108.79072,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1568770,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17423',
      geometry: { type: 'Point', coordinates: [-74.14167302, 45.25615644] },
      geometry_name: 'shape',
      properties: {
        portname: 'Valleyfield',
        code: 'CAVLF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 45.25616,
        longitude: -74.14167,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 6139289,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17424',
      geometry: { type: 'Point', coordinates: [-73.13758879, 46.04735837] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tracy',
        code: 'CATRC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.04736,
        longitude: -73.13759,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 6151352,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17425',
      geometry: { type: 'Point', coordinates: [-80.62759478, 28.41084991] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Canaveral',
        code: 'USPCA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.41085,
        longitude: -80.62759,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 4149959,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17426',
      geometry: { type: 'Point', coordinates: [-124.18946298, 49.26227878] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nanoose',
        code: 'CANNE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.26228,
        longitude: -124.18946,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 8504652,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17427',
      geometry: { type: 'Point', coordinates: [-122.6041, 49.33393387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ioco',
        code: 'CAIOC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 49.33393,
        longitude: -122.6041,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 5983663,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17390',
      geometry: { type: 'Point', coordinates: [29.10453611, -1.96147222] },
      geometry_name: 'shape',
      properties: {
        portname: 'Butonyi',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -1.96147,
        longitude: 29.10454,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-28T06:49:57.188Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17394',
      geometry: { type: 'Point', coordinates: [29.05377525, -2.05455833] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kamasha',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -2.05456,
        longitude: 29.05378,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-28T06:59:31.489Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17395',
      geometry: { type: 'Point', coordinates: [29.02259722, -2.14209722] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mwiri',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -2.1421,
        longitude: 29.0226,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-28T07:04:19.947Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17396',
      geometry: { type: 'Point', coordinates: [29.02319286, -2.14512352] },
      geometry_name: 'shape',
      properties: {
        portname: 'Karhulo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -2.14512,
        longitude: 29.02319,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-28T07:09:10.718Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17428',
      geometry: { type: 'Point', coordinates: [11.70979906, 57.89426248] },
      geometry_name: 'shape',
      properties: {
        portname: 'Marieberg',
        code: 'SEMAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 57.89426,
        longitude: 11.7098,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2692688,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17429',
      geometry: { type: 'Point', coordinates: [-22.541829, 63.98596894] },
      geometry_name: 'shape',
      properties: {
        portname: 'Keflavikurkaupstadur',
        code: 'ISKEV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.98597,
        longitude: -22.54183,
        iso3: null,
        iso3_op: 'ISL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3415496,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17430',
      geometry: { type: 'Point', coordinates: [4.34686516, 50.86008949] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brussel (Bruxelles)',
        code: 'BEBRU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.86009,
        longitude: 4.34687,
        iso3: 'BEL',
        iso3_op: 'BEL',
        country: 'Belgium',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2800866,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17431',
      geometry: { type: 'Point', coordinates: [4.38620893, 51.24949853] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antwerpen (Antwerp)',
        code: 'BEANR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 51.2495,
        longitude: 4.38621,
        iso3: 'BEL',
        iso3_op: 'BEL',
        country: 'Belgium',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2803138,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17432',
      geometry: { type: 'Point', coordinates: [133.55796057, 33.98993842] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mishima-Kawanoe',
        code: 'JPKWN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.98994,
        longitude: 133.55796,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1926092,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17433',
      geometry: { type: 'Point', coordinates: [-6.6730844, 34.25683286] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kenitra-Media',
        code: 'MANNA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.25683,
        longitude: -6.67308,
        iso3: 'MAR',
        iso3_op: 'MAR',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2544571,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17434',
      geometry: { type: 'Point', coordinates: [37.33216307, 41.1129736] },
      geometry_name: 'shape',
      properties: {
        portname: 'Unye',
        code: 'TRUNY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.11297,
        longitude: 37.33216,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 738349,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17435',
      geometry: { type: 'Point', coordinates: [135.44486824, 34.61669363] },
      geometry_name: 'shape',
      properties: {
        portname: 'Suminoe',
        code: 'JPSUM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.61669,
        longitude: 135.44487,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1855159,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17436',
      geometry: { type: 'Point', coordinates: [121.03275179, 13.76836551] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tabangoa',
        code: 'PHTBG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.76837,
        longitude: 121.03275,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1685079,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17437',
      geometry: { type: 'Point', coordinates: [115.53812837, -31.99477464] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rottnest Island',
        code: 'AURTS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -31.99477,
        longitude: 115.53813,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17438',
      geometry: { type: 'Point', coordinates: [130.63882301, -11.76051687] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bathurst Island',
        code: 'AUBRT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -11.76052,
        longitude: 130.63882,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17439',
      geometry: { type: 'Point', coordinates: [143.84520268, -39.92794551] },
      geometry_name: 'shape',
      properties: {
        portname: 'King Bay',
        code: 'AUKIB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -39.92795,
        longitude: 143.8452,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17440',
      geometry: { type: 'Point', coordinates: [-68.50906067, 63.74111168] },
      geometry_name: 'shape',
      properties: {
        portname: 'Frobisher Bay',
        code: 'CAFBB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.74111,
        longitude: -68.50906,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17441',
      geometry: { type: 'Point', coordinates: [-93.98358934, 30.00676074] },
      geometry_name: 'shape',
      properties: {
        portname: "Smith'S Bluff",
        code: 'USSML',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.00676,
        longitude: -93.98359,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17442',
      geometry: { type: 'Point', coordinates: [-73.54539339, 41.04314585] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stamps',
        code: 'USSTM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.04315,
        longitude: -73.54539,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17443',
      geometry: { type: 'Point', coordinates: [10.49106912, 59.26040453] },
      geometry_name: 'shape',
      properties: {
        portname: 'Valloy',
        code: 'NOVAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.2604,
        longitude: 10.49107,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17444',
      geometry: { type: 'Point', coordinates: [15.38979609, 67.10361449] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saltdal',
        code: 'NOSDL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 67.10361,
        longitude: 15.3898,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17445',
      geometry: { type: 'Point', coordinates: [170.62699393, -45.79917033] },
      geometry_name: 'shape',
      properties: {
        portname: 'Otago Harbour',
        code: 'NZORR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -45.79917,
        longitude: 170.62699,
        iso3: null,
        iso3_op: 'NZL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17446',
      geometry: { type: 'Point', coordinates: [72.95042642, 18.95374297] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jawaharlal Nehru',
        code: 'INNSA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.95374,
        longitude: 72.95043,
        iso3: null,
        iso3_op: 'IND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1275339,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17447',
      geometry: { type: 'Point', coordinates: [131.59141975, 34.03782691] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mitajiri',
        code: 'JPNAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.03783,
        longitude: 131.59142,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1862302,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17448',
      geometry: { type: 'Point', coordinates: [135.50550436, 35.52226092] },
      geometry_name: 'shape',
      properties: {
        portname: 'Uchiura',
        code: 'JPUCR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.52226,
        longitude: 135.5055,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1857766,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17449',
      geometry: { type: 'Point', coordinates: [136.90050067, 37.39974646] },
      geometry_name: 'shape',
      properties: {
        portname: 'Wajima',
        code: 'JPWJM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.39975,
        longitude: 136.9005,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1848976,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17450',
      geometry: { type: 'Point', coordinates: [48.38340053, 28.71919997] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mina Saud',
        code: 'KWMIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 28.7192,
        longitude: 48.3834,
        iso3: 'KWT',
        iso3_op: 'KWT',
        country: 'Kuwait',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 285542,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17451',
      geometry: { type: 'Point', coordinates: [73.14929481, -0.68777001] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gan Island',
        code: 'MVGAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.68777,
        longitude: 73.14929,
        iso3: null,
        iso3_op: 'MDV',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17452',
      geometry: { type: 'Point', coordinates: [115.2332142, 5.27288835] },
      geometry_name: 'shape',
      properties: {
        portname: 'Labuan Hadji',
        code: 'MYLBH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.27289,
        longitude: 115.23321,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17453',
      geometry: { type: 'Point', coordinates: [102.2542325, 2.19090168] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malacca',
        code: 'MYMKZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.1909,
        longitude: 102.25423,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1734759,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17454',
      geometry: { type: 'Point', coordinates: [166.2411397, -21.6145692] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thialis',
        code: 'FRTHI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -21.61457,
        longitude: 166.24114,
        iso3: null,
        iso3_op: 'NCL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2138103,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17455',
      geometry: { type: 'Point', coordinates: [29.53001704, 40.76635745] },
      geometry_name: 'shape',
      properties: {
        portname: 'Diliskelesi',
        code: 'TRDIL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.76636,
        longitude: 29.53002,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 745652,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17456',
      geometry: { type: 'Point', coordinates: [42.87917105, 14.91481611] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Al Kalib',
        code: 'YERAK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.91482,
        longitude: 42.87917,
        iso3: 'YEM',
        iso3_op: '',
        country: 'Yemen',
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 79415,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17457',
      geometry: { type: 'Point', coordinates: [-109.43840627, -27.15358284] },
      geometry_name: 'shape',
      properties: {
        portname: 'Isla De Pascua',
        code: 'CLIPC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -27.15358,
        longitude: -109.43841,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 4030754,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17458',
      geometry: { type: 'Point', coordinates: [78.1940957, 8.75635715] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tuticorin',
        code: 'INTUT',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.75636,
        longitude: 78.1941,
        iso3: null,
        iso3_op: 'IND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 8629640,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17459',
      geometry: { type: 'Point', coordinates: [23.04879828, 63.86256152] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kokkola',
        code: 'FIKOK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.86256,
        longitude: 23.0488,
        iso3: 'FIN',
        iso3_op: 'FIN',
        country: 'Finland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 651945,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17460',
      geometry: { type: 'Point', coordinates: [-66.65541199, 48.00000141] },
      geometry_name: 'shape',
      properties: {
        portname: 'Campbellton',
        code: 'CACOM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 48,
        longitude: -66.65541,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 6696258,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17461',
      geometry: { type: 'Point', coordinates: [49.76883751, -16.16114781] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mananara',
        code: 'MGWMR',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.16115,
        longitude: 49.76884,
        iso3: null,
        iso3_op: 'MDG',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'Bing',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1061412,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17462',
      geometry: { type: 'Point', coordinates: [11.88131114, 55.81154527] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kyndby / Krostrup',
        code: 'DKKBY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.81155,
        longitude: 11.88131,
        iso3: 'DNK',
        iso3_op: 'DNK',
        country: 'Denmark',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2618197,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17463',
      geometry: { type: 'Point', coordinates: [17.39247352, 60.64672091] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skutskar',
        code: 'SESSR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 60.64672,
        longitude: 17.39247,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2677019,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17464',
      geometry: { type: 'Point', coordinates: [-81.2817784, 41.75181604] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fairport',
        code: 'USFPT',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.75182,
        longitude: -81.28178,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 5153633,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17465',
      geometry: { type: 'Point', coordinates: [35.04644563, -17.44400973] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sena',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -17.44401,
        longitude: 35.04645,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Sena: (I) -No Vessel Restriction- (Ii) -Truck Access-(Iii)-Network Coverage- (Iv) -No Hazard To Navigation--Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1024698,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17466',
      geometry: { type: 'Point', coordinates: [-9.16694577, 38.70056098] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lisboa (Lisbon)',
        code: 'PTLIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 38.70056,
        longitude: -9.16695,
        iso3: 'PRT',
        iso3_op: 'PRT',
        country: 'Portugal',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2267057,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17467',
      geometry: { type: 'Point', coordinates: [5.16687538, 43.47139316] },
      geometry_name: 'shape',
      properties: {
        portname: 'Berre',
        code: 'FRBEE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.47139,
        longitude: 5.16688,
        iso3: 'FRA',
        iso3_op: 'FRA',
        country: 'France',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3033241,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17468',
      geometry: { type: 'Point', coordinates: [13.38341883, 43.63759885] },
      geometry_name: 'shape',
      properties: {
        portname: 'Falconara',
        code: 'ITFAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.6376,
        longitude: 13.38342,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3177250,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17469',
      geometry: { type: 'Point', coordinates: [-71.95952699, 12.24900355] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Bolivar',
        code: 'COPBO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.249,
        longitude: -71.95953,
        iso3: 'COL',
        iso3_op: 'COL',
        country: 'Colombia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3688648,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17470',
      geometry: { type: 'Point', coordinates: [109.08483441, -7.68807209] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tjilatjap',
        code: 'IDTJI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.68807,
        longitude: 109.08483,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1646559,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17471',
      geometry: { type: 'Point', coordinates: [-76.82570903, 21.35852684] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manati',
        code: 'CUMNT',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.35853,
        longitude: -76.82571,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3543301,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17472',
      geometry: { type: 'Point', coordinates: [26.98509154, 36.94686201] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kalimnos Island',
        code: 'GRKMI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.94686,
        longitude: 26.98509,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 261507,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17473',
      geometry: { type: 'Point', coordinates: [54.00454423, 26.56645421] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kish Island',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.56645,
        longitude: 54.00454,
        iso3: 'IRN',
        iso3_op: 'IRN',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 126914,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17474',
      geometry: { type: 'Point', coordinates: [-57.89570092, -34.86214386] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Plata',
        code: 'ARLPG',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.86214,
        longitude: -57.8957,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3432043,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17475',
      geometry: { type: 'Point', coordinates: [-120.90649548, 35.44909541] },
      geometry_name: 'shape',
      properties: {
        portname: 'Estero Bay',
        code: 'USESB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.4491,
        longitude: -120.9065,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17476',
      geometry: { type: 'Point', coordinates: [6.04371192, 59.01909785] },
      geometry_name: 'shape',
      properties: {
        portname: 'Strand',
        code: 'NOSTD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 59.0191,
        longitude: 6.04371,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17477',
      geometry: { type: 'Point', coordinates: [9.50519481, 40.92508127] },
      geometry_name: 'shape',
      properties: {
        portname: 'Olbia (Terranova)',
        code: 'ITOLB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.92508,
        longitude: 9.50519,
        iso3: 'ITA',
        iso3_op: 'ITA',
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17478',
      geometry: { type: 'Point', coordinates: [88.18107048, 22.48903069] },
      geometry_name: 'shape',
      properties: {
        portname: 'Budge-Budge',
        code: 'INBUD',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.48903,
        longitude: 88.18107,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1275004,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17479',
      geometry: { type: 'Point', coordinates: [133.73612899, 34.50968499] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mizushima',
        code: 'JPMIZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.50968,
        longitude: 133.73613,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1854383,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17480',
      geometry: { type: 'Point', coordinates: [138.69710675, 35.13918149] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tagonoura',
        code: 'JPTGO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.13918,
        longitude: 138.69711,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1851717,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17481',
      geometry: { type: 'Point', coordinates: [144.28399737, 44.01351701] },
      geometry_name: 'shape',
      properties: {
        portname: 'Abashiri',
        code: 'JPABA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.01352,
        longitude: 144.284,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2130741,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17482',
      geometry: { type: 'Point', coordinates: [35.34433281, 33.50451133] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ez Zahrani',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.50451,
        longitude: 35.34433,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fuel Oil And Others',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 280155,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17483',
      geometry: { type: 'Point', coordinates: [161.78921388, -10.41080339] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pakera Point',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -10.4108,
        longitude: 161.78921,
        iso3: 'SLB',
        iso3_op: 'SLB',
        country: 'Solomon islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2178753,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17484',
      geometry: { type: 'Point', coordinates: [125.0037, -12.10726613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skua Venture',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -12.10727,
        longitude: 125.0037,
        iso3: null,
        iso3_op: 'PYF',
        country: null,
        lastcheckdate: null,
        remarks: 'Auskv,  Oil Terminal',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17485',
      geometry: { type: 'Point', coordinates: [-60.99736117, 10.140758] },
      geometry_name: 'shape',
      properties: {
        portname: 'Point Galeota',
        code: 'TTPTG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.14076,
        longitude: -60.99736,
        iso3: 'TTO',
        iso3_op: 'TTO',
        country: 'Trinidad and Tobago',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3574435,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17486',
      geometry: { type: 'Point', coordinates: [27.11354776, 40.61138426] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sarkoy',
        code: 'TRSRK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.61138,
        longitude: 27.11355,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 740000,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17487',
      geometry: { type: 'Point', coordinates: [108.23366931, 16.09528508] },
      geometry_name: 'shape',
      properties: {
        portname: 'Red Beach',
        code: 'VNRBE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.09529,
        longitude: 108.23367,
        iso3: 'VNM',
        iso3_op: 'VNM',
        country: 'Viet Nam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1583992,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17488',
      geometry: { type: 'Point', coordinates: [-68.86088557, 12.06960852] },
      geometry_name: 'shape',
      properties: {
        portname: 'Caracasbaii',
        code: 'ANCRB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.06961,
        longitude: -68.86089,
        iso3: 'ANT',
        iso3_op: 'ANT',
        country: 'Netherlands Antilles',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3513445,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17489',
      geometry: { type: 'Point', coordinates: [-64.93844633, -40.73554742] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Antonio Este',
        code: 'ARSAE',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -40.73555,
        longitude: -64.93845,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3837980,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17490',
      geometry: { type: 'Point', coordinates: [58.50812222, 23.63930788] },
      geometry_name: 'shape',
      properties: {
        portname: 'Min-Al-Fahal',
        code: 'OMMFH',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.63931,
        longitude: 58.50812,
        iso3: 'OMN',
        iso3_op: 'OMN',
        country: 'Oman',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 8309889,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17491',
      geometry: { type: 'Point', coordinates: [-64.5753368, 44.15172104] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Medway',
        code: 'CAPMW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.15172,
        longitude: -64.57534,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 6111955,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17492',
      geometry: { type: 'Point', coordinates: [-53.73581292, 47.63018349] },
      geometry_name: 'shape',
      properties: {
        portname: 'Come By Chance Bay',
        code: 'CACBC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.63018,
        longitude: -53.73581,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 5926314,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17493',
      geometry: { type: 'Point', coordinates: [-9.05915009, 52.63470268] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aughinish Island',
        code: 'IEAUG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 52.6347,
        longitude: -9.05915,
        iso3: 'IRL',
        iso3_op: 'IRL',
        country: 'Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3230593,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17494',
      geometry: { type: 'Point', coordinates: [124.74164801, 8.53392029] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tagoloan Mis. Or',
        code: 'PHTGO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.53392,
        longitude: 124.74165,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1684379,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17495',
      geometry: { type: 'Point', coordinates: [29.82794113, 40.7212376] },
      geometry_name: 'shape',
      properties: {
        portname: 'Golcuk',
        code: 'TRGOK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.72124,
        longitude: 29.82794,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 746666,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17496',
      geometry: { type: 'Point', coordinates: [103.62350901, 2.65541668] },
      geometry_name: 'shape',
      properties: {
        portname: 'Endau',
        code: 'MYEND',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.65542,
        longitude: 103.62351,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1734897,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17497',
      geometry: { type: 'Point', coordinates: [39.51598852, 68.05647432] },
      geometry_name: 'shape',
      properties: {
        portname: 'Iokanka',
        code: 'RUIOK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 68.05647,
        longitude: 39.51599,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 556268,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17498',
      geometry: { type: 'Point', coordinates: [-86.95511689, 20.50756494] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cozumel',
        code: 'MXCZM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.50756,
        longitude: -86.95512,
        iso3: 'MEX',
        iso3_op: 'MEX',
        country: 'Mexico',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3530103,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17499',
      geometry: { type: 'Point', coordinates: [146.82309497, -41.10773263] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Dalrymple',
        code: 'AUPTD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -41.10773,
        longitude: 146.82309,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17500',
      geometry: { type: 'Point', coordinates: [116.20503259, -20.8413548] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cape Preston',
        code: 'AUCPN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -20.84135,
        longitude: 116.20503,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17501',
      geometry: { type: 'Point', coordinates: [-70.14116844, 47.62254846] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pointe Au Pic',
        code: 'CAPPC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.62255,
        longitude: -70.14117,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17502',
      geometry: { type: 'Point', coordinates: [-90.69423982, 63.341853] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chester',
        code: 'CACST',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.34185,
        longitude: -90.69424,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17503',
      geometry: { type: 'Point', coordinates: [-123.84986231, 46.96498446] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gray',
        code: 'USGRG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 46.96498,
        longitude: -123.84986,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17504',
      geometry: { type: 'Point', coordinates: [-97.39010168, 27.83327067] },
      geometry_name: 'shape',
      properties: {
        portname: 'Corpus Christi',
        code: 'USCRP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 27.83327,
        longitude: -97.3901,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17505',
      geometry: { type: 'Point', coordinates: [10.6171073, 59.57347304] },
      geometry_name: 'shape',
      properties: {
        portname: 'Halvorshavn',
        code: 'NOHAN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.57347,
        longitude: 10.61711,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17506',
      geometry: { type: 'Point', coordinates: [-2.9104315, 35.16256282] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nador',
        code: 'MANDR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 35.16256,
        longitude: -2.91043,
        iso3: 'MAR',
        iso3_op: 'ESP',
        country: 'Morocco',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2541479,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17507',
      geometry: { type: 'Point', coordinates: [177.04068424, -12.50707151] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rotuma',
        code: 'FJRTA',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -12.50707,
        longitude: 177.04068,
        iso3: 'FJI',
        iso3_op: 'FJI',
        country: 'Fiji',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2197281,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17508',
      geometry: { type: 'Point', coordinates: [134.60044726, 34.77747721] },
      geometry_name: 'shape',
      properties: {
        portname: 'Himeji',
        code: 'JPABO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.77748,
        longitude: 134.60045,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1862627,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17509',
      geometry: { type: 'Point', coordinates: [135.13118781, 34.22805178] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hiki',
        code: 'JPHIK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.22805,
        longitude: 135.13119,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1926004,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17510',
      geometry: { type: 'Point', coordinates: [177.38854684, -17.68338799] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vuda',
        code: 'FJVUD',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.68339,
        longitude: 177.38855,
        iso3: null,
        iso3_op: 'FJI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2196647,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17511',
      geometry: { type: 'Point', coordinates: [102.15852585, 1.36036212] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sumenep',
        code: 'IDSUP',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.36036,
        longitude: 102.15853,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1625934,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17512',
      geometry: { type: 'Point', coordinates: [-76.59859745, 21.20112576] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Padre',
        code: 'CUPPA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.20113,
        longitude: -76.5986,
        iso3: 'CUB',
        iso3_op: 'CUB',
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3543299,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17513',
      geometry: { type: 'Point', coordinates: [127.95831325, 26.3391228] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kinwan',
        code: 'JPKIN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.33912,
        longitude: 127.95831,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1862933,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17514',
      geometry: { type: 'Point', coordinates: [137.08806251, 34.64758159] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tahara',
        code: 'JPATM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.64758,
        longitude: 137.08806,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1851259,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17515',
      geometry: { type: 'Point', coordinates: [136.97852133, 34.913288] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kinuura',
        code: 'JPKNU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.91329,
        longitude: 136.97852,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1856057,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17516',
      geometry: { type: 'Point', coordinates: [129.4015, 36.01113387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pohang',
        code: 'KRKPO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.01113,
        longitude: 129.4015,
        iso3: null,
        iso3_op: 'KOR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1839071,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17517',
      geometry: { type: 'Point', coordinates: [111.3378852, 2.15937832] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanjong Kling',
        code: 'MYTKL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.15938,
        longitude: 111.33789,
        iso3: 'MYS',
        iso3_op: 'MYS',
        country: 'Malaysia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1735825,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17518',
      geometry: { type: 'Point', coordinates: [143.1811, 59.35038569] },
      geometry_name: 'shape',
      properties: {
        portname: 'Okhotsk',
        code: 'RUOHO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 59.35039,
        longitude: 143.1811,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2122605,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17519',
      geometry: { type: 'Point', coordinates: [133.08258507, 42.73503902] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vostochniy',
        code: 'RUVYP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.73504,
        longitude: 133.08259,
        iso3: 'RUS',
        iso3_op: 'RUS',
        country: 'Russian Federation',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2019528,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17520',
      geometry: { type: 'Point', coordinates: [156.4095089, -6.6928531] },
      geometry_name: 'shape',
      properties: {
        portname: 'Choiseul Bay',
        code: 'SBCHY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.69285,
        longitude: 156.40951,
        iso3: 'SLB',
        iso3_op: 'SLB',
        country: 'Solomon islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2104192,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17521',
      geometry: { type: 'Point', coordinates: [-71.14488369, 21.42728986] },
      geometry_name: 'shape',
      properties: {
        portname: 'Grand Turk Island',
        code: 'TCGDT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.42729,
        longitude: -71.14488,
        iso3: 'TCA',
        iso3_op: 'TCA',
        country: 'Turks and Caicos islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3576994,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17522',
      geometry: { type: 'Point', coordinates: [-61.48726732, 10.39971288] },
      geometry_name: 'shape',
      properties: {
        portname: 'Point Lisas',
        code: 'TTPTS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.39971,
        longitude: -61.48727,
        iso3: 'TTO',
        iso3_op: 'TTO',
        country: 'Trinidad and Tobago',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3573738,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17523',
      geometry: { type: 'Point', coordinates: [35.9351, 36.88203387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ceyhan',
        code: 'TRCEY',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.88203,
        longitude: 35.9351,
        iso3: null,
        iso3_op: 'TUR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 318675,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17524',
      geometry: { type: 'Point', coordinates: [-62.72198551, 8.34102598] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Ordaz',
        code: 'VEPZO',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.34103,
        longitude: -62.72199,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3645528,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17525',
      geometry: { type: 'Point', coordinates: [114.46374302, 4.67375439] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lumut',
        code: 'BNLUM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.67375,
        longitude: 114.46374,
        iso3: 'BRN',
        iso3_op: 'BRN',
        country: 'Brunei Darussalam',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1820704,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17526',
      geometry: { type: 'Point', coordinates: [-71.31365659, -29.45209309] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cruz Grande',
        code: 'CLCGR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -29.45209,
        longitude: -71.31366,
        iso3: 'CHL',
        iso3_op: 'CHL',
        country: 'Chile',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3884373,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17527',
      geometry: { type: 'Point', coordinates: [-71.06699307, 9.77922086] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Lorenzo / Maracaibo L',
        code: 'VESLV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.77922,
        longitude: -71.06699,
        iso3: null,
        iso3_op: 'VEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3628027,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17528',
      geometry: { type: 'Point', coordinates: [9.96216902, 53.48015024] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hamburg-Harburg',
        code: 'DEHBU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 53.48015,
        longitude: 9.96217,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2911298,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17529',
      geometry: { type: 'Point', coordinates: [-117.07289018, 32.36854872] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rosarito Terminal',
        code: 'MXRST',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.36855,
        longitude: -117.07289,
        iso3: null,
        iso3_op: 'MEX',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3988392,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17530',
      geometry: { type: 'Point', coordinates: [48.15592505, 29.03950005] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shuaiba',
        code: 'KWMIB',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.0395,
        longitude: 48.15593,
        iso3: 'KWT',
        iso3_op: 'KWT',
        country: 'Kuwait',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 285716,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17531',
      geometry: { type: 'Point', coordinates: [115.20943029, -8.74328966] },
      geometry_name: 'shape',
      properties: {
        portname: 'Benoa',
        code: 'IDBOA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.74329,
        longitude: 115.20943,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1649125,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17532',
      geometry: { type: 'Point', coordinates: [29.79894961, 31.1325957] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Dekheila',
        code: 'EGEDK',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.1326,
        longitude: 29.79895,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 361819,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17533',
      geometry: { type: 'Point', coordinates: [-1.41982172, 55.01642831] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tyne',
        code: 'GBTYN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.01643,
        longitude: -1.41982,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2635269,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17534',
      geometry: { type: 'Point', coordinates: [6.1527418, 62.47298997] },
      geometry_name: 'shape',
      properties: {
        portname: 'Alesund',
        code: 'NOAES',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 62.47299,
        longitude: 6.15274,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3163392,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17535',
      geometry: { type: 'Point', coordinates: [130.26020523, 31.71546488] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ichikikushikino',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 31.71546,
        longitude: 130.26021,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1858041,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17537',
      geometry: { type: 'Point', coordinates: [23.0631024, 2.26526128] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bagata',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.26526,
        longitude: 23.0631,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 218567,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17538',
      geometry: { type: 'Point', coordinates: [124.4859552, 12.53327516] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Jose Carangian',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.53328,
        longitude: 124.48596,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1689507,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17539',
      geometry: { type: 'Point', coordinates: [12.11669753, 54.1507791] },
      geometry_name: 'shape',
      properties: {
        portname: 'Warnemunde',
        code: 'DEWAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.15078,
        longitude: 12.1167,
        iso3: 'DEU',
        iso3_op: 'DEU',
        country: 'Germany',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17540',
      geometry: { type: 'Point', coordinates: [-4.37569906, 54.31824453] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ramsey',
        code: 'GBRSY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 54.31824,
        longitude: -4.3757,
        iso3: null,
        iso3_op: 'IMY',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17541',
      geometry: { type: 'Point', coordinates: [34.20444003, 26.25571845] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hamrawein',
        code: 'EGHAM',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.25572,
        longitude: 34.20444,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 360531,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17542',
      geometry: { type: 'Point', coordinates: [32.2802924, 30.58835485] },
      geometry_name: 'shape',
      properties: {
        portname: 'Suez Canal',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.58835,
        longitude: 32.28029,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 361055,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17543',
      geometry: { type: 'Point', coordinates: [-87.44342383, 15.7906725] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santo Tomas De Castilla',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 15.79067,
        longitude: -87.44342,
        iso3: null,
        iso3_op: 'HND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17544',
      geometry: { type: 'Point', coordinates: [72.83811704, 18.92470278] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mumbai',
        code: null,
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.9247,
        longitude: 72.83812,
        iso3: null,
        iso3_op: 'IND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1275339,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17545',
      geometry: { type: 'Point', coordinates: [54.55135128, 25.89589173] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sirri Island',
        code: 'IRSXI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.89589,
        longitude: 54.55135,
        iso3: null,
        iso3_op: 'IRN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17546',
      geometry: { type: 'Point', coordinates: [134.33768749, 34.25746473] },
      geometry_name: 'shape',
      properties: {
        portname: 'Higashikagawa',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 34.25746,
        longitude: 134.33769,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 7391347,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17547',
      geometry: { type: 'Point', coordinates: [82.65223, 49.94201787] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Of Oskemen',
        code: null,
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.94202,
        longitude: 82.65223,
        iso3: 'KAZ',
        iso3_op: 'KAZ',
        country: 'Kazakhstan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1520316,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17548',
      geometry: { type: 'Point', coordinates: [47.93437041, 29.34865022] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shuwaikh',
        code: 'KWSWK',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.34865,
        longitude: 47.93437,
        iso3: 'KWT',
        iso3_op: 'KWT',
        country: 'Kuwait',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 285787,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17549',
      geometry: { type: 'Point', coordinates: [35.4689, 33.89853387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ain El Mraisse _El Manara',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.89853,
        longitude: 35.4689,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 276781,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17550',
      geometry: { type: 'Point', coordinates: [35.49545555, 33.90227502] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ain El Mraisse _El Jisr',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.90228,
        longitude: 35.49546,
        iso3: 'LBN',
        iso3_op: 'LBN',
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: 'Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 276781,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17551',
      geometry: { type: 'Point', coordinates: [117.64343617, 0.53569702] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sangatta',
        code: 'IDTBA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.5357,
        longitude: 117.64344,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 8423783,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17552',
      geometry: { type: 'Point', coordinates: [-80.8108803, -2.15858318] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salinas',
        code: 'ECSNC',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.15858,
        longitude: -80.81088,
        iso3: 'ECU',
        iso3_op: 'ECU',
        country: 'Ecuador',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3652100,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17553',
      geometry: { type: 'Point', coordinates: [-71.74349109, 19.70629043] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manzanillo',
        code: 'DOMAN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 19.70629,
        longitude: -71.74349,
        iso3: null,
        iso3_op: 'HTI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3495024,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17554',
      geometry: { type: 'Point', coordinates: [35.19096216, -17.63092683] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jardim',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -17.63093,
        longitude: 35.19096,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks:
          'Jardim: (I) -No Vessel Restriction- (Ii) -No Truck Access- (Iii) -Network Coverage- (Iv) -No Hazard To Navigation--Operational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17555',
      geometry: { type: 'Point', coordinates: [-79.81966585, 9.39204872] },
      geometry_name: 'shape',
      properties: {
        portname: 'Payardi',
        code: 'PAPAY',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.39205,
        longitude: -79.81967,
        iso3: 'PAN',
        iso3_op: 'PAN',
        country: 'Panama',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3712076,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17556',
      geometry: { type: 'Point', coordinates: [157.73026016, -8.49934599] },
      geometry_name: 'shape',
      properties: {
        portname: 'Viru Harbour',
        code: 'SBVIU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.49935,
        longitude: 157.73026,
        iso3: 'SLB',
        iso3_op: 'SLB',
        country: 'Solomon islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2102605,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17557',
      geometry: { type: 'Point', coordinates: [156.60523364, -7.58005739] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malloco Bay',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.58006,
        longitude: 156.60523,
        iso3: 'SLB',
        iso3_op: 'SLB',
        country: 'Solomon islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2106857,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17558',
      geometry: { type: 'Point', coordinates: [32.23356709, 41.6867053] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bartin',
        code: 'TRBTN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.68671,
        longitude: 32.23357,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 751057,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17559',
      geometry: { type: 'Point', coordinates: [-68.40857471, -50.12180279] },
      geometry_name: 'shape',
      properties: {
        portname: 'Punta Quilla',
        code: 'ARPQU',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -50.1218,
        longitude: -68.40857,
        iso3: 'ARG',
        iso3_op: 'ARG',
        country: 'Argentina',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3860466,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17560',
      geometry: { type: 'Point', coordinates: [32.7122177, 29.58510246] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Sudr',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 29.5851,
        longitude: 32.71222,
        iso3: 'EGY',
        iso3_op: 'EGY',
        country: 'Egypt',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 449808,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17561',
      geometry: { type: 'Point', coordinates: [123.80561901, 11.16465293] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santa Fe',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 11.16465,
        longitude: 123.80562,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: '2013-12-10T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1688171,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17562',
      geometry: { type: 'Point', coordinates: [123.94480992, 11.09309483] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hagnaya',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 11.09309,
        longitude: 123.94481,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: '2013-12-10T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1711991,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17563',
      geometry: { type: 'Point', coordinates: [123.99659932, 11.06977389] },
      geometry_name: 'shape',
      properties: {
        portname: 'Polambato',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 11.06977,
        longitude: 123.9966,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: '2013-12-10T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1693147,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17564',
      geometry: { type: 'Point', coordinates: [124.39178644, 11.56037225] },
      geometry_name: 'shape',
      properties: {
        portname: 'Naval',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 11.56037,
        longitude: 124.39179,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: '2013-12-10T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1697879,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17565',
      geometry: { type: 'Point', coordinates: [123.8738916, 12.66473991] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bulan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Planned',
        locprecision: 'accurate',
        latitude: 12.66474,
        longitude: 123.87389,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1722985,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17566',
      geometry: { type: 'Point', coordinates: [67.30258942, 37.19915926] },
      geometry_name: 'shape',
      properties: {
        portname: 'Termiz',
        code: null,
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.19916,
        longitude: 67.30259,
        iso3: 'UZB',
        iso3_op: 'AFG',
        country: 'Uzbekistan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1215957,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17567',
      geometry: { type: 'Point', coordinates: [12.4035, -6.95206613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Palanca Terminal',
        code: 'AOPAT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -6.95207,
        longitude: 12.4035,
        iso3: null,
        iso3_op: 'AGO',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17568',
      geometry: { type: 'Point', coordinates: [13.83532488, -11.18834854] },
      geometry_name: 'shape',
      properties: {
        portname: 'Novo Redondo',
        code: 'AONOE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -11.18835,
        longitude: 13.83532,
        iso3: null,
        iso3_op: 'AGO',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3346015,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17569',
      geometry: { type: 'Point', coordinates: [-68.9391179, 12.10711016] },
      geometry_name: 'shape',
      properties: {
        portname: 'Willemstad',
        code: 'ANWIL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.10711,
        longitude: -68.93912,
        iso3: 'ANT',
        iso3_op: 'ANT',
        country: 'Netherlands Antilles',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3513090,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17570',
      geometry: { type: 'Point', coordinates: [-63.04171423, 18.02083757] },
      geometry_name: 'shape',
      properties: {
        portname: 'Philipsburg',
        code: 'ANPHI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.02084,
        longitude: -63.04171,
        iso3: 'ANT',
        iso3_op: 'ANT',
        country: 'Netherlands Antilles',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3513392,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17571',
      geometry: { type: 'Point', coordinates: [53.06484807, 24.8982429] },
      geometry_name: 'shape',
      properties: {
        portname: 'Zurku Island',
        code: 'AEZUR',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.89824,
        longitude: 53.06485,
        iso3: 'ARE',
        iso3_op: 'ARE',
        country: 'United Arab Emirates',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17572',
      geometry: { type: 'Point', coordinates: [52.56419154, 24.77698242] },
      geometry_name: 'shape',
      properties: {
        portname: 'Arzanah Island',
        code: 'AEARZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.77698,
        longitude: 52.56419,
        iso3: null,
        iso3_op: 'ARE',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17573',
      geometry: { type: 'Point', coordinates: [52.88001706, 25.14178624] },
      geometry_name: 'shape',
      properties: {
        portname: 'Das Island',
        code: 'AEDAS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.14179,
        longitude: 52.88002,
        iso3: 'ARE',
        iso3_op: 'ARE',
        country: 'United Arab Emirates',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17574',
      geometry: { type: 'Point', coordinates: [53.08522771, 24.87970188] },
      geometry_name: 'shape',
      properties: {
        portname: 'Abu Al Bukhoosh',
        code: 'AEABU',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 24.8797,
        longitude: 53.08523,
        iso3: null,
        iso3_op: 'ARE',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17575',
      geometry: { type: 'Point', coordinates: [52.41047479, 25.66841862] },
      geometry_name: 'shape',
      properties: {
        portname: 'Halul',
        code: 'QAHAL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.66842,
        longitude: 52.41047,
        iso3: null,
        iso3_op: 'QAT',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17576',
      geometry: { type: 'Point', coordinates: [54.23508251, 25.21980989] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fateh Terminal',
        code: 'AEFAT',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.21981,
        longitude: 54.23508,
        iso3: 'ARE',
        iso3_op: 'ARE',
        country: 'United Arab Emirates',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17577',
      geometry: { type: 'Point', coordinates: [55.0113241, 25.87547731] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mubarek Terminal',
        code: 'AEMUB',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 25.87548,
        longitude: 55.01132,
        iso3: 'IRN',
        iso3_op: 'ARE',
        country: 'Iran  (Islamic Republic of)',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17578',
      geometry: { type: 'Point', coordinates: [55.04795164, 24.99389206] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jebel Ali',
        code: 'AEJEA',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 24.99389,
        longitude: 55.04795,
        iso3: 'ARE',
        iso3_op: 'ARE',
        country: 'United Arab Emirates',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 292223,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17579',
      geometry: { type: 'Point', coordinates: [146.97601642, -43.15859898] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Huon',
        code: 'AUPHU',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -43.1586,
        longitude: 146.97602,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17580',
      geometry: { type: 'Point', coordinates: [137.7583772, -35.02140116] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Giles',
        code: 'AUPGI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -35.0214,
        longitude: 137.75838,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17581',
      geometry: { type: 'Point', coordinates: [115.16699005, -34.35462514] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cape Leeuwin',
        code: 'AUCLN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -34.35463,
        longitude: 115.16699,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17582',
      geometry: { type: 'Point', coordinates: [137.76427985, -32.99192481] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Bonython',
        code: 'AUPBY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.99192,
        longitude: 137.76428,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17583',
      geometry: { type: 'Point', coordinates: [113.41287531, -26.13013394] },
      geometry_name: 'shape',
      properties: {
        portname: 'Useless Loop',
        code: 'AUUSL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -26.13013,
        longitude: 113.41288,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17584',
      geometry: { type: 'Point', coordinates: [114.13671457, -21.95632538] },
      geometry_name: 'shape',
      properties: {
        portname: 'Exmouth',
        code: 'AUPEX',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -21.95633,
        longitude: 114.13671,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17585',
      geometry: { type: 'Point', coordinates: [-77.41207609, 18.46072168] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Rhoades',
        code: 'JMPRH',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.46072,
        longitude: -77.41208,
        iso3: 'JAM',
        iso3_op: 'JAM',
        country: 'Jamaica',
        lastcheckdate: '2019-05-22T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3488726,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17586',
      geometry: { type: 'Point', coordinates: [115.02431648, -21.45933386] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saladin Marine Terminal',
        code: 'AUSMT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -21.45933,
        longitude: 115.02432,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17587',
      geometry: { type: 'Point', coordinates: [115.47305272, -20.72734294] },
      geometry_name: 'shape',
      properties: {
        portname: 'Varanus Island',
        code: 'AUVAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -20.72734,
        longitude: 115.47305,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17588',
      geometry: { type: 'Point', coordinates: [148.07556641, -19.88986535] },
      geometry_name: 'shape',
      properties: {
        portname: 'Abbot Point',
        code: 'AUABP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -19.88987,
        longitude: 148.07557,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17589',
      geometry: { type: 'Point', coordinates: [123.74960833, -16.14731623] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yampi',
        code: 'AUYAM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.14732,
        longitude: 123.74961,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17590',
      geometry: { type: 'Point', coordinates: [145.35109146, -14.98212084] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cape Flattery',
        code: 'AUCQP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -14.98212,
        longitude: 145.35109,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17591',
      geometry: { type: 'Point', coordinates: [10.9267, -3.88156613] },
      geometry_name: 'shape',
      properties: {
        portname: "M'Bya Terminal",
        code: 'GAMBY',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -3.88157,
        longitude: 10.9267,
        iso3: null,
        iso3_op: 'GAB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17592',
      geometry: { type: 'Point', coordinates: [35.3675, 33.56453387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saida',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.56453,
        longitude: 35.3675,
        iso3: null,
        iso3_op: 'LBN',
        country: null,
        lastcheckdate: null,
        remarks: 'Commercial, Fishing And Recreational',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 268064,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17593',
      geometry: { type: 'Point', coordinates: [44.76915245, 1.71300387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Merca',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 1.713,
        longitude: 44.76915,
        iso3: 'SOM',
        iso3_op: 'SOM',
        country: 'Somalia',
        lastcheckdate: '2013-11-07T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 54225,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17594',
      geometry: { type: 'Point', coordinates: [125.72299493, 11.03073816] },
      geometry_name: 'shape',
      properties: {
        portname: 'Guiuan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.03074,
        longitude: 125.72299,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1712206,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17595',
      geometry: { type: 'Point', coordinates: [142.21965428, -10.58509855] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thursday Island',
        code: 'AUTIS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -10.5851,
        longitude: 142.21965,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17596',
      geometry: { type: 'Point', coordinates: [138.47131023, -35.11040467] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Stanvac',
        code: 'AUPST',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -35.1104,
        longitude: 138.47131,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17597',
      geometry: { type: 'Point', coordinates: [151.7655182, -32.91905141] },
      geometry_name: 'shape',
      properties: {
        portname: 'Newcastle',
        code: 'AUNTL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -32.91905,
        longitude: 151.76552,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17598',
      geometry: { type: 'Point', coordinates: [153.01741065, -30.89379823] },
      geometry_name: 'shape',
      properties: {
        portname: 'South West Rocks',
        code: 'AUSWR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -30.8938,
        longitude: 153.01741,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17599',
      geometry: { type: 'Point', coordinates: [117.14091638, -20.62780952] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Walcott',
        code: 'AUPWL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -20.62781,
        longitude: 117.14092,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17600',
      geometry: { type: 'Point', coordinates: [147.91873434, -42.5100205] },
      geometry_name: 'shape',
      properties: {
        portname: 'Spring Bay',
        code: 'AUSPB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -42.51002,
        longitude: 147.91873,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17601',
      geometry: { type: 'Point', coordinates: [153.34920721, -29.4077618] },
      geometry_name: 'shape',
      properties: {
        portname: 'Clarence River',
        code: 'AUCLR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -29.40776,
        longitude: 153.34921,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17602',
      geometry: { type: 'Point', coordinates: [123.60922213, -17.29158681] },
      geometry_name: 'shape',
      properties: {
        portname: 'Derby',
        code: 'AUDRB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.29159,
        longitude: 123.60922,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17603',
      geometry: { type: 'Point', coordinates: [136.67918536, -12.19841264] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gove',
        code: 'AUGOV',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.19841,
        longitude: 136.67919,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17604',
      geometry: { type: 'Point', coordinates: [115.13003001, -21.6459552] },
      geometry_name: 'shape',
      properties: {
        portname: 'Griffin Venture',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -21.64596,
        longitude: 115.13003,
        iso3: 'AUS',
        iso3_op: 'AUS',
        country: 'Australia',
        lastcheckdate: null,
        remarks: 'Augrv, Oil Terminal',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17605',
      geometry: { type: 'Point', coordinates: [-52.97776457, 47.51642853] },
      geometry_name: 'shape',
      properties: {
        portname: 'Long Pond',
        code: 'CALOP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.51643,
        longitude: -52.97776,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17606',
      geometry: { type: 'Point', coordinates: [-68.40778657, 58.09832289] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fort Chimo',
        code: 'CAFCM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 58.09832,
        longitude: -68.40779,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17607',
      geometry: { type: 'Point', coordinates: [-74.69519562, 62.14541421] },
      geometry_name: 'shape',
      properties: {
        portname: 'Deception Bay',
        code: 'CADEB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 62.14541,
        longitude: -74.6952,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17608',
      geometry: { type: 'Point', coordinates: [-64.70940285, 47.74219192] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shippegan',
        code: 'CASHP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 47.74219,
        longitude: -64.7094,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17609',
      geometry: { type: 'Point', coordinates: [-123.85770065, 49.13897903] },
      geometry_name: 'shape',
      properties: {
        portname: 'Harmac',
        code: 'CAHMC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.13898,
        longitude: -123.8577,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17610',
      geometry: { type: 'Point', coordinates: [-123.25458052, 49.66648883] },
      geometry_name: 'shape',
      properties: {
        portname: 'Woodfibre',
        code: 'CAWOO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 49.66649,
        longitude: -123.25458,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17611',
      geometry: { type: 'Point', coordinates: [-125.28594301, 50.07220129] },
      geometry_name: 'shape',
      properties: {
        portname: 'Duncan Bay',
        code: 'CADCN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.0722,
        longitude: -125.28594,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17612',
      geometry: { type: 'Point', coordinates: [-130.43402536, 54.55605377] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Simpson',
        code: 'CAPSI',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 54.55605,
        longitude: -130.43403,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17613',
      geometry: { type: 'Point', coordinates: [-82.5302005, 42.61884786] },
      geometry_name: 'shape',
      properties: {
        portname: 'Algona',
        code: 'USUBJ',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 42.61885,
        longitude: -82.5302,
        iso3: 'USA',
        iso3_op: 'CAN',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17614',
      geometry: { type: 'Point', coordinates: [-130.01264075, 55.90471039] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hyde',
        code: 'USHYD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.90471,
        longitude: -130.01264,
        iso3: 'USA',
        iso3_op: 'CAN',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17615',
      geometry: { type: 'Point', coordinates: [-84.54749192, 73.0676056] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nanisivik',
        code: 'CANVK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 73.06761,
        longitude: -84.54749,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17616',
      geometry: { type: 'Point', coordinates: [-70.8743791, 48.33369363] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Alfred',
        code: 'CAPAF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 48.33369,
        longitude: -70.87438,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17617',
      geometry: { type: 'Point', coordinates: [-66.48549354, 50.15559989] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pointe Noire',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 50.1556,
        longitude: -66.48549,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17618',
      geometry: { type: 'Point', coordinates: [-79.61670344, 43.48953582] },
      geometry_name: 'shape',
      properties: {
        portname: 'Clarkson',
        code: 'CACSN',
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 43.48954,
        longitude: -79.6167,
        iso3: 'CAN',
        iso3_op: 'CAN',
        country: 'Canada',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17619',
      geometry: { type: 'Point', coordinates: [-93.92672039, 29.8672873] },
      geometry_name: 'shape',
      properties: {
        portname: 'Parlier',
        code: 'USPAR',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 29.86729,
        longitude: -93.92672,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17620',
      geometry: { type: 'Point', coordinates: [-74.00132505, 40.69122198] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brooklyn',
        code: 'USBOY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 40.69122,
        longitude: -74.00133,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17621',
      geometry: { type: 'Point', coordinates: [-77.96152068, 33.84557556] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cape Fear',
        code: 'USCFN',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.84558,
        longitude: -77.96152,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17622',
      geometry: { type: 'Point', coordinates: [-76.32705023, 36.95048131] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sewells Point',
        code: 'USSWP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.95048,
        longitude: -76.32705,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17623',
      geometry: { type: 'Point', coordinates: [-76.01707099, 37.26568647] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cape Charles',
        code: 'USCCJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.26569,
        longitude: -76.01707,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17624',
      geometry: { type: 'Point', coordinates: [-76.33032298, 37.01606533] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hampton-Newport News-Williamsb.',
        code: 'USPHF',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 37.01607,
        longitude: -76.33032,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17625',
      geometry: { type: 'Point', coordinates: [-118.55755529, 33.00485721] },
      geometry_name: 'shape',
      properties: {
        portname: 'El Capitan',
        code: 'USELC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 33.00486,
        longitude: -118.55756,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17626',
      geometry: { type: 'Point', coordinates: [-76.6094791, 36.05651194] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eden',
        code: 'USEDB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.05651,
        longitude: -76.60948,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17627',
      geometry: { type: 'Point', coordinates: [-123.71322055, 38.9733431] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manchester',
        code: 'USMNY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 38.97334,
        longitude: -123.71322,
        iso3: null,
        iso3_op: 'USA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17628',
      geometry: { type: 'Point', coordinates: [-95.10567697, 29.74736476] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jacintoport',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 29.74736,
        longitude: -95.10568,
        iso3: 'USA',
        iso3_op: 'USA',
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17629',
      geometry: { type: 'Point', coordinates: [5.51725769, 62.03979017] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vanylven',
        code: 'NOVAY',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 62.03979,
        longitude: 5.51726,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17630',
      geometry: { type: 'Point', coordinates: [9.10889526, 63.52092557] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hitra',
        code: 'NOHIT',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 63.52093,
        longitude: 9.1089,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17631',
      geometry: { type: 'Point', coordinates: [10.89367465, 63.47466956] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stjordal',
        code: 'NOSTJ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 63.47467,
        longitude: 10.89367,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17632',
      geometry: { type: 'Point', coordinates: [5.02902377, 61.60025487] },
      geometry_name: 'shape',
      properties: {
        portname: 'Flora',
        code: 'NOFRO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 61.60025,
        longitude: 5.02902,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17633',
      geometry: { type: 'Point', coordinates: [6.94889889, 62.30843737] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fuglafirdi (Fuglefjord)',
        code: 'FOFUG',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 62.30844,
        longitude: 6.9489,
        iso3: 'NOR',
        iso3_op: 'NOR',
        country: 'Norway',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17634',
      geometry: { type: 'Point', coordinates: [88.05092042, 22.02445726] },
      geometry_name: 'shape',
      properties: {
        portname: 'Haldia',
        code: 'INHAL',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.02446,
        longitude: 88.05092,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1344377,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17635',
      geometry: { type: 'Point', coordinates: [-2.35387792, 53.47482222] },
      geometry_name: 'shape',
      properties: {
        portname: 'Barton',
        code: 'GBBNM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 53.47482,
        longitude: -2.35388,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17636',
      geometry: { type: 'Point', coordinates: [-6.28870046, 55.75784185] },
      geometry_name: 'shape',
      properties: {
        portname: 'Islay',
        code: 'GBIYP',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 55.75784,
        longitude: -6.2887,
        iso3: 'GBR',
        iso3_op: 'GBR',
        country: 'U.K. of Great Britain and Northern Ireland',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17637',
      geometry: { type: 'Point', coordinates: [174.78516282, -36.93231352] },
      geometry_name: 'shape',
      properties: {
        portname: 'Onehunga',
        code: 'NZONE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -36.93231,
        longitude: 174.78516,
        iso3: 'NZL',
        iso3_op: 'NZL',
        country: 'New Zealand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17638',
      geometry: { type: 'Point', coordinates: [23.46469754, 37.35055496] },
      geometry_name: 'shape',
      properties: {
        portname: 'Idhra (Hydra)',
        code: 'GRHYD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 37.35055,
        longitude: 23.4647,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17639',
      geometry: { type: 'Point', coordinates: [22.81133776, 38.89102688] },
      geometry_name: 'shape',
      properties: {
        portname: 'Achladi',
        code: 'GRACL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: 38.89103,
        longitude: 22.81134,
        iso3: 'GRC',
        iso3_op: 'GRC',
        country: 'Greece',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17640',
      geometry: { type: 'Point', coordinates: [140.62552924, 36.49796839] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hitachi',
        code: 'JPHTC',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 36.49797,
        longitude: 140.62553,
        iso3: 'JPN',
        iso3_op: 'JPN',
        country: 'Japan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 6822096,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17641',
      geometry: { type: 'Point', coordinates: [100.40645322, 20.27397609] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chiang Khong',
        code: null,
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 20.27398,
        longitude: 100.40645,
        iso3: 'THA',
        iso3_op: 'LAO',
        country: 'Thailand',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1663526,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17642',
      geometry: { type: 'Point', coordinates: [20.3146504, 63.78749731] },
      geometry_name: 'shape',
      properties: {
        portname: 'Stormskaer',
        code: 'STSOK',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 63.7875,
        longitude: 20.31465,
        iso3: 'SWE',
        iso3_op: 'SWE',
        country: 'Sweden',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17643',
      geometry: { type: 'Point', coordinates: [50.71173494, 26.19012341] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mina Salman',
        code: 'BHMIN',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 26.19012,
        longitude: 50.71173,
        iso3: null,
        iso3_op: 'BHR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 290332,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17644',
      geometry: { type: 'Point', coordinates: [-66.1663, 44.19233387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Meteghan',
        code: 'CAMET',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 44.19233,
        longitude: -66.1663,
        iso3: null,
        iso3_op: 'CAN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 6072486,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17645',
      geometry: { type: 'Point', coordinates: [8.56288275, 53.56565582] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bremerhaven',
        code: null,
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 53.56566,
        longitude: 8.56288,
        iso3: 'DEU',
        iso3_op: null,
        country: 'Germany',
        lastcheckdate: '2015-02-11T23:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'OSM, Bing',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2944368,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17646',
      geometry: { type: 'Point', coordinates: [-15.42720659, 28.14147463] },
      geometry_name: 'shape',
      properties: {
        portname: 'Las Palmas',
        code: null,
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 28.14147,
        longitude: -15.42721,
        iso3: 'ESP',
        iso3_op: null,
        country: 'Spain',
        lastcheckdate: '2015-02-11T23:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'OSM',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2515270,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17647',
      geometry: { type: 'Point', coordinates: [-5.4450171, 36.12830488] },
      geometry_name: 'shape',
      properties: {
        portname: 'Algeciras',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 36.1283,
        longitude: -5.44502,
        iso3: 'ESP',
        iso3_op: null,
        country: 'Spain',
        lastcheckdate: '2015-03-16T23:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'OSM',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2522013,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17648',
      geometry: { type: 'Point', coordinates: [76.26729943, 9.96772084] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cochin',
        code: 'INCOK',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 9.96772,
        longitude: 76.2673,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1273874,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17649',
      geometry: { type: 'Point', coordinates: [70.44506922, 22.96025808] },
      geometry_name: 'shape',
      properties: {
        portname: 'Navalakhi',
        code: 'INNAV',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.96026,
        longitude: 70.44507,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1261655,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17650',
      geometry: { type: 'Point', coordinates: [69.3590516, 22.83216644] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mandvi',
        code: 'INMDV',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.83217,
        longitude: 69.35905,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1263824,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17651',
      geometry: { type: 'Point', coordinates: [69.59200605, 21.63354559] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porbandar',
        code: 'INPBD',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.63355,
        longitude: 69.59201,
        iso3: null,
        iso3_op: 'IND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1259395,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17652',
      geometry: { type: 'Point', coordinates: [73.80130502, 15.40865393] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mormugao',
        code: 'INMRM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.40865,
        longitude: 73.80131,
        iso3: null,
        iso3_op: 'IND',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1263494,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17653',
      geometry: { type: 'Point', coordinates: [74.83260567, 12.85831586] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mangalore',
        code: 'INIXE',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 12.85832,
        longitude: 74.83261,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1263780,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17654',
      geometry: { type: 'Point', coordinates: [82.27790057, 16.96854698] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kakinada',
        code: 'INKAK',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 16.96855,
        longitude: 82.2779,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1268561,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17655',
      geometry: { type: 'Point', coordinates: [70.22264221, 23.0025566] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kandla',
        code: 'INIXY',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 23.00256,
        longitude: 70.22264,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1268124,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17656',
      geometry: { type: 'Point', coordinates: [53.99858388, 16.93873774] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salalah',
        code: 'OMRAY',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: 16,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 16.93874,
        longitude: 53.99858,
        iso3: 'OMN',
        iso3_op: 'OMN,YEM',
        country: 'Oman',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 286750,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17657',
      geometry: { type: 'Point', coordinates: [42.74523641, 13.00664439] },
      geometry_name: 'shape',
      properties: {
        portname: 'Assab',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.00664,
        longitude: 42.74524,
        iso3: null,
        iso3_op: 'ERI,YEM',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 343386,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17658',
      geometry: { type: 'Point', coordinates: [88.34608772, 22.57597067] },
      geometry_name: 'shape',
      properties: {
        portname: 'Calcutta',
        code: 'INCCU',
        prttype: 'River',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 22.57597,
        longitude: 88.34609,
        iso3: 'IND',
        iso3_op: 'IND',
        country: 'India',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1275004,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17659',
      geometry: { type: 'Point', coordinates: [-179.99113798, -16.78772713] },
      geometry_name: 'shape',
      properties: {
        portname: 'Waiyavu',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'approximate',
        latitude: -16.78773,
        longitude: -179.99114,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 4035783,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17661',
      geometry: { type: 'Point', coordinates: [37.22834715, 19.60101454] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Sudan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 19.60101,
        longitude: 37.22835,
        iso3: 'SDN',
        iso3_op: 'SDN, ETH',
        country: 'Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 377039,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17662',
      geometry: { type: 'Point', coordinates: [179.4239803, -17.35327313] },
      geometry_name: 'shape',
      properties: {
        portname: 'Koro Jetty',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -17.35327,
        longitude: 179.42398,
        iso3: 'FJI',
        iso3_op: null,
        country: 'Fiji',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17663',
      geometry: { type: 'Point', coordinates: [178.68771965, -16.99454724] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nabouwalu Jetty',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -16.99455,
        longitude: 178.68772,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2202352,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17664',
      geometry: { type: 'Point', coordinates: [93.93617309, 19.56698142] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tat Taung',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 19.56698,
        longitude: 93.93617,
        iso3: 'MMR',
        iso3_op: null,
        country: 'Myanmar',
        lastcheckdate: '2015-08-05T22:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1326781,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17665',
      geometry: { type: 'Point', coordinates: [169.26580167, -19.53235788] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lenakel',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -19.53236,
        longitude: 169.2658,
        iso3: 'VUT',
        iso3_op: null,
        country: 'Vanuatu',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2136499,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17666',
      geometry: { type: 'Point', coordinates: [94.2187844, 18.83695028] },
      geometry_name: 'shape',
      properties: {
        portname: 'Taungup',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 18.83695,
        longitude: 94.21878,
        iso3: 'MMR',
        iso3_op: null,
        country: 'Myanmar',
        lastcheckdate: '2015-08-04T22:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1293696,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17667',
      geometry: { type: 'Point', coordinates: [93.54899389, 19.43481388] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kyaukpyu',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 19.43481,
        longitude: 93.54899,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: '2015-08-04T22:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1316752,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17668',
      geometry: { type: 'Point', coordinates: [94.56894299, 17.59152881] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gwa',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 17.59153,
        longitude: 94.56894,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: '2015-08-05T22:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1325814,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17669',
      geometry: { type: 'Point', coordinates: [57.71182946, 19.65834987] },
      geometry_name: 'shape',
      properties: {
        portname: 'Duqm',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 19.65835,
        longitude: 57.71183,
        iso3: 'OMN',
        iso3_op: null,
        country: 'Oman',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 288471,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17670',
      geometry: { type: 'Point', coordinates: [-79.64434058, 0.98460444] },
      geometry_name: 'shape',
      properties: {
        portname: 'Esmeraldas',
        code: 'ECESM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 0.9846,
        longitude: -79.64434,
        iso3: null,
        iso3_op: 'ECU',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3657990,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17671',
      geometry: { type: 'Point', coordinates: [-83.16865926, 22.9704793] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bahia Honda',
        code: 'CUBHO',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 22.97048,
        longitude: -83.16866,
        iso3: 'CUB',
        iso3_op: null,
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3567995,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17672',
      geometry: { type: 'Point', coordinates: [-82.92982622, 22.98337801] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cabanas',
        code: 'CUCAB',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 22.98338,
        longitude: -82.92983,
        iso3: 'CUB',
        iso3_op: null,
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3566573,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17673',
      geometry: { type: 'Point', coordinates: [42.6888395, 15.70229017] },
      geometry_name: 'shape',
      properties: {
        portname: 'Loheiya',
        code: 'YELOH',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.70229,
        longitude: 42.68884,
        iso3: 'YEM',
        iso3_op: '',
        country: 'Yemen',
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 79050,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17674',
      geometry: { type: 'Point', coordinates: [39.47658611, 15.60998694] },
      geometry_name: 'shape',
      properties: {
        portname: 'Massawa',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.60999,
        longitude: 39.47659,
        iso3: null,
        iso3_op: 'ERI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 330546,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17675',
      geometry: { type: 'Point', coordinates: [13.24481526, -8.80094335] },
      geometry_name: 'shape',
      properties: {
        portname: 'Luanda',
        code: 'AOLAD',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -8.80094,
        longitude: 13.24482,
        iso3: 'AGO',
        iso3_op: 'AGO COD',
        country: 'Angola',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2240449,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17676',
      geometry: { type: 'Point', coordinates: [79.84279959, 6.94118728] },
      geometry_name: 'shape',
      properties: {
        portname: 'Colombo',
        code: 'LKCMB',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 6.94119,
        longitude: 79.8428,
        iso3: 'LKA',
        iso3_op: 'LKA',
        country: 'Sri Lanka',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1248991,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17677',
      geometry: { type: 'Point', coordinates: [177.46469061, -16.84130286] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nabukeru',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -16.8413,
        longitude: 177.46469,
        iso3: 'FJI',
        iso3_op: 'FJI',
        country: 'Fiji',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2202311,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17678',
      geometry: { type: 'Point', coordinates: [96.1739706, 16.76688113] },
      geometry_name: 'shape',
      properties: {
        portname: 'Yangon',
        code: 'MMRGN',
        prttype: 'River',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 16.76688,
        longitude: 96.17397,
        iso3: 'MMR',
        iso3_op: 'MMR',
        country: 'Myanmar',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1298824,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17679',
      geometry: { type: 'Point', coordinates: [25.62806855, -33.96351029] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Elizabeth',
        code: 'ZAPLZ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -33.96351,
        longitude: 25.62807,
        iso3: 'ZAF',
        iso3_op: 'ZAF',
        country: 'South Africa',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 964420,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17680',
      geometry: { type: 'Point', coordinates: [122.71018881, 11.60526] },
      geometry_name: 'shape',
      properties: {
        portname: 'Culasi',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 11.60526,
        longitude: 122.71019,
        iso3: null,
        iso3_op: 'PHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1716571,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17681',
      geometry: { type: 'Point', coordinates: [125.00015755, 11.24829039] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tacloban',
        code: 'PHTAC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.24829,
        longitude: 125.00016,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1684712,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17682',
      geometry: { type: 'Point', coordinates: [124.60673641, 11.00505713] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ormoc',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.00506,
        longitude: 124.60674,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1697018,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17683',
      geometry: { type: 'Point', coordinates: [124.43366845, 10.89922656] },
      geometry_name: 'shape',
      properties: {
        portname: 'Isabel',
        code: 'PHISL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.89923,
        longitude: 124.43367,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1710523,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17684',
      geometry: { type: 'Point', coordinates: [123.7180661, 11.16643471] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bantayan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 11.16643,
        longitude: 123.71807,
        iso3: 'PHL',
        iso3_op: 'PHL',
        country: 'Philippines',
        lastcheckdate: '2013-12-10T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1727043,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17685',
      geometry: { type: 'Point', coordinates: [103.51771123, 10.64954698] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sihanoukville',
        code: 'KHSIH',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 10.64955,
        longitude: 103.51771,
        iso3: 'KHM',
        iso3_op: 'KHM',
        country: 'Cambodia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1831142,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17686',
      geometry: { type: 'Point', coordinates: [-69.3101251, 18.4490585] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Pedro De Macoris',
        code: 'DOSPM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.44906,
        longitude: -69.31013,
        iso3: null,
        iso3_op: 'DOM',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3493032,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17660',
      geometry: { type: 'Point', coordinates: [-179.98372027, -16.78157779] },
      geometry_name: 'shape',
      properties: {
        portname: 'Blackpoint',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -16.78158,
        longitude: -179.98372,
        iso3: null,
        iso3_op: 'FJI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-03-01T06:45:00.475Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17687',
      geometry: { type: 'Point', coordinates: [49.15160773, 14.52084554] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mukalla',
        code: 'YEMKX',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 14.52085,
        longitude: 49.15161,
        iso3: 'YEM',
        iso3_op: 'YEM',
        country: 'Yemen',
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 78754,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17688',
      geometry: { type: 'Point', coordinates: [52.19765297, 15.82170441] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nishtun',
        code: 'YENIS',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.8217,
        longitude: 52.19765,
        iso3: 'YEM',
        iso3_op: '',
        country: 'Yemen',
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 72176,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17689',
      geometry: { type: 'Point', coordinates: [45.59394976, 2.1776927] },
      geometry_name: 'shape',
      properties: {
        portname: "El Ma'an",
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 2.17769,
        longitude: 45.59395,
        iso3: 'SOM',
        iso3_op: 'SOM',
        country: 'Somalia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 53654,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17690',
      geometry: { type: 'Point', coordinates: [41.49105695, -1.74579028] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kiunga',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.74579,
        longitude: 41.49106,
        iso3: 'KEN',
        iso3_op: 'KEN',
        country: 'Kenya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 190954,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17691',
      geometry: { type: 'Point', coordinates: [40.90159237, -2.26814713] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lamu',
        code: 'KELAU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.26815,
        longitude: 40.90159,
        iso3: 'KEN',
        iso3_op: 'KEN',
        country: 'Kenya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 189741,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17692',
      geometry: { type: 'Point', coordinates: [40.1256601, -3.21595349] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malindi',
        code: 'KEMYD',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.21595,
        longitude: 40.12566,
        iso3: 'KEN',
        iso3_op: 'KEN',
        country: 'Kenya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 187968,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17693',
      geometry: { type: 'Point', coordinates: [39.84509215, -3.63390509] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kilifi',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.63391,
        longitude: 39.84509,
        iso3: 'KEN',
        iso3_op: 'KEN',
        country: 'Kenya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 192067,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17694',
      geometry: { type: 'Point', coordinates: [39.37978655, -4.64779067] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shimoni',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.64779,
        longitude: 39.37979,
        iso3: null,
        iso3_op: 'KEN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 180389,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17695',
      geometry: { type: 'Point', coordinates: [34.74528544, -0.10395544] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kisumu',
        code: null,
        prttype: 'Lake',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.10396,
        longitude: 34.74529,
        iso3: 'KEN',
        iso3_op: 'KEN',
        country: 'Kenya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 191245,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17696',
      geometry: { type: 'Point', coordinates: [40.5833002, -12.34354282] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ibo',
        code: 'MZIBO',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.34354,
        longitude: 40.5833,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1024695,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17697',
      geometry: { type: 'Point', coordinates: [40.48835781, -12.96563823] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pemba',
        code: 'MZPOL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.96564,
        longitude: 40.48836,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1028918,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17698',
      geometry: { type: 'Point', coordinates: [40.52676137, -14.18178493] },
      geometry_name: 'shape',
      properties: {
        portname: 'Memba',
        code: 'MZMEM',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -14.18178,
        longitude: 40.52676,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1038882,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17699',
      geometry: { type: 'Point', coordinates: [40.73659338, -15.0308104] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mocambique',
        code: 'MZMZQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -15.03081,
        longitude: 40.73659,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1037390,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17700',
      geometry: { type: 'Point', coordinates: [39.90224253, -16.23500976] },
      geometry_name: 'shape',
      properties: {
        portname: 'Angoche',
        code: 'MZANO',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.23501,
        longitude: 39.90224,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1052944,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17701',
      geometry: { type: 'Point', coordinates: [39.2215134, -16.76688214] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moma',
        code: 'MZMMW',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.76688,
        longitude: 39.22151,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1037225,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17702',
      geometry: { type: 'Point', coordinates: [36.87954167, -17.87888364] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quelimane',
        code: 'MZUEL',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.87888,
        longitude: 36.87954,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1028434,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17703',
      geometry: { type: 'Point', coordinates: [40.66747569, -14.54221816] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nacala',
        code: 'MZMNC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -14.54222,
        longitude: 40.66748,
        iso3: 'MOZ',
        iso3_op: 'MOZ, MWI, ZWE',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1035010,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17704',
      geometry: { type: 'Point', coordinates: [11.82790807, -4.7766232] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pointe Noire',
        code: 'CGPNR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -4.77662,
        longitude: 11.82791,
        iso3: null,
        iso3_op: 'COG COD',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2255414,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17705',
      geometry: { type: 'Point', coordinates: [-83.96778918, 22.67299767] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santa Lucia',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 22.673,
        longitude: -83.96779,
        iso3: 'CUB',
        iso3_op: null,
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3537467,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17706',
      geometry: { type: 'Point', coordinates: [49.60690663, 14.75047916] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ash Shihr',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 14.75048,
        longitude: 49.60691,
        iso3: 'YEM',
        iso3_op: 'YEM',
        country: 'Yemen',
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Google Earth',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 30490,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17707',
      geometry: { type: 'Point', coordinates: [42.66200565, 15.18921468] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ras Isa (SBM)',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Closed',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'approximate',
        latitude: 15.18921,
        longitude: 42.66201,
        iso3: null,
        iso3_op: 'YEM',
        country: null,
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 74406,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17708',
      geometry: { type: 'Point', coordinates: [-77.2125013, 21.54431921] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pastelillo',
        code: 'CUPAS',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 21.54432,
        longitude: -77.2125,
        iso3: 'CUB',
        iso3_op: null,
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17709',
      geometry: { type: 'Point', coordinates: [-75.65523935, 20.76759296] },
      geometry_name: 'shape',
      properties: {
        portname: 'Preston',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 20.76759,
        longitude: -75.65524,
        iso3: 'CUB',
        iso3_op: null,
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17710',
      geometry: { type: 'Point', coordinates: [-74.85635474, 20.63507632] },
      geometry_name: 'shape',
      properties: {
        portname: 'Punta Gorda',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 20.63508,
        longitude: -74.85635,
        iso3: 'CUB',
        iso3_op: null,
        country: 'Cuba',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3543205,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17711',
      geometry: { type: 'Point', coordinates: [-70.16290291, 18.23408391] },
      geometry_name: 'shape',
      properties: {
        portname: 'Palenque',
        code: 'DOPAL',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.23408,
        longitude: -70.1629,
        iso3: null,
        iso3_op: 'DOM',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3494270,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17712',
      geometry: { type: 'Point', coordinates: [37.18910961, -17.72150633] },
      geometry_name: 'shape',
      properties: {
        portname: 'Porto Belo',
        code: 'MZPBE',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.72151,
        longitude: 37.18911,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17713',
      geometry: { type: 'Point', coordinates: [36.45985894, -18.57656492] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chinde',
        code: 'MZINE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -18.57656,
        longitude: 36.45986,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1024697,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17714',
      geometry: { type: 'Point', coordinates: [35.38037766, -23.86696148] },
      geometry_name: 'shape',
      properties: {
        portname: 'Inhambane',
        code: 'MZINH',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -23.86696,
        longitude: 35.38038,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1045114,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17715',
      geometry: { type: 'Point', coordinates: [14.48488416, -22.9579541] },
      geometry_name: 'shape',
      properties: {
        portname: 'Walvis Bay',
        code: 'NAWVB',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -22.95795,
        longitude: 14.48488,
        iso3: 'NAM',
        iso3_op: 'NAM, ZWE',
        country: 'Namibia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3359638,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17716',
      geometry: { type: 'Point', coordinates: [34.83322443, -19.82676235] },
      geometry_name: 'shape',
      properties: {
        portname: 'Beira',
        code: 'MZBEW',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Restricted',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -19.82676,
        longitude: 34.83322,
        iso3: 'MOZ',
        iso3_op: 'MOZ, MWI, ZWE',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1052373,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17717',
      geometry: { type: 'Point', coordinates: [32.57539635, -25.97700984] },
      geometry_name: 'shape',
      properties: {
        portname: 'Maputo',
        code: 'MZMPM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -25.97701,
        longitude: 32.5754,
        iso3: 'MOZ',
        iso3_op: 'MOZ, ZWE',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1040652,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17718',
      geometry: { type: 'Point', coordinates: [32.67750564, -26.34633686] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bela Vista',
        code: 'MZBEL',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -26.34634,
        longitude: 32.67751,
        iso3: 'MOZ',
        iso3_op: 'MOZ',
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1052365,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17719',
      geometry: { type: 'Point', coordinates: [11.92923969, -4.91912951] },
      geometry_name: 'shape',
      properties: {
        portname: 'Djeno Terminal',
        code: 'CGDJE',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.91913,
        longitude: 11.92924,
        iso3: 'COG',
        iso3_op: 'COG',
        country: 'Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2260392,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17720',
      geometry: { type: 'Point', coordinates: [168.3062168, -17.75535075] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Vila',
        code: 'VUVLI',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.75535,
        longitude: 168.30622,
        iso3: null,
        iso3_op: 'VUT',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2135171,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17721',
      geometry: { type: 'Point', coordinates: [168.54890632, -17.68278158] },
      geometry_name: 'shape',
      properties: {
        portname: 'Forari Bay',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.68278,
        longitude: 168.54891,
        iso3: null,
        iso3_op: 'VUT',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2135108,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17722',
      geometry: { type: 'Point', coordinates: [167.783846, -16.43750122] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Sandwich',
        code: 'VUPSA',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.4375,
        longitude: 167.78385,
        iso3: null,
        iso3_op: 'VUT',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2136652,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17723',
      geometry: { type: 'Point', coordinates: [167.18054891, -15.51510479] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santo',
        code: 'VUSAN',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -15.5151,
        longitude: 167.18055,
        iso3: null,
        iso3_op: 'VUT',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2136150,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17724',
      geometry: { type: 'Point', coordinates: [177.43840207, -17.60476274] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lautoka',
        code: 'FJLTK',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.60476,
        longitude: 177.4384,
        iso3: null,
        iso3_op: 'FJI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2204506,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17725',
      geometry: { type: 'Point', coordinates: [178.42447482, -18.13360468] },
      geometry_name: 'shape',
      properties: {
        portname: 'Suva',
        code: 'FJSUV',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -18.1336,
        longitude: 178.42447,
        iso3: null,
        iso3_op: 'FJI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2198148,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17726',
      geometry: { type: 'Point', coordinates: [177.76350094, -17.39870768] },
      geometry_name: 'shape',
      properties: {
        portname: 'Vatia',
        code: 'FJVAT',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.39871,
        longitude: 177.7635,
        iso3: null,
        iso3_op: 'FJI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2200897,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17727',
      geometry: { type: 'Point', coordinates: [178.83630932, -17.68471893] },
      geometry_name: 'shape',
      properties: {
        portname: 'Levuka',
        code: 'FJLEV',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -17.68472,
        longitude: 178.83631,
        iso3: 'FJI',
        iso3_op: 'FJI',
        country: 'Fiji',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2204418,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17728',
      geometry: { type: 'Point', coordinates: [179.36656982, -16.36140487] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malau',
        code: 'FJMAL',
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.3614,
        longitude: 179.36657,
        iso3: null,
        iso3_op: 'FJI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2204146,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17729',
      geometry: { type: 'Point', coordinates: [179.36007411, -16.36488619] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lambasa',
        code: 'FJLBS',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.36489,
        longitude: 179.36007,
        iso3: null,
        iso3_op: 'FJI',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2204582,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17730',
      geometry: { type: 'Point', coordinates: [179.32634993, -16.77844042] },
      geometry_name: 'shape',
      properties: {
        portname: 'Savusavu',
        code: 'FJSUV',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -16.77844,
        longitude: 179.32635,
        iso3: 'FJI',
        iso3_op: 'FJI',
        country: 'Fiji',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2198520,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17731',
      geometry: { type: 'Point', coordinates: [11.73688315, 33.08128966] },
      geometry_name: 'shape',
      properties: {
        portname: 'Abu Kammash',
        code: 'LYABK',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 33.08129,
        longitude: 11.73688,
        iso3: 'LBY',
        iso3_op: 'LBY',
        country: 'Libya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2220457,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17732',
      geometry: { type: 'Point', coordinates: [14.2464372, 32.67332929] },
      geometry_name: 'shape',
      properties: {
        portname: 'Homs',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.67333,
        longitude: 14.24644,
        iso3: 'LBY',
        iso3_op: 'LBY',
        country: 'Libya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2219905,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17733',
      geometry: { type: 'Point', coordinates: [14.27166703, 32.65584139] },
      geometry_name: 'shape',
      properties: {
        portname: 'Al Khoms',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.65584,
        longitude: 14.27167,
        iso3: 'LBY',
        iso3_op: 'LBY',
        country: 'Libya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2219905,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17734',
      geometry: { type: 'Point', coordinates: [18.36525721, 30.63548521] },
      geometry_name: 'shape',
      properties: {
        portname: 'Es Sider',
        code: 'LYESI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 30.63549,
        longitude: 18.36526,
        iso3: 'LBY',
        iso3_op: 'LBY',
        country: 'Libya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2219340,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17735',
      geometry: { type: 'Point', coordinates: [22.65246202, 32.76257068] },
      geometry_name: 'shape',
      properties: {
        portname: 'Derna',
        code: 'LYDNF',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.76257,
        longitude: 22.65246,
        iso3: 'LBY',
        iso3_op: 'LBY',
        country: 'Libya',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 87205,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17736',
      geometry: { type: 'Point', coordinates: [23.98277325, 32.0770396] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tobruq',
        code: 'LYTOB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 32.07704,
        longitude: 23.98277,
        iso3: null,
        iso3_op: 'LBY',
        country: null,
        lastcheckdate: null,
        remarks:
          'STORAGE: 2 warehouses at the port (2000m2 each) but only ? a warehouse is available for use.\r\nSignificant storage facilities exist in Tobruk city\r\nCAPACITY\r\nCapacity was reported as being:\r\n120 TEU/day\r\n1200 MT bulk/day\r\n400MT loose cargo/day',
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 81302,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17737',
      geometry: { type: 'Point', coordinates: [-77.07840751, 3.89244167] },
      geometry_name: 'shape',
      properties: {
        portname: 'Buenaventura',
        code: 'COBUN',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 3.89244,
        longitude: -77.07841,
        iso3: null,
        iso3_op: 'COL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3688451,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17738',
      geometry: { type: 'Point', coordinates: [-76.73992408, 8.09527549] },
      geometry_name: 'shape',
      properties: {
        portname: 'Turbo',
        code: 'COTRB',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.09528,
        longitude: -76.73992,
        iso3: 'COL',
        iso3_op: 'COL',
        country: 'Colombia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3666570,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17739',
      geometry: { type: 'Point', coordinates: [-75.50976931, 10.32662364] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mamonal',
        code: 'COMAM',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.32662,
        longitude: -75.50977,
        iso3: null,
        iso3_op: 'COL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3675555,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17740',
      geometry: { type: 'Point', coordinates: [167.4432233, -16.1143987] },
      geometry_name: 'shape',
      properties: {
        portname: 'Litzlitz',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -16.1144,
        longitude: 167.44322,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: '2015-03-19T23:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2136390,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17741',
      geometry: { type: 'Point', coordinates: [168.14809056, -15.54754771] },
      geometry_name: 'shape',
      properties: {
        portname: 'Loltong',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -15.54755,
        longitude: 168.14809,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: '2015-03-19T23:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2136326,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17742',
      geometry: { type: 'Point', coordinates: [167.5502365, -13.87267752] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sola',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -13.87268,
        longitude: 167.55024,
        iso3: 'VUT',
        iso3_op: null,
        country: 'Vanuatu',
        lastcheckdate: '2015-03-19T23:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2134814,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17743',
      geometry: { type: 'Point', coordinates: [169.78109446, -20.23354087] },
      geometry_name: 'shape',
      properties: {
        portname: 'Anelgaohat',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -20.23354,
        longitude: 169.78109,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2137548,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17744',
      geometry: { type: 'Point', coordinates: [94.338671, 18.36544709] },
      geometry_name: 'shape',
      properties: {
        portname: 'Thandwe',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 18.36545,
        longitude: 94.33867,
        iso3: 'MMR',
        iso3_op: null,
        country: 'Myanmar',
        lastcheckdate: '2015-08-05T22:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1298073,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17745',
      geometry: { type: 'Point', coordinates: [58.87157609, 20.65828811] },
      geometry_name: 'shape',
      properties: {
        portname: 'Masirah',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 20.65829,
        longitude: 58.87158,
        iso3: 'OMN',
        iso3_op: null,
        country: 'Oman',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17746',
      geometry: { type: 'Point', coordinates: [59.51748093, 22.57690449] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sur',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 22.5769,
        longitude: 59.51748,
        iso3: 'OMN',
        iso3_op: null,
        country: 'Oman',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 286245,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17747',
      geometry: { type: 'Point', coordinates: [28.98472926, 41.02709473] },
      geometry_name: 'shape',
      properties: {
        portname: 'Istanbul',
        code: 'TRIST',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 41.02709,
        longitude: 28.98473,
        iso3: 'TUR',
        iso3_op: 'TUR',
        country: 'Turkey',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 745044,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17748',
      geometry: { type: 'Point', coordinates: [95.32268533, 5.88065032] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sabang',
        code: 'IDSBG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.88065,
        longitude: 95.32269,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1214026,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17749',
      geometry: { type: 'Point', coordinates: [97.09631175, 5.22202886] },
      geometry_name: 'shape',
      properties: {
        portname: 'Blang Lancang',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.22203,
        longitude: 97.09631,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 6718703,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17750',
      geometry: { type: 'Point', coordinates: [98.02056437, 4.52416408] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kuala Langsa',
        code: 'IDKUA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.52416,
        longitude: 98.02056,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1214841,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17751',
      geometry: { type: 'Point', coordinates: [102.10845065, 1.46621593] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bengkalis',
        code: 'IDBKI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.46622,
        longitude: 102.10845,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1649169,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17752',
      geometry: { type: 'Point', coordinates: [104.45375877, 0.92994342] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tanjung Pinang',
        code: 'IDTNJ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.92994,
        longitude: 104.45376,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1624863,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17753',
      geometry: { type: 'Point', coordinates: [104.60928592, 0.83742619] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kidjang',
        code: 'IDKID',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.83743,
        longitude: 104.60929,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1640044,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17754',
      geometry: { type: 'Point', coordinates: [104.56569562, -0.50100091] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dabo',
        code: 'IDDAS',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.501,
        longitude: 104.5657,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1881603,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17755',
      geometry: { type: 'Point', coordinates: [105.17548764, -2.07444216] },
      geometry_name: 'shape',
      properties: {
        portname: 'Muntok',
        code: 'IDMUO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.07444,
        longitude: 105.17549,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1634678,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17756',
      geometry: { type: 'Point', coordinates: [104.62022773, -5.49954697] },
      geometry_name: 'shape',
      properties: {
        portname: 'Semangka Bay',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.49955,
        longitude: 104.62023,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 7863222,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17757',
      geometry: { type: 'Point', coordinates: [98.78523235, 1.72919531] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sibolga',
        code: 'IDSLG',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.7292,
        longitude: 98.78523,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1213855,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17758',
      geometry: { type: 'Point', coordinates: [96.8114615, 3.72073027] },
      geometry_name: 'shape',
      properties: {
        portname: 'Susoh',
        code: 'IDSUS',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 3.72073,
        longitude: 96.81146,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1213612,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17759',
      geometry: { type: 'Point', coordinates: [96.12932042, 4.12710814] },
      geometry_name: 'shape',
      properties: {
        portname: 'Meulaboh',
        code: 'IDMEQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.12711,
        longitude: 96.12932,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1214488,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17760',
      geometry: { type: 'Point', coordinates: [109.01491044, -7.74912771] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cilacap',
        code: 'IDCXP',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -7.74913,
        longitude: 109.01491,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1646559,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17761',
      geometry: { type: 'Point', coordinates: [111.33739792, -6.7007341] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rembang',
        code: 'IDREM',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.70073,
        longitude: 111.3374,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 8060713,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17762',
      geometry: { type: 'Point', coordinates: [116.67453989, -8.48609599] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kayangan',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.4861,
        longitude: 116.67454,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 7545527,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17763',
      geometry: { type: 'Point', coordinates: [136.37691742, -1.25615801] },
      geometry_name: 'shape',
      properties: {
        portname: 'Biak',
        code: 'IDBIK',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.25616,
        longitude: 136.37692,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1648844,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17764',
      geometry: { type: 'Point', coordinates: [127.38809429, 0.78066317] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ternate',
        code: 'IDTTE',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 0.78066,
        longitude: 127.38809,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1624041,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17765',
      geometry: { type: 'Point', coordinates: [127.08498169, -3.26644901] },
      geometry_name: 'shape',
      properties: {
        portname: 'Namlea',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.26645,
        longitude: 127.08498,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1634519,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17766',
      geometry: { type: 'Point', coordinates: [121.07289145, -2.64253953] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malili',
        code: 'IDMLI',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.64254,
        longitude: 121.07289,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1622198,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17767',
      geometry: { type: 'Point', coordinates: [125.09461911, -1.76143618] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tubang',
        code: 'IDTBN',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -1.76144,
        longitude: 125.09462,
        iso3: null,
        iso3_op: 'IDN',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 9962771,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17768',
      geometry: { type: 'Point', coordinates: [117.15070675, -0.50506899] },
      geometry_name: 'shape',
      properties: {
        portname: 'Samarinda',
        code: 'IDSRI',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -0.50507,
        longitude: 117.15071,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1629001,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17769',
      geometry: { type: 'Point', coordinates: [112.97806282, -2.56809874] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sampit',
        code: 'IDSMQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.5681,
        longitude: 112.97806,
        iso3: 'IDN',
        iso3_op: 'IDN',
        country: 'Indonesia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1628884,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17770',
      geometry: { type: 'Point', coordinates: [-16.02815157, 17.99621751] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nouakchott',
        code: 'MRNKC',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 17.99622,
        longitude: -16.02815,
        iso3: null,
        iso3_op: 'MRT',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2377450,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17771',
      geometry: { type: 'Point', coordinates: [-80.00008085, -3.25739681] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Bolivar',
        code: 'ECPBO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.2574,
        longitude: -80.00008,
        iso3: 'ECU',
        iso3_op: 'ECU',
        country: 'Ecuador',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3652757,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17772',
      geometry: { type: 'Point', coordinates: [13.054232, -5.85953823] },
      geometry_name: 'shape',
      properties: {
        portname: 'Boma',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.85954,
        longitude: 13.05423,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2316702,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17773',
      geometry: { type: 'Point', coordinates: [12.40014666, -6.00129626] },
      geometry_name: 'shape',
      properties: {
        portname: 'Banana',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.0013,
        longitude: 12.40015,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2317418,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17774',
      geometry: { type: 'Point', coordinates: [31.81624719, -1.34839433] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bukoba',
        code: null,
        prttype: 'Lake',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -1.34839,
        longitude: 31.81625,
        iso3: 'TZA',
        iso3_op: null,
        country: 'United Republic of Tanzania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 160961,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17775',
      geometry: { type: 'Point', coordinates: [-60.73562797, 11.18156111] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port of Scarborough',
        code: 'PATT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 11.18156,
        longitude: -60.73563,
        iso3: 'TTO',
        iso3_op: null,
        country: 'Trinidad and Tobago',
        lastcheckdate: '2017-05-23T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'Google',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17777',
      geometry: { type: 'Point', coordinates: [-69.88201163, 18.47556833] },
      geometry_name: 'shape',
      properties: {
        portname: 'Santo Domingo',
        code: 'DOSDQ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.47557,
        longitude: -69.88201,
        iso3: 'DOM',
        iso3_op: 'DOM',
        country: 'Dominican Republic',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 7874116,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17778',
      geometry: { type: 'Point', coordinates: [-70.83628651, 18.34796819] },
      geometry_name: 'shape',
      properties: {
        portname: 'Puerto Viejo De Azua',
        code: 'DOPVA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.34797,
        longitude: -70.83629,
        iso3: null,
        iso3_op: 'DOM',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3494329,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17779',
      geometry: { type: 'Point', coordinates: [-71.65510065, 17.9254353] },
      geometry_name: 'shape',
      properties: {
        portname: 'Cabo Rojo',
        code: 'DOCBJ',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 17.92544,
        longitude: -71.6551,
        iso3: null,
        iso3_op: 'DOM',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3510888,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17780',
      geometry: { type: 'Point', coordinates: [-75.15958284, -15.35780645] },
      geometry_name: 'shape',
      properties: {
        portname: 'San Juan',
        code: 'PESJA',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -15.35781,
        longitude: -75.15958,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3929520,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17781',
      geometry: { type: 'Point', coordinates: [-78.49793568, -9.25610595] },
      geometry_name: 'shape',
      properties: {
        portname: 'Samanco',
        code: 'PESAM',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -9.25611,
        longitude: -78.49794,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3692686,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17782',
      geometry: { type: 'Point', coordinates: [-79.86606718, -6.93326305] },
      geometry_name: 'shape',
      properties: {
        portname: 'Eten',
        code: 'PEEEN',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -6.93326,
        longitude: -79.86607,
        iso3: null,
        iso3_op: 'PER',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3697083,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17783',
      geometry: { type: 'Point', coordinates: [-81.30779945, -4.66044212] },
      geometry_name: 'shape',
      properties: {
        portname: 'Negritos',
        code: 'PENGS',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.66044,
        longitude: -81.3078,
        iso3: 'PER',
        iso3_op: 'PER',
        country: 'Peru',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3694414,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17784',
      geometry: { type: 'Point', coordinates: [-77.14323026, 17.81696203] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rocky Point',
        code: 'JMROP',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 17.81696,
        longitude: -77.14323,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3488777,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17785',
      geometry: { type: 'Point', coordinates: [32.89668383, -2.51751662] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mwanza',
        code: null,
        prttype: 'Lake',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -2.51752,
        longitude: 32.89668,
        iso3: 'TZA',
        iso3_op: null,
        country: 'United Republic of Tanzania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 152224,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17786',
      geometry: { type: 'Point', coordinates: [-76.84368132, 17.93463208] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Royal',
        code: 'JMPRO',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 17.93463,
        longitude: -76.84368,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3488980,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17787',
      geometry: { type: 'Point', coordinates: [43.13937248, 11.60650137] },
      geometry_name: 'shape',
      properties: {
        portname: 'Djibouti',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 11.6065,
        longitude: 43.13937,
        iso3: null,
        iso3_op: 'DJI,SOM,ETH,SSD,SDN,YEM',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 223817,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17788',
      geometry: { type: 'Point', coordinates: [48.17710876, 13.97953681] },
      geometry_name: 'shape',
      properties: {
        portname: 'Balhaf',
        code: null,
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Closed',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 13.97954,
        longitude: 48.17711,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 77369,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17789',
      geometry: { type: 'Point', coordinates: [9.90518839, 2.93724912] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kribi',
        code: 'CMKBI',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'approximate',
        latitude: 2.93725,
        longitude: 9.90519,
        iso3: null,
        iso3_op: 'CMR, CAF',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2229761,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17790',
      geometry: { type: 'Point', coordinates: [5.6025, 4.24973387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pennington',
        code: 'NGPEN',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 4.24973,
        longitude: 5.6025,
        iso3: null,
        iso3_op: '',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17791',
      geometry: { type: 'Point', coordinates: [8.3321, 4.20893387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Antan Terminal',
        code: 'NGANT',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 4.20893,
        longitude: 8.3321,
        iso3: null,
        iso3_op: '',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17792',
      geometry: { type: 'Point', coordinates: [5.6562302, 5.91715784] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sapele',
        code: 'NGSPL',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.91716,
        longitude: 5.65623,
        iso3: 'NGA',
        iso3_op: '',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2323675,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17793',
      geometry: { type: 'Point', coordinates: [7.15815757, 4.42732377] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bonny',
        code: 'NGBON',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.42732,
        longitude: 7.15816,
        iso3: 'NGA',
        iso3_op: '',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2346812,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17794',
      geometry: { type: 'Point', coordinates: [6.23909203, 4.30587423] },
      geometry_name: 'shape',
      properties: {
        portname: 'Brass',
        code: 'NGBRA',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.30587,
        longitude: 6.23909,
        iso3: 'NGA',
        iso3_op: '',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2346734,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17795',
      geometry: { type: 'Point', coordinates: [8.31729499, 5.01135414] },
      geometry_name: 'shape',
      properties: {
        portname: 'Calabar',
        code: 'NGCBQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.01135,
        longitude: 8.31729,
        iso3: 'NGA',
        iso3_op: '',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2346229,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17796',
      geometry: { type: 'Point', coordinates: [5.72582442, 5.51753091] },
      geometry_name: 'shape',
      properties: {
        portname: 'Warri',
        code: 'NGWAR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.51753,
        longitude: 5.72582,
        iso3: 'NGA',
        iso3_op: '',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2319133,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17797',
      geometry: { type: 'Point', coordinates: [7.98998904, 4.54504558] },
      geometry_name: 'shape',
      properties: {
        portname: 'Que Oboe / Eket',
        code: 'NGOBO',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.54505,
        longitude: 7.98999,
        iso3: 'NGA',
        iso3_op: '',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2338110,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17798',
      geometry: { type: 'Point', coordinates: [8.5491, 4.21153387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kole Terminal',
        code: 'CMKOL',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 4.21153,
        longitude: 8.5491,
        iso3: null,
        iso3_op: '',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17799',
      geometry: { type: 'Point', coordinates: [8.4781, 4.10893387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Moudi Terminal',
        code: 'CMMOU',
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 4.10893,
        longitude: 8.4781,
        iso3: null,
        iso3_op: '',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17800',
      geometry: { type: 'Point', coordinates: [9.19301494, 4.01141852] },
      geometry_name: 'shape',
      properties: {
        portname: 'Victoria',
        code: 'CMVCC',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 4.01142,
        longitude: 9.19301,
        iso3: null,
        iso3_op: '',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2229411,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17801',
      geometry: { type: 'Point', coordinates: [3.38966179, 6.43672174] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lagos',
        code: 'NGLOS',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 6.43672,
        longitude: 3.38966,
        iso3: 'NGA',
        iso3_op: 'NGA',
        country: 'Nigeria',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2332459,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17802',
      geometry: { type: 'Point', coordinates: [39.28791904, -6.81982326] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dar Es Salaam',
        code: 'TZDAR',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -6.81982,
        longitude: 39.28792,
        iso3: 'TZA',
        iso3_op: 'TZA,COD,SSD,UGA,MWI',
        country: 'United Republic of Tanzania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 160263,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17803',
      geometry: { type: 'Point', coordinates: [-61.38839935, 15.31189996] },
      geometry_name: 'shape',
      properties: {
        portname: 'Woodbridge Bay Port',
        code: 'DMRSU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.3119,
        longitude: -61.3884,
        iso3: 'DMA',
        iso3_op: 'DMA',
        country: 'Dominica',
        lastcheckdate: '2017-09-25T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3575635,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17804',
      geometry: { type: 'Point', coordinates: [-78.17106751, 18.4447264] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lucea',
        code: 'JMLUC',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 18.44473,
        longitude: -78.17107,
        iso3: 'JAM',
        iso3_op: null,
        country: 'Jamaica',
        lastcheckdate: '2019-05-22T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3489657,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17805',
      geometry: { type: 'Point', coordinates: [13.44988088, -5.82388697] },
      geometry_name: 'shape',
      properties: {
        portname: 'Matadi',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -5.82389,
        longitude: 13.44988,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2313002,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17806',
      geometry: { type: 'Point', coordinates: [13.54947811, -12.35074948] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lobito',
        code: 'AOLOB',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -12.35075,
        longitude: 13.54948,
        iso3: 'AGO',
        iso3_op: 'AGO',
        country: 'Angola',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3372346,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17807',
      geometry: { type: 'Point', coordinates: [31.01967259, -29.88107545] },
      geometry_name: 'shape',
      properties: {
        portname: 'Durban',
        code: 'ZADUR',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -29.88108,
        longitude: 31.01967,
        iso3: null,
        iso3_op: 'ZAF, MWI, ZWE',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1007311,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17808',
      geometry: { type: 'Point', coordinates: [18.62354751, -0.10617634] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kombo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -0.10618,
        longitude: 18.62355,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2314000,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17809',
      geometry: { type: 'Point', coordinates: [18.97293819, -0.3137655] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ingende',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -0.31377,
        longitude: 18.97294,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2315437,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17810',
      geometry: { type: 'Point', coordinates: [-0.05170763, 16.27808904] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gao',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 16.27809,
        longitude: -0.05171,
        iso3: 'MLI',
        iso3_op: 'MLI',
        country: 'Mali',
        lastcheckdate: null,
        remarks: 'digitized manually from Bing imagery',
        url_lca: null,
        source: 'Compagnie Malienne de Navigation',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2457163,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17811',
      geometry: { type: 'Point', coordinates: [12.38706549, -7.34569038] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kiame Marine Terminal',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'unknown',
        latitude: -7.34569,
        longitude: 12.38707,
        iso3: null,
        iso3_op: 'AGO',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2239001,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17812',
      geometry: { type: 'Point', coordinates: [-71.61604875, 10.58756293] },
      geometry_name: 'shape',
      properties: {
        portname: 'Punta Piedras',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 10.58756,
        longitude: -71.61605,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'WPS',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3790235,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17813',
      geometry: { type: 'Point', coordinates: [-159.35987629, 3.85730149] },
      geometry_name: 'shape',
      properties: {
        portname: 'English Harbour',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 3.8573,
        longitude: -159.35988,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: '2018-09-05T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 4030952,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17814',
      geometry: { type: 'Point', coordinates: [9.80648252, 37.15689853] },
      geometry_name: 'shape',
      properties: {
        portname: 'Menzel Bourguiba',
        code: 'TNMBA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 37.1569,
        longitude: 9.80648,
        iso3: 'TUN',
        iso3_op: null,
        country: 'Tunisia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 11258825,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17815',
      geometry: { type: 'Point', coordinates: [12.58824266, -7.33097128] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kiabo Marine Terminal',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'unknown',
        latitude: -7.33097,
        longitude: 12.58824,
        iso3: null,
        iso3_op: 'AGO',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17816',
      geometry: { type: 'Point', coordinates: [12.14198543, -5.21934949] },
      geometry_name: 'shape',
      properties: {
        portname: 'Takula Terminal',
        code: 'AOTAK',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'approximate',
        latitude: -5.21935,
        longitude: 12.14199,
        iso3: 'AGO',
        iso3_op: 'AGO',
        country: 'Angola',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17817',
      geometry: { type: 'Point', coordinates: [12.2295, -6.33106613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Quinfuquena Terminal',
        code: 'AOSOQ',
        prttype: 'Sea',
        prtsize: 'Unknown',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -6.33107,
        longitude: 12.2295,
        iso3: null,
        iso3_op: 'AGO',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2236967,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17818',
      geometry: { type: 'Point', coordinates: [-61.38986871, 15.29814529] },
      geometry_name: 'shape',
      properties: {
        portname: 'Roseau',
        code: 'DMRSU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 15.29815,
        longitude: -61.38987,
        iso3: 'DMA',
        iso3_op: 'DMA',
        country: 'Dominica',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3575635,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17819',
      geometry: { type: 'Point', coordinates: [-61.45744423, 15.5774298] },
      geometry_name: 'shape',
      properties: {
        portname: 'Portsmouth',
        code: 'DMPOR',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.57743,
        longitude: -61.45744,
        iso3: 'DMA',
        iso3_op: 'DMA',
        country: 'Dominica',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3575654,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17820',
      geometry: { type: 'Point', coordinates: [171.36524288, 7.085588] },
      geometry_name: 'shape',
      properties: {
        portname: 'Majuro',
        code: 'MHMAJ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 7.08559,
        longitude: 171.36524,
        iso3: 'MHL',
        iso3_op: 'MHL',
        country: 'Marshall Islands',
        lastcheckdate: '2018-07-16T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2113779,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17821',
      geometry: { type: 'Point', coordinates: [162.96127068, 5.35507722] },
      geometry_name: 'shape',
      properties: {
        portname: 'Okat',
        code: ' ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: ' ',
        locprecision: 'accurate',
        latitude: 5.35508,
        longitude: 162.96127,
        iso3: null,
        iso3_op: 'FSM',
        country: null,
        lastcheckdate: '2018-05-21T00:00:00Z',
        remarks: ' ',
        url_lca: ' ',
        source: ' ',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2081694,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17822',
      geometry: { type: 'Point', coordinates: [151.83987882, 7.44549764] },
      geometry_name: 'shape',
      properties: {
        portname: 'Weno',
        code: ' ',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: ' ',
        locprecision: 'accurate',
        latitude: 7.4455,
        longitude: 151.83988,
        iso3: 'FSM',
        iso3_op: 'FSM',
        country: 'Micronesia (Federated States of)',
        lastcheckdate: '2018-05-21T00:00:00Z',
        remarks: ' ',
        url_lca: ' ',
        source: ' ',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 4042205,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17823',
      geometry: { type: 'Point', coordinates: [42.93463604, 14.83230226] },
      geometry_name: 'shape',
      properties: {
        portname: 'Hodeidah',
        code: 'YEHOD',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 14.8323,
        longitude: 42.93464,
        iso3: null,
        iso3_op: 'YEM',
        country: null,
        lastcheckdate: '2017-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 79415,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17824',
      geometry: { type: 'Point', coordinates: [24.07700377, 57.04010389] },
      geometry_name: 'shape',
      properties: {
        portname: 'Riga',
        code: 'LVRIX',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 57.0401,
        longitude: 24.077,
        iso3: 'LVA',
        iso3_op: 'LVA',
        country: 'Latvia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 456172,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17825',
      geometry: { type: 'Point', coordinates: [12.33945758, -6.11981643] },
      geometry_name: 'shape',
      properties: {
        portname: 'Soyo',
        code: 'AOSZA',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -6.11982,
        longitude: 12.33946,
        iso3: 'AGO',
        iso3_op: 'AGO',
        country: 'Angola',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2236967,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17826',
      geometry: { type: 'Point', coordinates: [30.53737878, 1.0543249] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ntokoro',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 1.05432,
        longitude: 30.53738,
        iso3: 'UGA',
        iso3_op: null,
        country: 'Uganda',
        lastcheckdate: '2018-08-20T22:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 227605,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17827',
      geometry: { type: 'Point', coordinates: [12.37226477, -6.83259882] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lombo Marine Terminal',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'unknown',
        latitude: -6.8326,
        longitude: 12.37226,
        iso3: null,
        iso3_op: 'AGO',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2239789,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17828',
      geometry: { type: 'Point', coordinates: [-71.58855927, 10.7043363] },
      geometry_name: 'shape',
      properties: {
        portname: 'La Ceiba / Maracaibo L',
        code: 'VELCV',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.70434,
        longitude: -71.58856,
        iso3: 'VEN',
        iso3_op: 'VEN',
        country: 'Venezuela',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3633009,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17829',
      geometry: { type: 'Point', coordinates: [158.20141194, 6.97956593] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pohnpei',
        code: ' ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: ' ',
        locprecision: 'accurate',
        latitude: 6.97957,
        longitude: 158.20141,
        iso3: null,
        iso3_op: 'FSM',
        country: null,
        lastcheckdate: '2018-05-21T00:00:00Z',
        remarks: ' ',
        url_lca: ' ',
        source: ' ',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 0,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17830',
      geometry: { type: 'Point', coordinates: [21.55135934, 57.40035623] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ventspils',
        code: 'LVVNT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 57.40036,
        longitude: 21.55136,
        iso3: null,
        iso3_op: 'LVA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 454310,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17831',
      geometry: { type: 'Point', coordinates: [167.73667447, 8.73202854] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kwajalein',
        code: 'MHKWA',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 8.73203,
        longitude: 167.73667,
        iso3: 'MHL',
        iso3_op: 'MHL',
        country: 'Marshall Islands',
        lastcheckdate: '2018-07-16T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17832',
      geometry: { type: 'Point', coordinates: [138.12526239, 9.51578289] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tomil',
        code: 'FMYAP',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: ' ',
        locprecision: 'accurate',
        latitude: 9.51578,
        longitude: 138.12526,
        iso3: null,
        iso3_op: 'FSM',
        country: null,
        lastcheckdate: '2018-05-21T00:00:00Z',
        remarks: ' ',
        url_lca: ' ',
        source: ' ',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 0,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17833',
      geometry: { type: 'Point', coordinates: [169.53437876, -0.86506278] },
      geometry_name: 'shape',
      properties: {
        portname: 'Banaba',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -0.86506,
        longitude: 169.53438,
        iso3: 'KIR',
        iso3_op: null,
        country: 'Kiribati',
        lastcheckdate: '2018-09-05T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'World Port Source',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17834',
      geometry: { type: 'Point', coordinates: [-157.47164006, 1.98308513] },
      geometry_name: 'shape',
      properties: {
        portname: 'London',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 1.98309,
        longitude: -157.47164,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: '2018-09-05T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 7521955,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17835',
      geometry: { type: 'Point', coordinates: [172.93222125, 1.35859327] },
      geometry_name: 'shape',
      properties: {
        portname: 'Betio Harbour',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 1.35859,
        longitude: 172.93222,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: '2018-09-05T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 7521491,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17836',
      geometry: { type: 'Point', coordinates: [22.80827731, 57.50701905] },
      geometry_name: 'shape',
      properties: {
        portname: 'Roja Harbor',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 57.50702,
        longitude: 22.80828,
        iso3: 'LVA',
        iso3_op: 'LVA',
        country: 'Latvia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 456107,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17837',
      geometry: { type: 'Point', coordinates: [33.06204531, 8.61362283] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nasser',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 8.61362,
        longitude: 33.06205,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 369355,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17838',
      geometry: { type: 'Point', coordinates: [144.66280078, 13.43965322] },
      geometry_name: 'shape',
      properties: {
        portname: 'Apra',
        code: 'GUAPR',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 13.43965,
        longitude: 144.6628,
        iso3: null,
        iso3_op: 'GUM',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 4038473,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17839',
      geometry: { type: 'Point', coordinates: [24.40706418, 57.3168616] },
      geometry_name: 'shape',
      properties: {
        portname: 'Skulte',
        code: 'LVSKU',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 57.31686,
        longitude: 24.40706,
        iso3: 'LVA',
        iso3_op: 'LVA',
        country: 'Latvia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 455498,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17840',
      geometry: { type: 'Point', coordinates: [134.45702493, 7.33088309] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port of Koror',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 7.33088,
        longitude: 134.45702,
        iso3: null,
        iso3_op: '',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17841',
      geometry: { type: 'Point', coordinates: [24.35094565, 57.75836294] },
      geometry_name: 'shape',
      properties: {
        portname: 'Salacgriva Harbor',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 57.75836,
        longitude: 24.35095,
        iso3: null,
        iso3_op: 'LVA',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 455910,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17842',
      geometry: { type: 'Point', coordinates: [121.59119801, 15.75582081] },
      geometry_name: 'shape',
      properties: {
        portname: 'Baler',
        code: null,
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 15.75582,
        longitude: 121.5912,
        iso3: 'PHL',
        iso3_op: null,
        country: 'Philippines',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1728264,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17843',
      geometry: { type: 'Point', coordinates: [-159.78383081, -21.20415164] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port of Avatiu',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -21.20415,
        longitude: -159.78383,
        iso3: 'COK',
        iso3_op: null,
        country: 'Cook Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17845',
      geometry: { type: 'Point', coordinates: [145.7350469, 15.22485922] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saipan',
        code: 'MPSPN',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 15.22486,
        longitude: 145.73505,
        iso3: 'MNP',
        iso3_op: 'MNP',
        country: 'Northern Mariana Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 7828758,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17846',
      geometry: { type: 'Point', coordinates: [34.26843598, 8.1912518] },
      geometry_name: 'shape',
      properties: {
        portname: 'Itang',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 8.19125,
        longitude: 34.26844,
        iso3: 'ETH',
        iso3_op: 'ETH,SSD',
        country: 'Ethiopia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 334050,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17847',
      geometry: { type: 'Point', coordinates: [30.77409997, 7.10669314] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shambe',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 7.10669,
        longitude: 30.7741,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 367322,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17848',
      geometry: { type: 'Point', coordinates: [34.58947358, 8.24532167] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gambela',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 8.24532,
        longitude: 34.58947,
        iso3: 'ETH',
        iso3_op: 'ETH,SSD',
        country: 'Ethiopia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 337405,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17849',
      geometry: { type: 'Point', coordinates: [145.14359516, 14.13942689] },
      geometry_name: 'shape',
      properties: {
        portname: 'Rota West',
        code: 'MPROP',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 14.13943,
        longitude: 145.1436,
        iso3: 'MNP',
        iso3_op: null,
        country: 'Northern Mariana Islands',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17850',
      geometry: { type: 'Point', coordinates: [145.62026883, 14.96588461] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tinian',
        code: 'MPTIQ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 14.96588,
        longitude: 145.62027,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17851',
      geometry: { type: 'Point', coordinates: [32.788694, 11.7478145] },
      geometry_name: 'shape',
      properties: {
        portname: 'Renk',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 11.74781,
        longitude: 32.78869,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 378487,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17852',
      geometry: { type: 'Point', coordinates: [33.44645286, 8.35625146] },
      geometry_name: 'shape',
      properties: {
        portname: 'Metar',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 8.35625,
        longitude: 33.44645,
        iso3: 'ETH',
        iso3_op: 'ETH,SSD',
        country: 'Ethiopia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 9962476,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17853',
      geometry: { type: 'Point', coordinates: [171.22509388, 8.7044389] },
      geometry_name: 'shape',
      properties: {
        portname: 'Taroa',
        code: 'MHTAR',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.70444,
        longitude: 171.22509,
        iso3: null,
        iso3_op: 'MHL',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 4039539,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17854',
      geometry: { type: 'Point', coordinates: [31.10530276, -8.76378029] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mbulungu',
        code: null,
        prttype: null,
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -8.76378,
        longitude: 31.1053,
        iso3: 'ZMB',
        iso3_op: null,
        country: 'Zambia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 175961,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17855',
      geometry: { type: 'Point', coordinates: [29.62667, -4.87694] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kigoma',
        code: null,
        prttype: null,
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -4.87694,
        longitude: 29.62667,
        iso3: 'TZA',
        iso3_op: null,
        country: 'United Republic of Tanzania',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 157738,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17857',
      geometry: { type: 'Point', coordinates: [32.20147548, 10.43586509] },
      geometry_name: 'shape',
      properties: {
        portname: 'Melut',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 10.43587,
        longitude: 32.20148,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 370669,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17858',
      geometry: { type: 'Point', coordinates: [31.76846341, 5.20181976] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mongalia',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 5.20182,
        longitude: 31.76846,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 369893,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17859',
      geometry: { type: 'Point', coordinates: [89.52180583, 22.60053306] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chalna Port',
        code: 'BDCHL',
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.60053,
        longitude: 89.52181,
        iso3: 'BGD',
        iso3_op: 'BGD',
        country: 'Bangladesh',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1207571,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17860',
      geometry: { type: 'Point', coordinates: [91.79599093, 22.30145585] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chittagong Port',
        code: 'BDCGP',
        prttype: 'Sea',
        prtsize: 'Large',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 22.30146,
        longitude: 91.79599,
        iso3: 'BGD',
        iso3_op: 'BGD',
        country: 'Bangladesh',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1205733,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17861',
      geometry: { type: 'Point', coordinates: [172.97475768, 1.33163724] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tarawa',
        code: 'KITRW',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 1.33164,
        longitude: 172.97476,
        iso3: null,
        iso3_op: 'KIR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2110257,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17862',
      geometry: { type: 'Point', coordinates: [89.5812589, 22.80161104] },
      geometry_name: 'shape',
      properties: {
        portname: 'Khulna Port',
        code: null,
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 22.80161,
        longitude: 89.58126,
        iso3: 'BGD',
        iso3_op: 'BGD',
        country: 'Bangladesh',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1336135,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17863',
      geometry: { type: 'Point', coordinates: [89.59083463, 22.4878591] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mongla Port',
        code: 'BDMGL',
        prttype: 'River',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 22.48786,
        longitude: 89.59083,
        iso3: 'BGD',
        iso3_op: 'BGD',
        country: 'Bangladesh',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1341778,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17864',
      geometry: { type: 'Point', coordinates: [90.5051, 23.60323387] },
      geometry_name: 'shape',
      properties: {
        portname: 'Narayanganj Port',
        code: 'BDNAR',
        prttype: 'River',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 23.60323,
        longitude: 90.5051,
        iso3: null,
        iso3_op: 'BGD',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1185155,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17865',
      geometry: { type: 'Point', coordinates: [-78.9740744, 21.59800189] },
      geometry_name: 'shape',
      properties: {
        portname: 'Palo Alto',
        code: 'CUPAL',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 21.598,
        longitude: -78.97407,
        iso3: null,
        iso3_op: 'BHS',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3545028,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17866',
      geometry: { type: 'Point', coordinates: [-78.1340965, 18.20850494] },
      geometry_name: 'shape',
      properties: {
        portname: 'Savanna La Mar',
        code: 'JMSLM',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.2085,
        longitude: -78.1341,
        iso3: 'JAM',
        iso3_op: 'JAM',
        country: 'Jamaica',
        lastcheckdate: '2019-05-22T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3488613,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17856',
      geometry: { type: 'Point', coordinates: [30.27156053, 8.55010102] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kilo 29',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 8.5501,
        longitude: 30.27156,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-03-01T06:54:31.623Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17867',
      geometry: { type: 'Point', coordinates: [-76.45294174, 18.18188456] },
      geometry_name: 'shape',
      properties: {
        portname: 'Port Antonio',
        code: 'JMPOT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.18188,
        longitude: -76.45294,
        iso3: null,
        iso3_op: 'JAM',
        country: null,
        lastcheckdate: '2019-05-22T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3489005,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17868',
      geometry: { type: 'Point', coordinates: [-77.93671863, 18.45857964] },
      geometry_name: 'shape',
      properties: {
        portname: 'Montego Bay',
        code: 'JMMBJ',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 18.45858,
        longitude: -77.93672,
        iso3: null,
        iso3_op: 'JAM',
        country: null,
        lastcheckdate: '2019-05-22T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3489460,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17869',
      geometry: { type: 'Point', coordinates: [9.70061312, 4.06602513] },
      geometry_name: 'shape',
      properties: {
        portname: 'Douala',
        code: 'CMDLA',
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 4.06603,
        longitude: 9.70061,
        iso3: null,
        iso3_op: 'CAF COD TCD CMR',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2232593,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17870',
      geometry: { type: 'Point', coordinates: [89.78048794, 23.83598711] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aricha Ghat Port',
        code: null,
        prttype: 'River',
        prtsize: null,
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 23.83599,
        longitude: 89.78049,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17871',
      geometry: { type: 'Point', coordinates: [90.2792596, 21.98900163] },
      geometry_name: 'shape',
      properties: {
        portname: 'Payra Port',
        code: null,
        prttype: 'River',
        prtsize: null,
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 21.989,
        longitude: 90.27926,
        iso3: null,
        iso3_op: null,
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17872',
      geometry: { type: 'Point', coordinates: [91.00113429, 24.04233411] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ashuganj Port',
        code: null,
        prttype: 'River',
        prtsize: null,
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 24.04233,
        longitude: 91.00113,
        iso3: 'BGD',
        iso3_op: null,
        country: 'Bangladesh',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1212405,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17873',
      geometry: { type: 'Point', coordinates: [90.40943145, 23.70565969] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dhaka River Port',
        code: null,
        prttype: 'River',
        prtsize: null,
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 23.70566,
        longitude: 90.40943,
        iso3: 'BGD',
        iso3_op: null,
        country: 'Bangladesh',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17874',
      geometry: { type: 'Point', coordinates: [90.45527414, 23.65699852] },
      geometry_name: 'shape',
      properties: {
        portname: 'Pangaon Port',
        code: null,
        prttype: 'River',
        prtsize: null,
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 23.657,
        longitude: 90.45527,
        iso3: 'BGD',
        iso3_op: null,
        country: 'Bangladesh',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1192609,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17875',
      geometry: { type: 'Point', coordinates: [-78.85286136, 21.62087258] },
      geometry_name: 'shape',
      properties: {
        portname: 'Jucaro',
        code: 'CUJUC',
        prttype: 'Sea',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 21.62087,
        longitude: -78.85286,
        iso3: null,
        iso3_op: 'CUB',
        country: null,
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3555731,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17876',
      geometry: { type: 'Point', coordinates: [39.10406268, 22.30510455] },
      geometry_name: 'shape',
      properties: {
        portname: 'King Abdullah Port',
        code: null,
        prttype: 'Sea',
        prtsize: 'Medium',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 22.3051,
        longitude: 39.10406,
        iso3: 'SAU',
        iso3_op: 'YEM',
        country: 'Saudi Arabia',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17877',
      geometry: { type: 'Point', coordinates: [-76.63721791, 25.49997594] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dunmore',
        code: null,
        prttype: null,
        prtsize: 'Very Small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 25.49998,
        longitude: -76.63722,
        iso3: 'BHS',
        iso3_op: 'BHS',
        country: 'Bahamas',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3572462,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17878',
      geometry: { type: 'Point', coordinates: [-79.88075382, 9.36138639] },
      geometry_name: 'shape',
      properties: {
        portname: 'Manzanillo',
        code: null,
        prttype: 'Sea Port',
        prtsize: 'Big',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 9.36139,
        longitude: -79.88075,
        iso3: 'PAN',
        iso3_op: null,
        country: 'Panama',
        lastcheckdate: '2019-02-20T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17879',
      geometry: { type: 'Point', coordinates: [-95.27215017, 29.73005195] },
      geometry_name: 'shape',
      properties: {
        portname: 'Houston',
        code: null,
        prttype: 'Sea',
        prtsize: 'Big',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 29.73005,
        longitude: -95.27215,
        iso3: 'USA',
        iso3_op: null,
        country: 'United States of America',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 4699066,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17880',
      geometry: { type: 'Point', coordinates: [15.90898552, 38.45793838] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gioia Tauro',
        code: null,
        prttype: 'Sea',
        prtsize: 'Big',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 38.45794,
        longitude: 15.90899,
        iso3: 'ITA',
        iso3_op: null,
        country: 'Italy',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 2524606,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17881',
      geometry: { type: 'Point', coordinates: [34.95878221, 29.55035153] },
      geometry_name: 'shape',
      properties: {
        portname: 'Elat',
        code: 'ILETH',
        prttype: 'Unknown',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 29.55035,
        longitude: 34.95878,
        iso3: 'ISR',
        iso3_op: 'ISR',
        country: 'Israel',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 295277,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17882',
      geometry: { type: 'Point', coordinates: [44.39270854, -12.16743555] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mutsamudu',
        code: 'KMMUT',
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -12.16744,
        longitude: 44.39271,
        iso3: 'COM',
        iso3_op: 'COM',
        country: 'Comoros',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 921753,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17883',
      geometry: { type: 'Point', coordinates: [125.57469004, -8.55409361] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dili',
        code: null,
        prttype: 'Sea',
        prtsize: 'Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -8.55409,
        longitude: 125.57469,
        iso3: 'TLS',
        iso3_op: 'TLS',
        country: 'Timor-Leste',
        lastcheckdate: '2019-05-14T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1645457,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17884',
      geometry: { type: 'Point', coordinates: [-77.03902039, 26.55098118] },
      geometry_name: 'shape',
      properties: {
        portname: 'Albury',
        code: null,
        prttype: null,
        prtsize: 'Very Small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 26.55098,
        longitude: -77.03902,
        iso3: 'BHS',
        iso3_op: 'BHS',
        country: 'Bahamas',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17885',
      geometry: { type: 'Point', coordinates: [-75.76868449, 23.50693705] },
      geometry_name: 'shape',
      properties: {
        portname: 'Gerge Town',
        code: null,
        prttype: null,
        prtsize: 'Very Small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: 23.50694,
        longitude: -75.76868,
        iso3: 'BHS',
        iso3_op: 'BHS',
        country: 'Bahamas',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 3572349,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17886',
      geometry: { type: 'Point', coordinates: [40.3601868, -11.33713911] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mocmboa da Praia',
        code: null,
        prttype: null,
        prtsize: null,
        status: 'Closed',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: -11.33714,
        longitude: 40.36019,
        iso3: 'MOZ',
        iso3_op: null,
        country: 'Mozambique',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-24T11:54:56.019Z',
        geonameid: 1024699,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14312',
      geometry: { type: 'Point', coordinates: [18.72147, -4.65347] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kipindi',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.65347,
        longitude: 18.72147,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-25T06:45:00.601Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14313',
      geometry: { type: 'Point', coordinates: [18.91101, -3.83567] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ipole',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -3.83567,
        longitude: 18.91101,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-25T06:49:51.089Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14355',
      geometry: { type: 'Point', coordinates: [29.09693056, -2.20858333] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nkuvu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.20858,
        longitude: 29.09693,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-25T07:04:24.420Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14385',
      geometry: { type: 'Point', coordinates: [27.34070976, -2.68302405] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ulindi',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.68302,
        longitude: 27.34071,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-25T07:09:22.946Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14386',
      geometry: { type: 'Point', coordinates: [17.39063612, -5.66033412] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -5.66033,
        longitude: 17.39064,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-25T07:14:17.248Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14410',
      geometry: { type: 'Point', coordinates: [21.77296068, 1.6324158] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lilanga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 1.63242,
        longitude: 21.77296,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-25T07:18:56.299Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14419',
      geometry: { type: 'Point', coordinates: [20.96311734, -4.61294533] },
      geometry_name: 'shape',
      properties: {
        portname: 'Makayabo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -4.61295,
        longitude: 20.96312,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-25T07:23:41.222Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14423',
      geometry: { type: 'Point', coordinates: [21.69881968, -1.01845909] },
      geometry_name: 'shape',
      properties: {
        portname: 'Itoko',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -1.01846,
        longitude: 21.69882,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-25T07:28:24.945Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14425',
      geometry: { type: 'Point', coordinates: [29.095075, -2.16881944] },
      geometry_name: 'shape',
      properties: {
        portname: 'Karhanywa',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -2.16882,
        longitude: 29.09508,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-25T07:33:07.050Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14479',
      geometry: { type: 'Point', coordinates: [17.37415663, -5.33226006] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -5.33226,
        longitude: 17.37416,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-25T07:37:52.498Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14481',
      geometry: { type: 'Point', coordinates: [17.40711561, -5.83523155] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -5.83523,
        longitude: 17.40712,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-26T06:49:45.637Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14482',
      geometry: { type: 'Point', coordinates: [29.08097778, -2.24253889] },
      geometry_name: 'shape',
      properties: {
        portname: 'Boza',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.24254,
        longitude: 29.08098,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-26T06:54:27.612Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14487',
      geometry: { type: 'Point', coordinates: [21.24198435, 1.56828805] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mabunde',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: 1.56829,
        longitude: 21.24198,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-26T06:59:11.146Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14489',
      geometry: { type: 'Point', coordinates: [29.05393889, -2.26657778] },
      geometry_name: 'shape',
      properties: {
        portname: 'De Bwina',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.26658,
        longitude: 29.05394,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-26T07:03:59.145Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14492',
      geometry: { type: 'Point', coordinates: [18.61302913, -4.52729498] },
      geometry_name: 'shape',
      properties: {
        portname: 'Annicet / Ntantu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -4.52729,
        longitude: 18.61303,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-26T07:08:47.208Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14551',
      geometry: { type: 'Point', coordinates: [16.5353, -4.87386613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kunde',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -4.87387,
        longitude: 16.5353,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-26T07:13:28.070Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14555',
      geometry: { type: 'Point', coordinates: [29.10298333, -2.15902222] },
      geometry_name: 'shape',
      properties: {
        portname: 'Shwishwi',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -2.15902,
        longitude: 29.10298,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-26T07:18:20.516Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14585',
      geometry: { type: 'Point', coordinates: [29.09777195, -4.10648889] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mushimbake',
        code: null,
        prttype: 'Lake',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -4.10649,
        longitude: 29.09777,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-26T07:23:08.155Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.14586',
      geometry: { type: 'Point', coordinates: [17.44017753, -3.24331159] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lumbu',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Open',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: -3.24331,
        longitude: 17.44018,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: '2014-08-18T00:00:00Z',
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:52:47.493Z',
        updatedate: '2021-02-26T07:27:50.234Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17221',
      geometry: { type: 'Point', coordinates: [23.79067817, -5.04654141] },
      geometry_name: 'shape',
      properties: {
        portname: 'Batempa',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -5.04654,
        longitude: 23.79068,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-26T07:32:31.585Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17224',
      geometry: { type: 'Point', coordinates: [18.95872335, -3.90799538] },
      geometry_name: 'shape',
      properties: {
        portname: 'Olympia',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: -3.908,
        longitude: 18.95872,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-26T07:37:18.386Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17290',
      geometry: { type: 'Point', coordinates: [17.4, -5.83] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -5.83,
        longitude: 17.4,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:14.295Z',
        updatedate: '2021-02-27T06:45:00.414Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17320',
      geometry: { type: 'Point', coordinates: [21.07013755, -5.22867404] },
      geometry_name: 'shape',
      properties: {
        portname: 'Lusanga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -5.22867,
        longitude: 21.07014,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-27T07:08:55.853Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17329',
      geometry: { type: 'Point', coordinates: [16.54430893, -4.68516111] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kimpasa',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -4.68516,
        longitude: 16.54431,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-27T07:13:44.191Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17358',
      geometry: { type: 'Point', coordinates: [17.45106088, -6.23947018] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -6.23947,
        longitude: 17.45106,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-27T07:18:30.352Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17361',
      geometry: { type: 'Point', coordinates: [16.8937, -4.04566613] },
      geometry_name: 'shape',
      properties: {
        portname: 'Fabieza',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -4.04567,
        longitude: 16.8937,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-27T07:23:19.946Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17364',
      geometry: { type: 'Point', coordinates: [29.09849722, -1.94488889] },
      geometry_name: 'shape',
      properties: {
        portname: 'Bwajingi',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -1.94489,
        longitude: 29.0985,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-27T07:28:08.706Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17366',
      geometry: { type: 'Point', coordinates: [18.04204726, 1.44238205] },
      geometry_name: 'shape',
      properties: {
        portname: 'Boburu',
        code: null,
        prttype: 'Unknown',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Yes',
        locprecision: 'accurate',
        latitude: 1.44238,
        longitude: 18.04205,
        iso3: 'COD',
        iso3_op: 'COG',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-27T07:33:03.305Z',
        geonameid: 2316308,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17370',
      geometry: { type: 'Point', coordinates: [29.05378056, -1.95560278] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nkora',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -1.9556,
        longitude: 29.05378,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-27T07:37:50.749Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17388',
      geometry: { type: 'Point', coordinates: [19.23364, -4.47108] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kamtsha',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'approximate',
        latitude: -4.47108,
        longitude: 19.23364,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-28T06:45:04.644Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17392',
      geometry: { type: 'Point', coordinates: [29.04511171, -1.96974505] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kagobe',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -1.96975,
        longitude: 29.04511,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-28T06:54:45.015Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17397',
      geometry: { type: 'Point', coordinates: [29.05596239, -2.05164755] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kirabo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -2.05165,
        longitude: 29.05596,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-28T07:14:02.169Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17398',
      geometry: { type: 'Point', coordinates: [29.09809444, -1.94136111] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nsulo',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'approximate',
        latitude: -1.94136,
        longitude: 29.09809,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-28T07:18:49.828Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17400',
      geometry: { type: 'Point', coordinates: [29.01789872, -2.15710783] },
      geometry_name: 'shape',
      properties: {
        portname: 'Kihara',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -2.15711,
        longitude: 29.0179,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-28T07:23:36.812Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17403',
      geometry: { type: 'Point', coordinates: [18.60389, -4.52965] },
      geometry_name: 'shape',
      properties: {
        portname: 'Mazinga',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -4.52965,
        longitude: 18.60389,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-28T07:28:25.081Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17406',
      geometry: { type: 'Point', coordinates: [16.1990094, -3.17900982] },
      geometry_name: 'shape',
      properties: {
        portname: 'Malela',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'No',
        locprecision: 'accurate',
        latitude: -3.17901,
        longitude: 16.19901,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-28T07:33:09.497Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17536',
      geometry: { type: 'Point', coordinates: [17.4, -5.83] },
      geometry_name: 'shape',
      properties: {
        portname: null,
        code: null,
        prttype: 'Unknown',
        prtsize: 'Unknown',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'unknown',
        latitude: -5.83,
        longitude: 17.4,
        iso3: 'COD',
        iso3_op: 'COD',
        country: 'Democratic Republic of the Congo',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: null,
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-02-28T07:37:51.790Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17844',
      geometry: { type: 'Point', coordinates: [30.24384317, 8.68357429] },
      geometry_name: 'shape',
      properties: {
        portname: 'Nyaroump',
        code: null,
        prttype: 'River',
        prtsize: 'Very Small',
        status: 'Unknown',
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: 'Unknown',
        locprecision: 'accurate',
        latitude: 8.68357,
        longitude: 30.24384,
        iso3: 'SSD',
        iso3_op: 'SSD',
        country: 'South Sudan',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'CO',
        createdate: '2021-02-24T11:54:56.019Z',
        updatedate: '2021-03-01T06:49:48.221Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17893',
      geometry: { type: 'Point', coordinates: [35.55244881, 33.89812884] },
      geometry_name: 'shape',
      properties: {
        portname: 'Ed Daoura Fuel Oil Port',
        code: null,
        prttype: 'sea',
        prtsize: 'small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 33.89813,
        longitude: 35.55245,
        iso3: 'LBN',
        iso3_op: null,
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'WFP CO',
        createdate: '2021-12-23T12:18:13.643Z',
        updatedate: '2021-12-24T06:49:51.264Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17887',
      geometry: { type: 'Point', coordinates: [35.81286068, 34.45520994] },
      geometry_name: 'shape',
      properties: {
        portname: 'Tripoli Fishing Port',
        code: null,
        prttype: 'sea',
        prtsize: 'small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 34.45521,
        longitude: 35.81286,
        iso3: 'LBN',
        iso3_op: null,
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'WFP CO',
        createdate: '2021-12-23T12:18:13.643Z',
        updatedate: '2021-12-24T06:54:42.318Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17888',
      geometry: { type: 'Point', coordinates: [35.58868302, 33.94409927] },
      geometry_name: 'shape',
      properties: {
        portname: 'Dbaiye 2 Port',
        code: null,
        prttype: 'sea',
        prtsize: 'small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 33.9441,
        longitude: 35.58868,
        iso3: 'LBN',
        iso3_op: null,
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'WFP CO',
        createdate: '2021-12-23T12:18:13.643Z',
        updatedate: '2021-12-24T06:59:42.951Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17889',
      geometry: { type: 'Point', coordinates: [35.36527383, 33.55656152] },
      geometry_name: 'shape',
      properties: {
        portname: 'Saida Commercial Port',
        code: null,
        prttype: 'sea',
        prtsize: 'small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 33.55656,
        longitude: 35.36527,
        iso3: 'LBN',
        iso3_op: null,
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'WFP CO',
        createdate: '2021-12-23T12:18:13.643Z',
        updatedate: '2021-12-24T07:04:28.843Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17890',
      geometry: { type: 'Point', coordinates: [35.19795891, 33.27381918] },
      geometry_name: 'shape',
      properties: {
        portname: 'Sour Commercial Port',
        code: null,
        prttype: 'sea',
        prtsize: 'small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 33.27382,
        longitude: 35.19796,
        iso3: 'LBN',
        iso3_op: null,
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'WFP CO',
        createdate: '2021-12-23T12:18:13.643Z',
        updatedate: '2021-12-24T07:09:13.126Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17891',
      geometry: { type: 'Point', coordinates: [35.63044118, 34.15433912] },
      geometry_name: 'shape',
      properties: {
        portname: 'Aamshite Fuel Oil Port',
        code: null,
        prttype: 'sea',
        prtsize: 'small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 34.15434,
        longitude: 35.63044,
        iso3: 'LBN',
        iso3_op: null,
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'WFP CO',
        createdate: '2021-12-23T12:18:13.643Z',
        updatedate: '2021-12-24T07:13:58.906Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
    {
      type: 'Feature',
      id: 'wld_trs_ports_wfp.17892',
      geometry: { type: 'Point', coordinates: [35.72544502, 34.32934406] },
      geometry_name: 'shape',
      properties: {
        portname: 'Chekka Fuel Oil Port',
        code: null,
        prttype: 'sea',
        prtsize: 'small',
        status: null,
        maxdepth: null,
        maxlength: null,
        annualcapacitymt: null,
        humuse: null,
        locprecision: 'accurate',
        latitude: 34.32934,
        longitude: 35.72545,
        iso3: 'LBN',
        iso3_op: null,
        country: 'Lebanon',
        lastcheckdate: null,
        remarks: null,
        url_lca: null,
        source: 'WFP CO',
        createdate: '2021-12-23T12:18:13.643Z',
        updatedate: '2021-12-24T07:18:45.752Z',
        geonameid: 999999999,
        gdb_geomattr_data: null,
      },
    },
  ],
  totalFeatures: 3582,
  numberMatched: 3582,
  numberReturned: 3582,
  timeStamp: '2022-03-25T07:05:14.822Z',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:EPSG::4326' } },
};
