import React, { memo, useContext } from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { GeoJSON } from 'react-leaflet';
import L from 'leaflet';

import { TheiaContext } from '../../Theia';

import { convertArrToGeojson, pointToLayer, getShipDetails } from '../../utils';

const SpoofingDetections = ({ spoofingDetections }) => {
  const { setHaveShipInfo, selectedShips, setShowPathPredictionDialog,setSelectedShips, setSpoofingTimelineShips, setSatelliteData,setCurrentLocationData, setTileLayerUrl, selectedPolygons, setPathInterpolationData, setViewPathInterpolation, setViewSatelliteImage, setShowSpinner, setIsSnackbarOpen, colorArr, setColorArr, setSelectShipWaiting, setSatelliteTimelineData } = useContext(TheiaContext);

  const onEachFeaturePoint = (feature, layer) => {
    layer.on({
      click: async function (e) {
        setSatelliteData({geojson:null, key: parseInt(Math.random() * 10000)})
        setSatelliteTimelineData({ geojson: null, key: parseInt(Math.random() * 10000) })
        setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
        setSelectShipWaiting(true)
        setTileLayerUrl(null)
        setPathInterpolationData(null)
        setViewSatelliteImage(false)
        setViewPathInterpolation(false)
        setSpoofingTimelineShips([])
        setShowPathPredictionDialog(false)
        getShipDetails(feature, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, 'pink')
      }
    });
  }

  let shipIndex = 0

  if(selectedShips.length > 0) {
    shipIndex = selectedShips[selectedShips.length - 1].shipIndex
  }

  if(selectedShips.length > 0 && selectedShips[selectedShips.length - 1].path) {
    shipIndex = shipIndex + selectedShips[selectedShips.length - 1].path.length
  }
  return (
    <>
      {spoofingDetections.ships.length > 0 && (
        <MarkerClusterGroup
          disableClusteringAtZoom={9}
          maxClusterRadius={40}
          iconCreateFunction={function (cluster) {
            return L.divIcon({
              html: cluster.getChildCount(),
              className: 'pinkCluster',
              // iconSize: L.point(40, 40),
            });
          }}
        >
          <GeoJSON
            key={'pinkShips' + spoofingDetections.key + selectedShips.length + selectedPolygons.length+ shipIndex}
            data={convertArrToGeojson(spoofingDetections.ships)}
            pointToLayer={(feature, latlng) => pointToLayer(feature, latlng, 'pink')}
            onEachFeature={onEachFeaturePoint}
          />
        </MarkerClusterGroup>
      )}
    </>
  )
}

export default memo(SpoofingDetections);
