import React from 'react';
import L from 'leaflet';
import axios from 'axios';

import { getRandomAngleUsingLatLng, getSatelliteImageRequest } from '../../utils';
import RotatedMarker from './RotatedMarker';

const ObservedShips = ({
  observedShips,
  isBlueShips,
  selectedShips,
  setSelectedShips,
  shipKey,
  setShipImgArr,
  shipCategory
}) => {
  return (
    <>
      {renderObservedShips(
        observedShips,
        isBlueShips,
        setSelectedShips,
        selectedShips,
        shipKey,
        shipCategory,
        setShipImgArr
      )}
    </>
  );
};

const renderObservedShips = (
  observedShips,
  isBlueShips,
  setSelectedShips,
  selectedShips,
  shipKey,
  shipCategory,
  setShipImgArr
) => {
  const detectedShips = [];
  console.log(observedShips, "observations")
  observedShips?.map((ship, i) => {
    let shipLength = ship.length;
    const { latitude: lat, longitude: lon, dark } = ship;
    const coordinates = [lat, lon];
    let iconSize = [25, 25];

    if ('a' in ship || 'o' in ship || 'f' in ship) {
      iconSize = [35, 35]
    } 

    if ("bunkeringStatus" in ship && ship.bunkeringStatus === "Ais_STS") {
      iconSize = [50, 30]
    } 
    
    if ("ship1_dark" in ship || "ship2_dark" in ship ) {
      iconSize = [50, 30]
    }

    if (shipLength < 30) {
      iconSize = [20, 20];
    }
    if (shipLength > 60) {
      iconSize = [30, 30];
    }

    let iconUrl = './blueEyeFilled.svg'
    console.log(shipCategory, ':shipCategory')

    if (dark) {
      iconUrl = './orangeEyeFilled.svg'
    }
    
    if ('a' in ship || 'o' in ship || 'f' in ship) {
      iconUrl = "./seethroughPinky.svg"
    } 

    if ("bunkeringStatus" in ship && ship.bunkeringStatus === "Ais_STS") {
      iconUrl = "./aisBunkering.svg"
    }

    if (ship?.ship1_dark === false && ship?.ship2_dark === null) {
      iconUrl = "./blueRedBunkering.svg"
    } else if (ship?.ship1_dark === null && ship?.ship2_dark === false) {
      iconUrl = "./redBlueBunkering.svg"
    } else if (ship?.ship1_dark === false && ship?.ship2_dark === false) {
      iconUrl = "./blueBlueBunkering.svg"
    } else if (ship?.ship1_dark === true && ship?.ship2_dark === null) {
      iconUrl = "./orangeRedBunkering.svg"
    } else if (ship?.ship1_dark === null && ship?.ship2_dark === true) {
      iconUrl = "./redOrangeBunkering.svg"
    } else if (ship?.ship1_dark === true && ship?.ship2_dark === true) {
      iconUrl = "./orangeOrangeBunkering.svg"
    } else if (ship?.ship1_dark === false && ship?.ship2_dark === true) {
      iconUrl = "./blueOrangeBunkering.svg"
    } else if (ship?.ship1_dark === true && ship?.ship2_dark === false) {
      iconUrl = "./orangeBlueBunkering.svg"
    } 

    var eyeBoatIcon = L.icon({
      iconUrl,
      iconSize,
    });
    let heading = ship.heading;
    if (heading === 9999) {
      heading = getRandomAngleUsingLatLng(lat, lon);
    }
    detectedShips.push(
      <RotatedMarker
        position={coordinates}
        icon={eyeBoatIcon}
        key={i + selectedShips.length}
        rotationAngle={heading / 2}
        onClick={() => {
          const selectedShip = selectedShips.filter((ship) => ship.key === shipKey)[0];

          const updatedShip = {
            ...selectedShip,
            initialData: {
              ...selectedShip.initialData,
              properties: {
                // ...selectedShip.initialData.properties,
                // acquired: ship.acquired,
                // dark: ship.dark,
                // heading: ship.heading,
                // latitude: ship.latitude,
                // longitude: ship.longitude,
                // object_id: ship.object_id
                ...ship
              }
            }
          }

            let index = selectedShips
              .map(function (e) {
                return e.key;
              })
              .indexOf(shipKey);

          console.log(index, "index", [...selectedShips.slice(1), updatedShip], updatedShip, selectedShips)
            let newArr = [];
            if (index === 0) {
              newArr = [...selectedShips.slice(1), updatedShip];
            } else {
              newArr = [
                ...selectedShips.slice(0, index),
                ...selectedShips.slice(index + 1),
                updatedShip,
              ];
            }
          setSelectedShips(newArr);
          }
        }
        // onClick={async (e) => {
        //   const res = await axios.get('/shipPic', {
        //     params: {
        //       imagePath: object_id,
        //     },
        //     headers: { Authorization: 'Bearer ' + token },
        //   });
        //   if (res.data) {
        //     setSatelliteImages([res.data]);
        //   } else {
        //     setSatelliteImages([]);
        //   }
        //   const selectedShip = selectedShips.filter((ship) => ship.key === shipKey)[0];
        //   const updatedShip = {
        //     ...selectedShip,
        //     details: {
        //       ...selectedShip.details,
        //       newHeading: heading,
        //       newLen: shipLength,
        //       newLocs: [lon, lat],
        //       newDates: timestamp,
        //       observed: true,
        //       object_id,
        //     },
        //   };
        //
        //   var index = selectedShips
        //     .map(function (e) {
        //       return e.key;
        //     })
        //     .indexOf(shipKey);
        //   let newArr = [];
        //   if (index === 0) {
        //     newArr = [...selectedShips.slice(1), updatedShip];
        //   } else {
        //     newArr = [
        //       ...selectedShips.slice(0, index),
        //       ...selectedShips.slice(index + 1),
        //       updatedShip,
        //     ];
        //   }
        //   setSelectedShips(newArr);
        //   setShipImgArr(selectedShip.imageUrls);

        //   return setSelectedShips(newArr);
        // }}
      ></RotatedMarker>
    );
  });
  return detectedShips;
};

export default ObservedShips;
