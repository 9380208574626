import React, { useState, useContext, useMemo, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import cn from "classnames";
import { TheiaContext } from "../Theia";
import FilterDataManager from "./FilterDataManager";
import { TheiaWorkspace } from '../TheiaWorkspace'
import styles from "./dataManager.module.css";
import { countryCodes } from './countryCodes'

export const DataManager = () => {
  const { filters, haveShipsSelected, sanctionedShipsPositionData, bunkeringData, aisBunkeringData, aisShips, similarDetections, setUploadedGejson, AisSpoofingShips, footprintShips, spoofingDetections,uploadedGeojsons,uploadedCSVs,setUploadedCSVs,darkShipDetections, blueShipDetections,setIsFiltersChanged, orangeShipDetections, setFilters, switches, setSwitches, setPurpleShipVisible, purpleShipVisible } = useContext(TheiaContext);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [isFilterOpen, setIsFilterOpen] = React.useState("");
  const [flag, setFlag] = React.useState({ selectAIS: "China" });

  useEffect(() => {
    console.log(filters, ':filters==>')
  },[filters])

  const handleFlagChange = (data) => {
    setFlag(data);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const toggleOpen = () => {
    setOpen((prevState) => !prevState);
      };

  const toggleFilter = (name) => {
    setIsFilterOpen((prevState) => {
      if (prevState === name) {
        return "";
      }

      return name;
    });
  };

  const onChangeFilter = (data) => {
    console.log(data, ':data')
    setIsFiltersChanged(true)
    setFilters((prevValues) => {
      return { ...prevValues, ...data };
    });
  };

  const setSwitchValue = (key) => {
    setSwitches((prevState) => {
      return {
        ...prevState,
        [key]: !prevState[key],
      };
    });
  };

  const filterManager = useMemo(
    () => [
      {
        id: 1,
        filterName: "ais",
        iconShip: "./greenShipFilled.svg",
        toggleFilter,
        onChangeFilter,
        dataCheckbox: {
          colorLabel: "#148000",
          label: "AIS",
          subLabel: (filters.ais && filters.bunkeringAis ? aisBunkeringData.events.length : 0) +  aisShips.ships.length,
          value: filters.ais,
        },
        dataSlider: {
          name: "lengthAis",
          value: filters.lengthAis,
          maxValue: 500,
        },
        widthSlider: {
          name: "widthAis",
          value: filters.widthAis,
          maxValue: 100,
        },
        typePicker: {
          name: "typeAis",
          value: filters.typeAis
        },
        countryPicker: {
          name: "countryAis",
          value: filters.countryAis
        },
        dataSelect: null,
        noImoShips: {
          name: 'noImoAis',
          value: filters.noImoAis
        },
        bunkering: {
          name: 'bunkeringAis',
          value: filters.bunkeringAis
        },
        sanctionedAis: {
          name: 'sanctionedAis',
          value: filters.sanctionedAis
        },
        showDropdown: true
      },
      {
        id: 2,
        filterName: "light",
        iconShip: "./blueEyeFilled.svg",
        showDropdown: true,
        toggleFilter,
        onChangeFilter,
        dataCheckbox: {
          colorLabel: "#25a0ca",
          label: "Light",
          subLabel: blueShipDetections.ships.length,
          value: filters.light,
        },
        dataSlider: {
          name: "lengthLight",
          value: filters.lengthLight,
          maxValue: 500,
        },
        widthSlider: {
          name: "widthLight",
          value: filters.widthLight,
          maxValue: 100,
        },
        typePicker: {
          name: "typeLight",
          value: filters.typeLight
        },
        countryPicker: {
          name: "countryLight",
          value: filters.countryLight
        },
        dataSelect: null,
        noImoShips: {
          name: 'noImoLight',
          value: filters.noImoLight
        },
        bunkering: {
          name: 'bunkeringLight',
          value: filters.bunkeringLight
        },
        sanctionedLight: {
          name: 'sanctionedLight',
          value: filters.sanctionedLight
        },
        sentinel1: {
          name: 'sentinel1Light',
          value: filters.sentinel1Light
        },
        sentinel2: {
          name: 'sentinel2Light',
          value: filters.sentinel2Light
        },
        planetScope: {
          name: 'planetScopeLight',
          value: filters.planetScopeLight
        },
      },
      {
        id: 3,
        filterName: "dark",
        showDropdown: true,
        iconShip: "./orangeEyeFilled.svg",
        toggleFilter,
        onChangeFilter,
        dataCheckbox: {
          colorLabel: "#fba501",
          label: "Dark",
          subLabel: orangeShipDetections.ships.length,
          value: filters.dark,
        },
        dataSlider: {
          name: "lengthDark",
          value: filters.lengthDark,
          maxValue: 500,
        },
        widthSlider: {
          name: "widthDark",
          value: filters.widthDark,
          maxValue: 100,
        },
        typePicker: {
          name: "typeDark",
          value: filters.typeDark
        },
        countryPicker: {
          name: "countryDark",
          value: filters.countryDark
        },
        dataSelect: null,
        noImoShips: {
          name: 'noImoDark',
          value: filters.noImoDark
        },
        bunkering: {
          name: 'bunkeringOrange',
          value: filters.bunkeringOrange
        },
        sanctionedDark: {
          name: 'sanctionedDark',
          value: filters.sanctionedDark
        },
        sentinel1: {
          name: 'sentinel1Dark',
          value: filters.sentinel1Dark
        },
        sentinel2: {
          name: 'sentinel2Dark',
          value: filters.sentinel2Dark
        },
        planetScope: {
          name: 'planetScopeDark',
          value: filters.planetScopeDark
        },
      },
      {
        id: 4,
        filterName: "unknown",
        showDropdown: true,
        iconShip: "./redEyeFilled.svg",
        toggleFilter,
        onChangeFilter,
        dataCheckbox: {
          colorLabel: "#f85348",
          label: "Unattributed",
          subLabel: darkShipDetections.ships.length,
          value: filters.unknown,
        },
        dataSlider: {
          name: "lengthUnknown",
          value: filters.lengthUnknown,
          maxValue: 500,
        },
        widthSlider: null,
        dataSelect: null,
        countryPicker: null,
        typePicker: false,
        bunkering: {
          name: 'bunkeringUnknown',
          value: filters.bunkeringUnknown
        },
        sentinel1: {
          name: 'sentinel1Unknown',
          value: filters.sentinel1Unknown
        },
        sentinel2: {
          name: 'sentinel2Unknown',
          value: filters.sentinel2Unknown
        },
        planetScope: {
          name: 'planetScopeUnknown',
          value: filters.planetScopeUnknown
        },
      },
      {
        id: 5,
        showDropdown: true,
        filterName: "spoofing",
        iconShip: "./seethroughPinky.svg",
        toggleFilter,
        onChangeFilter,
        dataCheckbox: {
          colorLabel: "#f092dd",
          label: "Spoofing Events",
          subLabel: (filters.opticalSpoofing ? spoofingDetections.ships.length : 0) + (filters.footprintSpoofing ? footprintShips.ships.length : 0) + (filters.aisSpoofing ? AisSpoofingShips.ships.length : 0) ,
          value: filters.spoofing,
        },
        dataSlider: {
          name: "lengthSpoofing",
          value: filters.lengthSpoofing,
          maxValue: 500,
        },
        widthSlider: {
          name: "widthSpoofing",
          value: filters.widthSpoofing,
          maxValue: 100,
        },
        typePicker: {
          name: "typeSpoofing",
          value: filters.typeSpoofing
        },
        countryPicker: {
          name: "countrySpoofing",
          value: filters.countrySpoofing
        },
        noImoShips: {
          name: 'noImoSpoofing',
          value: filters.noImoSpoofing
        },
        aisSpoofing : {
          name: 'aisSpoofing',
          value:filters.aisSpoofing
        },
        opticalSpoofing : {
          name: 'opticalSpoofing',
          value:filters.opticalSpoofing
        },
        footprintSpoofing : {
          name: 'footprintSpoofing',
          value:filters.footprintSpoofing
        },
        dataSelect: null,
      },
      {
        id: 6,
        filterName: "decoupleBunkering",
        iconShip: "./bunkeringIcon.svg",
        toggleFilter,
        onChangeFilter,
        dataCheckbox: {
          colorLabel: "#333",
          label: "Bunkering Events",
          subLabel: bunkeringData.events.length + (filters.ais && filters.bunkeringAis ? aisBunkeringData.events.length : 0),
          value: filters.decoupleBunkering,
        },
        dataSlider: null,
        widthSlider: null,
        dataSelect: null,
        countryPicker: null,
        typePicker: false
      },
      {
        id: 7,
        filterName: "similarShips",
        iconShip: "./synmax-logo.svg",
        toggleFilter,
        onChangeFilter,
        dataCheckbox: {
          colorLabel: "#f092dd",
          label: "Similar Ships",
          subLabel: similarDetections?.length ,
          value: filters.similarShips,
        }
      },
      {
        id: 8,
        filterName: "sanctionedShips",
        iconShip: "./sanctionedShip.svg",
        toggleFilter,
        onChangeFilter,
        dataCheckbox: {
          colorLabel: "#f5528a",
          label: "Sanctioned Ships",
          subLabel: sanctionedShipsPositionData?.length,
          value: filters.sanctionedShips,
        }
      },
    ],
    [aisShips, darkShipDetections, sanctionedShipsPositionData, blueShipDetections, orangeShipDetections, spoofingDetections,footprintShips, similarDetections, bunkeringData, aisBunkeringData, JSON.stringify(filters), flag.selectAIS]
  );

  const filterLayers = useMemo(
    () => [
      {
        id: "layer1",
        label: "Territories",
        value: switches.territories,
        onChange: () => setSwitchValue("territories"),
      },
      {
        id: "layer2",
        label: "Ports",
        value: switches.ports,
        onChange: () => setSwitchValue("ports"),
      },
      {
        id: "layer3",
        label: "Infrastructure",
        value: switches.infrastructure,
        onChange: () => setSwitchValue("infrastructure"),
      },
      {
        id: "layer4",
        label: "Submarine Cables",
        value: switches.submarineCablesVisible,
        onChange: () => setSwitchValue("submarineCablesVisible"),
      },
      // {
      //   id: "layer5",
      //   label: "Heatmap",
      //   value: switches.heatmap,
      //   onChange: () => setSwitchValue("heatmap"),
      // },
      {
        id: "layer6",
        label: "Coverage",
        value: switches.coverage,
        onChange: () => setSwitchValue("coverage"),
      },
      {
        id: "layer7",
        label: "Show Cluster",
        value: switches.showCluster,
        onChange: () => setSwitchValue("showCluster"),
      },
      {
        id: "layer8",
        label: "JWC Listed Areas",
        value: switches.highRisk,
        onChange: () => setSwitchValue("highRisk"),
      },
      ...uploadedGeojsons.sort(compare).map(({name, isVisible, uId}, i) => ({id: "layer" + i +6, label: name, value:isVisible, onChange: () => {
      setUploadedGejson([...uploadedGeojsons.filter(item => item.uId !== uId), {...uploadedGeojsons.filter(item => item.uId === uId)[0], isVisible: !isVisible} ])
      }})),
      ...uploadedCSVs.sort(compare).map(({name, isVisible, uId}, i) => ({id: "layer" + i +6 + uploadedGeojsons.length, label: name, value:isVisible, onChange: () => {
        setUploadedCSVs([...uploadedCSVs.filter(item => item.uId !== uId), {...uploadedCSVs.filter(item => item.uId === uId)[0], isVisible: !isVisible} ])
        }}))
    ],
    [JSON.stringify(switches), uploadedGeojsons, uploadedCSVs]
  );

  return (
    <div className={cn(styles.dataManager, open && styles.dataManagerOpen )}>
      <IconButton className={styles.dataManagerBtn} color="primary" aria-label="add to shopping cart" onClick={toggleOpen} size="small">
        <MenuIcon />
      </IconButton>
      {open && (
        <div className={styles.wrapper}>
          <Accordion className={styles.dataManagerAccordion} expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography>Data Manager</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {filterManager.map((item) => {
                return (
                  <FilterDataManager
                    key={item.id}
                    iconShip={item.iconShip}
                    isFilterOpen={isFilterOpen}
                    filterName={item.filterName}
                    toggleFilter={item.toggleFilter}
                    onChangeFilter={onChangeFilter}
                    dataCheckbox={item.dataCheckbox}
                    showDropdown={item.showDropdown}
                    dataSlider={item.dataSlider}
                    widthSlider={item.widthSlider}
                    dataSelect={item.dataSelect}
                    countryPicker={item.countryPicker}
                    typePicker={item.typePicker}
                    purpleShipVisible={purpleShipVisible}
                    setPurpleShipVisible={setPurpleShipVisible}
                    noImoShips={item.noImoShips}
                    bunkering={item.bunkering}
                    footprintSpoofing={item.footprintSpoofing}
                    opticalSpoofing={item.opticalSpoofing}
                    aisSpoofing={item.aisSpoofing}
                    sanctionedLight={item.sanctionedLight}
                    sanctionedDark={item.sanctionedDark}
                    sanctionedAis={item.sanctionedAis}
                    sentinel1={item.sentinel1}
                    sentinel2={item.sentinel2}
                    planetScope={item.planetScope}
                  />
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion className={styles.dataManagerAccordion} expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
              <Typography>Layers</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {filterLayers.map((item) => (
                <FormControlLabel
                  key={item.id}
                  className={styles.layerRow}
                  control={<Checkbox checked={item.value} onChange={item.onChange} />}
                  label={item.label}
                />
              ))}
 
            </AccordionDetails>
          </Accordion>
          
          <Accordion className={styles.dataManagerAccordion} expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
              <Typography>Workspace</Typography>
            </AccordionSummary>
            <AccordionDetails>
            {haveShipsSelected && <TheiaWorkspace />}
            </AccordionDetails>
          </Accordion>
          {/* <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
              <Typography>Alerts</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>-</Typography>
            </AccordionDetails>
          </Accordion> */}
         
        </div>
      )}
    </div>
  );
};


function compare( a, b ) {
  if ( a.uId < b.uId ){
    return -1;
  }
  if ( a.uId > b.uId ){
    return 1;
  }
  return 0;
}
