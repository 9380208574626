// Download Map table data
import React, { useEffect, useState } from 'react'

import XLSX from 'xlsx';
import { TheiaContext } from '../../Theia';
import moment from 'moment';


const DownloadXLS = ({greenShips, darkShips, orangeShip, blueShips}) => {

    const downloadXLSForTimeseries = () => {


        const updatedAisData = greenShips.ships.map((f) => {
            return { ...f, timestamp : `${moment(new Date(f.timestamp * 1000)).format('YYYY-MM-DD HH:mm:ss')}` }
          });

        const blueShipSheet = XLSX.utils.json_to_sheet(blueShips.ships);
        const orangeShipSheet = XLSX.utils.json_to_sheet(orangeShip.ships);
        const darkShipSheet = XLSX.utils.json_to_sheet(darkShips.ships);
        const aisShipSheet = XLSX.utils.json_to_sheet(updatedAisData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, blueShipSheet, 'Light Ship;');
        XLSX.utils.book_append_sheet(workBook, orangeShipSheet, 'Dark');
        XLSX.utils.book_append_sheet(workBook, darkShipSheet, 'Unattributed');
        XLSX.utils.book_append_sheet(workBook, aisShipSheet, 'Ais');

        XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
        XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
        XLSX.writeFile(workBook, 'PolygonShipData.xlsx');
    }

    return (
        <>
            <button
                className='downloadXLSButton' onClick={downloadXLSForTimeseries} >Download XLS</button>
        </>
    )
}

export default DownloadXLS