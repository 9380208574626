import React, { useEffect, useState, useContext } from 'react'
import { Slider } from '@mui/material';

import { TheiaContext } from '../Theia'
import { toHoursAndMinutes } from '../utils'
import { futurePathData } from '../LeafletMap/FuturePathPrediction/aaronData'

import './futurePathPredictionSlider.scss'
import Draggable from 'react-draggable';

const FuturePathPredictionSlider = () => {
    const {futurePathObj, setFuturePathObj, pathPredictionData, setZindex, zIndex} = useContext(TheiaContext);
    const [mainSliderValue, setMainSliderValue] = useState(10)
    const [FuturePathSliderPosition, setFuturePathSliderPosition] = useState({
      clientX: null,
      clientY: null
    })

    useEffect(() => {
        // later user pathPredictionData not futurePathData.futures
        // const tempdata = 
        console.log(pathPredictionData, ':pathPredictionData')
        let pathPredictionDataArr = pathPredictionData.map(dataPoint => ({...dataPoint, future_seconds: parseInt(dataPoint.future_seconds)}))
        setFuturePathObj(pathPredictionDataArr[mainSliderValue])
    },[mainSliderValue])

    const getLabel = (value) => {
        if (futurePathObj) {
          const {h,m,s} = toHoursAndMinutes(futurePathObj.future_seconds);
          return `${h}:${m}:${s} (HH:MM:SS)`
        } else {
          return 'No Data';
        }
      };

      const futurePathSliderHandler = (_, data) => {
        setFuturePathSliderPosition({
          clientX: data.x,
          clientY: data.y
        })
      }

    return (
      <Draggable
          axis="both"
          handle=".handle"
          defaultPosition={{ x: 0, y: 0 }}
          cancel="div.futurePathSlider"
          position={{ x: FuturePathSliderPosition.clientX, y: FuturePathSliderPosition.clientY }}
          onDrag={futurePathSliderHandler}
          bounds={{ right: 0, bottom: 0 }}
          onStart={() => setZindex({
            dropPin: false,
            pathSlider: false,
            attribution: false,
            satelliteImage: false,
            extendedPath: false,
            polygonSlider: false,
            polygonDataTable:false,
            futurePathModal: false,
            futurePathSlider: true,
            spoofingTimelineSlider : false,
            portsOfCallSlider: false,
          })}
      >
            <div className={`${zIndex.futurePathSlider ? 'zindex-true' : 'zindex-false'} handle futurePathPredictionSlider`} >
            {futurePathObj && (
              <div>
                <div>
                  <p className="futurePathText">Future Path Slider</p>
                </div>
                <div className='futurePathSlider'>
                  <Slider
                      size="medium"
                      max={142}
                      value={mainSliderValue}
                      valueLabelFormat={getLabel}
                      min={0}
                      aria-label="Blue"
                      valueLabelDisplay="on"
                      onChange={(e, val) => setMainSliderValue(val)}
                      style={{
                        color: '#24A0CA',
                      }}
                      />
                  </div>
                </div>
            )}    
            </div>
        </Draggable>
    )
}

function compare( a, b ) {
    if ( a.future_seconds < b.future_seconds ){
      return -1;
    }
    if ( a.future_seconds > b.future_seconds ){
      return 1;
    }
    return 0;
  }
  

export default FuturePathPredictionSlider