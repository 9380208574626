import React, { useContext, useEffect, useRef } from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { GeoJSON } from 'react-leaflet';
import L from 'leaflet';

import { TheiaContext } from '../../Theia';
import { convertArrToGeojson, pointToLayer, getUniqueColor } from '../../utils';

const DarkDetections = ({darkShipDetections}) => {
  const { setHaveShipInfo, selectedShips,setSelectedShips, setShowPathPredictionDialog, setSpoofingTimelineShips, deselectedShipsObjectIds, colorArr,selectedPolygons, setPathInterpolationData,setViewPathInterpolation,setViewSatelliteImage, setTileLayerUrl,setColorArr, setSelectShipWaiting } = useContext(TheiaContext);


  const onEachFeaturePoint = (feature, layer) => {
    layer.on({
      click: async function (e) {
        setSelectShipWaiting(true)
        let shipIndex = 1;
        if (selectedShips.length > 0) {
          let highestValue = 0
          selectedShips.forEach(ship => {
            if (ship.shipIndex > highestValue) {
              highestValue = ship.shipIndex
            }
          })
          shipIndex = highestValue + 1
        }
        setTileLayerUrl(null)
        setViewSatelliteImage(false)
        setPathInterpolationData(null)
        setViewPathInterpolation(false)
        setShowPathPredictionDialog(false)
        console.log('redfeature;', {shipCategory: 'red',initialData: {...feature, isClicked: true}, shipIndex, visible: true, pathVisible: true, shipData: {...feature.properties, name: 'Dark Ship'}, showCircle: true, path: [], color: getUniqueColor(colorArr, setColorArr)})
        setSelectedShips([...selectedShips, {shipCategory: 'red',initialData: {...feature, isClicked: true}, shipIndex, visible: true, pathVisible: true, shipData: {...feature.properties, name: 'Dark Ship'}, showCircle: true, path: [], color: getUniqueColor(colorArr, setColorArr)}])
        setHaveShipInfo(true)
        setSelectShipWaiting(false)
        setSpoofingTimelineShips([])
      },
      mouseover: () => {
        console.log(feature, 'feature')
        // const {destination, name} = feature.properties;
        // layer.bindPopup(
        //   `<div>
        //       <div>Destination: ${destination}</div>
        //       <div>Name: ${name}</div>
        //   </div>`);
        // layer.openPopup()
      },
      mouseout: () => {
        layer.closePopup()
      }
    });
  }

  let shipIndex = 0

  if(selectedShips.length > 0) {
    shipIndex = selectedShips[selectedShips.length - 1].shipIndex
  }

  if(selectedShips.length > 0 && selectedShips[selectedShips.length - 1].path) {
    shipIndex = shipIndex + selectedShips[selectedShips.length - 1].path.length
  }
  
  return (
    <>
        {darkShipDetections.ships.length > 0 && <MarkerClusterGroup
          disableClusteringAtZoom={9}
          maxClusterRadius={40}
          iconCreateFunction={function (cluster) {
            return L.divIcon({
              html: cluster.getChildCount(),
              className: 'redcluster',
              // iconSize: L.point(40, 40),
            });
          }}
        >
           <GeoJSON
            key={'darkShips' + darkShipDetections.key + selectedShips.length + selectedPolygons.length + deselectedShipsObjectIds.length+ shipIndex}
            data={convertArrToGeojson(darkShipDetections.ships)}
            pointToLayer={(feature, latlng) => pointToLayer(feature, latlng, 'red')}
            onEachFeature={onEachFeaturePoint}
          />
        </MarkerClusterGroup>}
    </>
  )
}

export default DarkDetections
