import React, {
  useEffect,
  memo,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { CircleMarker, Popup, Marker } from "react-leaflet";
import L from "leaflet";
import moment from "moment";
import useSupercluster from "use-supercluster";

import { TheiaContext } from "../../Theia";
import { PolylineDecorator, formatPathData } from "./PolylineDecorator";

const icons = {};
const fetchIcon = (count, size, color) => {
  if (!icons[count]) {
    icons[count] = L.divIcon({
      html: `<div class="cluster-marker" style="width: ${size}px; height: ${size}px; background:${color}">
        ${count}
      </div>`,
    });
  }
  return icons[count];
};

const SuperCluster = ({
  allSpoofPointsFlat,
  index,
  refreshKey,
  pathData,
  viewAnalyzePath,
  allPathPoints,
  color,
  allSpoofingPoints,
}) => {
  const { mapRef, switches } = useContext(TheiaContext);
  const [bounds, setBounds] = useState([0, 55, 0, 0]);
  const [zoom, setZoom] = useState(13);
  const clusterZoom = useMemo(() => (switches.showCluster ? 18 : 0), [switches.showCluster]);

  const updateMap = useCallback(() => {
    const b = mapRef.current.leafletElement.getBounds();
    setBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat,
    ]);
    setZoom(mapRef.current.leafletElement.getZoom());
  }, [mapRef]);

  useEffect(() => {
    updateMap();
  }, [updateMap]);

  useEffect(() => {
    const map = mapRef.current.leafletElement;
    map.addEventListener("zoomend", updateMap);
    map.addEventListener("moveend", updateMap);
    return () => {
      map.removeEventListener("zoomend", updateMap);
      map.removeEventListener("moveend", updateMap);
    };
  }, [mapRef, updateMap, zoom, bounds]);

  const pathPointsFlatArr = allPathPoints.map((shipData) => {
    var pathPointsFlat = {};
    pathPointsFlat.type = "Feature";
    pathPointsFlat.properties = {
      cluster: false,
      id: shipData.synmax_ship_id,
    };
    pathPointsFlat.geometry = {
      type: "Point",
      coordinates: [shipData.longitude, shipData.latitude],
      timestamp: shipData.timestamp,
    };
    return pathPointsFlat;
  });

  const spoofPointsFlatArr = allSpoofPointsFlat.map((shipData) => {
    var spoofPointsFlat = {};
    spoofPointsFlat.type = "Feature";
    spoofPointsFlat.properties = {
      cluster: false,
      id: shipData.synmax_ship_id,
    };
    spoofPointsFlat.geometry = {
      type: "Point",
      coordinates: [shipData.longitude, shipData.latitude],
      timestamp: shipData.timestamp,
    };
    return spoofPointsFlat;
  });

  const { clusters: pathPointCluster, supercluster: pathPointSuperCluster } =
    useSupercluster({
      points: pathPointsFlatArr,
      bounds: bounds,
      zoom: zoom,
      options: { radius: 150, maxZoom: clusterZoom },
    });

  const { clusters: spoofPointCluster, supercluster: spoofPointSuperCluster } =
    useSupercluster({
      points: spoofPointsFlatArr,
      bounds: bounds,
      zoom: zoom,
      options: { radius: 150, maxZoom: clusterZoom },
    });

  if (viewAnalyzePath) {
    return (
      <>
        {pathData.length > 3000 ? (
          <div>
            {pathPointCluster.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } =
                cluster.properties;

              if (isCluster) {
                return (
                  <Marker
                    key={`cluster-${cluster.id}`}
                    position={[latitude, longitude]}
                    icon={fetchIcon(
                      pointCount,
                      10 + (pointCount / pathPointsFlatArr.length) * 40,
                      color
                    )}
                    onClick={() => {
                      updateMap();
                      const expansionZoom = Math.min(
                        pathPointSuperCluster.getClusterExpansionZoom(
                          cluster.id
                        ),
                        clusterZoom
                      );
                      const leaflet = mapRef.current.leafletElement;
                      leaflet.setView([latitude, longitude], expansionZoom, {
                        animate: true,
                      });
                    }}
                  />
                );
              }

              return (
                <CircleMarker
                  key={cluster.properties.synmax_ship_id}
                  center={{ lat: latitude, lng: longitude }}
                  radius={5}
                  color={color}
                >
                  <Popup>
                    <div>
                      Time:{" "}
                      {moment
                        .utc(cluster.geometry.timestamp * 1000)
                        .format("YYYY-MM-DD HH:mm:ss") + " UTC"}
                    </div>
                    <div>Lat: {latitude}</div>
                    <div>Lon: {longitude}</div>
                  </Popup>
                </CircleMarker>
              );
            })}
            {spoofPointCluster.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } =
                cluster.properties;

              if (isCluster) {
                return (
                  <Marker
                    key={`cluster-${cluster.id}`}
                    position={[latitude, longitude]}
                    icon={fetchIcon(
                      pointCount,
                      10 + (pointCount / spoofPointsFlatArr.length) * 40,
                      color
                    )}
                    onClick={() => {
                      updateMap();
                      const expansionZoom = Math.min(
                        spoofPointSuperCluster.getClusterExpansionZoom(
                          cluster.id
                        ),
                        clusterZoom
                      );
                      const leaflet = mapRef.current.leafletElement;
                      leaflet.setView([latitude, longitude], expansionZoom, {
                        animate: true,
                      });
                    }}
                  />
                );
              }

              return (
                <CircleMarker
                  key={cluster.properties.synmax_ship_id}
                  center={{ lat: latitude, lng: longitude }}
                  radius={5}
                  color="#F552D4"
                >
                  <Popup>
                    <div>
                      Time:{" "}
                      {moment
                        .utc(cluster.geometry.timestamp * 1000)
                        .format("YYYY-MM-DD HH:mm:ss") + " UTC"}
                    </div>
                    <div>Lat: {latitude}</div>
                    <div>Lon: {longitude}</div>
                  </Popup>
                </CircleMarker>
              );
            })}
          </div>
        ) : (
          <div>
            {allPathPoints.map(
              ({ latitude: lat, longitude: lng, timestamp: t }, i) => (
                <CircleMarker
                  key={i}
                  center={{ lat, lng }}
                  radius={5}
                  color={color}
                >
                  <Popup>
                    <div>
                      Time:{" "}
                      {moment.utc(t * 1000).format("YYYY-MM-DD HH:mm:ss") +
                        " UTC"}
                    </div>
                    <div>Lat: {lat}</div>
                    <div>Lon: {lng}</div>
                  </Popup>
                </CircleMarker>
              )
            )}
            {allSpoofingPoints.map((dataSet) =>
              dataSet.map(
                ({ latitude: lat, longitude: lng, timestamp: t }, i) => (
                  <CircleMarker
                    key={i}
                    center={{ lat, lng }}
                    radius={5}
                    color={"#F552D4"}
                  >
                    <Popup>
                      <div>
                        Time:{" "}
                        {moment.utc(t * 1000).format("YYYY-MM-DD HH:mm:ss") +
                          " UTC"}
                      </div>
                      <div>Lat: {lat}</div>
                      <div>Lon: {lng}</div>
                    </Popup>
                  </CircleMarker>
                )
              )
            )}
          </div>
        )}
      </>
    );
  } else {
    return (
      <div key={`selected-ships`}>
        <PolylineDecorator
          preferCanvas={true}
          key={`path${index + refreshKey}`}
          positions={formatPathData(pathData)}
          color={color}
          opacity={1}
        />

        {allSpoofingPoints.map((dataSet) => (
          <PolylineDecorator
            preferCanvas={true}
            positions={formatPathData(dataSet)}
            color={"#F552D4"}
            opacity={1}
          />
        ))}
      </div>
    );
  }
};

export default memo(SuperCluster);
