import React from "react";
import { Polyline, Marker, Popup } from "react-leaflet";

const RenderDarkEvent = ({ darkEventOnMap }) => {
  const { latitude_t0, latitude_t1, longitude_t0, longitude_t1 } =
    darkEventOnMap.data;
  return (
    <>
      <Marker position={[latitude_t0, longitude_t0]}>
        <Popup>Start</Popup>
      </Marker>
      <Polyline
        key={darkEventOnMap.key}
        positions={[
          [latitude_t0, longitude_t0],
          [latitude_t1, longitude_t1],
        ]}
        color="#0088FF"
        dashArray={"20, 20"}
        dashOffset="20"
        weight={3}
      />
      <Marker  position={[latitude_t1, longitude_t1]}>
        <Popup>End</Popup>
      </Marker>
    </>
  );
};

export default RenderDarkEvent;
