import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { TheiaContext } from "../../Theia";
import { convertArrToGeojson, frontendAPIURL, getShipDetails } from "../../utils";
import moment from "moment";
import { GeoJSON } from "react-leaflet";
import L from 'leaflet'

const SanctionedShips = () => {
  const { date, selectedShips,filters, allSanctionedShips, sanctionedShipsPositionData, setSanctionedShipsPositionData,setAllSanctionedShips, setSelectedShips, setShowPathPredictionDialog,setTileLayerUrl,setSatelliteData, setCurrentLocationData, setSpoofingTimelineShips, setPathInterpolationData, setViewPathInterpolation,setViewSatelliteImage,setShowSpinner, setIsSnackbarOpen, colorArr, setColorArr, setHaveShipInfo, setSelectShipWaiting } = useContext(TheiaContext);
 
  const [shipsKey, setShipsKey] = useState(0)

  useEffect(() => {
    getSanctionedData();
  }, []);

  useEffect(() => {
   if(allSanctionedShips) {
     showSanctionedShipsOnMap(date);
   }
   
  }, [allSanctionedShips, date]);

  const getSanctionedData = async (date) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${frontendAPIURL}/sanctioned_vessels`,{
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
      );

      setAllSanctionedShips(res.data);
    } catch (err) {
      console.log(err, ":err");
    }
  };


  const showSanctionedShipsOnMap = async () => {
    const token = localStorage.getItem("token");
    console.log(allSanctionedShips, ':allSanctionedShips')
    // try {
    //   const dateString = moment(date.$d).format("YYYY-MM-DD");
    //   const shipInfoRes = await axios.post(
    //     `${frontendAPIURL}/ais_for_ships`,
    //     {
    //       id: "cfrontend",
    //       shipids: allSanctionedShips.sanctioned_vessels,
    //       start: `${dateString} 00:00:00`,
    //       end: `${dateString} 23:59:59`,
    //     },
    //     {
    //       headers: {
    //         Authorization: "Bearer " + token,
    //       },
    //     }
    //   );

    //   const uniqueShipIds = [];
    //   const shipPositionData = []; // both pinged on jan3 23

    //   shipInfoRes.data.forEach((ship) => {
    //     if (!uniqueShipIds.includes(ship.synmax_ship_id)) {
    //       shipPositionData.push(ship);
    //       uniqueShipIds.push(ship.synmax_ship_id);
    //     }
    //   });
    let shipPositionData = []

    allSanctionedShips.sanctioned_vessels.forEach((ship_id, index) => {
      shipPositionData.push({
        synmax_ship_id: ship_id,
        latitude: allSanctionedShips.latitude[index],
        longitude: allSanctionedShips.longitude[index],
        heading: allSanctionedShips.heading[index],
        speed: 0,
        timestamp: allSanctionedShips.timestamp[index],
      })
    })
      setSanctionedShipsPositionData(shipPositionData);
      setShipsKey(parseInt(Math.random() * 10000))
    // } catch (err) {
    //   console.log(err, ":err");
    // }
  };

  let shipIndex = 0;

  if (selectedShips.length > 0) {
    shipIndex = selectedShips[selectedShips.length - 1].shipIndex;
  }

  if (
    selectedShips.length > 0 &&
    selectedShips[selectedShips.length - 1].path
  ) {
    shipIndex = shipIndex + selectedShips[selectedShips.length - 1].path.length;
  }

  const onEachFeaturePoint = (feature, layer) => {
    layer.on({
      click: async function (e) {
        setSelectShipWaiting(true) //for disable pointer when ship data 
        setTileLayerUrl(null)
        setPathInterpolationData(null)
        setViewSatelliteImage(false)
        setViewPathInterpolation(false)
        setSpoofingTimelineShips([])
        setShowPathPredictionDialog(false)
        setSatelliteData({geojson:null, key: parseInt(Math.random() * 10000)})
        setCurrentLocationData({ geojson: null, key: parseInt(Math.random() * 10000) })
        getShipDetails(feature,setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, setSelectShipWaiting, 'sanction')
      }
    });
  }

  return (
    <>
     {filters.sanctionedShips && <GeoJSON
        key={
          "aisships" +
          shipsKey +
          selectedShips.length +
          shipIndex
        }
        data={convertArrToGeojson(sanctionedShipsPositionData)}
        pointToLayer={(feature, latlng) => pointToLayer(feature, latlng, 'ais')}
        onEachFeature={onEachFeaturePoint}
      /> }
    </> 
  );
};


 const pointToLayer = (feature, latlng, type) => {
  let { heading } = feature.properties;
  return L.canvasMarker(latlng, {
    radius: 20,
    img: {
      url: './sanctionedShip.svg',
      size: [20, 30],
      rotate: heading,
      opacity: 0.5
    }
  })
 }

export default SanctionedShips;
