import React from 'react';
import { useLeaflet } from 'react-leaflet';

function round(number, precision = 0) {
  return (
    Math.round(number * Math.pow(10, precision) + Number.EPSILON) /
    Math.pow(10, precision)
  );
}

function formatLatitude(latitude) {
  const direction = latitude > 0 ? '+' : '-';
  return `${direction} ${round(Math.abs(latitude), 6)}° `;
}

function formatLongitude(longitude) {
  const direction = longitude > 0 ? '+' : '-';
  return `${direction} ${round(Math.abs(longitude), 6)}° `;
}

function MouseCoordinates() {
  const [mousePoint, setMousePoint] = React.useState(null);
// console.log(mousePoint)
  const formattedCoordinates =
    mousePoint === null
      ? ''
      : `${formatLatitude(mousePoint.lat)}, ${formatLongitude(mousePoint.lng)}`;

  React.useEffect(
    function copyToClipboard() {
      function handleCtrlCKeydown(event) {
        if (
          event.key === 'c' &&
          event.ctrlKey &&
          formattedCoordinates.length > 0 &&
          navigator.clipboard
        ) {
          navigator.clipboard.writeText(formattedCoordinates);
        }
      }

      document.addEventListener('keydown', handleCtrlCKeydown);

      return function cleanup() {
        document.removeEventListener('keydown', handleCtrlCKeydown);
      };
    },
    [formattedCoordinates]
  );
  const {map} = useLeaflet();
  map.on('mousemove',(e)=>{setMousePoint(e.latlng);})
  map.on('mouseout',()=>{ setMousePoint(null);})


  if (formattedCoordinates.length === 0) return null;

  return (
    <div className="leaflet-control-attribution leaflet-control"> 
      {formattedCoordinates}
    </div>
  );
}

export default MouseCoordinates;