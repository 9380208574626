import React, { useContext, useEffect, useRef, useState } from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { GeoJSON } from 'react-leaflet';
import L from 'leaflet';

import { TheiaContext } from '../../Theia';

import { convertArrToGeojson, pointToLayer, getShipDetails } from '../../utils';
import DarkDetections from './DarkDetections';
import BlueDetections from './BlueDetections';
import OrangeDetections from './OrangeDetections';

const SimilarDetections = ({ similarDetections }) => {

  const [similarRedShips, setSimilarRedShips] = useState({ ships: [], key: 0})
  const [similarBlueShips, setSimilarBlueShips] = useState({ ships: [], key: 0})
  const [similarOrangeShips, setSimilarOrangeShips] = useState({ ships: [], key: 0})


useEffect(() => {
  
    setSimilarOrangeShips({ships: similarDetections.filter(detection => (detection.dark && detection.attribution !== 'None')), key: parseInt(Math.random() * 10000)})
    setSimilarBlueShips({ships: similarDetections.filter(detection => (!detection.dark && detection.attribution !== 'None')), key: parseInt(Math.random() * 10000)})
    setSimilarRedShips({ships: similarDetections.filter(detection => (!detection.dark && detection.attribution === 'None')), key: parseInt(Math.random() * 10000)})
},[similarDetections])

  return (
    <>
     <DarkDetections darkShipDetections={similarRedShips} />
     <BlueDetections blueShipDetections={similarBlueShips}/>
     <OrangeDetections orangeShipDetections={similarOrangeShips}/>
    </>
  )
}

export default SimilarDetections
