import React, { useContext, useEffect } from 'react'
import { Slider, TextField, Button } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { TheiaContext } from '../Theia'
import './terracottaImageSlider.scss'
import { useState } from 'react';
import axios from 'axios';


const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

export const TerracottaImageSlider = ({ initialData }) => {

	const { tileBlueValue, setTileBlueValue, tileGreenValue, setTileGreenValue, tileRedValue, setTileRedValue, setShowSpinner, mapRef, blueBand, greenBand, redBand, setMapCenter, selectedShips } = useContext(TheiaContext);
	const [mainSliderValue, setMainSliderValue] = useState([0, 100])
	const [advancedSettings, setAdvancedSettings] = useState(false)
	const [lat, setLat] = useState('')
	const [lng, setLng] = useState('')


	useEffect(() => {
		setTileBlueValue([blueBand[mainSliderValue[0]], blueBand[mainSliderValue[1]]])
		setTileGreenValue([greenBand[mainSliderValue[0]], greenBand[mainSliderValue[1]]])
		setTileRedValue([redBand[mainSliderValue[0]], redBand[mainSliderValue[1]]])
	}, [mainSliderValue])

	useEffect(() => {
		let lat = selectedShip.initialData.geometry.coordinates[1];
		let lng = selectedShip.initialData.geometry.coordinates[0];
		setLat(lat)
		setLng(lng)
		console.log(lat, lng, '<=====lat lng')
	}, [])




	async function extentStretch() {
		const { _northEast, _southWest } = mapRef.current.leafletElement.getBounds()
		const { lat: lat1, lng: lng1 } = _northEast;
		const { lat: lat2, lng: lng2 } = _southWest;
		// const fullMap = [[-180,180],[-90,90]]
		try{
			let stretchUrl = 'https://terracotta-image-stretch-xzzxclvs3q-uc.a.run.app/extent_stretch'

			if (process.env.REACT_APP_NGA_ENV === true || process.env.REACT_APP_NGA_ENV === "true") {
				stretchUrl = 'https://terracotta-image-stretch-abekcpaqxa-uk.a.run.app/extent_stretch'
			}
			const res = await axios.post(stretchUrl, {
				"image_id": initialData.properties.source_id, "area": { "type": "Polygon", "coordinates": [[[lng2, lat1], [lng1, lat1], [lng1, lat2], [lng2, lat2], [lng2, lat1]]] }
			});

			const split1 = res.data.terracotta_layer.split(("&b_range="))
			const blueRange = JSON.parse(split1[1])
			const split2 = split1[0].split('&g_range=')
			const greenRange = JSON.parse(split2[1])
			const split3 = split2[0].split('&r_range=')
			const redRange = JSON.parse(split3[1])

			setTileBlueValue(blueRange)
			setTileGreenValue(greenRange)
			setTileRedValue(redRange)
			setShowSpinner(false)
		}catch {
			setShowSpinner(false)
		}
	}
	const enhanceImage = async () => {
		setShowSpinner(true)
		setMapCenter({ lat, lng })
		const myTimeout = setTimeout(extentStretch, 500);
	}

	const viewAdvancedSettings = () => {

		if (advancedSettings) {
			setAdvancedSettings(false)
		} else {
			setAdvancedSettings(true)

		}


	}

	const selectedShip = selectedShips[selectedShips.length - 1]
	console.log(selectedShip.initialData.geometry.coordinates, '<===selectedShip');

	return (

		<ThemeProvider theme={darkTheme}>
			<CssBaseline />

			<div className='terracottaImageSlider' >
				<div className='terracottaImageSlider__buttonClass'>
					<Button variant="contained" style={{ marginTop: '2rem', color: '#000', background: '#fff', borderColor: '#24A0CA', fontSize: '12px' }} onClick={enhanceImage}>Enhance Image</Button>
					<Button variant="contained" style={{ marginTop: '2rem', color: '#000', background: '#fff', borderColor: '#24A0CA', fontSize: '12px' }} onClick={viewAdvancedSettings}>{!advancedSettings ? "Show Advanced Settings" : "Hide Advanced Settings"}</Button>
				</div>
				{advancedSettings &&
					<div>
						<Slider
							size="medium"
							max={100}
							value={mainSliderValue}
							// defaultValue={10}
							min={0}
							aria-label="Blue"
							valueLabelDisplay="on"
							onChange={(e, val) => setMainSliderValue(val)}
							style={{
								color: '#24A0CA',
							}}
						/>
						<div className="terracottaImageSlider__inputBox">

							<div className="terracottaImageSlider__valueInputs">
								<div className="terracottaImageSlider__heading">Red Bands:</div>
								<div className="terracottaImageSlider__inputArea">

									<TextField
										id="outlined-basic"
										label="Red Min"
										variant="standard"
										type="number"
										name="length"
										value={tileRedValue[0]}
										onChange={(e) => setTileRedValue([e.target.value, tileRedValue[1]])}
									/>

								</div>
								<div className="terracottaImageSlider__inputArea">
									<TextField
										id="outlined-basic"
										label="Red Max"
										variant="standard"
										type="number"
										name="length"
										value={tileRedValue[1]}
										onChange={(e) => setTileRedValue([tileRedValue[0], e.target.value])}
									/>
								</div>
							</div>

							<div className="terracottaImageSlider__valueInputs">
								<div className="terracottaImageSlider__heading">Blue Bands:</div>
								<div className="terracottaImageSlider__inputArea">
									<TextField
										id="outlined-basic"
										label="Blue Min"
										variant="standard"
										type="number"
										name="length"
										value={tileBlueValue[0]}
										onChange={(e) => setTileBlueValue([e.target.value, tileBlueValue[1]])}
									/>

								</div>
								<div className="terracottaImageSlider__inputArea">
									<TextField
										id="outlined-basic"
										label="Blue Max"
										variant="standard"
										type="number"
										name="length"
										value={tileBlueValue[1]}
										onChange={(e) => setTileBlueValue([tileBlueValue[0], e.target.value])}
									/>
								</div>
							</div>

							<div className="terracottaImageSlider__valueInputs">
								<div className="terracottaImageSlider__heading">Green Bands:</div>
								<div className="terracottaImageSlider__inputArea">
									<TextField
										id="outlined-basic"
										label="Green Min"
										variant="standard"
										type="number"
										name="length"
										value={tileGreenValue[0]}
										onChange={(e) => setTileGreenValue([e.target.value, tileGreenValue[1]])}
									/>

								</div>
								<div className="terracottaImageSlider__inputArea">
									<TextField
										id="outlined-basic"
										label="Green Max"
										variant="standard"
										type="number"
										name="length"
										value={tileGreenValue[1]}
										onChange={(e) => setTileGreenValue([tileGreenValue[0], e.target.value])}
									/>
								</div>
							</div>
						</div>
						<Button variant="contained" style={{ marginTop: '2rem', color: '#000', background: '#fff', borderColor: '#24A0CA', fontSize: '12px' }} onClick={enhanceImage}>Enhance Image</Button>
					</div>
				}


				{/* <div style={{ color: '#000', fontSize: '2rem' }}>Blue</div>
      <Slider
        size="medium"
        max={11428}
        value={tileBlueValue}
        // defaultValue={10}
        min={5322}
        aria-label="Blue"
        valueLabelDisplay="on"
        onChange={(e, val) => setTileBlueValue(val)}
        style={{
          color: '#24A0CA',
        }}
      />

      <div style={{ color: '#000', fontSize: '2rem' }}>Green</div>
      <Slider
        size="medium"
        max={10401}
        value={tileGreenValue}
        // defaultValue={10}
        min={3198}
        aria-label="Green"
        valueLabelDisplay="on"
        onChange={(e, val) => setTileGreenValue(val)}
        style={{
          color: '#24A0CA',
        }}
      />

      <div style={{ color: '#000', fontSize: '2rem' }}>Red</div>
      <Slider
        size="medium"
        max={9489}
        value={tileRedValue}
        // defaultValue={10}
        min={1693}
        aria-label="Red"
        valueLabelDisplay="on"
        onChange={(e, val) => setTileRedValue(val)}
        style={{
          color: '#24A0CA',
        }}
      /> */}

			</div>
		</ThemeProvider>
	)
}
