import React, { useContext } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import {Dialog, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { TheiaContext } from "../Theia";

import { getShipInfoUsingObjectId } from "../utils";

export const SatelliteImagesTimelineDialog = ({
  satelliteImagesTimeline,
  openSatelliteImagesTimelineDialog,
  setOpenSatelliteImagesTimelineDialog
}) => {

  const { selectedShips, setSelectedShips, setShowSpinner, setHaveShipInfo, setIsSnackbarOpen, colorArr, setColorArr,setMapCenter,setSelectShipWaiting } = useContext(TheiaContext);

  // function to get ship when clicked on the image on image timeline with ship Id
  const getDetectionsForClickedImage = (id) =>{

    getShipInfoUsingObjectId(id, setHaveShipInfo, selectedShips, setSelectedShips, setShowSpinner, colorArr,setColorArr, setIsSnackbarOpen, setSelectShipWaiting, setMapCenter, setOpenSatelliteImagesTimelineDialog );

  }

  return (
    <Dialog
      open={openSatelliteImagesTimelineDialog}
      className="satelliteImageTimeLine"
      fullWidth
      maxWidth={satelliteImagesTimeline.length <= 3 ? 'sm': 'xl'}
      onClose={() => setOpenSatelliteImagesTimelineDialog(false)}
    >
      <IconButton onClick={() => setOpenSatelliteImagesTimelineDialog(false)} style={{position:'absolute',  right:8, top:8, backgroundColor:'gray', color:'white' }} >
        <CloseIcon />
      </IconButton>
      <ImageList sx={{ width: 'auto', height: 450, padding: '20px', marginTop:'30px', backgroundColor: '#30323A'}} className="satelliteImageList" gap={8} cols={6} >
        {satelliteImagesTimeline.length === 0 ? <h1 style={{color: '#fff'}}>Images Not Available</h1> :satelliteImagesTimeline.sort(function(a, b) {
          return new Date(b.acquired) - new Date(a.acquired);
        }).map(({latitude: lat, longitude: lng, object_id, length, signedUrl, acquired, heading}, i) => {
          return (satelliteImagesTimeline.length > 0) ? (
            <ImageListItem key={i}>
              <img
                style={{cursor: 'pointer'}}
                src={signedUrl}
                loading="lazy"
                alt=""
                onClick={()=>{
                  getDetectionsForClickedImage(object_id)
                }}
              />
              <div className='sat-images-info'>
                <p className="timestamp">{new Date(acquired)?.toISOString()}</p>
                <p>lat : {lat}</p>
                <p>lon : {lng}</p>
                <p>OID : {object_id}</p>
              </div>
            </ImageListItem>
          ) : <h1 style={{color: '#fff'}}>No Imagery to show</h1>
        })}
      </ImageList >
    </Dialog>
  )
}
