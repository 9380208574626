import React from "react";

const Ownership = ({ selectedShips }) => {

  let commercialownermain, effectivecontrolmain,financialownermain,technicalmanagermain = null

  if(selectedShips[selectedShips.length - 1].shipCategory !== 'red') {
    const {
      commercialowner,
      effectivecontrol,
      financialowner,
      technicalmanager,
    } = selectedShips[selectedShips.length - 1].shipData.static[0];

    commercialownermain = commercialowner
    effectivecontrolmain = effectivecontrol
    financialownermain = financialowner
    technicalmanagermain = technicalmanager
  }

  return (
    <div className="dataBox" id="commercialOwner">
      {commercialownermain &&
      effectivecontrolmain &&
      financialownermain &&
      technicalmanagermain ? (
        <>
          <div>Commercial Owner: {commercialownermain}</div>
          <div>Effective Owner: {effectivecontrolmain}</div>
          <div>Financial Owner: {financialownermain}</div>
          <div>Technical Owner: {technicalmanagermain}</div>
        </>
      ) : (
        <>Details not available</>
      )}
    </div>
  );
};

export default Ownership;
