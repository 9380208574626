import React from "react";

const TankerIcon = ({fill }) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_3"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 823.8 212.6",
    }}
    viewBox="0 0 823.8 212.6"
  >
    <style>{".st0{fill:#fff}"}</style>
    <path
      d="m725.5 127.6-.7-.7c.1-.1 8-7.1 8.8-14.1l.1-.4h.4c.5 0 49.8.9 86.5-2.4l.1 1c-34.9 3.1-81.2 2.5-86.2 2.4-1.1 7.1-8.7 13.9-9 14.2z"
      fill={fill}
    />
    <path
      d="M784.4 209.4H75c-13.5 0-22.6-6.3-22.7-6.4l-11.9-6.4v-6l.3-.1c5-1.7 5-5.4 5-5.4v-6.7c0-5-8.5-7.6-8.6-7.6l-22.4-4.5c-11-2.8-12-10-12.1-10.3C0 146.2 0 127.4 0 127.2v-.5h725.1c46.6 0 86.3-3.5 90.2-3.9l6.9-17.8c-25.4 1.9-42.6 2-44.8 2l-5.2 6.4-.8-.6 5.5-6.7h.2c.2 0 18.3 0 45.8-2.1l.8-.1-18.2 46.8v8.4c14.2.2 18 9.8 18 14.9 0 14.4-7.2 23.6-7.3 23.7-9.9 11.6-30.9 11.7-31.8 11.7zm-743-13.3 11.4 6.1c.1.1 9.1 6.2 22.2 6.2h709.4c.2 0 21.4-.1 31-11.5.1-.1 7.1-9 7.1-23 0-.6-.2-13.9-17.5-13.9h-.5v-9.7l10.3-26.5c-6 .5-44.7 3.8-89.8 3.8H1c0 3.1.3 19.3 2.7 28.1.1.3 1 7 11.3 9.5l22.4 4.5c.4.1 9.4 2.9 9.4 8.6v6.7c0 .2-.1 4.3-5.4 6.2v4.9z"
      fill={fill}
    />
    <path
      d="M25.2 209.4H10.6c-2.9 0-3.4-4.7-3.4-4.9L6 161.6h1l1.2 42.8s.5 4 2.4 4h14.5c.8-.2 2.8-1.1 2.8-2.2v-37.7h1v37.7c-.1 2.1-3.2 3.1-3.7 3.2zM39.6 196h-7.4l-1.7-1.1v-2.3l1.6-1.1h7.4v4.5zm-7.1-1h6.1v-2.6h-6.1l-.9.7v1.3l.9.6z"
      fill={fill}
    />
    <path
      d="M39.1 192.1h1.8v1h-1.8zM39.6 194.1h1.8v1h-1.8zM35.7 212.6h-.1c-.8-.1-1.3-1.1-1.4-1.3v-14.2l.4-1.8 1 .2-.4 1.7V211c.1.3.4.6.5.6 0 0 .2-.1.4-.3 0 0 1.7-2.8 1.7-14l-.4-1.7 1-.2.5 1.9c0 11.7-1.8 14.5-1.9 14.6-.6.6-1 .7-1.3.7zM38.2 191.6l-1-.2.4-1.7c0-11.2-1.7-14-1.7-14l-.3-.3c-.1 0-.4.3-.5.6v13.7l.4 1.7-1 .2-.5-1.9v-14c.1-.2.5-1.2 1.4-1.3.3 0 .8.1 1.3.7.1.1 1.9 3 1.9 14.7v.1l-.4 1.7zM780.1 112.8h-1l.3-16.6h8.3v16.3h-1V97.2h-6.3z"
      fill={fill}
    />
    <path d="M782.4 96.7h-1l.7-29.3h3.8v29.3h-1V68.4h-1.8z" fill={fill} />
    <path
      d="m818.6 105-33.8-36.9V55.6h4.6v1.5l-3.6 2.9v7.7l33.5 36.6-.7.7zm-32.7-48.4v2.1l2.6-2.1h-2.6z"
      fill={fill}
    />
    <path
      d="m785.7 66.5-.7-.7 3.4-3.3h-3v-1h4.1v1.4zM85.2 127.2h-1v-96L62 34.3l-5.3 41.8v51.1h-1V76l5.4-42.6L85.2 30zM10.9 127.2h-1v-14.5l26.8-34.8-1.1-1.1L7.3 113v14.2h-1v-14.5l29.2-37.4 2.5 2.5L10.9 113z"
      fill={fill}
    />
    <path
      d="M84.7 76.5h-38v-5.2h38v1h-37v3.2h37zM84.7 100.8h-38v-5.2h38v1h-37v3.2h37zM84.7 113.3h-38v-5.2h9.9v1h-8.9v3.2h37zM135.4 89.1H40.7l-4.9-4.5 2.4-.9 97.2.3v5.1zm-94.3-1h93.3v-3.2l-96.1-.3-.7.3 3.5 3.2z"
      fill={fill}
    />
    <path
      d="M51.4 112.8h1v14.4h-1zM47.6 100.7h1v8.1h-1zM50.3 100.7h1v8.1h-1zM47.6 89h1v7.2h-1zM50.3 89h1v7.2h-1zM47.6 76.5h1v8.1h-1zM50.3 76.5h1v8.1h-1zM14 113.2l-.7-.7 26-21.2-7.2-7 .7-.7 8 7.7z"
      fill={fill}
    />
    <path
      d="M18.6 113.3h-8.2v-1h7.9l22.1-16.8v-7.3h1V96l-.2.2zM33.05 84.634l.164-.986 3.65.605-.164.987zM70.9 88.6h-1v-8.5h-2.7v8.5h-1v-9.5h4.7zM127.6 64.9H95.2v-5.5h32.4v5.5zm-31.4-1h30.4v-3.5H96.2v3.5zM159.9 64.9h-4.6v-5.5h4.6v5.5zm-3.6-1h2.6v-3.5h-2.6v3.5zM158.1 53.2H105v-5.5h53.1v5.5zm-52-1h51.1v-3.5h-51.1v3.5zM135.7 77.4H95.2v-5.5h40.5v5.5zm-39.5-1h38.5v-3.5H96.2v3.5zM156.3 100.8H95.2v-5.5h61.2v5.5zm-60.1-1h59.2v-3.5H96.2v3.5z"
      fill={fill}
    />
    <path
      d="M95.2 64.4h1v62.9h-1zM105.1 52.7h1v11.7h-1zM156.3 95.8h-1V60.4h-28.6l-1.7-7.6.9-.2 1.6 6.8h28.8z"
      fill={fill}
    />
    <path d="m155.372 59.787 1.793-7.18.97.243-1.793 7.18z" fill={fill} />
    <path d="M126.4 54.9H157v1h-30.6zM155.3 100.3h1v26.9h-1z" fill={fill} />
    <path
      d="M275.8 113.3H155.3v-5.5h120.4v5.5zm-119.5-1h118.4v-3.5H156.3v3.5zM354.8 113.3h-74.6v-5.5h74.6v5.5zm-73.6-1h72.6v-3.5h-72.6v3.5zM470.6 113.3H359.2v-5.5h111.4v5.5zm-110.4-1h109.4v-3.5H360.2v3.5zM614.3 113.3H474.1v-5.5h140.2v5.5zm-139.2-1h138.2v-3.5H475.1v3.5zM734.5 113.3H618.7v-5.4l158.1-.9v1l-157.1.9v3.4h114.8z"
      fill={fill}
    />
    <path
      d="M227.3 127.7h-5.5V99.8h5.5v27.9zm-4.5-1h3.5v-25.9h-3.5v25.9zM315.3 127.7h-5.5V99.8h5.5v27.9zm-4.5-1h3.5v-25.9h-3.5v25.9zM402.4 127.7h-5.5V99.8h5.5v27.9zm-4.5-1h3.5v-25.9h-3.5v25.9zM489.5 127.7H484V99.8h5.5v27.9zm-4.5-1h3.5v-25.9H485v25.9zM576.6 127.7h-5.5V99.8h5.5v27.9zm-4.5-1h3.5v-25.9h-3.5v25.9zM676.2 127.7h-5.5V99.8h5.5v27.9zm-4.4-1h3.5v-25.9h-3.5v25.9zM725.6 127.7h-5.5V99.8h5.5v27.9zm-4.5-1h3.5v-25.9h-3.5v25.9zM274.8 112.8h1v14.4h-1zM280.2 113.3h1v14.4h-1zM353.8 113.3h1v14.4h-1zM359.2 113.3h1v14.4h-1zM469.6 113.3h1v14.4h-1zM474.1 113.3h1v14.4h-1zM613.3 113.3h1v14.4h-1zM618.7 113.3h1v14.4h-1zM127.2 15.9h1v36.8h-1zM117.369 52.57l6.192-36.781.986.166-6.192 36.782z"
      fill={fill}
    />
    <path d="M136.6 16.4h-18.1V0h1v15.4h17.1z" fill={fill} />
    <path d="m118.534 8.14.919-.396 3.406 7.896-.918.397z" fill={fill} />
    <path
      d="M134.2 15.9h-1v-4.4h-12.9v-1h13.9zM122.4 7.4h18.3v1h-18.3zM130.3 18.2h10.4v1h-10.4z"
      fill={fill}
    />
    <path
      d="M129.4 7.9h1V11h-1zM133 7.9h1V11h-1zM138.7 26.1h-16.3v-1h15.3v-3.3h-14.6v-1h15.6z"
      fill={fill}
    />
    <path
      d="M134.1 18.7h1v2.5h-1zM136.8 18.7h1v2.5h-1zM110.987 64.035l10.08-11.644.755.654-10.079 11.644z"
      fill={fill}
    />
    <path
      d="M111.3 64.4h-1v-4.7l6.3-7.3.8.6-6.1 7.1zM111.5 77.3 99.7 64.1v-4.2h1v3.8l11.5 12.9z"
      fill={fill}
    />
    <path
      d="M112.3 76.5h-1v-4.3l-10.6-12 .7-.7 10.9 12.3zM110.6 88.9 99.7 76.7v-4.3h1v3.9l10.6 12z"
      fill={fill}
    />
    <path
      d="M112.3 89h-1v-4.2l-10.6-12 .7-.7 10.9 12.3zM110.6 100.6 99.7 88.3v-4.2h1V88l10.6 11.9z"
      fill={fill}
    />
    <path
      d="M112.3 100.7h-1v-4.3l-10.6-12 .7-.6L112.3 96zM111.5 113.2 99.7 100v-4.2h1v3.8l11.5 12.9z"
      fill={fill}
    />
    <path d="M112.3 112.4h-1v-4.3l-10.6-12 .7-.6 10.9 12.2z" fill={fill} />
    <path
      d="M95.7 112.3h60.2v1H95.7zM133.2 76.9h-1v-8.2h-2.8v8.2h-1v-9.2h4.8zM133.2 88.6h-1v-8.3h-2.8v8.3h-1v-9.3h4.8zM133.2 100.3h-1V92h-2.8v8.3h-1V91h4.8zM10.4 121.7h41.5v1H10.4zM84.9 121.7h10.3v1H84.9zM155.8 121.7h573.3v1H155.8z"
      fill={fill}
    />
    <path
      d="M175.4 112.8h1v14.4h-1zM187.7 113.3h1v14.4h-1zM200.5 112.8h1v14.4h-1zM212.8 113.3h1v14.4h-1zM238 113.3h1v14.4h-1zM250.8 112.8h1v14.4h-1zM263.1 113.3h1v14.4h-1zM289.1 113.3h1v14.4h-1zM302 112.8h1v14.4h-1zM327.7 113.3h1v14.4h-1zM339.4 113.3h1v14.4h-1zM375.3 113.3h1v14.4h-1zM387 113.3h1v14.4h-1zM413 113.3h1v14.4h-1zM424.7 113.3h1v14.4h-1zM437.3 113.3h1v14.4h-1zM449 113.3h1v14.4h-1zM462.4 113.3h1v14.4h-1zM500.1 113.3h1v14.4h-1zM512.7 113.3h1v14.4h-1zM524.4 113.3h1v14.4h-1zM537 113.3h1v14.4h-1zM549.5 113.3h1v14.4h-1zM561.2 113.3h1v14.4h-1zM585.4 113.3h1v14.4h-1zM599.8 113.3h1v14.4h-1zM624.1 113.3h1v14.4h-1zM636.6 113.3h1v14.4h-1zM649.2 113.3h1v14.4h-1zM662.7 113.3h1v14.4h-1zM686.9 113.3h1v14.4h-1zM699.5 113.3h1v14.4h-1zM713 113.3h1v14.4h-1zM5.2 159H805v1H5.2z"
      fill={fill}
    />
  </svg>
);

export default TankerIcon;
