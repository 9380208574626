import React, { useRef, useState, useContext, useEffect } from "react";
import {
  Map,
  withLeaflet,
  Polyline,
  Marker,
  Tooltip,
  TileLayer,
  GeoJSON,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-polylinedecorator";
import L, { tileLayer } from "leaflet";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { frontendAPIURL, onEachCoverageFeature } from "../utils";

import AllRenderedShips from "./AllRenderedShips/AllRenderedShips";
import TilesAndLeafletFeatures from "./TilesAndLeafletFeatures";
import { TerracottaImageRender } from "./TerracottaImageRender";
import moment from "moment";
// Don't remove this, L.canvasMarker uses it
import canvasMarker from "leaflet-canvas-markers";
import { CircularProgress } from "@mui/material";
import { PathRender } from "./PathRender";
import { TheiaContext } from "../Theia";
import { SelectedShips } from "./AllRenderedShips/SelectedShips";
import MinorFeatures from "./MinorFeatures";
import MovingIcon from "../components/PathSlider/MovingIcon";
import FuturePathPrediction from "./FuturePathPrediction/FuturePathPrediction";
import WktTest from "./FuturePathPrediction/WktTest";
import PolylineMeasure from "./PolylineMeasure";
import SatelliteCoverageSpoofing from "./SatelliteCoverageSpoofing/SatelliteCoverageSpoofing";
import UploadGeoJson from "./UploadGeoJson";
import UploadedCSVs from "./UploadedCSVs";
import AllShipsHeatmap from "./SatelliteCoverageSpoofing/AllShipsHeatmap";
import { daboDelgado } from "./GeoJSON/Cabo+Delgado_53,262+Sq+Km_Dec+23";
import { gulfOfGuinea } from "./GeoJSON/Gulf+of+Guinea_544,526+Sq+Km_Dec+23";
import { indianOcean } from "./GeoJSON/Indian+Ocean,+Gulf+of+Aden+&+Southern+Red+Sea_2,409,763+Sq+Km_Dec+23";
import { persianGulf } from "./GeoJSON/Persian+Gulf+&+Gulf+of+Oman_342,781+Sq+Km_Dec+23";
import { seaOfAzov } from "./GeoJSON/Sea+of+Azov+&+Black+Sea_168,279+Sq+Km_Dec+23";
import RenderDarkEvent from "./RenderDarkEvent";

L.Icon.Default.imagePath =
  "//cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/";

const streets = tileLayer(
  "https://terracotta-server-dev-xzzxclvs3q-uc.a.run.app/rgb/3952212/planet/20220827/064038/{z}/{x}/{y}.png?r=red&g=green&b=blue&r_range=[2104,2482]&g_range=[3484,3848]&b_range=[5654,6084]"
);

const RefreshMap = withLeaflet((props) => {
  const polyRef = useRef();
  useEffect(() => {
    const { map } = polyRef.current.props.leaflet; //get native Leaflet map
    for (var i in map._layers) {
      if (map._layers[i]._paths) {
        if (map._layers[i]._paths.length) {
          map._layers[i].setPaths([]);
        }
      }
    }
  }, []);
  return <Polyline ref={polyRef} {...props} />;
});

const polygon = [
  [51.515, -0.09],
  [51.52, -0.1],
  [51.52, -0.12],
];

const LeafletMap = ({ setRecentlyUpdatedLiveViewOn, date }) => {
  const [pathlatlng, setPathlatlng] = useState([24.42, 120.33]);
  const [pathIndex, setPathIndex] = useState(1);
  const [pathAngle, setPathAngle] = useState(90);
  const [tileUrl, setTileUrl] = useState("");
  const [coverageGeoJson, setCoverageGeoJson] = useState(null);
  const [coverageSourceId, setCoverageSourceId] = useState(null);
  const [coverageTerracottaUrl, setCoverageTerracottaUrl] = useState(null);

  const {
    mapTheme,
    setMapTheme,
    switches,
    selectedShips,
    viewSatelliteImage,
    tileLayerUrl,
    zoomLevel,
    setZoomLevel,
    dragValueForRerender,
    setDragValueForRerender,
    showSpinner,
    setShowSpinner,
    shipViewToggle,
    pathSliderRef,
    viewPathSlider,
    viewAnalyzePath,
    mapCenter,
    droppedPins,
    setDroppedPins,
    tileBlueValue,
    tileGreenValue,
    tileRedValue,
    mapRef,
    futurePathObj,
    dragEndValueForRerender,
    setDragEndValueForRerender,
    selectShipWaiting,
    satelliteSpoofingGeojsonPosition,
    uploadedGeojsons,
    uploadedCSVs,
    setUploadedCSVs,
    darkEventOnMap,
  } = useContext(TheiaContext);

  useEffect(() => {
    pathSliderRef.current = updateShipDetails;
  }, []);

  useEffect(() => {
    getCoverageData();
  }, [date]);

  useEffect(() => {
    if (coverageSourceId) {
      setCoverageTerracotta();
    }
  }, [coverageSourceId]);

  const getCoverageData = async () => {
    setCoverageGeoJson(null);
    const token = localStorage.getItem("token");
    const dateString = moment(date.$d).format("YYYY-MM-DD");
    // const res = await axios.post(`${frontendAPIURL}/coverage`, {
    //   date: dateString
    // }
    //   , {
    //     headers: {
    //       Authorization: 'Bearer ' + token,
    //     },
    //   })

    const res = await axios.post(
      `${frontendAPIURL}/get_events`,
      {
        types: ["images"],
        start: `${dateString} 00:00:00`,
        end: `${dateString} 23:59:59`,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setCoverageGeoJson(res.data.images);
  };

  const updateShipDetails = (shipData, newValue) => {
    if (!shipData || typeof shipData.timestamp === "undefined") {
      // Handle the case where shipData or timestamp is undefined
      console.error("Error: shipData or timestamp is undefined");
      return;
    }

    const { timestamp, latitude, heading, longitude } = shipData;

    setPathlatlng([latitude, longitude]);
    setPathIndex(newValue);
    setPathAngle(heading / 2);
  };

  let refreshKey = selectedShips.length + shipViewToggle + viewAnalyzePath;

  if (selectedShips.length > 0) {
    if (selectedShips[selectedShips.length - 1]) {
      refreshKey += selectedShips[selectedShips.length - 1].path?.length;
    }
  }

  const splitTheTileUrl = () => {
    if (tileLayerUrl) {
      if (tileLayerUrl.split("planet").length === 2) {
        // planet image
        let RGB = tileLayerUrl.substring(
          tileLayerUrl.indexOf("rgb/") + 4,
          tileLayerUrl.indexOf("/planet/")
        );
        let PlanetNumber = tileLayerUrl.substring(
          tileLayerUrl.indexOf("/planet/") + 8,
          tileLayerUrl.indexOf("{z}")
        );
        let baseUrl = tileLayerUrl.substring(0, tileLayerUrl.indexOf("rgb/"));
        let tileUrl =
          baseUrl +
          "rgb/" +
          RGB +
          "/" +
          "planet" +
          "/" +
          PlanetNumber +
          "{z}/{x}/{y}.png?r=red&g=green&b=blue&r_range=" +
          "[" +
          tileRedValue[0] +
          "," +
          tileRedValue[1] +
          "]" +
          "&g_range=[" +
          tileGreenValue[0] +
          "," +
          tileGreenValue[1] +
          "]&b_range=[" +
          tileBlueValue[0] +
          "," +
          tileBlueValue[1] +
          "]";
        setTileUrl(tileUrl);
      } else {
        // sentinel-2 image
        const example = `https://us-central1-satellite-284212.cloudfunctions.net/frontend-image-server-v2//rgb/37QDF/sentinel2/20230817/075619/{z}/{x}/{y}.png
        ?r=red&g=green&b=blue&r_range=[1990,4809]&g_range=[2147,4564]&b_range=[2468,4726]`;

        const baseUrl = tileLayerUrl.split("&r_range=")[0];

        let tileUrl =
          baseUrl +
          `&r_range=[${tileRedValue[0]},${tileRedValue[1]}]&g_range=[${tileGreenValue[0]},${tileGreenValue[1]}]&b_range=[${tileBlueValue[0]},${tileBlueValue[1]}]`;
        setTileUrl(tileUrl);
      }
    }
  };

  useEffect(() => {
    splitTheTileUrl();
  }, [tileLayerUrl, tileRedValue, tileGreenValue, tileBlueValue]);

  const selectedAISShipsGeojson = {
    type: "FeatureCollection",
    features: selectedShips
      .filter((ship) => ship.visible)
      .map(({ initialData, shipCategory, shipIndex }) => ({
        ...initialData,
        properties: { ...initialData.properties, shipCategory, shipIndex },
      })),
  };

  const removeMarker = (i) => {
    const oldPins = [...droppedPins];
    oldPins.splice(i, 1);
    setDroppedPins(oldPins);
  };

  const onZoom = (e) => {
    const { _northEast, _southWest } =
      mapRef.current.leafletElement.getBounds();
    const { lat: lat1, lng: lng1 } = _northEast;
    const { lat: lat2, lng: lng2 } = _southWest;
    console.log(
      [
        [
          [lng2, lat1],
          [lng1, lat1],
          [lng1, lat2],
          [lng2, lat2],
          [lng2, lat1],
        ],
      ],
      "coords"
    );
    setZoomLevel(e.target._animateToZoom);
  };

  const purpleOptions = { color: "purple" };

  const whenReadyHandler = (event) => {
    const { target } = event;
    target.on("baselayerchange", (e) => {
      setMapTheme(e?.name);
    });
  };

  const setCoverageTerracotta = async () => {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${frontendAPIURL}/image_url`,
      { source_id: coverageSourceId },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    setCoverageTerracottaUrl(res.data.url);
  };

  return (
    <div className={`leafletMap ${selectShipWaiting ? "pointer-disable" : ""}`}>
      {showSpinner && (
        <div className="leafletMap__spinner">
          <CircularProgress
            sx={{
              color: "#fff",
            }}
          />
        </div>
      )}

      <Map
        preferCanvas={true}
        style={{ height: "100%", width: "100%" }}
        center={mapCenter}
        zoom={zoomLevel}
        maxZoom={30}
        minZoom={3}
        whenReady={whenReadyHandler}
        // onDragEnd={onDrag}
        ref={mapRef}
        // onClick={({ latlng: latLngVal }) => setLatLngVal(latLngVal)}
        onzoom={onZoom}
        // onresize={onDrag}
        dragging={!showSpinner}
        worldCopyJump={true}
        ondrag={() => setDragValueForRerender(!dragValueForRerender)}
        ondragend={() => setDragEndValueForRerender(!dragEndValueForRerender)}
        // ondragend={() => setDragValueForRerender(!dragValueForRerender)}
      >
        {viewPathSlider && (
          <MovingIcon
            pathlatlng={pathlatlng}
            pathIndex={pathIndex}
            pathAngle={pathAngle}
            selectedShips={selectedShips}
          />
        )}

        <RefreshMap key={refreshKey} positions={[]}></RefreshMap>

        {!viewPathSlider && (
          <AllRenderedShips
            date={date}
            setRecentlyUpdatedLiveViewOn={setRecentlyUpdatedLiveViewOn}
            mapRef={mapRef}
            setShowSpinner={setShowSpinner}
          />
        )}

        {selectedShips.length > 0 && (
          <SelectedShips
            selectedAISShipsGeojson={selectedAISShipsGeojson}
            refreshKey={refreshKey}
          />
        )}

        {droppedPins.map((item, i) => (
          <Marker key={i} position={item.point}>
            <Tooltip
              direction="right"
              offset={[0, 0]}
              opacity={1}
              permanent
              interactive={true}
            >
              <div>
                <div
                  onClick={() => removeMarker(i)}
                  style={{ cursor: "pointer" }}
                >
                  <CloseIcon
                    sx={{ color: "#000", fontSize: 15, cursor: "pointer" }}
                  />
                </div>
                <div>Point: {item.name}</div>
                <div>lat:{item.point[0]}</div>
                <div>lng:{item.point[1]}</div>
              </div>
            </Tooltip>
          </Marker>
        ))}

        {uploadedGeojsons.length > 0 && (
          <UploadGeoJson uploadedGeojsons={uploadedGeojsons} />
        )}
        {uploadedCSVs.length > 0 && (
          <UploadedCSVs uploadedCSVs={uploadedCSVs} />
        )}
        <TilesAndLeafletFeatures />
        <PolylineMeasure />

        {switches.coverage && coverageGeoJson && coverageGeoJson.features && (
          <GeoJSON
            data={coverageGeoJson}
            key={coverageGeoJson.features.length}
            onEachFeature={(a, b) =>
              onEachCoverageFeature(a, b, setCoverageSourceId)
            }
          />
        )}
        <MinorFeatures switches={switches} />

        {futurePathObj && <FuturePathPrediction />}
        <SatelliteCoverageSpoofing
          satelliteSpoofingGeojsonPosition={satelliteSpoofingGeojsonPosition}
          date={date}
        />
        {switches.highRisk && (
          <>
            <GeoJSON
              data={daboDelgado}
              onEachFeature={(feature, layer) =>
                layer.bindPopup(`${feature.properties.name}`)
              }
              style={{ color: "red" }}
            />
            <GeoJSON
              data={gulfOfGuinea}
              onEachFeature={(feature, layer) =>
                layer.bindPopup(`${feature.properties.name}`)
              }
              style={{ color: "red" }}
            />
            <GeoJSON
              data={indianOcean}
              onEachFeature={(feature, layer) =>
                layer.bindPopup(`${feature.properties.name}`)
              }
              style={{ color: "red" }}
            />
            <GeoJSON
              data={persianGulf}
              onEachFeature={(feature, layer) =>
                layer.bindPopup(`${feature.properties.name}`)
              }
              style={{ color: "red" }}
            />
            <GeoJSON
              data={seaOfAzov}
              onEachFeature={(feature, layer) =>
                layer.bindPopup(`${feature.properties.name}`)
              }
              style={{ color: "red" }}
            />
          </>
        )}

        <AllShipsHeatmap />
        {/* <WktTest /> */}
        {/* <TileLayer
            //  noWrap
             preferCanvas={true}
            // url="https://us-central1-satellite-284212.cloudfunctions.net/frontend-image-server/rgb/5155509/planet/20230116/013850/{z}/{x}/{y}.png?r=red&g=green&b=blue&r_range=[1313,16659]&g_range=[2200,18154]&b_range=[3752,20631]&auth=eyJhbGciOiJSUzI1NiIsImtpZCI6ImY1NWU0ZDkxOGE0ODY0YWQxMzUxMDViYmRjMDEwYWY5Njc5YzM0MTMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vc2F0ZWxsaXRlLTI4NDIxMiIsImF1ZCI6InNhdGVsbGl0ZS0yODQyMTIiLCJhdXRoX3RpbWUiOjE2NzM4NTM1MTcsInVzZXJfaWQiOiJRTkM5a0Y0N1ZJaG9uUGx4eTdRa2tpZTRiZGgyIiwic3ViIjoiUU5DOWtGNDdWSWhvblBseHk3UWtraWU0YmRoMiIsImlhdCI6MTY3Mzk0OTE2MywiZXhwIjoxNjczOTUyNzYzLCJlbWFpbCI6InBhcmFzQHN5bm1heC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsicGFyYXNAc3lubWF4LmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.qow6LRk319OiuCjP47DAfVVmGdBZQpdBcgnE5AGcPJwECu6JAf7GbQ8c8YbSJrexPzgHv07-KbFw1bfqVrUsSQS_-Qbn5rQsA67IINK1b_XPLEnMfTV1ywaSlIkl6iJ3LHiZo6fJyiEcpxkawjSRH537qrRwV9lsFCuLCS2H5eUomKc4iIgj1rVTiP8PURHX8rBte7A0-TPMbxTtK6YZ4G6HKjlGt_7zjacai9Br04NyU0rZc_aHEo1kI3GFSwvBimtAQU6ZSzGOtbiKPV30_K5BT-ZYF0AdwgGlvGfTSGJ7BfrtvyUsrh-PxSmarZPgEmfgBPgMBFhxPfUPLQXl5Q"
            // url="'https://us-central1-satellite-284212.cloudfunctions.net/frontend-image-server/rgb/5155509/planet/20230116/013850/{z}/{x}/{y}.png?r=red&g=green&b=blue&r_range=[1313,16659]&g_range=[2200,18154]&b_range=[3752,20631]&auth=eyJhbGciOiJSUzI1NiIsImtpZCI6ImY1NWU0ZDkxOGE0ODY0YWQxMzUxMDViYmRjMDEwYWY5Njc5YzM0MTMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vc2F0ZWxsaXRlLTI4NDIxMiIsImF1ZCI6InNhdGVsbGl0ZS0yODQyMTIiLCJhdXRoX3RpbWUiOjE2NzM4NTM1MTcsInVzZXJfaWQiOiJRTkM5a0Y0N1ZJaG9uUGx4eTdRa2tpZTRiZGgyIiwic3ViIjoiUU5DOWtGNDdWSWhvblBseHk3UWtraWU0YmRoMiIsImlhdCI6MTY3Mzk0OTE2MywiZXhwIjoxNjczOTUyNzYzLCJlbWFpbCI6InBhcmFzQHN5bm1heC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsicGFyYXNAc3lubWF4LmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.qow6LRk319OiuCjP47DAfVVmGdBZQpdBcgnE5AGcPJwECu6JAf7GbQ8c8YbSJrexPzgHv07-KbFw1bfqVrUsSQS_-Qbn5rQsA67IINK1b_XPLEnMfTV1ywaSlIkl6iJ3LHiZo6fJyiEcpxkawjSRH537qrRwV9lsFCuLCS2H5eUomKc4iIgj1rVTiP8PURHX8rBte7A0-TPMbxTtK6YZ4G6HKjlGt_7zjacai9Br04NyU0rZc_aHEo1kI3GFSwvBimtAQU6ZSzGOtbiKPV30_K5BT-ZYF0AdwgGlvGfTSGJ7BfrtvyUsrh-PxSmarZPgEmfgBPgMBFhxPfUPLQXl5Q"
         url="https://us-central1-satellite-284212.cloudfunctions.net/frontend-image-server/rgb/5154710/planet/20230117/014000/{z}/{x}/{y}.png?r=red&g=green&b=blue&r_range=[1898,7542]&g_range=[3457,9082]&b_range=[4823,11047]&auth=eyJhbGciOiJSUzI1NiIsImtpZCI6ImY1NWU0ZDkxOGE0ODY0YWQxMzUxMDViYmRjMDEwYWY5Njc5YzM0MTMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vc2F0ZWxsaXRlLTI4NDIxMiIsImF1ZCI6InNhdGVsbGl0ZS0yODQyMTIiLCJhdXRoX3RpbWUiOjE2NzM4NTM1MTcsInVzZXJfaWQiOiJRTkM5a0Y0N1ZJaG9uUGx4eTdRa2tpZTRiZGgyIiwic3ViIjoiUU5DOWtGNDdWSWhvblBseHk3UWtraWU0YmRoMiIsImlhdCI6MTY3Mzk1NjA2MSwiZXhwIjoxNjczOTU5NjYxLCJlbWFpbCI6InBhcmFzQHN5bm1heC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsicGFyYXNAc3lubWF4LmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.VxJ3avjz0ZjSxUhpoRcUP61B26CYcI19tLAARI5PR7QO-o6iJ6PXK7qur1us3wCpSTUFKsCNDPwS3IGLX-SlDhhW2nffB9Fj0LfVaqt2Pya5EY0aGnyVCg09sYZbeJ3deLk11Ddq6hwpNSJp-iNvBATvcW0tEGyQCUrv_7sCYJCLOLRNTHHNAw3jigjrvapBm7u9sY0_CdmVjtJT8tBJa33JZX1F6K_W0JC9WW5RlLEmcSuN1oGOabcrtgcdPXt8TNFQ7iD9O8ZxMncm6yP9J9BRqDadn6MtLt87fD89mJWUr8Cuh_4kTqa8y1gY9UaeqMDkHJvka2wvLzlG0JHo8g"
         // mike url="https://terracotta-server-dev-xzzxclvs3q-uc.a.run.app/rgb/3952212/planet/20220827/064038/{z}/{x}/{y}.png?r=red&g=green&b=blue&r_range=[2104,2482]&g_range=[3484,3848]&b_range=[5654,6084]"
          /> 
        */}

        {futurePathObj && <FuturePathPrediction />}

        {zoomLevel < 8 && switches.heatmap && (
          <TileLayer
            preferCanvas={true}
            url={`https://us-central1-satellite-284212.cloudfunctions.net/frontend-image-server/singleband/heatmap/analytics/20230101/000000/red/{z}/{x}/{y}.png?colormap=jet&auth=${localStorage.getItem(
              "token"
            )}`}
          />
        )}

        {mapTheme === "satellite" && (
          <TileLayer
            //  noWrap
            preferCanvas={true}
            url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3lubWF4IiwiYSI6ImNtMnAzejJzejBsaXYycnB1djJ5Z3FibDQifQ.QY0rEegGW89JwhOYJqAOXQ"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">Mapbox</a> contributors'
          />
        )}

        {mapTheme === "light" && (
          <TileLayer
            preferCanvas={true}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">Mapbox</a> contributors'
          />
        )}
        {mapTheme === "dark" && (
          <TileLayer
            preferCanvas={true}
            url="https://api.mapbox.com/styles/v1/mapbox/dark-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3lubWF4IiwiYSI6ImNtMnAzejJzejBsaXYycnB1djJ5Z3FibDQifQ.QY0rEegGW89JwhOYJqAOXQ"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">Mapbox</a> contributors'
          />
        )}

        {coverageTerracottaUrl && (
          <TileLayer
            preferCanvas={true}
            url={`${coverageTerracottaUrl}&auth=${localStorage.getItem(
              "token"
            )}`}
            key={mapTheme + tileUrl}
            // tileSize={256}
          />
        )}

        {viewSatelliteImage && tileUrl && (
          <TileLayer
            preferCanvas={true}
            url={`${tileUrl}&auth=${localStorage.getItem("token")}`}
            key={mapTheme + tileUrl}
            // tileSize={256}
          />
        )}

        {darkEventOnMap && <RenderDarkEvent darkEventOnMap={darkEventOnMap} />}
      </Map>
    </div>
  );
};

export default LeafletMap;
