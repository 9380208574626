import React, { useState, useEffect } from 'react'
import RotatedMarker from "./RotatedMarker";
import L from 'leaflet';
import { Circle } from "react-leaflet";

const MovingIcon = ({ pathlatlng, pathIndex, pathAngle, selectedShips }) => {
  const [isBlueShip, setIsBlueShip] = useState(true)

  useEffect(() => {
    if(selectedShips.length > 0) {
      if(selectedShips[selectedShips.length - 1].initialData.properties.object_id !== "No Info") {
        setIsBlueShip(true)
      } else {
        console.log('is green ship')
        setIsBlueShip(false)
      }
    }
  },[selectedShips])

  var shipIcon = L.icon({
    iconUrl: isBlueShip ? './blueEyeFilled.svg' : './greenIconFilled.svg',
    iconSize: [50, 50],
  });


  return (
    <>
    {pathlatlng.length !== 0 && (
      // This block renders the ship icon and the 3 circles we
      // see move along the path, when the slider is dragged
      <>
        <RotatedMarker
          position={pathlatlng}
          icon={shipIcon}
          key={pathIndex + 'ship'}
          rotationAngle={pathAngle}
        ></RotatedMarker>
        {/* <Circle
          key={pathIndex + 'green'}
          center={pathlatlng}
          color="green"
          fillColor="green"
          radius={100000}
        />
        <Circle
          key={pathIndex + 'blue'}
          center={pathlatlng}
          color="#24A0CA"
          fillColor="#24A0CA"
          radius={50000}
        />
        <Circle
          key={pathIndex + 'red'}
          center={pathlatlng}
          color="#F85348"
          fillColor="#F85348"
          radius={10000}
        /> */}
      </>
    )}
    </>
  )
}

export default MovingIcon