import React, { useState } from 'react';
import { TextField, Button, Snackbar } from '@mui/material';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail 
} from 'firebase/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import './auth.scss';
import { analytics } from '../../App';
import { useLocation } from 'react-router';
import { logEvent } from '@firebase/analytics';

const Auth = ({ isNewUser, setIsNewUser, forgotPassword, setForgotPassword }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const location = useLocation(); // React Hook
// console.log(location.pathname);

  const auth = getAuth();

  const createNewUser = () => {
    setIsLoading(true);
    setErrMessage('');
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {          
        localStorage.setItem("firstName", firstName)
        localStorage.setItem("lastName", lastName)
      })
      .catch((error) => {
        setIsLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        setErrMessage(errorMessage);
      });
  };

  const signInUser = (e) => {
    e.preventDefault()
    setIsLoading(true);
    setErrMessage('');

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        setIsLoading(false);
        const user = userCredential.user;
        window.gtag('event', 'user_login', {
          date : new Date().toLocaleDateString(),
          time : new Date().getTime(),
          user_id : user.uid,
          user_email : user.email,
          user_domain : location.pathname,
          alertnate_pathname : window.location.origin
        })
        console.log(user, 'user')
        clearState();
      })
      .catch((error) => {
        setIsLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        setErrMessage(errorMessage);
      });
  };

  const sendResetEmail = () => {
    setIsLoading(true);
    setErrMessage('');

  sendPasswordResetEmail(auth, email)
  .then(() => {
    setIsLoading(false);
    setIsSnackBarOpen(true)
    clearState('')
  })
  .catch((error) => {
    setIsLoading(false);
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
    setErrMessage(errorMessage);
  });

  }

  const clearState = () => {
    setPassword('');
    setFirstName('');
    setLastName('')
    setEmail('');
  };
  return (
    <div className="auth">
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackBarOpen(false)}
        message="Reset Email Sent!"
      />
      <div className="auth__box">
        <div className="auth__header">
          {
            forgotPassword
            ? 'Reset Password'
            : isNewUser
            ? 'New To SynMax? Create An Account'
            : 'Sign In'}
        </div>

     
          <div className="switchForm">
            {isNewUser ? (
              <div className="switch">
                {forgotPassword
                  ? 'Back To Previous Form?'
                  : 'Already have an account?'}
                <Button
                  onClick={(e) => {
                    clearState();
                    setForgotPassword(false);
                    return setIsNewUser(false);
                  }}
                >
                  {forgotPassword ? 'Go Back' : 'Sign In'}
                </Button>
              </div>
            ) : (
              <div className="switch">
                {forgotPassword ? 'Back To Previous Form?' : ' New to SynMax?'}
                <Button
                  onClick={(e) => {
                    clearState();
                    setForgotPassword(false);
                    return setIsNewUser(true);
                  }}
                >
                  {forgotPassword ? 'Go Back' : 'Sign Up'}
                </Button>
              </div>
            )}
          </div>
 

        {(forgotPassword) ? (
          <form className="auth__inputs auth__signUp">
            <div className="auth__email">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="standard"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <LoadingButton
              variant="outlined"
              className="button"
              onClick={sendResetEmail}
              loading={isLoading}
              type="submit"
              style={{
                width: 180,
              }}
            >
              Send Reset Email
            </LoadingButton>
          </form>
        ) : (isNewUser) ? (
          <form className="auth__inputs auth__signUp">
            <div className="auth__email">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="standard"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="auth__name">
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="standard"
                type="text"
                name="name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className="auth__lastName">
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="standard"
                type="text"
                name="name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className="auth__password">
              <TextField
                id="password"
                label="Password"
                variant="standard"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <LoadingButton
              variant="outlined"
              className="button"
              onClick={createNewUser}
              loading={isLoading}
              type="submit"
            >
              Sign Up
            </LoadingButton>
          </form>
        ) : (
          <form className="auth__inputs auth__signUp auth__signIn">
            <div className="auth__email">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="standard"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="auth__password">
              <TextField
                id="password"
                label="Password"
                variant="standard"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <LoadingButton
              variant="outlined"
              className="button"
              onClick={signInUser}
              loading={isLoading}
              type="submit"
            >
              Sign In
            </LoadingButton>
          </form>
        )}

        {errMessage !== '' && <div className="errorMessage">{errMessage}</div>}

        {!forgotPassword && (
          <div className="switchForm resetPassword">
            <div className="switch">
              Forgot Password?
              <Button
                onClick={(e) => {
                  clearState();
                  setIsNewUser(!isNewUser);
                  return setForgotPassword(true);
                }}
              >
                Reset Password
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Auth;
