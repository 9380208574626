import React, { useContext, useEffect, useRef } from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { GeoJSON } from 'react-leaflet';
import L from 'leaflet';

import { TheiaContext } from '../../Theia';
import { convertArrToGeojson, pointToLayer, getShipDetails } from '../../utils';

const PurpleDetections = ({ purpleShips}) => {
  const {setHaveShipInfo, selectedShips, setSelectedShips, setShowPathPredictionDialog,setTileLayerUrl, selectedPolygons, setPathInterpolationData, setViewPathInterpolation,setViewSatelliteImage,setShowSpinner, setIsSnackbarOpen, colorArr, setColorArr  } = useContext(TheiaContext);
 
  const onEachFeaturePoint = (feature, layer) => {
    layer.on({
      click: async function (e) {
        setTileLayerUrl(null)
        setViewSatelliteImage(false)
        setPathInterpolationData(null)
        setViewPathInterpolation(false)
        setShowPathPredictionDialog(false)
        // getShipDetails(feature, setHaveShipInfo, inputRef?.current, setSelectedShips, setShowSpinner, colorArr, setColorArr, setIsSnackbarOpen, 'blue')
      },
      mouseover: () => {
        console.log(feature, 'feature')
        // const {destination, name} = feature.properties;
        // layer.bindPopup(
        //   `<div>
        //       <div>Destination: ${destination}</div>
        //       <div>Name: ${name}</div>
        //   </div>`);
        // layer.openPopup()
      },
      mouseout: () => {
        layer.closePopup()
      }
    });
  }


  let shipIndex = 0

  if(selectedShips.length > 0) {
    shipIndex = selectedShips[selectedShips.length - 1].shipIndex
  }

  return (
    <>
        {purpleShips.ships.length > 0 && <MarkerClusterGroup
          disableClusteringAtZoom={7}
          maxClusterRadius={40}
          iconCreateFunction={function (cluster) {
            return L.divIcon({
              html: cluster.getChildCount(),
              className: 'bluecluster',
              // iconSize: L.point(40, 40),
            });
          }}
        >
           <GeoJSON
            key={'purpleShips'+ purpleShips.key + selectedShips.length + selectedPolygons.length+shipIndex}
            data={convertArrToGeojson(purpleShips.ships)}
            pointToLayer={(feature, latlng) => pointToLayer(feature, latlng, 'purpleDetections')}
            onEachFeature={onEachFeaturePoint}
          />
        </MarkerClusterGroup>}
    </>
  )
}

export default PurpleDetections
