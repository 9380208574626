import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Select, MenuItem, Snackbar, Dialog } from '@material-ui/core';
import { TextField, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import XLSX from 'xlsx';

import { db } from '../../App';
import './admin.scss';
import axios from 'axios';

import { firebaseConfig } from '../../App';

const DataTable = ({ userData }) => {

  const [allProfiles, setAllProfiles] = useState([]);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [roleChanged, setRoleChanged] = useState(false);
  const [showDialog, setShowDialog] = useState({
    show: false,
    role: '',
    userId: '',
  });
  const [noOfDays, setNoOfDays] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false)

  const changeRole = async (role, userId) => {
    if (role === 'temporary_theia_user') {
      setShowDialog({
        show: true,
        role,
        userId,
      });
    } else {
      const adminUrl = (window.location.href.includes('localhost')) ? "http://localhost:8000"
          : (process.env.REACT_APP_ENV==='dev' ? 'https://frontend-admin-api-j75nm34yfa-uc.a.run.app' : 'https://frontend-admin-api-y5rlib2gfa-uc.a.run.app');

      setIsDataLoading(true)
      const token = localStorage.getItem('token')
      const headers = {
        "Authorization": "Bearer " + token,
        "Accept": "application/json",
        "Crossorigin": true
      }
      const user = {
        uid: userId,
        role: role
      }
      await axios.post(adminUrl + '/user', user, { "headers": headers })
          .then(response => {
            let profiles = []
            const users = response.data
            users.forEach(user => {
              profiles.push({
                id: user.email,
                role: user.role,
                userId: user.uid,
                expiry_date: user.expiry != null ? moment.unix(user.expiry_date).format('DD MMM YYYY') : '-',
                firstName: user.first,
                lastName: user.last,
                creation: user.created,
                lastLoggedIn: user.lastLogin
              })
              setAllProfiles(profiles);
            });
          }).catch(err => {
            console.error(err)
          });
      setIsDataLoading(false)
      setRoleChanged(!roleChanged);
      setIsSnackBarOpen(true);
    }
  };

  const columns = [
    { field: 'id', headerName: 'Email', flex: 2 },
    { field: 'firstName', headerName: 'First',  flex: 1 },
    { field: 'lastName', headerName: 'Last', flex: 1 },
    { field: 'creation', headerName: 'Created', flex: 2,
      renderCell: (params, val) => {
        return params.row.creation.substr(5)
      }
  },
    { field: 'lastLoggedIn', headerName: 'Last Logged In', minWidth: 150, flex: 2,
      renderCell: (params, val) => {
        return params.row.lastLoggedIn.substr(5)
      }
  },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 150,
      flex:3,
      renderCell: (params, val) => {
        const { userId, role } = params.row;
        switch (userData.role) {
          case 'super_user':
            return (
              <div style={{ display: 'flex', flexDirection: 'column', fontSize: 15 }}>
                <Select
                  labelId="criteria-label"
                  id="criteria"
                  defaultValue={role}
                  label="Age"
                  style={{ fontSize: 15}}
                  onChange={(e) => {
                    e.stopPropagation()
                  return  changeRole(e.target.value, userId)
                  }}
                >
                  <MenuItem value="new_user"  style={{ fontSize: 15}}>New User</MenuItem>
                 
                
                  <MenuItem value="theia_customer">Theia Customer</MenuItem>
                 
                  <MenuItem value="co-worker">Co Worker</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
                {role === 'temporary_hyperion_user' && (
                  <div>
                    Expires on :{' '}
                    {moment(params.row.expiry_date).format('DD MMM')}
                  </div>
                )}
                {role === 'temporary_theia_user' && (
                  <div>
                    Expires on :{' '}
                    {moment(params.row.expiry_date).format('DD MMM')}
                  </div>
                )}
              </div>
            );
          case 'admin':
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Select
                  labelId="criteria-label"
                  id="criteria"
                  defaultValue={role}
                  label="Age"
                  onChange={(e) => {
                    e.stopPropagation()
                  return  changeRole(e.target.value, userId)
                  }}
                  disabled={role === 'admin' || role === 'super_user'}
                >
                  <MenuItem value="new_user">New User</MenuItem>
                  
                  <MenuItem value="theia_customer">Theia Customer</MenuItem>
                  
                  <MenuItem value="co-worker">Co Worker</MenuItem>
                 
                  {role === 'admin' && <MenuItem value="admin">Admin</MenuItem>}
                  {role === 'super_user' && (
                    <MenuItem value="super_user">Super User</MenuItem>
                  )}
                </Select>
                {role === 'temporary_hyperion_user' && (
                  <div>
                    Expires on :{' '}
                    {moment(params.row.expiry_date).format('DD MMM')}
                  </div>
                )}
                 {role === 'temporary_theia_user' && (
                  <div>
                    Expires on :{' '}
                    {moment(params.row.expiry_date).format('DD MMM')}
                  </div>
                )}
              </div>
            );
          default:
            return params.value;
        }
      },
    },
  ];

  useEffect(() => {
    getAllUsers();
  }, [roleChanged]);

  const getAllUsers = async () => {
    console.log('process.env.REACT_APP_ENV')
    console.log(process.env.REACT_APP_ENV)
    const adminUrl = (window.location.href.includes('localhost')) ? "http://localhost:8000"
        : (process.env.REACT_APP_ENV==='dev' ? 'https://frontend-admin-api-j75nm34yfa-uc.a.run.app' : 'https://frontend-admin-api-y5rlib2gfa-uc.a.run.app');

    setIsDataLoading(true)
    const token = localStorage.getItem('token')
    const headers = {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Crossorigin": true
    }
    let profiles = []
    await axios.get(adminUrl + '/users', { "headers": headers })
        .then(response => {
          const users = response.data
          console.log(users)
          users.forEach(user => {
            profiles.push({
              id: user.email,
              role: user.role,
              userId: user.uid,
              expiry_date: user.expiry != null ? moment.unix(user.expiry_date).format('DD MMM YYYY') : '-',
              firstName: user.first,
              lastName: user.last,
              creation: user.created,
              lastLoggedIn: user.lastLogin
            })
          });
        }).catch(err => {
          console.error(err)
        });
    console.log(profiles, 'profiles')
    setAllProfiles(profiles);
    setIsDataLoading(false)
  };

  const giveTemporaryAccess = async () => {
    const { role, userId } = showDialog;
    const userRef = doc(db, 'users', userId);

    const expiry_date = moment(Date.now()).add('days', noOfDays)._d;

    await updateDoc(userRef, {
      role,
      expiry_date,
    });
    setRoleChanged(!roleChanged);
    setShowDialog({ show: false });
    setIsSnackBarOpen(true);
  };

  const downloadXLS = () => {
    const users = allProfiles.map(
      ({ id, role, expiry_date, firstName, lastName, lastLoggedIn, creation }) => ({
        email: id,
        role,
        'expiry date': expiry_date,
        'First Name': firstName,
        'Last Name': lastName,
        'Created On': creation,
        'Last Logged In': lastLoggedIn
      })
    );
    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Users');
    XLSX.writeFile(wb, `allUsers.xlsx`);
  }

  return (
    <div className="admin">
      <Dialog
        open={showDialog.show}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') {
            return;
          } else {
            setShowDialog({ show: false });
          }
        }}
        classes={{ root: 'MuiDialog-root pickDays' }}
        disableEscapeKeyDown
      >
        <div className="admin__dialog">
          <div className="dialogHeading">
            Enter the number of days, this user should have access for:
          </div>
          <TextField
            placeholder="No Of Days"
            type="number"
            value={noOfDays}
            onChange={(e) => setNoOfDays(e.target.value)}
          />
          <Button
            style={{ display: 'block', marginTop: 10 }}
            size="small"
            variant="contained"
            onClick={giveTemporaryAccess}
          >
            Give Access
          </Button>
        </div>
      </Dialog>
      <div className="admin__navbar">
        <div className="admin__navbarText">Admin</div>
        <div className="admin__navbarText">
          <Link to="/" style={{ color: '#000' }}>
            Back to Theia
          </Link>
        </div>
      </div>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={2000}
        onClose={() => setIsSnackBarOpen(false)}
        message="Role Updated"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <div className="admin__content">
        <div className="admin__currentUser">
          Logged in as: <strong>{userData.email}</strong>
        </div>
        <div className="admin__role">
          <strong>({userData.role})</strong>
        </div>

        <div className="admin__download" onClick={downloadXLS}>
          <div className="admin__download--icon">
          <DownloadIcon style={{ color: '#333', fontSize: "22px" }} />
          </div>
         <div className="admin__download--text">
          Download XLS
         </div>
          
        </div>
        <div className="admin__table">
          <DataGrid
            rows={allProfiles}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[5]}
            loading={isDataLoading}
            style={{ fontSize: 15}}
          />
        </div>
      </div>
    </div>
  );
};

export default DataTable;
