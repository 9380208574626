import React from 'react'
import { useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../App'
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'white',
    color: "#474747",
    border: '0px solid #fff',
    borderRadius:"10px",
    boxShadow: 24,
    p: 4,
    outline: "none"
  };

export default function Updates() {
    const [open, setOpen] = React.useState(false);
    const [version, setVersion] = React.useState("");

    const [newUpdate, setNewUpdate] = useState([]);

    const getVersion = localStorage.getItem("version");

    useEffect(()=>{

        if(version && getVersion && getVersion !== version){
            setOpen(true)
        } else {
            return;
        }

    },[version])


    useEffect(async() => {
        const docRef = doc(db, 'updates', "new_feature");

        onSnapshot(docRef, async (snapshot) => {
            if (snapshot.exists()) {
                const docSnap = snapshot.data()
                setVersion(docSnap.version);
                setNewUpdate([docSnap.newUpdate]);
            }
        })

    }, []);


    const handleClose =() =>{
        setOpen(false)
        localStorage.setItem("version", version)
    }

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <div style={{zIndex:"1px"}} >
          <CloseIcon
          style={{ cursor: 'pointer', float:"right", position:"absolute", right:"12px", top:"10px" }}
          onClick={handleClose}
          />
          {newUpdate && newUpdate?.map((item, i)=> {
            return(
                <div key={i}>
                    <Typography style={{color: "#474747", textAlign:"center"}} id="modal-modal-title" fontSize="32px" variant="h6" component="h2">
                        {item.message}
                    </Typography>
                    <div className='welcomeMessagePara'>
                        <p>SynMax is pleased to announce that six new collection areas are now live within Theia. The six new areas of interest are: </p>
                        <p>1. Gibraltar Strait</p>
                        <p>2. Galapagos Islands </p>
                        <p>3. South Africa</p>
                        <p>Large portions of:</p>
                        <p>4. Gulf of Mexico</p>
                        <p>5. West Africa</p>
                        <p>6. East Coast of the United States</p>
                        {/* <p> {item.content} </p>
                         */}
                        <p> Our team has put together documentation to help you navigate the Theia portal. It can be found at <a rel='noreferrer' target='_blank' href='https://apidocs.theia.synmax.com/'>https://apidocs.theia.synmax.com/</a></p>

                        <p> For help and support, please contact: <br/> Product Inquiries: dean@synmax.com <br/> IT/Login Inquiries: kristen@synmax.com </p>
                        
                        <p> We hope you enjoy using Theia as much as we enjoyed creating it </p>

                        <p> <strong>SynMax-Theia Team</strong></p>
                    </div>
                </div>
            )
          })}
       </div>
    </Box>
  </Modal>
  )
}
